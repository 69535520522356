import { actionStatuses, companiesTypes } from '../../../constants/action-types';

const defaultState = {
  company: {},
  user: {},
  companies: { data: [], meta: {} },
  subCompanies: { data: [], meta: {} },
  isFetchCompanies: false,
  isFetchSubCompanies: false,
  errorMessage: ''
};

const map = {
  // GET_COMPANIES
  [`${companiesTypes.GET_COMPANIES}${actionStatuses.FULFILLED}`]: (state, action) => ({
    ...state,
    companies: action.payload.data,
    isFetchCompanies: false
  }),
  [`${companiesTypes.GET_COMPANIES}${actionStatuses.PENDING}`]: state => ({
    ...state,
    isFetchCompanies: true
  }),
  [`${companiesTypes.UPDATE_MESSENGER_STATUS}${actionStatuses.PENDING}`]: state => ({
    ...state,
    isFetchSubCompanies: true
  }),
  [`${companiesTypes.UPDATE_MESSENGER_STATUS}${actionStatuses.REJECTED}`]: (state, action) => ({
    ...state,
    isFetchSubCompanies: false,
    errorMessage: action.payload.response.data.message
  }),
  [`${companiesTypes.UPDATE_MESSENGER_STATUS}${actionStatuses.FULFILLED}`]: (state, action) => ({
    ...state,
    isFetchSubCompanies: false,
    subCompanies: {
      ...state.subCompanies,
      data: state.subCompanies.data.map(company =>
        company.id === action.payload.data.data.id ? action.payload.data.data : company
      )
    }
  }),
  [`${companiesTypes.GET_COMPANIES}${actionStatuses.REJECTED}`]: (state, action) => ({
    ...state,
    isFetchCompanies: false,
    errorMessage: action.payload.response.data.message
  }),

  // GET_SUB_COMPANIES
  [`${companiesTypes.GET_SUB_COMPANIES}${actionStatuses.FULFILLED}`]: (state, action) => ({
    ...state,
    subCompanies: action.payload.data,
    isFetchSubCompanies: false
  }),
  [`${companiesTypes.GET_SUB_COMPANIES}${actionStatuses.PENDING}`]: state => ({
    ...state,
    isFetchSubCompanies: true
  }),
  [`${companiesTypes.GET_SUB_COMPANIES}${actionStatuses.REJECTED}`]: (state, action) => ({
    ...state,
    isFetchSubCompanies: false,
    errorMessage: action.payload.response.data.message
  }),

  // GET_COMPANY
  [`${companiesTypes.GET_COMPANY}${actionStatuses.FULFILLED}`]: (state, action) => ({
    ...state,
    company: action.payload.data.data,
    isFetchCompany: false
  }),
  [`${companiesTypes.GET_COMPANY}${actionStatuses.PENDING}`]: state => ({
    ...state,
    isFetchCompany: true
  }),
  [`${companiesTypes.GET_COMPANY}${actionStatuses.REJECTED}`]: (state, action) => ({
    ...state,
    isFetchCompany: false,
    errorMessage: action.payload.response.data.message
  })
};

export default (state = defaultState, action) =>
  (map[action.type] && map[action.type](state, action)) || state;
