import React from 'react';
import PropTypes from 'prop-types';
import { getLocalStorage } from '../../utils';

class Canny extends React.Component {
  static propTypes = {
    appID: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      token: props.token
    };
  }

  shouldComponentUpdate() {
    return true;
  }

  componentDidMount() {
    this.renderScript();
  }

  renderScript() {
    const { appID } = this.props;
    const email = getLocalStorage('email');
    const user_id = getLocalStorage('user_id');
    const created_at = getLocalStorage('created_at');
    if (email === null || user_id === null || created_at === null) {
      return;
    }
    const cannyEmbed = document.createElement('script');
    cannyEmbed.setAttribute('id', 'cannyEmbed');

    const inlineCannyScript = document.createTextNode(
      '!function(w,d,i,s){function l(){if(!d.getElementById(i)){var f=d.getElementsByTagName(s)[0],e=d.createElement(s);e.type="text/javascript",e.async=!0,e.src="https://canny.io/sdk.js",f.parentNode.insertBefore(e,f)}}if("function"!=typeof w.Canny){var c=function(){c.q.push(arguments)};c.q=[],w.Canny=c,"complete"===d.readyState?l():w.attachEvent?w.attachEvent("onload",l):w.addEventListener("load",l,!1)}}(window,document,"canny-jssdk","script");'
    );

    cannyEmbed.appendChild(inlineCannyScript);
    document.getElementsByTagName('head')[0].appendChild(cannyEmbed);
    const newScript = document.createElement('script');
    const inlineScript = document.createTextNode(
      `Canny('identify', {\n` +
        `  appID: "${appID}",\n` +
        `  user: {\n` +
        `    // Replace these values with the current user's data\n` +
        `    email: "${email}",\n` +
        `    name: "${email}",\n` +
        `    id: "${user_id}",\n` +
        `\n` +
        `    created: new Date("${created_at.date}").toISOString(),\n` +
        `  },\n` +
        `});\n`
    );
    newScript.setAttribute('id', 'cannyScript');
    newScript.appendChild(inlineScript);
    document.getElementsByTagName('head')[0].appendChild(newScript);
  }

  componentWillUnmount() {
    this.clearScript();
  }

  clearScript() {
    const cannyEmbed = document.getElementById('cannyEmbed');
    const cannyScript = document.getElementById('cannyScript');
    if (cannyEmbed && cannyScript) {
      cannyEmbed.remove();
      cannyScript.remove();
    }
  }

  componentDidUpdate() {
    this.clearScript();
    this.renderScript();
  }

  render() {
    const { token } = this.props;

    if (typeof token === 'undefined' || token === '') return false;
    return true;
  }
}

export default Canny;
