import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { SelectAsyncPaginate, Select } from '@Thread-Magic/jasmine';

import AsyncSelect from '../../SimpleSelect/async';

import * as Styled from './styles';

const SelectByType = (type, props) => {
  const types = {
    paginate: SelectAsyncPaginate,
    async: AsyncSelect,
    basic: Select
  };

  const Component = types[type];

  return <Component {...props} />;
};

const CustomSelect = ({ label, description, shouldPaginate, componentType, ...props }) => (
  <Fragment>
    {label && <Styled.LabelStyled>{label}</Styled.LabelStyled>}
    {description && <Styled.DescStyled>{description}</Styled.DescStyled>}
    {SelectByType(componentType, props)}
  </Fragment>
);

CustomSelect.propTypes = {
  label: PropTypes.string,
  description: PropTypes.string,
  componentType: PropTypes.string,
  placeholder: PropTypes.string,
  shouldPaginate: PropTypes.bool,
  getOptionValue: PropTypes.func,
  getOptionLabel: PropTypes.func,
  customStyles: PropTypes.object
};

CustomSelect.defaultProps = {
  label: null,
  description: null,
  shouldPaginate: false,
  componentType: 'basic',
  placeholder: 'Select...',
  getOptionValue: option => option.id,
  getOptionLabel: option => option.name,
  customStyles: {}
};

export default CustomSelect;
