import React from 'react';
import { useMutation, useQuery } from 'react-query';
import queryKeys from 'src/constants/queryKeys';
import FadeInOutContainer from 'src/components/FadeInOutContainer';
import { chatgenieAPI } from 'src/config/api';
import PageHolder from 'src/components/PageHolder';
import ReactMarkdown from 'react-markdown';
import { RadioButton } from '@Thread-Magic/jasmine';
import { FiInfo } from 'react-icons/fi';
import { Tooltip } from 'src/components/Tooltip/style';
import { queryClient } from 'src/config/queryClient';
import useToast from 'src/hooks/useToast';
import { Wrapper } from '../style';
import * as Styled from '../style';
import { Card } from '../CopilotTestAction/style';
import CopilotTestChat from '../CopilotTestChat';
import { GenerateTitleBody, GenerateTitleHeader, RecommendedTag } from './styled';

const newMessageNormalizer = ({ data }) => {
  return {
    type: 'copilot',
    name: 'Magic AI',
    body: (
      <Card>
        <h5>Generate title action</h5>
        <div>
          <ReactMarkdown>{data.title}</ReactMarkdown>
        </div>
      </Card>
    )
  };
};

const usageTypes = [
  {
    value: 'all_client',
    label: 'Use for all chat',
    recommended: true
  },
  {
    value: 'all_client_and_psa',
    label: 'Use for all chat, email and phone',
    recommended: false
  },
  {
    value: 'flows',
    label: 'Specify in the Flows',
    recommended: false,
    tooltip: (
      <>
        Choose this option if you only want to apply this
        <br /> feature to specific Flows you define
      </>
    )
  }
];

const CopilotGenerateTitle = () => {
  const { toast } = useToast();
  const { data, isLoading, refetch } = useQuery(
    [queryKeys.GET_COPILOT_GENERATE_TITLE_SETTINGS],
    () => chatgenieAPI.generateTitle.getSettings(),
    { refetchOnMount: true }
  );

  const { mutate } = useMutation(payload => chatgenieAPI.generateTitle.updateSettings(payload), {
    onError: err => {
      const errorMessage = `Failed to update Generate Title settings: ${
        err?.response?.data?.message || err?.message || 'Unknown'
      }`;
      toast.error(errorMessage);
      refetch();
    }
  });

  const handleChange = e => {
    const payload = {
      usage_type: e.target.value
    };
    queryClient.setQueryData([queryKeys.GET_COPILOT_GENERATE_TITLE_SETTINGS], old => ({
      ...old,
      ...payload
    }));
    mutate(payload);
  };

  const handleSendMessage = ({ message }) => {
    return chatgenieAPI.generateTitle.test({
      message
    });
  };

  if (isLoading) {
    return <PageHolder isLoading fullHeight />;
  }

  return (
    <Wrapper pageMode="view">
      <FadeInOutContainer visible>
        <Styled.ViewContainer>
          <Styled.ViewInner paddingTop={32}>
            <GenerateTitleHeader>
              <h2>Summary</h2>
              <p>Let Magic AI generate the thread summary based on the request.</p>
            </GenerateTitleHeader>
            <GenerateTitleBody>
              <h5>Automatically generate title</h5>
              <div>
                {usageTypes.map(({ value, label, recommended, tooltip }) => (
                  <RadioButton
                    onChange={handleChange}
                    id="generate-title-usage-type"
                    checked={data?.usage_type === value}
                    value={value}
                    key={value}
                  >
                    {label} {recommended && <RecommendedTag>Recommended</RecommendedTag>}
                    {tooltip && (
                      <>
                        &nbsp; <FiInfo data-tip data-for={`${value}-tooltip`} />
                        <Tooltip id={`${value}-tooltip`} effect="solid">
                          {tooltip}
                        </Tooltip>
                      </>
                    )}
                  </RadioButton>
                ))}
              </div>
            </GenerateTitleBody>
          </Styled.ViewInner>
          <CopilotTestChat
            title="Test generate title"
            description="Magic will automatically set a title of the thread."
            firstMessage="Hi, Magic here! Tell me about your issue and I'll create a title for you."
            askAnotherMessage="Ask me another one and I’ll give you a title!"
            newMessageNormalizer={newMessageNormalizer}
            onSend={handleSendMessage}
          />
        </Styled.ViewContainer>
      </FadeInOutContainer>
    </Wrapper>
  );
};

export default CopilotGenerateTitle;
