import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

const { version } = require('../../package.json');

const initilizeSentry = () => {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
    release: `admin-ui-web@${version}`,
    environment: process.env.REACT_APP_ENV,
    ignoreErrors: ['Non-Error exception captured']
  });
};

export default initilizeSentry;
