import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from 'react-query';
import queryKeys from 'src/constants/queryKeys';
import { chatgenieAPI } from 'src/config/api';
import FadeInOutContainer from 'src/components/FadeInOutContainer';
import sortBy from 'lodash/sortBy';
import magicAnimated from 'src/assets/icons/magic-animated.gif';
import { isHalo } from 'src/utils/psa';
import Introduction from '../Introduction';
import PageHolder from '../../PageHolder';
import ViewTypes from './ViewTypes';
import EditTypes from './EditTypes';
import { Wrapper } from '../style';
import { introContent } from './constants';

const pageModes = {
  start: 'start',
  edit: 'edit',
  view: 'view',
  loading: 'loading'
};

const isAnyCategorizationTypeModified = data => {
  return data?.data && data.data.some(board => board.filled_descriptions);
};

const CopilotCategorization = ({ companyId }) => {
  const { data, isLoading } = useQuery(
    [queryKeys.GET_CATEGORIZATION_TYPES],
    () =>
      chatgenieAPI.getCategorizationTypes(companyId).then(res => {
        return { data: sortBy(res.data, 'name') };
      }),
    {
      refetchOnMount: true
    }
  );

  const [pageMode, setPageMode] = useState();

  const handleStart = async () => {
    setPageMode(pageModes.edit);
  };

  const handleSave = () => {
    setPageMode(isAnyCategorizationTypeModified(data) ? pageModes.view : pageModes.start);
  };

  useEffect(() => {
    if (data) {
      setPageMode(
        isAnyCategorizationTypeModified(data) || isHalo() ? pageModes.view : pageModes.start
      );
    }
  }, [data]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pageMode]);

  if (isLoading) {
    return <PageHolder fullHeight isLoading />;
  }

  return (
    <Wrapper pageMode={pageMode}>
      {pageMode && pageMode !== pageModes.view && (
        <img src={magicAnimated} alt="Magic" width="92" height="92" />
      )}
      <FadeInOutContainer visible={pageMode === pageModes.start}>
        <Introduction content={introContent} onStartClick={handleStart} />
      </FadeInOutContainer>
      <FadeInOutContainer visible={pageMode === pageModes.edit}>
        <EditTypes companyId={companyId} onSave={handleSave} data={data.data} />
      </FadeInOutContainer>
      <FadeInOutContainer visible={pageMode === pageModes.view}>
        <ViewTypes companyId={companyId} data={data.data} />
      </FadeInOutContainer>
    </Wrapper>
  );
};

CopilotCategorization.propTypes = {
  companyId: PropTypes.number.isRequired
};

export default CopilotCategorization;
