import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button } from '@Thread-Magic/jasmine';
import { Switch } from 'antd';
import { updateSsoEnabled } from 'src/redux/modules/chats/chatsActions';
import { ReactComponent as GreenCheckIcon } from 'src/assets/images/green-check.svg';
import {
  MicrosoftConnectionWrapper,
  MicrosoftConnectDescriptionWrapper,
  MicrosoftConnectionTitleWrapper,
  MicrosoftConnectionIcon,
  MicrosoftConnectionSubText,
  SsoSwitchWrapper
} from './style';
import { useSsoFlag } from './featureFlag';

function MicrosoftConnection({
  icon,
  title,
  text,
  subText,
  switchLabel,
  chatInfoLoader,
  isLoading,
  handleConnect,
  handleDisconnect,
  connected,
  buttonLabel,
  updateSsoEnabled,
  ssoEnabled
}) {
  const [isChecked, setIsChecked] = useState(ssoEnabled);
  const [switchLoading, setSwitchLoading] = useState(false);

  const handleSwitchChange = checked => {
    setSwitchLoading(true);
    updateSsoEnabled(checked).then(() => {
      setIsChecked(checked);
      setSwitchLoading(false);
    });
  };

  const handleButtonClick = () => {
    if (connected) {
      handleDisconnect();
    } else {
      handleConnect();
    }
  };

  useEffect(() => {
    setIsChecked(ssoEnabled);
  }, [ssoEnabled]);

  const ssoFeatureEnabled = useSsoFlag();

  return (
    <MicrosoftConnectionWrapper data-testid="microsoft-connections-wrapper">
      {icon && <MicrosoftConnectionIcon>{icon}</MicrosoftConnectionIcon>}
      <MicrosoftConnectDescriptionWrapper>
        <MicrosoftConnectionTitleWrapper>
          <b>{title}</b>
          {connected && (
            <>
              <GreenCheckIcon />
              <div>Connected</div>
            </>
          )}
        </MicrosoftConnectionTitleWrapper>
        <p style={{ marginBottom: '4px', fontSize: '14px' }}>{text}</p>
        {subText && <MicrosoftConnectionSubText>{subText}</MicrosoftConnectionSubText>}
        {switchLabel && ssoFeatureEnabled && (
          <SsoSwitchWrapper>
            <div className="switch-label">Enable SSO</div>
            <Switch
              checked={isChecked}
              onChange={handleSwitchChange}
              disabled={!connected}
              loading={switchLoading}
            />
          </SsoSwitchWrapper>
        )}
      </MicrosoftConnectDescriptionWrapper>
      <br />
      <Button
        size="small"
        full
        isLoader={chatInfoLoader === 'pending' || isLoading}
        onClick={handleButtonClick}
        style={{ maxWidth: '100px', marginTop: '7px', height: '32px' }}
        data-testid="connect-button"
      >
        {buttonLabel}
      </Button>
    </MicrosoftConnectionWrapper>
  );
}

MicrosoftConnection.propTypes = {
  icon: PropTypes.node,
  title: PropTypes.string,
  text: PropTypes.string,
  subText: PropTypes.string,
  switchLabel: PropTypes.string,
  chatInfoLoader: PropTypes.string,
  isLoading: PropTypes.bool,
  handleConnect: PropTypes.func,
  handleDisconnect: PropTypes.func,
  connected: PropTypes.bool,
  buttonLabel: PropTypes.string,
  updateSsoEnabled: PropTypes.func.isRequired,
  ssoEnabled: PropTypes.number
};

MicrosoftConnection.defaultProps = {
  icon: null,
  title: '',
  text: '',
  subText: '',
  switchLabel: '',
  chatInfoLoader: '',
  isLoading: false,
  handleConnect: () => {},
  handleDisconnect: () => {},
  connected: false,
  buttonLabel: '',
  ssoEnabled: 0
};

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  updateSsoEnabled: ssoEnabled => dispatch(updateSsoEnabled(ssoEnabled))
});

export default connect(mapStateToProps, mapDispatchToProps)(MicrosoftConnection);
