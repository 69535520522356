import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { contactsColumnsData } from './config';
import { getContacts } from '../../../redux/modules/contacts/actions';

import Table from '../../../components/Table';

const Contacts = ({ getContacts, contacts, isFetchContacts, errorMessage }) => {
  return (
    <Fragment>
      <Table
        getData={getContacts}
        isLoading={isFetchContacts}
        error={errorMessage}
        columns={contactsColumnsData}
        rows={contacts}
      />
    </Fragment>
  );
};

Contacts.propTypes = {
  getContacts: PropTypes.func.isRequired,
  contacts: PropTypes.objectOf(PropTypes.any),
  isFetchContacts: PropTypes.bool,
  errorMessage: PropTypes.string
};

Contacts.defaultProps = {
  contacts: {},
  isFetchContacts: false,
  errorMessage: ''
};

const mapStateToProps = state => ({
  contacts: state.contactsReducer.contacts,
  isFetchContacts: state.contactsReducer.isFetchContacts,
  errorMessage: state.contactsReducer.errorMessage
});

const mapDispatchToProps = dispatch => ({
  getContacts: params => dispatch(getContacts(params))
});

export default connect(mapStateToProps, mapDispatchToProps)(Contacts);
