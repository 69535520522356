import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { chatgenieAPI } from 'src/config/api';
import useToast from 'src/hooks/useToast';
import { useMagicAiTrial } from 'src/pages/DeploymentCenter/context/magicAiTrialContext';
import { MAGIC_AI_STATES, MESSENGER_STATES } from 'src/pages/DeploymentCenter/utils/constants';
import { updatePartnerClientsData } from 'src/redux/modules/deployment-center/actions';

const useMessengerMagicAiStateUpdates = ({ onFinishCb } = {}) => {
  const { isTrialPeriodRunning } = useMagicAiTrial();
  const { toast } = useToast();
  const clientsData = useSelector(state => state.deploymentCenterReducer.clients.data || []);

  const dispatch = useDispatch();

  const { mutateAsync: updateStatuses, isLoading } = useMutation({
    mutationFn: async payload => {
      const result =
        payload.actionKey === 'magic_ai_status'
          ? await chatgenieAPI.updateMagicAIStatuses({
              companyIds: payload.companyIds,
              excludedCompanyIds: payload.excludedCompanyIds,
              action: payload.actionType,
              query: payload.queryParams
            })
          : await chatgenieAPI.updateMessengerStatuses({
              companyIds: payload.companyIds,
              excludedCompanyIds: payload.excludedCompanyIds,
              action: payload.actionType,
              query: payload.queryParams
            });
      let updatedActionType = payload.actionType;

      if (payload.actionKey === 'is_messenger_enabled') {
        updatedActionType = payload.actionType === MESSENGER_STATES.ENABLED;
      }

      return {
        payload: {
          ...payload,
          actionType: updatedActionType
        },
        result
      };
    },
    onSuccess: ({ payload, result } = {}) => {
      const isUpdateApplied = result?.data?.updated_count > 0;
      const action =
        isTrialPeriodRunning &&
        payload.actionKey === 'magic_ai_status' &&
        payload.actionType === MAGIC_AI_STATES.ENABLED
          ? MAGIC_AI_STATES.TRIAL
          : payload.actionType;

      if (result?.status === 200 && payload?.companyIds && clientsData.length && isUpdateApplied) {
        const newClientsData = clientsData.map(client => {
          if (payload.companyIds.includes(client.company_id)) {
            return {
              ...client,
              [payload.actionKey]: action
            };
          }
          return client;
        });
        if (newClientsData) {
          dispatch(updatePartnerClientsData(newClientsData));
        }
        if (payload.successMessage) {
          toast.success(payload.successMessage);
        }
      }
    },
    onError: error => {
      toast.error(error.message);
    },
    onSettled: () => {
      if (onFinishCb) {
        onFinishCb();
      }
    }
  });

  return {
    updateStatuses,
    isLoading
  };
};

export default useMessengerMagicAiStateUpdates;
