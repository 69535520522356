import jwtDecode from 'jwt-decode';
import { getLocalStorage, getUrlParam, setLocalStorage } from '../../../utils';
import { normalizeUserData, normalizeWorspaceSettings } from '../../../utils/normalizer';
import {
  usersTypes,
  flowsTypes,
  actionStatuses,
  chatsTypes,
  configurationsTypes,
  workspacesTypes
} from '../../../constants/action-types';

const defaultState = {
  userId: getLocalStorage('userId'),
  isUpdatingPassword: false,
  cannyToken: '',
  isCreatePassword: false,
  isFetchCannyToken: false,
  isFetchUser: false,
  signInErrorMessage: '',
  signUnErrorMessage: '',
  resetPasswordErrorMessage: '',
  createPasswordErrorMessage: '',
  inviteMemberStatus: 'idle',
  inviteMemberError: null,
  user: {
    company: {},
    email: getLocalStorage('email'),
    userId: getLocalStorage('userId'),
    appId: getUrlParam('app_id') || '',
    cwConfiguration: getLocalStorage('cwConfiguration'),
    chatConfiguration: getLocalStorage('chatConfiguration'),
    botInstalled: getLocalStorage('botInstalled'),
    flowItemsReady: getLocalStorage('flowItemsReady'),
    msTeamsHasNewConfiguration: getLocalStorage('msTeamsHasNewConfiguration'),
    slackHasNewConfiguration: getLocalStorage('slackHasNewConfiguration')
  }
};

const map = {
  // SET_IS_FLOWS
  [usersTypes.SET_IS_FLOWS]: state => ({
    ...state,
    user: { ...state.user, isFlows: true }
  }),

  // FLOW_ITEMS_READY
  [flowsTypes.FLOW_ITEMS_READY]: (state, action) => ({
    ...state,
    user: {
      ...state.user,
      flowItemsReady: action.payload.flowItemsReady
    }
  }),
  // For companion apps only
  [`${chatsTypes.UPDATE_CHAT_CONFIGURATION}${actionStatuses.FULFILLED}`]: (state, action) => {
    const chatConfiguration =
      state.user.chatConfiguration === chatsTypes.SLACK
        ? chatsTypes.SLACK
        : action.payload.data.data.selected_configuration;

    return {
      ...state,
      user: {
        ...state.user,
        chatConfiguration,
        botInstalled: Boolean(action.payload.data.data.selected_configuration)
      }
    };
  },
  // For companion apps only
  [`${chatsTypes.UNINSTALL_CHAT_CONFIGURATION}${actionStatuses.FULFILLED}`]: (state, action) => {
    let chatConfiguration = chatsTypes.SKIPPED;
    if (JSON.parse(action.payload.config.data).is_deleting_m365) {
      chatConfiguration =
        state.user.chatConfiguration === chatsTypes.SLACK ? chatsTypes.SLACK : chatsTypes.SKIPPED;
    }

    return {
      ...state,
      user: {
        ...state.user,
        chatConfiguration,
        botInstalled: false
      }
    };
  },
  [`${chatsTypes.SIGN_IN_SLACK}${actionStatuses.FULFILLED}`]: state => ({
    ...state,
    user: {
      ...state.user,
      chatConfiguration: chatsTypes.SLACK,
      slackHasNewConfiguration: false,
      botInstalled: true
    }
  }),
  [`${chatsTypes.INTEGRATE_MS}${actionStatuses.FULFILLED}`]: (state, action) => {
    const chatConfiguration =
      state.user.chatConfiguration === chatsTypes.SLACK
        ? chatsTypes.SLACK
        : action.payload.config.chatConfiguration;

    return {
      ...state,
      user: {
        ...state.user,
        msTeamsHasNewConfiguration: false,
        chatConfiguration
      }
    };
  },
  [`${configurationsTypes.GET_TEAMS}${actionStatuses.FULFILLED}`]: (state, action) => ({
    ...state,
    user: {
      ...state.user,
      botInstalled: !!action.payload.data.data.length
    }
  }),
  [`${usersTypes.SIGN_OUT}`]: state => ({
    ...state,
    user: {}
  }),
  [`${usersTypes.SAVE_TOKEN}`]: (state, action) => ({
    ...state,
    user: { ...state.user, token: action.payload.token }
  }),
  [`${usersTypes.SAVE_APP_ID}`]: (state, action) => {
    const { appId, token } = action.payload;
    setLocalStorage({ app: { sub: jwtDecode(token || state.user.token).sub, id: appId } });

    return {
      ...state,
      user: { ...state.user, appId }
    };
  },
  [`${usersTypes.CLEAR_USER_DATA}`]: state => ({
    ...state,
    user: {
      ...defaultState.user,
      token: state.user.token,
      companyCount: state.user.companyCount,
      appId: null
    }
  }),

  // SIGN_IN
  [`${usersTypes.SIGN_IN}${actionStatuses.FULFILLED}`]: (state, action) => ({
    ...state,
    user: {
      ...state.user,
      ...normalizeUserData(action.payload.data)
    },
    isFetchUser: false,
    signInErrorMessage: ''
  }),
  [`${usersTypes.SIGN_IN}${actionStatuses.PENDING}`]: state => ({
    ...state,
    isFetchUser: true,
    signInErrorMessage: ''
  }),
  [`${usersTypes.SIGN_IN}${actionStatuses.REJECTED}`]: (state, action) => ({
    ...state,
    isFetchUser: false,
    signInErrorMessage: action.payload.response.data.message
  }),

  // GET_CANNY_TOKEN
  [`${usersTypes.GET_CANNY_TOKEN}${actionStatuses.PENDING}`]: state => ({
    ...state,
    isFetchCannyToken: true
  }),
  [`${usersTypes.GET_CANNY_TOKEN}${actionStatuses.REJECTED}`]: state => ({
    ...state,
    isFetchCannyToken: false
  }),
  [`${usersTypes.GET_CANNY_TOKEN}${actionStatuses.FULFILLED}`]: (state, action) => ({
    ...state,
    isFetchCannyToken: false,
    cannyToken: action.payload.data.data.token
  }),
  [`${usersTypes.SET_PARTIAL_USER_DATA}`]: (state, action) => ({
    ...state,
    user: {
      ...state.user,
      ...action.payload
    }
  }),

  // UPDATE_WORKSPACE_SETTINGS
  [`${workspacesTypes.UPDATE_WORKSPACE_SETTINGS}${actionStatuses.FULFILLED}`]: (state, action) => {
    const { company } = state.user;
    const { data } = action.payload.data;
    return {
      ...state,
      user: {
        ...state.user,
        ...(action.meta.companyId === company.id && {
          company: { ...company, settings: normalizeWorspaceSettings(data) }
        })
      }
    };
  },

  // CREATE_WORKSPACE_SETTINGS
  [`${workspacesTypes.CREATE_WORKSPACE_SETTINGS}${actionStatuses.FULFILLED}`]: (state, action) => {
    const { company } = state.user;
    const { data } = action.payload.data;
    return {
      ...state,
      user: {
        ...state.user,
        ...(action.meta.companyId === company.id && {
          company: { ...company, settings: normalizeWorspaceSettings(data) }
        })
      }
    };
  },

  // INVITE_MEMBER
  [`${usersTypes.INVITE_MEMBER}${actionStatuses.PENDING}`]: state => ({
    ...state,
    inviteMemberStatus: 'loading'
  }),
  [`${usersTypes.INVITE_MEMBER}${actionStatuses.REJECTED}`]: (state, action) => ({
    ...state,
    inviteMemberStatus: 'failed',
    inviteMemberError: action.payload.response.data.message
  }),
  [`${usersTypes.INVITE_MEMBER}${actionStatuses.FULFILLED}`]: state => ({
    ...state,
    inviteMemberStatus: 'succeeded'
  }),
  [usersTypes.CLEAR_INVITE_MEMBER_ERROR]: state => ({
    ...state,
    inviteMemberError: null
  })
};

export default function usersReducer(state = defaultState, action) {
  return (map[action.type] && map[action.type](state, action)) || state;
}
