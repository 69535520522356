import { psaTypes } from '.';

export default {
  [psaTypes.CONNECTWISE]: {
    board: 'Board',
    type: 'Type',
    subtype: 'Subtype'
  },
  [psaTypes.AUTOTASK]: {
    board: 'Queue',
    type: 'Issue Type',
    subtype: 'Sub Issue Type'
  },
  [psaTypes.HALO]: {
    board: 'Team'
  }
};
