import styled from 'styled-components/macro';

export const Description = styled.p`
  max-width: 400px;
  margin: 0 auto;
  font-size: 14px;
  text-align: center;
  color: ${({ theme }) => theme.colors.gray5};
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
  button {
    margin-top: 13px;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 16px 0;
`;

export const FormItem = styled.div`
  width: 100%;
`;
