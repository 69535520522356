import styled, { css } from 'styled-components/macro';

export const Wrapper = styled.div`
  position: relative;
`;

export const FieldError = styled.p`
  height: 13px;
  margin-top: 4px;
  margin-bottom: 0;
  font-size: 11px;
  line-height: 13px;
  color: ${({ theme }) => theme.colors.danger.main};
`;

export const ImageWrapper = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: 0;
  padding: 12px;
  border: 1px solid ${({ theme }) => theme.colors.border.variant1};
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.background.default};
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: ${({ theme }) => theme.colors.gray2};
  }

  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    pointer-events: none;
  }

  input {
    position: absolute;
    top: -1000px;
    left: -1000px;
    opacity: 0;
    width: 1px;
    height: 1px;
  }

  svg {
    width: 24px;
    height: 24px;
  }
`;

export const ImagePlaceholder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  font-size: 40px;
`;

export const CropArea = styled.div`
  margin-bottom: 16px;
`;

export const CropContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const CanvasPreview = styled.canvas`
  object-fit: contain;
  width: 100%;
  height: 100%;
`;

export const IconPopup = styled.div`
  position: absolute;
  top: ${({ position }) => position.y + 10}px;
  left: ${({ position }) => position.x + 10}px;
  width: 340px;
  border: 1px solid ${({ theme }) => theme.colors.border.variant1};
  border-radius: 4px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
  background: ${({ theme }) => theme.colors.background.default};
  z-index: 1000;

  .emoji-mart {
    border: none;
  }
`;

export const PopupHeader = styled.nav`
  display: flex;
  padding: 0 4px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.variant1};
`;

export const PickerTab = styled.button`
  position: relative;
  width: 72px;
  margin-right: 6px;
  padding: 12px 0 10px;
  border: none;
  color: ${({ theme }) => theme.colors.brand.light.base};
  text-align: center;
  font-size: 16px;
  line-height: 19px;
  background: none;
  text-transform: capitalize;

  &:hover {
    font-weight: 600;
  }

  ${({ isActive }) =>
    isActive &&
    css`
      font-weight: 600;

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        display: block;
        width: 100%;
        height: 2px;
        background-color: ${({ theme }) => theme.colors.brand.light.base};
      }
    `}
`;

export const ImagePicker = styled.div`
  position: relative;
  padding: 20px;

  input {
    display: none;
  }

  p {
    margin: 20px 0 0;
    text-align: center;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: ${({ theme }) => theme.colors.gray6};
  }
  ${FieldError} {
    color: ${({ theme }) => theme.colors.danger.main};
  }
`;

export const UploadImage = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 8px;
  border: 1px solid ${({ theme }) => theme.colors.brand.light.base};
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.brand.light.base};
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  cursor: pointer;

  svg {
    margin-right: 6px;
    stroke: ${({ theme }) => theme.colors.brand.light.base};
  }
`;
