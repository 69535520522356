import styled, { css } from 'styled-components/macro';
import appPreview from 'src/assets/images/app-preview.svg';
import { Button as JasmineButton } from '@Thread-Magic/jasmine';
import LinkButton from 'src/components/LinkButton';
import SimpleSelect from 'src/components/SimpleSelect';

export const Col = styled.div`
  padding-bottom: 20px;

  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.border.hover};
    margin-bottom: 20px;
  }
`;
export const Row = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${({ marginBottom }) => marginBottom || 10}px;
  margin-top: ${({ marginTop }) => marginTop || 0}px;
`;
export const RadioLabel = styled.div`
  line-height: 14px;
  margin-left: 6px;
  color: ${({ theme }) => theme.colors.gray5};
  b {
    font-weight: 600;
    color: ${({ theme }) => theme.colors.common.black};
  }
`;

export const Container = styled.div`
  margin: 0 -20px;
`;
export const ListItem = styled.div`
  display: flex;
  height: 37px;
  align-items: center;
  padding: 0px 14px;
  cursor: ${({ isSelected }) => (isSelected ? 'default' : 'pointer')};
  .label {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
    span {
      font-size: 12px;
      margin-left: 10px;
    }
  }
  :hover {
    background-color: ${({ theme }) => theme.colors.action.variant2};
  }
  ${({ isFocused }) =>
    isFocused &&
    css`
      background-color: ${({ theme }) => theme.colors.action.variant2};
    `}
`;

export const Content = styled.div`
  display: flex;
  flex-direction: ${({ flexRow }) => (flexRow ? 'row' : 'column')};
  align-items: ${({ flexRow }) => (flexRow ? 'center' : 'flex-start')};
  justify-content: ${({ flexRow }) => (flexRow ? 'space-between' : 'normal')};
  flex: 1;
`;

export const Subtitle = styled.h4`
  margin: 0;
  margin-bottom: 16px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: ${({ theme }) => theme.colors.text.default};
`;

export const Preview = styled.div`
  width: 70px;
  height: 70px;
  margin-left: 0px;
  margin-right: 30px;
  background-image: ${({ logo }) => `url(${logo || appPreview})`};
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`;

export const Title = styled.h3`
  margin: 0;
  margin-bottom: 54px;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  height: 90px;
`;

export const Logo = styled.div`
  width: 48px;
  height: 48px;
  margin-right: 50px;
`;

export const Description = styled.div`
  margin-right: 53px;
  min-width: 245px;
`;

export const Name = styled.h5`
  margin: 0;
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  color: ${({ theme }) => theme.colors.text.default};
`;

export const PlatformID = styled.p`
  line-height: 32px;
  font-size: 11px;
  margin: 0;
`;

export const Status = styled.div`
  margin-left: auto;
  font-size: 14px;
  line-height: 17px;
  color: ${({ theme, danger }) => (danger ? theme.colors.red1 : theme.colors.green4)};

  svg {
    width: 18px;
    height: 18px;
    margin-right: 6px;
    stroke: #27ae60;
  }
`;

export const Button = styled(JasmineButton)`
  flex: 1;
`;

export const DangerOutlineButton = styled(JasmineButton)`
  color: #d0605c;
  border-color: #d0605c;
  min-width: 99px;
  :hover,
  :focus {
    background-color: #fdf1f2;
    color: #d0605c;
  }

  svg circle {
    stroke: #d0605c !important;
  }
`;

export const ConfirmButton = styled(JasmineButton)`
  :hover,
  :focus {
    color: #fff;
  }
`;

export const Action = styled.button`
  border: none;
  background: none;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: ${({ theme }) => theme.colors.brand.light.base};
`;

export const Info = styled.p`
  margin-right: 16px;
  margin-bottom: 6px;
  font-size: 14px;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.gray5};
`;

export const Actions = styled.div`
  svg {
    width: 12px;
    height: 12px;
    margin-right: 5px;
    margin-bottom: 4px;
    stroke: ${({ theme }) => theme.colors.gray5};
  }
`;

export const ModalWrapper = styled.div`
  margin-bottom: 20px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 20px;
  gap: 15px;
`;

export const CancelButton = styled(LinkButton)`
  color: #999999;
`;

export const Select = styled(SimpleSelect)`
  width: 130px;
`;

export const WrapperMessenger = styled.div`
  display: flex;
  position: relative;
`;

export const InfoMessenger = styled.p`
  margin-right: 16px;
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 22px;
  width: 400px;
`;

export const Code = styled.div`
  background-color: #efeeea;
  padding: 10px 20px;
  line-height: 25px;
  font-size: 14px;
  border-radius: 5px;
  width: 400px;
  text-wrap: pretty;
  overflow-wrap: break-word;
  margin-top: 16px;
`;
