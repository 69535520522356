import { getTheme } from '@Thread-Magic/jasmine';

export const isDevelopment = () => process.env.NODE_ENV === 'development';

export const isProduction = () => process.env.NODE_ENV === 'production';

export const getHeaders = (getState, headers) => {
  const { user } = getState().usersReducer;

  return {
    Authorization: `Bearer ${user.token}`,
    ...(user.appId && { 'APP-ID': user.appId }),
    ...headers
  };
};

export const setLocalStorage = data => {
  Object.keys(data).forEach(item => {
    localStorage[item] = JSON.stringify({ data: data[item] });
  });
};

export const getLocalStorage = key => {
  const storageItem = JSON.parse(localStorage.getItem(key));
  return storageItem && storageItem.data;
};

export const removeLocalStorage = key => {
  localStorage.removeItem(key);
};

export const getCurrencyFormat = format => {
  const currency = {
    USD: '$0,0.00'
  };

  return currency[format];
};

export const compareOnKey = key => (a, b) => {
  if (a[key] < b[key]) {
    return -1;
  }

  if (a[key] > b[key]) {
    return 1;
  }

  return 0;
};

export const getUrlParam = param =>
  window.location.search
    .replace('?', '')
    .split('&')
    .reduce((accumulator, currentValue) => {
      const a = currentValue.split('=');
      accumulator[decodeURIComponent(a[0])] = decodeURIComponent(a[1]);
      return accumulator;
    }, {})[param];

export const getChangedUrl = data => {
  let { url, value } = data;
  const { name } = data;
  const pattern = new RegExp(`\\b(${name}=).*?(&|#|$)`);

  if (value == null) {
    value = '';
  }

  if (url.search(pattern) >= 0) {
    return url.replace(pattern, `$1${value}$2`);
  }

  url = url.replace(/[?#]$/, '');
  return `${url}${url.indexOf('?') > 0 ? '&' : '?'}${name}=${value}`;
};

export const processfile = (imageURL, name) =>
  new Promise((resolve, reject) => {
    const image = new Image();

    const onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = image.width;
      canvas.height = image.height;

      const ctx = canvas.getContext('2d');
      ctx.drawImage(image, 0, 0);
      canvas.toBlob(blob => resolve(new File([blob], name)));
    };

    image.crossOrigin = 'anonymous';
    image.onload = onload;
    image.onerror = reject;

    // passing the timestamp to prevent caching - https://www.hacksoft.io/blog/handle-images-cors-error-in-chrome
    image.src = `${imageURL}?v=${Date.now()}`;
  });

export function getFileName(path) {
  return path?.replace(/^.*[\\\/]/, '');
}

export function downloadFile(data, filename, mime) {
  const formattedFileName = filename.replace(/\s+/g, '-').toLowerCase();
  // It is necessary to create a new blob object with mime-type explicitly set
  // otherwise only Chrome works like it should
  const blob = new Blob([data], { type: mime || 'application/octet-stream' });
  if (typeof window.navigator.msSaveBlob !== 'undefined') {
    // IE doesn't allow using a blob object directly as link href.
    // Workaround for "HTML7007: One or more blob URLs were
    // revoked by closing the blob for which they were created.
    // These URLs will no longer resolve as the data backing
    // the URL has been freed."
    window.navigator.msSaveBlob(blob, formattedFileName);
    return;
  }
  // Other browsers
  // Create a link pointing to the ObjectURL containing the blob
  const blobURL = window.URL.createObjectURL(blob);
  const tempLink = document.createElement('a');
  tempLink.style.display = 'none';
  tempLink.href = blobURL;
  tempLink.setAttribute('download', formattedFileName);
  // Safari thinks _blank anchor are pop ups. We only want to set _blank
  // target if the browser does not support the HTML5 download attribute.
  // This allows you to download files in desktop safari if pop up blocking
  // is enabled.
  if (typeof tempLink.download === 'undefined') {
    tempLink.setAttribute('target', '_blank');
  }
  document.body.appendChild(tempLink);
  tempLink.click();
  document.body.removeChild(tempLink);
  setTimeout(() => {
    // For Firefox it is necessary to delay revoking the ObjectURL
    window.URL.revokeObjectURL(blobURL);
  }, 100);
}

export const isValidUrl = urlString => {
  const urlPattern = new RegExp(
    '^(https?:\\/\\/)?' + // validate protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ); // validate fragment locator
  return !!urlPattern.test(urlString);
};

export function parseJwt(token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(c => {
        return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
}

export const getInitials = str => {
  const rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');
  let initials = [...str.matchAll(rgx)] || [];
  initials = ((initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')).toUpperCase();

  return initials;
};

export function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export const getPriorityColor = color => {
  const theme = getTheme();
  if (!color) {
    return theme.colors.priority.default;
  }
  const isHexColor = /^#[0-9A-F]{6}[0-9a-f]{0,2}$/i.test(color);
  if (isHexColor) {
    return color;
  }

  return theme.colors.priority[color] || color.toLowerCase();
};
