import React, { Fragment } from 'react';
import ActionsMenu from 'src/components/ActionsMenu';
import { ReactComponent as SuccessIcon } from 'src/assets/icons/done.svg';
import { ReactComponent as ErrorIcon } from 'src/assets/icons/error.svg';

export const flowTableColumns = (actions, chatType) => {
  const columns = [
    { key: 'name', title: 'Flow Name', component: row => <b>{row.name}</b> },
    {
      key: 'entity_type',
      title: 'Type',
      filterType: 'checkbox'
    },
    {
      key: 'inboxChannelName',
      title: 'Inbox Channel',
      isSort: false
    }
  ];

  if (['SLACK', 'MS_TEAMS'].includes(chatType)) {
    columns.push({
      key: 'channelName',
      title: 'Channel',
      isSort: false
    });
  }

  columns.push({
    key: 'is_create_channel',
    title: 'New Channel?',
    component: row => (row.isCreateChannel ? 'Yes' : 'No'),
    filterType: 'checkbox'
  });

  if (chatType === 'SLACK') {
    columns.push({
      key: 'is_reply_broadcast',
      title: 'Reply Broadcast?',
      component: row => (row.isReplyBroadcast ? 'Yes' : 'No'),
      filterType: 'checkbox'
    });
  }

  columns.push(
    ...[
      {
        key: 'lastRunTime',
        title: 'Last Run Time',
        component: row =>
          row.lastRunTime === null || row.lastRunTime === '0' || !row.lastRunTime
            ? 'Never'
            : row.lastRunTime
      },
      {
        key: 'is_active',
        title: 'Status',
        component: row =>
          row.status ? (
            <Fragment>
              <SuccessIcon width="20" height="20" style={{ marginRight: '10px' }} />
              <span>Running</span>
            </Fragment>
          ) : (
            <Fragment>
              <ErrorIcon width="20" height="20" style={{ marginRight: '10px' }} />
              <span>Not running</span>
            </Fragment>
          ),
        filterType: 'checkbox'
      },
      {
        key: 'row-actions',
        title: '',
        compact: true,
        component: row => <ActionsMenu key={row.id} row={row} actions={() => actions(row)} />,
        isSort: false
      }
    ]
  );

  return columns;
};
