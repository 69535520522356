import { Button as JasmineButton } from '@Thread-Magic/jasmine';
import styled from 'styled-components';

export const Wrapper = styled.div`
  max-width: 588px;
  margin-bottom: 16px;
  background: #ffffff;
  margin: 10vh auto 20px;
  padding: 30px 66px;
`;

export const Title = styled.h3`
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: ${({ theme }) => theme.colors.text.default};
`;

export const SubTitle = styled.h5`
  font-weight: 600;
  font-size: 16px;
  margin-top: 30px;
`;
export const Desc = styled.p`
  font-size: 14px;
`;
export const LinkContainer = styled.div`
  display: flex;
  margin-bottom: 10px;
  div {
    flex: 1;
  }
`;
export const LinkButton = styled(JasmineButton)`
  height: 40px;
  margin-left: 10px;
`;

export const ButtonLink = styled.button`
  display: block;
  margin: 0 auto;
  border: none;
  background: none;
  font-size: 14px;
  line-height: 33px;
  text-align: center;
  color: ${({ theme }) => theme.colors.brand.light.base};
`;

export const CountdownContainer = styled.div`
  margin-bottom: 30px;
  display: inline-flex;
  align-items: center;
  background-color: ${({ isOver, theme }) =>
    isOver ? theme.colors.danger.light : theme.colors.background.variant7};
  color: ${({ isOver, theme }) => (isOver ? theme.colors.danger.dark : theme.colors.text.variant7)};
  padding: 2px 6px;
  font-weight: 600;
  font-size: 13px;
  border-radius: 2px;
  svg {
    margin-right: 4px;
  }
  svg circle {
    stroke-dasharray: 32px;
    stroke-linecap: round;
    stroke-width: 2px;
    stroke: ${({ fullRed, theme }) => (fullRed ? theme.colors.red1 : theme.colors.yellow1)};
    fill: none;
  }
`;

export const CentralizedContainer = styled.div`
  text-align: center;
`;

export const Code = styled.div`
  background-color: #efeeea;
  padding: 10px 20px;
  line-height: 25px;
  font-size: 14px;
  border-radius: 5px;
  width: 100%;
  text-wrap: pretty;
  overflow-wrap: break-word;
  margin-top: 16px;
`;

export const SwitchContainer = styled.div`
  width: 100px;
  height: 32px;
`;

export const M365AuthContainer = styled.div`
  display: flex;
  gap: 0px;
`;
