import PageHolder from 'src/components/PageHolder';
import styled from 'styled-components';

export const LocalPageHolder = styled(PageHolder)`
  background-color: #fff;
  padding: 0;
  min-height: 60vh;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Aside = styled.div`
  position: relative;
  width: 423px;
  z-index: 1;
`;

export const MessengerWrapper = styled.div`
  position: sticky;
  top: 60px;
  padding-top: 60px;
  width: 100%;
  display: flex;
  align-items: center;
`;

export const ChatBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 430px;
  background-color: #faf9f6;
`;

export const Body = styled.div`
  display: flex;
  flex-grow: 1;
  padding: 20px;
  box-shadow: 0px 14px 50px rgba(0, 0, 0, 0.1);
  z-index: 2;
`;

export const Title = styled.div`
  font-style: normal;
  font-weight: ${({ thin }) => (thin ? 400 : 600)};
  font-size: 20px;
  line-height: 22px;
  margin: ${({ marginTop }) => marginTop || 22}px 0px 20px;
`;

export const SubTitle = styled.h2`
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 22px;
  font-weight: bold;
  color: #000000;
`;

export const Description = styled.p`
  font-size: 14px;
  margin-bottom: 10px;
`;

export const FormGroup = styled.fieldset`
  position: relative;
  max-width: ${({ maxWidth }) => maxWidth || '600px'};
  margin-bottom: 22px;

  & > &:first-child {
    margin-top: -12px;
  }

  fieldset {
    margin-left: 24px;
    margin-bottom: 16px;
  }

  input {
    max-width: none;
  }

  label {
    font-size: 14px;
    color: ${({ theme }) => theme.colors.black1};
  }

  ${({ inline }) =>
    inline &&
    `
    display: flex;
    flex-direction: column;

    & > * {
      margin-right: 16px;
    }
  `}

  ${({ oneLine }) =>
    oneLine &&
    `
    display: flex;
    align-items: center;
    gap: 16px;

    .label {
      font-size: 14px;
    }

    .simple-select-async {
      flex-grow: 1;
    }
  `}

  .proactive-messages {
    align-self: flex-start;
  }
`;

export const InnerField = styled.div`
  margin-top: 10px;
  margin-left: 24px;
`;

export const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
`;
export const Form = styled.form`
  max-width: 800px;
  position: relative;

  ${({ disabled }) =>
    disabled &&
    `
    &::after {
      content: '';
      position: absolute;
      top: 30px;
      display: block;
      width: 100%;
      height: calc(100% - 70px);
      background-color: #ffffff94;
    }
  `}
  h2, label[for="bot-name"] {
    font-size: 14px;
  }
`;

export const FormGroupTitle = styled.h3`
  margin: 0 0 6px;
  font-size: 14px;
  color: #172a4b;
  font-weight: bold;
  margin-bottom: 10px;
`;

export const Input = styled.input`
  width: 400px;
  height: 50px;
  padding: 12px 15px;
  outline: none;
  background: #ffffff;
  border: 1px solid #dddddd;
  box-sizing: border-box;
  border-radius: 2px;
`;

export const IconWrapper = styled.div`
  display: flex;
  width: fit-content;
  div {
    display: flex;
    flex-direction: column-reverse;
  }
  & > * {
    margin-right: 16px;
  }

  label {
    font-size: 14px;
    font-weight: normal;
  }
  img {
    max-height: 40px;
    min-width: 50px;
  }
`;

export const InputError = styled.span`
  position: absolute;
  top: 100%;
  right: 0;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin: 4px 0;
  font-size: 12px;
  line-height: 15px;
  color: ${({ theme }) => theme.colors.danger.dark};
`;

export const TitleLabel = styled.span`
  display: inline-flex;
  margin-left: 8px;
  padding: 4px 6px;
  border-radius: 4px;
  background: #ccf1eb;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #00bb99;
`;
