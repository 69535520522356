import axios from 'axios';

import { URL_API } from '../../../constants';
import { getHeaders } from '../../../utils';
import { chatsTypes, configurationsTypes } from '../../../constants/action-types';

export const signInSlack = code => (dispatch, getState) =>
  dispatch({
    type: chatsTypes.SIGN_IN_SLACK,
    payload: axios({
      method: 'POST',
      url: `${URL_API}/api/action-platform/slack/auth`,
      data: {
        code,
        redirectUri: process.env.REACT_APP_SLACK_REDIRECT_URI
      },
      headers: getHeaders(getState)
    })
  });

export const integrateMs = (code, state, path, chatConfiguration) => (dispatch, getState) => {
  const { verificationHash } = state;

  return dispatch({
    type: chatsTypes.INTEGRATE_MS,
    payload: axios({
      method: 'POST',
      url: `${URL_API}/api/action-platform/${path}/auth?code=${code}&redirectUri=${process.env.REACT_APP_DOMAIN}/signup/chat`,
      data: { verificationHash },
      headers: getHeaders(getState),
      chatConfiguration
    })
  });
};

export const signInMs = () => (dispatch, getState) =>
  dispatch({
    type: chatsTypes.SIGN_IN_MS,
    payload: axios({
      method: 'GET',
      url: `${URL_API}/api/action-platform/ms-team/sign-in?redirectUri=${process.env.REACT_APP_DOMAIN}/signup/chat`,
      headers: getHeaders(getState)
    }).then(res => {
      window.location.href = res.data.data.authUrl;
    })
  });

/**
 * Used only for companion apps
 */
export const updateChatConfiguration = (companyId, selectedConfiguration) => (dispatch, getState) =>
  dispatch({
    type: chatsTypes.UPDATE_CHAT_CONFIGURATION,
    payload: axios({
      method: 'POST',
      url: `${URL_API}/api/companies/${companyId}/chat-configuration`,
      data: { selectedConfiguration, _method: 'PATCH' },
      headers: getHeaders(getState)
    })
  });

/**
 * Used only for companion apps
 */
export const uninstallChatConfiguration = (companyId, isDeletingM365) => (dispatch, getState) =>
  dispatch({
    type: chatsTypes.UNINSTALL_CHAT_CONFIGURATION,
    payload: axios({
      method: 'DELETE',
      url: `${URL_API}/api/v1/companies/${companyId}/chat-configuration`,
      data: { is_deleting_m365: isDeletingM365 },
      headers: getHeaders(getState)
    })
  });

export const getConfigurationId = () => (dispatch, getState) =>
  dispatch({
    type: configurationsTypes.GET_CONFIGURATION,
    payload: axios({
      method: 'GET',
      url: `${URL_API}/api/action-platform/ms-team/configuration`,
      headers: getHeaders(getState)
    })
  });

export const getTeams = () => (dispatch, getState) =>
  dispatch({
    type: configurationsTypes.GET_TEAMS,
    payload: axios({
      method: 'GET',
      url: `${URL_API}/api/action-platform/ms-team/configuration/ms-teams`,
      headers: getHeaders(getState)
    })
  });

export const updateSsoEnabled = ssoEnabled => (dispatch, getState) =>
  dispatch({
    type: configurationsTypes.UPDATE_SSO_ENABLED,
    payload: axios({
      method: 'PATCH',
      url: `${URL_API}/api/action-platform/ms-team/configuration/sso-enabled`,
      data: { sso_enabled: ssoEnabled },
      headers: getHeaders(getState)
    })
  });

export const getMsConfiguration = companyId => (dispatch, getState) =>
  dispatch({
    type: configurationsTypes.GET_MS_CONFIGURATION,
    payload: axios({
      method: 'GET',
      url: `${URL_API}/api/v1/integration/ms-graph/configuration`,
      data: { company_id: companyId },
      headers: getHeaders(getState)
    })
  });
