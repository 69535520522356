import { useState } from 'react';

export const useFlows = ({ renameFlow, deleteFlow, history }) => {
  const [isCreateModal, setIsCreateModal] = useState(false);
  const [renameFlowModal, setRenameFlowModal] = useState({ isVisible: false, flowId: null });
  const [deleteFlowModal, setDeleteFlowModal] = useState({ isVisible: false, flowId: null });
  const [flowName, setFlowName] = useState('');

  const openRenameFlowModal = (id, name) => {
    setFlowName(name);
    setRenameFlowModal({ isVisible: !renameFlowModal.isVisible, flowId: id });
  };

  const openDeleteFlowModal = id => {
    setDeleteFlowModal({ isVisible: !renameFlowModal.isVisible, flowId: id });
  };

  const renameFlowHandler = event => {
    event.preventDefault();
    renameFlow(renameFlowModal.flowId, flowName).then(() => {
      setRenameFlowModal({ isVisible: !renameFlowModal.isVisible });
    });
  };

  const deleteFlowHandler = event => {
    event.preventDefault();
    deleteFlow(deleteFlowModal.flowId, flowName).then(() => {
      setDeleteFlowModal({ isVisible: !deleteFlowModal.isVisible });
    });
  };

  const createFlowHandler = event => {
    event.preventDefault();
    history.push(`/dashboard/flows/configure-flow/?name=${flowName}`);
  };

  const duplicateFlowHandler = data => {
    const flow = {
      ...data,
      name: `${data.name} (2)`,
      filterData: JSON.parse(data.filterData)
    };

    history.push({
      pathname: `/dashboard/flows/configure-flow`,
      state: { flow: JSON.stringify(flow) }
    });
  };

  return {
    isCreateModal,
    setIsCreateModal,
    renameFlowModal,
    setRenameFlowModal,
    deleteFlowModal,
    setDeleteFlowModal,
    flowName,
    setFlowName,
    openRenameFlowModal,
    openDeleteFlowModal,
    renameFlowHandler,
    deleteFlowHandler,
    createFlowHandler,
    duplicateFlowHandler
  };
};
