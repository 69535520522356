import React from 'react';
import { connect } from 'react-redux';
import { psaTypes } from 'src/constants';
import { fetch as fetchPlatforms } from '../../redux/modules/integrations/platforms/actions';
import {
  fetch as fetchConfiguration,
  update as updateConfiguration,
  create as createConfiguration,
  clearState as clearConfiguration
} from '../../redux/modules/integrations/configurations/actions';
import { useIntegrationsEditor } from './hook';
import { transformToCamelCase } from '../../utils/data-transformer';

import RefactrForm from './forms/RefactrForm';
import AuvikForm from './forms/AuvikForm';
import ItGlueForm from './forms/ItGlueForm';
import CWAutomateForm from './forms/CWAutomateForm';
import CWManageForm from './forms/CWManageForm';
import BMSForm from './forms/BMSForm';
import AutotaskForm from './forms/AutotaskForm';
import CloudradialForm from './forms/CloudradialForm';
import HaloForm from './forms/HaloForm';
import TimeZestForm from './forms/TimeZestForm';

const formMap = {
  refactr: RefactrForm,
  auvik: AuvikForm,
  itglue: ItGlueForm,
  automate: CWAutomateForm,
  cloudradial: CloudradialForm,
  timezest: TimeZestForm,
  [psaTypes.CONNECTWISE]: CWManageForm,
  [psaTypes.KASEYA_BMS]: BMSForm,
  [psaTypes.AUTOTASK]: AutotaskForm,
  [psaTypes.HALO]: HaloForm
};

const IntegrationsEditor = props => {
  const {
    match,
    fetchPlatforms,
    fetchConfiguration,
    updateConfiguration,
    createConfiguration,
    clearConfiguration,
    configuration,
    status = 'fetch_pending',
    history,
    errorMessage,
    psaType
  } = props;

  const platformCommand = match.params.platform;

  const { selectedPlatform, visibleOverlay, onSubmit } = useIntegrationsEditor({
    platformCommand,
    fetchPlatforms,
    fetchConfiguration,
    updateConfiguration,
    createConfiguration,
    clearConfiguration,
    configuration_id: configuration.id,
    configuration,
    status,
    psaType
  });

  if (!platformCommand) {
    return null;
  }

  return (
    <>
      {React.createElement(formMap[platformCommand] || 'div', {
        platform: selectedPlatform,
        configuration_id: configuration.id,
        formData:
          configuration.configuration &&
          transformToCamelCase(JSON.parse(configuration.configuration)),
        isValid: configuration.is_valid,
        configuration,
        onSubmit,
        visibleOverlay,
        status: status || 'fetch_pending',
        history,
        errorMessage
      })}
    </>
  );
};

IntegrationsEditor.propTypes = {};

function mapStateToProps(state) {
  return {
    configuration: state.integrations.configurations.data,
    errorMessage: state.integrations.configurations.errorMessage,
    status: state.integrations.configurations.infoLoader,
    psaType: state.usersReducer.user.psaType
  };
}

export default connect(mapStateToProps, {
  fetchPlatforms,
  fetchConfiguration,
  updateConfiguration,
  createConfiguration,
  clearConfiguration
})(IntegrationsEditor);
