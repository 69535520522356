import { useMutation, useQuery, useQueryClient } from 'react-query';
import { chatgenieAPI } from 'src/config/api';
import useAuthUser from '../useAuthUser';

const useAdminViews = ({ type }) => {
  const user = useAuthUser();
  const queryClient = useQueryClient();

  const { data, isLoading } = useQuery({
    queryKey: ['adminViews', user.companyId, type],
    queryFn: async () => {
      const res = await chatgenieAPI.adminViews.getAll({ type });
      return res.data.data;
    }
  });

  const { mutateAsync: createAdminView, isLoading: isCreating } = useMutation({
    mutationKey: ['adminViews', user.companyId, type],
    mutationFn: async view => {
      const res = await chatgenieAPI.adminViews.create(type, view);
      return res.data.data;
    },
    onSuccess: data => {
      queryClient.setQueryData(['adminViews', user.companyId, type], oldData => {
        return [...oldData, data];
      });
    }
  });

  const { mutateAsync: updateAdminView, isLoading: isUpdating } = useMutation({
    mutationKey: ['adminViews', user.companyId, type],
    mutationFn: async ({ id, ...viewData }) => {
      const res = await chatgenieAPI.adminViews.update(type, id, viewData);
      return res.data.data;
    },
    onSuccess: data => {
      queryClient.setQueryData(['adminViews', user.companyId, type], oldData => {
        return oldData.map(view => (view.id === data.id ? data : view));
      });
    }
  });

  return {
    adminViews: data || [],
    createAdminView,
    isLoading: isLoading || isCreating,
    updateAdminView,
    isUpdating
  };
};

export default useAdminViews;
