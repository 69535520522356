/* eslint-disable no-param-reassign */
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as Spinner } from 'src/assets/icons/spinner.svg';
import { TextareaContainer } from './style';

function AutoExpandTextarea({ textareaRef, value, isLoading, borderOnHover, ...rest }) {
  const ref = useRef();

  function setHeight() {
    if (!ref.current) {
      return;
    }
    const style = getComputedStyle(ref.current, null);
    const verticalBorders = Math.round(
      parseFloat(style.borderTopWidth) + parseFloat(style.borderBottomWidth)
    );
    const maxHeight = parseFloat(style.maxHeight);
    ref.current.style.height = 'auto';
    const newHeight = ref.current.scrollHeight + verticalBorders;
    ref.current.style.overflowY = newHeight > maxHeight ? 'auto' : 'hidden';
    ref.current.style.height = `${Math.min(newHeight, maxHeight)}px`;
  }

  useEffect(() => {
    if (value) {
      setHeight();
    }
  }, []);

  return (
    <TextareaContainer
      isLoading={isLoading}
      className="expandable-textarea-container"
      borderOnHover={borderOnHover}
    >
      <textarea
        rows="1"
        ref={target => {
          ref.current = target;
          if (textareaRef) {
            textareaRef.current = target;
          }
        }}
        onInput={setHeight}
        value={value}
        {...rest}
      />
      {isLoading && <Spinner width={16} />}
    </TextareaContainer>
  );
}

AutoExpandTextarea.propTypes = {
  value: PropTypes.string.isRequired,
  textareaRef: PropTypes.object,
  isLoading: PropTypes.bool,
  borderOnHover: PropTypes.bool
};
AutoExpandTextarea.defaultProps = {
  textareaRef: null,
  isLoading: false,
  borderOnHover: true
};
export default AutoExpandTextarea;
