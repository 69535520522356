import React from 'react';
import PropTypes from 'prop-types';
import Halo from 'src/pages/SignupTicketing/Halo';
import { FORM_ID } from 'src/pages/SignupTicketing/Halo/config';
import { Route, Switch, withRouter } from 'react-router-dom';
import { transformToCamelCase, transformToSnakeCase } from 'src/utils/data-transformer';
import useAuthUser from 'src/hooks/useAuthUser';
import { messageIntegrations } from '../../../../constants/integrations';
import { overlayChidrens } from '../../overlayTypes';
import MessageForm from '../../../../components/MessageForm';
import SimpleButton from '../../../../components/SimpleButton';
import PageWrapper from '../../../../components/PageWrapper';
import Switcher from '../../../../components/Switcher';
import {
  ToolsStyled,
  ContentStyled,
  FormContainer,
  IntegrationLogo,
  FormContent
} from '../../style';
import styles from '../../styles.module.scss';
import { useControlForm } from '../../hook';
import IntegrationSyncForm from '../IntegrationSyncForm';
import HaloPsaSettingsForm from '../HaloPsaSettingsForm';
import EmbeddedPodSettings from '../EmbeddedPodSettings';

const HaloForm = ({
  onSubmit,
  formData,
  isValid,
  status,
  visibleOverlay,
  configuration,
  errorMessage,
  match
}) => {
  const { onFormSubmit, isActive, setIsActive, canSubmit, defaultValues, onFormValid } =
    useControlForm({
      onSubmit,
      formData,
      configuration,
      defaultValueNormalizer(data) {
        return {
          ...(data.haloConfiguration && transformToCamelCase(data.haloConfiguration))
        };
      },
      submitValueNormalizer(data) {
        return {
          haloConfiguration: transformToSnakeCase(data)
        };
      }
    });

  const { featureFlags } = useAuthUser();
  const isHaloCustomTabsScreenEnabled = featureFlags.includes('halo_custom_tabs_screen');

  return (
    <PageWrapper
      title="HaloPSA"
      isBackButton
      backButtonPath="/dashboard/integrations"
      pageLogo={<img src="/images/halo-logo.png" width="150" alt="Halo logo" />}
      tools={
        <ToolsStyled>
          <Switcher
            id="is-active-flow"
            checked={isActive}
            onChange={() => setIsActive(!isActive)}
            controlText={{ checked: 'on', unchecked: 'off' }}
          />
          <SimpleButton
            isLoader={visibleOverlay}
            isDisabled={!canSubmit}
            type="submit"
            form={FORM_ID}
          >
            Save
          </SimpleButton>
        </ToolsStyled>
      }
      tabs={[
        { id: 1, path: `${match.url}`, name: 'Configuration' },
        { id: 2, path: `${match.url}/sync`, name: 'PSA Sync' },
        { id: 3, path: `${match.url}/settings`, name: 'PSA Settings' },
        ...(isHaloCustomTabsScreenEnabled
          ? [{ id: 4, path: `${match.url}/pods`, name: 'Custom Tabs' }]
          : [])
      ]}
    >
      <ContentStyled>
        <FormContainer>
          <IntegrationLogo src="/images/halo-logo.png" width="150" alt="Halo logo" />
          <Switch>
            <Route path={`${match.path}/sync`} component={IntegrationSyncForm} />
            <Route path={`${match.path}/settings`} component={HaloPsaSettingsForm} />
            {isHaloCustomTabsScreenEnabled && (
              <Route path={`${match.path}/pods`} component={EmbeddedPodSettings} />
            )}
            <Route
              path={`${match.path}`}
              render={() => (
                <FormContent>
                  {errorMessage && <MessageForm typeView="block">{errorMessage}</MessageForm>}
                  {isValid === false && (
                    <MessageForm typeView="block">{messageIntegrations.NOT_VALID}</MessageForm>
                  )}
                  {isValid === true && !errorMessage && configuration.is_active && (
                    <MessageForm typeView="block" typeMsg="success">
                      {messageIntegrations.VALID}
                    </MessageForm>
                  )}
                  <Halo
                    onSubmit={onFormSubmit}
                    defaultValues={defaultValues}
                    onFormValid={onFormValid}
                    isLogoVisible={false}
                    withSyncMembersCheckbox={false}
                  />
                </FormContent>
              )}
            />
          </Switch>
          {visibleOverlay && overlayChidrens[status] && (
            <div className={styles.overlay}>{overlayChidrens[status].icon}</div>
          )}
        </FormContainer>
      </ContentStyled>
    </PageWrapper>
  );
};

HaloForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  formData: PropTypes.objectOf(PropTypes.any),
  isValid: PropTypes.bool,
  status: PropTypes.string,
  visibleOverlay: PropTypes.bool,
  errorMessage: PropTypes.string,
  configuration: PropTypes.any
};

HaloForm.defaultProps = {
  formData: {},
  isValid: undefined,
  visibleOverlay: false,
  status: '',
  errorMessage: '',
  configuration: {}
};

export default withRouter(HaloForm);
