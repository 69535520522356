import styled from 'styled-components';

export const FromStyled = styled.form`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

export const HolderStyled = styled.div`
  position: relative;
  min-height: 100px;
  padding: 110px 20px 110px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  text-align: center;
  background: #ffffff;

  H3 {
    font-size: 18px;
    font-weight: 600;
    letter-spacing: -0.5px;
    color: #333333;
  }

  P {
    margin: 10px 0 5px;
  }

  P,
  BUTTON,
  A {
    font-size: 14px;
    line-height: 24px;
  }
`;

export const WrapContent = styled.span`
  white-space: normal;
  text-transform: ${({ lowercase }) => (lowercase ? 'lowercase' : 'none')};
`;
