import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  padding: 16px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  background: #ffffff;
  min-height: ${({ minHeight }) => (minHeight ? `${minHeight}px` : '90px')};
`;

export const Code = styled.code`
  display: block;
  max-width: 700px;
  width: 100%;
  margin: 10px 0;
  padding: 10px;
  word-break: break-all;
  white-space: pre-wrap;
  background-color: #f5f5f5;
  font-size: 12px;
  color: #24292e;
  border: 1px solid #ddd;
  border-radius: 4px;
`;
