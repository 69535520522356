import styled, { css } from 'styled-components/macro';

export const ButtonStyle = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 44px;
  margin-bottom: 17px;
  padding: 12px;
  border-radius: 4px;
  border: solid 1px ${({ theme }) => theme.colors.gray1};
  white-space: nowrap;
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
  letter-spacing: -0.15px;
  color: #000000;
  transition: border-color 0.2s;
  background-color: #ffffff;
  text-decoration: none;

  &:last-child {
    margin-bottom: 0;
  }

  &:focus,
  &:hover {
    outline: none;
    text-decoration: none;
    border-color: ${({ theme }) => theme.colors.brand.light.hover};
    color: #000000;
  }

  img {
    max-width: 70px;
    object-fit: cover;
    margin-left: 10px;
  }
`;

export const Link = styled.a`
  ${ButtonStyle}
`;

export const Button = styled.button`
  ${ButtonStyle}
`;

export const LoadingOverlay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 105px;

  svg {
    width: 40px;
    height: 40px;
  }
`;

export const InboxSignupWrapper = styled.div`
  margin-bottom: 50px;
`;

export const Title = styled.h4`
  font-size: 16px;
  font-weight: 600;
  margin: 0 0 20px;
  text-align: center;
`;

export const Step = styled.span`
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 50%;
  font-weight: 600;
  background-color: ${({ theme }) => theme.colors.brand.light.hover};
  color: ${({ theme }) => theme.colors.common.white};
  margin: 24px auto;
`;

export const Instruction = styled.p`
  text-align: center;
`;
