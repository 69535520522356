import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import theme from 'src/config/theme';

export const TabList = styled.ul`
  list-style: none;
  margin: 0;
  border-bottom: 1px solid #ddd;
  display: flex;
  width: 100%;
  padding: 0 20px;
  align-items: stretch;
  height: 60px;
`;

export const Tab = styled.li`
  display: flex;
  align-items: stretch;
`;

export const TabRight = styled.li`
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  font-weight: 600;
`;

export const TabLink = styled(NavLink)`
  margin: 0 20px 0 0;
  padding: 0;
  text-decoration: none;
  font-size: 14px;
  font-weight: 600;
  color: ${theme.colors.text.default};
  line-height: calc(60px - 2px);

  &:hover {
    text-decoration: none;
    color: ${theme.colors.brand.light.hover};
  }

  &.active {
    color: ${theme.colors.brand.light.active};
  }

  &:hover::after,
  &.active::after {
    content: '';
    display: block;
    width: 100%;
    height: 2px;
    background-color: ${theme.colors.brand.light.active};
  }
`;
