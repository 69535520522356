import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { FiTag } from 'react-icons/fi';
import { Button } from '@Thread-Magic/jasmine';
import { useMutation } from 'react-query';
import { chatgenieAPI } from 'src/config/api';
import { queryClient } from 'src/config/queryClient';
import queryKeys from 'src/constants/queryKeys';
import useToast from 'src/hooks/useToast';
import { psaTranslate, isAutotask } from 'src/utils/psa';
import * as Styled from '../style';
import Stepper from '../Stepper';
import SelectMultipleBoard from './components/SelectMultipleBoard';
import BoardTypeTable from './components/BoardTypeTable';
import { BoardTypeFieldContext, defaultContextValue } from './boardContext';

export const getMappedBoardMetaData = boards => {
  const mapped = {};
  boards.forEach(el => {
    mapped[el.id] = {
      filled_descriptions: el.filled_descriptions,
      total_types: el.total_types
    };
  });
  return mapped;
};

function EditTypes({ data, onSave }) {
  const [boardTypes, setBoardTypes] = useState(data);
  const [selectedBoards, setSelectedBoards] = useState(data.filter(el => el.filled_descriptions));
  const { toast } = useToast();
  const { mutate, isLoading } = useMutation(chatgenieAPI.updateCategorizationTypes, {
    onSuccess: res => {
      toast.success(`Categorization types set successfully`);
      queryClient.setQueryData([queryKeys.GET_CATEGORIZATION_TYPES], old => {
        return {
          ...old,
          data: res
        };
      });
      onSave();
    },
    onError: err => {
      toast.error(
        `Failed to save categorization types: ${err?.response?.data?.message || err?.message}`
      );
    }
  });

  const boardsMetaData = useMemo(() => getMappedBoardMetaData(data), [data]);

  const steps = [
    {
      type: `${psaTranslate('Type')}s`
    },
    {
      type: 'Review'
    }
  ];

  const filteredBoardTypes = useMemo(() => {
    if (selectedBoards.length === 0) return boardTypes;
    const selectedBoardTypes = [];
    selectedBoards.forEach(board => {
      const boardType = boardTypes.find(el => el.id === board.id);
      selectedBoardTypes.push(boardType);
    });
    return selectedBoardTypes;
  }, [boardTypes, selectedBoards]);

  const handleChange = ({ boardId, id, values }) => {
    setBoardTypes(old =>
      old.map(board =>
        board.id === boardId
          ? {
              ...board,
              types: board.types.map(type => (type.id === id ? { ...type, ...values } : type))
            }
          : board
      )
    );
  };

  const handleSave = () => {
    const normalizedTypes = boardTypes.map(board => board.types).flat();
    mutate(normalizedTypes);
  };

  return (
    <Styled.EditWrapper>
      <h5>Configure Categories for Magic AI</h5>
      <Stepper steps={steps} activeStep={0} />
      <Styled.EditContent>
        <h5>
          <FiTag /> {psaTranslate('Type')}s
        </h5>
        <p>
          Let Magic AI categorize new threads by defining types. Magic AI will automatically
          categorize {psaTranslate('Subtype')}s {!isAutotask() ? 'and Items ' : ''}based on their
          names.
        </p>
        <SelectMultipleBoard
          value={selectedBoards}
          onChange={values => setSelectedBoards(values)}
          boardsMetaData={boardsMetaData}
          label={`Filter ${psaTranslate('Board')}s`}
        />
        <BoardTypeFieldContext.Provider value={defaultContextValue}>
          <BoardTypeTable data={filteredBoardTypes} onChange={handleChange} />
        </BoardTypeFieldContext.Provider>
      </Styled.EditContent>
      <Styled.ActionArea>
        <Button size="large" isLoader={isLoading} onClick={handleSave}>
          Next -&gt;
        </Button>
      </Styled.ActionArea>
    </Styled.EditWrapper>
  );
}

EditTypes.propTypes = {
  onSave: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default EditTypes;
