import axios from 'axios';
import { companyTypes } from 'src/constants/deployment-center';
import { URL_API } from '../../../constants';
import { getHeaders } from '../../../utils';

export const getPartnerClients =
  (props = {}) =>
  (dispatch, getState) => {
    dispatch({
      type: companyTypes.GET_PARTNER_CLIENTS,
      payload: axios({
        method: 'GET',
        url: `${URL_API}/api/v1/deployment-center/clients?${props.queryString || ''}`,
        headers: getHeaders(getState)
      })
    });
  };

export const updatePartnerClientsData = payload => ({
  type: companyTypes.UPDATE_PARTNER_CLIENTS_DATA,
  payload
});
