import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 20px;
`;

export const AgentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const IntentWrapper = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 48px 0;
`;

export const IntentBlock = styled.div`
  width: 656px;
  & > h5 {
    color: ${props => props.theme.colors.gray6};
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  & > div {
    padding: 24px;
    background-color: ${props => props.theme.colors.background.default};
    border-radius: 4px;
    border: 1px solid #ddd;
    & > p {
      font-size: 14px;
      font-weight: 600;
    }
  }
`;

export const IntentFieldGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const IntentField = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  align-items: center;
  & > p {
    color: #000;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    margin: 0;
  }
  & > input {
    max-width: none;
  }
`;

export const IntentArgumentWrapper = styled.div`
  border-radius: 4px;
  border: 1px solid #ddd;
  padding: 10px 20px 20px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  & > button {
    background-color: transparent;
    border: none;
    margin-left: auto;
    margin-bottom: 10px;
    svg {
      color: ${props => props.theme.colors.red1};
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 100%;
`;

export const Body = styled.div`
  flex-grow: 1;
  padding: 20px;
  box-shadow: 0px 14px 50px rgba(0, 0, 0, 0.1);
  z-index: 2;
`;

export const Aside = styled.div`
  position: relative;
  width: 423px;
  z-index: 1;
`;
