import React from 'react';
import PropTypes from 'prop-types';

import SimpleButton from '../SimpleButton';
import LinkButton from '../LinkButton';

import {
  ModalStyled,
  ModalHeaderStyled,
  ModalBodyStyled,
  DescriptionStyled,
  ActionsWrapperStyled
} from './style';

const ConfirmModal = ({
  className,
  isOpen,
  toggleHandler,
  title,
  description,
  modalType,
  submitText,
  cancelText,
  onSubmit,
  isLoading,
  btnFullWidth,
  btnCenter,
  confirmFirst,
  children
}) => (
  <ModalStyled className={className} isOpen={isOpen} toggle={toggleHandler}>
    <ModalHeaderStyled toggle={toggleHandler}>{title}</ModalHeaderStyled>
    <ModalBodyStyled>
      {description && <DescriptionStyled>{description}</DescriptionStyled>}
      {children}
      <ActionsWrapperStyled btnFullWidth={btnFullWidth} btnCenter={btnCenter}>
        {confirmFirst ? (
          <>
            <SimpleButton
              isLoader={isLoading}
              type="button"
              onClick={onSubmit}
              styleMode={modalType}
            >
              {submitText}
            </SimpleButton>
            <LinkButton type="button" onClick={toggleHandler}>
              {cancelText}
            </LinkButton>
          </>
        ) : (
          <>
            <LinkButton type="button" onClick={toggleHandler}>
              {cancelText}
            </LinkButton>
            <SimpleButton
              isLoader={isLoading}
              type="button"
              onClick={onSubmit}
              styleMode={modalType}
            >
              {submitText}
            </SimpleButton>
          </>
        )}
      </ActionsWrapperStyled>
    </ModalBodyStyled>
  </ModalStyled>
);

ConfirmModal.propTypes = {
  className: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  btnFullWidth: PropTypes.bool,
  confirmFirst: PropTypes.bool,
  btnCenter: PropTypes.bool,
  toggleHandler: PropTypes.func.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  modalType: PropTypes.string,
  submitText: PropTypes.string,
  cancelText: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  children: PropTypes.node
};

ConfirmModal.defaultProps = {
  className: 'basic-confirm-modal',
  isLoading: false,
  title: '',
  description: '',
  modalType: '',
  submitText: 'Yes',
  cancelText: 'Cancel',
  children: '',
  btnFullWidth: false,
  confirmFirst: false,
  btnCenter: false
};

export default ConfirmModal;
