import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from '../../style';
import { ruleSections } from '../constants';
import PrioritizationItem from './PrioritizationItem';

function PrioritizationSection({ data, isFirst }) {
  return (
    <Styled.PrioritizationSection>
      <div>
        <h5>{ruleSections[data.type].title}</h5>
        {isFirst && <span>Ignore</span>}
      </div>
      <div>
        {data.rules.map((rule, index) => {
          const isOddRow = index % 2 === 0;
          return (
            <PrioritizationItem
              key={`${rule.rule_id}-${rule.selected_priority?.id}`}
              data={rule}
              isOddRow={isOddRow}
            />
          );
        })}
      </div>
    </Styled.PrioritizationSection>
  );
}

PrioritizationSection.propTypes = {
  data: PropTypes.shape({
    rules: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        text: PropTypes.string,
        action: PropTypes.bool,
        selected_priority: PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string,
          color: PropTypes.string
        })
      })
    ),
    type: PropTypes.string
  }).isRequired,
  isFirst: PropTypes.bool.isRequired
};

export default PrioritizationSection;
