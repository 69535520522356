import styled from 'styled-components';

export const ServiceLevelAgreementFieldContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 20px;
  & > span {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
  }
  & > div {
    flex: 1;
  }
`;
