import styled from 'styled-components/macro';
import ImageAttachmentBlock from 'src/components/ImageAttachmentBlock';

export const Wrapper = styled.div`
  margin-bottom: 28px;
`;

export const SubTitle = styled.h2`
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 22px;
  font-weight: bold;
  color: #000000;
`;

export const ColorsList = styled.div`
  display: flex;
`;

export const Icon = styled(ImageAttachmentBlock)`
  [class*='image-picker-container'] {
    padding: 0;
    border: none;

    &:hover {
      background: none;
    }
  }

  .ReactCrop__canvas-preview {
    border-radius: 100%;
  }
`;

export const ImagePreviewWrapper = styled.div`
  display: flex;
  align-items: center;
  pointer-events: none;

  img {
    border-radius: 100%;
    object-fit: cover;
  }
`;

export const ImagePreview = styled.div`
  width: 52px;
  height: 52px;
  margin-right: 8px;
  padding: 4px;
  border-radius: 100%;
  border: 1px solid ${({ theme }) => theme.colors.brand.light.base};
`;

export const IconAction = styled.span`
  color: ${({ theme }) => theme.colors.brand.light.base};
  transition: color 0.2s;
  font-size: 16px;
  font-weight: 600;

  &:hover {
    color: ${({ theme }) => theme.colors.brand.light.hover};
  }
`;

export const ImageTemplateList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0 0 -8px;
  padding: 10px 15px;
`;

export const Template = styled.li`
  position: relative;

  &:hover::after {
    content: '';
    position: absolute;
    top: -2px;
    left: -2px;
    border: 1px solid ${({ theme }) => theme.colors.brand.light.base};
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    border-radius: 100%;
  }

  display: flex;
  width: 42px;
  height: 42px;
  margin: 0 0 8px;
  margin-right: 8px;
  background: url(${({ url }) => url});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
`;
