import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { chatgenieAPI } from 'src/config/api';
import queryKeys from 'src/constants/queryKeys';
import SyncMembersCheckbox from 'src/pages/SignupTicketing/components/SyncMembersCheckbox';
import { FiInfo } from 'react-icons/fi';
import { Tooltip } from 'src/components/Tooltip/style';
import { ReactComponent as Spinner } from 'src/assets/icons/spinner.svg';
import { FormContent, IntegrationSyncBanner, SyncPreferences, SyncTypeList } from '../../style';
import SyncTypeItem from './SyncTypeItem';
import LogMagicAIActivityCheckbox from './LogMagicAIActivityCheckbox';

export default function IntegrationSyncForm() {
  const { data: syncTypes = [], isLoading } = useQuery(
    [queryKeys.GET_INTEGRATION_SYNC_TYPES],
    chatgenieAPI.integration.getSyncTypes,
    {
      refetchOnMount: true,
      refetchInterval: 1000 * 10,
      refetchOnWindowFocus: true
    }
  );
  const [currentSyncType, setCurrentSyncType] = useState(null);

  useEffect(() => {
    setCurrentSyncType(
      syncTypes?.find(syncType => syncType.created_at && !syncType.finished_at)?.type
    );
  }, [syncTypes]);

  return (
    <FormContent>
      {currentSyncType && (
        <IntegrationSyncBanner>
          <FiInfo />
          Syncing could take a few minutes or up to an hour. You can leave this page and the process
          will continue.
        </IntegrationSyncBanner>
      )}
      <h5>Force sync with the PSA</h5>
      <p>Thread automatically syncs PSA info at 12:00am EST daily.</p>
      <SyncTypeList>
        {syncTypes.map(syncType => (
          <SyncTypeItem
            currentSyncType={currentSyncType}
            setCurrentSyncType={setCurrentSyncType}
            key={syncType.key}
            data={syncType}
          />
        ))}
        {isLoading && <Spinner width={24} />}
      </SyncTypeList>

      <SyncPreferences>
        <h5>Sync preferences</h5>
        <li>
          <p>
            Keep members in sync with PSA <FiInfo data-tip data-for="auto-sync-members" />
            <Tooltip id="auto-sync-members" effect="solid" place="bottom">
              Auto-add & remove members. You still need to invite them to channels
            </Tooltip>
          </p>
          <SyncMembersCheckbox />
        </li>
      </SyncPreferences>

      <SyncPreferences>
        <h5>Log Magic AI activity in PSA</h5>
        <li>
          <p>
            Magic AI will automatically post an internal note to PSA tickets when they are updated.
          </p>
          <LogMagicAIActivityCheckbox />
        </li>
      </SyncPreferences>
    </FormContent>
  );
}
