import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ReactComponent as Spinner } from 'src/assets/icons/spinner.svg';

const WrapperStyled = styled.div`
  position: relative;
  min-height: 100px;
  padding: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  background: #fff;
  ${({ fullHeight }) => fullHeight && `height: 100%;`}
`;

export const LoaderStyled = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 0.5);

  svg {
    width: 40px;
    height: 40px;
  }
`;

const PageHolder = ({ children, isLoading, ...rest }) => (
  <WrapperStyled {...rest}>
    {children}
    {isLoading && (
      <LoaderStyled>
        <Spinner />
      </LoaderStyled>
    )}
  </WrapperStyled>
);

PageHolder.propTypes = {
  children: PropTypes.node,
  isLoading: PropTypes.bool
};

PageHolder.defaultProps = {
  children: '',
  isLoading: false
};

export default PageHolder;
