import styled from 'styled-components';
import { rgba } from 'polished';

export const Alert = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 10px 16px;
  margin-bottom: 20px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;

  svg {
    width: 16px;
    height: 16px;
  }

  &.alert-type-warning {
    background-color: ${({ theme }) => rgba(theme.colors.red1, 0.1)};
    color: ${({ theme }) => theme.colors.red1};
  }

  &.alert-type-info {
    background-color: ${({ theme }) => rgba(theme.colors.blue1, 0.1)};
    color: ${({ theme }) => theme.colors.blue1};

    svg path {
      fill: ${({ theme }) => theme.colors.blue1};
    }
  }

  &.alert-type-success {
    background-color: ${({ theme }) => rgba(theme.colors.green3, 0.1)};
    color: ${({ theme }) => theme.colors.green3};
  }
`;
