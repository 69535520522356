import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery, useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { queryClient } from 'src/config/queryClient';
import queryKeys from 'src/constants/queryKeys';
import { chatgenieAPI } from 'src/config/api';
import { Button } from '@Thread-Magic/jasmine';
import useToast from 'src/hooks/useToast';
import { ReactComponent as Figure } from 'src/assets/images/teams-placeholder.svg';
import { ReactComponent as Spinner } from 'src/assets/icons/spinner.svg';
import { ReactComponent as TrashIcon } from 'src/assets/icons/trash.svg';
import { ReactComponent as EditIcon } from 'src/assets/icons/edit.svg';
import { ReactComponent as CopyIcon } from 'src/assets/icons/copy.svg';
import ConfirmModal from 'src/components/ConfirmModal';
import Search, { useSearch } from 'src/components/Search';
import CreatedModal from './popups/CreatedModal';
import UpdatedModal from './popups/UpdatedModal';

import * as Styled from './style';

export const Loader = ({ size }) => (
  <Styled.ModalOverlay>
    <Spinner width={size} height={size} />
  </Styled.ModalOverlay>
);

const TeamsApps = ({ companyId, location }) => {
  const history = useHistory();
  const [isCreatedModal, setIsCreatedModal] = useState(false);
  const [isUpdatedModal, setIsUpdatedModal] = useState(false);
  const [deleteModal, setIsDeleteModal] = useState({ id: null, isVisible: false });
  const [fetchManifest] = useState({ appId: null, isLoading: false });
  const { debouncedSearchTerm, searchHandler, searchValue } = useSearch();

  const { toast } = useToast();
  const {
    data: apps = [],
    isLoading,
    isError
  } = useQuery(
    [queryKeys.GET_TEAMS_APPS, companyId, debouncedSearchTerm],
    () => chatgenieAPI.getTeamsApps({ companyId, query: searchValue }),
    {
      onError: error => {
        toast.error(error.message);
      }
    }
  );

  const mutation = useMutation(
    data => chatgenieAPI.deleteTeamsApp({ teamsAppId: data.id, companyId }),
    {
      onSuccess: (data, variables) => {
        queryClient.setQueryData([queryKeys.GET_TEAMS_APPS, companyId, debouncedSearchTerm], apps =>
          apps.filter(item => item.id !== variables.id)
        );
        toast.success('Deleted successfully');
      },
      onError: error => {
        toast.error(error.response.data?.message || error.message);
      }
    }
  );

  const goToBuilder = ({ app }) => {
    history.push({
      pathname: `/dashboard/messenger/settings/apps/teams-app-builder/${app.id}`,
      state: { appData: app }
    });
  };

  const duplicate = ({ app }) => {
    history.push({
      pathname: '/dashboard/messenger/settings/apps/teams-app-builder',
      state: { appData: { ...app, id: null, title: `${app.title} (duplicate)` } }
    });
  };

  useEffect(() => {
    if (location.state) {
      if (location.state.appEvent === 'created') setIsCreatedModal(true);
      if (location.state.appEvent === 'updated') setIsUpdatedModal(true);
      history.replace({});
    }
  }, [location, history]);

  return apps.length || isLoading || debouncedSearchTerm ? (
    <Styled.LayoutFullPage>
      <Styled.Header>
        <Styled.Content>
          <h3>Your own Teams Apps</h3>
          <p>
            Service your customers with a custom branded support landing page on your website or in
            Microsoft Teams.
          </p>
        </Styled.Content>
        <Button
          type="submit"
          size="large"
          onClick={() => history.push('/dashboard/messenger/settings/apps/teams-app-builder')}
        >
          Create New
        </Button>
      </Styled.Header>
      <Styled.SearchWrapper>
        <Search value={searchValue} setValue={searchHandler} />
      </Styled.SearchWrapper>
      <Styled.List>
        <Styled.AppsHeader>
          <td>Name</td>
        </Styled.AppsHeader>
        {!isLoading ? (
          <>
            {apps.map(item => (
              <Styled.App key={item.id}>
                {((mutation.isLoading && mutation.variables.id === item.id) ||
                  (fetchManifest.appId === item.id && fetchManifest.isLoading)) && (
                  <Loader size={30} />
                )}
                <Styled.Preview>
                  <Styled.Logo src={item.logo} />
                </Styled.Preview>
                <Styled.AppContent>
                  <h4>{item.title}</h4>
                  <p>{item.shortDescription}</p>
                </Styled.AppContent>
                <Styled.Actions>
                  <Styled.Action
                    type="button"
                    isStrokeStyle
                    onClick={() => goToBuilder({ app: item })}
                  >
                    <EditIcon />
                    Edit
                  </Styled.Action>
                  <Styled.Action type="button" onClick={() => duplicate({ app: item })}>
                    <CopyIcon />
                    Duplicate
                  </Styled.Action>
                  <Styled.Action
                    danger
                    type="button"
                    isStrokeStyle
                    onClick={() => setIsDeleteModal({ isVisible: true, id: item.id })}
                  >
                    <TrashIcon />
                    Delete
                  </Styled.Action>
                </Styled.Actions>
              </Styled.App>
            ))}
          </>
        ) : (
          <Styled.LayoutFullPage>
            <Loader size={40} />
          </Styled.LayoutFullPage>
        )}
      </Styled.List>
      {!apps.length && !isError && !isLoading && debouncedSearchTerm && (
        <Styled.EmptyPlaceholder>There is no data to display</Styled.EmptyPlaceholder>
      )}
      <CreatedModal
        isOpen={isCreatedModal}
        onClose={() => setIsCreatedModal(false)}
        state={location.state}
      />
      <UpdatedModal
        isOpen={isUpdatedModal}
        onClose={() => setIsUpdatedModal(false)}
        state={location.state}
      />
      <ConfirmModal
        isOpen={deleteModal.isVisible}
        toggleHandler={() => setIsDeleteModal({ isVisible: false })}
        title="Do you really want to delete the app?"
        modalType="error"
        submitText="Yes"
        onSubmit={() => {
          mutation.mutate({ id: deleteModal.id });
          setIsDeleteModal({ isVisible: false });
        }}
      />
    </Styled.LayoutFullPage>
  ) : (
    <Styled.LayoutCompact>
      <Figure />
      <Styled.Title>Your own Teams App</Styled.Title>
      <Styled.Description>
        Service your customers with a custom branded support landing page on your website or in
        Microsoft Teams.
      </Styled.Description>
      <Button
        type="submit"
        size="large"
        onClick={() => history.push('/dashboard/messenger/settings/apps/teams-app-builder')}
      >
        Create App
      </Button>
    </Styled.LayoutCompact>
  );
};

TeamsApps.propTypes = {
  companyId: PropTypes.number.isRequired,
  location: PropTypes.objectOf(PropTypes.any)
};

TeamsApps.defaultProps = {
  location: {
    state: {}
  }
};

Loader.propTypes = {
  size: PropTypes.number.isRequired
};

export default React.memo(TeamsApps);
