import { createContext } from 'react';

export const defaultContextValue = {
  showTableHeader: true,
  showAccordionHeader: true
};

// It will be used to keep track of current focused field of board type
// in order to prevent prop drilling
export const BoardTypeFieldContext = createContext({});
