import React from 'react';
import PropTypes from 'prop-types';
import MessengerPreview from 'src/components/MessengerPreview';
import { chatgenieAPI } from 'src/config/api';
import queryKeys from 'src/constants/queryKeys';
import { useQuery } from 'react-query';
import * as Styled from '../style';

const normalizeDesignSettings = data => ({
  logo: { url: data.logo?.path },
  trayIcon: { url: data.tray_icon?.path },
  botIcon: { url: data.bot_icon?.path },
  primaryColor: data.primary_color,
  botName: data.bot_name
});

export default function MessengerWidget({ appId, supportType, data, shouldUseSavedData = true }) {
  const { data: designSettings } = useQuery(
    [queryKeys.GET_MESSENGER_DESIGN],
    () => chatgenieAPI.getMessengerDesign(),
    {
      enabled: shouldUseSavedData
    }
  );
  const normalizedDesignData = shouldUseSavedData
    ? designSettings && normalizeDesignSettings(designSettings)
    : data;

  return (
    <Styled.MessengerWrapper>
      <Styled.ChatBackground />
      {normalizedDesignData && (
        <MessengerPreview
          appId={appId}
          image={normalizedDesignData.logo?.url}
          icon={normalizedDesignData.trayIcon?.url}
          botImage={normalizedDesignData.botIcon?.url}
          primaryColor={normalizedDesignData.primaryColor}
          botName={normalizedDesignData.botName}
          supportType={supportType}
        />
      )}
    </Styled.MessengerWrapper>
  );
}

MessengerWidget.propTypes = {
  appId: PropTypes.string.isRequired,
  supportType: PropTypes.string,
  shouldUseSavedData: PropTypes.bool,
  data: PropTypes.shape({})
};

MessengerWidget.defaultProps = {
  shouldUseSavedData: true,
  supportType: 'live',
  data: null
};
