import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as WarningIcon } from 'src/assets/icons/message-warning.svg';
import { ReactComponent as SuccessIcon } from 'src/assets/icons/message-success.svg';
import { ReactComponent as InfoIcon } from 'src/assets/icons/bell.svg';
import * as Styled from './Alert.styles';

const Alert = ({ type = 'info', children }) => (
  <Styled.Alert className={`alert-type-${type}`}>
    {type === 'warning' && <WarningIcon />}
    {type === 'success' && <SuccessIcon />}
    {type === 'info' && <InfoIcon />}
    {children}
  </Styled.Alert>
);

Alert.defaultProps = {
  type: 'info',
  children: []
};

Alert.propTypes = {
  type: PropTypes.oneOf(['info', 'warning', 'success']),
  children: PropTypes.node
};

export default Alert;
