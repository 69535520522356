import { gaEvent } from '../../components/GoogleAnalytics';
import { flowsTypes, actionStatuses } from '../../constants/action-types';
import { getUrlParam } from '../../utils';

export default () => next => action => {
  switch (action.type) {
    case `${flowsTypes.CREATE_FLOW}${actionStatuses.FULFILLED}`:
      gaEvent({
        category: 'Flow',
        action: getUrlParam('template')
          ? `${flowsTypes.CREATE_FLOW_TEMPLATE}${actionStatuses.FULFILLED}`
          : action.type
      });
      break;
    default:
      gaEvent({
        category: 'ChatGenie App',
        action: action.type
      });
      break;
  }

  next(action);
};
