export const teamsAppNormalizer = data => ({
  id: data.id,
  icon: data.tab_icon,
  logo: data.app_icon,
  title: data.name,
  appName: data.app_name,
  externalAppId: data.external_id,
  description: data.description,
  shortDescription: data.short_description,
  featuredImage: data.service_page.featured_image,
  isChatPlacementSwapped: data.service_page.is_chat_placement_swapped,
  servicePageId: data.service_page.id,
  blocks: data.service_page.blocks.map(block => ({
    id: block.id,
    order: block.block_order,
    blockType: block.block_type,
    ...block.properties
  })),
  tabs: data.tabs.map(tab => ({
    id: tab.entity_id,
    key: tab.entity_id,
    name: tab.name,
    url: tab.content_url
  }))
});

export const teamsAppsNormalizer = apps => apps.map(app => teamsAppNormalizer(app)).reverse();
