import React from 'react';
import { Checkbox, SelectAsync, SelectAsyncPaginate } from '@Thread-Magic/jasmine';
import PropTypes from 'prop-types';
import { chatgenieAPI } from 'src/config/api';
import { queryClient } from 'src/config/queryClient';
import queryKeys from 'src/constants/queryKeys';
import { ReactComponent as ExmplamationMark } from 'src/assets/icons/exclamation-mark.svg';
import { components } from 'react-select';
import { FiCheck } from 'react-icons/fi';
import { getPriorityColor } from 'src/utils';
import * as Styled from '../../style';
import { getSortedPriorities } from '../utils';
import usePrioritizationConfig from '../hooks/usePrioritizationConfig';

export const getOptions = (query, page) => {
  const cachedOptions = queryClient.getQueryData([queryKeys.GET_PRIORITIES]);
  const isInitialRequest = !query && page === 1;
  if (isInitialRequest && cachedOptions) {
    return Promise.resolve(getSortedPriorities(cachedOptions));
  }
  return chatgenieAPI.getPriorities({ query, page, normalizer: res => res.data }).then(res => {
    if (isInitialRequest) {
      queryClient.setQueryData([queryKeys.GET_PRIORITIES], res);
    }
    return getSortedPriorities(res);
  });
};

const CustomOption = props => {
  const { data: option, isSelected } = props;
  return (
    <components.Option {...props}>
      <Styled.PrioritySelectValue>
        {option && <ExmplamationMark fill={getPriorityColor(option?.color)} />}
        {option?.name}
      </Styled.PrioritySelectValue>
      {isSelected && <FiCheck color="#00BB99" size={16} />}
    </components.Option>
  );
};

CustomOption.propTypes = {
  data: PropTypes.shape({
    color: PropTypes.string,
    name: PropTypes.string
  }).isRequired,
  isSelected: PropTypes.bool.isRequired
};

const SingleValue = ({ ...props }) => {
  const {
    selectProps: { value: data }
  } = props;
  return (
    <components.SingleValue {...props}>
      <Styled.PrioritySelectValue>
        {data && <ExmplamationMark fill={getPriorityColor(data?.color)} />}
        {data?.name}
      </Styled.PrioritySelectValue>
    </components.SingleValue>
  );
};

SingleValue.propTypes = {
  selectProps: PropTypes.shape({
    value: PropTypes.shape({
      color: PropTypes.string,
      name: PropTypes.string
    })
  }).isRequired
};

export const getCustomStyles = (extendedStyles, { noBorder } = {}) => ({
  ...extendedStyles,
  control: state => ({
    ...(noBorder && { border: 'none' }),
    ...(extendedStyles?.control && extendedStyles.control(state)),
    ...(state.isDisabled && { opacity: 0.5 })
  }),
  menuList: state => ({
    padding: '4px',
    ...(extendedStyles?.menuList && extendedStyles.menuList(state))
  }),
  singleValue: state => ({
    color: '#000',
    ...(extendedStyles?.singleValue && extendedStyles.singleValue(state))
  }),
  option: state => ({
    margin: '4px 0',
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    ...(extendedStyles?.option && extendedStyles.option(state))
  })
});

function PrioritySelectField({
  data,
  value,
  onChange,
  marginTop,
  width,
  noBorder,
  customStyles,
  showIgnoreCheckbox,
  onIgnoreChange,
  ...rest
}) {
  const { selectedSla } = usePrioritizationConfig();

  return (
    <Styled.PriorityField marginTop={marginTop} width={width}>
      {data && (
        <>
          {data.icon}
          <p>{data.description}</p>
        </>
      )}
      {showIgnoreCheckbox && (
        <Styled.IgnoreFieldRow>
          <Checkbox
            id={`ignore-${data.id}`}
            onChange={e => {
              const { checked } = e.target;
              onIgnoreChange(checked);
            }}
            checked={data.ignore}
          />
          Ignore
        </Styled.IgnoreFieldRow>
      )}
      {selectedSla ? (
        <SelectAsync
          placeholder="Select priority..."
          value={value}
          onChange={onChange}
          getOptionValue={option => option?.id}
          options={query => chatgenieAPI.getPrioritiesBySla({ query, slaId: selectedSla.id })}
          components={{ Option: CustomOption, SingleValue }}
          menuPortalTarget={document.body}
          customStyles={getCustomStyles(customStyles, { noBorder })}
          key={selectedSla.id}
          {...rest}
        />
      ) : (
        <SelectAsyncPaginate
          placeholder="Select priority..."
          value={value}
          onChange={onChange}
          getOptionValue={option => option?.id}
          options={getOptions}
          components={{ Option: CustomOption, SingleValue }}
          menuPortalTarget={document.body}
          customStyles={getCustomStyles(customStyles, { noBorder })}
          {...rest}
        />
      )}
    </Styled.PriorityField>
  );
}

PrioritySelectField.propTypes = {
  data: PropTypes.shape({
    icon: PropTypes.element,
    description: PropTypes.string,
    id: PropTypes.number,
    ignore: PropTypes.bool
  }),
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any,
  marginTop: PropTypes.number,
  noBorder: PropTypes.bool,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  customStyles: PropTypes.object,
  showIgnoreCheckbox: PropTypes.bool,
  onIgnoreChange: PropTypes.func
};

PrioritySelectField.defaultProps = {
  value: null,
  data: null,
  marginTop: 0,
  width: null,
  noBorder: false,
  customStyles: null,
  showIgnoreCheckbox: false,
  onIgnoreChange: () => {}
};

export default PrioritySelectField;
