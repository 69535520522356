import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@Thread-Magic/jasmine';
import { useMutation } from 'react-query';
import useToast from 'src/hooks/useToast';
import useAuthUser from 'src/hooks/useAuthUser';
import { getInitials } from 'src/utils';
import { ReactComponent as MagicIcon } from 'src/assets/icons/magic-icon-with-bg.svg';
import FadeInOutContainer from 'src/components/FadeInOutContainer';
import magicAnimated from 'src/assets/icons/magic-animated.gif';
import * as Styled from './style';

const animationContainerStyles = {
  display: 'flex',
  justifyContent: 'center',
  paddingTop: '32px',
  position: 'absolute',
  width: '100%',
  left: 0,
  top: 0
};

const CopilotTestChat = ({
  title,
  description,
  onSend,
  firstMessage,
  askAnotherMessage,
  newMessageNormalizer,
  isAnimating
}) => {
  const authUser = useAuthUser();
  const { toast } = useToast();
  const [message, setMessage] = useState('');
  const scrollElementRef = useRef(null);

  const initialMessage = {
    type: 'copilot',
    name: 'Magic AI',
    body: firstMessage
  };

  const [messages, setMessages] = useState([initialMessage]);

  const testCopilot = useMutation(onSend, {
    onSuccess: res => {
      const copilotMessage = newMessageNormalizer(res);

      setMessages(old => [...old, copilotMessage]);

      // Prompt user to ask another question after 2 seconds
      setTimeout(() => {
        setMessages(old => [
          ...old,
          {
            type: 'copilot',
            name: 'Magic AI',
            body: askAnotherMessage
          }
        ]);
      }, 2000);
    },
    onError: error => {
      toast.error(`Failed to send message: ${error?.response?.data?.message || error?.message}`);
    }
  });

  const sendMessage = event => {
    if (!message || testCopilot.isLoading || isAnimating) return;

    if (event.key === 'Enter' || !event.key) {
      setMessages([
        ...messages,
        {
          type: 'user',
          name: authUser.fullname,
          body: message
        }
      ]);
    }
  };

  const scrollToBottom = () =>
    scrollElementRef.current.scrollTo(0, scrollElementRef.current.scrollHeight);

  useEffect(() => {
    if (messages.length && messages[messages.length - 1]?.type === 'user') {
      testCopilot.mutate({ message });
      setMessage('');
    }

    if (scrollElementRef.current) {
      scrollToBottom();
    }
  }, [messages]);

  useEffect(() => {
    if (scrollElementRef.current && testCopilot.isLoading) {
      scrollToBottom();
    }
  }, [testCopilot.isLoading]);

  useEffect(() => {
    if (!isAnimating) {
      setMessages([initialMessage]);
    }
  }, [isAnimating]);

  return (
    <Styled.Aside>
      <Styled.AsideTitle>{title}</Styled.AsideTitle>
      <Styled.AsideDescription>{description}</Styled.AsideDescription>
      <Styled.Messenger>
        <FadeInOutContainer fullHeight visible={!isAnimating}>
          <Styled.MessengerBody ref={scrollElementRef}>
            {messages.map((message, index) => (
              <Styled.Message
                // eslint-disable-next-line react/no-array-index-key
                key={`${message.body}-${index}`}
                className={`message-${message.type}`}
              >
                <Styled.Avatar type={message.type}>
                  {message.type === 'copilot' ? (
                    <MagicIcon width="32" height="32" />
                  ) : (
                    getInitials(message.name)
                  )}
                </Styled.Avatar>
                <Styled.MessageContent>
                  <Styled.MessageName>{message.name}</Styled.MessageName>
                  {message.body}
                </Styled.MessageContent>
              </Styled.Message>
            ))}
            {testCopilot.isLoading && (
              <Styled.TypingIndicator>
                <Styled.CopilotAvatar>
                  <MagicIcon width="18" height="18" />
                </Styled.CopilotAvatar>
                <Styled.TypingText>Magic is&nbsp;typing...</Styled.TypingText>
              </Styled.TypingIndicator>
            )}
          </Styled.MessengerBody>
        </FadeInOutContainer>
        <FadeInOutContainer visible={isAnimating} style={animationContainerStyles}>
          <img src={magicAnimated} alt="Magic" width="64" height="64" />
        </FadeInOutContainer>
        <Styled.MessengerFooter>
          <input
            type="text"
            value={message}
            placeholder="Write your test request or issue here"
            onChange={event => setMessage(event.target.value)}
            onKeyDown={sendMessage}
          />
          <Button
            size="small"
            type="button"
            onClick={sendMessage}
            disabled={!message || testCopilot.isLoading || isAnimating}
          >
            Send
          </Button>
        </Styled.MessengerFooter>
      </Styled.Messenger>
    </Styled.Aside>
  );
};

CopilotTestChat.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  firstMessage: PropTypes.string.isRequired,
  askAnotherMessage: PropTypes.string.isRequired,
  newMessageNormalizer: PropTypes.func.isRequired,
  onSend: PropTypes.func.isRequired,
  isAnimating: PropTypes.bool
};

CopilotTestChat.defaultProps = {
  isAnimating: false
};

export default CopilotTestChat;
