import styled from 'styled-components';

export const Wrapper = styled.div`
  input {
    opacity: 0;
    width: 1px;
    height: 1px;
    margin: 0;
  }
`;

export const Label = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  & + & {
    margin-top: 13px;
  }

  .dot {
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-right: 9px;
    border-radius: 50%;
    border: 1px solid #ddd;
    background: #efeeea;
    box-shadow: inset 0 0 0 0 transparent;
    transition: 0.2s all;
  }

  input:checked + .dot {
    background: #fff;
    border-color: #00bb99;
    box-shadow: inset 0 0 0 3px #00bb99, 0 0 0 1px #00bb99;
  }
`;
