import React, { Fragment, useState, useCallback, useEffect, useMemo } from 'react';

import useMembers from 'src/hooks/useMembers';
import useAuthUser from 'src/hooks/useAuthUser';

import { useLocation, useRouteMatch } from 'react-router-dom';
import { memberStateOptions } from 'src/constants/member-states';
import { usersColumnsData } from './config';

import PageWrapper from '../../components/PageWrapper';
import SimpleButton from '../../components/SimpleButton';
import ActionModal from '../../components/ActionModal';
import Table from '../../components/Table';
import InviteMember from '../../components/InviteMember';

import { FromStyled } from './styles';
import EntityNotificationSelect from './EntityNotificationSelect';
import RoleSelect from './RoleSelect';

const Members = () => {
  const match = useRouteMatch();
  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);
  const defaultState = urlParams.has('filter-state')
    ? urlParams.get('filter-state')
    : memberStateOptions[0].value;
  const [isInviteMemberModalOpen, setIsInviteMemberModalOpen] = useState(false);
  // Initial should match because Table prop getData will update on load
  const [queryString, setQueryString] = useState(`?page=1&filter-state=${defaultState}`);
  const { members, isLoading, updateMember, error } = useMembers({
    queryString
  });
  const handleInviteMemberClose = useCallback(() => {
    setIsInviteMemberModalOpen(false);
  }, []);
  const handleQueryStringChanged = useCallback(({ queryString }) => {
    setQueryString(queryString);
  }, []);

  const pageTabs = useMemo(() => {
    return memberStateOptions.map((option, key) => ({
      id: key + 1,
      path: match.url,
      name: option.label,
      search: `?filter-state=${option.value}`,
      isQuery: true
    }));
  }, [match.url]);

  useEffect(() => {
    const currentParams = new URLSearchParams(queryString);

    // Because state depends on tab changes we need to update current
    // query parameter with a new state and preserve the rest
    if (urlParams.has('filter-state')) {
      currentParams.set('filter-state', urlParams.get('filter-state'));
      setQueryString(`?${currentParams.toString()}`);
    }
  }, [urlParams]);

  const userPsaType = useAuthUser().psaType;

  return (
    <Fragment>
      <PageWrapper title="Members" tabs={pageTabs}>
        <Table
          getData={handleQueryStringChanged}
          isLoading={isLoading}
          error={error?.response?.data?.message}
          columns={usersColumnsData({
            EntityNotificationSelect,
            RoleSelect,
            updateMember,
            userPsaType
          })}
          initialFilters={{ state: memberStateOptions[0].value }}
          rows={members}
        />
      </PageWrapper>
      <ActionModal
        isOpen={false}
        toggleHandler={() => {}}
        title="Delete Flow"
        description="Are you sure you want to delete the Flow?"
      >
        <FromStyled id="delete-flow" onSubmit={() => {}}>
          <SimpleButton type="submit" form="delete-flow" styleMode="error">
            Delete Flow
          </SimpleButton>
        </FromStyled>
      </ActionModal>
      <InviteMember isOpen={isInviteMemberModalOpen} onClose={handleInviteMemberClose} />
    </Fragment>
  );
};

export default Members;
