import React from 'react';
import * as yup from 'yup';

const helpDocsLink =
  'https://docs.getthread.com/article/53ai1f9qnb-creating-a-connect-wise-manage-api-user';
const fields = {
  HOST: {
    name: 'host',
    id: 'host',
    label: 'Host',
    placeholder: 'e.g. https://na.myconnectwise.net',
    fieldKey: 'host',
    helpTooltip: 'Same URL you use to login into the portal'
  },
  COMPANY_NAME: {
    name: 'companyName',
    id: 'companyName',
    label: 'My Company ID',
    placeholder: 'e.g. company123',
    fieldKey: 'company_name',
    helpTooltip: 'Same as the company name you use to login into the portal'
  },
  PUBLIC_KEY: {
    name: 'publicKey',
    id: 'publicKey',
    label: 'Public key',
    placeholder: 'e.g. CoPw0lLoCZNtWpiL',
    fieldKey: 'public_key',
    helpTooltip: (
      <>
        Follow{' '}
        <a href={helpDocsLink} rel="noopener noreferrer" target="_blank">
          this documentation
        </a>{' '}
        for more details
      </>
    )
  },
  PRIVATE_KEY: {
    name: 'privateKey',
    id: 'privateKey',
    label: 'Private key',
    type: 'password',
    placeholder: 'e.g. ouU40lLoCZNtWpiT',
    fieldKey: 'private_key',
    helpTooltip: (
      <>
        Follow{' '}
        <a href={helpDocsLink} rel="noopener noreferrer" target="_blank">
          this documentation
        </a>{' '}
        for more details
      </>
    )
  }
};
const schema = yup
  .object({
    [fields.HOST.name]: yup.string().required().label(fields.HOST.label).default(''),
    [fields.COMPANY_NAME.name]: yup
      .string()
      .required()
      .label(fields.COMPANY_NAME.label)
      .default(''),
    [fields.PUBLIC_KEY.name]: yup.string().required().label(fields.PUBLIC_KEY.label).default(''),
    [fields.PRIVATE_KEY.name]: yup.string().required().label(fields.PRIVATE_KEY.label).default('')
  })
  .required();

export const FORM_ID = 'connectwise-form';

export default {
  key: 'CONNECTWISE',
  fields,
  schema,
  helpDocsLink,
  logo: { src: '/images/connectwise-logo.png', width: 123, alt: 'Connectwise logo' },
  formId: FORM_ID
};
