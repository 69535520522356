import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import { useQuery } from 'react-query';
import queryKeys from 'src/constants/queryKeys';
import { getContactsByCompany, inviteContact } from 'src/redux/modules/contacts/actions';
import MessageForm from 'src/components/MessageForm';
import AsyncSelect from 'src/components/SimpleSelect/async';
import { InputStyled } from 'src/components/FormInputWithLabel/style';
import { chatgenieAPI } from 'src/config/api';
import useToast from 'src/hooks/useToast';
import { INVITATION_TYPES } from 'src/constants';
import { ReactComponent as WarningIcon } from 'src/assets/icons/warning-mini.svg';
import { ReactComponent as Spinner } from 'src/assets/icons/spinner.svg';
import { parseJwt } from 'src/utils';
import * as Styled from './style';

const TOKEN_EXPIRATION_TIME = 15 * 60; // 15 minutes - need for circular progress

const getReturnValues = countDown => {
  const minutes = Math.floor(countDown / 60);
  const seconds = countDown - minutes * 60;
  const isOver = countDown === 0;

  return [minutes, seconds < 10 ? `0${seconds}` : seconds, isOver];
};

const Invitation = ({
  isFetchInviteContact,
  getContactsByCompany,
  errorMessage,
  match,
  location,
  inviteContact,
  appId,
  history,
  companyId
}) => {
  const [contact, setContact] = useState({});
  const [isCopied, setIsCopied] = useState(false);
  const [isLinkLoading, setIsLinkLoading] = useState(false);
  const { toast } = useToast();
  const [magicLink, setMagicLink] = useState('');
  const [expirationTime, setExpirationTime] = useState();
  const [countDown, setCountDown] = useState(
    Math.ceil((expirationTime - new Date().getTime()) / 1000)
  );
  const { data, isLoading } = useQuery([queryKeys.GET_COMPANY, companyId], () =>
    chatgenieAPI.getCompany({ id: companyId })
  );

  const [minutes, seconds, isOver] = getReturnValues(countDown);
  const percentage = 1 - countDown / TOKEN_EXPIRATION_TIME;
  const teamsAppId = location.state?.teamsAppId || '';
  const teamsAppName = encodeURI(location.state?.teamsAppName || '');
  const inviteType = location.state?.inviteType;
  const selectedPlatform = data?.chat_platform?.platform;

  const inviteHandler = () => {
    inviteContact({
      contactId: contact.id,
      data: {
        invite_type: inviteType,
        invited_company_id: match.params.company,
        ...(inviteType === INVITATION_TYPES.MS_TEAMS && {
          ms_teams_app_id: teamsAppId,
          ms_teams_app_name: teamsAppName
        })
      }
    })
      .then(() => {
        setContact('');
      })
      .catch(err => {
        toast.error(`Error while inviting: ${err.message}`);
      });
  };

  const promiseOptionsUsers = like =>
    getContactsByCompany({
      companyId: match.params.company,
      params: { queryString: `?filter-email=${like || ''}` }
    }).then(resolve => {
      const { data } = resolve.action.payload.data;
      const usersMap = data.map(item => ({ id: item.id, value: item.email, name: item.email }));
      return usersMap;
    });
  const handleCopy = () => {
    navigator.clipboard.writeText(magicLink).then(
      () => {
        setIsCopied(true);
      },
      err => {
        console.error('Async: Could not copy text: ', err);
      }
    );
  };

  const generateMagicLink = async () => {
    try {
      setIsLinkLoading(true);
      const res = await chatgenieAPI.getMagicLinkToken(match.params.company);
      const link = `${process.env.REACT_APP_MESSENGER_URL}/support-bot?appId=${appId}${
        teamsAppName ? `&teamsAppName=${teamsAppName}` : ''
      }${teamsAppId ? `&teamsAppId=${teamsAppId}` : ''}&type=${inviteType}&token=${
        res.data.data.token
      }`;
      const decodedToken = parseJwt(res.data.data.token);
      const expireTime = new Date(decodedToken.exp * 1000).getTime();
      setExpirationTime(expireTime);
      setCountDown(Math.ceil((expireTime - new Date().getTime()) / 1000));
      setMagicLink(link);
      setIsLinkLoading(false);
      setIsCopied(false);
      return link;
    } catch (err) {
      toast.error(`Error while generating link: ${err.message}`);
      setIsLinkLoading(false);
      return null;
    }
  };

  useEffect(() => {
    if (!expirationTime) {
      return () => {};
    }
    const interval = setInterval(() => {
      setCountDown(() => {
        const newValue = Math.ceil((expirationTime - new Date().getTime()) / 1000);
        if (newValue <= 0) {
          clearInterval(interval);
          return 0;
        }
        return newValue;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [expirationTime]);

  useEffect(() => {
    const handleReCalculateCountdown = () => {
      setCountDown(() => {
        const newValue = Math.ceil((expirationTime - new Date().getTime()) / 1000);
        if (newValue <= 0) {
          return 0;
        }
        return newValue;
      });
    };
    window.addEventListener('focus', handleReCalculateCountdown);
    return () => {
      window.removeEventListener('focus', handleReCalculateCountdown);
    };
  }, [expirationTime]);

  useEffect(() => {
    if (isOver) {
      generateMagicLink();
    }
  }, [isOver]);

  useEffect(() => {
    generateMagicLink();
  }, [appId]);

  if (selectedPlatform) {
    return (
      <Redirect to={{ pathname: `/dashboard/clients/companies/${companyId}/connect/status` }} />
    );
  }

  if (isLoading) {
    return (
      <Styled.Wrapper>
        <Styled.CentralizedContainer>
          <Spinner width={40} height={40} />
        </Styled.CentralizedContainer>
      </Styled.Wrapper>
    );
  }

  return (
    <Styled.Wrapper>
      <Styled.Title>Email invite</Styled.Title>
      <Styled.Desc>
        This user will receive an invite email to connect and install the bot. Invite link will be
        available for 1 hour.
      </Styled.Desc>
      {errorMessage && <MessageForm typeView="block">{errorMessage}</MessageForm>}
      <AsyncSelect
        name="user"
        title="user"
        getOptionValue={option => option.name}
        getOptionLabel={option => option.name}
        options={promiseOptionsUsers}
        onChange={option => setContact(option)}
      />
      <Styled.Button
        isDisabled={!contact.name}
        onClick={inviteHandler}
        isLoader={isFetchInviteContact}
        type="submit"
        formId="save-settings"
        size="small"
      >
        Invite
      </Styled.Button>
      <Styled.Title>Magic Link</Styled.Title>
      <Styled.Desc>
        Copy this link into new session, where ever you are logged into that Teams or Slack account.
      </Styled.Desc>
      <Styled.LinkContainer>
        <InputStyled
          value={magicLink || 'Loading'}
          hasError={isOver}
          disabled={isLinkLoading}
          readOnly
        />
        <Styled.LinkButton
          onClick={isOver ? generateMagicLink : handleCopy}
          isLoader={magicLink && isLinkLoading}
          styleMode="outline"
        >
          {isOver ? 'Regenerate' : isCopied ? 'Copied' : 'Copy link'}
        </Styled.LinkButton>
      </Styled.LinkContainer>
      {magicLink && expirationTime && (
        <Styled.CountdownContainer isOver={isOver}>
          {isOver ? (
            <>
              <WarningIcon />
              Link expired
            </>
          ) : (
            <>
              <svg width="12" height="12">
                <circle r="5" cx="6" cy="6" strokeDashoffset={(percentage || 0) * 32} />
              </svg>{' '}
              Link expires in {minutes}:{seconds}
            </>
          )}
        </Styled.CountdownContainer>
      )}
      <Styled.ButtonLink size="large" onClick={() => history.goBack()}>
        Previous
      </Styled.ButtonLink>
    </Styled.Wrapper>
  );
};

Invitation.propTypes = {
  isFetchInviteContact: PropTypes.bool,
  getContactsByCompany: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  match: PropTypes.objectOf(PropTypes.any),
  inviteContact: PropTypes.func.isRequired,
  appId: PropTypes.string.isRequired,
  companyId: PropTypes.number.isRequired,
  history: PropTypes.objectOf(PropTypes.any)
};

Invitation.defaultProps = {
  isFetchInviteContact: false,
  errorMessage: '',
  match: {},
  history: {}
};

const mapStateToProps = state => ({
  contactsByCompany: state.contactsReducer.contactsByCompany.data.map(contact => ({
    value: contact.email,
    label: contact.email,
    ...contact
  })),
  parentAppId: state.usersReducer.user.appId,
  isFetchContactsByCompany: state.contactsReducer.isFetchContactsByCompany,
  errorMessage: state.contactsReducer.errorMessage,
  isFetchInviteContact: state.contactsReducer.isFetchInviteContact
});

const mapDispatchToProps = dispatch => ({
  getContactsByCompany: params => dispatch(getContactsByCompany(params)),
  inviteContact: props => dispatch(inviteContact(props))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Invitation));
