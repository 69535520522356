import { actionStatuses, companyTypes } from 'src/constants/deployment-center';

const defaultState = {
  clients: { data: [], meta: {} },
  isFetchClients: false,
  errorMessage: ''
};

const map = {
  [`${companyTypes.GET_PARTNER_CLIENTS}${actionStatuses.FULFILLED}`]: (state, action) => ({
    ...state,
    clients: action.payload.data,
    isFetchClients: false
  }),
  [`${companyTypes.GET_PARTNER_CLIENTS}${actionStatuses.PENDING}`]: state => ({
    ...state,
    isFetchClients: true
  }),
  [`${companyTypes.GET_PARTNER_CLIENTS}${actionStatuses.REJECTED}`]: (state, action) => ({
    ...state,
    isFetchClients: false,
    errorMessage: action.payload.response.data.message
  }),
  [`${companyTypes.UPDATE_PARTNER_CLIENTS_DATA}`]: (state, action) => ({
    ...state,
    clients: {
      ...state.clients,
      data: action.payload
    }
  })
};

export default (state = defaultState, action) =>
  (map[action.type] && map[action.type](state, action)) || state;
