import React from 'react';
import { IntegrationPlatformTitle } from './IntegrationPlatformTitle';
import { IntegrationStatus } from './IntegrationStatus';

const tableColumns = [
  {
    key: 'name',
    title: 'Name',
    component: row => <IntegrationPlatformTitle row={row} />,
    isSort: false
  },
  {
    key: 'status',
    title: 'Status',
    component: row => (
      <IntegrationStatus valid={row.is_valid} active={row.is_active} hint={row.hint} />
    ),
    isSort: false
  }
];

export default tableColumns;
