import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useAuthUser from 'src/hooks/useAuthUser';
import PartnerDeploymentMetrics from '../PartnerDeploymentMetrics';
import PartnerQueueClients from '../PartnerQueueClients';
import MagicAITrial from '../MagicAITrial';
import { MagicAiTrialProvider } from '../context/magicAiTrialContext';

const PartnerClientsTab = ({ history, location }) => {
  const [viewFiltersAndSorting, setViewFiltersAndSorting] = useState({
    filters: {
      state: 'any'
    },
    sortOption: 'rank'
  });
  const [view, setView] = useState(null);

  const { featureFlags } = useAuthUser();
  const isMagicAITrialEnabled = featureFlags?.includes('magic_ai_trial');

  return (
    <>
      <MagicAiTrialProvider>
        <PartnerDeploymentMetrics />

        {isMagicAITrialEnabled && (
          <MagicAITrial view={view} viewFiltersAndSorting={viewFiltersAndSorting} />
        )}

        <PartnerQueueClients
          history={history}
          location={location}
          viewFiltersAndSorting={viewFiltersAndSorting}
          setViewFiltersAndSorting={setViewFiltersAndSorting}
          view={view}
          setView={setView}
        />
      </MagicAiTrialProvider>
    </>
  );
};

PartnerClientsTab.propTypes = {
  history: PropTypes.objectOf(PropTypes.any),
  location: PropTypes.objectOf(PropTypes.any)
};

PartnerClientsTab.defaultProps = {
  history: {},
  location: {}
};

export default PartnerClientsTab;
