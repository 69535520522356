import resolveResponse from 'contentful-resolve-response';
import { compareOnKey, isProduction } from 'src/utils';
import { actionStatuses, flowsTypes } from '../../../constants/action-types';
import { flowErrorsTransformData } from '../../../utils/data-transformer';

const prepareActionsTemplate = ({ data }) => {
  const actionConfigProd = {
    reply: {
      execution_action_id: 44,
      selected_action_id: 43
    },
    note: {
      execution_action_id: 46,
      selected_action_id: 45
    },
    priority: {
      execution_action_id: 8,
      selected_action_id: 7
    },
    assign: {
      execution_action_id: 20,
      selected_action_id: 19
    }
  };

  const actionConfigStage = {
    reply: {
      execution_action_id: 96,
      selected_action_id: 95
    },
    note: {
      execution_action_id: 98,
      selected_action_id: 97
    },
    priority: {
      execution_action_id: 76,
      selected_action_id: 75
    },
    assign: {
      execution_action_id: 88,
      selected_action_id: 87
    }
  };

  const actionConfig = isProduction() ? actionConfigProd : actionConfigStage;

  const actions = data.map(({ fields }) => {
    return {
      actions: {
        ...(actionConfig[fields.type] || {
          execution_action_id: fields.id
        })
      },
      form_data: {
        ...(fields.value && { [fields.type]: fields.value }),
        ...(fields.name && { name: fields.name }),
        ...(fields.id && { id: fields.id })
      }
    };
  });

  return actions;
};

const defaultState = {
  isFilterAttributesFetching: false,
  filterAttributes: [],
  flows: { data: [], meta: {} },
  flowListLoading: false,
  flowListError: '',
  flowTemplatesLoading: false,
  flowErrors: {},
  activeFlow: {},
  changeFlowLoading: false,
  configurePageLoading: false,
  flowTemplates: [],
  flowTemplatesErrorMessage: '',
  flowSyncError: ''
};

const map = {
  [`${flowsTypes.GET_FLOW_TEMPLATES}${actionStatuses.FULFILLED}`]: (state, action) => {
    return {
      ...state,
      flowTemplatesLoading: false,
      configurePageLoading: false,
      flowTemplates: resolveResponse(action.payload.data)
        .map(item => ({
          ...item.fields,
          id: item.sys.id,
          notifications: item.fields.notifications.map(code => ({ code })),
          ...(item.fields.actions && {
            actions: prepareActionsTemplate({ data: item.fields.actions })
          })
        }))
        .sort(compareOnKey('order'))
    };
  },
  [`${flowsTypes.GET_FLOW_TEMPLATES}${actionStatuses.PENDING}`]: state => ({
    ...state,
    flowTemplatesLoading: true,
    configurePageLoading: true
  }),
  [`${flowsTypes.GET_FLOW_TEMPLATES}${actionStatuses.REJECTED}`]: (state, action) => ({
    ...state,
    flowTemplatesLoading: false,
    configurePageLoading: false,
    flowTemplatesErrorMessage: action.payload.response.data.message
  }),
  [`${flowsTypes.GET_FLOWS}${actionStatuses.PENDING}`]: state => ({
    ...state,
    flowListLoading: true
  }),
  [`${flowsTypes.GET_FLOWS}${actionStatuses.FULFILLED}`]: (state, action) => ({
    ...state,
    activeFlow: {},
    flows: action.payload.data,
    flowListLoading: false
  }),
  [`${flowsTypes.GET_FLOWS}${actionStatuses.REJECTED}`]: (state, action) => ({
    ...state,
    flowListLoading: false,
    flowListError: action.payload.response.data.message
  }),
  [`${flowsTypes.GET_FLOW}${actionStatuses.FULFILLED}`]: (state, action) => ({
    ...state,
    activeFlow: action.payload.data.data,
    configurePageLoading: false,
    flowErrors: {}
  }),
  [`${flowsTypes.GET_FLOW}${actionStatuses.PENDING}`]: state => ({
    ...state,
    configurePageLoading: true
  }),
  [`${flowsTypes.GET_FLOW}${actionStatuses.REJECTED}`]: state => ({
    ...state,
    configurePageLoading: false
  }),
  [`${flowsTypes.CREATE_FLOW}${actionStatuses.FULFILLED}`]: (state, action) => ({
    ...state,
    changeFlowLoading: false,
    activeFlow: action.payload.data.data,
    flows: {
      ...state.flows,
      data: [...state.flows.data, action.payload.data.data]
    }
  }),
  [`${flowsTypes.CREATE_FLOW}${actionStatuses.PENDING}`]: state => ({
    ...state,
    changeFlowLoading: true,
    flowErrors: {}
  }),
  [`${flowsTypes.CREATE_FLOW}${actionStatuses.REJECTED}`]: (state, action) => {
    const { errors, message } = action.payload.response.data;
    return {
      ...state,
      changeFlowLoading: false,
      flowErrors: errors ? flowErrorsTransformData(errors) : message
    };
  },
  [`${flowsTypes.UPDATE_FLOW}${actionStatuses.FULFILLED}`]: (state, action) => ({
    ...state,
    changeFlowLoading: false,
    activeFlow: action.payload.data.data,
    flows: {
      ...state.flows.data,
      data: state.flows.data.map(flow =>
        flow.id === action.meta.id ? action.payload.data.data : flow
      )
    }
  }),
  [`${flowsTypes.UPDATE_FLOW}${actionStatuses.PENDING}`]: state => ({
    ...state,
    changeFlowLoading: true,
    flowErrors: {}
  }),
  [`${flowsTypes.UPDATE_FLOW}${actionStatuses.REJECTED}`]: (state, action) => ({
    ...state,
    changeFlowLoading: false,
    flowErrors: flowErrorsTransformData(action.payload.response.data.errors)
  }),

  // DELETE_FLOW
  [`${flowsTypes.DELETE_FLOW}${actionStatuses.FULFILLED}`]: (state, action) => ({
    ...state,
    flows: {
      ...state.flows,
      data: state.flows.data.filter(flow => flow.id !== action.meta.id)
    },
    changeFlowLoading: false,
    errorMessage: ''
  }),
  [`${flowsTypes.DELETE_FLOW}${actionStatuses.PENDING}`]: state => ({
    ...state,
    changeFlowLoading: true,
    errorMessage: ''
  }),
  [`${flowsTypes.DELETE_FLOW}${actionStatuses.REJECTED}`]: state => ({
    ...state,
    changeFlowLoading: false
  }),

  // RENAME_FLOW
  [`${flowsTypes.RENAME_FLOW}${actionStatuses.FULFILLED}`]: (state, action) => ({
    ...state,
    flows: {
      ...state.flows,
      data: state.flows.data.map(flow =>
        flow.id === action.meta.id ? { ...flow, name: action.meta.name } : flow
      )
    },
    changeFlowLoading: false,
    errorMessage: ''
  }),
  [`${flowsTypes.RENAME_FLOW}${actionStatuses.PENDING}`]: state => ({
    ...state,
    changeFlowLoading: true,
    errorMessage: ''
  }),
  [`${flowsTypes.RENAME_FLOW}${actionStatuses.REJECTED}`]: state => ({
    ...state,
    changeFlowLoading: false
  }),

  [`${flowsTypes.GET_FILTER_ATTRIBUTES}${actionStatuses.PENDING}`]: state => ({
    ...state,
    isFilterAttributesFetching: true
  }),
  [`${flowsTypes.GET_FILTER_ATTRIBUTES}${actionStatuses.FULFILLED}`]: (state, action) => ({
    ...state,
    isFilterAttributesFetching: false,
    filterAttributes: action.payload.data.data
  }),
  [`${flowsTypes.GET_FILTER_ATTRIBUTES}${actionStatuses.REJECTED}`]: state => ({
    ...state,
    isFilterAttributesFetching: false
  })
};

export default function flowsReducer(state = defaultState, action) {
  return (map[action.type] && map[action.type](state, action)) || state;
}
