import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FiInfo } from 'react-icons/fi';
import { Button } from '@Thread-Magic/jasmine';
import { useMutation } from 'react-query';
import { chatgenieAPI } from 'src/config/api';
import useToast from 'src/hooks/useToast';
import FadeInOutContainer from 'src/components/FadeInOutContainer';
import { SwitchTransition } from 'react-transition-group';
import { Controller, useForm } from 'react-hook-form';
import { ReactComponent as StarsIcon } from 'src/assets/icons/stars.svg';
import isBoolean from 'lodash/isBoolean';
import * as Styled from '../style';
import Stepper from '../Stepper';
import PrioritySelectField from './components/PrioritySelectField';
import PriorityRadioField from './components/PriorityRadioField';
import { booleanOptions, defaultRuleValues, ruleSections } from './constants';
import { getSortedPriorities, updateMultipleEntities } from './utils';
import usePrioritizationConfig from './hooks/usePrioritizationConfig';

const normalizeDefaultValues = (data, priorities) => {
  const defaultValues = {};
  const { data: sortedPriorities = [] } = getSortedPriorities(priorities);

  if (data) {
    data.forEach(section =>
      section.rules.forEach(rule => {
        defaultValues[rule.id] =
          rule.action === null
            ? rule.selected_priority || defaultRuleValues(rule.id, sortedPriorities)
            : rule.action;
      })
    );
  }
  return defaultValues;
};

const getDefaultIgnoreValues = data => {
  const defaultValues = {};

  if (data) {
    data.forEach(section =>
      section.rules.forEach(rule => {
        defaultValues[rule.id] = !!rule.ignore;
      })
    );
  }
  return defaultValues;
};

function EditPrioritization({ data, defaultStep, priorities, onSave }) {
  const { control, reset, getValues } = useForm({
    defaultValues: normalizeDefaultValues(data, priorities)
  });
  const { selectedSla } = usePrioritizationConfig();
  const [ignoredFields, setIgnoredFields] = useState(getDefaultIgnoreValues(data));
  const [activeStep, setActiveStep] = useState(defaultStep || 0);
  const { toast } = useToast();
  const { mutate, isLoading } = useMutation(chatgenieAPI.updateCopilotPriorityRules, {
    onSuccess: () => {
      toast.success(`Successfully saved types!`);
      onSave();
    },
    onError: err => {
      toast.error(`Failed to save types: ${err?.response?.data?.message || err?.message}`);
    }
  });

  const activeStepContent = data[activeStep];
  const isLastStep = activeStep + 1 === data.length;

  const handleNext = () => {
    if (isLastStep) {
      const formData = getValues();
      const entries = Object.entries(formData);
      const normalizedFormData = entries.map(([key, value]) => ({
        condition_id: key,
        priority_id: value?.id ?? null,
        action: isBoolean(value) ? value : null,
        ignore: ignoredFields[key],
        service_level_agreement_id: selectedSla?.id
      }));

      mutate(
        {
          conditions: normalizedFormData
        },
        {
          onSuccess() {
            const normalizedRulesData = entries.reduce((acc, [key, value]) => {
              const isValueBoolean = isBoolean(value);
              acc[key] = {
                selected_priority: isValueBoolean ? null : value,
                action: isValueBoolean ? value : null,
                ignore: ignoredFields[key]
              };
              return acc;
            }, {});
            updateMultipleEntities(
              { rules: normalizedRulesData, firstAccess: false },
              {
                slaId: selectedSla?.id
              }
            );
          }
        }
      );
    } else {
      setActiveStep(prev => prev + 1);
    }
  };

  const handlePrev = () => {
    setActiveStep(prev => prev - 1);
  };

  useEffect(() => {
    if (data) {
      reset(normalizeDefaultValues(data, priorities));
      setIgnoredFields(getDefaultIgnoreValues(data));
    }
  }, [data, priorities]);

  return (
    <Styled.EditWrapper>
      <h5>Configure Priorities for Magic AI</h5>
      <Stepper steps={data} activeStep={activeStep} />
      <SwitchTransition mode="out-in">
        <FadeInOutContainer
          key={activeStep}
          timeout={200}
          transformType="horizontal"
          style={{
            minWidth: 492
          }}
        >
          <Styled.EditContent small>
            <h5>{ruleSections[activeStepContent.type].title}</h5>
            <p>
              How should we handle {ruleSections[activeStepContent.type].title.toLowerCase()}{' '}
              scenarios?
            </p>
            {ruleSections[activeStepContent.type].additional && (
              <Styled.Info>
                <FiInfo />
                {ruleSections[activeStepContent.type].additional}
              </Styled.Info>
            )}
            {activeStepContent.rules.map((rule, index) => {
              const isSelect = rule.action === null;
              const { icons } = ruleSections[activeStepContent.type];
              const additionalData = {
                icon: icons[index],
                description: rule.text,
                options: booleanOptions,
                id: rule.id,
                ignore: ignoredFields[rule.id]
              };

              return (
                <Controller
                  key={rule.id}
                  name={`${rule.id}`}
                  control={control}
                  render={({ field: { onChange, value } }) =>
                    isSelect ? (
                      <PrioritySelectField
                        onChange={onChange}
                        value={value}
                        data={additionalData}
                        showIgnoreCheckbox
                        onIgnoreChange={value =>
                          setIgnoredFields(old => ({ ...old, [rule.id]: value }))
                        }
                        isDisabled={ignoredFields[rule.id]}
                        marginTop={20}
                        width={320}
                      />
                    ) : (
                      <PriorityRadioField
                        marginTop={20}
                        value={value}
                        onChange={onChange}
                        showIgnoreCheckbox
                        isDisabled={ignoredFields[rule.id]}
                        onIgnoreChange={value =>
                          setIgnoredFields(old => ({ ...old, [rule.id]: value }))
                        }
                        data={additionalData}
                      />
                    )
                  }
                />
              );
            })}
          </Styled.EditContent>
        </FadeInOutContainer>
      </SwitchTransition>
      <Styled.ActionArea animate={isLoading}>
        {activeStep > 0 && (
          <Button type="button" size="large" styleMode="outline" onClick={handlePrev}>
            &lt;- Previous
          </Button>
        )}
        <Button type="button" size="large" isDisabled={isLoading} onClick={handleNext}>
          {isLastStep ? (
            <>
              <StarsIcon /> Generate
            </>
          ) : (
            <>Next -&gt;</>
          )}
        </Button>
      </Styled.ActionArea>
    </Styled.EditWrapper>
  );
}

EditPrioritization.propTypes = {
  onSave: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(PropTypes.object),
  defaultStep: PropTypes.number,
  priorities: PropTypes.object.isRequired
};

EditPrioritization.defaultProps = {
  defaultStep: null,
  data: null
};

export default EditPrioritization;
