import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { ReactComponent as MSTeamsIcon } from 'src/assets/icons/microsoft-teams.svg';
import useToast from 'src/hooks/useToast';
import { useHistory } from 'react-router-dom';
import useAuthUser from 'src/hooks/useAuthUser';
import { setLocalStorage } from 'src/utils';
import { ReactComponent as Spinner } from 'src/assets/icons/spinner.svg';
import { getMsConfiguration } from 'src/redux/modules/chats/chatsActions';
import { useMsConfiguration } from 'src/hooks/useMsConfiguration';
import MicrosoftConnection from './MicrosoftConnection';
import { MicrosoftIntegrationsWrapper, IntegrationsTitle, SpinnerWrapper } from './style';
import { ReactComponent as Microsoft365Logo } from '../../../assets/icons/microsoft-365-logo.svg';

const generateMicrosoftIntegrationsTitle = isIntegratedWithSlackApp =>
  `Microsoft 365${isIntegratedWithSlackApp ? '' : ' & Teams app'}`;

const MICROSOFT_365_CONFIG = {
  title: 'Microsoft 365',
  icon: <Microsoft365Logo />,
  text: 'Connect to M365 to enable SSO login for your workspaces.',
  switchLabel: 'Enable SSO',
  platformStep: 'microsoft365'
};

const MICROSOFT_TEAMS_CONFIG = {
  icon: <MSTeamsIcon height="44" width="44" />,
  title: 'Teams Companion App',
  text: 'This is the internal app that is installed in your Teams tenant.'
};

function MicrosoftIntegrations({
  chatInfoLoader,
  handleConnect,
  handleDisconnect,
  getMsConfiguration
}) {
  const { toast } = useToast();
  const history = useHistory();
  const user = useAuthUser();

  const [integratedWithMicrosoft365, setIntegratedWithMicrosoft365] = useState(false);
  const [hasTeamsCompanionApp, setHasTeamsCompanionApp] = useState(false);
  const [ssoEnabled, setSsoEnabled] = useState(false);
  const [isLoadingConfig, setIsLoadingConfig] = useState(true);

  const msConfiguration = useMsConfiguration();

  const userIntegratedWithSlackApp = user.chatConfiguration === 'SLACK';

  useEffect(() => {
    getMsConfiguration(user.company.id);
  }, []);

  useEffect(() => {
    if (Object.keys(msConfiguration).length > 0) {
      setIntegratedWithMicrosoft365(msConfiguration.is_connected_to_m365);
      setHasTeamsCompanionApp(msConfiguration.has_teams_companion_app);
      setSsoEnabled(msConfiguration.is_sso_enabled);
      setIsLoadingConfig(false);
    }
  }, [msConfiguration]);

  const handleMicrosoftConnect = () => {
    try {
      setLocalStorage({ chatPlatformStep: MICROSOFT_365_CONFIG.platformStep });
      history.push('/signup/chat');
    } catch (error) {
      toast.error('Something went wrong, please try again');
    }
  };

  const handleMicrosoftDisconnect = () => {
    handleDisconnect(true);
  };

  const handleTeamsDisconnect = () => {
    handleDisconnect();
  };

  const microsoft365ButtonLabel = integratedWithMicrosoft365 ? 'Disconnect' : 'Connect';
  const teamsBotButtonLabel = hasTeamsCompanionApp ? 'Disconnect' : 'Connect';

  if (isLoadingConfig) {
    return (
      <SpinnerWrapper>
        <Spinner width={40} height={40} />
      </SpinnerWrapper>
    );
  }

  return (
    <MicrosoftIntegrationsWrapper data-testid="microsoft-integrations-wrapper">
      <IntegrationsTitle>
        {generateMicrosoftIntegrationsTitle(userIntegratedWithSlackApp)}
      </IntegrationsTitle>
      <MicrosoftConnection
        icon={MICROSOFT_365_CONFIG.icon}
        title={MICROSOFT_365_CONFIG.title}
        text={MICROSOFT_365_CONFIG.text}
        chatInfoLoader={chatInfoLoader}
        handleConnect={handleMicrosoftConnect}
        handleDisconnect={handleMicrosoftDisconnect}
        switchLabel={MICROSOFT_365_CONFIG.switchLabel}
        connected={integratedWithMicrosoft365}
        buttonLabel={microsoft365ButtonLabel}
        ssoEnabled={ssoEnabled}
      />
      {!userIntegratedWithSlackApp && (
        <>
          <hr style={{ width: '100%', marginTop: '32px', marginBottom: '32px' }} />
          <MicrosoftConnection
            icon={MICROSOFT_TEAMS_CONFIG.icon}
            title={MICROSOFT_TEAMS_CONFIG.title}
            text={MICROSOFT_TEAMS_CONFIG.text}
            chatInfoLoader={chatInfoLoader}
            handleConnect={handleConnect}
            handleDisconnect={handleTeamsDisconnect}
            connected={hasTeamsCompanionApp}
            buttonLabel={teamsBotButtonLabel}
          />
        </>
      )}
    </MicrosoftIntegrationsWrapper>
  );
}

MicrosoftIntegrations.propTypes = {
  chatInfoLoader: PropTypes.string,
  handleConnect: PropTypes.func,
  handleDisconnect: PropTypes.func,
  getMsConfiguration: PropTypes.func
};

MicrosoftIntegrations.defaultProps = {
  chatInfoLoader: '',
  handleConnect: () => {},
  handleDisconnect: () => {},
  getMsConfiguration: () => {}
};

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  getMsConfiguration: companyId => dispatch(getMsConfiguration(companyId))
});

export default connect(mapStateToProps, mapDispatchToProps)(MicrosoftIntegrations);
