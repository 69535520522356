import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useToast from 'src/hooks/useToast';
import ConfirmModal from 'src/components/ConfirmModal';
import useMessengerMagicAiStateUpdates from 'src/hooks/useMessengerMagicAIStateUpdates';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import { List, ListItem } from './ActionsSelector.style';
import Popover from '../Popover';
import { MAGIC_AI_STATES, MESSENGER_STATES } from '../../utils/constants';

const DROPDOWN_ACTION_SELECTORS = [
  {
    actionKey: 'is_messenger_enabled',
    itemText: 'Enable Messenger',
    actionType: MESSENGER_STATES.ENABLED,
    modalConfirmTxt: 'enable Messenger',
    successMessage: 'Messenger has been enabled for these clients'
  },
  {
    actionKey: 'is_messenger_enabled',
    itemText: 'Disable Messenger',
    actionType: MESSENGER_STATES.DISABLED,
    modalConfirmTxt: 'disable Messenger',
    successMessage: 'Messenger has been disabled for these clients'
  },
  {
    actionKey: 'magic_ai_status',
    itemText: 'Enable Magic AI',
    actionType: MAGIC_AI_STATES.ENABLED,
    modalConfirmTxt: 'enable Magic AI',
    successMessage: 'Magic AI has been enabled for these clients'
  },
  {
    actionKey: 'magic_ai_status',
    itemText: 'Disable Magic AI',
    actionType: MAGIC_AI_STATES.DISABLED,
    modalConfirmTxt: 'disable Magic AI',
    successMessage: 'Magic AI has been disabled for these clients'
  }
];

const ActionsSelector = ({
  checkedCompanies,
  excludedCompanies,
  onClickCb,
  filters,
  isAllSelected
}) => {
  const { toast } = useToast();
  const { search } = useLocation();

  const modalDefaultState = {
    isOpen: false,
    actionType: '',
    modalConfirmTxt: '',
    actionKey: '',
    successMessage: ''
  };

  const [confirmModalState, setConfirmModalState] = useState(modalDefaultState);
  const { updateStatuses, isLoading } = useMessengerMagicAiStateUpdates({ onFinishCb: onClickCb });

  const handleActionSelectorClick = data => {
    if (!isAllSelected && !checkedCompanies?.length > 0) {
      toast.warn('Nothing was selected');
      return;
    }

    setConfirmModalState({
      isOpen: true,
      actionType: data.actionType,
      modalConfirmTxt: data.modalConfirmTxt,
      actionKey: data.actionKey,
      successMessage: data.successMessage
    });
  };

  const handleConfirmModal = async () => {
    const queryParams = new URLSearchParams();
    Object.keys(filters).forEach(key => {
      if (filters[key]) {
        queryParams.set(key, filters[key]);
      }
    });

    const currentQuery = new URLSearchParams(search);
    const searchQuery = currentQuery.get('query') || '';

    if (searchQuery.length > 0) {
      queryParams.set('query', searchQuery);
    }

    await updateStatuses({
      ...confirmModalState,
      companyIds: checkedCompanies,
      excludedCompanyIds: excludedCompanies,
      queryParams: queryParams.toString()
    });
    setConfirmModalState(modalDefaultState);
  };

  const handleCancelModal = () => {
    setConfirmModalState(modalDefaultState);
  };

  const renderActionListItems = () => {
    const filteredActionSelectorsData = DROPDOWN_ACTION_SELECTORS;

    return filteredActionSelectorsData.map(data => (
      <ListItem type="button" onClick={() => handleActionSelectorClick(data)}>
        {data.itemText}
      </ListItem>
    ));
  };

  return (
    <div>
      <Popover title="Actions" closeOnSelect>
        <List>{renderActionListItems()}</List>
      </Popover>
      <ConfirmModal
        title="Confirm edit"
        description={`Are you sure you want to ${confirmModalState.modalConfirmTxt} Messenger for these clients?`}
        isOpen={confirmModalState.isOpen}
        cancelText="Cancel"
        submitText="Confirm"
        toggleHandler={handleCancelModal}
        onSubmit={handleConfirmModal}
        isLoading={isLoading}
        btnCenter
        confirmFirst
      />
    </div>
  );
};

ActionsSelector.defaultProps = {};

ActionsSelector.propTypes = {
  onClickCb: PropTypes.func.isRequired,
  checkedCompanies: PropTypes.arrayOf(PropTypes.any).isRequired,
  excludedCompanies: PropTypes.arrayOf(PropTypes.any).isRequired
};

export default ActionsSelector;
