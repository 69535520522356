import { useState, useEffect } from 'react';
import useToast from 'src/hooks/useToast';

function isValidURL(url) {
  const urlPattern = /^(https:\/\/)([a-z0-9-]+\.)+[a-z]{2,6}([/?].+)?$/i;
  return urlPattern.test(url);
}

export const useCloudradialFormPage = ({ onSubmit, formData, configuration }) => {
  const formId = 'integration-cloudradial-form';
  const [portalUrl, setPortalUrl] = useState(formData.portalUrl || '');
  const [submitted, setSubmitted] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const { toast } = useToast();

  useEffect(() => {
    setPortalUrl(formData.portalUrl);
  }, [formData]);

  useEffect(() => {
    setIsActive(configuration.is_active);
  }, [configuration]);

  const onFormSubmit = event => {
    event.preventDefault();
    if (!isValidURL(portalUrl)) {
      toast.warn(
        'Please enter a valid URL without a trailing slash (i.e. https://myportal.cloudradial.com)'
      );
      return;
    }
    setSubmitted(true);

    onSubmit(
      {
        portalUrl
      },
      isActive
    );
  };

  return {
    formId,
    portalUrl,
    setPortalUrl,
    onFormSubmit,
    submitted,
    isActive,
    setIsActive
  };
};
