import styled, { css } from 'styled-components/macro';
import { ReactComponent as ArrowIcon } from 'src/assets/icons/arrow.svg';

export const ArrowDown = styled(ArrowIcon)`
  flex-shrink: 0;
  transition: transform 0.2s;
`;

export const SelectedOption = styled.div`
  height: 60px;
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  cursor: pointer;
  font-size: 14px;

  &:hover {
    background: ${({ theme }) => theme.colors.brand.main.hover};
  }
`;

export const NavDropdownStyled = styled.div`
  position: relative;

  ${({ size }) =>
    size === 'large'
      ? css`
          ${SelectedOption} {
            height: 72px;
            font-size: 18px;
          }
        `
      : ''}

  ${({ size }) =>
    size !== 'large'
      ? css`
          ${ArrowDown} {
            width: 10px;
            height: 6px;
          }
        `
      : ''}

  ${({ isOpen }) => `
    ${ArrowDown} {
      ${isOpen ? 'transform: rotate(180deg);' : ''}
    }
  `}
`;

export const OptionList = styled.ul`
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  background: #ffffff;
  position: absolute;
  ${({ position }) => (position === 'top' ? `bottom: 100%;` : `top: 100%;`)};
  width: 100%;
  list-style: none;
  padding: 0;
  margin: 0;
  border-radius: 2px;
  overflow: hidden;
`;

export const Option = styled.li`
  padding: 10px 20px;
  cursor: pointer;
  color: #000000;

  &:hover {
    background: #faf9f6;
  }

  svg {
    height: 12px;
    width: 12px;
    margin-left: 8px;
    margin-bottom: 2px;
  }
`;
