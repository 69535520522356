import styled from 'styled-components';

export const TextStyled = styled.span`
  position: relative;
  display: block;
  font-size: 14px;
  line-height: 1.3em;

  .svgicon {
    width: 16px;
    height: 16px;
    position: absolute;
    top: 10px;
    left: 10px;
  }

  ${({ typeMsg }) =>
    typeMsg === 'error' &&
    `
    border-color: #E7121D;
    background-color: #FF929830;
    color: #E7121D;
  `}

  ${({ typeMsg }) =>
    typeMsg === 'success' &&
    `
    border-color: #C3E6CB;
    background-color: #D4EDDA;
    color: #155724;
  `}

  ${({ typeMsg }) =>
    typeMsg === 'info' &&
    `
    color: #FAA035;
    background-color: #FFEBD3;
    border-color: #FFC784;

    .svgicon { fill: #FAA035; }
  `}

  ${({ type }) =>
    type === 'text' &&
    `
    margin: -10px auto 20px;
    font-weight: bold;
    text-align: center;
    background-color: transparent;
  `}

  ${({ type, isIcon }) =>
    type === 'block' &&
    `
    text-align: left;
    margin: 0px auto 10px;
    font-weight: 600;
    border-width: 1px;
    border-style: solid;
    border-radius: 4px;
    padding: ${isIcon ? '10px 10px 10px 32px' : '10px'};
  `}
`;
