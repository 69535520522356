import React, { useState, useRef, Fragment } from 'react';
import PropTypes from 'prop-types';
import SimpleRadio from 'src/components/SimpleRadio';
import SimpleCheckbox from 'src/components/SimpleCheckbox';
import useOutsideClick from 'src/hooks/useOutsideClick';
import { ReactComponent as FilterIcon } from 'src/assets/icons/funnel.svg';
import * as Styled from './styles';

const Filters = ({ data, name, value, onChangeFilter, onClear, type }) => {
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const ref = useRef();

  useOutsideClick(ref, () => {
    setIsPopupVisible(!isPopupVisible);
  });

  const handleTogglePopupVisibility = event => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    setIsPopupVisible(!isPopupVisible);
  };

  const isActiveByType = {
    checkbox: !!Object.keys(value).filter(key => value[key]).length,
    radio: !!Object.keys(value).filter(key => value[key]).length
  };

  return (
    <Styled.Wrapper>
      <Styled.FilterButton
        type="button"
        onClick={handleTogglePopupVisibility}
        isActive={isActiveByType[type]}
      >
        <FilterIcon />
      </Styled.FilterButton>
      {isPopupVisible && (
        <Styled.Popup ref={ref}>
          {isActiveByType[type] && (
            <Styled.ClearButton type="button" onClick={onClear}>
              Clear selection
            </Styled.ClearButton>
          )}
          {type === 'checkbox' &&
            data.map(item => (
              <SimpleCheckbox
                title={item.name}
                key={`${name}-${item.id}`}
                id={`${name}-${item.id}`}
                name={name}
                value={item.id}
                onChange={event => onChangeFilter(event, name, type)}
                checked={value && value[item.id]}
                label={item.name}
                htmlFor={`${name}-${item.id}`}
              />
            ))}
          {type === 'radio' && (
            <Fragment>
              {data.map(item => (
                <SimpleRadio
                  key={`${name}-${item.id}`}
                  id={`${name}-${item.id}`}
                  name={name}
                  value={item.id}
                  onChange={event => onChangeFilter(event, name, type)}
                  checked={value && value[item.id]}
                  label={item.name}
                  htmlFor={`${name}-${item.id}`}
                />
              ))}
            </Fragment>
          )}
        </Styled.Popup>
      )}
    </Styled.Wrapper>
  );
};

Filters.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any),
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.objectOf(PropTypes.any)]),
  onChangeFilter: PropTypes.func,
  onClear: PropTypes.func,
  type: PropTypes.string
};

Filters.defaultProps = {
  data: PropTypes.arrayOf(PropTypes.any),
  name: '',
  value: '',
  onChangeFilter: () => {},
  onClear: () => {},
  type: 'radio'
};

export default React.memo(Filters);
