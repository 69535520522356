export const messageIntegrations = {
  NOT_VALID: 'The configuration is incorrect, check the data is correct.',
  VALID: 'The integration is properly configured.'
};

export const integrationCommands = {
  IT_GLUE: 'ITGLUE',
  AUTOMATE: 'AUTOMATE',
  COPILOT: 'COPILOT'
};
