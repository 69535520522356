import { useState, useEffect } from 'react';

export const useCWAutomateFormPage = ({ onSubmit, formData, configuration }) => {
  const formId = 'integration-cw-automate-form';
  const [baseUrl, setBaseUrl] = useState(formData.baseUrl || '');
  const [username, setUsername] = useState(formData.username || '');
  const [password, setPassword] = useState(formData.password || '');
  const [submitted, setSubmitted] = useState(false);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    setBaseUrl(formData.baseUrl);
    setUsername(formData.username);
    setPassword(formData.password);
  }, [formData]);

  useEffect(() => {
    setIsActive(configuration.is_active);
  }, [configuration]);

  const onFormSubmit = event => {
    event.preventDefault();
    setSubmitted(true);

    onSubmit(
      {
        baseUrl,
        username,
        password
      },
      isActive
    );
  };

  return {
    formId,
    baseUrl,
    setBaseUrl,
    username,
    setUsername,
    password,
    setPassword,
    onFormSubmit,
    submitted,
    isActive,
    setIsActive
  };
};
