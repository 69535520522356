import React, { useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import useOutsideClick from 'src/hooks/useOutsideClick';
import DotsIcon from 'src/assets/icons/dots.svg';
import { MAGIC_AI_STATES, MESSENGER_STATES } from 'src/pages/DeploymentCenter/utils/constants';
import useMessengerMagicAiStateUpdates from 'src/hooks/useMessengerMagicAIStateUpdates';
import { Wrapper, ToggleAIAndMessenger, Toggle, DotsWrap } from './ToggleMessengerMagicAI.style';
import { useMagicAiTrial } from '../../context/magicAiTrialContext';

const ToggleMessengerMagicAI = ({ magicAIStatus, isMessengerEnabled, companyId }) => {
  const { isTrialPeriodRunning } = useMagicAiTrial();
  const [isToggleOpen, setToggleOpen] = useState(false);
  const wrapperRef = useRef(null);
  const isMagicAIEnabled = magicAIStatus !== MAGIC_AI_STATES.DISABLED;
  const { updateStatuses } = useMessengerMagicAiStateUpdates();

  useOutsideClick(wrapperRef, () => setToggleOpen(false));

  const handleDotsToggle = () => {
    setToggleOpen(prev => !prev);
  };

  const handleEnableDisable = (actionKey, actionType) => {
    updateStatuses({
      actionKey,
      actionType,
      companyIds: [companyId]
    });
    setToggleOpen(false);
  };

  const magicAiAction = useMemo(() => {
    if (isMagicAIEnabled) {
      return MAGIC_AI_STATES.DISABLED;
    }

    if (isTrialPeriodRunning) {
      return MAGIC_AI_STATES.TRIAL;
    }

    return MAGIC_AI_STATES.ENABLED;
  }, [isMagicAIEnabled, isTrialPeriodRunning]);

  return (
    <Wrapper ref={wrapperRef}>
      <DotsWrap onClick={handleDotsToggle}>
        <img src={DotsIcon} alt="..." />
      </DotsWrap>

      {isToggleOpen && (
        <ToggleAIAndMessenger>
          <Toggle
            onClick={() =>
              handleEnableDisable(
                'is_messenger_enabled',
                isMessengerEnabled ? MESSENGER_STATES.DISABLED : MESSENGER_STATES.ENABLED
              )
            }
          >
            {isMessengerEnabled ? 'Disable' : 'Enable'} Messenger
          </Toggle>
          <Toggle onClick={() => handleEnableDisable('magic_ai_status', magicAiAction)}>
            {isMagicAIEnabled ? 'Disable' : 'Enable'} MagicAI
          </Toggle>
        </ToggleAIAndMessenger>
      )}
    </Wrapper>
  );
};

ToggleMessengerMagicAI.propTypes = {
  magicAIStatus: PropTypes.string,
  isMessengerEnabled: PropTypes.bool,
  companyId: PropTypes.number
};

ToggleMessengerMagicAI.defaultProps = {
  magicAIStatus: '',
  isMessengerEnabled: false,
  companyId: -1
};

export default ToggleMessengerMagicAI;
