import styled from 'styled-components';

export const RowActionsWrapper = styled.div`
  display: flex;
  position: relative;
  width: 38px;
  margin-left: auto;
`;

export const Dot = styled.div`
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #bdbdbd;
  margin-right: 3px;

  &:last-child {
    margin: 0;
  }
`;

export const Dots = styled.div`
  display: flex;
  padding: 4px 6px;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover ${Dot} {
    background-color: ${({ theme }) => theme.colors.brand.light.hover};
  }
`;
