import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory, Switch, Redirect, Route } from 'react-router-dom';
import { setLocalStorage } from 'src/utils';
import useAuthUser from 'src/hooks/useAuthUser';
import {
  uninstallChatConfiguration,
  updateChatConfiguration
} from 'src/redux/modules/chats/chatsActions';
import { ReactComponent as SlackIcon } from 'src/assets/icons/slack.svg';
import { ReactComponent as MSTeamsIcon } from 'src/assets/icons/microsoft-teams.svg';
import { ReactComponent as Spinner } from 'src/assets/icons/spinner.svg';
import { chatsTypes } from 'src/constants/action-types';
import useToast from 'src/hooks/useToast';
import InfoModal from 'src/components/InfoModal';
import { Button } from '@Thread-Magic/jasmine';
import { ReactComponent as Microsoft365Logo } from 'src/assets/icons/microsoft-logo-and-icon.svg';
import { useMsConfiguration } from 'src/hooks/useMsConfiguration';
import PageWrapper from '../../components/PageWrapper';
import MicrosoftIntegrations from './MicrosoftIntegrations/MicrosoftIntegrations';
import MicrosoftSyncs from './MicrosoftIntegrations/MicrosoftSyncs';
import {
  Heading,
  Description,
  CenteredContent,
  ContentFlexed,
  SubHeading,
  DangerOutlineButton,
  ButtonsWrapper,
  ModalWrapper,
  Microsoft365LogoStyled
} from './style';
import { useSsoFlag } from './MicrosoftIntegrations/featureFlag';
import {
  isBotInstallStep,
  isMicrosoft365Integration,
  isUserIntegratedWithMicrosoft
} from './MicrosoftIntegrations/microsoftConnectionHelpers';

const configData = (isIntegratedWithSlackApp = false) => ({
  slack: {
    title: 'Slack',
    breadcrumb: 'Slack companion app',
    icon: <SlackIcon height="44" width="44" />
  },
  msteams: {
    title: 'Microsoft Teams',
    breadcrumb: `Microsoft 365${isIntegratedWithSlackApp ? '' : ' & Teams app'}`,
    icon: <MSTeamsIcon height="44" width="44" />
  }
});

const ChatIntegrationsEditor = ({
  match,
  chatInfoLoader,
  updateChatConfiguration,
  uninstallChatConfiguration
}) => {
  const authenticatedUser = useAuthUser();
  const isIntegratedWithSlackApp = authenticatedUser.chatConfiguration === chatsTypes.SLACK;
  const config = configData(isIntegratedWithSlackApp)[match.params.platform];
  const [isUninstalling, setIsUninstalling] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isDeletingM365, setIsDeletingM365] = useState(false);
  const history = useHistory();
  const { toast } = useToast();
  const msConfiguration = useMsConfiguration();

  const toggleConfirmModal = (isDeletingM365 = true) => {
    setIsConfirmModalOpen(!isConfirmModalOpen);
    setIsDeletingM365(isDeletingM365);
  };

  const ssoFeatureEnabled = useSsoFlag();

  const handleConnect = () => {
    if (ssoFeatureEnabled && isBotInstallStep(authenticatedUser)) {
      setLocalStorage({ onboardingStep: 'bot_install' });
      history.push('/signup/bot-install');
      return;
    }

    if (isUserIntegratedWithMicrosoft(authenticatedUser)) {
      // Do not reset chat configuration if the user is already integrated with Microsoft
      setLocalStorage({ chatPlatformStep: match.params.platform });
      history.replace('/signup/chat');
      return;
    }
    updateChatConfiguration(authenticatedUser.company.id, null)
      .then(() => {
        setLocalStorage({ chatPlatformStep: match.params.platform });
        history.replace('/signup/chat');
      })
      .catch(() => {
        toast.error('Something went wrong, please try again.');
      });
  };

  const handleUninstall = () => {
    setIsUninstalling(true);

    uninstallChatConfiguration(authenticatedUser.company.id, isDeletingM365)
      .then(() => {
        setIsUninstalling(false);
        setIsConfirmModalOpen(false);
        toast.success('App disconnected successfully.');
      })
      .catch(() => {
        setIsUninstalling(false);
        toast.error('Something went wrong, please try again.');
      });
  };

  if (!config) return null;

  const isTeamsOrM365Integration =
    (match.params.platform === 'msteams' || isMicrosoft365Integration()) &&
    match.params.platform !== 'slack';

  const renderLegacyDisconnect = () => {
    if (ssoFeatureEnabled) {
      // If the feature flag is enabled, only render the legacy disconnect page for Slack
      return !isTeamsOrM365Integration && authenticatedUser.chatConfiguration === chatsTypes.SLACK;
    }
    // If the feature flag is not enabled, then check if the user has one of the two possible configurations
    return [chatsTypes.MS_TEAMS, chatsTypes.SLACK].includes(authenticatedUser.chatConfiguration);
  };

  if (renderLegacyDisconnect()) {
    return (
      <>
        <PageWrapper title={config.breadcrumb} isBackButton>
          <CenteredContent>
            <Heading>{config.title}</Heading>
            <SubHeading>Installed App</SubHeading>
            <ContentFlexed>
              <div>{config.icon}</div>
              <div style={{ marginLeft: '16px' }}>
                <strong>{config.breadcrumb}</strong>
                <p
                  style={{ marginBottom: '0' }}
                >{`This is the internal app that is installed in your ${config.title} tenant.`}</p>
              </div>
              <div>
                <DangerOutlineButton styleMode="outline" onClick={() => toggleConfirmModal(false)}>
                  Disconnect
                </DangerOutlineButton>
              </div>
            </ContentFlexed>
          </CenteredContent>
        </PageWrapper>
        <InfoModal
          isOpen={isConfirmModalOpen}
          toggleHandler={toggleConfirmModal}
          title={`Disconnect ${config.title}`}
        >
          <ModalWrapper>
            <p>
              Confirming this action will disconnect your {config.title} app from your internal
              tenant.
            </p>
          </ModalWrapper>
          <ButtonsWrapper>
            <Button styleMode="outline" onClick={() => toggleConfirmModal(false)}>
              Cancel
            </Button>
            <Button styleMode="danger" onClick={handleUninstall}>
              {isUninstalling ? <Spinner width={20} height={20} /> : 'Yes, disconnect'}
            </Button>
          </ButtonsWrapper>
        </InfoModal>
      </>
    );
  }

  const tabs = ssoFeatureEnabled && [
    { id: 1, path: `${match.url}/integrations`, name: 'Integrations' },
    msConfiguration.is_connected_to_m365 && isTeamsOrM365Integration
      ? {
          id: 2,
          path: `${match.url}/sync`,
          name: 'M365 Sync'
        }
      : {}
  ];

  const backButtonPath = ssoFeatureEnabled && '/dashboard/integrations';

  return (
    <PageWrapper title={config.breadcrumb} tabs={tabs} isBackButton backButtonPath={backButtonPath}>
      {ssoFeatureEnabled && isTeamsOrM365Integration && (
        <Microsoft365LogoStyled>
          <Microsoft365Logo />
        </Microsoft365LogoStyled>
      )}
      <CenteredContent>
        {isTeamsOrM365Integration && ssoFeatureEnabled ? (
          <Switch>
            <Redirect exact from={match.path} to={`${match.path}/integrations`} />
            <Route
              path={`${match.path}/integrations`}
              render={() => (
                <>
                  <MicrosoftIntegrations
                    chatInfoLoader={chatInfoLoader}
                    handleConnect={handleConnect}
                    handleDisconnect={toggleConfirmModal}
                  />
                  <InfoModal
                    isOpen={isConfirmModalOpen}
                    toggleHandler={toggleConfirmModal}
                    title={`Disconnect ${config.title}`}
                  >
                    <ModalWrapper>
                      <p>
                        Confirming this action will disconnect your {config.title} app from your
                        internal tenant.
                      </p>
                    </ModalWrapper>
                    <ButtonsWrapper>
                      <Button styleMode="outline" onClick={() => toggleConfirmModal(false)}>
                        Cancel
                      </Button>
                      <Button styleMode="danger" onClick={handleUninstall}>
                        {isUninstalling ? <Spinner width={20} height={20} /> : 'Yes, disconnect'}
                      </Button>
                    </ButtonsWrapper>
                  </InfoModal>
                </>
              )}
            />
            <Route path={`${match.path}/sync`} render={() => <MicrosoftSyncs />} />
          </Switch>
        ) : (
          <>
            <Heading>{config.title}</Heading>
            <ContentFlexed>
              <div>{config.icon}</div>
              <Description>{`Connect with ${config.title}, this is a one time setup.`}</Description>
            </ContentFlexed>
            <br />
            <Button
              size="large"
              full
              isLoader={chatInfoLoader === 'pending'}
              onClick={handleConnect}
            >
              Connect
            </Button>
          </>
        )}
      </CenteredContent>
    </PageWrapper>
  );
};

ChatIntegrationsEditor.propTypes = {
  match: PropTypes.object.isRequired,
  chatInfoLoader: PropTypes.string.isRequired,
  updateChatConfiguration: PropTypes.func.isRequired,
  uninstallChatConfiguration: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  chatInfoLoader: state.chatsReducer.chatInfoLoader
});

const mapDispatchToProps = dispatch => ({
  updateChatConfiguration: (companyId, chatConfig) =>
    dispatch(updateChatConfiguration(companyId, chatConfig)),
  uninstallChatConfiguration: (companyId, isDeletingM365) =>
    dispatch(uninstallChatConfiguration(companyId, isDeletingM365))
});

export default connect(mapStateToProps, mapDispatchToProps)(ChatIntegrationsEditor);
