import React, { useEffect } from 'react';
import { ReactComponent as MagicIcon } from 'src/assets/icons/magic-icon.svg';
import { ReactComponent as MessageIcon } from 'src/assets/icons/message.svg';
import { ReactComponent as ThreadIcon } from 'src/assets/icons/thread.svg';
import { ReactComponent as Spinner } from 'src/assets/icons/spinner.svg';

import {
  Wrapper,
  Banner,
  BannerItem,
  MagicIconWrapper,
  MessageIconWrapper,
  MetricNumberWrapper,
  ThreadIconWrapper,
  MetricDescription,
  Divider,
  LoadingIconWrapper
} from './PartnerDeploymentMetrics.style';
import usePartnerMetrics from '../hooks/usePartnerMetrics';
import { useMagicAiTrial } from '../context/magicAiTrialContext';

const PartnerDeploymentMetrics = () => {
  const { isLoading, data, refetch } = usePartnerMetrics();
  const { isTrialPeriodRunning } = useMagicAiTrial();

  useEffect(() => {
    if (isTrialPeriodRunning) {
      refetch();
    }
  }, [isTrialPeriodRunning]);

  if (isLoading) {
    return (
      <Wrapper>
        <Banner>
          <LoadingIconWrapper>
            <Spinner width={40} height={40} />
          </LoadingIconWrapper>
        </Banner>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Banner>
        <BannerItem>
          <MessageIconWrapper>
            <MessageIcon width={30} height={30} />
          </MessageIconWrapper>
          <MetricNumberWrapper>
            {data?.deployed_clients_total}/{data?.total_clients}
          </MetricNumberWrapper>
          <MetricDescription>Customers deployed chat</MetricDescription>
        </BannerItem>

        <BannerItem>
          <Divider />
          <ThreadIconWrapper>
            <ThreadIcon width={30} height={30} />
          </ThreadIconWrapper>
          <MetricNumberWrapper>{data?.thread_usage_90d_total}</MetricNumberWrapper>
          <MetricDescription>Threads in the last 90 days</MetricDescription>
        </BannerItem>

        <BannerItem>
          <Divider />
          <MagicIconWrapper>
            <MagicIcon width={30} height={30} />
          </MagicIconWrapper>
          <MetricNumberWrapper>{data?.magic_ai_licenses_count}</MetricNumberWrapper>
          <MetricDescription>Clients running Magic AI</MetricDescription>
        </BannerItem>
      </Banner>
    </Wrapper>
  );
};

export default PartnerDeploymentMetrics;
