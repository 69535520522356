import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  display: inline-flex;
`;

export const FilterButton = styled.button`
  margin-left: 10px;
  padding: 0;
  border: none;
  background: none;

  &:focus {
    outline: none;
  }

  &:hover svg {
    fill: ${({ theme }) => theme.colors.brand.light.hover};
    stroke: ${({ theme }) => theme.colors.brand.light.hover};
  }

  svg {
    width: 10px;
    height: 13px;
    fill: ${({ isActive, theme }) => (isActive ? theme.colors.brand.light.active : '#D9D9D9')};
    stroke: ${({ isActive, theme }) => (isActive ? theme.colors.brand.light.active : '#D9D9D9')};
    transition: fill 0.2s, stroke 0.2s;
  }
`;

export const Popup = styled.div`
  position: absolute;
  top: 28px;
  right: 0;
  z-index: 90;
  overflow: auto;
  display: flex;
  flex-direction: column;
  max-height: 240px;
  max-width: 300px;
  min-width: 160px;
  padding: 8px 4px 10px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;

  label {
    display: inline-flex;
    margin: 0;
    padding: 6px 8px;
    transition: all 0.2s;
    font-size: 12px;
    line-height: 14px;
    color: #212121;

    &:focus,
    &:hover {
      outline: none;
      background-color: #f9f9f9;
    }
  }
`;

export const ClearButton = styled.button`
  display: inline-flex;
  margin-bottom: 4px;
  padding: 0 6px;
  border: none;
  font-size: 12px;
  line-height: 15px;
  color: ${({ theme }) => theme.colors.accent.main};
  background: none;
`;
