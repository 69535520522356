import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import useAuthUser from 'src/hooks/useAuthUser';
import { createProfile, getInfo } from '../../redux/modules/users/usersActions';
import SignupProfile from './SignupProfile';

const SignupProfileContainer = ({ createProfile, getInfo }) => {
  const history = useHistory();
  const authUser = useAuthUser();

  const handleSubmit = useCallback(
    data => {
      return createProfile({ ...data, logo: data.logo?.file })
        .then(getInfo)
        .then(() => {
          history.push('/');
        });
    },
    [createProfile, getInfo, history]
  );

  return <SignupProfile onSubmit={handleSubmit} user={authUser} />;
};

SignupProfileContainer.propTypes = {
  createProfile: PropTypes.func.isRequired,
  getInfo: PropTypes.func.isRequired
};

export default connect(null, { createProfile, getInfo })(SignupProfileContainer);
