import { useState, useEffect } from 'react';
import { queryClient } from 'src/config/queryClient';
import queryKeys from 'src/constants/queryKeys';
import { psaTypes } from 'src/constants';

export const useIntegrationsEditor = hookData => {
  const {
    fetchPlatforms,
    fetchConfiguration,
    createConfiguration,
    updateConfiguration,
    clearConfiguration,
    platformCommand,
    status,
    psaType
  } = hookData;

  const [selectedPlatform, setSelectedPlatform] = useState(null);

  const onSubmit = (data, isActive) => {
    if (selectedPlatform) {
      if (!selectedPlatform.configuration_id) {
        createConfiguration(selectedPlatform.id, data, isActive);
      } else {
        updateConfiguration(selectedPlatform.configuration_id, data, isActive).then(() => {
          queryClient.resetQueries(queryKeys.GET_ACTION_PLATFORMS, { exact: true });
        });
      }
    }
  };

  const [visibleOverlay, setVisibleOverlay] = useState(false);

  useEffect(() => {
    // TODO: Technically we can just rely on status
    if (/rejected|fulfilled$/.test(status)) {
      setVisibleOverlay(false);
    } else {
      setVisibleOverlay(true);
    }
  }, [status]);

  useEffect(() => {
    clearConfiguration();
    fetchPlatforms().then(({ value }) => {
      // IMPORTANT: `platformCommand` matches `psaType` and since today we only
      // have connectwise as a platform we need to handle the case for new PSA
      // integrations and fallback to connectwise if it is one of them.
      const validPlatformCommand = [psaTypes.KASEYA_BMS, psaTypes.AUTOTASK, psaTypes.HALO].includes(
        platformCommand
      )
        ? psaTypes.CONNECTWISE
        : platformCommand;

      const platform = (value.data.data || []).find(item => item.command === validPlatformCommand);

      if (platform) {
        setSelectedPlatform(platform);

        if (platform.configuration_id) {
          fetchConfiguration(platform.configuration_id);
        } else {
          setVisibleOverlay(false);
        }
      } else {
        setVisibleOverlay(false);
      }
    });
  }, [platformCommand]);

  return {
    selectedPlatform,
    visibleOverlay,
    onSubmit
  };
};

export const useControlForm = ({
  onSubmit,
  formData,
  configuration,
  defaultValueNormalizer = data => data,
  submitValueNormalizer = data => data
}) => {
  const [isActive, setIsActive] = useState(false);
  const [defaultValues, setDefaultValues] = useState();
  const [canSubmit, setCanSubmit] = useState(false);
  const onFormValid = isValid => {
    setCanSubmit(isValid);
  };

  useEffect(() => {
    setIsActive(configuration.is_active);
  }, [configuration]);

  useEffect(() => {
    setDefaultValues(defaultValueNormalizer(formData));
  }, [formData]);

  const onFormSubmit = data => {
    const formattedData = submitValueNormalizer(data);
    onSubmit(formattedData, isActive);
  };

  return {
    onFormSubmit,
    defaultValues,
    setDefaultValues,
    isActive,
    setIsActive,
    onFormValid,
    canSubmit
  };
};
