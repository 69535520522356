import { PostHogProvider } from 'posthog-js/react';
import posthog from 'posthog-js';
import React, { useEffect, useMemo } from 'react';
import useAuthUser from 'src/hooks/useAuthUser';

const normalizeUserProperties = user => ({
  user_member_id: user.memberId,
  user_contact_id: null,
  user_fullname: user.fullname,
  user_email: user.email,
  user_role: user.roles.admin ? 'admin' : 'basic',
  company_id: user.company.id,
  company_name: user.company.name,
  company_psa: user.psaType,
  company_parent_id: user.company.parent_id
});

const POSTHOG_KEY = process.env.REACT_APP_PUBLIC_POSTHOG_KEY;
const POSTHOG_HOST = process.env.REACT_APP_PUBLIC_POSTHOG_HOST;

export const withPostHogProvider = Component => props => {
  const user = useAuthUser();
  const memberId = `m|${user.memberId}`;

  const posthogClient = useMemo(
    () =>
      posthog.init(POSTHOG_KEY, {
        api_host: POSTHOG_HOST,
        advanced_disable_feature_flags_on_first_load: true,
        bootstrap: {
          distinctID: memberId
        }
      }),
    [memberId]
  );

  useEffect(() => {
    if (!user.memberId) {
      return;
    }

    posthogClient.identify(memberId, normalizeUserProperties(user));
  }, [memberId, posthogClient]);

  return (
    <PostHogProvider client={posthogClient}>
      <Component {...props} />
    </PostHogProvider>
  );
};
