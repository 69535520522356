import styled, { css } from 'styled-components';

export const Textarea = styled.textarea`
  width: 100%;
  max-width: 600px;
  min-height: 36px;
  border: 1px solid ${({ theme }) => theme.colors.border.hover};
  border-radius: 4px;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.text.secondary};
  transition: all 0.2s;

  ${({ size }) => {
    switch (size) {
      case 'small':
        return `
        padding: 6px 8px;
      `;
      default:
        return `
        padding: 8px 12px;
      `;
    }
  }}

  &:hover,
  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.colors.brand.light.base};
  }

  ${({ isError }) =>
    isError &&
    css`
      &,
      &:hover,
      &:focus {
        border-color: ${({ theme }) => theme.colors.dager.dark};
      }
    `}
`;
