import styled from 'styled-components';

export const SelecOption = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  & > div {
    display: flex;
    align-items: center;
  }
  & > span {
    background-color: #e1ecfe;
    color: #626995;
    font-size: 12px;
    padding: 2px 8px;
  }
`;

export const SelectBoardContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 16px;
  & > h6 {
    font-size: 14px;
    font-weight: 600;
    margin: 0;
  }
  & > div {
    flex: 1;
    margin-left: 12px;
  }
`;

export const BoardTypeTableContainer = styled.div`
  width: 100%;
  min-height: 300px;
`;
export const BoardTypeTableHeader = styled.div`
  padding: 12px 16px;
  border-bottom: 1px solid #ddd;
  background: #efeeea;
  color: #666;
  font-size: 14px;
`;
export const BoardAccordions = styled.div``;
export const BoardAccordionContainer = styled.div``;
export const BoardAccordionHeader = styled.div`
  padding: 12px 16px;
  background: #faf9f6;
  display: flex;
  align-items: center;
  font-size: 14px;
  border-bottom: 1px solid #ddd;
  cursor: ${({ isEditable }) => (isEditable ? 'pointer' : 'default')};
  & > svg {
    color: #999;
    font-size: 20px;
    margin-right: 6px;
  }
`;

export const BoardAccordionContent = styled.div`
  overflow: hidden;
`;

export const BoardTableEmpty = styled.div`
  padding: 24px;
  display: flex;
  font-size: 14px;
  align-items: center;
  justify-content: center;
`;

export const ValueRemoverContainer = styled.div`
  position: relative;

  &::before {
    content: '';
    position: absolute;
    border-radius: 0px 50px 50px 0px;
    height: 26px;
    width: 28px;
    left: 0px;
    top: -4px;
    z-index: 1;
  }
  &:hover::before {
    background: #ccf1eb;
  }
  svg {
    position: relative;
    z-index: 2;
  }
`;
