import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { chatgenieAPI } from 'src/config/api';
import { Button } from '@Thread-Magic/jasmine';
import { zonedTimeToUtc, format } from 'date-fns-tz';
import useToast from 'src/hooks/useToast';
import PropTypes from 'prop-types';
import { queryClient } from 'src/config/queryClient';
import queryKeys from 'src/constants/queryKeys';

export default function SyncTypeItem({ data, setCurrentSyncType, currentSyncType }) {
  const { toast } = useToast();

  // When sync is triggered, data.finished_at becomes null
  // we want keep last synced time as state here
  const [lastSyncedAt, setLastSyncedAt] = useState(
    data.finished_at && zonedTimeToUtc(data.finished_at.date, data.finished_at.timezone)
  );

  // Note: A settled request does not necessarily indicate that the synchronization process is complete.
  // Additional logic is required to accurately determine when the synchronization is in progress (see useEffect below)
  const { mutate } = useMutation(chatgenieAPI.integration.triggerSync, {
    onMutate() {
      setCurrentSyncType(data.type);
    },
    onError(err) {
      if (err?.response?.data?.message) {
        setCurrentSyncType(null);
        toast.error(err?.response?.data?.message);
      }
    },
    onSettled() {
      queryClient.invalidateQueries([queryKeys.GET_INTEGRATION_SYNC_TYPES]);
    }
  });

  useEffect(() => {
    if (data.finished_at) {
      const newFinishedAt = zonedTimeToUtc(data.finished_at.date, data.finished_at.timezone);
      if (!lastSyncedAt || lastSyncedAt.getTime() !== newFinishedAt.getTime()) {
        if (data.failed) {
          toast.error(`Failed to sync ${data?.name || 'data'} entity.`, {
            duration: 5000
          });
        } else {
          toast.success(`Done! Your ${data?.name || 'data'} have synced successfully.`, {
            duration: 5000
          });
        }
        setCurrentSyncType(null);
      }
      setLastSyncedAt(newFinishedAt);
    }
  }, [data]);

  return (
    <li key={data.id}>
      <div>
        <p>{data.name}</p>
        <span>
          {lastSyncedAt
            ? `Last synced at ${format(lastSyncedAt, 'p')} on ${format(lastSyncedAt, 'PP')}`
            : 'No syncs yet'}
        </span>
      </div>
      <Button
        isLoader={currentSyncType === data.type}
        isDisabled={!!currentSyncType}
        size="small"
        onClick={() =>
          mutate({
            type: data.type
          })
        }
      >
        Sync Now
      </Button>
    </li>
  );
}

SyncTypeItem.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    type: PropTypes.string,
    failed: PropTypes.bool,
    created_at: {
      date: PropTypes.string,
      timezone: PropTypes.string
    },
    finished_at: {
      date: PropTypes.string,
      timezone: PropTypes.string
    }
  }).isRequired,
  setCurrentSyncType: PropTypes.func.isRequired,
  currentSyncType: PropTypes.string
};

SyncTypeItem.defaultProps = {
  currentSyncType: null
};
