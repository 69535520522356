import React, { useCallback, useMemo, useState, memo } from 'react';
import PropTypes from 'prop-types';
import ActionModal from 'src/components/ActionModal';
import SimpleSelect from 'src/components/SimpleSelect';
import SimpleButton from 'src/components/SimpleButton';
import { useHistory } from 'react-router-dom';
import { Wrapper, Action } from './CustomerTenantMapModal.style';
import useClientCompanies from '../hooks/useClientCompanies';

const CustomerTenantMapModal = ({ isOpen = false, onClose }) => {
  const { isLoading, clientCompanies } = useClientCompanies();
  const [selectedClient, setSelectedClient] = useState(null);
  const history = useHistory();

  const options = useMemo(() => {
    return clientCompanies.map(company => ({
      label: company.name,
      value: company.id
    }));
  }, [clientCompanies]);

  const handleSelectChange = useCallback(value => {
    setSelectedClient(value);
  }, []);

  const handleClientDeploy = useCallback(() => {
    history.push(`/dashboard/clients/companies/${selectedClient.value}/connect`);
  }, [selectedClient]);

  return (
    <ActionModal
      isOpen={isOpen}
      size="small"
      toggleHandler={onClose}
      aligned="left"
      title="Map this Teams tenant to your client"
    >
      <Wrapper>
        <SimpleSelect
          label="Client"
          options={options}
          isLoading={isLoading}
          value={selectedClient}
          onChange={handleSelectChange}
        />
        <Action>
          <SimpleButton isDisabled={!selectedClient} onClick={handleClientDeploy}>
            Continue
          </SimpleButton>
        </Action>
      </Wrapper>
    </ActionModal>
  );
};

CustomerTenantMapModal.defaultProps = {
  isOpen: false
};

CustomerTenantMapModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired
};

export default memo(CustomerTenantMapModal);
