import React from 'react';
import * as Styled from './style';

const SimpleRadio = ({ htmlFor, id, name, value, onChange, checked, label, children }) => (
  <Styled.Label htmlFor={htmlFor}>
    <input type="radio" id={id} name={name} value={value} onChange={onChange} checked={checked} />
    <span>{label}</span>
    {children}
  </Styled.Label>
);

export default SimpleRadio;
