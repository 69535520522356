import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import OnboardingScreen from 'src/containers/OnboardingScreen';
import MessengerWidget from 'src/components/MessengerSettings/MessengerWidget';
import ColorPicker from 'src/components/MessengerDesign/ColorPicker';
import theme from 'src/config/theme';
import BotStyleBlock from 'src/components/BotStyleBlock';
import { Button } from '@Thread-Magic/jasmine';
import LinkButton from 'src/components/LinkButton';
import { useMutation, useQuery } from 'react-query';
import { chatgenieAPI } from 'src/config/api';
import queryKeys from 'src/constants/queryKeys';
import useToast from 'src/hooks/useToast';
import useAuthUser from 'src/hooks/useAuthUser';
import { processfile, setLocalStorage } from 'src/utils';
import { setPartialUserData } from 'src/redux/modules/users/usersActions';
import { useDispatch } from 'react-redux';
import { ColorExtractor } from 'react-color-extractor';
import { captureMessage } from '@sentry/react';
import * as Styled from './styles';
import content from './constants';

const SignupMessengerDesign = ({ history }) => {
  const { toast } = useToast();
  const dispatch = useDispatch();

  // For color extraction from logo, we need to convert logo url to blob
  const [logoBlobImage, setLogoBlobImage] = useState(null);
  const [extendedColors, setExtendedColors] = useState([]);
  const [messengerStyle, setMessengerStyle] = useState({
    primaryColor: theme.messenger.default.primaryColors[0],
    botIcon: {
      id: null,
      url: `${window.location.origin}${theme.messenger.default.botLogos[0]}`
    },
    botName: theme.messenger.default.botName
  });

  const { appId } = useAuthUser();

  const { data: designSettings, isLoading } = useQuery([queryKeys.GET_MESSENGER_DESIGN], () =>
    chatgenieAPI.getMessengerDesign()
  );

  const updateDesignSettings = useMutation(data => chatgenieAPI.updateMessengerDesign({ data }), {
    onSuccess() {
      setLocalStorage({
        onboardingStep: 'sync'
      });
      history.push('/signup/synchronization');
    },
    onError: error => {
      toast.error(error.message);
    }
  });

  const handleSubmit = () => {
    const data = {
      bot_icon_id: messengerStyle.botIcon?.id,
      bot_name: messengerStyle.botName,
      primary_color: messengerStyle.primaryColor
    };
    updateDesignSettings.mutate(data);
  };

  const handleGoBack = () => {
    setLocalStorage({
      onboardingStep: 'psa'
    });
    dispatch(
      setPartialUserData({
        cwConfiguration: false
      })
    );
    history.push('/signup/ticketing');
  };

  const handleDetectColorsFromLogo = colors => {
    setExtendedColors(colors.slice(0, 3));
    setMessengerStyle(old => ({ ...old, primaryColor: colors[0] }));
  };

  useEffect(() => {
    if (designSettings) {
      setMessengerStyle({
        primaryColor: designSettings.primary_color || theme.messenger.default.primaryColors[0],
        botName: designSettings.bot_name || theme.messenger.default.botName,
        botIcon: {
          id: designSettings.bot_icon?.id || null,
          url:
            designSettings.bot_icon?.path ||
            `${window.location.origin}${theme.messenger.default.botLogos[0]}`
        },
        logo: {
          id: designSettings.logo?.id || null,
          url:
            designSettings.logo?.path || `${window.location.origin}${theme.messenger.default.logo}`
        },
        trayIcon: {
          id: designSettings.tray_icon?.id || null,
          url:
            designSettings.tray_icon?.path ||
            `${window.location.origin}${theme.messenger.default.trayIcon}`
        }
      });
    }
  }, [designSettings]);

  useEffect(() => {
    if (messengerStyle.logo?.url) {
      processfile(messengerStyle.logo.url, 'logo')
        .then(myBlob => {
          const blobUrl = URL.createObjectURL(myBlob);
          setLogoBlobImage(blobUrl);
        })
        .catch(err => {
          captureMessage(`Failed to process logo from url: ${err}`);
        });
    }
  }, [messengerStyle.logo?.url]);

  return (
    <OnboardingScreen
      title={content.TITLE}
      step={3}
      isLoading={isLoading}
      aside={<MessengerWidget appId={appId} shouldUseSavedData={false} data={messengerStyle} />}
    >
      <Styled.Description>{content.DESCRIPTION}</Styled.Description>
      <Styled.Wrapper>
        <Styled.SubTitle>Primary color</Styled.SubTitle>
        {logoBlobImage && (
          <ColorExtractor getColors={handleDetectColorsFromLogo} src={logoBlobImage} />
        )}
        <ColorPicker
          additionalColors={extendedColors}
          color={messengerStyle?.primaryColor}
          onChange={primaryColor => setMessengerStyle(old => ({ ...old, primaryColor }))}
        />
      </Styled.Wrapper>
      <BotStyleBlock state={messengerStyle || {}} onChangeState={setMessengerStyle} />
      <Styled.Actions>
        <Button
          size="large"
          type="submit"
          isLoader={updateDesignSettings.isLoading}
          onClick={handleSubmit}
        >
          Continue
        </Button>
        <LinkButton styleMode="outline" size="large" type="button" onClick={handleGoBack}>
          Previous
        </LinkButton>
      </Styled.Actions>
    </OnboardingScreen>
  );
};

SignupMessengerDesign.propTypes = {
  history: PropTypes.objectOf(PropTypes.any)
};

SignupMessengerDesign.defaultProps = {
  history: {}
};

export default SignupMessengerDesign;
