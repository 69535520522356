import React, { useEffect, useState } from 'react';
import FadeInOutContainer from 'src/components/FadeInOutContainer';
import { SwitchTransition } from 'react-transition-group';
import { sleep } from 'src/utils';
import magicAnimated from 'src/assets/icons/magic-animated.gif';
import * as Styled from '../../style';

export default function GenerateAnimation() {
  const [action, setAction] = useState('start');

  const runAnimation = async () => {
    try {
      await sleep(2000);
      setAction('middle');
      await sleep(800);
      setAction('ready');
      await sleep(1000);
      setAction('destroy');
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('Failed to run generate animation', err);
    }
  };

  useEffect(() => {
    runAnimation();
  }, []);

  return (
    <Styled.GenerateAnimation shouldDestroy={action === 'destroy'}>
      <img src={magicAnimated} alt="Magic" width="64" height="64" />
      <SwitchTransition mode="in-out">
        <FadeInOutContainer fullHeight={false} transformType={null} key={action} timeout={300}>
          <h6>
            {['ready', 'destroy'].includes(action)
              ? 'Ready'
              : 'Generating your priorities with magic...'}
          </h6>
          {action === 'start' && <p>Setting your rules up</p>}
          {action === 'middle' && <p>Getting your test eviroment ready</p>}
        </FadeInOutContainer>
      </SwitchTransition>
    </Styled.GenerateAnimation>
  );
}
