import React from 'react';
import PropTypes from 'prop-types';

import cloudradialLogo from 'src/assets/images/cloudradial-logo.png';
import { useCloudradialFormPage } from './hook';
import { messageIntegrations } from '../../../../constants/integrations';
import { cloudradialFormFields } from '../../../../constants/cloudradial-form';
import { overlayChidrens } from '../../overlayTypes';

import FormInputWithLabel from '../../../../components/FormInputWithLabel';
import MessageForm from '../../../../components/MessageForm';
import SimpleButton from '../../../../components/SimpleButton';
import PageWrapper from '../../../../components/PageWrapper';
import Switcher from '../../../../components/Switcher';

import { ToolsStyled, ContentStyled } from '../../style';
import styles from '../../styles.module.scss';

const CloudradialForm = ({
  onSubmit,
  formData,
  isValid,
  status,
  visibleOverlay,
  configuration,
  errorMessage
}) => {
  const { portalUrl, setPortalUrl, formId, onFormSubmit, isActive, setIsActive } =
    useCloudradialFormPage({ onSubmit, formData, configuration });

  return (
    <PageWrapper
      title="Cloudradial"
      isBackButton
      isModalContent
      pageLogo={<img src={cloudradialLogo} alt="Cloudradial" height="40" />}
      tools={
        <ToolsStyled>
          <Switcher
            id="is-active-flow"
            checked={isActive}
            onChange={() => setIsActive(!isActive)}
            controlText={{ checked: 'on', unchecked: 'off' }}
          />
          <SimpleButton onClick={onFormSubmit} isLoader={visibleOverlay} isDisabled={!portalUrl}>
            Save
          </SimpleButton>
        </ToolsStyled>
      }
    >
      <ContentStyled>
        <p className={styles.description}>
          {
            'Let’s setup your Cloudradial integration, for this you’ll need an  account. This is a one time setup. '
          }
          <a
            href="https://docs.getthread.com/article/olxd3tx3zp-embedding-cloudradial-in-your-teams-app"
            target="_blank"
            rel="noopener noreferrer"
          >
            Need help?
          </a>
        </p>
        {errorMessage && <MessageForm typeView="block">{errorMessage}</MessageForm>}
        {isValid === false && (
          <MessageForm typeView="block">{messageIntegrations.NOT_VALID}</MessageForm>
        )}
        {isValid === true && !errorMessage && configuration.is_active && (
          <MessageForm typeView="block" typeMsg="success">
            {messageIntegrations.VALID}
          </MessageForm>
        )}
        <form className={styles.form} id={formId} onSubmit={onFormSubmit}>
          <FormInputWithLabel
            onChange={event => setPortalUrl(event.target.value)}
            value={portalUrl}
            {...cloudradialFormFields.PORTAL_URL}
          />
          <SimpleButton
            className={styles.button}
            type="submit"
            formId={formId}
            isDisabled={!portalUrl}
          >
            Connect
          </SimpleButton>
        </form>
      </ContentStyled>
      {visibleOverlay && overlayChidrens[status] && (
        <div className={styles.overlay}>
          {overlayChidrens[status].icon}
          <p>{overlayChidrens[status].text}</p>
        </div>
      )}
    </PageWrapper>
  );
};

CloudradialForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  formData: PropTypes.objectOf(PropTypes.any),
  isValid: PropTypes.bool
};

CloudradialForm.defaultProps = {
  formData: {},
  isValid: undefined
};

export default CloudradialForm;
