import { Button } from '@Thread-Magic/jasmine';
import styled from 'styled-components/macro';

export const CenteredContent = styled.div`
  width: 100%;
  max-width: 600px;
  min-height: 230px;
  margin: 60px auto;
  border: solid 1px #e0e0e0;
  border-radius: 5px;
  padding: 40px;
  background-color: #ffffff;
`;

export const Microsoft365LogoStyled = styled.div`
  margin-right: 20px;
  svg {
    display: block;
    margin: 86px auto 0 auto;
  }
`;

export const ContentFlexed = styled.div`
  display: flex;
  align-items: center;
`;

export const DangerOutlineButton = styled(Button)`
  color: #d0605c;
  border-color: #d0605c;
  min-width: 99px;
  :hover,
  :focus {
    background-color: #fdf1f2;
    color: #d0605c;
  }

  svg circle {
    stroke: #d0605c !important;
  }
`;

export const Heading = styled.h1`
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
`;

export const SubHeading = styled.h2`
  font-size: 18px;
  margin-bottom: 20px;
`;

export const Description = styled.p`
  margin: 24px 20px;
`;

export const ModalWrapper = styled.div`
  margin-bottom: 20px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0 20px;
  gap: 15px;
`;
