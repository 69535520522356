import styled from 'styled-components';

export const Wrapper = styled.div``;

export const Figure = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);

  svg {
    width: 106%;
    min-width: 200px;
  }
`;
