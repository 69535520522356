import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { chatgenieAPI } from 'src/config/api';
import useToast from 'src/hooks/useToast';
import ReactCrop, { convertToPixelCrop, centerCrop, makeAspectCrop } from 'react-image-crop';
import ActionModal from 'src/components/ActionModal';
import { isValidUrl } from 'src/utils';
import { Button } from '@Thread-Magic/jasmine';

import { ReactComponent as Spinner } from 'src/assets/icons/spinner.svg';
import { ReactComponent as UploadIcon } from 'src/assets/icons/upload-2.svg';
import { ReactComponent as PlusIcon } from 'src/assets/icons/plus.svg';

import 'react-image-crop/dist/ReactCrop.css';
import * as Styled from './style';

import { canvasPreview } from './canvasPreview';
import { useDebounceEffect } from './useDebounceEffect';

const centerAspectCrop = (mediaWidth, mediaHeight, aspect) => {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 90
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
};

const Attachment = ({
  onChange,
  value,
  className,
  placeholder,
  accept,
  aspect,
  validator,
  name,
  cropTitle,
  tabs,
  formErrorObject,
  components,
  uploadTitle,
  uploadText
}) => {
  const [isLoading, setIsLoading] = useState();
  const [iconPickerType, setIconPickerType] = useState(tabs && tabs[0].name);
  const [showAdvancedPopup, setShowAdvancedPopup] = useState(false);
  const [popupPosition, setPopupPosition] = useState({ x: '50%', y: '50%' });
  const { toast } = useToast();
  const defaultAccept = {
    width: 60,
    height: 60,
    x: 20,
    y: 20,
    unit: '%'
  };

  const uploadPlaceholder = (components?.uploadPlaceholder && components?.uploadPlaceholder()) || (
    <Styled.ImagePlaceholder>{placeholder || <UploadIcon />}</Styled.ImagePlaceholder>
  );

  const [imgSrc, setImgSrc] = useState('');
  const previewCanvasRef = useRef(null);
  const imgRef = useRef(null);
  const inputRef = useRef(null);
  const containerRef = useRef(null);
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState();
  const [isShowCropModal, setIsShowCropModal] = useState();

  const onSelectFile = event => {
    if (event.target.files && event.target.files.length > 0) {
      setCrop(undefined);
      if (!accept) setCrop(defaultAccept); // Makes crop preview update between images.
      const reader = new FileReader();
      reader.addEventListener('load', () => setImgSrc(reader.result?.toString() || ''));
      setIsShowCropModal(true);
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const handleHidePopupOnOutsideClick = event => {
    const container = document.querySelector('.icon-picker-container');

    if (
      event.target.className.includes &&
      !event.target.className.includes(`image-picker-container-${name}`) &&
      container &&
      !container.contains(event.target)
    ) {
      setShowAdvancedPopup(false);
    }
  };

  useEffect(() => {
    if (tabs) {
      window.addEventListener('click', handleHidePopupOnOutsideClick);
      return () => {
        window.removeEventListener('click', handleHidePopupOnOutsideClick);
      };
    }
  }, []);

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop);
      }
    },
    100,
    [completedCrop]
  );

  const onImageLoad = event => {
    const { width, height } = event.currentTarget;

    if (aspect) {
      setCrop(centerAspectCrop(width, height, aspect));
    } else {
      setCompletedCrop(convertToPixelCrop(defaultAccept, width, height));
    }
  };

  const handleImageChange = async (event, onChange) => {
    event.preventDefault(event);
    setIsShowCropModal(false);

    const blob = await new Promise(resolve => previewCanvasRef.current.toBlob(resolve));
    const file = new File([blob], name);

    const isValid = validator ? validator(file, name) : true;
    if (!isValid) {
      return;
    }

    const formData = new FormData();
    if (file instanceof File) formData.append('file', file);
    if (accept) formData.append('accept', accept);
    setIsLoading(true);

    try {
      const data = await chatgenieAPI.uploadAttachments({ data: formData });
      if (data.id) {
        onChange(data);
      }
      setIsLoading(false);
      setCompletedCrop(null);
    } catch ({ response }) {
      setCrop(null);
      setCompletedCrop(null);

      if (response?.data?.errors) {
        Object.keys(response.data.errors).map(key => {
          response.data.errors[key].map(msg => toast.error(msg));
        });
      }

      toast.error(response?.data.message);
      setIsLoading(false);
    }
  };

  const handleCloseCropper = () => {
    setIsShowCropModal(false);
    setCrop(defaultAccept);
    setCompletedCrop(null);
    inputRef.current.value = null;
  };

  const getPreview = (component, value = '') => {
    if (components?.imagePreview) {
      return components?.imagePreview(component);
    }

    return isValidUrl(value) || !value ? component : value;
  };

  const openAdvancedPopup = event => {
    if (tabs) {
      setShowAdvancedPopup(true);
      const container = containerRef.current;
      const popupWidth = 340;
      const target = container.getBoundingClientRect();
      const popupPositionX = event.clientX - target.left;
      const popupPositionY = event.clientY - target.top;

      setPopupPosition({
        x:
          popupPositionX + popupWidth >= container.offsetWidth && container.offsetWidth > popupWidth
            ? popupPositionX - popupWidth
            : popupPositionX,
        y: popupPositionY
      });
    }
  };

  return (
    <Styled.Wrapper className={className}>
      <Styled.ImageWrapper
        className={`image-picker-container-${name}`}
        onClick={openAdvancedPopup}
        ref={containerRef}
      >
        {!tabs && (
          <input type="file" ref={inputRef} onChange={onSelectFile} accept={accept} name={name} />
        )}
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            {value && !completedCrop && getPreview(<img src={value} alt={name} />, value)}
            {!value && !completedCrop && uploadPlaceholder}
            {!!completedCrop &&
              getPreview(
                <Styled.CanvasPreview
                  className="ReactCrop__canvas-preview"
                  ref={previewCanvasRef}
                />
              )}
          </>
        )}
      </Styled.ImageWrapper>
      {showAdvancedPopup && (
        <Styled.IconPopup className="icon-picker-container" position={popupPosition}>
          <Styled.PopupHeader>
            {tabs.map(({ name }) => (
              <Styled.PickerTab
                key={name}
                isActive={iconPickerType === name}
                type="button"
                onClick={() => setIconPickerType(name)}
              >
                {name}
              </Styled.PickerTab>
            ))}
            <Styled.PickerTab
              isActive={iconPickerType === 'custom'}
              type="button"
              onClick={() => setIconPickerType('custom')}
            >
              Custom
            </Styled.PickerTab>
          </Styled.PopupHeader>
          <div>
            {tabs.map(({ name, component }) =>
              name === iconPickerType ? (
                component(() => setShowAdvancedPopup(false))
              ) : (
                <Styled.ImagePicker key={name}>
                  <Styled.UploadImage htmlFor="custom">
                    <input id="custom" type="file" ref={inputRef} onChange={onSelectFile} />
                    <PlusIcon width="20" height="20" /> {uploadTitle}
                  </Styled.UploadImage>
                  {uploadText && <p>{uploadText}</p>}
                  {formErrorObject && (
                    <Styled.FieldError>{formErrorObject.message}</Styled.FieldError>
                  )}
                </Styled.ImagePicker>
              )
            )}
          </div>
        </Styled.IconPopup>
      )}
      <ActionModal
        isOpen={isShowCropModal}
        size="medium"
        title={cropTitle}
        toggleHandler={handleCloseCropper}
      >
        <Styled.CropContent>
          <Styled.CropArea>
            {!!imgSrc && (
              <ReactCrop
                crop={crop}
                onChange={(_, percentCrop) => setCrop(percentCrop)}
                onComplete={c => {
                  setCompletedCrop(c);
                }}
                aspect={aspect}
              >
                <img ref={imgRef} alt="Crop me" src={imgSrc} onLoad={onImageLoad} />
              </ReactCrop>
            )}
          </Styled.CropArea>
          <Button
            type="button"
            onClick={event => handleImageChange(event, onChange)}
            isLoading={isLoading}
          >
            Apply
          </Button>
        </Styled.CropContent>
      </ActionModal>
    </Styled.Wrapper>
  );
};
Attachment.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  accept: PropTypes.string,
  uploadText: PropTypes.string,
  uploadTitle: PropTypes.string,
  validator: PropTypes.func,
  name: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.any,
  aspect: PropTypes.number,
  cropTitle: PropTypes.string,
  tabs: PropTypes.arrayOf(PropTypes.any),
  formErrorObject: PropTypes.objectOf(PropTypes.any)
};

Attachment.defaultProps = {
  onChange: () => {},
  uploadTitle: 'Upload an image',
  aspect: undefined,
  name: '',
  accept: '',
  className: '',
  placeholder: '',
  validator: null,
  value: null,
  cropTitle: 'Crop Image',
  tabs: false,
  formErrorObject: null,
  uploadText: ''
};
export default Attachment;
