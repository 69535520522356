import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { isDevelopment } from 'src/utils';
import initilizeSentry from 'src/utils/sentry';
import './styles/main.css';
import App from './App';

if (!isDevelopment()) {
  initilizeSentry();
}

const root = createRoot(document.getElementById('root'));
root.render(<App />);
