import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as Spinner } from 'src/assets/icons/spinner.svg';

import { ButtonStyled } from './style';

const LinkButton = props => {
  const { children, isLoader } = props;
  return (
    <ButtonStyled {...props}>
      <span>{children}</span>
      {isLoader && <Spinner width="30" height="30" />}
    </ButtonStyled>
  );
};

LinkButton.propTypes = {
  children: PropTypes.node,
  isLoader: PropTypes.bool
};

LinkButton.defaultProps = {
  children: '',
  isLoader: false
};

export default LinkButton;
