import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import { useQuery } from 'react-query';
import { Switch } from '@Thread-Magic/jasmine';
import queryKeys from 'src/constants/queryKeys';
import { getContactsByCompany, inviteContact } from 'src/redux/modules/contacts/actions';
import { InputStyled } from 'src/components/FormInputWithLabel/style';
import { chatgenieAPI } from 'src/config/api';
import useToast from 'src/hooks/useToast';
import { ReactComponent as WarningIcon } from 'src/assets/icons/warning-mini.svg';
import { ReactComponent as Spinner } from 'src/assets/icons/spinner.svg';
import { parseJwt } from 'src/utils';
import * as Styled from './style';

const TOKEN_EXPIRATION_TIME = 15 * 60; // 15 minutes - need for circular progress

const getReturnValues = countDown => {
  const minutes = Math.floor(countDown / 60);
  const seconds = countDown - minutes * 60;
  const isOver = countDown === 0;

  return [minutes, seconds < 10 ? `0${seconds}` : seconds, isOver];
};

const WindowsInvitation = ({ match, location, appId, history, companyId }) => {
  const [isCopied, setIsCopied] = useState(false);
  const [isLinkLoading, setIsLinkLoading] = useState(false);
  const { toast } = useToast();
  const [magicLink, setMagicLink] = useState('');
  const [expirationTime, setExpirationTime] = useState();
  const [countDown, setCountDown] = useState(
    Math.ceil((expirationTime - new Date().getTime()) / 1000)
  );
  const [isM365Auth, setIsM365Auth] = useState(false);

  const { data, isLoading } = useQuery([queryKeys.GET_COMPANY, companyId], () =>
    chatgenieAPI.getCompany({ id: companyId })
  );

  const [minutes, seconds, isOver] = getReturnValues(countDown);
  const percentage = 1 - countDown / TOKEN_EXPIRATION_TIME;
  const inviteType = location.state?.inviteType;
  const selectedPlatform = data?.chat_platform?.platform;

  const handleCopy = () => {
    navigator.clipboard.writeText(magicLink).then(
      () => {
        setIsCopied(true);
      },
      err => {
        console.error('Async: Could not copy text: ', err);
      }
    );
  };

  const generateMagicLink = async () => {
    try {
      setIsLinkLoading(true);
      const res = await chatgenieAPI.getMagicLinkToken(match.params.company);
      const link = `${process.env.REACT_APP_MESSENGER_URL}/support-bot?appId=${appId}&type=${inviteType}&token=${res.data.data.token}`;
      const decodedToken = parseJwt(res.data.data.token);
      const expireTime = new Date(decodedToken.exp * 1000).getTime();
      setExpirationTime(expireTime);
      setCountDown(Math.ceil((expireTime - new Date().getTime()) / 1000));
      setMagicLink(link);
      setIsLinkLoading(false);
      setIsCopied(false);
      return link;
    } catch (err) {
      toast.error(`Error while generating link: ${err.message}`);
      setIsLinkLoading(false);
      return null;
    }
  };

  useEffect(() => {
    if (!expirationTime) {
      return () => {};
    }
    const interval = setInterval(() => {
      setCountDown(() => {
        const newValue = Math.ceil((expirationTime - new Date().getTime()) / 1000);
        if (newValue <= 0) {
          clearInterval(interval);
          return 0;
        }
        return newValue;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [expirationTime]);

  useEffect(() => {
    const handleReCalculateCountdown = () => {
      setCountDown(() => {
        const newValue = Math.ceil((expirationTime - new Date().getTime()) / 1000);
        if (newValue <= 0) {
          return 0;
        }
        return newValue;
      });
    };
    window.addEventListener('focus', handleReCalculateCountdown);
    return () => {
      window.removeEventListener('focus', handleReCalculateCountdown);
    };
  }, [expirationTime]);

  const openHelpDocs = () => {
    window.open(
      'https://docs.getthread.com/article/68gd2y9l0b-deploying-messenger-on-windows',
      '_blank'
    );
  };

  const handleM365Authentication = () => {
    setIsM365Auth(!isM365Auth);
  };

  useEffect(() => {
    if (isOver) {
      generateMagicLink();
    }
  }, [isOver]);

  useEffect(() => {
    if (appId && isM365Auth) {
      generateMagicLink();
    }
  }, [appId, isM365Auth]);

  if (selectedPlatform) {
    return (
      <Redirect to={{ pathname: `/dashboard/clients/companies/${companyId}/connect/status` }} />
    );
  }

  if (isLoading) {
    return (
      <Styled.Wrapper>
        <Styled.CentralizedContainer>
          <Spinner width={40} height={40} />
        </Styled.CentralizedContainer>
      </Styled.Wrapper>
    );
  }

  return (
    <Styled.Wrapper>
      <Styled.Title>Deploy Windows app</Styled.Title>
      <Styled.ButtonLink onClick={openHelpDocs}>
        Learn more about Windows app deployment
      </Styled.ButtonLink>
      <Styled.SubTitle>Auto authentication</Styled.SubTitle>

      <Styled.M365AuthContainer>
        <Styled.Desc>
          We’ll use the clients M365 credentials to auto-authenticate into Messenger.
        </Styled.Desc>
        <Styled.SwitchContainer onClick={handleM365Authentication}>
          <Switch
            controlText={{
              checked: 'on',
              unchecked: 'off'
            }}
            checked={isM365Auth}
            onChange={handleM365Authentication}
          />
        </Styled.SwitchContainer>
      </Styled.M365AuthContainer>

      {!isM365Auth && (
        <>
          <Styled.SubTitle>Silent install the app</Styled.SubTitle>
          <Styled.Code>
            msiexec /i
            &quot;https://assets.getthread.com/messenger/downloads/desktop/messenger.msi&quot;
            APP_ID=
            {appId} FLOW=customer INSTALL_TYPE=user /qn
          </Styled.Code>
        </>
      )}

      {isM365Auth && (
        <>
          <Styled.SubTitle>Connect to Microsoft 365</Styled.SubTitle>
          <Styled.Desc>
            Copy this link and open it in an new tab to connect to Microsoft 365.
          </Styled.Desc>
          <Styled.LinkContainer>
            <InputStyled
              value={magicLink || 'Loading'}
              hasError={isOver}
              disabled={isLinkLoading}
              readOnly
            />
            <Styled.LinkButton
              onClick={isOver ? generateMagicLink : handleCopy}
              isLoader={magicLink && isLinkLoading}
              styleMode="outline"
            >
              {isOver ? 'Regenerate' : isCopied ? 'Copied' : 'Copy link'}
            </Styled.LinkButton>
          </Styled.LinkContainer>
          {magicLink && expirationTime && (
            <Styled.CountdownContainer isOver={isOver}>
              {isOver ? (
                <>
                  <WarningIcon />
                  Link expired
                </>
              ) : (
                <>
                  <svg width="12" height="12">
                    <circle r="5" cx="6" cy="6" strokeDashoffset={(percentage || 0) * 32} />
                  </svg>{' '}
                  Link expires in {minutes}:{seconds}
                </>
              )}
            </Styled.CountdownContainer>
          )}
          <Styled.ButtonLink
            size="large"
            onClick={() => {
              // NB: for some reason, we need to go back two steps to see the plataform selection page (the previous page)
              history.go(-2);
            }}
          >
            Previous
          </Styled.ButtonLink>
        </>
      )}
    </Styled.Wrapper>
  );
};

WindowsInvitation.propTypes = {
  match: PropTypes.objectOf(PropTypes.any),
  appId: PropTypes.string.isRequired,
  companyId: PropTypes.number.isRequired,
  history: PropTypes.objectOf(PropTypes.any)
};

WindowsInvitation.defaultProps = {
  match: {},
  history: {}
};

const mapStateToProps = state => ({
  contactsByCompany: state.contactsReducer.contactsByCompany.data.map(contact => ({
    value: contact.email,
    label: contact.email,
    ...contact
  }))
});

const mapDispatchToProps = dispatch => ({
  getContactsByCompany: params => dispatch(getContactsByCompany(params)),
  inviteContact: props => dispatch(inviteContact(props))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(WindowsInvitation));
