import styled from 'styled-components';

export const WrapperStyled = styled.div`
  padding: 20px;
  text-align: center;

  h2 {
    margin-bottom: 20px;
  }

  a {
    text-transform: uppercase;
  }
`;
