import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { chatgenieAPI } from 'src/config/api';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import queryKeys from 'src/constants/queryKeys';
import useToast from 'src/hooks/useToast';
import SimpleButton from 'src/components/SimpleButton';
import { Checkbox } from '@Thread-Magic/jasmine';
import BotStyleBlock from 'src/components/BotStyleBlock';
import theme from 'src/config/theme';
import * as Styled from 'src/components/MessengerSettings/style';
import MessengerStyleBlock from './StyleBlock';
import MessengerWidget from '../MessengerSettings/MessengerWidget';

const DEFAULT_MESSENGER_DESKTOP_ICON =
  'https://chatgenie-messenger.s3.amazonaws.com/downloads/desktop/Messenger+Desktop+Logo.png';

const MessengerDesign = ({ companyId, appId, parentCompanyId }) => {
  const { toast } = useToast();
  const queryClient = useQueryClient();
  const isParentCompany = () => parentCompanyId === companyId;
  const [overrideDesign, setOverrideDesign] = useState(0);
  const [messengerStyle, setMessengerStyle] = useState({
    primaryColor: theme.messenger.default.primaryColors[0],
    logo: {
      id: null,
      url: `${window.location.origin}${theme.messenger.default.logo}`
    },
    trayIcon: {
      id: null,
      url: `${window.location.origin}${theme.messenger.default.trayIcon}`
    },
    desktopIcon: {
      id: null,
      url: DEFAULT_MESSENGER_DESKTOP_ICON
    }
  });
  const [botStyle, setBotStyle] = useState({
    botName: theme.messenger.default.botName,
    botIcon: {
      id: null,
      url: `${window.location.origin}${theme.messenger.default.botLogos[0]}`
    }
  });

  const { data: designSettings, isLoading: isLoadingDesignSettings } = useQuery(
    [queryKeys.GET_MESSENGER_DESIGN],
    () => chatgenieAPI.getMessengerDesign()
  );

  const { data: clientDesignSettings, isLoading: isLoadingClientDesignSettings } = useQuery(
    [queryKeys.GET_MESSENGER_DESIGN, companyId],
    () => chatgenieAPI.getCompanyMessengerDesign({ companyId }),
    { enabled: !isParentCompany() }
  );

  const isLoadingAllSettings = isLoadingDesignSettings || isLoadingClientDesignSettings;

  const mutateDesignSettings = useMutation(data => chatgenieAPI.updateMessengerDesign({ data }), {
    onSuccess: res => {
      queryClient.setQueryData([queryKeys.GET_MESSENGER_DESIGN], res);
      toast.success('Successfully saved');
    },
    onError: error => {
      toast.error(error.message);
    }
  });

  const mutateCompanyDesignSettings = useMutation(
    data =>
      clientDesignSettings.id
        ? chatgenieAPI.updateCompanyMessengerDesign({ data, companyId })
        : chatgenieAPI.createCompanyMessengerDesign({ data, companyId }),
    {
      onSuccess: res => {
        queryClient.setQueryData([queryKeys.GET_MESSENGER_DESIGN, companyId], res);
        toast.success('Successfully saved');
      },
      onError: error => {
        toast.error(error.message);
      }
    }
  );

  const updateSettings = async settings => {
    setMessengerStyle({
      primaryColor: settings?.primary_color || theme.messenger.default.primaryColors[0],
      logo: {
        id: settings?.logo?.id || null,
        url: settings?.logo?.path || `${window.location.origin}${theme.messenger.default.logo}`
      },
      trayIcon: {
        id: settings?.tray_icon?.id || null,
        url:
          settings?.tray_icon?.path ||
          `${window.location.origin}${theme.messenger.default.trayIcon}`
      },
      desktopIcon: {
        id: settings?.desktop_icon?.id || null,
        url: settings?.desktop_icon?.path || DEFAULT_MESSENGER_DESKTOP_ICON
      }
    });

    setBotStyle({
      botName: settings?.bot_name || theme.messenger.default.botName,
      botIcon: {
        id: settings?.bot_icon?.id || null,
        url:
          settings?.bot_icon?.path ||
          `${window.location.origin}${theme.messenger.default.botLogos[0]}`
      }
    });
  };

  useEffect(() => {
    if (designSettings || clientDesignSettings) {
      const settings = clientDesignSettings?.id ? clientDesignSettings : designSettings;
      if (!isParentCompany())
        setOverrideDesign(!clientDesignSettings?.id ? 0 : settings.override_design);
      updateSettings(settings, !clientDesignSettings?.id);
    }
  }, [designSettings, clientDesignSettings]);

  const onFormSubmit = event => {
    event.preventDefault();

    const currentSettings = {
      logo_id: messengerStyle.logo?.id,
      tray_icon_id: messengerStyle.trayIcon?.id,
      bot_icon_id: botStyle.botIcon?.id,
      bot_name: botStyle.botName,
      desktop_icon_id: messengerStyle.desktopIcon?.id,
      primary_color: messengerStyle.primaryColor
    };

    const parentSettings = {
      logo_id: designSettings.logo?.id,
      tray_icon_id: designSettings.tray_icon?.id,
      bot_icon_id: designSettings.bot_icon?.id,
      desktop_icon_id: designSettings.desktop_icon?.id,
      bot_name: designSettings.bot_name || theme.messenger.default.botName,
      primary_color: designSettings.primary_color || theme.messenger.default.primaryColors[0]
    };

    if (isParentCompany()) {
      mutateDesignSettings.mutate(currentSettings);
    } else {
      mutateCompanyDesignSettings.mutate({
        ...(overrideDesign ? { ...currentSettings } : { ...parentSettings }),
        override_design: overrideDesign
      });
    }
  };

  return (
    <Styled.LocalPageHolder isLoading={isLoadingAllSettings}>
      <Styled.Content>
        {!isLoadingAllSettings && (
          <Styled.Body>
            <Styled.Form
              id="save-settings"
              onSubmit={onFormSubmit}
              disabled={!isParentCompany() && !overrideDesign}
            >
              {!isParentCompany() && (
                <>
                  <Styled.FormGroup>
                    <Checkbox
                      className="override-design"
                      id="override-design"
                      checked={Boolean(overrideDesign)}
                      onChange={() => setOverrideDesign(overrideDesign ? 0 : 1)}
                    >
                      Override parent design
                    </Checkbox>
                  </Styled.FormGroup>
                  <hr />
                </>
              )}
              <Styled.Title marginTop={10}>Messenger styling</Styled.Title>
              <MessengerStyleBlock state={messengerStyle} onChangeState={setMessengerStyle} />
              <Styled.Title>Chatbot Settings</Styled.Title>
              <BotStyleBlock state={botStyle} onChangeState={setBotStyle} />
              <SimpleButton
                isLoader={mutateDesignSettings.isLoading || mutateCompanyDesignSettings.isLoading}
                type="submit"
                formId="save-settings"
                size="small"
              >
                Save
              </SimpleButton>
            </Styled.Form>
          </Styled.Body>
        )}
        {!isLoadingAllSettings && (
          <Styled.Aside>
            <MessengerWidget
              appId={appId}
              data={{
                ...messengerStyle,
                ...botStyle
              }}
              shouldUseSavedData={false}
            />
          </Styled.Aside>
        )}
      </Styled.Content>
    </Styled.LocalPageHolder>
  );
};

MessengerDesign.propTypes = {
  companyId: PropTypes.number.isRequired,
  parentCompanyId: PropTypes.number.isRequired,
  appId: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
  parentCompanyId: state.usersReducer.user.company.id
});

export default connect(mapStateToProps)(MessengerDesign);
