import axios from 'axios';
import { contentfulSpace, contentfulToken } from '../../../config/integration';
import { URL_API } from '../../../constants';
import { flowsTypes } from '../../../constants/action-types';
import { getHeaders } from '../../../utils';

export const createFlow = flow => (dispatch, getState) => {
  const {
    entityType,
    filterData,
    inboxChannel,
    channel,
    name,
    isCreateChannel,
    isReplyBroadcast,
    isActive,
    notifications,
    type,
    actions
  } = flow;

  return dispatch({
    type: flowsTypes.CREATE_FLOW,
    payload: axios({
      method: 'POST',
      url: `${URL_API}/api/flow/flows`,
      data: {
        entity_type: entityType,
        filter_data: JSON.stringify(filterData),
        inbox_channel_id: inboxChannel?.id,
        channel_id: channel && channel.id,
        name,
        type: type || 'regular',
        is_create_channel: isCreateChannel,
        is_reply_broadcast: isReplyBroadcast,
        is_active: isActive,
        notifications: notifications.map(item => item.code),
        actions,
        timezone_id: flow.timezone?.id
      },
      headers: getHeaders(getState)
    })
  });
};

export const updateFlow = flow => (dispatch, getState) => {
  const {
    id,
    entityType,
    filterData,
    inboxChannel,
    channel,
    name,
    isCreateChannel,
    isReplyBroadcast,
    isActive,
    notifications,
    type,
    actions
  } = flow;

  return dispatch({
    type: flowsTypes.UPDATE_FLOW,
    meta: { id },
    payload: axios({
      method: 'PUT',
      url: `${URL_API}/api/flow/flows/${id}`,
      data: {
        entity_type: entityType,
        filter_data: JSON.stringify(filterData),
        inbox_channel_id: inboxChannel?.id,
        channel_id: channel?.id || null,
        name,
        type,
        is_create_channel: isCreateChannel,
        is_reply_broadcast: isReplyBroadcast,
        is_active: isActive,
        notifications: notifications.map(item => item.code),
        actions,
        timezone_id: flow.timezone?.id
      },
      headers: getHeaders(getState)
    })
  });
};

export const getFlows =
  (props = {}) =>
  (dispatch, getState) => {
    dispatch({
      type: flowsTypes.GET_FLOWS,
      payload: axios({
        method: 'GET',
        url: `${URL_API}/api/flow/flows${props.queryString || ''}`,
        headers: getHeaders(getState)
      })
    });
  };

export const getFlow = id => (dispatch, getState) => {
  dispatch({
    type: flowsTypes.GET_FLOW,
    payload: axios({
      method: 'GET',
      url: `${URL_API}/api/flow/flows/${id}`,
      headers: getHeaders(getState)
    }).then(res => {
      return res;
    })
  });
};

export const getBoards =
  ({ query, page }) =>
  (dispatch, getState) =>
    dispatch({
      type: flowsTypes.GET_BOARDS,
      payload: axios({
        method: 'GET',
        url: `${URL_API}/api/boards?filter-name=~${query || ''}&page=${page || 1}`,
        headers: getHeaders(getState)
      })
    });

export const getStatuses =
  ({ query, boardId }) =>
  (dispatch, getState) =>
    dispatch({
      type: flowsTypes.GET_STATUSES,
      payload: axios({
        method: 'GET',
        url: `${URL_API}/api/statuses?filter-name=~${query || ''}&filter-board_id=${boardId || ''}`,
        headers: getHeaders(getState)
      })
    });

export const deleteFlow = id => (dispatch, getState) =>
  dispatch({
    type: flowsTypes.DELETE_FLOW,
    payload: axios({
      method: 'DELETE',
      url: `${URL_API}/api/flow/flows/${id}`,
      headers: getHeaders(getState)
    }),
    meta: { id }
  });

export const renameFlow = (id, name) => (dispatch, getState) => {
  const data = getState().flowsReducer.flows.data.filter(flow => flow.id === id)[0];

  return dispatch({
    type: flowsTypes.RENAME_FLOW,
    payload: axios({
      method: 'PUT',
      url: `${URL_API}/api/flow/flows/${id}`,
      headers: getHeaders(getState),
      data: {
        ...data,
        name,
        notifications: data.notifications.map(item => item.code)
      }
    }),
    meta: { id, name }
  });
};

export const getFilterAttributes = entity => (dispatch, getState) => {
  dispatch({
    type: flowsTypes.GET_FILTER_ATTRIBUTES,
    payload: axios({
      method: 'GET',
      url: `${URL_API}/api/flow/filter-attributes?filter-entity=${entity}`,
      headers: getHeaders(getState)
    })
  });
};

export const getFlowTemplates = () => dispatch => {
  dispatch({
    type: flowsTypes.GET_FLOW_TEMPLATES,
    payload: axios({
      method: 'GET',
      url: `https://cdn.contentful.com/spaces/${contentfulSpace}/environments/${process.env.REACT_APP_CONTENTFUL_ENV}/entries?content_type=templates&include=1`,
      headers: {
        Authorization: `Bearer ${contentfulToken}`
      }
    })
  });
};

export const searchAttributeValues = (id, q) => (dispatch, getState) =>
  dispatch({
    type: flowsTypes.SEARCH_FILTER_ATTRIBUTE_VALUES,
    payload: axios({
      method: 'GET',
      url: `${URL_API}/api/flow/filter-attributes/${id}/value?like=${q || ''}`,
      headers: getHeaders(getState)
    })
  }).then(res => res?.value?.data?.data);
