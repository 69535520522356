import React, { useState, useCallback, useEffect, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { match } from 'ts-pattern';
import { getPartnerClients } from 'src/redux/modules/deployment-center/actions';
import TableView from 'src/components/TableView';
import useAuthUser from 'src/hooks/useAuthUser';
import { columns } from './config';
import { Wrapper } from './PartnerQueueClient.style';
import ControlBar from '../components/ControlBar';
import { normalizeClientCompanyFilters } from '../utils';
import { useMagicAiTrial } from '../context/magicAiTrialContext';
import PartnerCenterIntegration from '../PartnerCenterIntegration';

const mapSortOptionToQueryParam = sortOption =>
  match(sortOption)
    .with('rank', () => 'rank')
    .with('requests', () => '-thread_usage')
    .with('name', () => 'name')
    .otherwise(() => 'rank');

const mapSortFieldToSortOption = sortField =>
  match(sortField)
    .with('rank', () => 'rank')
    .with('rank', () => 'rank')
    .with('thread_usage', () => 'requests')
    .with('name', () => 'name')
    .otherwise(() => 'rank');

const sortOptions = [
  { label: 'Recommended', value: 'rank' },
  { label: 'Requests', value: 'requests' },
  { label: 'Name', value: 'name' }
];

const PartnerQueueClient = ({
  viewFiltersAndSorting,
  setViewFiltersAndSorting,
  view,
  setView,
  getPartnerClients,
  clients,
  isFetchClients,
  errorMessage,
  history,
  location
}) => {
  const { featureFlags } = useAuthUser();
  const isPartnerCenterAvailable = featureFlags.includes('partner_center');

  const [isViewFiltersChanged, setIsViewFiltersChanged] = useState(false);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [checkedCompanies, setCheckedCompanies] = useState([]);
  const [excludedIds, setExcludedIds] = useState([]);

  const queryStringRef = useRef('');
  const { isTrialPeriodRunning } = useMagicAiTrial();

  const handleFilterChange = useCallback(newFilters => {
    setViewFiltersAndSorting(prev => ({ ...prev, filters: newFilters }));
    setIsViewFiltersChanged(true);
  }, []);

  const handleSortOptionChange = useCallback(newSortOption => {
    setViewFiltersAndSorting(prev => ({ ...prev, sortOption: newSortOption }));
    setIsViewFiltersChanged(true);
  }, []);

  const handleCheckCompany = useCallback(
    ids => {
      setCheckedCompanies(ids);
    },
    [checkedCompanies]
  );

  const handleExcludeCompany = useCallback(
    ids => {
      setExcludedIds(ids);
    },
    [checkedCompanies]
  );

  const handleOnSelectAll = checked => {
    setIsAllSelected(checked);
  };

  const handleGetPartnerClients = useCallback(
    params => {
      const { queryString = '' } = params;
      const existingUrlParams = new URLSearchParams(queryString);
      const page = existingUrlParams.get('page') || 1;
      const normalizedFilters = normalizeClientCompanyFilters(viewFiltersAndSorting.filters);

      const urlParams = new URLSearchParams();
      urlParams.set('page', page);

      Object.keys(normalizedFilters).forEach(key => {
        if (normalizedFilters[key]) {
          urlParams.set(key, normalizedFilters[key]);
        }
      });

      const sortParam = mapSortOptionToQueryParam(viewFiltersAndSorting.sortOption);
      urlParams.set('sort', sortParam);

      const search = existingUrlParams.get('query') || '';
      if (search.length > 0) {
        urlParams.set('query', search);
      }

      getPartnerClients({ ...params, queryString: urlParams.toString() });

      queryStringRef.current = urlParams.toString();
    },
    [viewFiltersAndSorting, getPartnerClients]
  );

  const handleClick = useCallback(row => {
    history.push({
      pathname: `/dashboard/clients/companies/${row.company_id}/connect`,
      state: {
        companyName: row.name,
        appId: row.company_id,
        search: location.search,
        fromDeploymentCenter: 1
      }
    });
  }, []);

  const handleActionSelectorChange = useCallback(() => {
    // NB: Refresh the current page if the action happened after select all based on current filters
    if (isAllSelected) {
      setExcludedIds([]);
      handleGetPartnerClients({ queryString: queryStringRef.current });
    }

    setCheckedCompanies([]);
    setIsAllSelected(false);
  }, [isAllSelected, viewFiltersAndSorting]);

  const normalizedClients = useMemo(() => {
    const data = clients.data.map(client => ({ ...client, id: client.company_id }));
    return { ...clients, data };
  }, [clients]);

  // NB: When there is a view or filter selected, come back to page 1 keeping the search query
  const resetPage = useCallback(() => {
    const existingUrlParams = new URLSearchParams(queryStringRef.current);
    const search = existingUrlParams.get('query') || '';

    const urlParams = new URLSearchParams();
    urlParams.set('page', 1);
    urlParams.set('query', search);
    history.replace({ search });

    queryStringRef.current = urlParams.toString();
  }, [queryStringRef.current]);

  useEffect(() => {
    if (view) {
      setViewFiltersAndSorting({
        filters: view.filter_data,
        sortOption: mapSortFieldToSortOption(view.sort_field)
      });
      setIsViewFiltersChanged(false);
      resetPage();
    }
  }, [view]);

  useEffect(() => {
    resetPage();
    handleGetPartnerClients({ queryString: queryStringRef.current });
  }, [viewFiltersAndSorting]);

  useEffect(() => {
    if (isTrialPeriodRunning) {
      handleGetPartnerClients({ queryString: queryStringRef.current });
    }
  }, [isTrialPeriodRunning]);

  return (
    <Wrapper>
      <ControlBar
        filters={viewFiltersAndSorting.filters}
        onFilterChange={handleFilterChange}
        viewFiltersChanged={isViewFiltersChanged}
        sortOption={viewFiltersAndSorting.sortOption}
        sortField={mapSortOptionToQueryParam(viewFiltersAndSorting.sortOption)}
        onSortOptionChange={handleSortOptionChange}
        view={view}
        checkedCompanies={checkedCompanies}
        excludedCompanies={excludedIds}
        onViewChange={setView}
        onActionsChange={handleActionSelectorChange}
        viewType="deployment_center"
        sortOptions={sortOptions}
        isAllSelected={isAllSelected}
      />

      {isPartnerCenterAvailable && <PartnerCenterIntegration />}

      <TableView
        getData={handleGetPartnerClients}
        isLoading={isFetchClients}
        error={errorMessage}
        columns={columns({ handleClick })}
        rows={normalizedClients}
        cellPadding={false}
        selectable
        onSelect={handleCheckCompany}
        selectedIds={checkedCompanies}
        onExclude={handleExcludeCompany}
        excludedIds={excludedIds}
        onSelectAll={handleOnSelectAll}
        isAllSelected={isAllSelected}
        clearSelected={checkedCompanies.length === 0}
      />
    </Wrapper>
  );
};

PartnerQueueClient.propTypes = {
  viewFiltersAndSorting: PropTypes.shape({}).isRequired,
  view: PropTypes.shape({}),
  setViewFiltersAndSorting: PropTypes.func.isRequired,
  setView: PropTypes.func.isRequired,
  getPartnerClients: PropTypes.func.isRequired,
  clients: PropTypes.objectOf(PropTypes.any),
  isFetchClients: PropTypes.bool,
  errorMessage: PropTypes.string,
  history: PropTypes.objectOf(PropTypes.any),
  location: PropTypes.objectOf(PropTypes.any)
};

PartnerQueueClient.defaultProps = {
  clients: {},
  isFetchClients: false,
  errorMessage: '',
  history: {},
  location: {},
  view: null
};

const mapStateToProps = state => ({
  clients: state.deploymentCenterReducer.clients,
  isFetchClients: state.deploymentCenterReducer.isFetchClients,
  errorMessage: state.deploymentCenterReducer.errorMessage
});

const mapDispatchToProps = dispatch => ({
  getPartnerClients: params => dispatch(getPartnerClients(params))
});

export default connect(mapStateToProps, mapDispatchToProps)(PartnerQueueClient);
