export const cwAutomateFormFields = {
  BASE_URL: {
    id: 'baseUrl',
    label: 'Base URL',
    placeholder: ''
  },
  USERNAME: {
    id: 'username',
    label: 'Username',
    placeholder: ''
  },
  PASSWORD: {
    id: 'password',
    label: 'Password',
    placeholder: ''
  }
};
