import { lighten } from 'polished';
import styled from 'styled-components';

export const GenerateTitleHeader = styled.div`
  h2 {
    font-size: 20px;
    margin-bottom: 12px;
  }
  p {
    font-size: 14px;
  }
`;

export const GenerateTitleBody = styled.div`
  margin-top: 48px;
  h5 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 16px;
  }

  & > div {
    display: flex;
    flex-direction: column;
    gap: 14px;
    font-size: 14px;
  }
`;

export const RecommendedTag = styled.div`
  font-size: 11px;
  font-style: normal;
  padding: 4px;
  font-weight: 600;
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.brand.light.base};
  background-color: ${({ theme }) => lighten(0.25, theme.colors.brand.light.hover)};
  margin: 0 4px;
`;
