import { differenceInCalendarDays } from 'date-fns';
import React, { useState, useContext, createContext, useEffect } from 'react';
import { useQuery } from 'react-query';
import { chatgenieAPI } from 'src/config/api';
import queryKeys from 'src/constants/queryKeys';

const MagicAiTrialContext = createContext();

export const MagicAiTrialProvider = ({ children }) => {
  const [isTrialPeriodRunning, setIsTrialPeriodRunning] = useState(false);
  const [daysLeft, setDaysLeft] = useState(null);

  const {
    isLoading: isLoadingTrialPeriod,
    data,
    refetch
  } = useQuery([queryKeys.GET_MAGIC_AI_TRIAL_PERIOD], () =>
    chatgenieAPI.deploymentCenter.magicAiTrialPeriod()
  );
  const magicAiTrialEndsAt = data?.data?.magic_ai_trial_ends_at;

  useEffect(() => {
    if (magicAiTrialEndsAt) {
      setIsTrialPeriodRunning(new Date().getTime() < new Date(magicAiTrialEndsAt).getTime());
      setDaysLeft(differenceInCalendarDays(new Date(magicAiTrialEndsAt), new Date()));
    }
  }, [magicAiTrialEndsAt]);

  return (
    <MagicAiTrialContext.Provider
      value={{
        isTrialPeriodRunning,
        setIsTrialPeriodRunning,
        magicAiTrialEndsAt,
        isLoadingTrialPeriod,
        daysLeft,
        refetch
      }}
    >
      {children}
    </MagicAiTrialContext.Provider>
  );
};

export const useMagicAiTrial = () => useContext(MagicAiTrialContext);
