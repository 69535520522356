import * as yup from 'yup';
import React from 'react';

const helpDocsLink = 'https://docs.getthread.com/article/8huxg3nm4l-creating-a-autotask-api-user';
const fields = {
  COMPANY_NAME: {
    id: 'companyName',
    name: 'companyName',
    label: 'Company Name',
    placeholder: 'Name of your company',
    fieldKey: 'company_name',
    helpTooltip: 'Same as the company name you use to login into the portal'
  },
  USERNAME: {
    id: 'username',
    name: 'username',
    label: 'Username (Key)',
    placeholder: 'Username (Key)',
    fieldKey: 'username',
    helpTooltip: (
      <>
        Follow{' '}
        <a href={helpDocsLink} rel="noopener noreferrer" target="_blank">
          this documentation
        </a>{' '}
        for more details
      </>
    )
  },
  PASSWORD: {
    id: 'password',
    name: 'password',
    type: 'password',
    label: 'Password (Secret)',
    placeholder: 'Password (Secret)',
    fieldKey: 'password',
    helpTooltip: (
      <>
        Follow{' '}
        <a href={helpDocsLink} rel="noopener noreferrer" target="_blank">
          this documentation
        </a>{' '}
        for more details
      </>
    )
  }
};
const schema = yup
  .object({
    [fields.COMPANY_NAME.name]: yup
      .string()
      .required()
      .label(fields.COMPANY_NAME.label)
      .default(''),
    [fields.USERNAME.name]: yup.string().required().label(fields.USERNAME.label).default(''),
    [fields.PASSWORD.name]: yup.string().required().label(fields.PASSWORD.label).default('')
  })
  .required();

export const FORM_ID = 'autotask-form';

export default {
  key: 'AUTOTASK',
  fields,
  schema,
  helpDocsLink,
  logo: { src: '/images/autotask-logo.png', width: 123, alt: 'Autotask logo' },
  formId: FORM_ID
};
