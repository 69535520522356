import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import useOutsideClick from 'src/hooks/useOutsideClick';
import { NavDropdownStyled, SelectedOption, Option, OptionList, ArrowDown } from './style';

const NavDropdown = ({
  options,
  size,
  position,
  onSelect,
  defaultOptionIndex,
  AlwaysSelectedOption
}) => {
  const ref = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(options[defaultOptionIndex]);

  useOutsideClick(ref, () => {
    setIsOpen(false);
  });

  function handleOptionClick(option) {
    setSelectedOption(option);
    onSelect(option);
    setIsOpen(false);
  }

  return (
    <NavDropdownStyled ref={ref} size={size} isOpen={isOpen}>
      <SelectedOption onClick={() => setIsOpen(!isOpen)}>
        {AlwaysSelectedOption || selectedOption.title}
        <ArrowDown width="15" height="8" fill="#BCC4D9" />
      </SelectedOption>
      <OptionList isOpen={isOpen} position={position}>
        {options.map(option => (
          <Option key={option.id} onClick={() => handleOptionClick(option)}>
            {option.title}
          </Option>
        ))}
      </OptionList>
    </NavDropdownStyled>
  );
};

NavDropdown.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.oneOfType([PropTypes.element, PropTypes.string])
    })
  ),
  AlwaysSelectedOption: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  size: PropTypes.oneOf(['large', 'medium']),
  position: PropTypes.oneOf(['top', 'bottom']),
  onSelect: PropTypes.func.isRequired,
  defaultOptionIndex: PropTypes.number
};

NavDropdown.defaultProps = {
  size: 'medium',
  position: 'bottom',
  options: [],
  defaultOptionIndex: 0,
  AlwaysSelectedOption: null
};

export default NavDropdown;
