import * as yup from 'yup';
import React from 'react';

const helpDocsLink =
  'https://docs.getthread.com/article/7xcblfrlbu-how-to-setup-halo-psa-integration';
const fields = {
  BASE_URL: {
    id: 'baseUrl',
    name: 'baseUrl',
    label: 'URL',
    placeholder: 'URL of your HaloPSA instance',
    fieldKey: 'base_url',
    helpTooltip: 'Same host name you use to login into the portal'
  },
  CLIENT_ID: {
    id: 'clientId',
    name: 'clientId',
    label: 'Client ID',
    placeholder: 'Client ID',
    fieldKey: 'client_id',
    helpTooltip: (
      <>
        Follow{' '}
        <a href={helpDocsLink} rel="noopener noreferrer" target="_blank">
          this documentation
        </a>{' '}
        for more details
      </>
    )
  },
  CLIENT_SECRET: {
    id: 'clientSecret',
    name: 'clientSecret',
    type: 'password',
    label: 'Client Secret',
    placeholder: 'Client Secret',
    fieldKey: 'client_secret',
    helpTooltip: (
      <>
        Follow{' '}
        <a href={helpDocsLink} rel="noopener noreferrer" target="_blank">
          this documentation
        </a>{' '}
        for more details
      </>
    )
  }
};
const schema = yup
  .object({
    [fields.BASE_URL.name]: yup.string().required().label(fields.BASE_URL.label).default(''),
    [fields.CLIENT_ID.name]: yup.string().required().label(fields.CLIENT_ID.label).default(''),
    [fields.CLIENT_SECRET.name]: yup
      .string()
      .required()
      .label(fields.CLIENT_SECRET.label)
      .default('')
  })
  .required();

export const FORM_ID = 'halo-form';

export default {
  key: 'HALO',
  fields,
  schema,
  helpDocsLink,
  logo: { src: '/images/halo-logo.png', width: 123, alt: 'halo logo' },
  formId: FORM_ID
};
