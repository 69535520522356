import styled from 'styled-components/macro';

export const MicrosoftIntegrationsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;

export const MicrosoftSyncsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

export const IntegrationsTitle = styled.h1`
  font-size: 20px;
  font-weight: 700;
  line-height: 19.52px;
  padding-bottom: 42px;
`;

export const SyncsTitle = styled.h1`
  font-size: 20px;
  font-weight: 700;
  line-height: 19.52px;
`;

export const SyncsSubtitle = styled.p`
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 20.16px;
  text-align: left;
  padding-bottom: 32px;
  color: ${props => props.theme.colors.text.variant3};
`;

export const MicrosoftConnectionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const MicrosoftConnectDescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
`;

export const MicrosoftConnectionTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flext-start;
  flex-grow: 1;
  padding-bottom: 12.34px;

  b {
    padding-right: 17px;
  }

  div {
    color: #27ae60;
    font-size: 14px;
    line-height: 16.93px;
    padding-right: 5px;
    padding-left: 1px;
  }
`;

export const MicrosoftConnectionIcon = styled.div`
  width: 100px;
  height: 100%;
`;

export const MicrosoftConnectionSubText = styled.p`
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.08px;
  text-align: left;
  color: ${props => props.theme.colors.text.variant3};
`;

export const SsoSwitchWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 10px;

  .switch-label {
    padding-right: 10px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    line-height: 21.56px;
    text-align: left;
  }
`;

export const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-top: 50px;
`;
