import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ReactComponent as Spinner } from 'src/assets/icons/spinner.svg';
import { getCannyToken } from '../../redux/modules/users/usersActions';

import { WrapperStyled, LoaderStyled } from './style';

const Feedback = ({
  basePath,
  boardToken,
  ssoToken,
  getCannyToken,
  isFetchCannyToken,
  location
}) => {
  useEffect(() => {
    if (!ssoToken) {
      getCannyToken();
    }
  }, []);

  useEffect(() => {
    if (ssoToken) {
      /* eslint-disable no-unused-expressions */
      (function (w, d, i, s) {
        function l() {
          if (!d.getElementById(i)) {
            const f = d.getElementsByTagName(s)[0];
            const e = d.createElement(s);
            (e.type = 'text/javascript'),
              (e.async = !0),
              (e.src = 'https://canny.io/sdk.js'),
              f.parentNode.insertBefore(e, f);
          }
        }
        if (typeof w.Canny !== 'function') {
          var c = function () {
            c.q.push(arguments);
          };
          (c.q = []),
            (w.Canny = c),
            d.readyState === 'complete'
              ? l()
              : w.attachEvent
              ? w.attachEvent('onload', l)
              : w.addEventListener('load', l, !1);
        }
      })(window, document, 'canny-jssdk', 'script');
      window.Canny('render', { boardToken, basePath, ssoToken });
    }

    return () => {
      if (ssoToken) {
        document.getElementById('canny-iframe')?.remove();
      }
    };
  }, [location.pathname, ssoToken]);

  return (
    <WrapperStyled data-canny>
      {isFetchCannyToken ? (
        <LoaderStyled>
          <Spinner />
        </LoaderStyled>
      ) : null}
    </WrapperStyled>
  );
};

Feedback.propTypes = {
  boardToken: PropTypes.string,
  basePath: PropTypes.string,
  ssoToken: PropTypes.string,
  getCannyToken: PropTypes.func.isRequired,
  isFetchCannyToken: PropTypes.bool
};

Feedback.defaultProps = {
  boardToken: '',
  basePath: null,
  ssoToken: null,
  isFetchCannyToken: false
};

const mapStateToProps = state => ({
  ssoToken: state.usersReducer.cannyToken,
  isFetchCannyToken: state.usersReducer.isFetchCannyToken
});

const mapDispatchToProps = dispatch => ({
  getCannyToken: entity => dispatch(getCannyToken(entity))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Feedback));
