import { useQuery } from 'react-query';
import Axios from 'axios';
import queryKeys from 'src/constants/queryKeys';
import { getHeaders, setLocalStorage } from 'src/utils';
import { URL_API } from 'src/constants';
import store from 'src/redux/store';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setFlowItemsReady } from 'src/redux/modules/users/usersActions';

// TODO: remove this fetched and use chatgenieAPI
const initFetcher = () =>
  Axios({
    method: 'GET',
    url: `${URL_API}/api/flow/items-init`,
    headers: getHeaders(store.getState)
  }).then(res => res.data.data);

// TODO: remove this fetched and use chatgenieAPI
const readyFetcher = () =>
  Axios({
    method: 'GET',
    url: `${URL_API}/api/flow/items-ready`,
    headers: getHeaders(store.getState)
  }).then(res => res.data.data);

const REFETCH_INTERVAL = 3000;

export const useSignUpSyncPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const flowItemsInit = useQuery([queryKeys.GET_FLOW_ITEMS_INIT], initFetcher);

  const flowItemsReady = useQuery([queryKeys.GET_FLOW_ITEMS_READY], readyFetcher, {
    enabled: flowItemsInit.status === 'success',
    refetchInterval: REFETCH_INTERVAL,
    onSuccess: data => {
      if (data?.status === 'ready') {
        setLocalStorage({
          onboardingStep: 'settings'
        });
        dispatch(
          setFlowItemsReady({
            flowItemsReady: true
          })
        );
        history.push('/signup/messenger/settings');
      }
    }
  });

  return {
    flowsInitError: flowItemsInit.error?.message,
    flowsReadyError: flowItemsReady.error?.message
  };
};
