import React from 'react';
import { match } from 'ts-pattern';
import { isValidUrl } from 'src/utils';
import { TeamsHeader, TeamsSidebar } from 'src/components/msTeamsParts';
import useWorkspaceSettings from 'src/hooks/useWorkspaceSettings';
import useAuthUser from 'src/hooks/useAuthUser';

import * as Styled from './style';

const TeamsAppPreview = () => {
  const authUser = useAuthUser();
  const { logo, botIcon, trayIcon, primaryColor, botName } = useWorkspaceSettings();
  const {
    icon,
    appName,
    featuredImage,
    blocks,
    title,
    logo: teamsLogo,
    isChatPlacementSwapped
  } = window.teamsAppState;

  return (
    <Styled.Wrapper>
      <TeamsSidebar iconUrl={icon} appName={appName} />
      <Styled.Page>
        <TeamsHeader>Microsoft Teams</TeamsHeader>
        <Styled.TeamsTitle>
          <img src={teamsLogo} alt={title} /> {title}
        </Styled.TeamsTitle>
        <Styled.Body>
          <Styled.Cover url={featuredImage} />
          <Styled.Content reverseRows={isChatPlacementSwapped}>
            <Styled.Blocks reverseRows={isChatPlacementSwapped}>
              {blocks.map(item => (
                <Styled.Block kye={item.id}>
                  <Styled.BlockIcon>
                    {isValidUrl(item.icon) ? <img src={item.icon} alt="" /> : item.icon}
                  </Styled.BlockIcon>
                  <Styled.BlockContent>
                    <Styled.Title>{item.title}</Styled.Title>
                    <Styled.Description>{item.description}</Styled.Description>
                  </Styled.BlockContent>
                  <Styled.BlockAction primaryColor={primaryColor}>
                    <a
                      href={match(item.type)
                        .with('phone', () => `tel:${item.value}`)
                        .with('email', () => `mailto:${item.value}`)
                        .otherwise(() => item.value)}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {item.label}
                    </a>
                  </Styled.BlockAction>
                </Styled.Block>
              ))}
            </Styled.Blocks>
            <Styled.Messenger
              appId={authUser.company.appId}
              image={logo?.path}
              botIcon={trayIcon?.path}
              botImage={botIcon?.path}
              primaryColor={primaryColor}
              botName={botName}
              isCloseButton={false}
            />
          </Styled.Content>
        </Styled.Body>
      </Styled.Page>
    </Styled.Wrapper>
  );
};

TeamsAppPreview.propTypes = {};

TeamsAppPreview.defaultProps = {};

export default TeamsAppPreview;
