import { dialogTypes } from '../../../constants/action-types';

export const clearDialog = () => dispatch => {
  dispatch({
    type: dialogTypes.CLEAR_DIALOG
  });
};
export const showDialog = payload => dispatch => {
  dispatch({
    type: dialogTypes.SHOW_DIALOG,
    payload
  });
};
