import styled from 'styled-components';

export const Label = styled.label`
  cursor: pointer;

  span {
    position: relative;
    display: flex;
    align-items: center;
    font-size: 14px;
  }

  span::after,
  span::before {
    content: '';
    display: block;
    position: relative;
    top: 0;
    flex-shrink: 0;
    border-radius: 50%;
    transition: all 0.15s;
    pointer-events: none;
  }

  span::before {
    width: 16px;
    height: 16px;
    margin-right: 10px;
    box-sizing: border-box;
    background-color: #ffffff;
    border: 1px solid #979797;
    box-shadow: 0 0 0 2px transparent;
    cursor: pointer;
    transition: all 0.15s;
  }

  span::after {
    position: absolute;
    left: 0;
    width: 8px;
    height: 8px;
    margin: 4px;
    opacity: 0;
    background: ${({ theme }) => theme.colors.accent.main};
  }

  input {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    border: 0;
    padding: 0;
    white-space: nowrap;
    clip-path: inset(100%);
    clip: rect(0 0 0 0);
    overflow: hidden;
  }

  input:checked + span::after {
    opacity: 1;
  }
`;
