import React from 'react';
import { CSSTransition } from 'react-transition-group';
import PropTypes from 'prop-types';
import * as Styled from './style';

function FadeInOutContainer({
  visible,
  timeout,
  transformType,
  children,
  fullHeight,
  style,
  ...rest
}) {
  return (
    <CSSTransition in={visible} timeout={timeout} unmountOnExit {...rest}>
      <Styled.FadeInOutContainer
        timeout={timeout}
        transformType={transformType}
        fullHeight={fullHeight}
        style={style}
      >
        {children}
      </Styled.FadeInOutContainer>
    </CSSTransition>
  );
}

FadeInOutContainer.propTypes = {
  visible: PropTypes.bool,
  timeout: PropTypes.number,
  transformType: PropTypes.oneOf(['vertical', 'horizontal', null]),
  fullHeight: PropTypes.bool,
  children: PropTypes.node.isRequired,
  style: PropTypes.object
};

FadeInOutContainer.defaultProps = {
  timeout: 500,
  transformType: 'vertical',
  style: {},
  fullHeight: true,
  visible: false
};
export default FadeInOutContainer;
