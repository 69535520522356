import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import useToast from 'src/hooks/useToast';
import PropTypes from 'prop-types';
import * as Styled from './styles';

const labels = {
  COPILOT_RECAP_TEMPLATE_DEFAULT: 'Default',
  COPILOT_RECAP_TEMPLATE_INCIDENT_REPORT: 'Incident report',
  COPILOT_RECAP_TEMPLATE_ESCALATION_REPORT: 'Escalation report',
  COPILOT_RECAP_TEMPLATE_CUSTOMER_FACING_REPORT: 'Customer facing report'
};

function RecapRulesPresets({ templates, rules, setRules, isLoading }) {
  const options = templates.map(template => ({
    value: template.scenario,
    label: labels[template.scenario]
  }));

  const matchedTemplate = templates.find(t => t.template === rules);
  const matchedScenario = matchedTemplate && matchedTemplate.scenario;
  const selectedOption = options.find(o => o.value === matchedScenario) || options[0];

  return (
    <Styled.FormRow>
      <Styled.FormCell>
        <Styled.FormInputLabel htmlFor="template">Use template</Styled.FormInputLabel>
      </Styled.FormCell>
      <Styled.FormCell fixed="250px">
        <Styled.CustomSelect
          id="template"
          options={options}
          value={selectedOption}
          isLoading={isLoading}
          isDisabled={isLoading}
          onChange={option =>
            !option.isDisabled &&
            setRules(templates.find(template => template.scenario === option.value).template || '')
          }
        />
      </Styled.FormCell>
    </Styled.FormRow>
  );
}

function RecapRulesEditor({ rules, templates, onSave, onChange }) {
  const [editedRules, setEditedRules] = useState(rules);
  const { toast } = useToast();
  const { mutateAsync: update, isLoading } = useMutation(() => onSave(editedRules), {
    onSuccess: () => {
      toast.success(`Successfully saved rules!`);
    },
    onError(err) {
      toast.error(`Failed to save rules: ${err?.response?.data?.message || err?.message}`);
    }
  });

  useEffect(() => {
    setEditedRules(rules);
  }, [rules]);

  useEffect(() => {
    onChange(editedRules);
  }, [editedRules]);

  function saveChanges(e) {
    e.preventDefault();
    update();
  }

  return (
    <Styled.Form onSubmit={saveChanges}>
      <Styled.PageTitle>Recap</Styled.PageTitle>
      <Styled.PageDescription>
        Let Magic AI generate a recap of what happened in the thread. You can customize the output
        by choosing a template and editing it or write your own.
      </Styled.PageDescription>
      <Styled.FormRow>
        <Styled.FormCell>
          <Styled.FormSectionLabel>Recap rules</Styled.FormSectionLabel>
        </Styled.FormCell>
        <Styled.FormCell align="right">
          <RecapRulesPresets
            templates={templates}
            rules={editedRules}
            setRules={setEditedRules}
            isLoading={isLoading}
          />
        </Styled.FormCell>
      </Styled.FormRow>
      <Styled.FormGroup>
        <Styled.Textarea
          value={editedRules}
          onChange={e => setEditedRules(e.target.value)}
          disabled={isLoading}
        />
        <Styled.CustomButton size="small" disabled={editedRules === rules} isLoader={isLoading}>
          Save
        </Styled.CustomButton>
      </Styled.FormGroup>
    </Styled.Form>
  );
}

RecapRulesEditor.propTypes = {
  rules: PropTypes.string.isRequired,
  templates: PropTypes.arrayOf({
    scenario: PropTypes.string,
    template: PropTypes.string
  }).isRequired,
  onSave: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
};

export default RecapRulesEditor;
