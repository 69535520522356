import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as Spinner } from 'src/assets/icons/spinner.svg';
import * as Styled from './style';

const PageBuilder = ({ title, actions, children, isLoading }) => (
  <Styled.Wrapper>
    <Styled.Header>
      <Styled.ButtonGroup>{actions[0]}</Styled.ButtonGroup>
      <Styled.Title>{title}</Styled.Title>
      <Styled.ButtonGroup>{actions[1]}</Styled.ButtonGroup>
    </Styled.Header>
    <Styled.ContentWrapper isLoading={isLoading}>
      {isLoading && <Spinner className="loader" />}
      {children}
    </Styled.ContentWrapper>
  </Styled.Wrapper>
);

PageBuilder.propTypes = {
  actions: PropTypes.array,
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
  isLoading: PropTypes.bool
};

PageBuilder.defaultProps = {
  actions: [],
  title: 'Title',
  isLoading: false
};

export default React.memo(PageBuilder);
