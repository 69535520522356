import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Prompt } from 'react-router-dom';
import ConfirmModal from '../ConfirmModal';

const RouteLeavingGuard = ({ when, navigate, shouldBlockNavigation }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  useEffect(() => {
    if (confirmedNavigation) {
      navigate(lastLocation.pathname);
    }
  }, [confirmedNavigation]);

  const showModal = location => {
    setIsModalVisible(true);
    setLastLocation(location);
  };

  const confirmNavigation = () => {
    if (lastLocation) {
      setConfirmedNavigation(true);
    }
  };

  const handleBlockedNavigation = nextLocation => {
    if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
      showModal(nextLocation);
      return false;
    }

    return true;
  };

  return (
    <Fragment>
      <Prompt when={when} message={handleBlockedNavigation} />
      <ConfirmModal
        isOpen={isModalVisible}
        toggleHandler={() => setIsModalVisible(!isModalVisible)}
        title="You have not saved your flow"
        description={
          <span>
            If you leave the page, you will lose your changes.
            <br />
            Are you sure you want to leave?
          </span>
        }
        modalType="error"
        submitText="Yes"
        onSubmit={confirmNavigation}
      />
    </Fragment>
  );
};

RouteLeavingGuard.propTypes = {
  when: PropTypes.bool.isRequired,
  navigate: PropTypes.func.isRequired,
  shouldBlockNavigation: PropTypes.func.isRequired
};

export default RouteLeavingGuard;
