import styled from 'styled-components';

export const LabelStyled = styled.label`
  user-select: none;
  display: block;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 6px;
  color: #000000;
`;

export const DescStyled = styled.p`
  font-size: 14px;
  margin-bottom: 10px;
`;
