import { components } from 'react-select';
import styled from 'styled-components';

export const LabelStyled = styled.label`
  user-select: none;
  display: block;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 6px;
  color: #000000;
`;

export const DescStyled = styled.p`
  font-size: 14px;
  margin-bottom: 10px;
`;

export const Option = styled(components.Option)`
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  input[type='checkbox'] {
    width: 1px;
    height: 1px;
    position: absolute;
    opacity: 0;
  }
  > * {
    margin: 0;
    padding: 0;
  }
`;

export const MultiValue = styled(components.MultiValue)`
  background-color: ${({ theme }) => theme.colors.brand.light.active};
  color: #fff;
  border-radius: 2px;

  svg {
    fill: #ffffff;
  }
`;

export const ValueContainer = styled(components.ValueContainer)`
  padding: 4px 8px !important;
`;
