import uuid from 'uuid';

export const getDefaultFilterData = (operatorTypes, attribute) => {
  const rules = attribute
    ? [
        {
          id: uuid.v4(),
          field: String(attribute.id) || '',
          operator: operatorTypes[attribute.type][0].name,
          value: ''
        }
      ]
    : [];

  return {
    id: uuid.v4(),
    rules,
    combinator: 'and',
    not: false
  };
};
