import { ReactComponent as HelpIcon } from 'src/assets/icons/help.svg';
import styled, { css } from 'styled-components/macro';

export const WrapperStyled = styled.div`
  position: relative;
  margin-bottom: 17px;
`;

export const ErrorMessageStyled = styled.span`
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.red1};
`;

export const LabelStyled = styled.label`
  display: flex;
  justify-content: space-between;
  margin-bottom: 6px;
  font-size: 16px;
  font-weight: 600;
  color: ${({ hasError, theme }) => (hasError ? theme.colors.red1 : '#000000')};
`;

export const Message = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  margin-top: 6px;

  svg {
    display: block;
    margin-right: 6px;
  }
`;

export const HelpIconStyled = styled(HelpIcon)`
  position: absolute;
  right: 0;
  top: 5px;
  right: 3px;
`;

export const InputStyled = styled.input`
  width: 100%;
  padding: 10px;
  border: 1px solid ${({ hasError, theme }) => (hasError ? theme.colors.red1 : '#DDDDDD')};
  border-radius: 2px;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
  background-color: #ffffff;
  transition: border-color 0.2s;

  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.colors.brand.light.base};
  }

  &::placeholder {
    color: #999999;
  }

  ${({ size }) => {
    switch (size) {
      case 'large':
        return css`
          padding: 12px 16px;
          font-size: 16px;
          line-height: 26px;
        `;
      default:
        return css`
          height: 40px;
          padding: 10px;
          font-size: 12px;
          line-height: 14px;
        `;
    }
  }}
`;
