import React, { useEffect, useState } from 'react';
import { Button } from '@Thread-Magic/jasmine';
import { ReactComponent as MicrosoftIcon } from 'src/assets/icons/microsoft.svg';
import usePartnerIntegrationState from 'src/pages/AppDeployer/hooks/usePartnerIntegrationState';
import { ReactComponent as Spinner } from 'src/assets/icons/spinner.svg';
import { partnerIntegrationStates } from 'src/pages/AppDeployer/constants';
import useAuthUser from 'src/hooks/useAuthUser';
import {
  Wrapper,
  Banner,
  IconWrapper,
  Description,
  ButtonWrapper,
  Title,
  LoadingIconWrapper
} from './PartnerCenterIntegration.style';

const PartnerCenterIntegration = () => {
  const { company } = useAuthUser();
  const { syncState, isLoading } = usePartnerIntegrationState({
    companyId: company.id,
    refetch: false
  });
  const [isSynced, setIsSynced] = useState();

  const handleStartAuthFlow = async () => {
    const clientId = process.env.REACT_APP_PARTNER_APP_CLIENT_ID;
    const redirectUrl = encodeURIComponent(
      `${process.env.REACT_APP_DOMAIN}/dashboard/deployment-assist/auth`
    );
    const url = `https://login.microsoftonline.com/common/oauth2/authorize?&client_id=${clientId}&response_type=code&redirect_url=${redirectUrl}`;

    window.location.replace(url);
  };

  useEffect(() => {
    if (syncState === partnerIntegrationStates.SYNCED) {
      setIsSynced(true);
      return;
    }

    setIsSynced(false);
  }, [syncState]);

  if (isLoading) {
    return (
      <Wrapper>
        <Banner>
          <LoadingIconWrapper>
            <Spinner width={40} height={40} />
          </LoadingIconWrapper>
        </Banner>
      </Wrapper>
    );
  }

  return (
    <>
      <Wrapper>
        <Banner>
          <IconWrapper>
            <MicrosoftIcon />
          </IconWrapper>
          <Description>
            <Title>
              {isSynced
                ? 'Microsoft Partner Center is connected'
                : 'Connect Microsoft Partner Center to see Teams usage.'}
            </Title>
          </Description>
          <ButtonWrapper>
            <Button styleMode="outline" onClick={handleStartAuthFlow}>
              {isSynced ? 'View Teams usage' : 'Connect'}
            </Button>
          </ButtonWrapper>
        </Banner>
      </Wrapper>
    </>
  );
};

export default PartnerCenterIntegration;
