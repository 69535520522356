import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding-bottom: 5%;
  svg path {
    fill: ${({ theme }) => theme.colors.text.main};
  }
`;

export const DotList = styled.div`
  margin-top: 16px;

  & > div {
    display: inline-block;
    width: 8px;
    height: 8px;
    margin: 0 2.5px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.gray4};
    animation: 0.6s ease-out;
    transform: translate3d(0, 0, 0);
    transition: transform 0.6s ease-out;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
  }

  & > div:nth-child(1) {
    animation-name: animateDot1;
  }

  & > div:nth-child(2) {
    animation-name: animateDot2;
  }

  & > div:nth-child(3) {
    animation-name: animateDot3;
  }

  @keyframes animateDot1 {
    0% {
      transform: scale(1) translate3d(0, 0, 0);
      background-color: ${({ theme }) => theme.colors.gray4};
    }
    20% {
      transform: scale(1) translate3d(0, 100%, 0);
      background-color: ${({ theme }) => theme.colors.text.main};
    }
    80% {
      transform: scale(1) translate3d(0, 0, 0);
      background-color: ${({ theme }) => theme.colors.gray4};
    }
  }
  @keyframes animateDot2 {
    13.333333333333334% {
      transform: scale(1) translate3d(0, 0, 0);
      background-color: ${({ theme }) => theme.colors.gray4};
    }
    33.333333333333336% {
      transform: scale(1) translate3d(0, 100%, 0);
      background-color: ${({ theme }) => theme.colors.text.main};
    }
    93.33333333333333% {
      transform: scale(1) translate3d(0, 0, 0);
      background-color: ${({ theme }) => theme.colors.gray4};
    }
  }
  @keyframes animateDot3 {
    26.666666666666668% {
      transform: scale(1) translate3d(0, 0, 0);
      background-color: ${({ theme }) => theme.colors.gray4};
    }
    46.66666666666667% {
      transform: scale(1) translate3d(0, 100%, 0);
      background-color: ${({ theme }) => theme.colors.text.main};
    }
    106.66666666666667% {
      transform: scale(1) translate3d(0, 0, 0);
      background-color: ${({ theme }) => theme.colors.gray4};
    }
  }
`;
