import styled from 'styled-components/macro';

export const List = styled.ul`
  max-height: 600px;
  overflow: auto;
  margin: 0 0 16px;
  padding: 0;
  list-style: none;

  & + button {
    flex-shrink: 0;
  }
`;

export const CTAs = styled.div`
  display: flex;

  button {
    margin-left: 8px;
  }
`;

export const WorkspaceName = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const Item = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray1};
  white-space: nowrap;
  font-size: 16px;
  line-height: 19px;

  &:first-child {
    border-top: 1px solid ${({ theme }) => theme.colors.gray1};
  }

  button {
    min-width: 100px;
  }
`;

export const Message = styled.p`
  margin: 2px 0;
  text-align: center;
  font-size: 14px;
`;

export const AddField = styled.input`
  width: 100%;
  margin-right: 20px;
  padding: 11px;
  line-height: 24px;
  border: 1px solid ${({ theme }) => theme.colors.gray1};
  border-radius: 2px;
  transition: border-color 0.2s;

  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.colors.accent.main};
  }
`;

export const Form = styled.form`
  display: flex;
  width: 100%;
`;
