import styled from 'styled-components/macro';
import { match } from 'ts-pattern';

const getTopOffset = props => {
  return match([props?.isTabs, props?.isTools])
    .with([true, false], () => 114)
    .with([false, true], () => 84)
    .with([true, true], () => 134)
    .with([false, false], () => 70)
    .run();
};

export const ContentAreaStyled = styled.div`
  width: calc(100% - 240px);
  min-width: 800px;
  margin: ${props => getTopOffset(props)}px 0 0 240px;
  padding: ${({ padding }) => padding ?? 20}px;
  height: 100%;
  ${({ backgroundColor }) => backgroundColor && `background-color: ${backgroundColor};`}
`;

export const ModalContentStyled = styled.div`
  position: relative;
  align-self: flex-start;
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 490px;
  min-height: 230px;
  margin: ${({ isPageLogo }) => (isPageLogo ? '20px auto' : '60px auto')};
  border: solid 1px #e0e0e0;
  border-radius: 5px;
  padding: 40px;
  background-color: #ffffff;
  z-index: 1;
`;

export const PageLogoStyled = styled.div`
  display: flex;
  justify-content: center;
  object-fit: contain;
  object-position: center;
  margin-top: 40px;

  svg {
    max-width: 100%;
  }
`;

export const MainPanelStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 22px 20px;
  background-color: #ffffff;
`;

export const TabsStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin: 0 -20px;
  padding: 0 20px;
  background-color: #ffffff;

  a {
    position: relative;
    margin: 0 16px;
    padding: 10px 0 18px;
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    color: #000000;
    white-space: nowrap;
    text-decoration: none;

    &.active {
      color: ${({ theme }) => theme.colors.brand.light.base};
    }

    &:first-child {
      margin-left: 0;
    }

    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 0;
      background-color: ${({ theme }) => theme.colors.brand.light.base};
      transition: all 0.2s;
    }

    &:hover {
      text-decoration: none;
      color: ${({ theme }) => theme.colors.brand.light.hover};
    }

    &:hover::after,
    &.active::after {
      height: 2px;
    }
  }
`;

export const TitleLabelStyled = styled.span`
  font-weight: 600;
  margin-right: 6px;
  color: #03194e;
`;

export const Title = styled.span`
  margin-right: 6px;
  color: #333333;
  font-size: 22px;
  line-height: 25px;
  font-weight: 400;
`;

export const OverlayStyled = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding-bottom: 80px;
  background-color: #ffffff99;

  svg {
    width: 58px;
    height: 58px;
  }

  p {
    margin-top: 10px;
    font-size: 14px;
    line-height: 18px;
    font-weight: bold;
  }
`;

export const ModalOverlayStyled = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding-bottom: 20px;
  background-color: #ffffff99;
`;
