import React from 'react';
import RankStars from '../components/RankStars';
import { NoValuesDescription } from './PartnerDeployedClients.style';
import AppIcon from '../components/AppIcon';
// eslint-disable-next-line import/named
import { MagicAIStatus } from '../PartnerQueueClients/PartnerQueueClient.style';

export const columns = () => [
  { key: 'company_name', title: 'Name', isSort: false },
  {
    key: 'is_messenger_enabled',
    title: 'Messenger',
    component: row => (row.is_messenger_enabled ? 'Enabled' : 'Disabled'),
    isSort: false
  },
  {
    key: 'magic_ai_status',
    title: 'Magic AI',
    component: row => <MagicAIStatus>{row.magic_ai_status?.toLowerCase()}</MagicAIStatus>,
    isSort: false
  },
  {
    key: 'app',
    title: 'Apps',
    component: row =>
      row.app.length === 0 ? (
        <NoValuesDescription>N/A</NoValuesDescription>
      ) : (
        row.app.split(',').map(app => <AppIcon app={app.trim()} />)
      ),
    isSort: false
  },
  {
    key: 'thread_usage',
    title: '# threads 90d',
    isSort: false,
    component: row =>
      row.thread_usage === 0 ? <NoValuesDescription>N/A</NoValuesDescription> : row.thread_usage
  },
  {
    key: 'magic_ai_transactions',
    title: '# Magic Transactions 90d',
    isSort: false,
    component: row =>
      row.thread_usage === 0 ? (
        <NoValuesDescription>N/A</NoValuesDescription>
      ) : (
        row.magic_ai_transactions
      )
  }
];
