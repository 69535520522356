import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { components } from 'react-select';
import useToast from 'src/hooks/useToast';
import { chatgenieAPI } from 'src/config/api';
import { downloadFile } from 'src/utils';
import { Loader } from 'src/containers/TeamsApps';
import { ReactComponent as EditIcon } from 'src/assets/icons/edit.svg';
import { ReactComponent as UploadIcon } from 'src/assets/icons/download.svg';
import PropTypes from 'prop-types';
import * as Styled from './style';

const ICONS_MAP = {
  download: <UploadIcon />,
  edit: <EditIcon />,
  change: <EditIcon />,
  select: <EditIcon />
};

export default function TeamsStatusActions({ app, companyId, currentUrl }) {
  const [isDownloading, setIsDownloading] = useState(false);
  const history = useHistory();
  const teamsAppId = app?.id;
  const { toast } = useToast();

  const getTeamsManifest = ({ teamsAppId, name, companyId }) => {
    if (!teamsAppId || !companyId) {
      return;
    }
    setIsDownloading(true);
    chatgenieAPI
      .downloadTeamsApp({ teamsAppId, companyId })
      .then(res => {
        downloadFile(res.data, `${name || 'app'}.zip`, 'application/zip');
      })
      .catch(error => {
        toast.error(error.message);
      })
      .finally(() => setIsDownloading(false));
  };

  const goToApps = () => {
    history.push({
      pathname: `/dashboard/clients/companies/${companyId}/connect/apps`,
      state: {
        action: 'updateApp'
      }
    });
  };

  const buildOptions = () => {
    if (teamsAppId) {
      return [
        { value: 'change', label: 'Change App' },
        { value: 'download', label: 'Download' },
        { value: 'edit', label: 'Edit' }
      ];
    }

    return [{ value: 'select', label: 'Select App' }];
  };

  const handleActionInstalledApp = event => {
    const { value } = event;

    if (value === 'download') {
      getTeamsManifest({ teamsAppId, name: app?.name, companyId });
      return;
    }

    if (value === 'edit') {
      history.push({
        pathname: `/dashboard/clients/companies/${companyId}/connect/apps/teams-app-builder/${teamsAppId}`,
        state: {
          backPathname: currentUrl
        }
      });
      return;
    }

    if (value === 'change' || value === 'select') {
      goToApps();
    }
  };

  return (
    <Styled.Col>
      <Styled.Subtitle>Installed app</Styled.Subtitle>
      <Styled.Wrapper isLoading={isDownloading}>
        {isDownloading && <Loader size={40} />}
        <Styled.Preview logo={app?.app_icon} />
        <Styled.Content flexRow={!app?.id}>
          <div>
            <Styled.Name>{app?.name || 'No App installed'}</Styled.Name>
            <Styled.Info>
              {app?.short_description || 'Choose an app and upload the zip'}
            </Styled.Info>
          </div>
        </Styled.Content>

        <Styled.Select
          id="im-notification"
          onChange={handleActionInstalledApp}
          value={{
            value: '',
            label: 'Options'
          }}
          components={{
            Option: ({ children, data, ...props }) => {
              return (
                <components.Option {...props}>
                  <Styled.Actions>
                    {ICONS_MAP[data.value]}
                    {children}
                  </Styled.Actions>
                </components.Option>
              );
            }
          }}
          options={buildOptions()}
        />
      </Styled.Wrapper>
    </Styled.Col>
  );
}

TeamsStatusActions.propTypes = {
  app: PropTypes.shape({
    name: PropTypes.string,
    short_description: PropTypes.string,
    app_icon: PropTypes.string,
    id: PropTypes.number
  }),
  companyId: PropTypes.number.isRequired,
  currentUrl: PropTypes.string.isRequired
};

TeamsStatusActions.defaultProps = {
  app: null
};
