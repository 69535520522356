import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as Styled from '../../style';
import ExceptionItem from './ExceptionItem';

const defaultRule = {
  text: '',
  selected_priority: null,
  isNew: true,
  ignore: false
};

function ExceptionsSection({ data }) {
  const [isNewRuleOpen, setIsNewRuleOpen] = useState(false);

  return (
    <Styled.PrioritizationSection noBottomBorder>
      <div>
        <h5>Custom Rules</h5>
      </div>
      <div>
        {data.rules.map(exception => (
          <ExceptionItem key={exception.rule_id} data={exception} />
        ))}
      </div>
      {isNewRuleOpen && (
        <ExceptionItem
          data={defaultRule}
          autoFocusInput
          onRemove={() => setIsNewRuleOpen(false)}
          onSave={() => setIsNewRuleOpen(false)}
        />
      )}
      <Styled.ExceptionAddButton
        visible={!isNewRuleOpen}
        type="button"
        onClick={() => setIsNewRuleOpen(true)}
      >
        + Add Rule
      </Styled.ExceptionAddButton>
    </Styled.PrioritizationSection>
  );
}

ExceptionsSection.propTypes = {
  data: PropTypes.object.isRequired
};

export default ExceptionsSection;
