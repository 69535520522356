import { Checkbox, SelectAsyncPaginate } from '@Thread-Magic/jasmine';
import React from 'react';
import { chatgenieAPI } from 'src/config/api';
import PropTypes from 'prop-types';
import { components } from 'react-select';
import { ReactComponent as RemoveIcon } from 'src/assets/icons/cross.svg';
import { queryClient } from 'src/config/queryClient';
import queryKeys from 'src/constants/queryKeys';
import { SelecOption, SelectBoardContainer, ValueRemoverContainer } from './style';

const Option = props => {
  const {
    data,
    isSelected,
    selectProps: { boardsMetaData, isMulti }
  } = props;
  const counts = boardsMetaData?.[data.id] || { filled_descriptions: 0, total_types: 0 };
  return (
    <components.Option {...props}>
      <SelecOption>
        <div>
          {isMulti && (
            <Checkbox
              id={data.id}
              className="non-selectable"
              size="small"
              checked={isSelected}
              readOnly
            />
          )}
          {data.name}
        </div>
        <span>
          {counts.total_types
            ? `${counts.filled_descriptions}/${counts.total_types}  types completed`
            : 'No types'}
        </span>
      </SelecOption>
    </components.Option>
  );
};

Option.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string
  }).isRequired,
  selectProps: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.object), PropTypes.object]),
    boardsMetaData: PropTypes.object,
    isMulti: PropTypes.bool
  }).isRequired,
  isSelected: PropTypes.bool.isRequired
};

const MultiValueRemove = props => {
  const {
    innerProps: { ref, ...restInnerProps }
  } = props;
  return (
    <ValueRemoverContainer {...restInnerProps} ref={ref}>
      <RemoveIcon width="16" height="16" fill="#999" />
    </ValueRemoverContainer>
  );
};

MultiValueRemove.propTypes = {
  innerProps: PropTypes.object.isRequired
};

const ClearIndicator = props => {
  const {
    getStyles,
    innerProps: { ref, ...restInnerProps }
  } = props;
  return (
    <span
      {...restInnerProps}
      ref={ref}
      style={{
        ...getStyles('clearIndicator', props),
        color: '#00BB99'
      }}
    >
      Remove all filters
    </span>
  );
};

ClearIndicator.propTypes = {
  getStyles: PropTypes.func.isRequired,
  innerProps: PropTypes.object.isRequired
};

const customStyles = {
  multiValue: () => ({
    background: '#EFEEEA',
    borderRadius: '32px',
    padding: '4px 4px 4px 8px',
    '& > div': {
      fontSize: '14px',
      lineHeight: '18px'
    },
    '& > div:first-child': {
      borderRight: '1px solid #ddd',
      padding: '0px 4px 0px 0'
    }
  }),
  menuList: () => ({
    margin: 8
  }),
  valueContainer: () => ({
    minHeight: '46px',
    padding: '8px 10px'
  }),
  option: state => ({
    padding: 8,
    borderRadius: 4,
    marginTop: 4,
    ...(state.isDisabled && {
      color: '#999',
      cursor: 'not-allowed',
      pointerEvents: 'none',
      '&:hover': {
        background: '#fff'
      }
    })
  })
};

const getOptions = (query, page) => {
  const cachedOptions = queryClient.getQueryData([queryKeys.GET_BOARDS]);
  const isInitialRequest = !query && page === 1;
  if (isInitialRequest && cachedOptions) {
    return Promise.resolve(cachedOptions);
  }
  return chatgenieAPI.getBoards({ query, page, normalizer: res => res.data }).then(res => {
    if (isInitialRequest) {
      queryClient.setQueryData([queryKeys.GET_BOARDS], res);
    }
    return res;
  });
};

function SelectMultipleBoard({
  value,
  onChange,
  boardsMetaData,
  isOptionDisabled,
  isMulti,
  closeMenuOnSelect,
  blurInputOnSelect,
  hideSelectedOptions,
  label
}) {
  return (
    <SelectBoardContainer>
      <h6>{label}</h6>
      <SelectAsyncPaginate
        isMulti={isMulti}
        isSearchable
        onChange={onChange}
        components={{
          MultiValueRemove,
          Option,
          ClearIndicator
        }}
        closeMenuOnSelect={closeMenuOnSelect}
        blurInputOnSelect={blurInputOnSelect}
        isOptionDisabled={option =>
          isOptionDisabled ? isOptionDisabled(option) : !boardsMetaData?.[option.id]?.total_types
        }
        getOptionValue={option => option.id}
        hideSelectedOptions={hideSelectedOptions}
        value={value}
        filterOption={() => true}
        noOptionsMessage={() => 'No options to show'}
        options={getOptions}
        boardsMetaData={boardsMetaData}
        placeholder="Select..."
        customStyles={customStyles}
      />
    </SelectBoardContainer>
  );
}
SelectMultipleBoard.propTypes = {
  value: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.object), PropTypes.object]),
  onChange: PropTypes.func.isRequired,
  boardsMetaData: PropTypes.object,
  isOptionDisabled: PropTypes.func,
  isMulti: PropTypes.bool,
  closeMenuOnSelect: PropTypes.bool,
  blurInputOnSelect: PropTypes.bool,
  hideSelectedOptions: PropTypes.bool,
  label: PropTypes.string
};

SelectMultipleBoard.defaultProps = {
  boardsMetaData: {},
  isOptionDisabled: null,
  value: null,
  isMulti: true,
  closeMenuOnSelect: false,
  blurInputOnSelect: false,
  hideSelectedOptions: false,
  label: ''
};

export default SelectMultipleBoard;
