import { useQuery } from 'react-query';
import { chatgenieAPI } from 'src/config/api';
import { partnerIntegrationStates } from '../../constants';

const defaultState = {
  isEnabled: false,
  lastSyncedAt: null,
  syncState: partnerIntegrationStates.PENDING
};

const usePartnerIntegrationState = ({ companyId, refetch = true }) => {
  const { data = defaultState, isLoading } = useQuery({
    queryKey: ['partnerIntegrationState', { companyId }],
    queryFn: async () => {
      const result = await chatgenieAPI.partnerCenter.status();
      return result.data.data;
    },
    refetchOnMount: refetch,
    refetchInterval: refetch ? 10000 : undefined
  });

  return {
    isLoading,
    isEnabled: data.isEnabled,
    syncState: data.syncState || partnerIntegrationStates.pending,
    lastSyncedAt: data.lastSyncedAt
  };
};

export default usePartnerIntegrationState;
