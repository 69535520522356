import React from 'react';
import { chatgenieAPI } from 'src/config/api';
import useToast from 'src/hooks/useToast';
import { useMutation } from 'react-query';
import MicrosoftConnection from './MicrosoftConnection';
import { MicrosoftSyncsWrapper, SyncsTitle, SyncsSubtitle } from './style';

const MICROSOFT_SYNCS_TITLE = 'M365 sync';
const MICROSOFT_MEMBERS_CONFIG = {
  text: 'Members',
  subText: 'Sync members from your Microsoft 365 account to Thread.' // TODO: update this once we have a mechanism for knowing last updated
};
const MICROSOFT_GROUPS_CONFIG = {
  text: 'Groups',
  subText: 'Sync groups from your Microsoft 365 account to Thread.' // TODO: update this once we have a mechanism for knowing last updated
};
const SYNC_BUTTON_LABEL = 'Sync now';

function MicrosoftSyncs() {
  const { toast } = useToast();

  const { mutateAsync: handleMembersConnect, isLoading: isMembersLoading } = useMutation(
    chatgenieAPI.syncMsUsers,
    {
      onSuccess: () => {
        toast.success('Members sync started successfully');
      },
      onError: () => {
        toast.error('Failed to sync members');
      }
    }
  );

  const { mutateAsync: handleGroupsConnect, isLoading: isGroupsLoading } = useMutation(
    chatgenieAPI.syncMsGroups,
    {
      onSuccess: () => {
        toast.success('Groups sync started successfully');
      },
      onError: () => {
        toast.error('Failed to sync groups');
      }
    }
  );

  return (
    <MicrosoftSyncsWrapper data-testid="microsoft-syncs-wrapper">
      <SyncsTitle>{MICROSOFT_SYNCS_TITLE}</SyncsTitle>
      <SyncsSubtitle>
        Future updates to members and groups will automatically be reflected in Thread.
      </SyncsSubtitle>
      <MicrosoftConnection
        text={MICROSOFT_MEMBERS_CONFIG.text}
        subText={MICROSOFT_MEMBERS_CONFIG.subText}
        isLoading={isMembersLoading}
        handleConnect={handleMembersConnect}
        buttonLabel={SYNC_BUTTON_LABEL}
      />
      <MicrosoftConnection
        text={MICROSOFT_GROUPS_CONFIG.text}
        subText={MICROSOFT_GROUPS_CONFIG.subText}
        isLoading={isGroupsLoading}
        handleConnect={handleGroupsConnect}
        buttonLabel={SYNC_BUTTON_LABEL}
      />
    </MicrosoftSyncsWrapper>
  );
}

export default MicrosoftSyncs;
