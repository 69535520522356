import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 2rem;
  padding-top: 0px;
  min-height: 550px;
`;

export const NoValuesDescription = styled.span`
  color: #999;
`;

export const Text = styled.div`
  width: 100%;
  padding: 15px 20px;
`;

export const MessengerStatus = styled.div`
  display: flex;
  align-items: center;

  img {
    margin-right: 5px;
    width: 20px;
    height: 20px;
  }
`;

export const MagicAIStatus = styled.div`
  display: flex;
  align-items: center;
  text-transform: capitalize;

  img {
    margin-right: 5px;
    width: 20px;
    height: 20px;
  }
`;

export const CollectingData = styled.div`
  color: #999;
  font-size: 12px;
`;
