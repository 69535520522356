import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getInfo, saveAppId } from 'src/redux/modules/users/usersActions';
import { getWorkspaces } from 'src/redux/modules/workspaces/actions';
import SelectWorkspace from './SelectWorkspace';
import useAuthUser from '../../hooks/useAuthUser';

const SelectWorkspaceContainer = () => {
  const dispatch = useDispatch();
  const authUser = useAuthUser();
  const history = useHistory();

  const { workspaces, isFetchWorkspaces, errorMessage } = useSelector(state => state.workspaces);

  const handleAddWorkspace = useCallback(() => {
    history.replace('/signup/welcome');
  }, [history]);

  useEffect(() => {
    if (!workspaces.length) {
      dispatch(getWorkspaces()).then(response => {
        if (!response.value.data?.data?.length) {
          handleAddWorkspace();
        }
      });
    }
  }, [handleAddWorkspace]);

  useEffect(() => {
    if (authUser.appId) {
      history.replace('/');
    }
  }, [history, authUser.appId]);

  if (authUser.appId) {
    return null;
  }

  return (
    <SelectWorkspace
      workspaces={workspaces}
      dispatch={dispatch}
      saveAppId={saveAppId}
      getInfo={getInfo}
      isFetchWorkspaces={isFetchWorkspaces}
      errorMessage={errorMessage}
      onAddWorkspace={handleAddWorkspace}
    />
  );
};

export default SelectWorkspaceContainer;
