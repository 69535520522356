import { Userpilot } from 'userpilot';
import { isProduction } from '.';

export const userPilotToken = process.env.REACT_APP_USER_PILOT_ID;

/**
 *
 * @param user User data
 * @param user.memberId User unique member id
 * @param user.fullname User full name
 * @param user.email User email
 * @param user.psaType Workspace psa type
 * @param user.hasPaidPlan Workspace has paid plan
 * @param user.company Workspace company data
 * @param user.company.id Workspace company id
 * @param user.company.name Workspace company name
 * @returns
 */
export const userPilotInit = user => {
  if (!isProduction()) {
    return;
  }

  Userpilot.initialize(userPilotToken);
  Userpilot.identify(user.memberId, {
    name: user.fullname,
    email: user.email,
    role: user.roles.admin ? 'admin' : 'basic',
    has_paid_plan: user.hasPaidPlan,
    company: {
      id: user.company.id,
      name: user.company.name,
      psa_type: user.psaType
    }
  });
};
