import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
`;

export const Trigger = styled.button`
  display: inline-flex;
  align-items: center;
  gap: 12px;
  background: #fff;
  padding: 11px 14px;
  border: 1px solid #ddd;
  border-radius: 2px;
  font-size: 14px;
  font-weight: 600;
  color: #666;
  user-select: none;

  .popover-variant-plain & {
    background: transparent;
    border: none;
  }

  & > svg {
    color: #666;
    font-size: 20px;
    pointer-events: none;
  }
`;

export const Content = styled.div`
  position: absolute;
  right: 0;
  top: 100%;
  background-color: #fff;
  min-width: 200px;
  border-radius: 4px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.08), 0 0 24px rgba(0, 0, 0, 0.2);
  z-index: 100;
`;

export const Title = styled.span`
  display: inline-flex;
  align-items: center;
  gap: 0.3em;

  strong {
    font-weight: 600;
  }
`;
