import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getFlowTemplates } from '../../redux/modules/flows/flowsActions';
import { useTemplates } from './hook';
import { TemplateTableColumns } from './config';

import PageWrapper from '../../components/PageWrapper';
import Table from '../../components/Table';

const Flows = ({ flowTemplates, flowTemplatesLoading, errorMessage, history }) => {
  useTemplates({ getFlowTemplates });

  return (
    <Fragment>
      <PageWrapper title="Templates">
        <Table
          className="template-table"
          error={errorMessage}
          isLoading={flowTemplatesLoading}
          columns={TemplateTableColumns()}
          rows={{ data: flowTemplates, meta: {} }}
          onRowClick={row =>
            history.push(`/dashboard/templates/create-flow?name=${row.name}&template=${row.id}`)
          }
          isSearch={false}
        />
      </PageWrapper>
    </Fragment>
  );
};

Flows.propTypes = {
  history: PropTypes.objectOf(PropTypes.any),
  flowTemplates: PropTypes.arrayOf(PropTypes.any),
  errorMessage: PropTypes.string,
  flowTemplatesLoading: PropTypes.bool
};

Flows.defaultProps = {
  history: {},
  errorMessage: '',
  flowTemplates: [],
  flowTemplatesLoading: false
};

const mapStateToProps = state => ({
  flowTemplates: state.flowsReducer.flowTemplates,
  flowTemplatesLoading: state.flowsReducer.flowTemplatesLoading,
  errorMessage: state.flowsReducer.flowTemplatesErrorMessage
});

const mapDispatchToProps = dispatch => ({
  getFlowTemplates: dispatch(getFlowTemplates())
});

export default connect(mapStateToProps, mapDispatchToProps)(Flows);
