import styled from 'styled-components/macro';

export const Wrapper = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: fit-content;
  width: 100%;
  height: 100%;
  margin-bottom: 20px;
  border: 1px solid ${({ theme }) => theme.colors.gray1};
  border-radius: 2px;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.colors.gray2};
  }

  img {
    display: block;
    margin: auto;
    width: auto;
    height: 100%;
  }

  input[type='file'] {
    position: absolute;
    left: -1000px;
    opacity: 0;
    width: 1px;
    height: 1px;
  }
`;
