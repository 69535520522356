import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getSubCompanies, updateMessengerStatus } from '../../../redux/modules/companies/actions';
import { companiesColumnsData } from './config';

import Table from '../../../components/Table';

const Companies = ({
  userRoles,
  getSubCompanies,
  subCompanies,
  isFetchSubCompanies,
  errorMessage,
  history,
  location,
  setMessengerStatus
}) => (
  <Fragment>
    <Table
      getData={getSubCompanies}
      isLoading={isFetchSubCompanies}
      error={errorMessage}
      onRowClick={row => {
        history.push({
          pathname: `/dashboard/clients/companies/${row.id}/connect`,
          state: { companyName: row.name, appId: row.app_id, search: location.search }
        });
      }}
      columns={companiesColumnsData(setMessengerStatus, userRoles)}
      rows={subCompanies}
      initialSortKey={{
        key: 'chat_platform',
        direction: 'asc'
      }}
    />
  </Fragment>
);

Companies.propTypes = {
  getSubCompanies: PropTypes.func.isRequired,
  setMessengerStatus: PropTypes.func.isRequired,
  userRoles: PropTypes.objectOf(PropTypes.any),
  subCompanies: PropTypes.objectOf(PropTypes.any),
  isFetchSubCompanies: PropTypes.bool,
  errorMessage: PropTypes.string,
  history: PropTypes.objectOf(PropTypes.any)
};

Companies.defaultProps = {
  userRoles: {},
  subCompanies: {},
  isFetchSubCompanies: false,
  errorMessage: '',
  history: {}
};

const mapStateToProps = state => ({
  userRoles: state.usersReducer.user.roles,
  subCompanies: state.companiesReducer.subCompanies,
  isFetchSubCompanies: state.companiesReducer.isFetchSubCompanies,
  errorMessage: state.companiesReducer.errorMessage
});

const mapDispatchToProps = dispatch => ({
  getSubCompanies: params => dispatch(getSubCompanies(params)),
  setMessengerStatus: params => dispatch(updateMessengerStatus(params))
});

export default connect(mapStateToProps, mapDispatchToProps)(Companies);
