import { Button as JasmineButton } from '@Thread-Magic/jasmine';
import SimpleButton from 'src/components/SimpleButton';
import styled from 'styled-components';

export const Wrapper = styled.div`
  max-width: 588px;
  margin-bottom: 16px;
  background: #ffffff;
  margin: 10vh auto 20px;
  padding: 30px 66px;
`;

export const Button = styled(SimpleButton)`
  margin-top: 16px;
  margin-bottom: 32px;
`;

export const Title = styled.h5`
  font-weight: 600;
  font-size: 16px;
`;
export const Desc = styled.p`
  font-size: 14px;
`;
export const LinkContainer = styled.div`
  display: flex;
  margin-bottom: 10px;
  div {
    flex: 1;
  }
`;
export const LinkButton = styled(JasmineButton)`
  height: 40px;
  margin-left: 10px;
`;
export const DownloadButton = styled(JasmineButton)`
  border: 0;
  color: ${({ theme }) => theme.colors.brand.light.base};
  font-weight: normal;
  padding: 0;
  &:hover,
  &:focus,
  &:active {
    background: transparent;
    color: ${({ theme }) => theme.colors.brand.light.hover};
  }
  & > svg {
    margin-right: 10px;
    stroke: ${({ theme }) => theme.colors.brand.light.base};
  }
`;

export const ButtonLink = styled.button`
  display: block;
  margin: 0 auto;
  border: none;
  background: none;
  font-weight: 700;
  font-size: 14px;
  line-height: 33px;
  text-align: center;
  color: ${({ theme }) => theme.colors.brand.light.base};
`;
export const LinkRegenerateContainer = styled.div`
  display: inline;
`;
export const LinkRegenerateButton = styled.button`
  border: none;
  background: none;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  color: ${({ theme }) => theme.colors.accent.main};
`;
export const CountdownContainer = styled.div`
  margin-bottom: 24px;
  display: inline-flex;
  align-items: center;
  background-color: ${({ isOver }) => (isOver ? '#FDEEEE' : '#FBEFC9')};
  color: ${({ isOver }) => (isOver ? '#EB5757' : '#7e7559')};
  padding: 2px 6px;
  font-weight: 600;
  font-size: 13px;
  border-radius: 2px;
  svg {
    margin-right: 4px;
  }
  svg circle {
    stroke-dasharray: 32px;
    stroke-linecap: round;
    stroke-width: 2px;
    stroke: ${({ fullRed, theme }) => (fullRed ? theme.colors.red1 : theme.colors.yellow1)};
    fill: none;
  }
`;

export const CentralizedContainer = styled.div`
  text-align: center;
`;
