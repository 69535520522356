import styled from 'styled-components';

export const FadeInOutContainer = styled.div`
  transition: ${({ timeout }) => timeout}ms;
  height: ${({ fullHeight }) => (fullHeight ? '100%' : 'auto')};
  &.enter {
    opacity: 0;
  }
  &.enter-active {
    opacity: 0;
    ${({ transformType }) =>
      transformType &&
      ` transform: ${transformType === 'vertical' ? 'translateY(50px)' : 'translateX(50px)'};`}
  }
  &.enter-done {
    opacity: 1;
    ${({ transformType }) =>
      transformType &&
      ` transform: ${transformType === 'vertical' ? 'translateY(0)' : 'translateX(0)'};`}
  }
  &.exit {
    opacity: 1;
    ${({ transformType }) =>
      transformType &&
      ` transform: ${transformType === 'vertical' ? 'translateY(0)' : 'translateX(0)'};`}
  }
  &.exit-active {
    opacity: 0;
    ${({ transformType }) =>
      transformType &&
      ` transform: ${transformType === 'vertical' ? 'translateY(50px)' : 'translateX(-50px)'};`}
  }
`;
