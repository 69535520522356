import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import * as Styled from './style';

const SimpleCheckbox = ({
  htmlFor,
  id,
  name,
  value,
  onChange,
  checked,
  indeterminate,
  label,
  title
}) => {
  const inputRef = useRef();

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.indeterminate = indeterminate;
    }
  }, [indeterminate]);

  return (
    <Styled.Label htmlFor={htmlFor} title={title}>
      <input
        type="checkbox"
        id={id}
        name={name}
        value={value}
        onChange={onChange}
        checked={checked}
        ref={inputRef}
      />
      <span>{label}</span>
    </Styled.Label>
  );
};

SimpleCheckbox.defaultProps = {
  htmlFor: null,
  label: '',
  title: '',
  id: null,
  name: null,
  value: null,
  indeterminate: false
};

SimpleCheckbox.propTypes = {
  htmlFor: PropTypes.string,
  label: PropTypes.string,
  title: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  indeterminate: PropTypes.bool
};

export default SimpleCheckbox;
