import React from 'react';
import PropTypes from 'prop-types';
import useAdminViews from 'src/hooks/useAdminViews';
import { List, ListItem, Wrapper } from './ViewSelector.style';
import Popover from '../Popover';

const ViewSelector = ({ onChange, type }) => {
  const { adminViews, isLoading } = useAdminViews({ type });
  return (
    <Popover title="View" closeOnSelect>
      <Wrapper>
        {isLoading ? (
          <span>Loading...</span>
        ) : (
          <List>
            {adminViews.map(view => (
              <ListItem key={view.id} type="button" onClick={() => onChange(view)}>
                {view.name}
              </ListItem>
            ))}
          </List>
        )}
      </Wrapper>
    </Popover>
  );
};

ViewSelector.defaultProps = {
  value: null
};

ViewSelector.propTypes = {
  type: PropTypes.string.isRequired,
  value: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string
  }),
  onChange: PropTypes.func.isRequired
};

export default ViewSelector;
