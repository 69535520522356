import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as StarIcon } from 'src/assets/icons/star.svg';
import { Wrapper } from './RankStars.style';

const RankStars = ({ rank }) => {
  if (rank === 0) {
    return <></>;
  }

  const buildStars = rank => {
    const stars = [];

    for (let i = 0; i < rank; i += 1) {
      stars.push(<StarIcon width={25} height={25} key={i} />);
    }

    return stars;
  };

  return <Wrapper>{buildStars(rank)}</Wrapper>;
};

RankStars.propTypes = {
  rank: PropTypes.number
};

RankStars.defaultProps = {
  rank: null
};

export default RankStars;
