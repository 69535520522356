import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as AuvikFullLogo } from 'src/assets/icons/auvik.svg';
import { useAuvikFormPage } from './hook';
import { messageIntegrations } from '../../../../constants/integrations';
import { auvikFormFields } from '../../../../constants/auvik-integration-form';
import { overlayChidrens } from '../../overlayTypes';

import FormInputWithLabel from '../../../../components/FormInputWithLabel';
import MessageForm from '../../../../components/MessageForm';
import SimpleButton from '../../../../components/SimpleButton';
import Switcher from '../../../../components/Switcher';
import PageWrapper from '../../../../components/PageWrapper';

import { ToolsStyled, ContentStyled } from '../../style';
import styles from '../../styles.module.scss';

const AuvikForm = ({
  onSubmit,
  formData,
  isValid,
  status,
  visibleOverlay,
  configuration,
  errorMessage
}) => {
  const {
    baseUrl,
    setBaseUrl,
    username,
    setUsername,
    apiKey,
    setApiKey,
    domainPrefix,
    setDomainPrefix,
    formId,
    onFormSubmit,
    isActive,
    setIsActive
  } = useAuvikFormPage({
    onSubmit,
    formData,
    configuration
  });

  return (
    <PageWrapper
      title="Auvik"
      isBackButton
      isModalContent
      pageLogo={<AuvikFullLogo height="40" />}
      tools={
        <ToolsStyled>
          <Switcher
            id="is-active-flow"
            checked={isActive}
            onChange={() => setIsActive(!isActive)}
            controlText={{ checked: 'on', unchecked: 'off' }}
          />
          <SimpleButton
            onClick={onFormSubmit}
            isLoader={visibleOverlay}
            isDisabled={!apiKey || !username || !baseUrl || !domainPrefix}
          >
            Save
          </SimpleButton>
        </ToolsStyled>
      }
    >
      <ContentStyled>
        <p className={styles.description}>
          {'Let’s setup your Auvik, this is a one time setup. '}
          <a
            href="https://docs.getthread.com/article/hdsf91b5fl-auvik-integration"
            target="_blank"
            rel="noopener noreferrer"
          >
            Need help?
          </a>
        </p>
        {errorMessage && <MessageForm typeView="block">{errorMessage}</MessageForm>}
        {isValid === false && (
          <MessageForm typeView="block">{messageIntegrations.NOT_VALID}</MessageForm>
        )}
        {isValid === true && !errorMessage && configuration.is_active && (
          <MessageForm typeView="block" typeMsg="success">
            {messageIntegrations.VALID}
          </MessageForm>
        )}
        <form className={styles.form} id={formId} onSubmit={onFormSubmit}>
          <FormInputWithLabel
            onChange={event => setBaseUrl(event.target.value)}
            value={baseUrl}
            {...auvikFormFields.BASE_URL}
          />
          <FormInputWithLabel
            onChange={event => setUsername(event.target.value)}
            value={username}
            {...auvikFormFields.USERNAME}
          />
          <FormInputWithLabel
            onChange={event => setApiKey(event.target.value)}
            value={apiKey}
            autoComplete="off"
            {...auvikFormFields.APIKEY}
          />
          <FormInputWithLabel
            onChange={event => setDomainPrefix(event.target.value)}
            value={domainPrefix}
            {...auvikFormFields.DOMAIN_PREFIX}
          />
          <SimpleButton
            className={styles.button}
            type="submit"
            formId={formId}
            isDisabled={!apiKey || !username || !baseUrl || !domainPrefix}
          >
            Connect
          </SimpleButton>
        </form>
      </ContentStyled>
      {visibleOverlay && overlayChidrens[status] && (
        <div className={styles.overlay}>
          {overlayChidrens[status].icon}
          <p>{overlayChidrens[status].text}</p>
        </div>
      )}
    </PageWrapper>
  );
};

AuvikForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  formData: PropTypes.objectOf(PropTypes.any),
  isValid: PropTypes.bool
};

AuvikForm.defaultProps = {
  formData: {},
  isValid: undefined
};

export default AuvikForm;
