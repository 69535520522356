import React, { useMemo, useState, useCallback } from 'react';
import PropTypes, { any } from 'prop-types';
import useAdminViews from 'src/hooks/useAdminViews';
import { Spinner } from 'src/assets/icons';
import LinkButton from 'src/components/LinkButton';
import { useLocation } from 'react-router-dom';
import { Wrapper, Title, Controls, Info, ActionButton } from './ControlBar.style';
import Popover from '../Popover';
import ClientFilter from '../ClientFilter';
import ClientSort from '../ClientSort';
import ViewSelector from '../ViewSelector';
import CreateViewModal from '../CreateViewModal';
import ActionsSelector from '../ActionsSelector';

const ControlBar = ({
  filters,
  onFilterChange,
  sortOption,
  sortField,
  onSortOptionChange,
  view,
  checkedCompanies,
  excludedCompanies,
  onViewChange,
  onActionsChange,
  viewFiltersChanged = false,
  viewType,
  sortOptions,
  showCompanyType = true,
  showAgreementType = true,
  showApp = true,
  showDeployStatus = true,
  isAllSelected = false
}) => {
  const location = useLocation();
  const [isCreateViewModalOpen, setIsCreateViewModalOpen] = useState(false);
  const [multiSelectValues, setMultiSelectValues] = useState({
    company_type: null,
    agreement_type: null
  });
  const { createAdminView, updateAdminView, isUpdating } = useAdminViews({
    type: viewType
  });

  const title = useMemo(() => {
    if (view) {
      return view.name;
    }
    return 'All Clients';
  }, [view]);

  const handleCreateView = useCallback(
    async name => {
      const sortOrder = sortField.startsWith('-') ? 'desc' : 'asc';
      const res = await createAdminView({
        name,
        filter_data: filters,
        sort_field: sortOrder === 'desc' ? sortField.slice(1) : sortField,
        sort_order: sortOrder
      });
      onViewChange(res);
      setIsCreateViewModalOpen(false);
    },
    [sortField, filters]
  );

  const handleUpdateView = useCallback(async () => {
    const sortOrder = sortField.startsWith('-') ? 'desc' : 'asc';
    const res = await updateAdminView({
      id: view.id,
      filter_data: filters,
      sort_field: sortOrder === 'desc' ? sortField.slice(1) : sortField,
      sort_order: sortOrder
    });
    onViewChange(res);
  }, [sortField, filters, view]);

  const handleClearFilterClick = () => {
    const regularState = location.pathname.includes('deployment-center/deployed')
      ? 'deployed'
      : 'any';

    setMultiSelectValues({
      company_type: null,
      agreement_type: null
    });
    onViewChange(null);
    onFilterChange({
      state: regularState
    });
  };

  const shouldShowActionsSelector = checkedCompanies.length > 0 || isAllSelected;
  const filtersCount = useMemo(() => {
    let count = 0;

    Object.keys(filters).forEach(key => {
      if (filters[key]) {
        count += 1;
      }
    });

    // The default state is when you clear filters or refresh page on Queue tab
    const isRegularState = filters.state === 'any';

    // Deployed tabm does not have the only deployed filter, this filter is applied by default
    const isDeployedHidden = filters.state === 'deployed' && !showDeployStatus;

    if (isDeployedHidden || isRegularState) count -= 1;

    return count;
  }, [filters]);

  return (
    <>
      <CreateViewModal
        onCreate={handleCreateView}
        isOpen={isCreateViewModalOpen}
        onClose={() => setIsCreateViewModalOpen(false)}
      />
      <Wrapper>
        <Info>
          <Title>{view?.name || title}</Title>
          {view && viewFiltersChanged && (
            <ActionButton className="outlined" onClick={handleUpdateView} disabled={isUpdating}>
              {isUpdating && Spinner}
              {isUpdating ? 'Updating' : 'Update view'}
            </ActionButton>
          )}
          {!view && viewFiltersChanged && (
            <ActionButton className="outlined" onClick={() => setIsCreateViewModalOpen(true)}>
              Save View
            </ActionButton>
          )}
          {view && viewFiltersChanged && (
            <ActionButton className="text-only" onClick={() => setIsCreateViewModalOpen(true)}>
              Save as New View
            </ActionButton>
          )}
        </Info>
        <Controls>
          {filtersCount > 0 && (
            <LinkButton onClick={handleClearFilterClick} type="button" style={{ marginRight: 5 }}>
              Clear filters
            </LinkButton>
          )}
          {shouldShowActionsSelector && (
            <ActionsSelector
              checkedCompanies={checkedCompanies}
              excludedCompanies={excludedCompanies}
              onClickCb={onActionsChange}
              filters={filters}
              isAllSelected={isAllSelected}
            />
          )}
          <ViewSelector value={view} onChange={onViewChange} type={viewType} />
          <Popover title={filtersCount ? `Filters (${filtersCount})` : 'Filters'}>
            <ClientFilter
              view={view}
              filters={filters}
              onChange={onFilterChange}
              onViewChange={onViewChange}
              showCompanyType={showCompanyType}
              showAgreementType={showAgreementType}
              showApp={showApp}
              showDeployStatus={showDeployStatus}
              multiSelectValues={multiSelectValues}
              setMultiSelectValues={setMultiSelectValues}
            />
          </Popover>
          <ClientSort value={sortOption} onChange={onSortOptionChange} sortOptions={sortOptions} />
        </Controls>
      </Wrapper>
    </>
  );
};

ControlBar.defaultProps = {
  filters: {
    state: 'any',
    app: null,
    companyType: ''
  },
  viewFiltersChanged: false,
  showCompanyType: true,
  showApp: true,
  showAgreementType: true,
  showDeployStatus: true,
  view: null,
  isAllSelected: false
};

ControlBar.propTypes = {
  filters: PropTypes.shape({
    state: PropTypes.string,
    app: PropTypes.string,
    companyType: PropTypes.string
  }),
  onFilterChange: PropTypes.func.isRequired,
  sortOption: PropTypes.string.isRequired,
  sortField: PropTypes.string.isRequired,
  onSortOptionChange: PropTypes.func.isRequired,
  view: PropTypes.shape(any),
  checkedCompanies: PropTypes.arrayOf(PropTypes.number).isRequired,
  excludedCompanies: PropTypes.arrayOf(PropTypes.number).isRequired,
  onViewChange: PropTypes.func.isRequired,
  onActionsChange: PropTypes.func.isRequired,
  viewFiltersChanged: PropTypes.bool,
  viewType: PropTypes.string.isRequired,
  sortOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string
    })
  ).isRequired,
  showCompanyType: PropTypes.bool,
  showApp: PropTypes.bool,
  showAgreementType: PropTypes.bool,
  showDeployStatus: PropTypes.bool,
  isAllSelected: PropTypes.bool
};

export default ControlBar;
