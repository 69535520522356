import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from 'react-query';
import queryKeys from 'src/constants/queryKeys';
import FadeInOutContainer from 'src/components/FadeInOutContainer';
import { chatgenieAPI } from 'src/config/api';
import PageHolder from 'src/components/PageHolder';
import { Wrapper } from '../style';
import ViewRecapRules from './ViewRecapRules';

const CopilotRecap = ({ companyId }) => {
  const { data: rulesLoaded, isFetching: isRulesLoading } = useQuery(
    [queryKeys.GET_COPILOT_RECAP_RULES],
    () => chatgenieAPI.recap.getRules(companyId),
    { refetchOnMount: true }
  );
  const { data: templates, isFetching: isTemplatesLoading } = useQuery(
    [queryKeys.GET_COPILOT_RECAP_TEMPLATES],
    () => chatgenieAPI.recap.getTemplates(companyId),
    {}
  );
  const { data: threads, isFetching: isThreadsLoading } = useQuery(
    [queryKeys.GET_COPILOT_RECAP_THREADS],
    () => chatgenieAPI.recap.getThreads(companyId),
    {}
  );

  const [pageLoaded, setPageLoaded] = useState(false);
  const [pageVisible, setPageVisible] = useState(false);
  const [rules, setRules] = useState('');
  const [rulesUpdated, setUpdatedRules] = useState('');

  useEffect(() => {
    const isRulesLoaded = !isRulesLoading;
    const isTemplatesLoaded = !isTemplatesLoading;
    const isThreadsLoaded = !isThreadsLoading;

    if (isRulesLoaded) {
      setRules(rulesLoaded);
      setUpdatedRules(rulesLoaded);
    }

    setPageLoaded(isRulesLoaded && isTemplatesLoaded && isThreadsLoaded);
  }, [rulesLoaded, isRulesLoading, templates, isTemplatesLoading, threads, isThreadsLoading]);

  useEffect(() => {
    setTimeout(() => setPageVisible(pageLoaded), 100);
  }, [pageLoaded]);

  const saveRules = rules => {
    return chatgenieAPI.recap.setRules(rules).then(() => {
      setRules(rules);
    });
  };
  const generateRecap = ticketId => chatgenieAPI.recap.generateRecap(ticketId, rulesUpdated);

  if (!pageLoaded) {
    return <PageHolder isLoading fullHeight />;
  }

  return (
    <Wrapper pageMode="view">
      <FadeInOutContainer visible={pageVisible}>
        <ViewRecapRules
          rules={rules}
          templates={templates}
          threads={threads}
          saveRules={saveRules}
          generateRecap={generateRecap}
          onChange={setUpdatedRules}
        />
      </FadeInOutContainer>
    </Wrapper>
  );
};

CopilotRecap.propTypes = {
  companyId: PropTypes.number.isRequired
};

export default CopilotRecap;
