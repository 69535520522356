import { contactsTypes, actionStatuses } from '../../../constants/action-types';

const defaultState = {
  contact: {},
  contacts: { data: [], meta: {} },
  contactsByCompany: { data: [], meta: {} },
  isFetchContacts: false,
  isFetchContactsByCompany: false,
  isFetchInviteContact: false,
  errorMessage: ''
};

const map = {
  // GET_CONTACTS
  [`${contactsTypes.GET_CONTACTS}${actionStatuses.FULFILLED}`]: (state, action) => ({
    ...state,
    isFetchContacts: false,
    contacts: action.payload.data,
    errorMessage: ''
  }),
  [`${contactsTypes.GET_CONTACTS}${actionStatuses.PENDING}`]: state => ({
    ...state,
    isFetchContacts: true,
    errorMessage: ''
  }),
  [`${contactsTypes.GET_CONTACTS}${actionStatuses.REJECTED}`]: (state, action) => ({
    ...state,
    isFetchContacts: false,
    errorMessage: action.payload.response.data.message
  }),

  // GET_CONTACT
  [`${contactsTypes.GET_CONTACT}${actionStatuses.FULFILLED}`]: (state, action) => ({
    ...state,
    isFetchContact: false,
    contact: action.payload.data.data,
    errorMessage: ''
  }),
  [`${contactsTypes.GET_CONTACT}${actionStatuses.PENDING}`]: state => ({
    ...state,
    isFetchContact: true,
    errorMessage: ''
  }),
  [`${contactsTypes.GET_CONTACT}${actionStatuses.REJECTED}`]: (state, action) => ({
    ...state,
    isFetchContact: false,
    errorMessage: action.payload.response.data.message
  }),

  // GET_CONTACTS_BY_COMPANY
  [`${contactsTypes.GET_CONTACTS_BY_COMPANY}${actionStatuses.FULFILLED}`]: (state, action) => ({
    ...state,
    isFetchContactsByCompany: false,
    contactsByCompany: action.payload.data,
    errorMessage: ''
  }),
  [`${contactsTypes.GET_CONTACTS_BY_COMPANY}${actionStatuses.PENDING}`]: state => ({
    ...state,
    isFetchContactsByCompany: true,
    errorMessage: ''
  }),
  [`${contactsTypes.GET_CONTACTS_BY_COMPANY}${actionStatuses.REJECTED}`]: (state, action) => ({
    ...state,
    isFetchContactsByCompany: false,
    errorMessage: action.payload.response.data.message
  }),

  // INVITE_CONTACT
  [`${contactsTypes.INVITE_CONTACT}${actionStatuses.FULFILLED}`]: state => ({
    ...state,
    isFetchInviteContact: false,
    errorMessage: ''
  }),
  [`${contactsTypes.INVITE_CONTACT}${actionStatuses.PENDING}`]: state => ({
    ...state,
    isFetchInviteContact: true,
    errorMessage: ''
  }),
  [`${contactsTypes.INVITE_CONTACT}${actionStatuses.REJECTED}`]: (state, action) => ({
    ...state,
    isFetchInviteContact: false,
    errorMessage: action.payload.response.data.message
  })
};

export default (state = defaultState, action) => {
  return (map[action.type] && map[action.type](state, action)) || state;
};
