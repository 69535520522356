import axios from 'axios';

import { URL_API } from '../../../constants';
import { getHeaders } from '../../../utils';
import { workspacesTypes, usersTypes } from '../../../constants/action-types';

export const getWorkspaces = () => (dispatch, getState) =>
  dispatch({
    type: workspacesTypes.GET_WORKSPACES,
    payload: axios({
      method: 'GET',
      url: `${URL_API}/api/workspaces`,
      headers: getHeaders(getState)
    })
  });

export const addWorkspace = data => (dispatch, getState) =>
  dispatch({
    type: workspacesTypes.ADD_WORKSPACE,
    payload: axios({
      method: 'POST',
      url: `${URL_API}/api/companies`,
      headers: getHeaders(getState),
      data
    }).then(({ data }) => {
      dispatch({ type: usersTypes.SAVE_APP_ID, payload: { appId: data.data.app_id } });
    })
  });
