import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { psaTranslate } from 'src/utils/psa';
import * as Styled from '../../style';
import TypesListItem from './TypesListItem';
import { BoardTableEmpty } from './style';

function TypesList({ types, isEditable, onChange }) {
  const topHeader = isEditable
    ? [psaTranslate('Type'), 'Definition', 'Ignore']
    : [psaTranslate('Type'), 'Definition'];
  const orderedTypes = useMemo(() => {
    const data = types.slice();
    if (!isEditable) {
      // Ignored types should be last ones, types with description should come first
      data.sort((a, b) => {
        if (a.ignore && !b.ignore) {
          return 1;
        }
        if (!a.ignore && b.ignore) {
          return -1;
        }

        if (!a.description && b.description) {
          return 1;
        }
        if (a.description && !b.description) {
          return -1;
        }

        return a.id - b.id;
      });
    }
    return data;
  }, [types, isEditable]);

  return (
    <Styled.TypesListContainer>
      <Styled.TypesListHeader isEditable={isEditable}>
        {topHeader.map(title => (
          <span key={title}>{title}</span>
        ))}
      </Styled.TypesListHeader>
      {orderedTypes.length ? (
        orderedTypes.map(item => (
          <TypesListItem key={item.id} isEditable={isEditable} data={item} onChange={onChange} />
        ))
      ) : (
        <BoardTableEmpty>No {psaTranslate('board')} types to show</BoardTableEmpty>
      )}
    </Styled.TypesListContainer>
  );
}

TypesList.propTypes = {
  types: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func,
  isEditable: PropTypes.bool
};
TypesList.defaultProps = {
  onChange: () => {},
  isEditable: true
};

export default TypesList;
