import React from 'react';
import PropTypes from 'prop-types';
import Kaseya from 'src/pages/SignupTicketing/Kaseya';
import { FORM_ID } from 'src/pages/SignupTicketing/Kaseya/config';
import { messageIntegrations } from '../../../../constants/integrations';
import { overlayChidrens } from '../../overlayTypes';
import MessageForm from '../../../../components/MessageForm';
import SimpleButton from '../../../../components/SimpleButton';
import PageWrapper from '../../../../components/PageWrapper';
import Switcher from '../../../../components/Switcher';
import { ToolsStyled, ContentStyled } from '../../style';
import styles from '../../styles.module.scss';
import { useControlForm } from '../../hook';

const BMSForm = ({
  onSubmit,
  formData,
  isValid,
  status,
  visibleOverlay,
  configuration,
  errorMessage
}) => {
  const { onFormSubmit, isActive, setIsActive, canSubmit, defaultValues, onFormValid } =
    useControlForm({
      onSubmit,
      formData,
      configuration,
      defaultValueNormalizer(data) {
        return {
          ...data,
          ...(data.hostV2 && {
            hostV2: {
              value: data.hostV2,
              label: data.hostV2
            }
          }),
          host: data.baseUrl
        };
      },
      submitValueNormalizer(data) {
        return {
          host: data.host,
          hostV2: data.hostV2.value,
          companyName: data.companyName,
          username: data.username,
          password: data.password
        };
      }
    });
  return (
    <PageWrapper
      title="BMS"
      isBackButton
      isModalContent
      pageLogo={<img src="/images/kaseya-logo.png" width="100" alt="Kaseya logo" />}
      tools={
        <ToolsStyled>
          <Switcher
            id="is-active-flow"
            checked={isActive}
            onChange={() => setIsActive(!isActive)}
            controlText={{ checked: 'on', unchecked: 'off' }}
          />
          <SimpleButton
            isLoader={visibleOverlay}
            isDisabled={!canSubmit}
            type="submit"
            form={FORM_ID}
          >
            Save
          </SimpleButton>
        </ToolsStyled>
      }
    >
      <ContentStyled>
        {errorMessage && <MessageForm typeView="block">{errorMessage}</MessageForm>}
        {isValid === false && (
          <MessageForm typeView="block">{messageIntegrations.NOT_VALID}</MessageForm>
        )}
        {isValid === true && !errorMessage && configuration.is_active && (
          <MessageForm typeView="block" typeMsg="success">
            {messageIntegrations.VALID}
          </MessageForm>
        )}
        <Kaseya
          onSubmit={onFormSubmit}
          onFormValid={onFormValid}
          isLogoVisible={false}
          defaultValues={defaultValues}
        />
      </ContentStyled>
      {visibleOverlay && overlayChidrens[status] && (
        <div className={styles.overlay}>
          {overlayChidrens[status].icon}
          <p>{overlayChidrens[status].text}</p>
        </div>
      )}
    </PageWrapper>
  );
};

BMSForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  formData: PropTypes.objectOf(PropTypes.any),
  isValid: PropTypes.bool,
  status: PropTypes.string,
  visibleOverlay: PropTypes.bool,
  errorMessage: PropTypes.string,
  configuration: PropTypes.any
};

BMSForm.defaultProps = {
  formData: {},
  isValid: undefined,
  visibleOverlay: false,
  status: '',
  errorMessage: '',
  configuration: {}
};
export default BMSForm;
