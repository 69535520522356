import React from 'react';
import PropTypes from 'prop-types';
import { teamsCompanionAppInstallation, slackCompanionAppInstallation } from 'src/config';
import FigureSwitcher from 'src/components/FigureSwitcher';
import OnboardingScreen from 'src/containers/OnboardingScreen';
import { ReactComponent as Spinner } from 'src/assets/icons/spinner.svg';
import MessageForm from 'src/components/MessageForm';
import { ReactComponent as SlackLogo } from 'src/assets/images/slack-logo-2.svg';
import { ReactComponent as TeamsLogo } from 'src/assets/images/msteams-logo-2.svg';
import msIcon, { ReactComponent as MSLogo } from 'src/assets/icons/microsoft.svg';
import slack from 'src/assets/images/slack.png';
import msteams from 'src/assets/images/msteams.png';
import SignupSkipButton from 'src/components/SignupSkipButton';
import { useHistory } from 'react-router-dom';
import {
  isMicrosoft365Integration,
  isUserIntegratedWithMicrosoft
} from 'src/containers/ChatIntegrationsEditor/MicrosoftIntegrations/microsoftConnectionHelpers';
import useAuthUser from 'src/hooks/useAuthUser';
import config from './constants';
import * as Styled from './styles';

const SignupChatPage = ({
  signInMs,
  chatConfiguration,
  updateChatConfiguration,
  chatErrorMessage,
  chatInfoLoader,
  slackHasNewConfiguration,
  msTeamsHasNewConfiguration,
  chatPlatformStep
}) => {
  const history = useHistory();
  const isReintegration = slackHasNewConfiguration || msTeamsHasNewConfiguration;
  const user = useAuthUser();
  const integratedWithMs = isUserIntegratedWithMicrosoft(user);

  if (integratedWithMs && isMicrosoft365Integration()) {
    history.push('/dashboard/integrations/chat/msteams');
  }

  return (
    <OnboardingScreen
      step={!isReintegration && 3}
      aside={
        <FigureSwitcher
          entity={chatPlatformStep}
          logos={{
            slack: <SlackLogo />,
            msteams: <TeamsLogo />,
            microsoft365: <MSLogo />
          }}
        />
      }
    >
      {chatErrorMessage && <MessageForm>{chatErrorMessage}</MessageForm>}
      {/*
       * For MS Teams in `signInMs` action we fetch redirect URI first and then redirect.
       * If we don't show loading screen there is a chance user will click continue right
       * before redirect and we will lose the state which will block user from continuing.
       */}
      {['pending', 'fulfilled_redirect'].includes(chatInfoLoader) ? (
        <Styled.LoadingOverlay>
          <Spinner />
        </Styled.LoadingOverlay>
      ) : (
        <>
          {((isReintegration && slackHasNewConfiguration) || chatPlatformStep === 'slack') && (
            <>
              <Styled.Instruction>
                Connect your Slack workspace by providing Thread required permissions.{' '}
                <a target="_blank" href={slackCompanionAppInstallation} rel="noreferrer">
                  Learn more about Companion App.
                </a>
              </Styled.Instruction>
              <Styled.Link href={config.SLACK.integrationLink}>
                Connect with
                <img src={slack} alt="slack" />
              </Styled.Link>
            </>
          )}
          {((isReintegration && msTeamsHasNewConfiguration) || chatPlatformStep === 'msteams') && (
            <>
              <Styled.Instruction>
                Connect your Teams tenant by providing Thread required permissions.{' '}
                <a target="_blank" href={teamsCompanionAppInstallation} rel="noreferrer">
                  Learn more about Companion App.
                </a>
              </Styled.Instruction>
              <Styled.Button
                type="button"
                onClick={() => signInMs()}
                isLoading={chatInfoLoader === 'pending'}
              >
                Connect with
                <img src={msteams} alt="msteams" />
              </Styled.Button>
            </>
          )}
          {chatPlatformStep === 'microsoft365' && (
            <>
              <Styled.Instruction>
                Connect your Microsoft 365 account by providing Thread required permissions.{' '}
              </Styled.Instruction>
              <Styled.Button
                type="button"
                onClick={() => signInMs()}
                isLoading={chatInfoLoader === 'pending'}
              >
                Connect with
                <img src={msIcon} alt="microsoft-365" />
              </Styled.Button>
            </>
          )}
          <SignupSkipButton
            chatConfiguration={chatConfiguration}
            updateChatConfiguration={updateChatConfiguration}
          />
        </>
      )}
    </OnboardingScreen>
  );
};

SignupChatPage.defaultProps = {
  chatErrorMessage: '',
  chatInfoLoader: false,
  slackHasNewConfiguration: false,
  msTeamsHasNewConfiguration: false,
  chatPlatformStep: '',
  chatConfiguration: null
};

SignupChatPage.propTypes = {
  signInMs: PropTypes.func.isRequired,
  updateChatConfiguration: PropTypes.func.isRequired,
  chatErrorMessage: PropTypes.string,
  chatInfoLoader: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  slackHasNewConfiguration: PropTypes.bool,
  msTeamsHasNewConfiguration: PropTypes.bool,
  chatPlatformStep: PropTypes.string,
  chatConfiguration: PropTypes.string
};

export default SignupChatPage;
