import React from 'react';
import Switcher from 'src/components/Switcher';
import { TabList, Tab, TabRight, TabLink } from './style';

const tabs = [
  { path: `/dashboard/magic/agent`, name: 'Catalog' },
  { path: `/dashboard/magic/agent/settings`, name: 'Settings' }
];

const Tabs = ({ agentEnabled, updateAgentSettings, isLoading }) => {
  function onEnabledChange() {
    updateAgentSettings({ enabled: !agentEnabled });
  }

  return (
    <TabList>
      {tabs.map(item => (
        <Tab key={item.path}>
          <TabLink to={item.path} exact>
            {item.name}
          </TabLink>
        </Tab>
      ))}
      <TabRight>
        Enable Magic Agent
        <Switcher
          id="is-magic-agent-enabled"
          checked={agentEnabled}
          onChange={onEnabledChange}
          width={50}
          controlText={{ checked: '', unchecked: '' }}
          isLoading={isLoading}
        />
      </TabRight>
    </TabList>
  );
};

export default Tabs;
