import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { chatgenieAPI } from 'src/config/api';
import { isAutotask, isConnectWise, isHalo, psaTranslate } from 'src/utils/psa';
import { useMutation } from 'react-query';
import queryKeys from 'src/constants/queryKeys';
import useToast from 'src/hooks/useToast';
import { queryClient } from 'src/config/queryClient';
import * as Styled from '../style';
import CopilotTestChat from '../CopilotTestChat';
import { Card } from '../CopilotTestChat/style';
import BoardTypeTable from './components/BoardTypeTable';
import SelectMultipleBoard from './components/SelectMultipleBoard';
import { getMappedBoardMetaData } from './EditTypes';
import { BoardTypeFieldContext } from './boardContext';

const newMessageNormalizer = ({ type, subType, item, explanation }) => {
  return {
    type: 'copilot',
    name: 'Magic AI',
    body: (
      <Card>
        <h5>Auto categorization action</h5>
        <div>
          {type && (
            <p>
              Magic AI set the <b>{psaTranslate('Type')}</b> to “{type}”
            </p>
          )}
          {subType && (
            <p>
              Magic AI set the <b>{psaTranslate('Subtype')}</b> to “{subType}“
            </p>
          )}
          {item && !isAutotask() && (
            <p>
              Magic AI set the <b>Item</b> to “{item}”
            </p>
          )}
          {explanation && <p>Reason: {explanation}</p>}
        </div>
      </Card>
    )
  };
};

function ViewTypes({ data }) {
  const [currentBoard, setCurrentBoard] = useState(
    data.find(el => el.filled_descriptions) || data[0]
  );
  const [isAnimating, setIsAnimating] = useState(false);
  const [currentField, setCurrentField] = useState({
    id: null,
    key: null
  });
  const { toast } = useToast();
  const isBoardSelectVisible = isConnectWise() || isAutotask();

  const { mutate, isLoading } = useMutation(chatgenieAPI.updateCategorizationTypes, {
    onSuccess(updatedData) {
      queryClient.setQueryData([queryKeys.GET_CATEGORIZATION_TYPES], old => ({
        ...old,
        data: updatedData
      }));
    },
    onError: err => {
      toast.error(
        `Failed to save categorization types: ${err?.response?.data?.message || err?.message}`
      );
      queryClient.invalidateQueries([queryKeys.GET_CATEGORIZATION_TYPES]);
    }
  });

  const handleSendMessage = ({ message }) => {
    return chatgenieAPI.testCopilotCategorization({
      data: { message, board_id: currentBoard?.id }
    });
  };

  const handleFieldBlur = useCallback(
    ({ id, key, value }) => {
      const board = data.find(el => el.id === currentBoard.id);
      const type = board.types.find(el => el.id === id);
      const isValueStale = type[key] !== value;
      if (isValueStale) {
        setCurrentField({ id, key });
        mutate(currentBoard.types.map(el => (el.id === id ? { ...el, [key]: value } : el)));
      }
    },
    [data, currentBoard]
  );

  const boardsMetaData = useMemo(() => getMappedBoardMetaData(data), [data]);

  const contextValue = useMemo(
    () => ({
      onBlur: handleFieldBlur,
      currentField,
      isLoading,
      showTableHeader: !isHalo(),
      showAccordionHeader: !isHalo()
    }),
    [currentField, isLoading, handleFieldBlur]
  );

  const handleChange = ({ id, values }) => {
    setCurrentBoard(old => ({
      ...old,
      types: old.types.map(type => (type.id === id ? { ...type, ...values } : type))
    }));
  };

  useEffect(() => {
    setIsAnimating(true);
    setTimeout(() => setIsAnimating(false), 2000);
    setCurrentBoard(data.find(el => el.id === currentBoard.id) || data[0]);
  }, [data]);

  return (
    <Styled.ViewContainer>
      <Styled.ViewInner>
        <Styled.ViewHeader>
          <h2>Categorization</h2>
        </Styled.ViewHeader>
        {isBoardSelectVisible && (
          <SelectMultipleBoard
            isMulti={false}
            isOptionDisabled={() => false}
            blurInputOnSelect
            closeMenuOnSelect
            hideSelectedOptions={false}
            value={currentBoard}
            onChange={board => {
              setCurrentBoard(data.find(el => el.id === board.id) || { ...board, types: [] });
            }}
            boardsMetaData={boardsMetaData}
            label={`Select a ${psaTranslate('Board')}`}
          />
        )}
        <BoardTypeFieldContext.Provider value={contextValue}>
          <BoardTypeTable data={[currentBoard]} onChange={handleChange} />
        </BoardTypeFieldContext.Provider>
      </Styled.ViewInner>
      <CopilotTestChat
        title="Test auto categorization"
        description={
          !isHalo() &&
          `Magic AI will automatically assign ${psaTranslate('Subtype')} ${
            !isAutotask() ? 'and Item values' : ''
          } based on definitions you've created for those fields within the Categorization Editor`
        }
        firstMessage="Hi, Magic AI here! Tell me about your request and I'll categorize it for you."
        askAnotherMessage="Ask me another one and I’ll give you the categorization!"
        onSend={handleSendMessage}
        isAnimating={isAnimating}
        newMessageNormalizer={newMessageNormalizer}
      />
    </Styled.ViewContainer>
  );
}

ViewTypes.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default ViewTypes;
