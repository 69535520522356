import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { components } from 'react-select';
import keyBy from 'lodash/keyBy';
import useAuthUser from 'src/hooks/useAuthUser';
import { memberStateOptions } from 'src/constants/member-states';
import { OptionRoleStyled, SelectWrapperStyled } from './styles';
import SimpleSelect from '../../components/SimpleSelect';

const stateOptionsKeyedByValue = keyBy(memberStateOptions, 'value');

const StateSelect = ({ row, action }) => {
  const [isLoading, setIsLoading] = useState(false);
  const authUser = useAuthUser();
  const handleChange = useCallback(
    async option => {
      setIsLoading(true);
      try {
        await action({ id: row.id, state: option.value });
      } finally {
        setIsLoading(false);
      }
    },
    [action, row]
  );

  return (
    <SelectWrapperStyled style={{ minWidth: '120px' }}>
      <SimpleSelect
        components={{
          Option: ({ children, data, ...props }) => (
            <components.Option {...props}>
              <OptionRoleStyled isActive={props.isSelected}>{children}</OptionRoleStyled>
            </components.Option>
          )
        }}
        name="user-roles"
        value={stateOptionsKeyedByValue[row.state]}
        onChange={handleChange}
        options={memberStateOptions}
        isLoading={isLoading}
        isDisabled={isLoading || authUser.memberId === row.id}
        size="small"
        customStyles={{ menuStyles: { minWidth: '220px' } }}
      />
    </SelectWrapperStyled>
  );
};

StateSelect.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.number.isRequired,
    state: PropTypes.string.isRequired
  }).isRequired,
  action: PropTypes.func.isRequired
};

export default StateSelect;
