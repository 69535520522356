import React from 'react';

import { ReactComponent as ChatgenieLogo } from 'src/assets/icons/logo.svg';
import { ReactComponent as NoAccessImage } from 'src/assets/images/no-access.svg';
import useAuthUser from 'src/hooks/useAuthUser';
import useLogout from 'src/hooks/useLogout';
import * as Styled from './NoAccess.styles';

const NoAccess = () => {
  const { logout } = useLogout();
  const { company, email } = useAuthUser();

  const handleLogout = e => {
    e.preventDefault();
    logout();
  };

  return (
    <Styled.Wrapper>
      <Styled.Header>
        <Styled.Logo>
          <ChatgenieLogo />
        </Styled.Logo>
      </Styled.Header>
      <Styled.Content>
        <NoAccessImage />
        <Styled.Message>
          <Styled.MessageTitle>
            Tough luck!
            <br />
            You do not have access to the page you requested.
          </Styled.MessageTitle>
        </Styled.Message>
        <Styled.MessageBody>
          Computer says no. Please contact your admin to request access to this page or get in touch
          with our{' '}
          <a href="https://help.getthread.com" rel="noopener noreferrer" target="_blank">
            support team
          </a>
          .
        </Styled.MessageBody>
      </Styled.Content>
      <Styled.Footer>
        <Styled.FooterNote>
          Signed in as {email} -{' '}
          <a href="#" onClick={handleLogout}>
            Log out
          </a>
        </Styled.FooterNote>
        {company && <Styled.FooterNote>in workspace “{company.name}”.</Styled.FooterNote>}
      </Styled.Footer>
    </Styled.Wrapper>
  );
};

export default NoAccess;
