import styled from 'styled-components/macro';
import { ReactComponent as SpinnerElement } from 'src/assets/icons/spinner.svg';

export const Description = styled.p`
  max-width: 400px;
  margin: 0 auto;
  font-size: 14px;
  text-align: center;
`;

export const Loader = styled.div`
  svg {
    display: flex;
    width: 58px;
    height: 58px;
    margin: 20px auto 0;
  }
`;

export const Figure = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);

  svg {
    width: 98%;
    min-width: 200px;
  }
`;

export const Spinner = styled(SpinnerElement)`
  position: relative;
  display: block;
  margin: 40px auto 10px;
`;
