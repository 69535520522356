import { useSelector } from 'react-redux';

/**
 * @typedef {Object} WorkspaceSettings
 * @property {Object} salesBoard
 * @property {Object} supportBoard
 * @property {Object} logo
 * @property {string} primaryColor
 * @property {string} secondaryColor
 *
 * @returns {WorkspaceSettings}
 */
const useWorkspaceSettings = () => {
  const user = useSelector(state => state.usersReducer.user);

  return user?.company?.settings || {};
};

export default useWorkspaceSettings;
