import styled from 'styled-components';

export const WrapperStyled = styled.nav`
  flex-grow: 1;
  padding-top: 28px;
  border-top: 1px solid ${({ theme }) => theme.colors.brand.main.hover};
  background: ${({ theme }) => theme.colors.brand.main.base};

  a {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 8px 20px;
    font-size: 14px;
    line-height: 24px;
    text-decoration: none;
    color: #ffffff;

    span {
      white-space: nowrap;
    }

    svg {
      width: 18px;
      height: 18px;
      margin-right: 8px;
      transition: fill 0.2s;
    }

    &.active {
      text-decoration: none;
      background-color: ${({ theme }) => theme.colors.brand.main.active};
    }

    &:hover,
    &:focus {
      background-color: ${({ theme }) => theme.colors.brand.main.hover};
    }
  }
`;
