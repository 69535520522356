import { lighten } from 'polished';
import { Link } from 'react-router-dom';
import { ReactComponent as SpinnerElement } from 'src/assets/icons/spinner.svg';
import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  overflow: hidden;
`;

export const Logo = styled.div`
  margin-bottom: 30px;
  text-align: center;
`;

export const Help = styled.a`
  position: absolute;
  top: 14px;
  left: 20px;
  display: flex;
  align-items: center;
  padding: 6px 10px;
  font-size: 12px;
  line-height: 15px;
  font-weight: 600;
  color: #666666;

  svg {
    margin-right: 6px;
  }

  &:focus,
  &:hover {
    color: ${({ theme }) => theme.colors.brand.light.hover};
  }
`;

export const TopRightActions = styled.div`
  position: absolute;
  top: 14px;
  right: 20px;
`;

export const TopRightAction = styled.button`
  box-sizing: border-box;
  border: 1px solid transparent;
  border-radius: 4px;
  padding: 6px 10px;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: ${({ theme }) => theme.colors.brand.light.base};
  background: none;
  transition: all 0.2s;

  &:focus,
  &:hover {
    outline: none;
    border: 1px solid ${({ theme }) => theme.colors.gray1};
  }
`;

export const Steps = styled.div`
  position: relative;
  max-width: 400px;
  height: 5px;
  margin: 0 auto 22px;
  border-radius: 20px;
  background-color: ${({ theme }) => theme.colors.gray3};
  width: 100%;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: ${({ step, to }) => (100 / to) * step}%;
    height: 100%;
    border-radius: 20px;
    background-color: ${({ theme }) => theme.colors.brand.light.base};
  }
`;

export const Body = styled.section`
  position: relative;
  display: flex;
  justify-content: center;
  width: 70%;
  padding: 24px 16px 16px;
  box-shadow: 0px 14px 50px rgb(0 0 0 / 10%);
  z-index: 10;
  background-color: #ffffff;

  @media screen and (max-width: 1024px) {
    width: 100%;
  }
`;

export const Content = styled.div`
  max-width: ${({ size }) => (size === 'large' ? '800px' : '500px')};
  width: 100%;
  margin-top: 20vh;
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  margin-bottom: 10px;
`;

export const Title = styled.h1`
  margin: 0;
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
  text-align: center;
  white-space: nowrap;
`;

export const SideBar = styled.aside`
  position: relative;
  display: flex;
  flex-grow: 1;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    height: 50%;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.second};
    z-index: -1;
  }

  @media screen and (max-width: 1024px) {
    display: none;
  }
`;

export const Spinner = styled(SpinnerElement)`
  position: relative;
  bottom: 40px;
  margin: auto;
`;

export const BackLinkWrapper = styled.div`
  text-align: center;
  margin-top: 33px;
`;

export const BackLink = styled(Link)`
  text-decoration: none;
  font-size: 14px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.brand.light.base};
  transition: color 0.2s;

  &:hover {
    color: ${({ theme }) => lighten(0.1, theme.colors.brand.light.hover)};
  }
`;
