import { useQuery } from 'react-query';
import { chatgenieAPI } from 'src/config/api';

const usePartnerMetrics = () => {
  const { data, isLoading, refetch } = useQuery({
    queryFn: async () => {
      const result = await chatgenieAPI.deploymentCenter.metrics();
      return result.data.data;
    }
  });

  return {
    isLoading,
    data,
    refetch
  };
};

export default usePartnerMetrics;
