import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from '@Thread-Magic/jasmine';
import AutoExpandTextarea from 'src/components/AutoExpandTextarea';
import { ReactComponent as Spinner } from 'src/assets/icons/spinner.svg';
import * as Styled from '../../style';
import useBoardConfig from '../hooks/useBoardConfig';

function TypesListItem({ data: item, isEditable, onChange }) {
  const { isLoading = false, onBlur = () => {}, currentField = {} } = useBoardConfig();

  const handleDescriptionChange = e => {
    const { value } = e.target;
    onChange({ id: item.id, values: { description: value } });
  };

  const handleCheckboxChange = e => {
    const value = e.target.checked;
    onChange({ id: item.id, values: { ignore: value } });

    // since checkbox's focused state is not sufficiently visible
    // we need to manually trigger blur
    onBlur({ id: item.id, key: 'ignore', value });
  };

  const isUpdating = currentField.id === item.id && isLoading;

  return (
    <Styled.TypesListItem key={item.id} disabled={item.ignore} isEditable={isEditable}>
      <span>{item.name}</span>
      <AutoExpandTextarea
        onChange={handleDescriptionChange}
        value={item.description || ''}
        disabled={item.ignore || !isEditable || isLoading}
        onBlur={e => onBlur({ id: item.id, key: 'description', value: e.target.value })}
        isLoading={isUpdating && currentField.key === 'description'}
        placeholder={
          isEditable ? 'Use this type for requests related to...' : 'No definition provided'
        }
      />
      {isEditable &&
        (isUpdating && currentField.key === 'ignore' ? (
          <Spinner width={16} />
        ) : (
          <Checkbox
            checked={item.ignore}
            disabled={isLoading}
            onChange={handleCheckboxChange}
            id={item.id}
          />
        ))}
    </Styled.TypesListItem>
  );
}

TypesListItem.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    ignore: PropTypes.bool
  }).isRequired,
  onChange: PropTypes.func,
  isEditable: PropTypes.bool
};
TypesListItem.defaultProps = {
  onChange: () => {},
  isEditable: true
};

export default TypesListItem;
