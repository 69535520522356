import React from 'react';

import threadyLoader from '../../assets/images/page-loader.gif';
import * as Styled from './styles';

const PageLoader = () => (
  <Styled.Wrapper>
    <img src={threadyLoader} alt="thready-loader" width="55" />
  </Styled.Wrapper>
);

export default PageLoader;
