import styled from 'styled-components';

export const WrapperStyled = styled.div`
  position: relative;
  padding: 16px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  background: #ffffff;
  min-height: 100px;
`;

export const LoaderStyled = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  svg {
    width: 40px;
    height: 40px;
  }
`;
