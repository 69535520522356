import React from 'react';
import { psaTypes } from 'src/constants';
import psaTranslation from 'src/constants/psaTranslation';
import store from 'src/redux/store';
import { ReactComponent as ConnectWiseLogo } from 'src/assets/icons/CW.svg';
import { ReactComponent as HaloLogo } from 'src/assets/icons/Halo.svg';
import { ReactComponent as AutotaskLogo } from 'src/assets/icons/AT.svg';

export function isConnectWise() {
  const { psaType } = store.getState().usersReducer.user;
  return psaType === psaTypes.CONNECTWISE;
}

export function isAutotask() {
  const { psaType } = store.getState().usersReducer.user;
  return psaType === psaTypes.AUTOTASK;
}

export function isHalo() {
  const { psaType } = store.getState().usersReducer.user;
  return psaType === psaTypes.HALO;
}

export function psaTranslate(key) {
  let { psaType } = store.getState().usersReducer.user;
  if (!(psaType in psaTranslation)) {
    psaType = psaTypes.CONNECTWISE;
  }
  const translation = psaTranslation[psaType][key.toLowerCase()] || key;
  const isLowerCased = key === key.toLowerCase();
  const isUpperCased = key === key.toUpperCase();
  if (isLowerCased) {
    return translation.toLowerCase();
  }
  if (isUpperCased) {
    return translation.toUpperCase();
  }
  return translation;
}

export function psaTypeToLogo(psaType) {
  switch (psaType) {
    case psaTypes.CONNECTWISE:
      return <ConnectWiseLogo />;
    case psaTypes.AUTOTASK:
      return <AutotaskLogo />;
    case psaTypes.HALO:
      return <HaloLogo />;
    default:
      return <div />;
  }
}
