import styled from 'styled-components';

export const Wrapper = styled.div`
  svg {
    fill: #f2c94c;

    path {
      stroke: #f2c94c;
    }
  }
`;
