import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, useHistory } from 'react-router-dom';
import { useQuery } from 'react-query';
import { chatgenieAPI } from 'src/config/api';
import queryKeys from 'src/constants/queryKeys';
import useAuthUser from 'src/hooks/useAuthUser';
import MessengerSettings from 'src/components/MessengerSettings';
import MessengerDesign from 'src/components/MessengerDesign';
import TeamsBuilder from '../../components/TeamsBuilder';
import ServicePageBuilder from '../../components/ServicePageBuilder';
import CopilotPrioritization from '../../components/Copilot/CopilotPrioritization';
import Invitation from './Invitation';
import Apps from './Apps';
import Status from './Status';
import Hours from '../Hours';
import Contacts from './Contacts';
import Connect from './Connect';
import Installation from '../Installation';
import PageWrapper from '../../components/PageWrapper';
import MacDownload from './MacDownload';
import WindowsInvitation from './WindowsInvitation';

const Company = ({ match, location, user }) => {
  const companyId = Number(match.params.company);
  const parentCompanyId = useAuthUser().company.id;
  const parentAppId = useAuthUser().appId;
  const history = useHistory();

  const backPage = location.state?.fromDeploymentCenter ? 'deployment-center/companies' : 'clients';
  const backUrl = `/dashboard/${backPage}${location.state?.search}`;

  const [backButtonPath] = useState(backUrl);
  const { data } = useQuery([queryKeys.GET_COMPANY, companyId], () =>
    chatgenieAPI.getCompany({ id: companyId })
  );
  const chatPlatform = data?.chat_platform;
  const appId = location.state?.appId || data?.app_id;
  const companyName = location.state?.name || data?.name || '';

  useEffect(() => {
    if (appId && !history.location.state?.appId) {
      history.push({
        state: {
          ...history.location.state,
          appId
        }
      });
    }
  }, [appId]);

  return (
    <PageWrapper
      isBackButton
      backButtonPath={backButtonPath}
      search={location.state?.search}
      title={`Clients ${companyName && `/ ${companyName}`}`}
      tabs={[
        {
          id: 1,
          path: `${match.url}/connect`,
          state: { companyName, appId },
          name: 'App Deployment',
          isNested: true
        },
        { id: 2, path: `${match.url}/contacts`, state: { companyName, appId }, name: 'Contacts' },
        // { id: 3, path: `${match.url}/copilot`, state: { companyName, appId }, name: 'CoPilot' },
        { id: 4, path: `${match.url}/settings`, state: { companyName, appId }, name: 'Settings' },
        { id: 5, path: `${match.url}/design`, state: { companyName, appId }, name: 'Design' },
        { id: 6, path: `${match.url}/hours`, state: { companyName, appId }, name: 'Hours' },
        {
          id: 7,
          path: `${match.url}/installation`,
          state: { companyName, appId },
          name: 'Installation'
        }
      ]}
    >
      <Switch>
        <Route path={`${match.path}/contacts`} component={Contacts} />
        <Route
          path={`${match.path}/connect/apps/service-page-builder/:teamsAppId`}
          render={props => (
            <ServicePageBuilder
              {...props}
              companyId={companyId}
              parentCompanyId={parentCompanyId}
            />
          )}
        />
        <Route
          path={`${match.path}/connect/apps/service-page-builder`}
          render={props => (
            <ServicePageBuilder
              {...props}
              companyId={companyId}
              parentCompanyId={parentCompanyId}
            />
          )}
        />
        <Route
          path={`${match.path}/connect/apps/teams-app-builder/:teamsAppId`}
          render={props => <TeamsBuilder {...props} parentCompanyId={parentCompanyId} />}
        />
        <Route
          path={`${match.path}/connect/apps/teams-app-builder`}
          render={props => <TeamsBuilder {...props} parentCompanyId={parentCompanyId} />}
        />
        <Route
          path={`${match.path}/connect/apps`}
          render={props => <Apps {...props} appId={appId} companyId={parentCompanyId} />}
        />
        <Route
          path={`${match.path}/connect/status`}
          render={props => (
            <Status
              {...props}
              companyId={companyId}
              appId={appId}
              chatPlatform={chatPlatform}
              parentCompanyId={parentCompanyId}
              parentAppId={parentAppId}
            />
          )}
        />
        <Route
          path={`${match.path}/connect/invitation/windows`}
          render={props => <WindowsInvitation {...props} appId={appId} companyId={companyId} />}
        />
        <Route
          path={`${match.path}/connect/invitation`}
          render={props => <Invitation {...props} appId={appId} companyId={companyId} />}
        />
        <Route
          path={`${match.path}/connect/download/mac`}
          render={() => <MacDownload history={history} companyId={companyId} />}
        />
        <Route
          path={`${match.path}/connect`}
          render={props => <Connect {...props} appId={appId} companyId={companyId} user={user} />}
        />
        <Route
          path={`${match.path}/copilot`}
          render={() => <CopilotPrioritization companyId={companyId} appId={appId} />}
        />
        <Route
          path={`${match.path}/settings`}
          render={() => <MessengerSettings companyId={companyId} appId={appId} />}
        />
        <Route
          path={`${match.path}/design`}
          render={() => <MessengerDesign companyId={companyId} appId={appId} />}
        />
        <Route path={`${match.path}/hours`} render={() => <Hours companyId={companyId} />} />
        <Route path={`${match.path}/installation`} render={() => <Installation appId={appId} />} />
      </Switch>
    </PageWrapper>
  );
};

Company.propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  user: PropTypes.objectOf(PropTypes.any).isRequired
};

export default Company;
