import React, { useState } from 'react';
import PropTypes from 'prop-types';
import InfoModal from 'src/components/InfoModal';
import { FiExternalLink } from 'react-icons/fi';
import * as Styled from './style';

const TeamsAndSlackDisconnectModals = ({
  disconnect,
  name,
  workspaceName,
  helpDocUrl,
  isFirstConfirmModalOpen,
  setIsFirstConfirmModalOpen
}) => {
  const [isSecondConfirmModalOpen, setIsSecondConfirmModalOpen] = useState(false);

  const toggleFirstConfirmModal = () => {
    setIsFirstConfirmModalOpen(!isFirstConfirmModalOpen);
  };

  const toggleSecondConfirmModal = () => {
    setIsSecondConfirmModalOpen(!isSecondConfirmModalOpen);
  };

  const handleFirstConfirmation = () => {
    setIsFirstConfirmModalOpen(false);
    setIsSecondConfirmModalOpen(true);
  };

  const handleUninstallChatConfiguration = () => {
    setIsSecondConfirmModalOpen(false);

    disconnect();
  };

  return (
    <>
      <InfoModal
        isOpen={isFirstConfirmModalOpen}
        toggleHandler={toggleFirstConfirmModal}
        title={`Uninstall ${name} in two steps`}
      >
        <Styled.ModalWrapper>
          <ol>
            <li>
              Follow{' '}
              <a href={helpDocUrl} target="_blank" rel="noreferrer">
                these steps <FiExternalLink />
              </a>{' '}
              to remove the Thread {name} application from your customer&apos;s {workspaceName}.
            </li>
            <li>
              Click &quot;Continue&quot; to disconnect the application from your Thread account to
              complete this process.
            </li>
          </ol>
          <p>
            <strong>Important!</strong> If you are trying to redeploy, you will still need to
            manually remove the Thread {name} appplication first.
          </p>
        </Styled.ModalWrapper>
        <Styled.ButtonsWrapper>
          <Styled.CancelButton onClick={toggleFirstConfirmModal}>Cancel</Styled.CancelButton>
          <Styled.ConfirmButton onClick={handleFirstConfirmation}>Continue</Styled.ConfirmButton>
        </Styled.ButtonsWrapper>
      </InfoModal>

      <InfoModal
        isOpen={isSecondConfirmModalOpen}
        toggleHandler={toggleSecondConfirmModal}
        title={`Disconnect ${name}`}
      >
        <Styled.ModalWrapper>
          <p>
            Confirming this action will disconnect your custom {name} app from your customer&apos;s
            tenant.
          </p>
        </Styled.ModalWrapper>
        <Styled.ButtonsWrapper>
          <Styled.CancelButton onClick={toggleSecondConfirmModal}>Cancel</Styled.CancelButton>
          <Styled.ConfirmButton styleMode="danger" onClick={handleUninstallChatConfiguration}>
            Yes, disconnect
          </Styled.ConfirmButton>
        </Styled.ButtonsWrapper>
      </InfoModal>
    </>
  );
};

TeamsAndSlackDisconnectModals.propTypes = {
  disconnect: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  workspaceName: PropTypes.string.isRequired,
  helpDocUrl: PropTypes.string.isRequired,
  isFirstConfirmModalOpen: PropTypes.bool.isRequired,
  setIsFirstConfirmModalOpen: PropTypes.func.isRequired
};

export default TeamsAndSlackDisconnectModals;
