import styled from 'styled-components';

export const Wrapper = styled.div`
  padding-top: 35px;
`;

export const Inner = styled.div``;

export const FormGroup = styled.div`
  & + & {
    margin-top: 20px;
  }
  input {
    display: block;
    width: 100%;
    max-width: 100%;
  }
  label {
    font-size: 16px;
    font-weight: 600;
  }
`;
