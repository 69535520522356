import styled from 'styled-components';
import { lighten } from 'polished';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px 0;
`;

export const Info = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Title = styled.h3`
  font-size: 20px;
  font-weight: 600;
  margin: 0 8px 0 0;
`;

export const Controls = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const ActionButton = styled.button`
  display: inline-flex;
  align-items: center;
  gap: 5px;
  background-color: #00bb99;
  border: 1px solid #00bb99;
  color: #fff;
  padding: 6px 12px;
  border-radius: 4px;
  font-weight: 600;
  font-size: 14px;
  transition: 0.2s;

  &&.outlined:disabled {
    background-color: #bbb;
    color: #fff;
    border-color: #b0b3bc;
    cursor: not-allowed;
  }

  &.outlined {
    background-color: #fff;
    color: #00bb99;

    &:hover {
      background-color: #00bb99;
      color: #fff;
    }
  }

  &.text-only {
    background-color: transparent;
    border: none;
    color: #00bb99;

    &:hover {
      color: ${() => lighten(0.05, '#00bb99')};
    }
  }

  svg {
    width: 16px;
    height: 16px;
  }
`;
