import { chatsTypes } from 'src/constants/action-types';
import { getLocalStorage } from 'src/utils';

export const INTEGRATED_WITH_MS_KEY = 'integratedWithMs';
export const CHAT_PLATFORM_STEP_KEY = 'chatPlatformStep';
export const MS365_PLATFORM_STEP = 'microsoft365';

export const M365_INTEGRATIONS_FULL_PATH = '/dashboard/integrations/chat/msteams/integrations';

/**
 * A function to check if a user is already integrated with Microsoft (either Teams or Microsoft 365).
 */
export const isUserIntegratedWithMicrosoft = user => {
  // The chat type is called WINDOWS due to legacy reasons. We're reusing an API used in Messenger.
  return (
    user.chatConfiguration === chatsTypes.WINDOWS || user.chatConfiguration === chatsTypes.MS_TEAMS
  );
};

export const isMicrosoft365Integration = () => {
  return getLocalStorage(CHAT_PLATFORM_STEP_KEY) === MS365_PLATFORM_STEP;
};

export const isBotInstallStep = user => {
  return user.chatConfiguration === chatsTypes.MS_TEAMS && !user.botInstalled;
};

export const isBotInstalled = user => {
  return user.chatConfiguration === chatsTypes.MS_TEAMS && user.botInstalled;
};
