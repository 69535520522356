/* eslint-disable react/prop-types */
import { Button } from '@Thread-Magic/jasmine';
import { yupResolver } from '@hookform/resolvers/yup';
import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import FormInputWithLabel from 'src/components/FormInputWithLabel';
import { FiExternalLink } from 'react-icons/fi';
import * as Styled from './styles';
import SyncMembersCheckbox from './components/SyncMembersCheckbox';

const withController = (Form, config) => {
  return props => {
    const {
      defaultValues,
      onSubmit,
      isLogoVisible,
      hasHorizontalMargin,
      onFormValid,
      requestErrors,
      isLoading,
      withSyncMembersCheckbox = true
    } = props;
    const { helpDocsLink } = config;
    const { handleSubmit, control, formState, reset } = useForm({
      defaultValues: !isEmpty(defaultValues) ? defaultValues : config.schema.cast(),
      resolver: yupResolver(config.schema),
      mode: 'onChange'
    });

    const handleRedirectToDocs = () => {
      window.open(helpDocsLink, '_blank');
    };

    useEffect(() => {
      reset(defaultValues);
    }, [defaultValues]);

    useEffect(() => {
      if (onFormValid) onFormValid(formState.isDirty && formState.isValid);
    }, [formState.isDirty, formState.isValid]);

    return (
      <Styled.FormContainer hasHorizontalMargin={hasHorizontalMargin}>
        {isLogoVisible && <Styled.Logo {...config.logo} />}
        <Form
          onSubmit={onSubmit}
          handleSubmit={handleSubmit}
          control={control}
          errors={formState.errors}
          requestErrors={requestErrors}
        >
          {Object.values(config.fields).map(item => (
            <Controller
              name={item.name}
              control={control}
              key={item.name}
              render={({ field }) => (
                <FormInputWithLabel
                  {...item}
                  {...field}
                  errorMessage={formState.errors[item.name]?.message || requestErrors?.[item.name]}
                />
              )}
            />
          ))}
          {withSyncMembersCheckbox && (
            <FormInputWithLabel
              label="Keep members in sync with PSA"
              fieldType="custom"
              id="sync-members"
              helpTooltip="Auto-add & remove members. You still need to invite them to channels."
            >
              <SyncMembersCheckbox />
            </FormInputWithLabel>
          )}
          <Styled.Actions>
            <Button styleMode="outline" size="large" type="button" onClick={handleRedirectToDocs}>
              View setup guide <FiExternalLink />
            </Button>
            <Button
              size="large"
              type="submit"
              isLoader={isLoading}
              isDisabled={!formState.isValid || !formState.isDirty}
            >
              Continue
            </Button>
          </Styled.Actions>
        </Form>
      </Styled.FormContainer>
    );
  };
};
export default withController;
