import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  margin: 0 2rem 2rem 2rem;
  position: relative;
`;

export const MagicAIBanner = styled.img`
  display: block;
  width: 100%;
  height: 100%;
`;

export const MagicAIContent = styled.div`
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  display: flex;
  align-items: center;
  width: 670px;
  gap: 2px;
  justify-content: center;
`;

export const TrialInfo = styled.div`
  font-size: 15px;

  p {
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const TrialStartBtn = styled.button`
  font-weight: 600;
  color: #00bb99;
  background-color: #fff;
  padding: 6px 12px;
  border: 1px solid #0b9;
  border-radius: 20px;
  box-shadow: 8px 0 4px 0 rgba(246, 246, 175, 0.25), -5px 0 21px 0 #9e9;
  font-size: 14px;
  transition: all 250ms ease;
  margin-left: 20px;

  &:hover {
    background-color: #0b9;
    color: #fff;
  }
`;

export const ModalTitle = styled.div`
  text-align: center;
  font-weight: 600;

  img {
    width: 200px;
    height: 200px;
    margin-bottom: 20px;
  }
`;

export const MagicAiBannerIconWrapper = styled.div`
  img {
    width: 50px;
    margin-right: 14px;
  }
`;
