export const flowFields = {
  FLOW_NAME: {
    id: 'flow-name',
    label: 'Name flow'
  }
};

export const flowNotifications = [
  {
    id: 1,
    code: 'note',
    label: 'Notes',
    tooltipText: ''
  },
  {
    id: 2,
    code: 'status',
    label: 'Status',
    tooltipText: 'Enabling this feature will notify you in a thread or channel the status changes.'
  }
];

export const flowWarnings = {
  NO_CHANNEL_SELECTED: 'Select the channel to which you will receive messages',
  NO_BOARD_SELECTED: 'It remains to fill in the filter values'
};

export const channelForm = {
  CHANNELS_NOT_FOUND: 'Channel not found',
  START_SEARCH_CHANNELS: 'Start typing the name of the channel'
};

export const filterForm = {
  ATTR_VALUE_NOT_FOUND: 'Value not found',
  START_SEARCH_ATTR_VALUE: 'Start typing'
};
