import styled, { css } from 'styled-components';
import { Button as JasmineButton } from '@Thread-Magic/jasmine';
import Search from 'src/components/Search';

export const Title = styled.h3`
  margin-bottom: 30px;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: ${({ theme }) => theme.colors.text.default};
`;

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  ${({ isLoading }) =>
    isLoading &&
    css`
      & > svg {
        width: 40px;
        height: 40px;
        position: absolute;
        left: 50%;
        top: 40%;
        transform: translate(-50%, -50%);
        z-index: 10;
      }

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: -66px;
        width: calc(100% + 132px);
        height: 100%;
        background-color: ${({ theme }) => theme.colors.background.default};
      }
    `}
`;

export const Figure = styled.div`
  margin-bottom: 16px;
  margin-left: -20px;
  margin-top: 24px;
`;

export const SubTitle = styled.h4`
  margin-bottom: 8px;
  font-weight: 700;
  font-size: 20px;
  line-height: 33px;
  text-align: center;
  color: ${({ theme }) => theme.colors.text.default};
`;

export const Description = styled.p`
  margin-bottom: 40px;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  color: ${({ theme }) => theme.colors.text.default};
`;

export const Button = styled(JasmineButton)`
  align-self: center;
  margin-bottom: 20px;
`;

export const List = styled.ul`
  position: relative;
  overflow: auto;
  max-height: 390px;
  margin: 0;
  padding: 0;
`;

export const Item = styled.li`
  position: relative;
  display: flex;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.variant1};
  transition: background-color 0.2s;

  :hover {
    background-color: ${({ theme }) => theme.colors.brand.lighter.hover};
  }

  label {
    display: flex;
    align-items: flex-start;
    flex-grow: 1;
    padding: 20px 8px;

    & > span {
      align-self: center;
    }
  }

  input {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    border: 0;
    padding: 0;
    white-space: nowrap;
    clip-path: inset(100%);
    clip: rect(0 0 0 0);
    overflow: hidden;
  }
`;

export const App = styled.label`
  display: flex;
  width: 100%;
`;

export const Point = styled.span`
  input:checked + span::after {
    opacity: 1;
  }

  &::after {
    content: '';
    position: absolute;
  }
`;

export const Content = styled.div`
  margin-bottom: 48px;
`;

export const AppTitle = styled.h4`
  margin: 0;
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
  color: ${({ theme }) => theme.colors.text.default};
`;

export const AppInfo = styled.p`
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.text.default};
`;

export const Logo = styled.div`
  width: 90px;
  height: 90px;
  margin-right: 20px;
  border-radius: 4px;
  background-image: url(${({ url }) => url});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;

export const GroubAction = styled.div`
  button {
    display: inline-block;
    width: 100%;
    margin: 0 auto 14px;
    text-align: center;
  }
`;

export const ButtonLink = styled.button`
  display: flex;
  align-items: center;
  margin-top: 20px;
  border: none;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.brand.light.base};
  background: none;

  svg {
    margin-right: 8px;
    fill: ${({ theme }) => theme.colors.blue5};
    stroke: ${({ theme }) => theme.colors.brand.light.base};
  }
`;

export const CustomSearch = styled(Search)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.variant1};

  label,
  input:focus {
    border: none;
    box-shadow: none;
  }
  .loupe-icon {
    left: 0;
  }

  .remove-button {
    right: 0;
  }

  label,
  input,
  input:focus {
    width: 100%;
  }
`;

export const ToastLink = styled.a`
  margin-left: 8px;
`;
