import styled from 'styled-components/macro';

export const Rendering = styled.div`
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  height: 360px;

  .chat-enter {
    opacity: 0;
  }

  .chat-enter-active {
    opacity: 1;
    transition: opacity 300ms;
  }

  .chat-exit {
    opacity: 1;
  }

  .chat-exit-active {
    opacity: 0;
    transition: opacity 300ms;
  }
`;

export const ImageLeftWrapper = styled.div`
  position: absolute;
  width: 437px;
  height: 360px;
  z-index: 10;

  img {
    width: 100%;
    object-fit: cover;
  }
`;

export const ImageRightWrapper = styled.div`
  position: absolute;
  width: 437px;
  height: 360px;
  left: 240px;
  top: 160px;

  img {
    width: 100%;
    object-fit: cover;
  }
`;

export const Logo = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 72px;
  height: 72px;
  border-radius: 100%;
  box-shadow: 0px 0px 34px rgba(0, 0, 0, 0.25);
  background-color: #ffffff;

  svg {
    width: 36px;
  }

  &:first-child {
    top: -42px;
    left: 10px;
  }

  &:nth-last-child(1) {
    top: 50px;
    left: 150px;
  }
`;

export const LogoSwitcher = styled.div`
  position: absolute;
  z-index: 10;
  top: 256px;
  left: min(6vw, 100px);
  width: 200px;
  height: 140px;
`;

export const Separator = styled.div`
  position: absolute;
  bottom: 46px;
  right: 75px;
  display: block;
  width: 82px;
  height: 50px;
`;
