import styled from 'styled-components';

export const MessageStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;

  .svgicon {
    margin-right: 8px;
  }

  .svgicon--success {
    color: #52c41a;
  }

  .svgicon--error {
    color: #f5222d;
  }

  .svgicon--warning {
    color: #daad14;
  }
`;
