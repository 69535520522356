import React, { useEffect, useMemo } from 'react';
import PageWrapper from 'src/components/PageWrapper';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect, useRouteMatch, useLocation, useHistory } from 'react-router-dom';
import useAdminViews from 'src/hooks/useAdminViews';
import Loader from 'src/pages/AppDeployer/components/Loader';
import PartnerClientsTab from './PartnerClientsTab';
import PartnerDeployedClientsTab from './PartnerDeployedClientsTab';
import PartnerOnboarding from './PartnerOnboarding';

const DeploymentCenter = () => {
  const match = useRouteMatch();
  const history = useHistory();
  const { pathname } = useLocation();
  const { adminViews, isLoading: isLoadingAdminViews } = useAdminViews({
    type: 'deployment_center'
  });

  const isOnboardingPage = pathname === `${match.path}/onboarding`;

  const pageTabs = useMemo(() => {
    if (!isOnboardingPage)
      return [
        { id: 1, path: `${match.url}/companies`, name: 'Queue' },
        { id: 2, path: `${match.url}/deployed`, name: 'Deployed' }
      ];
    return [];
  }, [isOnboardingPage]);

  useEffect(() => {
    // if there is no default view, go through onboarding
    if (!isLoadingAdminViews && adminViews?.length < 1) {
      history.push({
        pathname: `/dashboard/deployment-center/onboarding`
      });
    }
  }, [isLoadingAdminViews, adminViews]);

  return (
    <PageWrapper
      title="Deployment Center"
      backButtonPath="/dasbhoard/deployment-center/companies"
      padding={0}
      tabs={pageTabs}
    >
      {isLoadingAdminViews ? (
        <Loader message="Loading..." />
      ) : (
        <Switch>
          <Redirect exact from={match.path} to={`${match.url}/companies`} />
          <Route path={`${match.path}/companies`} component={PartnerClientsTab} />
          <Route path={`${match.path}/deployed`} component={PartnerDeployedClientsTab} />
          <Route path={`${match.path}/onboarding`} component={PartnerOnboarding} />
        </Switch>
      )}
    </PageWrapper>
  );
};

DeploymentCenter.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string,
    path: PropTypes.string
  }).isRequired
};

export default DeploymentCenter;
