import { useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { chatgenieAPI } from 'src/config/api';
import queryKeys from 'src/constants/queryKeys';
import useToast from 'src/hooks/useToast';
import useAuthUser from './useAuthUser';

const initalAction = {
  id: '',
  tab: 'REPLY',
  name: '',
  text: '',
  order: 0,
  tabId: 0,
  actionId: '',
  selectedOptionId: ''
};

const useActionsPlatform = ({ changeActionsList, actions, flowId }) => {
  const { data } = useQuery([queryKeys.GET_ACTIONS], () => chatgenieAPI.getActions());
  const { toast } = useToast();
  const { featureFlags } = useAuthUser();

  const [selectedActions, setSelectedActions] = useState([]);

  const actionsList = useMemo(() => {
    if (!data) {
      return [];
    }

    const filterCopilotActions = action => {
      if (action?.name === 'Auto Categorize') {
        return featureFlags.includes('copilot_categorization');
      }

      if (action?.name === 'Generate Recap') {
        return featureFlags.includes('copilot_summarize_thread');
      }
      return true;
    };

    const filteredActions = data
      .filter(action => !(action.command === 'copilot' && !featureFlags.includes('copilot')))
      .map(action => ({
        ...action,
        actions: action.actions
          .filter(item => filterCopilotActions(item.form_data))
          .map(option => ({
            ...option,
            ...option.form_data,
            code: action.command
          }))
      }));

    return filteredActions;
  }, [data, actions]);
  const getRandomInt = () => Math.floor(Math.random() * 10000);
  const enqueueActionsList = () => {
    setSelectedActions([
      ...selectedActions,
      { ...initalAction, order: selectedActions.length, tabId: getRandomInt() }
    ]);
    toast.success('Added successfully');
  };

  const unqueueActionsList = index => {
    setSelectedActions(selectedActions.filter((_, idx) => idx !== index));
    toast.success('Removed successfully');
  };

  const setValueToAction = useCallback(
    (index, newAction) => {
      setSelectedActions(prev =>
        prev.map((action, idx) => (idx === index ? { ...newAction } : action))
      );
    },
    [selectedActions.length]
  );

  useEffect(() => {
    if (selectedActions.length) {
      const filteredActions = selectedActions
        .filter(action => !!action.selectedOptionId)
        .map(action => action.payload);
      changeActionsList(filteredActions);
    } else {
      changeActionsList([]);
    }
  }, [selectedActions]);

  useEffect(() => {
    let filteredActions = [];
    const defaultTabs = actionsList?.find(item => item.command === 'connectwise');
    if (actions?.length > 0) {
      filteredActions = actions.map(action => {
        const tab = defaultTabs?.actions?.find(
          item => item.id === action.actions.selected_action_id
        );
        const brdcrmb = action.form_data?.actions_structure?.[0];
        return {
          ...action,
          ...action?.form_data,
          ...tab,
          tabId: getRandomInt(),
          tab: brdcrmb?.code || tab?.name?.toUpperCase() || initalAction.tab,
          actionId: action.actions.selected_action_id,
          selectedOptionId: action.form_data?.id || action.actions.selected_action_id,
          payload: action
        };
      });
    }

    setSelectedActions(filteredActions);
    return () => {
      setSelectedActions([]);
    };
  }, [flowId, actionsList]);

  return {
    selectedActions,
    setValueToAction,
    enqueueActionsList,
    unqueueActionsList,
    setSelectedActions,
    actionsList
  };
};

export default useActionsPlatform;
