import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 2rem;
  padding-top: 0px;
`;

export const NoValuesDescription = styled.span`
  color: #999;
`;
