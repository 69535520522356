import React, { useState } from 'react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import PropTypes from 'prop-types';
import * as Styled from './style';
import TypesList from './TypesList';
import useBoardConfig from '../hooks/useBoardConfig';

function BoardAccordion({ board, defaultIsCollapsed, onChange, isEditable }) {
  const [isCollapsed, setIsCollapsed] = useState(isEditable ? defaultIsCollapsed : false);
  const { showAccordionHeader } = useBoardConfig();
  return (
    <Styled.BoardAccordionContainer>
      {showAccordionHeader && (
        <Styled.BoardAccordionHeader
          isEditable={isEditable}
          onClick={() => isEditable && setIsCollapsed(prev => !prev)}
        >
          {isEditable && (isCollapsed ? <FiChevronDown /> : <FiChevronUp />)}
          {board.name}
        </Styled.BoardAccordionHeader>
      )}
      {!isCollapsed && (
        <Styled.BoardAccordionContent isCollapsed={isCollapsed}>
          <TypesList
            types={board.types}
            isEditable={isEditable}
            onChange={values => onChange({ ...values, boardId: board.id })}
          />
        </Styled.BoardAccordionContent>
      )}
    </Styled.BoardAccordionContainer>
  );
}

BoardAccordion.propTypes = {
  board: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    filled_descriptions: PropTypes.number,
    unfilled_descriptions: PropTypes.number,
    types: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        description: PropTypes.string,
        ignore: PropTypes.bool
      })
    )
  }).isRequired,
  isEditable: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  defaultIsCollapsed: PropTypes.bool
};

BoardAccordion.defaultProps = {
  defaultIsCollapsed: true
};

export default BoardAccordion;
