import React from 'react';
import PropTypes from 'prop-types';
import { FiCheck, FiX } from 'react-icons/fi';
import { Checkbox } from '@Thread-Magic/jasmine';
import * as Styled from '../../style';

function PriorityRadioField({
  data,
  onChange,
  value,
  marginTop,
  showIgnoreCheckbox,
  onIgnoreChange,
  isDisabled
}) {
  return (
    <Styled.PriorityField marginTop={marginTop}>
      {data.icon}
      <p>{data.description}</p>
      {showIgnoreCheckbox && (
        <Styled.IgnoreFieldRow>
          <Checkbox
            id={`ignore-${data.id}`}
            onChange={e => {
              const { checked } = e.target;
              onIgnoreChange(checked);
            }}
            checked={data.ignore}
          />
          Ignore
        </Styled.IgnoreFieldRow>
      )}
      <Styled.RadioOptionWrapper>
        {data.options.map(option => (
          <Styled.RadioOptionButton
            type="button"
            key={option.value}
            disabled={isDisabled}
            active={value === option.value}
            onClick={() => onChange(option.value)}
          >
            {option.value === true ? <FiCheck /> : <FiX />}
            {option.description}
          </Styled.RadioOptionButton>
        ))}
      </Styled.RadioOptionWrapper>
    </Styled.PriorityField>
  );
}

PriorityRadioField.propTypes = {
  data: PropTypes.shape({
    icon: PropTypes.element,
    description: PropTypes.string,
    id: PropTypes.number,
    ignore: PropTypes.bool,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.bool
      })
    )
  }).isRequired,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  marginTop: PropTypes.number,
  showIgnoreCheckbox: PropTypes.bool,
  onIgnoreChange: PropTypes.func,
  isDisabled: PropTypes.bool
};

PriorityRadioField.defaultProps = {
  value: false,
  marginTop: 0,
  showIgnoreCheckbox: false,
  onIgnoreChange: () => {},
  isDisabled: false
};

export default PriorityRadioField;
