import * as yup from 'yup';
import uuid from 'uuid';

export const blocksFiledTypes = {
  email: {
    labelPlaceholder: 'Email Us',
    valuePlaceholder: 'help@getthread.com',
    valueTitle: 'Email address'
  },
  phone: {
    labelPlaceholder: 'Call Us',
    valuePlaceholder: '+1555718365',
    valueTitle: 'Phone number'
  },
  link: {
    labelPlaceholder: 'Visit Site',
    valuePlaceholder: 'https://www.getthread.com/',
    valueTitle: 'Go to'
  }
};

export const schema = yup
  .object({
    blocks: yup
      .array()
      .of(
        yup.object().shape({
          title: yup.string().required().label('Title'),
          label: yup.string().required().label('Label'),
          value: yup.string().required().label(' ')
        })
      )
      .required('Required'),
    featuredImage: yup.string().required().label('Header image')
  })
  .required();

export const formDefaultValues = {
  featuredImage: '',
  blocks: [
    {
      id: uuid.v4(),
      description: '',
      icon: '✉️',
      label: 'Email Us',
      title: 'Email support',
      type: 'email',
      value: ''
    },
    {
      id: uuid.v4(),
      description: '',
      icon: '📞',
      label: 'Call Us',
      title: 'Call support',
      type: 'phone',
      value: ''
    }
  ],
  isChatPlacementSwapped: 0
};

export const newBlockData = {
  id: uuid.v4(),
  description: '',
  icon: '✉️',
  label: '',
  title: '',
  type: 'link',
  blockType: 'link',
  value: ''
};

export const imageSizes = {
  featuredImage: {
    size: 1024 * 1024 * 10, // 10 MB
    errorMessage: 'The image is too large to upload!'
  },
  blocks: {
    size: 1024 * 1024 * 5, // 5 MB
    errorMessage: 'Too large image. Allowed maximum size is 5 MB'
  }
};
