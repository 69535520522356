import { getTheme } from '@Thread-Magic/jasmine';
import React from 'react';
import toast from 'react-hot-toast';
import { ReactComponent as Success } from 'src/assets/icons/check_round.svg';
import { ReactComponent as Dismiss } from 'src/assets/icons/cross.svg';
import { ReactComponent as Danger } from 'src/assets/icons/danger.svg';
import { ReactComponent as Warning } from 'src/assets/icons/warning.svg';

const commonStyle = {
  border: 'none',
  borderRadius: 4,
  zIndex: 1999,
  maxWidth: 1000
};

const successOptions = {
  style: {
    ...commonStyle,
    background: getTheme().colors.green3
  }
};

const warningOptions = {
  style: {
    ...commonStyle,
    background: getTheme().colors.orange2
  }
};

const errorOptions = {
  duration: 15000,
  style: {
    ...commonStyle,
    background: getTheme().colors.red3
  }
};

const customMessage = (t, message) => (
  <>
    <span className="chatgenie-toast_message">{message}</span>
    <span className="chatgenie-toast_dismiss-btn">
      <Dismiss onClick={() => toast.dismiss(t.id)} />
    </span>
  </>
);

const useToast = () => {
  const toastify = {
    success: (message, params) =>
      toast.success(t => customMessage(t, message), {
        ...successOptions,
        icon: <Success />,
        ...params
      }),
    warn: (message, params) =>
      toast.success(t => customMessage(t, message), {
        ...warningOptions,
        icon: <Warning />,
        ...params
      }),
    error: (message, params) =>
      toast.success(t => customMessage(t, message), {
        ...errorOptions,
        icon: <Danger />,
        ...params
      })
  };

  return {
    toast: toastify
  };
};

export default useToast;
