/* eslint-disable no-dupe-keys */

export const usersTypes = {
  SIGN_IN: 'SIGN_IN',
  SIGN_OUT: 'SIGN_OUT',
  SAVE_TOKEN: 'SAVE_TOKEN',
  CREATE_PROFILE: 'CREATE_PROFILE',
  GET_CANNY_TOKEN: 'GET_CANNY_TOKEN',
  SET_USER_STATUS: 'SET_USER_STATUS',
  SAVE_APP_ID: 'SAVE_APP_ID',
  SET_IS_FLOWS: 'SET_IS_FLOWS',
  CLEAR_USER_DATA: 'CLEAR_USER_DATA',
  INVITE_MEMBER: 'INVITE_MEMBER',
  CLEAR_INVITE_MEMBER_ERROR: 'CLEAR_INVITE_MEMBER_ERROR',
  SET_PARTIAL_USER_DATA: 'SET_PARTIAL_USER_DATA'
};

export const viewsTypes = {
  SET_VIEW: 'SET_VIEW'
};

export const contactsTypes = {
  GET_CONTACTS: 'GET_CONTACTS',
  GET_CONTACT: 'GET_CONTACT',
  GET_CONTACTS_BY_COMPANY: 'GET_CONTACTS_BY_COMPANY',
  INVITE_CONTACT: 'INVITE_CONTACT'
};

export const notificationTypes = {
  CLEAR_NOTIFICATIONS: 'CLEAR_NOTIFICATIONS'
};

export const dialogTypes = {
  CLEAR_DIALOG: 'CLEAR_DIALOG',
  SHOW_DIALOG: 'SHOW_DIALOG'
};

/**
 * The WINDOWS terminology comes from Messenger, indicating a company integrated with Microsoft 365 without Teams
 */
export const chatsTypes = {
  SKIPPED: 'SKIPPED',
  SLACK: 'SLACK',
  MS_TEAMS: 'MS_TEAMS',
  SIGN_IN_SLACK: 'SIGN_IN_SLACK',
  SIGN_IN_MS: 'SIGN_IN_MS',
  INTEGRATE_MS: 'INTEGRATE_MS',
  UPDATE_CHAT_CONFIGURATION: 'UPDATE_CHAT_CONFIGURATION',
  UNINSTALL_CHAT_CONFIGURATION: 'UNINSTALL_CHAT_CONFIGURATION',
  WINDOWS: 'WINDOWS',
  MAC: 'MAC'
};

export const configurationsTypes = {
  GET_CONFIGURATION: 'GET_CONFIGURATION',
  GET_TEAMS: 'GET_TEAMS',
  UPDATE_SSO_ENABLED: 'UPDATE_SSO_ENABLED',
  GET_MS_CONFIGURATION: 'GET_MS_CONFIGURATION'
};

export const flowsTypes = {
  FLOW_ITEMS_READY: 'FLOW_ITEMS_READY',
  GET_FLOWS: 'GET_FLOWS',
  GET_FLOW: 'GET_FLOW',
  CREATE_FLOW: 'CREATE_FLOW',
  UPDATE_FLOW: 'UPDATE_FLOW',
  RENAME_FLOW: 'RENAME_FLOW',
  DELETE_FLOW: 'DELETE_FLOW',
  CREATE_FLOW_TEMPLATE: 'CREATE_FLOW_TEMPLATE',
  GET_FILTER_ATTRIBUTES: 'GET_FILTER_ATTRIBUTES',
  GET_FLOW_TEMPLATES: 'GET_FLOW_TEMPLATES',
  GET_BOARDS: 'GET_BOARDS',
  GET_STATUS: 'GET_STATUSES',
  GET_FILTER_BOARDS: 'GET_FILTER_BOARDS',
  GET_TICKET_INTEGRATION: 'GET_TICKET_INTEGRATION'
};

export const channelsTypes = {
  GET_CHANNELS: 'GET_CHANNELS',
  SAVE_CHANNEL: 'SAVE_CHANNEL',
  GET_CATEGORIES: 'GET_CATEGORIES'
};

export const companiesTypes = {
  GET_COMPANIES: 'GET_COMPANIES',
  GET_SUB_COMPANIES: 'GET_SUB_COMPANIES',
  GET_COMPANY: 'GET_COMPANY',
  UPDATE_MESSENGER_STATUS: 'UPDATE_MESSENGER_STATUS',
  SAVE_COMPANY: 'SAVE_COMPANY',
  GET_USERS_COMPANY: 'GET_USERS_COMPANY',
  GET_PLATFORMS_COMPANY: 'GET_PLATFORMS_COMPANY'
};

export const actionPlatformsTypes = {
  GET: 'ACTION_PLATFORMS_GET'
};

export const actionPlatformConfigurationsTypes = {
  CLEAR_STATE: 'ACTION_PLATFORMS_CONFIGURATIONS_CLEAR_STATE',
  GET_SINGLE: 'ACTION_PLATFORMS_CONFIGURATIONS_GET_SINGLE',
  UPDATE: 'ACTION_PLATFORMS_CONFIGURATIONS_UPDATE',
  CREATE: 'ACTION_PLATFORMS_CONFIGURATIONS_CREATE',
  DELETE: 'ACTION_PLATFORMS_CONFIGURATIONS_DELETE'
};

export const actionStatuses = {
  FULFILLED: '_FULFILLED',
  PENDING: '_PENDING',
  REJECTED: '_REJECTED'
};

export const workspacesTypes = {
  GET_WORKSPACES: 'GET_WORKSPACES',
  ADD_WORKSPACE: 'ADD_WORKSPACE'
};
