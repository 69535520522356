import React from 'react';
import PropTypes from 'prop-types';
import useAuthUser from '../../../hooks/useAuthUser';
import * as Styled from './styles';
import ColorPicker from '../ColorPicker';

const StyleBlock = ({ state, onChangeState }) => {
  const { featureFlags } = useAuthUser();
  const isDesktopIconFieldEnabled = featureFlags.includes('desktop_icon_field');

  const updateState = newState => {
    onChangeState({ ...state, ...newState });
  };

  return (
    <>
      <Styled.Wrapper>
        <Styled.SubTitle>Primary color</Styled.SubTitle>
        <ColorPicker
          color={state.primaryColor}
          onChange={primaryColor => updateState({ primaryColor })}
        />
      </Styled.Wrapper>
      <Styled.Wrapper display="flex">
        <Styled.Wrapper>
          <Styled.SubTitle>Web icon</Styled.SubTitle>
          <Styled.IconWrapper>
            <Styled.Icon
              onChange={trayIcon => updateState({ trayIcon })}
              name="icon-uploader"
              value={state.trayIcon.url}
              components={{
                imagePreview: children => (
                  <Styled.TrayIconHolder color={state.primaryColor}>
                    {children}
                  </Styled.TrayIconHolder>
                )
              }}
            />
          </Styled.IconWrapper>
          <Styled.IconTooltip>Icon for web widget</Styled.IconTooltip>
        </Styled.Wrapper>
        {isDesktopIconFieldEnabled && (
          <Styled.Wrapper marginLeft={30}>
            <Styled.SubTitle>Desktop icon (Win & Mac)</Styled.SubTitle>
            <Styled.IconWrapper>
              <Styled.Icon
                onChange={desktopIcon => updateState({ desktopIcon })}
                name="icon-uploader"
                value={state.desktopIcon.url}
                components={{
                  imagePreview: children => (
                    <Styled.DesktopIconHolder>{children}</Styled.DesktopIconHolder>
                  )
                }}
              />
            </Styled.IconWrapper>
            <Styled.IconTooltip>
              <div>Taskbar and Tray Icon</div>
              <div>Upload a PNG at least 256px by 256px</div>
            </Styled.IconTooltip>
          </Styled.Wrapper>
        )}
      </Styled.Wrapper>
      <Styled.Wrapper>
        <Styled.SubTitle>In-app logo</Styled.SubTitle>
        <Styled.LogoWrapper>
          <Styled.Logo
            onChange={logo => updateState({ logo })}
            name="logo-uploader"
            value={state.logo.url}
          />
        </Styled.LogoWrapper>
      </Styled.Wrapper>
    </>
  );
};

StyleBlock.defaultProps = {
  state: null,
  onChangeState: () => {}
};

StyleBlock.propTypes = {
  state: PropTypes.any,
  onChangeState: PropTypes.func
};

export default StyleBlock;
