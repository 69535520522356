import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 588px;
  min-height: 300px;
  margin-bottom: 16px;
  background: ${({ theme }) => theme.colors.background.default};
  margin: 10vh auto 20px;
  padding: 30px 66px;
`;

export const Title = styled.h3`
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: ${({ theme }) => theme.colors.text.default};
`;

export const SubTitle = styled.h3`
  margin-top: 30px;
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
  color: ${({ theme }) => theme.colors.text.default};
`;

export const Content = styled.div`
  margin-bottom: 30px;

  p {
    margin-bottom: 35px;
  }

  button {
    padding: 0px 30px;

    svg {
      stroke: ${({ theme }) => theme.colors.brand.light.base};
      margin-right: 7px;
    }

    &:hover {
      svg {
        stroke: #fff;
      }
    }
  }
`;

export const ButtonLink = styled.button`
  display: block;
  margin: 0 auto;
  border: none;
  background: none;
  font-size: 14px;
  line-height: 33px;
  text-align: center;
  color: ${({ theme }) => theme.colors.brand.light.base};
`;

export const Footer = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.colors.brand.light.base};
  font-weight: bold;
`;

export const Code = styled.pre`
  background-color: #efeeea;
  padding: 10px 20px;
  line-height: 20px;
  font-size: 14px;
  border-radius: 5px;
  width: 100%;
  text-wrap: pretty;
  overflow-wrap: break-word;
  margin-top: 16px;
  overflow-y: scroll;
  height: 400px;
`;

export const ClipboardContainer = styled.div`
  width: 30px;
  height: 30px;
  float: right;

  button {
    background-color: ${({ theme }) => theme.colors.background.secondary};
    border-color: ${({ theme }) => theme.colors.background.secondary};
    padding: 5px;
    border: 0px;

    svg {
      margin-left: 5px;
    }
  }

  button:hover {
    background-color: #ccc;
  }
`;
