import React from 'react';
import { Route } from 'react-router-dom';
import MessengerWidget from 'src/components/MessengerSettings/MessengerWidget';
import { Aside, Content, Body } from './style';
import Tabs from './Tabs';
import useTabs from './Tabs/hook';
import IntentList from './IntentList';
import AgentSettings from './Settings';

const Agent = function Agent({ base, appId }) {
  const { isLoading, agentEnabled, updateAgentSettings } = useTabs();

  return (
    <>
      <Tabs
        agentEnabled={agentEnabled}
        updateAgentSettings={updateAgentSettings}
        isLoading={isLoading}
      />
      <Content>
        <Body>
          <Route path={`${base}`} exact component={IntentList} />
          <Route path={`${base}/settings`} exact component={AgentSettings} />
        </Body>
        <Aside>
          <MessengerWidget appId={appId} />
        </Aside>
      </Content>
    </>
  );
};

export default Agent;
