/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import ActionModal from 'src/components/ActionModal';
import SimpleButton from 'src/components/SimpleButton';
import SimpleInput from 'src/components/SimpleInput';
import { Wrapper, FormGroup } from './CreateViewModal.style';

const CreateViewModal = ({ isOpen = false, onClose, onCreate }) => {
  const [name, setName] = useState('');
  const [isCreating, setIsCreating] = useState(false);

  const handleNameChange = useCallback(e => {
    setName(e.target.value);
  }, []);

  const handleCreate = useCallback(async () => {
    setIsCreating(true);
    try {
      await onCreate(name);
    } catch (error) {
      console.error(error);
    }
    setIsCreating(false);
  }, [name]);

  return (
    <Wrapper>
      <ActionModal
        isOpen={isOpen}
        aligned="left"
        title="Create View"
        toggleHandler={onClose}
        size="small"
        headerStyle={{ justifyContent: 'center' }}
      >
        <FormGroup>
          <label htmlFor="create-view-modal-name">Name view</label>
          <SimpleInput
            id="create-view-modal-name"
            label="View name"
            onChange={handleNameChange}
            placeholder="Give your View a name"
          />
        </FormGroup>
        <FormGroup>
          <SimpleButton
            isDisabled={!name || isCreating}
            isLoader={isCreating}
            onClick={handleCreate}
            full
          >
            Create View
          </SimpleButton>
        </FormGroup>
      </ActionModal>
    </Wrapper>
  );
};

CreateViewModal.defaultProps = {
  isOpen: false
};

CreateViewModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired
};

export default CreateViewModal;
