import React from 'react';
import PropTypes from 'prop-types';

import { WrapperStyled } from './style';

const TopPanel = ({ children }) => <WrapperStyled>{children}</WrapperStyled>;

TopPanel.propTypes = {
  children: PropTypes.node.isRequired
};

export default TopPanel;
