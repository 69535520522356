import styled, { css } from 'styled-components';
import MessageForm from '../MessageForm';

export const TableWrapper = styled.div`
  background: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  position: relative;
  min-height: 100px;

  .pagination {
    margin-right: 8px;
    margin: 0;
    font-size: 14px;

    li {
      display: inline-block;
      min-width: 32px;
      height: 32px;
      margin-right: 8px;
      line-height: 30px;
      text-align: center;
      vertical-align: middle;
      background-color: #ffffff;
      border: 1px solid #d9d9d9;
      border-radius: 2px;
      outline: 0;
      cursor: pointer;
      user-select: none;
      transition: all 0.2s;

      &.active {
        border-color: ${({ theme }) => theme.colors.brand.light.base};
        color: ${({ theme }) => theme.colors.brand.light.base};
      }

      &.disabled {
        cursor: not-allowed;
        color: hsla(0, 0%, 0%, 0.25);
      }

      &:hover,
      &:focus {
        border-color: ${({ theme }) => theme.colors.brand.light.hover};
      }

      &:hover,
      &:focus a {
        color: ${({ theme }) => theme.colors.brand.light.hover};
      }
    }

    li:not(.disabled) a:focus:not(:active) {
      color: ${({ theme }) => theme.colors.brand.light.base};
    }

    a {
      display: block;
      padding: 0 6px;
      outline: none;
      transition: all 0.2s ease-out;
    }

    .previous,
    .next {
      border: none;

      &:hover,
      &:focus {
        color: ${({ theme }) => theme.colors.brand.light.hover};
      }
    }
  }
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
`;

export const TableHeader = styled.thead`
  th {
    padding: 15px 20px;
    border-bottom: 1px solid #e0e0e0;
    font-size: 12px;
    font-weight: 400;
    white-space: nowrap;
    color: #9e9e9e;
    ${({ hasTopBorder }) => hasTopBorder && 'border-top: 1px solid #e0e0e0;'}

    &.compact {
      padding-left: 0;
      padding-right: 0;
      min-width: 0;
    }

    &.checkbox {
      max-width: 10px;
    }
  }
`;

export const TableCell = styled.td`
  max-width: 240px;
  padding: 15px 20px;
  border-bottom: 1px solid #e0e0e0;
  font-size: 12px;
  color: #212121;

  span {
    vertical-align: middle;
  }

  &.compact {
    padding: 0;
    min-width: 0;
    max-width: 110px;
  }

  &.checkbox {
    max-width: 10px;
  }
`;

export const TableRow = styled.tr`
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
  transition: background-color 0.2s;

  &:hover {
    background-color: ${({ theme }) => theme.colors.brand.lighter.hover};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      cursor: default;

      &:hover {
        background-color: transparent;
      }
    `}
`;

export const Loader = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 0.5);

  svg {
    width: 40px;
    height: 40px;
  }
`;

export const Message = styled(MessageForm)`
  max-width: 500px;
  margin: 20px auto 0;
`;

export const Placeholder = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-size: 15px;
  padding: 32px 0;
  color: #9e9e9e;
  margin: 0;
`;

export const Info = styled.span`
  margin-right: 25px;
  margin-top: 4px;
  font-size: 12px;
  line-height: 14px;
  color: #212121;
`;

export const TopPanel = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > div {
    padding: 8px 0 8px 16px;
  }
`;

export const BottomPanel = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 8px;

  & ${Info} {
    margin-top: 0;
  }
`;

export const Reset = styled.button`
  margin-left: 8px;
  margin-right: auto;
  padding: 0;
  border: none;
  background-color: transparent;
  font-size: 12px;
  line-height: 14px;
  color: ${({ theme }) => theme.colors.brand.light.base};

  &:hover {
    color: ${({ theme }) => theme.colors.brand.light.hover};
  }
`;

export const SortButton = styled.button`
  margin-left: 10px;
  padding: 0;
  border: none;
  background: none;

  &:focus {
    outline: none;
  }

  &:hover svg {
    fill: ${({ theme }) => theme.colors.brand.light.hover};
  }

  svg {
    width: 13px;
    height: 6px;
    fill: ${({ isActive, theme }) => (isActive ? theme.colors.brand.light.active : '#D9D9D9')};
    transition: fill 0.2s;
  }
`;

export const BulkActions = styled.div`
  margin: 0 20px 0 auto;
  width: 200px;
`;

export const TableCellWithoutPadding = styled.td`
  max-width: 240px;
  border-bottom: 1px solid #e0e0e0;
  font-size: 12px;
  color: #212121;

  span {
    vertical-align: middle;
  }

  &.compact {
    padding: 0;
    min-width: 0;
    max-width: 110px;
  }

  &.checkbox {
    max-width: 10px;
  }
`;

export const SelectedInfo = styled.span`
  margin-left: 12px;
  margin-top: 10px;
  font-size: 12px;
  line-height: 12px;
  color: #212121;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`;
