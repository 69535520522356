import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { SketchPicker } from 'react-color';
import * as Styled from './styles';

const ColorField = props => {
  const {
    name,
    value,
    label,
    circleSize,
    setColorHandler,
    activeColor,
    tabIndex,
    error,
    required
  } = props;
  const [color, setColor] = useState(value);
  const [expanded, setExpanded] = useState(false);
  const defaultColor = '#dddddd';

  useEffect(() => {
    setColorHandler(value || defaultColor);
    setExpanded(true);
    setColor(value);
  }, [value]);

  const handleHideOptions = e => {
    e.stopPropagation();
    setExpanded(false);
  };
  const handleExpand = () => {
    setExpanded(!expanded);
    if (color && !expanded) {
      setColorHandler(color);
    }
  };
  const handleChange = color => {
    if (color) {
      setColor(color.hex);
      setColorHandler(color.hex);
    }
  };
  return (
    <Styled.Wrapper circleSize={circleSize} isActive={activeColor === color}>
      {label ? <label htmlFor={name}>{label}</label> : null}
      <Styled.SelectInner
        className={`${expanded ? 'expanded' : ''}`}
        tabIndex={tabIndex || '0'}
        aria-label={label}
        aria-haspopup="true"
        aria-expanded={expanded}
        isError={error}
        circleSize={circleSize}
      >
        <Styled.Input id={name} type="text" required={required} />
        {expanded ? <Styled.Overlay onClick={handleHideOptions} /> : null}
        <Styled.SelectSingleValue
          circleSize={circleSize}
          onClick={handleExpand}
          color={color || defaultColor}
        />
        {expanded ? (
          <Styled.Options>
            <SketchPicker
              width={240}
              height={140}
              onChange={handleChange}
              color={color || defaultColor}
            />
          </Styled.Options>
        ) : null}
      </Styled.SelectInner>
    </Styled.Wrapper>
  );
};

ColorField.defaultProps = {
  value: '#dddddd',
  activeColor: null,
  label: '',
  tabIndex: 0,
  error: '',
  required: false,
  name: '',
  circleSize: 42
};

ColorField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  activeColor: PropTypes.string,
  error: PropTypes.string,
  tabIndex: PropTypes.number,
  circleSize: PropTypes.number,
  setColorHandler: PropTypes.func.isRequired,
  required: PropTypes.bool
};

export default ColorField;
