import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  signInSlack,
  integrateMs,
  signInMs,
  updateChatConfiguration
} from 'src/redux/modules/chats/chatsActions';
import { useSignUpChatPage } from './hook';
import SignupChatPage from './SignupChat';
import { getLocalStorage } from '../../utils';

const SignupChatPageContainer = ({
  signInSlack,
  signInMs,
  chatConfiguration,
  updateChatConfiguration,
  integrateMs,
  chatErrorMessage,
  chatInfoLoader,
  slackHasNewConfiguration,
  msTeamsHasNewConfiguration
}) => {
  const history = useHistory();
  const chatPlatformStep = getLocalStorage('chatPlatformStep');
  const isReintegration = slackHasNewConfiguration || msTeamsHasNewConfiguration;

  useSignUpChatPage({
    signInSlack,
    integrateMs,
    updateChatConfiguration,
    history,
    isReintegration
  });

  return (
    <SignupChatPage
      chatErrorMessage={chatErrorMessage}
      slackHasNewConfiguration={slackHasNewConfiguration}
      msTeamsHasNewConfiguration={msTeamsHasNewConfiguration}
      signInMs={signInMs}
      chatInfoLoader={chatInfoLoader}
      chatPlatformStep={chatPlatformStep}
      chatConfiguration={chatConfiguration}
      updateChatConfiguration={updateChatConfiguration}
    />
  );
};

SignupChatPageContainer.propTypes = {
  signInSlack: PropTypes.func.isRequired,
  integrateMs: PropTypes.func.isRequired,
  signInMs: PropTypes.func.isRequired,
  updateChatConfiguration: PropTypes.func.isRequired,
  chatErrorMessage: PropTypes.string,
  chatInfoLoader: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  slackHasNewConfiguration: PropTypes.bool,
  msTeamsHasNewConfiguration: PropTypes.bool,
  chatConfiguration: PropTypes.string
};

SignupChatPageContainer.defaultProps = {
  chatErrorMessage: '',
  chatInfoLoader: false,
  slackHasNewConfiguration: false,
  msTeamsHasNewConfiguration: false,
  chatConfiguration: null
};

const mapStateToProps = state => ({
  chatErrorMessage: state.chatsReducer.chatErrorMessage,
  chatInfoLoader: state.chatsReducer.chatInfoLoader,
  slackHasNewConfiguration: state.usersReducer.user.slackHasNewConfiguration,
  msTeamsHasNewConfiguration: state.usersReducer.user.msTeamsHasNewConfiguration
});

const mapDispatchToProps = dispatch => ({
  signInSlack: code => dispatch(signInSlack(code)),
  integrateMs: (code, state, path, chatConfiguration) =>
    dispatch(integrateMs(code, state, path, chatConfiguration)),
  updateChatConfiguration: (companyId, chatConfig) =>
    dispatch(updateChatConfiguration(companyId, chatConfig)),
  signInMs: () => dispatch(signInMs())
});

export default connect(mapStateToProps, mapDispatchToProps)(SignupChatPageContainer);
