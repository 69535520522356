import React, { useState } from 'react';
import PropTypes from 'prop-types';
import PartnerDeploymentMetrics from '../PartnerDeploymentMetrics';
import PartnerDeployedClients from '../PartnerDeployedClients';
import { MagicAiTrialProvider } from '../context/magicAiTrialContext';

const PartnerDeployedClientsTab = ({ history, location }) => {
  const [viewFiltersAndSorting, setViewFiltersAndSorting] = useState({
    filters: {
      state: 'deployed'
    },
    sortOption: 'requests'
  });
  const [view, setView] = useState(null);

  return (
    <>
      <MagicAiTrialProvider>
        <PartnerDeploymentMetrics />

        <PartnerDeployedClients
          history={history}
          location={location}
          viewFiltersAndSorting={viewFiltersAndSorting}
          setViewFiltersAndSorting={setViewFiltersAndSorting}
          view={view}
          setView={setView}
        />
      </MagicAiTrialProvider>
    </>
  );
};

PartnerDeployedClientsTab.propTypes = {
  history: PropTypes.objectOf(PropTypes.any),
  location: PropTypes.objectOf(PropTypes.any)
};

PartnerDeployedClientsTab.defaultProps = {
  history: {},
  location: {}
};

export default PartnerDeployedClientsTab;
