import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button, RadioButton } from '@Thread-Magic/jasmine';
import { useQuery, useMutation } from 'react-query';
import { useForm, Controller, useWatch } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import useAuthUser from 'src/hooks/useAuthUser';
import queryKeys from 'src/constants/queryKeys';
import PageHolder from 'src/components/PageHolder';
import { queryClient } from 'src/config/queryClient';
import { showDialog } from 'src/redux/modules/dialog/dialogActions';
import { chatgenieAPI } from 'src/config/api';
import { ReactComponent as ArrowIcon } from './arrow.svg';
import { generateFlow, getRelativeGreeting } from './utils';
import * as Styled from './styles';

const TimePickerIcon = (
  <Styled.IconTimePicker>
    <ArrowIcon />
  </Styled.IconTimePicker>
);

const Hours = ({ companyId }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const authUser = useAuthUser();
  const format = 'h:mm A';
  const isParentCompany = () => authUser.company.id === companyId;
  const { data, isLoading } = useQuery([queryKeys.GET_MESSENGER_HOURS, companyId], () =>
    chatgenieAPI.getHoursData({ companyId })
  );

  const defaultState = {
    timezone: {
      id: null,
      name: null
    },
    override_hours: 0,
    availability: 'always',
    settings: [],
    show_default_message: false,
    default_message: `${getRelativeGreeting()}, our support team is currently offline.`
  };

  const { control, handleSubmit, reset } = useForm({ defaultValues: defaultState });
  const showDefaultMessage = useWatch({ control, name: 'show_default_message' });
  const availability = useWatch({ control, name: 'availability' });
  const settings = useWatch({ control, name: 'settings' });
  const overrideHours = useWatch({ control, name: 'override_hours' });
  const isValidForm =
    availability === 'always' ||
    (availability === 'custom' && settings.some(item => item.is_available));
  const getOptions = useCallback(query => chatgenieAPI.getTimezones({ companyId, query }), []);
  const mutation = useMutation(data => chatgenieAPI.updateHoursData({ companyId, data }), {
    onSuccess: res => {
      queryClient.setQueryData([queryKeys.GET_MESSENGER_HOURS, companyId], res.data.data);
      dispatch(
        showDialog({
          text: 'Successfully saved',
          type: 'success'
        })
      );
    }
  });

  useEffect(() => {
    if (data) {
      if (!data.timezone) {
        getOptions(Intl.DateTimeFormat().resolvedOptions().timeZone).then(res => {
          reset({
            ...data,
            settings: data.settings || defaultState.settings,
            default_message: data.default_message || defaultState.default_message,
            timezone: res[0]
          });
        });

        return;
      }

      reset({
        ...data,
        settings: data.settings || defaultState.settings,
        default_message: data.default_message || defaultState.default_message
      });
    }
  }, [data, reset]);

  useEffect(() => {
    mutation.reset();
  }, []);

  if (isLoading) {
    return <PageHolder isLoading={isLoading} />;
  }

  return (
    <PageHolder>
      <Styled.TimePickerPopup />
      <Styled.Form
        onSubmit={handleSubmit(mutation.mutate)}
        disabled={!isParentCompany() && !overrideHours}
      >
        {!isParentCompany() && (
          <Styled.FormGroup>
            <Controller
              control={control}
              name="override_hours"
              render={({ field: { onChange, ref, value } }) => (
                <Styled.CustomCheckbox
                  id="override-hours"
                  onChange={onChange}
                  inputRef={ref}
                  name="override_hours"
                  checked={value}
                >
                  Override parent hours
                </Styled.CustomCheckbox>
              )}
            />
            <Styled.Separator />
          </Styled.FormGroup>
        )}
        <Styled.FormGroup>
          <Styled.GroupTitle>Time Zone</Styled.GroupTitle>
          <Controller
            control={control}
            name="timezone"
            render={({ field: { onChange, ref, value } }) => (
              <Styled.CustomSelect
                id="timezone"
                inputRef={ref}
                value={value}
                onChange={val => onChange(val)}
                options={getOptions}
                getOptionValue={({ id }) => id}
                getOptionLabel={({ utc_offset, name }) => `(GMT${utc_offset}) ${name}`}
              />
            )}
          />
        </Styled.FormGroup>
        <Styled.FormGroup>
          <Styled.GroupTitle>Help desk hours</Styled.GroupTitle>
          <Controller
            control={control}
            name="availability"
            render={({ field: { onChange, ref, name, onBlur, value } }) => (
              <RadioButton
                onChange={onChange}
                inputRef={ref}
                id="always"
                name={name}
                checked={value === 'always'}
                value="always"
                onBlur={onBlur}
              >
                24 hrs / 7 days
              </RadioButton>
            )}
          />
          <Controller
            control={control}
            name="availability"
            render={({ field: { onChange, ref, name, onBlur, value } }) => (
              <RadioButton
                onChange={onChange}
                inputRef={ref}
                id="custom"
                label="Select working days/hours"
                name={name}
                checked={value === 'custom'}
                value="custom"
                onBlur={onBlur}
              />
            )}
          />
          <Styled.CheckboxGroup isOverlay={availability === 'always'}>
            {!isValidForm ? (
              <Styled.Error>Please select at least one working day.</Styled.Error>
            ) : (
              <Styled.CustomLink
                onClick={() =>
                  history.push({
                    pathname: '/dashboard/flows/configure-flow',
                    state: { flow: generateFlow(settings) }
                  })
                }
                type="button"
              >
                + Create a flow based on these hours
              </Styled.CustomLink>
            )}
            {settings.map((item, index) => (
              <Styled.FormRow key={item.weekday}>
                <Controller
                  control={control}
                  name={`settings.${index}`}
                  render={({ field: { onChange, ref, name, value } }) => (
                    <Styled.CustomCheckbox
                      id={item.weekday}
                      onChange={e => onChange({ ...value, is_available: e.target.checked })}
                      inputRef={ref}
                      name={name}
                      checked={value.is_available}
                      maxWidth="120px"
                    >
                      {item.weekday}
                    </Styled.CustomCheckbox>
                  )}
                />
                <Controller
                  control={control}
                  name={`settings.${index}`}
                  render={({ field: { onChange, value } }) => (
                    <Styled.CustomTimePicker
                      showSecond={false}
                      popupClassName="time-picker-popup"
                      defaultValue={moment(value.time_from, format)}
                      value={moment(value.time_from, format)}
                      format={format}
                      onChange={time => onChange({ ...value, time_from: time.format(format) })}
                      disabled={!value.is_available}
                      use12Hours
                      clearIcon={<></>}
                      inputIcon={TimePickerIcon}
                    />
                  )}
                />
                <Styled.Separator>to</Styled.Separator>
                <Controller
                  control={control}
                  name={`settings.${index}`}
                  render={({ field: { onChange, value } }) => (
                    <Styled.CustomTimePicker
                      showSecond={false}
                      popupClassName="time-picker-popup"
                      defaultValue={moment(value.time_to, format)}
                      value={moment(value.time_to, format)}
                      format={format}
                      onChange={time => onChange({ ...value, time_to: time.format(format) })}
                      disabled={!value.is_available}
                      use12Hours
                      clearIcon={<></>}
                      inputIcon={TimePickerIcon}
                    />
                  )}
                />
              </Styled.FormRow>
            ))}
          </Styled.CheckboxGroup>
        </Styled.FormGroup>
        <Styled.FormGroup>
          <Styled.GroupTitle>Custom message</Styled.GroupTitle>
          <Controller
            control={control}
            name="show_default_message"
            render={({ field: { onChange, ref, value } }) => (
              <Styled.CustomCheckbox
                id="message"
                onChange={onChange}
                inputRef={ref}
                name="message"
                checked={value}
              >
                Use custom message
              </Styled.CustomCheckbox>
            )}
          />
          <Controller
            name="default_message"
            control={control}
            render={({ field }) => (
              <Styled.Messange rows={4} {...field} disabled={!showDefaultMessage} />
            )}
          />
        </Styled.FormGroup>
        {mutation.error && <Styled.Error>{mutation.error.message}</Styled.Error>}
        <Button type="submit" size="large" isLoader={mutation.isLoading} disabled={!isValidForm}>
          Save
        </Button>
      </Styled.Form>
    </PageHolder>
  );
};

Hours.propTypes = {
  companyId: PropTypes.number.isRequired
};

export default Hours;
