import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { ADMIN_ROLE, userRoles } from 'src/constants/user-roles';
import keyBy from 'lodash/keyBy';
import { inviteMember, clearInviteMemberError } from 'src/redux/modules/users/usersActions';
import ActionModal from '../ActionModal';
import SimpleInput from '../SimpleInput';
import SimpleSelect from '../SimpleSelect';
import SimpleButton from '../SimpleButton';
import * as Styled from './styles';
import Switcher from '../Switcher';

const userRoleListByObject = keyBy(userRoles, 'value');

const inviteMemberNotificationOptions = [
  {
    label: 'All',
    value: 2
  },
  {
    label: 'Flow Only',
    value: 1
  },
  {
    label: 'None',
    value: 0
  }
];

const InviteMember = ({
  isOpen,
  inviteMember,
  inviteMemberStatus,
  inviteMemberError,
  clearInviteMemberError,
  onClose
}) => {
  const defaultState = {
    fullname: '',
    email: '',
    phone: '',
    entity_notification: inviteMemberNotificationOptions[0].value,
    role: ADMIN_ROLE,
    is_active: true,
    user_type: 'member',
    redirect_uri: window.location.origin
  };
  const { reset, control, register, handleSubmit } = useForm({
    defaultValues: defaultState
  });

  function handleInviteMemberSubmit(data) {
    inviteMember(data);
  }

  useEffect(() => {
    if (inviteMemberStatus === 'succeeded') {
      onClose();
    }
  }, [inviteMemberStatus]);

  useEffect(() => {
    reset(defaultState);
    clearInviteMemberError();
  }, [isOpen]);

  return (
    <ActionModal
      isOpen={isOpen}
      size="medium"
      title="Invite team members"
      toggleHandler={onClose}
      errorMessage={inviteMemberError}
    >
      <Styled.Form onSubmit={handleSubmit(handleInviteMemberSubmit)}>
        <Styled.FormGrid>
          <div>
            <Styled.Label htmlFor="im-full-name">Full name</Styled.Label>
          </div>
          <div>
            <SimpleInput
              id="im-full-name"
              placeholder="Enter the name of your team member"
              required
              {...register('fullname')}
            />
          </div>
          <div>
            <Styled.Label htmlFor="im-email">Email</Styled.Label>
          </div>
          <div>
            <SimpleInput
              id="im-email"
              type="email"
              placeholder="Enter the email of your team member"
              required
              {...register('email')}
            />
          </div>
          <div>
            <Styled.Label htmlFor="im-phone">Phone number (optional)</Styled.Label>
          </div>
          <div>
            <SimpleInput
              id="im-phone"
              type="phone"
              placeholder="Enter the phone of your team member"
              {...register('phone')}
            />
          </div>
          <div>
            <Styled.Label htmlFor="im-notification">Notification</Styled.Label>
          </div>
          <div>
            <Controller
              control={control}
              name="entity_notification"
              render={({ field: { onChange, value, ref } }) => (
                <SimpleSelect
                  inputRef={ref}
                  id="im-notification"
                  onChange={val => onChange(val.value)}
                  value={inviteMemberNotificationOptions.find(c => c.value === value)}
                  options={inviteMemberNotificationOptions}
                />
              )}
            />
          </div>
          <div>
            <Styled.Label htmlFor="im-role">Role</Styled.Label>
          </div>
          <div>
            <Controller
              control={control}
              name="role"
              render={({ field: { onChange, value, ref } }) => (
                <SimpleSelect
                  inputRef={ref}
                  id="im-role"
                  onChange={val => onChange(val.value)}
                  value={userRoleListByObject[value]}
                  options={userRoles}
                />
              )}
            />
          </div>
        </Styled.FormGrid>
        <Styled.CenterDiv>
          <SimpleButton type="submit" isLoader={inviteMemberStatus === 'loading'}>
            Invite
          </SimpleButton>
        </Styled.CenterDiv>
      </Styled.Form>
    </ActionModal>
  );
};

InviteMember.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  inviteMember: PropTypes.func.isRequired,
  inviteMemberStatus: PropTypes.oneOf(['idle', 'loading', 'succeeded', 'failed']).isRequired,
  inviteMemberError: PropTypes.string,
  clearInviteMemberError: PropTypes.func.isRequired
};

InviteMember.defaultProps = {
  inviteMemberError: null
};

const mapStateToProps = state => ({
  inviteMemberStatus: state.usersReducer.inviteMemberStatus,
  inviteMemberError: state.usersReducer.inviteMemberError
});

const mapDispatchToProps = dispatch => ({
  inviteMember: data => dispatch(inviteMember(data)),
  clearInviteMemberError: () => dispatch(clearInviteMemberError())
});

export default connect(mapStateToProps, mapDispatchToProps)(InviteMember);
