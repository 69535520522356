import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select/async';

import { SelectGroupStyled, SelectBadgeStyled, styles } from './style';

import * as Styled from './style';

const AsyncSelect = ({
  title,
  size,
  value,
  className,
  onChange,
  options,
  getOptionValue,
  getOptionLabel,
  formatGroupLabel,
  noOptionsMessage,
  components,
  onKeyDown,
  filterOption,
  isDisabled,
  label,
  name,
  placeholder,
  ...rest
}) => {
  const GroupLabel = data => (
    <SelectGroupStyled>
      <span>{data.label}</span>
      <SelectBadgeStyled>{data.options.length}</SelectBadgeStyled>
    </SelectGroupStyled>
  );

  return (
    <Fragment>
      {label && <Styled.Label htmlFor={name}>{label}</Styled.Label>}
      <Select
        className={className}
        styles={styles({ size })}
        title={title}
        value={value}
        components={components}
        name={name}
        id={name}
        cacheOptions
        defaultOptions
        getOptionValue={getOptionValue}
        getOptionLabel={getOptionLabel}
        onChange={onChange}
        loadOptions={options}
        isDisabled={isDisabled}
        onKeyDown={onKeyDown}
        filterOption={filterOption}
        noOptionsMessage={noOptionsMessage}
        formatGroupLabel={formatGroupLabel && GroupLabel}
        placeholder={placeholder}
        {...rest}
      />
    </Fragment>
  );
};

AsyncSelect.propTypes = {
  value: PropTypes.any,
  title: PropTypes.string,
  className: PropTypes.string,
  options: PropTypes.func,
  size: PropTypes.string,
  onChange: PropTypes.func,
  getOptionValue: PropTypes.func,
  getOptionLabel: PropTypes.func,
  formatGroupLabel: PropTypes.bool,
  noOptionsMessage: PropTypes.func,
  onKeyDown: PropTypes.func,
  filterOption: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  isDisabled: PropTypes.bool
};

AsyncSelect.defaultProps = {
  isDisabled: false,
  formatGroupLabel: false,
  className: 'simple-select-async'
};

export default AsyncSelect;
