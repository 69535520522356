import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 2rem;
`;

export const Banner = styled.div`
  background-color: white;
  border-radius: 4px;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const BannerItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  column-gap: 10px;
  width: 30%;
`;

export const MagicIconWrapper = styled.div`
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-color: #ccf1eb;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MessageIconWrapper = styled.div`
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-color: #e1ecfe;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    path {
      stroke: #4baaed !important;
    }
    margin-left: 3px;
    margin-top: 3px;
  }
`;

export const ThreadIconWrapper = styled.div`
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-color: #ccf1eb;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    stroke: #00bb99;
  }
`;

export const MetricNumberWrapper = styled.div`
  font-size: 40px;
`;

export const MetricDescription = styled.div`
  font-size: 14px;
  font-weight: bold;
  width: 145px;
`;

export const Divider = styled.div`
  width: 5px;
  height: 40px;
  border-right: 1px solid #dddddd;
  margin-right: 10px;
`;

export const LoadingIconWrapper = styled.div`
  width: 100%;
  text-align: center;
`;
