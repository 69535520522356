import styled from 'styled-components';

export const WrapperStyled = styled.div`
  padding: 20px;
  text-align: center;

  span {
    text-align: center;
  }

  a {
    text-transform: uppercase;
  }
`;
