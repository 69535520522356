import React from 'react';
import { SelectAsync } from '@Thread-Magic/jasmine';
import { chatgenieAPI } from 'src/config/api';
import PropTypes from 'prop-types';
import { ServiceLevelAgreementFieldContainer } from './style';

export default function ServiceLevelAgreementField({ onChange, value }) {
  return (
    <ServiceLevelAgreementFieldContainer>
      <span>Select SLA</span>
      <SelectAsync
        options={chatgenieAPI.getServiceLevelAgreements}
        placeholder="Select SLA"
        onChange={onChange}
        getOptionValue={option => option.id}
        value={value}
      />
    </ServiceLevelAgreementFieldContainer>
  );
}

ServiceLevelAgreementField.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number
  }).isRequired
};
