import styled, { createGlobalStyle } from 'styled-components/macro';

export const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${({ theme }) => theme.colors.background.secondary};
  }
`;
export const Description = styled.p`
  max-width: 400px;
  margin: 0 auto;
  font-size: 14px;
  text-align: center;
  color: ${({ theme }) => theme.colors.gray5};
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 20px auto 20px;
  & > img {
    width: 100%;
    height: auto;
    max-width: 480px;
    min-height: 300px;
  }
`;
export const SubTitle = styled.h2`
  margin: 24px 0 10px;
  font-size: 16px;
  line-height: 22px;
  font-weight: bold;
  color: #000000;
`;
export const Actions = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  width: 100&;
  button {
    margin-top: 13px;
  }
`;
