import { useQuery } from 'react-query';
import { chatgenieAPI } from 'src/config/api';
import { customerDeploymentStates } from '../../constants';

const queryKeysFromQueryString = queryString => {
  const urlSearch = new URLSearchParams(queryString);

  return [...urlSearch.entries()].map(([key, value]) => `${key}=${value}`);
};

const usePartnerClients = ({ companyId, queryString, deploymentState }) => {
  const { data, isLoading } = useQuery({
    queryKey: [
      'partnerClients',
      deploymentState,
      { companyId },
      ...queryKeysFromQueryString(queryString)
    ],
    queryFn: async () => {
      let result;
      switch (deploymentState) {
        case customerDeploymentStates.DEPLOYED:
          result = await chatgenieAPI.partnerCenter.deployedClients(`${queryString}`);
          break;
        case customerDeploymentStates.NOT_DEPLOYED:
          result = await chatgenieAPI.partnerCenter.notDeployedClients(`${queryString}`);
          break;
        default:
          throw new Error('Invalid deployment state');
      }
      return result.data;
    },
    refetchOnMount: true
  });

  return {
    isLoading,
    clients: data
  };
};

export default usePartnerClients;
