import isEqual from 'lodash.isequal';
import { useEffect, useRef, useState } from 'react';
import { flowTransformData } from 'src/utils/data-transformer';
import { getChangedUrl, getUrlParam, compareOnKey } from '../../utils';
import { getDefaultFilterData } from '../../utils/flow-filters';

export const useConfigureFlow = ({
  nextFlow,
  template,
  flowErrors,
  activeFlow,
  getFlow,
  getFlowTemplates,
  getFilterAttributes,
  setIsFlows,
  filterAttributes,
  createFlow,
  updateFlow,
  history,
  location,
  operatorTypes
}) => {
  const activeFlowId = getUrlParam('id');
  const activeFlowTemplate = getUrlParam('template');
  const activeFlowGenerated = location.state?.flow;
  const defaultFlow = {
    id: null,
    name: !activeFlowId && getUrlParam('name'),
    isActive: true,
    isCreateChannel: false,
    entityType: 'ticket',
    channelId: null,
    channel: null,
    notifications: [{ code: 'note' }, { code: 'status' }],
    filterData: getDefaultFilterData(operatorTypes),
    actions: []
  };

  const currentFlow =
    nextFlow || (location.state?.flow && JSON.parse(activeFlowGenerated)) || defaultFlow;

  const [isEditableTitle, setIsEditableTitle] = useState(false);
  const title = useRef(null);
  const inputTitle = useRef(null);
  const [titleWidth, setTitleWidth] = useState(null);
  const [flowChannelError, setFlowChannelError] = useState('');
  const [entityModal, setEntityModal] = useState({ isVisible: false, entityType: null });
  const [entityError, setEntityError] = useState('');
  const [filterDataError, setFilterDataError] = useState('');
  const [channelError, setChannelError] = useState('');
  const [actionError, setActionError] = useState('');
  const [isSavedFlow, setIsSavedFlow] = useState(true);
  const [flow, setFlow] = useState(currentFlow);

  const setUrl = value => {
    const search = getChangedUrl({ url: location.search, name: 'name', value });
    history.replace(`${location.pathname}${search}`, { ...location.state });
  };

  const updateTitleWidth = () => {
    const rect = title.current.getBoundingClientRect();
    setTitleWidth(rect.width);
  };

  useEffect(() => {
    setEntityError(flowErrors.entityType);
    setFilterDataError(flowErrors.filterData);
    setChannelError(flowErrors.channel);
  }, [flowErrors]);

  useEffect(() => {
    if (activeFlow.id !== Number(activeFlowId) && activeFlowId) {
      getFlow(activeFlowId);
    }

    if (activeFlowTemplate) {
      getFlowTemplates();
    }

    if (activeFlow.id) {
      setFlow(activeFlow);
      setUrl(activeFlow.name);
    }
  }, [activeFlow.id]);

  useEffect(() => {
    if (activeFlowTemplate && template && !flow.id) {
      setFlow({ ...template });
    }
  }, [template]);

  useEffect(() => {
    if (activeFlowGenerated) {
      setFlow({ ...JSON.parse(activeFlowGenerated) });
    }
  }, [activeFlowGenerated]);

  useEffect(() => {
    if (flow.entityType) {
      getFilterAttributes(flow.entityType);
    }
  }, [flow.entityType]);

  useEffect(() => {
    if (
      filterAttributes.length &&
      !activeFlowId &&
      !activeFlowTemplate &&
      !activeFlowGenerated &&
      operatorTypes
    ) {
      setFlow({ ...flow, filterData: getDefaultFilterData(operatorTypes, filterAttributes[0]) });
    }
  }, [filterAttributes, operatorTypes]);

  useEffect(() => {
    if (!isEqual(flow, activeFlow)) {
      setIsSavedFlow(false);
    } else {
      setIsSavedFlow(true);
    }
  }, [flow]);

  useEffect(() => {
    if (flow.id) {
      setTitleWidth(title.current.clientWidth);
    }
  }, [flow.name]);

  const changeEntity = entityType => {
    const { value } = entityType;
    if (
      flow.entityType !== value &&
      flow.filterData.rules.length &&
      flow.filterData.rules[0].value
    ) {
      setEntityModal({ isVisible: true, entityType: value });
    } else {
      setEntityError('');
      setFlow({ ...flow, entityType: value });
    }
  };

  const changeInboxChannel = value => {
    setFlow({ ...flow, inboxChannel: value });
  };

  const changeChannel = value => {
    setChannelError('');
    setFlow({ ...flow, channel: value });
  };

  const changeAction = () => {
    setActionError('');
    setFlow({ ...flow });
  };

  const changeCategory = data => {
    setChannelError('');
    setFlow({
      ...flow,
      category: { id: data.value, name: data.name },
      channel: flow.category && flow.category.id !== data.value ? null : flow.channel
    });
  };

  const changeFilters = filterData => {
    setFilterDataError('');
    setFlow({ ...flow, filterData });
  };

  const changeActiveFlow = event => {
    setFlow({ ...flow, isActive: event.target.checked });
  };

  const changeCreateChannel = event => {
    setFlow({ ...flow, isCreateChannel: event.target.checked });
  };

  const changeTimezone = timezone => {
    setFlow({ ...flow, timezone });
  };

  const changeDynamicFlow = event => {
    setFlow({ ...flow, type: event.target.checked ? 'dynamic' : 'regular' });
  };

  const changeIsReplyBroadcast = event => {
    setFlow({ ...flow, isReplyBroadcast: event.target.checked });
  };
  const changeActionsList = actions => {
    setFlow({ ...flow, actions });
  };

  const saveFlow = () => {
    const afterSavingCallback = res => {
      setIsSavedFlow(true);
      setFlow(flowTransformData(res.value.data.data));
      setIsFlows();
      if (activeFlowTemplate) history.replace('/dashboard/flows');
    };

    if (flow.id && !activeFlowTemplate && !activeFlowGenerated) {
      updateFlow(flow).then(afterSavingCallback);
    } else {
      createFlow(flow).then(afterSavingCallback);
    }
  };

  const onClickFlowTitle = () => {
    updateTitleWidth();
    setIsEditableTitle(true);
    setTimeout(() => inputTitle.current.focus(), 0);
  };

  const onChangeFlowTitle = event => {
    setUrl(event.target.value);
    setFlow({ ...flow, name: event.target.value });
    updateTitleWidth();
  };

  const changeFlowByEntity = event => {
    event.preventDefault();
    setFlow({
      ...flow,
      entityType: entityModal.entityType,
      filterData: getDefaultFilterData(operatorTypes)
    });
    setEntityModal({ isVisible: !entityModal.isVisible });
  };

  const changeNotification = (event, notification) => {
    if (event.target.checked) {
      setFlow({
        ...flow,
        notifications: [...flow.notifications, { code: notification.code }].sort(
          compareOnKey('code')
        )
      });
    } else {
      setFlow({
        ...flow,
        notifications: flow.notifications
          .filter(item => notification.code !== item.code)
          .sort(compareOnKey('code'))
      });
    }
  };

  return {
    flow,
    changeActiveFlow,
    saveFlow,
    isSavedFlow,
    entityError,
    changeEntity,
    filterDataError,
    changeFilters,
    channelError,
    changeInboxChannel,
    changeChannel,
    changeCategory,
    changeCreateChannel,
    changeDynamicFlow,
    changeIsReplyBroadcast,
    changeNotification,
    changeActionsList,
    entityModal,
    setEntityModal,
    setFlow,
    onClickFlowTitle,
    onChangeFlowTitle,
    isEditableTitle,
    titleWidth,
    inputTitle,
    title,
    setIsEditableTitle,
    changeFlowByEntity,
    actionError,
    changeAction,
    flowChannelError,
    setFlowChannelError,
    changeTimezone
  };
};
