export const columns = [
  {
    key: 'name',
    title: 'Name',
    isSort: false
  },
  {
    key: 'description',
    title: 'Description',
    isSort: false
  },
  {
    key: 'status',
    title: 'Status',
    isSort: false,
    component: () => 'Running'
  }
];
