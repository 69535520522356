import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { ReactComponent as AppDeploymentImage } from 'src/assets/images/app-deployment.svg';
import Card from '../components/Card';

const OnBoarding = () => {
  const history = useHistory();

  const handleNavigateToAuthScreen = useCallback(async () => {
    history.push('/dashboard/deployment-assist/auth');
  }, []);

  return (
    <Card
      image={<AppDeploymentImage />}
      title="Find out which clients you can deploy Teams to!"
      description="We help you deploy your Teams app to your top Teams users, by showing usage across your tenants."
      action={{
        action: handleNavigateToAuthScreen,
        label: 'Get started'
      }}
    />
  );
};

OnBoarding.propTypes = {};

export default OnBoarding;
