export const normalizeClientCompanyFilters = filters => {
  return Object.keys(filters).reduce((prev, key) => {
    if (key === 'app' && filters[key] === 'any') {
      return prev;
    }
    if (key === 'companyType' && filters[key]?.id) {
      return { ...prev, company_type: filters[key].id };
    }
    if (key === 'agreementType' && filters[key]?.id) {
      return { ...prev, agreement_type: filters[key].id };
    }
    return { ...prev, [key]: filters[key] };
  }, {});
};
