import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { NavLink, withRouter } from 'react-router-dom';
import { ReactComponent as Spinner } from 'src/assets/icons/spinner.svg';
import BackButton from '../BackButton';
import BreadCrumbs from '../BreadCrumbs';
import TopPanel from '../TopPanel';
import {
  ContentAreaStyled,
  MainPanelStyled,
  ModalContentStyled,
  ModalOverlayStyled,
  PageLogoStyled,
  TabsStyled,
  TitleLabelStyled
} from './style';
import * as Styled from './style';

const PageWrapper = props => {
  const {
    title,
    titleLabel,
    titleComponent,
    tools,
    children,
    isModalContent,
    isBackButton,
    backButtonPath,
    backButtonState,
    history,
    pageLogo,
    isPage,
    tabs,
    isLoader,
    backgroundColor,
    padding
  } = props;

  const page = children => (
    <Fragment>
      {title && (
        <Helmet>
          <title>{title || ''} - Thread</title>
        </Helmet>
      )}
      <TopPanel>
        <MainPanelStyled>
          <BreadCrumbs>
            {isBackButton && (
              <BackButton
                onClick={() =>
                  backButtonPath
                    ? history.replace(backButtonPath, backButtonState)
                    : history.goBack()
                }
              />
            )}
            {titleLabel && <TitleLabelStyled>{titleLabel}</TitleLabelStyled>}
            <Styled.Title>{titleComponent || title}</Styled.Title>
          </BreadCrumbs>
          {tools}
        </MainPanelStyled>
        {tabs.length ? (
          <TabsStyled>
            {tabs.map(
              item =>
                !item.isHide && (
                  <NavLink
                    key={item.id}
                    exact
                    to={{
                      pathname: item.path,
                      state: item.state,
                      search: item.search
                    }}
                    isActive={(match, location) => {
                      if (item.isQuery) {
                        return item.search && location.search.includes(item.search);
                      }

                      if (item.isNested) {
                        return item.isNested && location.pathname.includes(item.path);
                      }

                      return Boolean(match);
                    }}
                  >
                    {item.name}
                  </NavLink>
                )
            )}
          </TabsStyled>
        ) : null}
      </TopPanel>
      <ContentAreaStyled
        backgroundColor={backgroundColor}
        padding={padding}
        isTabs={!!tabs.length}
        isTools={!!tools}
      >
        {children}
      </ContentAreaStyled>
    </Fragment>
  );

  const content = isModalContent ? (
    <Fragment>
      {pageLogo && <PageLogoStyled>{pageLogo}</PageLogoStyled>}
      <ModalContentStyled isPageLogo={pageLogo}>
        {children}
        {isLoader && (
          <ModalOverlayStyled>
            <Spinner width="40" height="40" />
          </ModalOverlayStyled>
        )}
      </ModalContentStyled>
    </Fragment>
  ) : (
    children
  );

  return isPage ? page(content) : content;
};

PageWrapper.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  tools: PropTypes.node,
  tabs: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  children: PropTypes.node,
  pageLogo: PropTypes.node,
  isModalContent: PropTypes.bool,
  isBackButton: PropTypes.bool,
  backButtonPath: PropTypes.string,
  backButtonState: PropTypes.objectOf(PropTypes.any),
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  isPage: PropTypes.bool,
  titleLabel: PropTypes.string,
  backgroundColor: PropTypes.string,
  padding: PropTypes.number
};

PageWrapper.defaultProps = {
  tabs: [],
  title: '',
  tools: '',
  children: '',
  pageLogo: '',
  isModalContent: false,
  isBackButton: false,
  backButtonPath: '',
  backButtonState: null,
  isPage: true,
  titleLabel: '',
  backgroundColor: '',
  padding: 20
};

export default withRouter(PageWrapper);
