import {
  Button as SimpleButton,
  ChannelEditor,
  Checkbox,
  EntityEditor,
  FilterEditor,
  InboxEditor
} from '@Thread-Magic/jasmine';
import keyby from 'lodash.keyby';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { ReactComponent as Spinner } from 'src/assets/icons/spinner.svg';
import { ReactComponent as DownArrow } from 'src/assets/icons/down-arrow.svg';
import { ReactComponent as Help } from 'src/assets/icons/help.svg';
import { chatgenieAPI } from 'src/config/api';
import useActionsPlatform from 'src/hooks/useActions';
import {
  getCategories,
  getChannels,
  saveChannel
} from 'src/redux/modules/channels/channelsActions';
import useAuthUser from 'src/hooks/useAuthUser';
import { useQuery } from 'react-query';
import queryKeys from 'src/constants/queryKeys';
import { useRouteMatch } from 'react-router-dom';
import ConfirmModal from '../../components/ConfirmModal';
import PageWrapper from '../../components/PageWrapper';
import RouteLeavingGuard from '../../components/RouteLeavingGuard';
import Switcher from '../../components/Switcher';
import { Tooltip } from '../../components/Tooltip/style';
import { flowConfiguration } from '../../constants/help-hint';
import {
  createFlow,
  getFilterAttributes,
  getFlow,
  getFlowTemplates,
  searchAttributeValues,
  updateFlow
} from '../../redux/modules/flows/flowsActions';
import { setIsFlows } from '../../redux/modules/users/usersActions';
import { getUrlParam } from '../../utils';
import { flowListTransformData, flowTransformData } from '../../utils/data-transformer';
import ActionFlowContainer from './ActionFlowContainer';
import { useConfigureFlow } from './hook';
import {
  AreaStyled,
  OverlayLoaderStyled,
  SeparatorStyled,
  TitleInputStyled,
  TitleStyled,
  TitleWrapperStyled,
  ToolsStyled,
  WrapperStyled
} from './style';

const ConfigureFlow = ({
  filterAttributes,
  getFilterAttributes,
  getFlow,
  getFlowTemplates,
  getCategories,
  getChannels,
  saveChannel,
  createFlow,
  updateFlow,
  activeFlow,
  nextFlow,
  template,
  flowErrors,
  chatConfiguration,
  configurePageLoading,
  changeFlowLoading,
  history,
  location,
  isTemplate,
  setIsFlows,
  searchAttributeValues
}) => {
  const { data: operatorTypes, isLoading: isLoadingOperators } = useQuery(
    [queryKeys.GET_FLOW_FILTER_OPERATORS],
    chatgenieAPI.getFlowFilterOperators
  );
  const isTemplateScreen = useRouteMatch('/dashboard/templates/create-flow');

  const isPageDataLoading = configurePageLoading || isLoadingOperators;

  const {
    flow,
    changeActiveFlow,
    saveFlow,
    isSavedFlow,
    entityError,
    changeEntity,
    filterDataError,
    changeNotification,
    changeFilters,
    channelError,
    changeInboxChannel,
    changeChannel,
    changeCategory,
    changeActionsList,
    changeDynamicFlow,
    changeCreateChannel,
    changeIsReplyBroadcast,
    entityModal,
    setEntityModal,
    onClickFlowTitle,
    onChangeFlowTitle,
    isEditableTitle,
    titleWidth,
    inputTitle,
    title,
    setIsEditableTitle,
    changeFlowByEntity,
    flowChannelError,
    setFlowChannelError,
    changeTimezone
  } = useConfigureFlow({
    nextFlow,
    template,
    flowErrors,
    activeFlow,
    getFlow,
    getFlowTemplates,
    getFilterAttributes,
    filterAttributes,
    createFlow,
    updateFlow,
    history,
    location,
    setIsFlows,
    chatConfiguration,
    operatorTypes
  });
  const {
    selectedActions,
    actionsList,
    setSelectedActions,
    setValueToAction,
    enqueueActionsList,
    unqueueActionsList
  } = useActionsPlatform({
    changeActionsList,
    actions: isTemplate ? template?.actions : activeFlow?.actions,
    flowId: isTemplate ? template?.id : activeFlow?.id
  });

  const { featureFlags } = useAuthUser();
  const isNewBlankFlowOperatorsEnabled = featureFlags.includes('new_blank_flow_operators');
  const operatorFilter = operators => {
    if (isNewBlankFlowOperatorsEnabled) {
      return operators;
    }
    const blankOperators = ['is blank', 'is not blank'];
    return operators?.filter(item => blankOperators.indexOf(item.name) === -1);
  };

  const getOptions = params => chatgenieAPI.getOptions(params);

  return (
    <PageWrapper
      titleComponent={
        <TitleWrapperStyled>
          <TitleInputStyled
            ref={inputTitle}
            type="text"
            value={flow.name}
            isShow={isEditableTitle}
            titleWidth={titleWidth}
            onChange={onChangeFlowTitle}
            onKeyDown={event => event.key === 'Enter' && setIsEditableTitle(false)}
            onBlur={() => setIsEditableTitle(false)}
          />
          <TitleStyled ref={title} isShow={!isEditableTitle} onClick={onClickFlowTitle}>
            {!isPageDataLoading && flow.name}
          </TitleStyled>
        </TitleWrapperStyled>
      }
      title={flow.name}
      isBackButton
      tools={
        <ToolsStyled>
          <Switcher
            id="is-active-flow"
            checked={flow.isActive}
            onChange={changeActiveFlow}
            controlText={{ checked: 'on', unchecked: 'off' }}
          />
          <SimpleButton
            onClick={saveFlow}
            isLoader={changeFlowLoading}
            isDisabled={isSavedFlow || isPageDataLoading}
          >
            {isTemplate ? 'Save a Copy' : 'Save'}
          </SimpleButton>
        </ToolsStyled>
      }
    >
      <AreaStyled>
        {!isPageDataLoading && (
          <WrapperStyled>
            <EntityEditor
              error={entityError}
              flow={flow}
              onChangeEntity={changeEntity}
              onChangeNotification={changeNotification}
            />
            {flow.entityType && (
              <Fragment>
                <SeparatorStyled withTopOffset>
                  <DownArrow />
                </SeparatorStyled>
                <FilterEditor
                  attributes={filterAttributes}
                  error={filterDataError}
                  info={isTemplate && !filterDataError && flow.filter_hint}
                  flow={flow}
                  operatorFilter={operatorFilter}
                  operatorTypes={operatorTypes}
                  timezoneSelectProps={
                    !isTemplateScreen && {
                      options: chatgenieAPI.getFlowTimezones,
                      value: flow.timezone,
                      onChange: changeTimezone,
                      getOptionValue: option => option.id,
                      placeholder: 'Search...'
                    }
                  }
                  onChangeFilters={changeFilters}
                  searchAttributeValues={searchAttributeValues}
                />
              </Fragment>
            )}
            <Fragment>
              <SeparatorStyled withTopOffset>
                <DownArrow />
              </SeparatorStyled>
              <InboxEditor
                error={channelError}
                getChannels={query => getChannels(query, 'twilio', 'channel')}
                channelData={flow.inboxChannel}
                onChangeChannel={changeInboxChannel}
              >
                <Checkbox
                  className="flow-checkbox-dinamic"
                  id="remove-tickets-from-channel"
                  checked={flow.type === 'dynamic'}
                  onChange={changeDynamicFlow}
                >
                  Dynamic Flows
                  <Help
                    data-tip
                    data-for="remove-tickets-tip"
                    fill="#BDBDBD"
                    width="16"
                    height="16"
                  />
                  <Tooltip id="remove-tickets-tip" effect="solid">
                    <div>{flowConfiguration.REMOVE_TICKETS_FROM_CHANNEL}</div>
                  </Tooltip>
                </Checkbox>
              </InboxEditor>
              {['SLACK', 'MS_TEAMS'].includes(chatConfiguration) && (
                <ChannelEditor
                  error={channelError}
                  info={isTemplate && !channelError && flow.channel_hint}
                  channelData={flow.channel}
                  categoryData={flow.category}
                  flowChannelError={flowChannelError}
                  onChangeChannel={changeChannel}
                  onChangeCategory={changeCategory}
                  setFlowChannelError={setFlowChannelError}
                  chatConfiguration={chatConfiguration}
                  saveChannel={saveChannel}
                  getCategories={getCategories}
                  getChannels={getChannels}
                >
                  <Checkbox
                    className="channel-checkbox-dinamic"
                    id="create-channel-for-ticket"
                    checked={flow.isCreateChannel}
                    onChange={changeCreateChannel}
                  >
                    {'Create a new channel for each '}
                    {flow.entityType}
                    <Help
                      data-tip
                      data-for="create-channel-tip"
                      fill="#BDBDBD"
                      width="16"
                      height="16"
                    />
                    <Tooltip id="create-channel-tip" effect="solid">
                      <div>{flowConfiguration.CREATE_NEW_CHANNEL}</div>
                    </Tooltip>
                  </Checkbox>
                  {chatConfiguration === 'SLACK' && (
                    <Checkbox
                      className="channel-checkbox-broadcast"
                      id="reply-broadcast"
                      checked={flow.isReplyBroadcast}
                      onChange={changeIsReplyBroadcast}
                    >
                      {'Send note as a message in channel '}
                      {flow.entityType}
                      <Help
                        data-tip
                        data-for="reply-broadcast-tip"
                        fill="#BDBDBD"
                        width="16"
                        height="16"
                      />
                      <Tooltip id="reply-broadcast-tip" effect="solid">
                        <div>{flowConfiguration.REPLY_BROADCAST}</div>
                      </Tooltip>
                    </Checkbox>
                  )}
                </ChannelEditor>
              )}
            </Fragment>
            <ActionFlowContainer
              actions={selectedActions}
              actionsList={actionsList}
              setActions={setSelectedActions}
              setValueToAction={setValueToAction}
              unqueueActionsList={unqueueActionsList}
              enqueueActionsList={enqueueActionsList}
              getOptions={getOptions}
            />
          </WrapperStyled>
        )}
        {isPageDataLoading && (
          <OverlayLoaderStyled>
            <Spinner width={50} height={50} />
          </OverlayLoaderStyled>
        )}
      </AreaStyled>
      <ConfirmModal
        isOpen={entityModal.isVisible}
        toggleHandler={() => setEntityModal({ isVisible: !entityModal.isVisible })}
        title="You have not saved your flow"
        description={
          <span>
            If you leave the page, you will lose your changes.
            <br />
            Are you sure you want to leave?
          </span>
        }
        modalType="error"
        submitText="Yes"
        onSubmit={changeFlowByEntity}
      />
      <RouteLeavingGuard
        when={!isSavedFlow && !isTemplate}
        navigate={path => history.push(path)}
        shouldBlockNavigation={location => !isSavedFlow && location.pathname === '/dashboard/flows'}
      />
    </PageWrapper>
  );
};

ConfigureFlow.propTypes = {
  getFilterAttributes: PropTypes.func.isRequired,
  getFlow: PropTypes.func.isRequired,
  createFlow: PropTypes.func.isRequired,
  updateFlow: PropTypes.func.isRequired,
  getFlowTemplates: PropTypes.func.isRequired,
  history: PropTypes.objectOf(PropTypes.any),
  location: PropTypes.objectOf(PropTypes.any),
  filterAttributes: PropTypes.arrayOf(PropTypes.any),
  template: PropTypes.objectOf(PropTypes.any),
  setIsFlows: PropTypes.func.isRequired,
  activeFlow: PropTypes.objectOf(PropTypes.any),
  configurePageLoading: PropTypes.bool.isRequired,
  saveChannel: PropTypes.func.isRequired,
  changeFlowLoading: PropTypes.bool.isRequired,
  nextFlow: PropTypes.objectOf(PropTypes.any),
  flowErrors: PropTypes.objectOf(PropTypes.any).isRequired,
  isTemplate: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  chatConfiguration: PropTypes.string,
  searchAttributeValues: PropTypes.func.isRequired,
  getCategories: PropTypes.func.isRequired,
  getChannels: PropTypes.func.isRequired
};

ConfigureFlow.defaultProps = {
  activeFlow: {},
  template: null,
  nextFlow: null,
  isTemplate: false,
  history: {},
  location: {},
  filterAttributes: [],
  chatConfiguration: ''
};

const mapStateToProps = state => ({
  activeFlow: flowTransformData(state.flowsReducer.activeFlow),
  chatConfiguration: state.usersReducer.user.chatConfiguration,
  filterAttributes: state.flowsReducer.filterAttributes,
  flowErrors: state.flowsReducer.flowErrors,
  configurePageLoading: state.flowsReducer.configurePageLoading,
  changeFlowLoading: state.flowsReducer.changeFlowLoading,
  isTemplate: getUrlParam('template'),
  nextFlow: flowListTransformData(state.flowsReducer.flows.data).filter(
    flow => flow.id === Number(getUrlParam('id'))
  )[0],
  template: keyby(flowListTransformData(state.flowsReducer.flowTemplates), 'id')[
    getUrlParam('template')
  ]
});

const mapDispatchToProps = dispatch => ({
  getFlow: id => dispatch(getFlow(id)),
  getFlowTemplates: () => dispatch(getFlowTemplates()),
  getFilterAttributes: entity => dispatch(getFilterAttributes(entity)),
  createFlow: flow => dispatch(createFlow(flow)),
  saveChannel: data => dispatch(saveChannel(data)),
  updateFlow: flow => dispatch(updateFlow(flow)),
  getCategories: like => dispatch(getCategories(like)),
  getChannels: (name, platform, type, categoryId) =>
    dispatch(getChannels(name, platform, type, categoryId)),
  setIsFlows: () => dispatch(setIsFlows()),
  searchAttributeValues: (id, q) => dispatch(searchAttributeValues(id, q))
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfigureFlow);
