import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';

import FormInputWithLabel from 'src/components/FormInputWithLabel';
import { Button } from '@Thread-Magic/jasmine';
import { ReactComponent as DangerIcon } from 'src/assets/icons/danger.svg';
import ImageUpload from '../ImageUpload';
import * as Styled from './SignupUserProfileForm.styles';

const SignupUserProfileForm = ({ initialData = {}, onSubmit }) => {
  const {
    handleSubmit,
    control,
    reset,
    formState: { isSubmitting, errors }
  } = useForm({
    mode: 'onBlur',
    defaultValues: useMemo(() => ({ ...initialData }), [initialData])
  });

  useEffect(() => {
    reset(initialData);
  }, [initialData]);

  return (
    <Styled.Wrapper>
      <Styled.Form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="full_name"
          rules={{ required: 'Please enter your full name' }}
          control={control}
          render={({ field, fieldState }) => (
            <FormInputWithLabel
              id={field.name}
              type="text"
              label="What's your name?"
              placeholder="Full name"
              errorMessage={fieldState.error && fieldState.error.message}
              onBlur={field.onBlur}
              onChange={field.onChange}
              value={field.value}
              state={fieldState}
            />
          )}
        />
        <Controller
          name="workspace_name"
          rules={{ required: 'Please enter your company or organization name' }}
          control={control}
          render={({ field, fieldState }) => (
            <FormInputWithLabel
              id={field.name}
              type="text"
              label="Workspace name"
              placeholder="The name of your company or organization."
              errorMessage={fieldState.error && fieldState.error.message}
              state={fieldState}
              onBlur={field.onBlur}
              onChange={field.onChange}
              value={field.value}
            />
          )}
        />
        <Styled.FormGroup>
          <Styled.FieldLabel hasError={!!errors.logo}>Upload your logo</Styled.FieldLabel>
          <Controller
            name="logo"
            control={control}
            rules={{
              required: 'Please upload your company logo'
            }}
            render={({ field, fieldState }) => (
              <>
                <Styled.IconWrapper>
                  <ImageUpload
                    onChange={logo => field.onChange({ target: { name: 'logo', value: logo } })}
                    name="logo"
                    file={field.value?.url}
                  />
                </Styled.IconWrapper>
                {fieldState.invalid && fieldState.error.message && (
                  <Styled.ErrorMessage>
                    <DangerIcon />
                    {fieldState.error.message}
                  </Styled.ErrorMessage>
                )}
              </>
            )}
          />
        </Styled.FormGroup>
        <Styled.FormGroup>
          <Button
            type="submit"
            size="large"
            full
            isFullWidth
            disabled={isSubmitting}
            isLoader={isSubmitting}
          >
            Continue
          </Button>
        </Styled.FormGroup>
      </Styled.Form>
    </Styled.Wrapper>
  );
};

SignupUserProfileForm.defaultProps = {
  initialData: {}
};

SignupUserProfileForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialData: PropTypes.object
};

export default SignupUserProfileForm;
