import React from 'react';
import PropTypes from 'prop-types';

import OnboardingScreen from 'src/containers/OnboardingScreen';
import MessageForm from 'src/components/MessageForm';
import MessengerWidget from 'src/components/MessengerSettings/MessengerWidget';
import useAuthUser from 'src/hooks/useAuthUser';
import content from './constants';
import { useSignUpSyncPage } from './hook';
import * as Styled from './styles';

const SignupSync = ({ history }) => {
  const { flowsInitError, flowsReadyError } = useSignUpSyncPage({
    history
  });
  const { appId } = useAuthUser();

  return (
    <OnboardingScreen title={content.TITLE} step={4} aside={<MessengerWidget appId={appId} />}>
      {flowsInitError || flowsReadyError ? (
        <MessageForm>{flowsInitError || flowsReadyError}</MessageForm>
      ) : (
        <>
          <Styled.Spinner width="40" height="40" />
          <Styled.Description>{content.DESCRIPTION}</Styled.Description>
        </>
      )}
    </OnboardingScreen>
  );
};

SignupSync.propTypes = {
  history: PropTypes.objectOf(PropTypes.any)
};

SignupSync.defaultProps = {
  history: {}
};

export default SignupSync;
