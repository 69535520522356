import styled, { createGlobalStyle, css } from 'styled-components';
import { SelectAsync, Checkbox } from '@Thread-Magic/jasmine';
import TimePicker from '../../components/TimePicker';

export const TimePickerPopup = createGlobalStyle`
  .time-picker-popup {
    margin-top: 3px;
    .rc-time-picker-panel-input-wrap {
      height: 38px;
    }

    .rc-time-picker-panel-input {
      padding: 7px 19px;
      font-size: 14px;
      background: none;
    }

    .rc-time-picker-panel-input-wrap {
      padding: 0;
    }

    .rc-time-picker-panel-inner {
      top: 3px;
      left: 0px;
      border-radius: 2px;
    }
  }
`;

export const Form = styled.form`
  ${({ disabled }) =>
    disabled &&
    `
      &::after {
        content: '';
        position: absolute;
        top: 44px;
        bottom: 80px;
        left: 0;
        width: 100%;
        background-color: #ffffff94;
      }
    `}
`;

export const FormGroup = styled.fieldset`
  margin-bottom: 24px;
`;

export const Error = styled.p`
  margin: -12px 0 16px;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: ${({ theme }) => theme.colors.red1};
`;

export const CustomLink = styled.button`
  padding: 0;
  display: block;
  margin: -12px 0 16px;
  border: none;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: ${({ theme }) => theme.colors.brand.light.base};
  text-decoration: none;
  background: none;
`;

export const GroupTitle = styled.legend`
  margin: 8px 0 16px;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
`;

export const CustomCheckbox = styled(Checkbox)`
  max-width: ${({ maxWidth }) => maxWidth || 'auto'};
  width: 100%;
  font-weight: 600;
  text-transform: capitalize;
`;

export const CheckboxGroup = styled.div`
  margin: 24px 0 0 26px;
  ${({ isOverlay }) =>
    isOverlay &&
    css`
      opacity: 0.6;
      pointer-events: none;
    `};

  transition: opacity 0.3s;
`;

export const CustomSelect = styled(SelectAsync)`
  max-width: 300px;
`;

export const FormRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  margin-bottom: 16px;
`;

export const CustomTimePicker = styled(TimePicker)`
  max-width: 170px;
  line-height: 17px;

  input {
    height: 38px;
    padding: 10px 20px;
    border: 1px solid #dddddd;
    border-radius: 2px;
    font-size: 14px;
  }
`;

export const Separator = styled.span`
  margin: 0 10px;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
`;

export const Messange = styled.textarea`
  max-width: 540px;
  width: 100%;
  margin: 10px 0 0 26px;
  padding: 5px 14px;
  border: 1px solid #dddddd;
  border-radius: 2px;
  font-size: 14px;
  line-height: 24px;
  resize: none;
  transition: border-color 0.3s;

  &:hover,
  &:focus {
    outline: none;
    border: 1px solid ${({ theme }) => theme.colors.accent.main};
  }

  &[disabled] {
    cursor: not-allowed;

    &:hover,
    &:focus {
      outline: none;
      border: 1px solid #dddddd;
    }
  }
`;

export const IconTimePicker = styled.div`
  position: absolute;
  top: 0;
  right: 6px;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 8px;
  pointer-events: none;

  svg {
    fill: #999999;
    height: 7px;
  }
`;
