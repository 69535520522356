import React, { useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import useOutsideClick from 'src/hooks/useOutsideClick';
import { Wrapper, Trigger, Content, Title } from './Popover.style';

const clickPreventBubble = e => {
  e.stopPropagation();
};

const Popover = ({ title, children, variant = 'default', closeOnSelect = false }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const wrapperRef = useRef(null);

  useOutsideClick(wrapperRef, () => setIsExpanded(false));

  const handleToggle = useCallback(e => {
    e.preventDefault();
    setIsExpanded(prev => !prev);
  }, []);

  const handleContentClick = useCallback(
    e => {
      if (closeOnSelect) {
        setIsExpanded(false);
      }
      clickPreventBubble(e);
    },
    [closeOnSelect]
  );

  return (
    <Wrapper ref={wrapperRef} className={`popover-variant-${variant}`}>
      <Trigger onClick={handleToggle}>
        <Title>{title}</Title>
        {isExpanded ? <FiChevronUp /> : <FiChevronDown />}
      </Trigger>
      {isExpanded && <Content onClick={handleContentClick}>{children}</Content>}
    </Wrapper>
  );
};

Popover.defaultProps = {
  variant: 'default',
  closeOnSelect: false
};

Popover.propTypes = {
  title: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  variant: PropTypes.oneOf(['default', 'plain']),
  closeOnSelect: PropTypes.bool
};

export default Popover;
