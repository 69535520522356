import styled from 'styled-components';

export const WrapperStyled = styled.nav`
  background: ${({ theme }) => theme.colors.brand.main.base};

  a {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 8px 20px;
    font-size: 14px;
    line-height: 24px;
    text-decoration: none;
    color: #ffffff;
    margin-bottom: 10px;
    cursor: pointer;

    span {
      white-space: nowrap;
    }

    svg {
      width: 30px;
      height: 30px;
      fill: #ffffff;
      margin-right: 10px;
      transition: fill 0.2s;
    }

    &:hover,
    &:focus,
    &.active {
      text-decoration: none;
      background-color: ${({ theme }) => theme.colors.brand.main.hover};
      color: #f5f5f5;

      & svg {
        fill: #fafafa;
      }
    }
  }
`;

export const LinkDetail = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: #fff;

  .new {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #f2c94c;
    border: 1px solid #f2c94c;
    border-radius: 2px;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    padding: 0 4px;
  }
`;
