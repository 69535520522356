import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as Styled from './style';

const SimpleTextarea = ({ autoresize, children, ...props }) => {
  const textAreaRef = useRef(null);

  useEffect(() => {
    const ref = textAreaRef.current;
    if (ref && autoresize) {
      ref.style.height = '0px';
      const { scrollHeight } = ref;
      ref.style.height = `${scrollHeight}px`;
    }
  }, [textAreaRef.current, children]);

  return <Styled.Textarea ref={textAreaRef} {...props} />;
};

SimpleTextarea.propTypes = {
  autoresize: PropTypes.bool,
  children: PropTypes.string
};

SimpleTextarea.defaultProps = {
  autoresize: false,
  children: ''
};

export default SimpleTextarea;
