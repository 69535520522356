import styled from 'styled-components';

export const OverlayStyled = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
  background: #00000080;
`;

export const MessageStyled = styled.div`
  position: absolute;
  left: 50%;
  top: 35%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 160px;
  min-height: 130px;
  border-radius: 5px;
  background: #ffffff;
  animation-duration: 0.2s;
  animation-name: slidein;
  animation-fill-mode: forwards;

  svg {
    display: block;
    width: 50px;
    height: 50px;
    margin-bottom: 6px;
  }

  @keyframes slidein {
    from {
      transform: translate(-50%, -65%);
    }

    to {
      transform: translate(-50%, -35%);
    }
  }
`;

export const TextStyled = styled.p`
  margin: 0;
`;
