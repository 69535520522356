import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import OnboardingScreen from 'src/containers/OnboardingScreen';
import { ReactComponent as Builders } from 'src/assets/images/builders.svg';
import MessageForm from 'src/components/MessageForm';
import { TICKET_SERVICE_TYPES, psaTypes } from 'src/constants';
import { useHistory } from 'react-router-dom';
import { useMutation } from 'react-query';
import { chatgenieAPI } from 'src/config/api';
import { useDispatch } from 'react-redux';
import { setPartialUserData } from 'src/redux/modules/users/usersActions';
import { setLocalStorage } from 'src/utils';
import useAuthUser from 'src/hooks/useAuthUser';
import Kaseya from './Kaseya';
import Connectwise from './Connectwise';
import * as Styled from './styles';
import config from './constants';
import Autotask from './Autotask';
import Halo from './Halo';

const FormRenderers = {
  CONNECTWISE: Connectwise,
  KASEYA: Kaseya,
  AUTOTASK: Autotask,
  HALO: Halo
};

const SignupTicketing = ({ activeTicketing, setActiveTicketing }) => {
  const history = useHistory();
  const { featureFlags } = useAuthUser();
  const isHaloPSAEnabled = featureFlags?.includes('halo_psa_integration');
  const filteredIntegrations = config.filter(integration => {
    if (!isHaloPSAEnabled) {
      return integration.key !== TICKET_SERVICE_TYPES.HALO;
    }
    return true;
  });
  const FormComponent = FormRenderers[activeTicketing] || null;
  const dispatch = useDispatch();
  const isInternalPSA = localStorage.getItem('isInternalPSA');
  const { mutateAsync, isLoading, error, reset } = useMutation(({ type, data }) => {
    if (type === TICKET_SERVICE_TYPES.AUTOTASK) {
      return chatgenieAPI.createAutotaskIntegration(data);
    }
    if (type === TICKET_SERVICE_TYPES.HALO) {
      return chatgenieAPI.createHaloIntegration(data);
    }
    return chatgenieAPI.connectPlatform({ ...data, type });
  });

  const onSubmit = (_, formattedData) => {
    mutateAsync({
      data: formattedData,
      type: TICKET_SERVICE_TYPES[activeTicketing]
    }).then(() => {
      setLocalStorage({
        onboardingStep: 'design'
      });
      dispatch(
        setPartialUserData({
          cwConfiguration: true,
          psaType: psaTypes[activeTicketing]
        })
      );
      history.push('/signup/messenger/design');
    });
  };
  const handleSkip = () => {
    mutateAsync({
      type: TICKET_SERVICE_TYPES.CG
    }).then(() => {
      setLocalStorage({
        onboardingStep: 'sync'
      });
      dispatch(
        setPartialUserData({
          cwConfiguration: true,
          psaType: 'internal_psa'
        })
      );
      history.push('/signup/synchronization');
    });
  };

  useEffect(() => {
    reset();
  }, [activeTicketing]);

  return (
    <OnboardingScreen
      title="Let’s setup your ticketing system."
      step={2}
      aside={
        <Styled.Figure>
          <Builders />
        </Styled.Figure>
      }
    >
      {error && <MessageForm>{error?.response?.data?.message}</MessageForm>}
      {activeTicketing ? (
        <FormComponent
          isLoading={isLoading}
          onSubmit={onSubmit}
          requestErrors={error?.response?.data?.errors}
          hasHorizontalMargin
        />
      ) : (
        <Fragment>
          {filteredIntegrations.map(item => (
            <Styled.Button
              key={item.key}
              type="button"
              onClick={() => setActiveTicketing(item.key)}
            >
              <img src={item.icon.src} alt={item.key} width={item.icon.width} />
            </Styled.Button>
          ))}
          {isInternalPSA && (
            <Styled.MainButton type="button" isLoader={isLoading} onClick={handleSkip}>
              Do this later
            </Styled.MainButton>
          )}
        </Fragment>
      )}
      {activeTicketing && (
        <Styled.BackButton type="button" onClick={() => setActiveTicketing(null)}>
          Previous
        </Styled.BackButton>
      )}
    </OnboardingScreen>
  );
};

SignupTicketing.defaultProps = {
  activeTicketing: null
};

SignupTicketing.propTypes = {
  activeTicketing: PropTypes.oneOf(Object.keys(TICKET_SERVICE_TYPES)),
  setActiveTicketing: PropTypes.func.isRequired
};

export default SignupTicketing;
