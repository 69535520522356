import styled from 'styled-components';

export const ToolsStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 220px;
`;

export const ContentStyled = styled.div`
  width: 100%;
`;

export const FormContainer = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const IntegrationLogo = styled.img`
  margin: 48px auto 16px;
`;

export const FormContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 490px;
  min-height: 230px;
  margin: 16px auto;
  border: solid 1px #e0e0e0;
  border-radius: 5px;
  padding: 40px;
  background-color: #ffffff;
  z-index: 1;
  & > * {
    width: 100%;
  }
  & > h5 {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
  }
  & > p {
    font-size: 14px;
    color: ${({ theme }) => theme.colors.gray5};
  }
`;

export const IntegrationSyncBanner = styled.div`
  display: flex;
  font-size: 13px;
  font-style: normal;
  border-radius: 4px;
  background: #dbeefc;
  width: 100%;
  padding: 12px;
  margin-bottom: 32px;
  svg {
    margin-right: 10px;
    color: #4babee;
    min-width: 24px;
    font-size: 24px;
  }
`;

export const SyncTypeList = styled.ul`
  list-style-type: none;
  margin: 16px 0 0 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    width: 100%;
    & > div {
      display: flex;
      flex-direction: column;
      p {
        font-size: 14px;
        margin: 0;
      }
      span {
        font-size: 12px;
        color: ${({ theme }) => theme.colors.gray5};
      }
    }
    & > button {
      width: 96px;
    }
  }
`;

export const SyncPreferences = styled.ul`
  border-top: solid 1px ${({ theme }) => theme.colors.gray1};
  list-style-type: none;
  margin: 16px 0 0 0;
  padding: 32px 0 0;
  h5 {
    font-size: 14px;
    font-weight: 600;
  }
  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    & > p {
      font-size: 14px;
      margin: 0 10px 0 0;
      display: flex;
      align-items: center;
      svg {
        margin-left: 5px;
        font-size: 16px;
        color: ${({ theme }) => theme.colors.gray5};
      }
    }
  }
`;

export const SettingTitle = styled.h5`
  font-size: 20px !important;
  font-weight: 600;
  text-align: center;
  margin-bottom: ${({ marginBottom }) => marginBottom ?? 32}px;
`;

export const EmbeddedPodSettingsWrapper = styled.div`
  font-size: 14px;
  h6 {
    font-weight: 600;
  }
  ol > li {
    margin-bottom: 10px;
  }
`;
