import React from 'react';
import { Link } from 'react-router-dom';

import { WrapperStyled } from './style';

import OnboardingModal from '../../containers/OnboardingModal';

const NoMatch = () => (
  <OnboardingModal>
    <WrapperStyled>
      <h2>404 - Page not found</h2>
      <Link to="/">Go to Homepage</Link>
    </WrapperStyled>
  </OnboardingModal>
);

NoMatch.propTypes = {};

NoMatch.defaultProps = {};

export default NoMatch;
