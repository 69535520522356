import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as InboxSvg } from 'src/assets/icons/inbox.svg';
import { BUSINESS_MESSENGER_URL } from 'src/constants';
import { LinkDetail, WrapperStyled } from './style';

const links = [
  {
    link: BUSINESS_MESSENGER_URL,
    title: 'Inbox',
    icon: <InboxSvg />,
    isNew: false
  }
];

const IntegratedNavigation = ({ appId }) => (
  <WrapperStyled data-qa="navigation">
    {links.map((link, l) => (
      <a href={`${link.link}?app_id=${appId}`} key={l} target="_blank" rel="noreferrer">
        {link.icon}
        <LinkDetail>
          {link.title}
          {link.isNew && <span className="new">NEW</span>}
        </LinkDetail>
      </a>
    ))}
  </WrapperStyled>
);

IntegratedNavigation.propTypes = {
  appId: PropTypes.string.isRequired
};

export default IntegratedNavigation;
