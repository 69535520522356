import styled, { css } from 'styled-components/macro';

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 588px;
  min-height: 300px;
  margin-bottom: 16px;
  background: ${({ theme }) => theme.colors.background.default};
  margin: 10vh auto 20px;
  padding: 30px 66px;

  & > svg {
    width: 40px;
    height: 40px;
    margin: auto;
  }
`;

export const Title = styled.h3`
  margin-bottom: 30px;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: ${({ theme }) => theme.colors.text.default};
`;

export const Content = styled.div`
  margin-bottom: 32px;
`;

export const PlatformButton = styled.button`
  position: relative;
  width: 100%;
  height: 44px;
  margin-bottom: 16px;
  border: 1px solid ${({ theme }) => theme.colors.gray5};
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.background.default};
  transition: border-color 0.2s;

  img {
    width: 70px;
    height: auto;
  }

  &:hover,
  &:focus {
    border-color: ${({ theme }) => theme.colors.brand.light.base};
  }

  ${({ isActive }) =>
    isActive &&
    css`
      border-color: ${({ theme }) => theme.colors.brand.light.base};

      &::after {
        content: '';
        position: absolute;
        top: 12px;
        right: 15px;
        width: 20px;
        height: 20px;
        background-color: ${({ theme }) => theme.colors.brand.light.base};
        -webkit-mask-image: url(/static/media/check-soft.1fe770bd.svg);
        mask-image: url(/static/media/check-soft.1fe770bd.svg);
        background-size: 100%;
      }
    `}
`;

export const MacLogo = styled.img`
  width: 25px !important;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
`;

export const AppName = styled.div`
  margin-top: 4px;
`;
