import styled from 'styled-components/macro';

export const Description = styled.p`
  max-width: 400px;
  margin: 0 auto;
  font-size: 14px;
  text-align: center;
  color: ${({ theme }) => theme.colors.gray5};
`;

export const Wrapper = styled.div`
  margin-bottom: 16px;
  margin-top: 22px;
`;
export const SubTitle = styled.h2`
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 22px;
  font-weight: bold;
  color: #000000;
`;
export const Actions = styled.div`
  display: flex;
  flex-direction: column;
  button {
    margin-top: 13px;
  }
`;
