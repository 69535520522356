import React from 'react';

import { WrapperStyled } from './style';

const BreadCrumbs = ({ children }) => <WrapperStyled>{children}</WrapperStyled>;

BreadCrumbs.propTypes = {
  // state: PropTypes.objectOf(PropTypes.any)
};

BreadCrumbs.defaultProps = {
  // state: {}
};

export default BreadCrumbs;
