import React from 'react';
import { Toaster } from 'react-hot-toast';
import { QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { queryClient } from 'src/config/queryClient';
import { isDevelopment } from 'src/utils';
import initilizeSentry from 'src/utils/sentry';
import { hot } from 'react-hot-loader';
import Root from './containers/Root';
import store from './redux/store';

if (!isDevelopment()) {
  initilizeSentry();
}
const toastOptions = {
  duration: 2000,
  success: {
    duration: 2000
  }
};

const App = () => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Toaster position="top-center" toastOptions={toastOptions} />
        <QueryClientProvider client={queryClient}>
          <Root />
        </QueryClientProvider>
      </BrowserRouter>
    </Provider>
  );
};

export default process.env.NODE_ENV === 'development' ? hot(module)(App) : App;
