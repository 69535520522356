export default {
  GET_FLOW_ITEMS_INIT: 'GET_FLOW_ITEMS_INIT',
  GET_FLOW_ITEMS_READY: 'GET_FLOW_ITEMS_READY',
  GET_MESSENGER_HOURS: 'GET_MESSENGER_HOURS',
  GET_ACTIONS: 'GET_ACTIONS',
  GET_TEAMS_APPS: 'GET_TEAMS_APPS',
  GET_TEAMS_APP: 'GET_TEAMS_APP',
  GET_MESSENGER_PROMPTS: 'GET_MESSENGER_PROMPTS',
  GET_COPILOT_PRIORITIES: 'GET_COPILOT_PRIORITIES',
  GET_COPILOT_PRIORITY_RULES: 'GET_COPILOT_PRIORITY_RULES',
  GET_WORKSPACE_SETTINGS: 'GET_WORKSPACE_SETTINGS',
  GET_MESSENGER_DESIGN: 'GET_MESSENGER_DESIGN',
  GET_MESSENGER_SETTINGS: 'GET_MESSENGER_SETTINGS',
  GET_ACTION_PLATFORMS: 'GET_ACTION_PLATFORMS',
  GET_AUDIT_LOGS: 'GET_AUDIT_LOGS',
  GET_PRIORITIES: 'GET_PRIORITIES',
  GET_OUTCOMES: 'GET_OUTCOMES',
  GET_BOARDS: 'GET_BOARDS',
  GET_CATEGORIZATION_TYPES: 'GET_CATEGORIZATION_TYPES',
  GET_INTEGRATION_SYNC_TYPES: 'GET_INTEGRATION_SYNC_TYPES',
  GET_FLOW_FILTER_OPERATORS: 'GET_FLOW_FILTER_OPERATORS',
  GET_AGENTS: 'GET_AGENTS',
  GET_AGENT_SETTINGS: 'GET_AGENT_SETTINGS',
  GET_COPILOT_RECAP_TEMPLATES: 'GET_COPILOT_RECAP_TEMPLATES',
  GET_COPILOT_RECAP_RULES: 'GET_COPILOT_RECAP_RULES',
  GET_COPILOT_RECAP_THREADS: 'GET_COPILOT_RECAP_THREADS',
  GET_MAGIC_AI_TRIAL_PERIOD: 'GET_MAGIC_AI_TRIAL_PERIOD',
  GET_COPILOT_GENERATE_TITLE_SETTINGS: 'GET_COPILOT_GENERATE_TITLE_SETTINGS'
};
