import { readableColor } from 'polished';
import styled, { css } from 'styled-components/macro';

export const Wrapper = styled.div`
  position: relative;

  label {
    user-select: none;
    display: block;
    font-size: 12px;
    margin-bottom: 6px;
  }

  ${({ isActive }) =>
    isActive &&
    css`
      &::after {
        content: '';
        position: absolute;
        width: calc(100% + 10px);
        height: calc(100% + 10px);
        top: -5px;
        left: -5px;
        border: 1px solid ${({ theme }) => theme.colors.brand.light.base};
        border-radius: 100%;
        user-select: none;
      }
    `}
`;

export const Options = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  opacity: 0;
  z-index: 100;
  transform: translateY(-10px) scaleY(0.8);
  background-color: #ffffff;
  border: 1px solid #eaebec;
  border-top: none;
  border-radius: 0 0 3px 3px;
  margin-top: 1px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0);
  transition: 0.2s;

  .expanded & {
    opacity: 1;
    transform: translateY(0) scaleY(1);
    box-shadow: 0 3px 2px rgba(0, 0, 0, 0.12);
  }
`;

export const SelectInner = styled.div`
  user-select: none;
  width: ${({ circleSize }) => circleSize}px;
  height: ${({ circleSize }) => circleSize}px;
  border-radius: 100%;
  padding: 0;
  position: relative;
  outline: none;
  transition: 0.2s;
`;

export const SelectSingleValue = styled.div`
  position: relative;
  width: ${({ circleSize }) => circleSize}px;
  height: ${({ circleSize }) => circleSize}px;
  border-radius: 100%;
  color: ${props => readableColor(props.color)};
  background-color: ${props => props.color || '#ffffff'};
  padding: 5px 10px;
  cursor: pointer;
  z-index: 10;

  .expanded &:after {
    transform: translateY(-50%) rotate(-90deg);
  }
`;

export const Overlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 99;
`;

export const ClearButton = styled.button`
  border: none;
  outline: none;
  cursor: pointer;
  background: none;
  font-size: 1.2em;
  color: ${props => readableColor(props.color)};
`;

export const Input = styled.input`
  overflow: hidden;
  position: absolute;
  padding: 0;
  border: 0;
  clip: rect(0 0 0 0);
  top: 0;
  height: 100%;
  width: 100%;
`;
