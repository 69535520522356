import axios from 'axios';
import { URL_API } from '../../../constants';
import { contactsTypes } from '../../../constants/action-types';
import { getHeaders } from '../../../utils';

export const getContacts =
  (props = {}) =>
  (dispatch, getState) => {
    dispatch({
      type: contactsTypes.GET_CONTACTS,
      payload: axios({
        method: 'GET',
        url: `${URL_API}/api/v1/contacts${props.queryString || ''}`,
        headers: getHeaders(getState)
      })
    });
  };

export const getContact = id => (dispatch, getState) => {
  dispatch({
    type: contactsTypes.GET_CONTACT,
    payload: axios({
      method: 'GET',
      url: `${URL_API}/api/v1/contacts/${id}`,
      headers: getHeaders(getState)
    })
  });
};

export const getContactsByCompany =
  ({ companyId, params = {} }) =>
  (dispatch, getState) =>
    dispatch({
      type: contactsTypes.GET_CONTACTS_BY_COMPANY,
      payload: axios({
        method: 'GET',
        url: `${URL_API}/api/v1/contacts${params.queryString || ''}${
          params.queryString ? '&' : '?'
        }filter-company_id=${companyId}`,
        headers: getHeaders(getState)
      })
    });

export const inviteContact =
  ({ contactId, data }) =>
  (dispatch, getState) =>
    dispatch({
      type: contactsTypes.INVITE_CONTACT,
      payload: axios({
        method: 'POST',
        url: `${URL_API}/api/v1/contacts/${contactId}/invite-link`,
        data,
        headers: getHeaders(getState)
      })
    });
