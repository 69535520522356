import { ActionEditorPlatform, Button as SimpleButton } from '@Thread-Magic/jasmine';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { Fragment, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { ReactComponent as DownArrow } from 'src/assets/icons/down-arrow.svg';
import { ReactComponent as Move } from 'src/assets/icons/fi_move.svg';
import { ReactComponent as Plus } from 'src/assets/icons/plus.svg';
import { ReactComponent as Trash } from 'src/assets/icons/trash.svg';
import {
  ActionContainerStyled,
  ActionDeleteStyled,
  DraggableBox,
  DragIcon,
  SeparatorStyled
} from '../style';

const queryAttr = 'data-rbd-drag-handle-draggable-id';

const getDraggedDom = draggableId => {
  const domQuery = `[${queryAttr}='${draggableId}']`;
  return document.querySelector(domQuery);
};
const bound = {
  zIndex: 0,
  width: '100%',
  opacity: 0.5,
  position: 'absolute'
};

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const checkDuplicateId = (list, value) => {
  const els = list.filter(item => item?.id === value);
  if (els.length > 1) return false;
  return value;
};

const ActionFlowContainer = ({
  actions,
  setActions,
  actionsList,
  setValueToAction,
  unqueueActionsList,
  enqueueActionsList,
  getOptions
}) => {
  const [placeholderProps, setPlaceholderProps] = useState({});
  const [placeholderDom, setPlaceholderDom] = useState(null);

  const handleDragStart = event => {
    const draggedDOM = getDraggedDom(event.draggableId);

    if (!draggedDOM) {
      return;
    }
    const { clientHeight } = draggedDOM;
    const sourceIndex = event.source.index;
    const parentPos = document.getElementById('flow-actions').getBoundingClientRect();
    const childPos = document.getElementById(`draggable${sourceIndex}`).getBoundingClientRect();

    setPlaceholderDom(actions[sourceIndex]);
    setPlaceholderProps({
      height: clientHeight,
      top: !isNaN(childPos.top - parentPos.top) ? childPos.top - parentPos.top : 0,
      left: childPos.lefts - parentPos.lefts,
      ...bound
    });
  };

  const handleOnDragEnd = result => {
    setPlaceholderProps({});
    const { destination, source } = result;
    if (!destination) return;

    const items = reorder(actions, source.index, destination.index);

    setActions(items);
  };

  const handleDragUpdate = event => {
    if (!event.destination) {
      return;
    }

    const draggedDOM = getDraggedDom(event.draggableId);

    if (!draggedDOM) {
      return;
    }

    setPlaceholderProps(prev => ({
      prev,
      display: 'none',
      ...bound
    }));
  };

  return (
    <>
      <DragDropContext
        onDragStart={handleDragStart}
        onDragUpdate={handleDragUpdate}
        onDragEnd={handleOnDragEnd}
      >
        <Droppable droppableId="flow-actions">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              className="flow-actions"
              id="flow-actions"
              ref={provided.innerRef}
            >
              {actions.map((action, a) => (
                <Draggable
                  key={checkDuplicateId(actions, action?.tabId) || a}
                  draggableId={`draggable${checkDuplicateId(actions, action?.tabId) || a}`}
                  index={a}
                >
                  {dragProvided => (
                    <DraggableBox
                      ref={dragProvided.innerRef}
                      id={`draggable${a}`}
                      className="draggable-item"
                      {...dragProvided.draggableProps}
                    >
                      <SeparatorStyled withTopOffset absolute>
                        <DownArrow />
                      </SeparatorStyled>
                      <DragIcon {...dragProvided.dragHandleProps} className="draggable-item_move">
                        <Move />
                      </DragIcon>
                      <ActionContainerStyled>
                        <ActionEditorPlatform
                          actionsList={actionsList}
                          setCurrentAction={newAction => setValueToAction(a, newAction)}
                          currentAction={action}
                          getOptions={getOptions}
                        />
                        <ActionDeleteStyled
                          onClick={() => unqueueActionsList(a)}
                          title={`trash-${a}`}
                        >
                          <Trash />
                        </ActionDeleteStyled>
                      </ActionContainerStyled>
                    </DraggableBox>
                  )}
                </Draggable>
              ))}

              {provided.placeholder}
              {!isEmpty(placeholderProps) && snapshot.isDraggingOver && (
                <DraggableBox
                  style={{
                    ...placeholderProps
                  }}
                >
                  <ActionContainerStyled>
                    <ActionEditorPlatform
                      actionsList={actionsList}
                      setCurrentAction={newAction => setValueToAction(0, newAction)}
                      currentAction={placeholderDom}
                    />
                    <ActionDeleteStyled onClick={() => unqueueActionsList(0)}>
                      <Trash />
                    </ActionDeleteStyled>
                  </ActionContainerStyled>
                </DraggableBox>
              )}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <Fragment>
        <SeparatorStyled withTopOffset={false} animated>
          <DownArrow />
          <SimpleButton onClick={enqueueActionsList}>
            <Plus fill="#061274" stroke="#00BB99" />
            <span className="hidden-text">Action</span>
          </SimpleButton>
        </SeparatorStyled>
      </Fragment>
    </>
  );
};

ActionFlowContainer.propTypes = {
  actions: PropTypes.array.isRequired,
  actionsList: PropTypes.array,
  setActions: PropTypes.func.isRequired,
  setValueToAction: PropTypes.func.isRequired,
  enqueueActionsList: PropTypes.func.isRequired,
  unqueueActionsList: PropTypes.func.isRequired,
  getOptions: PropTypes.func.isRequired
};

ActionFlowContainer.defaultProps = {
  actionsList: []
};

export default ActionFlowContainer;
