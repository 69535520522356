import React from 'react';
import PropTypes from 'prop-types';

import OnboardingModal from 'src/containers/OnboardingModal';
import { Button } from '@Thread-Magic/jasmine';
import { ADMIN_ROLE } from 'src/constants/user-roles';

import content from './constants';
import * as Styled from './SelectWorkspace.styles';

const SelectWorkspace = ({
  workspaces,
  dispatch,
  saveAppId,
  getInfo,
  isFetchWorkspaces,
  errorMessage,
  onAddWorkspace
}) => {
  return (
    <OnboardingModal title={content.TITLE} isLoading={isFetchWorkspaces} error={errorMessage}>
      <Styled.List>
        {workspaces.map(item => (
          <Styled.Item key={item.id}>
            <Styled.WorkspaceName title={item.name}>{item.name}</Styled.WorkspaceName>
            <Styled.CTAs>
              {item.memberRole === ADMIN_ROLE && (
                <Button
                  styleMode="outline"
                  onClick={() => {
                    dispatch(saveAppId({ appId: item.appId }));
                    dispatch(getInfo());
                  }}
                >
                  {content.BUTTON_ADMIN_TEXT}
                </Button>
              )}
              <Button
                styleMode="outline"
                onClick={() => {
                  window.location = `${process.env.REACT_APP_INBOX_URL}/app-launch?app_id=${item.appId}`;
                }}
              >
                {content.BUTTON_INBOX_TEXT}
              </Button>
            </Styled.CTAs>
          </Styled.Item>
        ))}
      </Styled.List>

      <Button size="large" onClick={onAddWorkspace}>
        {content.BUTTON_ADD_TEXT}
      </Button>
    </OnboardingModal>
  );
};

SelectWorkspace.defaultProps = {
  workspaces: [],
  errorMessage: ''
};

SelectWorkspace.propTypes = {
  workspaces: PropTypes.array,
  dispatch: PropTypes.func.isRequired,
  saveAppId: PropTypes.func.isRequired,
  getInfo: PropTypes.func.isRequired,
  isFetchWorkspaces: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string,
  onAddWorkspace: PropTypes.func.isRequired
};

export default SelectWorkspace;
