import LinkButton from 'src/components/LinkButton';
import SimpleButton from 'src/components/SimpleButton';
import styled, { css } from 'styled-components/macro';

export const ButtonStyle = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 44px;
  margin-bottom: 17px;
  padding: 12px;
  border-radius: 4px;
  border: solid 1px ${({ theme }) => theme.colors.gray1};
  white-space: nowrap;
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
  letter-spacing: -0.15px;
  color: #000000;
  transition: border-color 0.2s;
  background-color: #ffffff;

  &:last-child {
    margin-bottom: 0;
  }

  &:focus,
  &:hover {
    outline: none;
    text-decoration: none;
    border-color: ${({ theme }) => theme.colors.brand.light.hover};
    color: #000000;
  }

  img {
    height: auto;
  }
`;

export const Button = styled.button`
  ${ButtonStyle}
`;

export const BackButton = styled(LinkButton)`
  display: flex;
  margin: 15px auto;
`;

export const MainButton = styled(SimpleButton)`
  width: 100%;
  margin-top: 42px;
`;

export const Figure = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);

  svg {
    width: 106%;
    min-width: 200px;
  }
`;

export const FormContainer = styled.div`
  margin: 0 ${({ hasHorizontalMargin }) => (hasHorizontalMargin ? 50 : 0)}px;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: column;
  button {
    margin-top: 13px;
    svg {
      margin-left: 8px;
      font-size: 18px;
    }
  }
`;

export const Logo = styled.img`
  display: block;
  margin: 0 auto 15px;
`;

export const CentralizedContainer = styled.div`
  text-align: center;
`;
