import styled, { css } from 'styled-components/macro';
import { Button, Select } from '@Thread-Magic/jasmine';

export const Aside = styled.aside`
  max-width: 480px;
  min-width: 400px;
  height: 100%;
  width: 100%;
  padding: 38px 32px 16px;
  background: ${({ theme }) => theme.colors.background.secondary};
  margin-bottom: 80px;
`;

export const AsideTitle = styled.h4`
  margin-bottom: 15px;
  font-weight: 600;
  font-size: 16px;
  line-height: normal;
`;

export const AsideDescription = styled.p`
  margin-bottom: 17px;
  font-size: 14px;
  line-height: 17px;
  color: ${({ theme }) => theme.colors.text.variant3};
`;

export const MessengerBody = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 16px 16px 0;
  overflow: auto;
  height: 100%;

  .message-copilot ~ .message-copilot {
    margin-bottom: 12px;
  }

  .message-copilot + .message-copilot {
    margin-bottom: 18px;
  }

  .spinner {
    display: flex;
    width: 16px;
    height: 16px;
    margin: 0 auto 18px;
  }
`;

export const Messenger = styled.div`
  display: flex;
  flex-direction: column;
  height: 580px;
  border-radius: 4px;
  background: #ffffff;
  overflow: hidden;
  position: relative;
`;

export const Card = styled.div`
  margin-top: 10px;
  border: 1px solid ${({ theme }) => theme.colors.border.default};
  border-radius: 2px;

  h5 {
    margin: 0;
    font-size: 14px;
    padding: 11px 16px;
    font-weight: 600;
    background: ${({ theme }) => theme.colors.background.secondary};
  }

  div {
    font-size: 14px;
    line-height: 17px;
    padding: 14px 16px 24px;
    p {
      margin-bottom: 12px;
    }
    p:last-child {
      margin: 0;
    }
  }
`;

export const Message = styled.div`
  display: flex;
  margin-bottom: 18px;
`;

export const Avatar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 37px;
  height: 37px;
  margin-right: 9px;
  overflow: hidden;
  border-radius: 100%;
  color: #7980aa;

  ${({ type }) =>
    type === 'copilot'
      ? css`
          background-color: #ccf1eb;
        `
      : css`
          background-color: #d6dbfc;
        `};
`;

export const MessageContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 6px;
  font-size: 14px;
  line-height: 22px;
`;

export const MessageName = styled.span`
  margin-bottom: 5px;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
`;

export const TypingIndicator = styled.div`
  display: flex;
  align-items: center;
  margin-top: auto;
  margin-bottom: 4px;
`;

export const CopilotAvatar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 22px;
  height: 22px;
  margin-right: 4px;
  border-radius: 100%;
  background-color: #ccf1eb;
`;

export const TypingText = styled.span`
  padding: 2px;
  font-size: 14px;
  font-style: italic;
  color: ${({ theme }) => theme.colors.gray5};
`;

export const CustomSelect = styled(Select)`
  width: 100%;
  text-align: left;
`;

export const ActionButton = styled(Button)`
  margin: 12px 0;
`;
