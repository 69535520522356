import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as ChatIcon } from 'src/assets/icons/chat.svg';
import { ReactComponent as RequestIcon } from 'src/assets/icons/request.svg';
import { ReactComponent as CheckIcon } from 'src/assets/icons/check-soft.svg';

import * as Styled from './styles';

const ChatCircle = () => (
  <Styled.Circle>
    <ChatIcon />
  </Styled.Circle>
);

const RequestCircle = () => (
  <Styled.Circle>
    <RequestIcon />
  </Styled.Circle>
);

const controls = [
  {
    id: 'all',
    title: 'Live Chat + Non-Live Chat',
    description:
      'Arm your customers with both Live and Non-Live options so that they can self direct themselves.',
    Icon: () => (
      <Styled.Figure>
        <ChatCircle />
        <RequestCircle />
      </Styled.Figure>
    )
  },
  {
    id: 'live',
    title: 'Live Chat Only',
    description: "Let customers know that you'll respond to new threads shortly.",
    Icon: () => (
      <Styled.Figure>
        <ChatCircle />
      </Styled.Figure>
    )
  },
  {
    id: 'request',
    title: 'Non-Live Chat Only',
    description: 'Support a conversational chat experience without setting any time expectations.',
    Icon: () => (
      <Styled.Figure>
        <RequestCircle />
      </Styled.Figure>
    )
  }
];

const SupportTypeSection = ({ value, onChange }) => (
  <Styled.Wrapper>
    {controls.map(({ id, title, description, Icon }) => (
      <Styled.Label key={id}>
        <Styled.Input
          className="visually-hidden"
          type="radio"
          name="supportType"
          id={id}
          checked={value === id}
          onChange={onChange}
        />
        <Icon />
        <Styled.Title>{title}</Styled.Title>
        <Styled.Description>{description}</Styled.Description>
        <Styled.Check>
          <CheckIcon />
        </Styled.Check>
      </Styled.Label>
    ))}
  </Styled.Wrapper>
);

SupportTypeSection.propTypes = {
  value: PropTypes.string
};

SupportTypeSection.defaultProps = {
  value: 'live'
};

export default SupportTypeSection;
