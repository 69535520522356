import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as BackIcon } from 'src/assets/icons/return.svg';

import { ButtonStyled } from './style';

const BackButton = ({ onClick }) => (
  <ButtonStyled data-qa="back-button">
    <BackIcon onClick={onClick} />
  </ButtonStyled>
);

BackButton.propTypes = {
  onClick: PropTypes.func.isRequired
};

export default BackButton;
