import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as CrossIcon } from 'src/assets/icons/cross.svg';
import * as Styled from './styles';

const MessengerPreview = ({
  image,
  icon,
  primaryColor,
  appId,
  botName,
  botImage,
  className,
  supportType,
  isCloseButton
}) => {
  const iframeEl = useRef(null);
  const [isLoaded, setIsLoaded] = useState(false);

  function updateMessenger(payload) {
    iframeEl.current.contentWindow.postMessage(
      {
        type: 'UPDATE_THEME_PREVIEW',
        payload
      },
      process.env.REACT_APP_MESSENGER_URL
    );
  }

  function handleLoad() {
    setIsLoaded(true);
    updateMessenger({
      image,
      primaryColor,
      botName,
      botImage,
      supportType
    });
  }

  useEffect(() => {
    if (!isLoaded) {
      return;
    }

    updateMessenger({
      image,
      primaryColor,
      botName,
      botImage,
      supportType
    });
  }, [image, icon, primaryColor, botName, botImage, supportType]);

  return (
    <Styled.Messenger className={className}>
      <iframe
        ref={iframeEl}
        title="messenger"
        onLoad={handleLoad}
        src={`${process.env.REACT_APP_MESSENGER_URL}?appId=${appId}`}
      />
      {isCloseButton && (
        <Styled.Close color={primaryColor}>
          <CrossIcon />
        </Styled.Close>
      )}
    </Styled.Messenger>
  );
};

MessengerPreview.propTypes = {
  image: PropTypes.string,
  icon: PropTypes.string,
  primaryColor: PropTypes.string,
  appId: PropTypes.string,
  botName: PropTypes.string,
  botImage: PropTypes.string,
  isCloseButton: PropTypes.bool,
  className: PropTypes.string,
  supportType: PropTypes.string
};

MessengerPreview.defaultProps = {
  image: '',
  icon: '',
  primaryColor: '',
  appId: '',
  botName: '',
  botImage: '',
  isCloseButton: true,
  className: '',
  supportType: null
};

export default MessengerPreview;
