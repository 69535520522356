/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
import React from 'react';
import { components } from 'react-select';
import { ReactComponent as SlackIcon } from 'src/assets/icons/slack.svg';
import { ReactComponent as TeamsIcon } from 'src/assets/icons/teams.svg';
import SimpleSelect from 'src/components/SimpleSelect';

const options = {
  ENABLED: {
    value: 'ENABLED',
    label: 'Enabled'
  },
  DISABLED: {
    value: 'DISABLED',
    label: 'Disabled'
  }
};

const serviceLogo = {
  MS_TEAMS: <TeamsIcon width={30} height={30} />,
  SLACK: <SlackIcon width={30} height={30} />
};

const statusColors = {
  DISABLED: '#EB5757',
  ENABLED: '#27AE60'
};

const styles = {
  customStyles: {
    controlStyles: {
      display: 'flex',
      alignItems: 'center',
      height: 34
    },
    optionStyles: {
      height: 40,
      fontSize: 14,
      backgroundColor: '#fff'
    }
  },
  getConrolCircleStyle: color => ({
    marginLeft: 12,
    height: 12,
    width: 12,
    borderRadius: 1000,
    backgroundColor: color
  }),
  getOptionCircleStyle: color => ({
    marginRight: 10,
    height: 12,
    width: 12,
    borderRadius: 1000,
    backgroundColor: color
  }),
  wrapper: { maxWidth: '144px' },
  option: { height: '100%', display: 'flex', alignItems: 'center' },
  box: { display: 'flex', paddingLeft: 27, alignItems: 'center' }
};

const Select = ({ row, action, userRoles }) => (
  <div onClick={e => e.stopPropagation()} style={styles.wrapper}>
    {userRoles.admin ? (
      <SimpleSelect
        components={{
          Control: ({ children, data, ...rest }) => {
            const { value } = rest.selectProps.value;
            return (
              <components.Control {...rest}>
                <div style={styles.getConrolCircleStyle(statusColors[value])} />
                {children}
              </components.Control>
            );
          },
          Option: ({ children, data, ...props }) => (
            <components.Option {...props}>
              <div style={styles.option}>
                <div style={styles.getOptionCircleStyle(statusColors[data.value])} />
                {children}
              </div>
            </components.Option>
          )
        }}
        name="user-notifications"
        value={options[row.messenger_status]}
        onChange={option => action({ companyId: row.id, status: option.value })}
        options={Object.values(options).filter(el => el.value !== row.messenger_status)}
        size="small"
        customStyles={styles.customStyles}
      />
    ) : (
      <div style={styles.option}>
        <div style={styles.getOptionCircleStyle(statusColors[row.messenger_status])} />
        {options[row.messenger_status].label}
      </div>
    )}
  </div>
);

export const companiesColumnsData = (setMessengerStatus, userRoles) => [
  { key: 'name', title: 'Name' },
  {
    key: 'status_id',
    filterKey: 'status_id=i',
    title: 'Status',
    component: row => row.status?.name,
    filterType: 'checkbox',
    isSort: false
  },
  {
    key: 'types',
    title: 'Types',
    component: row => row.types?.map(item => item.name).join(', '),
    filterType: 'checkbox',
    isSort: false
  },
  {
    key: 'messenger_status',
    title: 'Messenger',
    component: row => <Select row={row} action={setMessengerStatus} userRoles={userRoles} />,
    filterType: 'checkbox',
    isSort: true
  },
  {
    key: 'chat_platform',
    title: 'Service Bot',
    component: ({ chat_platform }) => (
      <div style={styles.box}>{serviceLogo[chat_platform?.platform]}</div>
    ),
    isSort: true
  },
  { key: 'last_seen', title: 'Last Seen', component: row => row.last_seen || 'Never' }
];
