import React from 'react';
import { WrapContent } from './styles';

export const TemplateTableColumns = () => [
  { key: 'name', title: 'Name', component: row => <b>{row.name}</b>, isSort: false },
  { key: 'type', title: 'Type', isSort: false },
  { key: 'channel_name', title: 'Channel name', isSort: false },
  {
    key: 'is_create_channel',
    title: 'New Channel?',
    component: row => (row.is_create_channel ? 'Yes' : 'No'),
    isSort: false
  },
  {
    key: 'description',
    title: 'Description',
    component: row => <WrapContent>{row.description}</WrapContent>,
    isSort: false
  }
];
