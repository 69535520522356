import {
  actionPlatformConfigurationsTypes as types,
  actionStatuses
} from '../../../../constants/action-types';

const defaultState = {
  data: {},
  loading: false,
  errorMessage: '',
  infoLoader: ''
};

const map = {
  [types.CLEAR_STATE]: state => ({
    ...state,
    data: {},
    infoLoader: '',
    errorMessage: ''
  }),
  [`${types.GET_SINGLE}${actionStatuses.PENDING}`]: state => ({
    ...state,
    data: {},
    errorMessage: '',
    loading: true,
    infoLoader: 'fetch_pending'
  }),
  [`${types.GET_SINGLE}${actionStatuses.FULFILLED}`]: (state, action) => ({
    ...state,
    data: action.payload.data.data,
    loading: false,
    infoLoader: 'fetch_fulfilled'
  }),
  [`${types.GET_SINGLE}${actionStatuses.REJECTED}`]: (state, action) => {
    const responseStatus = action.payload.response.status;
    if (responseStatus === 404) {
      return {
        ...defaultState
      };
    }
    return {
      ...state,
      errorMessage: action.payload.data.message,
      loading: false,
      infoLoader: 'fetch_rejected'
    };
  },
  [`${types.CREATE}${actionStatuses.PENDING}`]: state => ({
    ...state,
    errorMessage: '',
    loading: true,
    infoLoader: 'create_pending'
  }),
  [`${types.CREATE}${actionStatuses.FULFILLED}`]: (state, action) => ({
    ...state,
    data: action.payload.data.data,
    loading: false,
    infoLoader: 'create_fulfilled'
  }),
  [`${types.CREATE}${actionStatuses.REJECTED}`]: (state, action) => ({
    ...state,
    errorMessage: action.payload.response.data.message,
    loading: false,
    infoLoader: 'create_rejected'
  }),
  [`${types.UPDATE}${actionStatuses.PENDING}`]: state => ({
    ...state,
    errorMessage: '',
    loading: true,
    infoLoader: 'update_pending'
  }),
  [`${types.UPDATE}${actionStatuses.FULFILLED}`]: (state, action) => ({
    ...state,
    data: action.payload.data.data,
    loading: false,
    infoLoader: 'update_fulfilled'
  }),
  [`${types.UPDATE}${actionStatuses.REJECTED}`]: (state, action) => ({
    ...state,
    errorMessage: action.payload.response.data.message,
    loading: false,
    infoLoader: 'update_rejected'
  })
};

export default (state = defaultState, action) => {
  return (map[action.type] && map[action.type](state, action)) || state;
};
