import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
`;

export const Body = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  flex-direction: column;
  width: 800px;
  height: fit-content;
  padding: 30px 100px 60px 100px;
  box-shadow: 0 14px 50px rgb(0 0 0 / 10%);
  border-radius: 4px;
  margin-top: 50px;

  .dc-btn-continue {
    margin-top: 30px;
  }
`;

export const Logo = styled.div`
  margin-bottom: 15px;
`;

export const TextInfo = styled.div`
  font-size: 16px;
  text-align: center;

  h2 {
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 10px;
  }
`;

export const SelectField = styled.div`
  margin-bottom: 0;
  width: 350px;

  p {
    font-size: 13px;
    font-weight: 600;
    text-align: center;
  }
`;

export const OR = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
  font-weight: 600;
  font-size: 13px;

  &:before,
  &:after {
    content: '';
    width: 86px;
    height: 1px;
    background-color: #ddd;
    margin: 0 22px;
  }
`;
