import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Popup from 'src/components/Popup';
import useOutsideClick from 'src/hooks/useOutsideClick';
import { RowActionsWrapper, Dots, Dot } from './styles';

const ActionsMenu = ({ row, actions }) => {
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const ref = useRef();

  useOutsideClick(ref, () => {
    setIsPopupVisible(!isPopupVisible);
  });

  const handleTogglePopupVisibility = event => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    setIsPopupVisible(!isPopupVisible);
  };

  return (
    <RowActionsWrapper>
      <Dots onClick={handleTogglePopupVisibility}>
        <Dot key={1} />
        <Dot key={2} />
        <Dot key={3} />
      </Dots>
      {isPopupVisible && (
        <div ref={ref}>
          <Popup actions={actions(row)} onClose={handleTogglePopupVisibility} />
        </div>
      )}
    </RowActionsWrapper>
  );
};

ActionsMenu.propTypes = {
  row: PropTypes.objectOf(PropTypes.any).isRequired,
  actions: PropTypes.func.isRequired
};

export default ActionsMenu;
