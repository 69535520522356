import React from 'react';
import { ReactComponent as SlackIcon } from 'src/assets/icons/slack.svg';
import { ReactComponent as TeamsIcon } from 'src/assets/icons/teams.svg';
import { ReactComponent as MessengerIcon } from 'src/assets/icons/messenger.svg';
import { ReactComponent as MagicIcon } from 'src/assets/icons/magic-icon.svg';
import { ReactComponent as WindowsIcon } from 'src/assets/icons/windows.svg';
import { ReactComponent as MacIcon } from 'src/assets/icons/apple.svg';
import { Wrapper } from './AppIcon.style';

const AppIcon = ({ app }) => {
  const appIcon = {
    SLACK: <SlackIcon width={17} height={17} />,
    MS_TEAMS: <TeamsIcon width={17} height={17} />,
    MESSENGER: <MessengerIcon width={17} height={17} />,
    WINDOWS: <WindowsIcon width={17} height={17} />,
    MAGIC: <MagicIcon width={17} height={17} />,
    MAC: <MacIcon width={17} height={17} />
  };

  const appName = app.replace('MS_TEAMS', 'Teams').replace('Skipped', 'N/A');

  return (
    <Wrapper>
      {appIcon[app]} {appName.charAt(0).toUpperCase() + appName.slice(1).toLowerCase()}
    </Wrapper>
  );
};

export default AppIcon;
