import React from 'react';
import PropTypes from 'prop-types';

import { ModalStyled, ModalHeaderStyled, ModalBodyStyled } from './style';

const InfoModal = ({ isOpen, toggleHandler, title, children }) => (
  <ModalStyled isOpen={isOpen} toggle={toggleHandler}>
    <ModalHeaderStyled toggle={toggleHandler}>{title}</ModalHeaderStyled>
    <ModalBodyStyled>{children}</ModalBodyStyled>
  </ModalStyled>
);

InfoModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleHandler: PropTypes.func.isRequired,
  title: PropTypes.string,
  children: PropTypes.node
};

InfoModal.defaultProps = {
  title: '',
  children: ''
};

export default InfoModal;
