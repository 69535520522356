import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import FormInputWithLabel from 'src/components/FormInputWithLabel';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { chatgenieAPI } from 'src/config/api';
import useAuthUser from 'src/hooks/useAuthUser';
import useToast from 'src/hooks/useToast';
import { Spinner } from 'reactstrap';
import { queryClient } from 'src/config/queryClient';
import queryKeys from 'src/constants/queryKeys';
import { useMutation } from 'react-query';
import PropTypes from 'prop-types';
import { FormContent, SettingTitle } from '../../style';
import * as Styled from '../../../../pages/SignupTicketing/styles';

export const getOutcomes = query => {
  const cachedOptions = queryClient.getQueryData([queryKeys.GET_OUTCOMES, query]);
  if (cachedOptions) {
    return Promise.resolve(cachedOptions);
  }
  return chatgenieAPI.getOutcomes({ query }).then(outcomes => {
    queryClient.setQueryData([queryKeys.GET_OUTCOMES, query], outcomes);
    return outcomes;
  });
};

const fields = {
  HALO_PSA_EXTERNAL_NOTE_ACTION_SYSTEM_ID: {
    id: 'halo_psa_external_note_action_system_id',
    name: 'halo_psa_external_note_action_system_id',
    fieldType: 'async-select',
    label: 'Action for sending customer-facing notes',
    placeholder: 'Action',
    fieldKey: 'halo_psa_external_note_action_system_id',
    fieldOptions: query => {
      return getOutcomes(query || '');
    },
    getOptionLabel: option => option.name
  },
  HALO_PSA_INTERNAL_NOTE_ACTION_SYSTEM_ID: {
    id: 'halo_psa_internal_note_action_system_id',
    name: 'halo_psa_internal_note_action_system_id',
    fieldType: 'async-select',
    label: 'Action for sending internal "private notes"',
    placeholder: 'Action',
    fieldKey: 'halo_psa_internal_note_action_system_id',
    fieldOptions: query => {
      return getOutcomes(query || '');
    },
    getOptionLabel: option => option.name
  },
  HALO_PSA_EMAIL_ACTION_SYSTEM_ID: {
    id: 'halo_psa_email_action_system_id',
    name: 'halo_psa_email_action_system_id',
    fieldType: 'async-select',
    label: 'Action for emailing contacts',
    placeholder: 'Action',
    fieldKey: 'halo_psa_email_action_system_id',
    fieldOptions: query => {
      return getOutcomes(query || '');
    },
    getOptionLabel: option => option.name
  }
};

const schema = yup
  .object({
    [fields.HALO_PSA_EXTERNAL_NOTE_ACTION_SYSTEM_ID]: yup
      .number()
      .required()
      .label(fields.HALO_PSA_EXTERNAL_NOTE_ACTION_SYSTEM_ID.label),
    [fields.HALO_PSA_INTERNAL_NOTE_ACTION_SYSTEM_ID]: yup
      .number()
      .required()
      .label(fields.HALO_PSA_INTERNAL_NOTE_ACTION_SYSTEM_ID.label),
    [fields.HALO_PSA_EMAIL_ACTION_SYSTEM_ID]: yup
      .number()
      .required()
      .label(fields.HALO_PSA_EMAIL_ACTION_SYSTEM_ID.label)
  })
  .required();

function ControllerWrapper({ item, control, formState }) {
  const { toast } = useToast();
  const { mutate, isLoading: isUpdating } = useMutation(
    chatgenieAPI.updateCompanyMessengerSettings,
    {
      onSuccess: () => {
        toast.success(`Successfully updated an action mapping`);
      },
      onError: () => {
        toast.error(`Failed to update action mapping`);
      }
    }
  );

  const { company } = useAuthUser();

  const handleOnChange = (value, name) => {
    mutate({
      data: {
        [name]: value.id
      },
      companyId: company.id
    });
  };

  return (
    <Controller
      name={item.name}
      control={control}
      render={({ field }) => (
        <FormInputWithLabel
          {...item}
          {...field}
          isLoading={isUpdating}
          errorMessage={formState.errors[item.name]?.message || ''}
          onChange={value => {
            field.onChange(value);
            handleOnChange(value, item.name);
          }}
        />
      )}
    />
  );
}

ControllerWrapper.propTypes = {
  item: PropTypes.any.isRequired,
  control: PropTypes.any.isRequired,
  formState: PropTypes.any.isRequired
};

export default function HaloPsaSettingsForm() {
  const { company } = useAuthUser();
  const { toast } = useToast();

  const { control, formState, reset } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange'
  });

  const [loading, setLoading] = useState(true);

  const fetchDefaults = async () => {
    try {
      const outcomes = await getOutcomes('');
      const settings = await chatgenieAPI.getCompanyMessengerSettings(company.id);

      reset({
        [fields.HALO_PSA_EXTERNAL_NOTE_ACTION_SYSTEM_ID.name]:
          settings.halo_psa_external_note_action_system_id && {
            id: settings.halo_psa_external_note_action_system_id,
            name: outcomes.find(
              outcome => outcome.id === settings.halo_psa_external_note_action_system_id
            ).name
          },
        [fields.HALO_PSA_INTERNAL_NOTE_ACTION_SYSTEM_ID.name]:
          settings.halo_psa_internal_note_action_system_id && {
            id: settings.halo_psa_internal_note_action_system_id,
            name: outcomes.find(
              outcome => outcome.id === settings.halo_psa_internal_note_action_system_id
            ).name
          },
        [fields.HALO_PSA_EMAIL_ACTION_SYSTEM_ID.name]: settings.halo_psa_email_action_system_id && {
          id: settings.halo_psa_email_action_system_id,
          name: outcomes.find(outcome => outcome.id === settings.halo_psa_email_action_system_id)
            .name
        }
      });
    } catch {
      toast.error('Something went wrong');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDefaults();
  }, []);

  return (
    <FormContent>
      <SettingTitle>Action Mapping</SettingTitle>
      <Styled.FormContainer>
        <Styled.Form id="halo-psa-settings">
          {loading ? (
            <Styled.CentralizedContainer>
              <Spinner width={16} />
            </Styled.CentralizedContainer>
          ) : (
            Object.values(fields).map(item => (
              <ControllerWrapper
                key={item.id}
                item={item}
                control={control}
                formState={formState}
              />
            ))
          )}
        </Styled.Form>
      </Styled.FormContainer>
    </FormContent>
  );
}
