import styled, { css } from 'styled-components/macro';
import MessengerPreview from 'src/components/MessengerPreview';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  min-width: 1000px;
`;

export const Page = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;
  background: ${({ theme }) => theme.colors.gray2};
`;

export const TeamsTitle = styled.div`
  width: 100%;
  margin-top: 64px;
  margin-bottom: -128px;
  padding: 12px 16px 16px;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;

  img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    object-fit: contain;
  }
`;

export const Messenger = styled(MessengerPreview)`
  min-width: 405px;
  height: 654px;
`;

export const Body = styled.div`
  width: 100%;
  padding-top: 128px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: ${({ reverseRows }) => (reverseRows ? 'row-reverse' : 'row')};
  max-width: 1200px;
  margin: -50px auto 50px;
  padding: 0 24px;
`;

export const Blocks = styled.ul`
  width: 100%;
  margin: ${({ reverseRows }) => (reverseRows ? '0 0 0 30px' : '0 30px 0 0')};
  padding: 0;
  list-style: none;
  border-radius: 10px;
  background: ${({ theme }) => theme.colors.background.default};
`;

export const Block = styled.li`
  display: flex;
  padding: 24px 28px;
  box-shadow: 0px 1px 0px ${({ theme }) => theme.colors.border.default};
`;

export const BlockIcon = styled.div`
  width: 45px;
  height: 45px;
  margin-right: 32px;
  line-height: 45px;
  font-size: 45px;

  img {
    display: block;
    width: 45px;
    height: 45px;
    object-fit: contain;
  }
`;

export const BlockContent = styled.div`
  margin-right: 72px;
  flex-grow: 1;
`;

export const Title = styled.h3`
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
`;

export const Description = styled.p`
  margin: 0;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.text.variant2};
`;

export const BlockAction = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;

  a {
    display: block;
    width: auto;
    min-width: 110px;
    max-width: 100%;
    padding: 12px;
    border-radius: 4px;
    white-space: nowrap;
    text-decoration: none;
    text-align: center;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    background-color: ${({ primaryColor, theme }) => primaryColor || theme.colors.brand.light.base};
    color: #ffffff;
    transition: background-color 0.2s;
  }

  a:hover {
    background-color: ${({ primaryColor, theme }) =>
      primaryColor || theme.colors.brand.light.base}e6;
  }
`;

export const Cover = styled.div`
  width: 100%;
  height: 240px;

  ${({ url }) =>
    url &&
    css`
      background-image: url(${url});
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    `}
`;
