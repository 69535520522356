import TimePicker from 'rc-time-picker';
import styled from 'styled-components';

export const TimePickerStyled = styled(TimePicker)`
  input {
    width: 100%;
    max-width: 180px;
    border: 1px solid transparent;
    border-radius: 0;
    font-size: 12px;
    line-height: 16px;
    color: #212121;

    ${({ size }) => {
      switch (size) {
        case 'small':
          return `
        padding: 6px 8px;
      `;
        default:
          return `
        padding: 12px 10px;
      `;
      }
    }}

    &:hover {
      border-color: #b3b3b3;
    }

    &:focus {
      outline: none;
      border-color: ${({ theme }) => theme.colors.accent.main};
    }
  }

  .rc-time-picker-clear {
    right: 5px;
  }

  .rc-time-picker-clear-icon:after {
    font-size: 14px;
    font-weight: 600;
  }
`;
