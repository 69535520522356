import React, { useRef, useState } from 'react';
import { FiCheck, FiX } from 'react-icons/fi';
import PropTypes from 'prop-types';
import { useMutation } from 'react-query';
import { chatgenieAPI } from 'src/config/api';
import useToast from 'src/hooks/useToast';
import { Checkbox, Select } from '@Thread-Magic/jasmine';
import { ReactComponent as Spinner } from 'src/assets/icons/spinner.svg';
import * as Styled from '../../style';
import PrioritySelectField, { getCustomStyles } from './PrioritySelectField';
import { booleanOptions } from '../constants';
import { updateCachedRule } from '../utils';
import usePrioritizationConfig from '../hooks/usePrioritizationConfig';

const getOptionLabel = option => {
  const props = {
    color: option.value ? '#00bb99' : '#EB5757',
    fontSize: 16
  };

  return (
    <Styled.PrioritySelectValue>
      {option.value ? <FiCheck {...props} /> : <FiX {...props} />}
      {option.label}
    </Styled.PrioritySelectValue>
  );
};

function PrioritizationItem({ data, isOddRow }) {
  const [rule, setRule] = useState(data);
  const { toast } = useToast();
  const isIgnoreFieldUpdating = useRef(false);
  const { selectedSla } = usePrioritizationConfig();

  const customStyles = {
    control: state => ({
      background: state.isFocused || state.menuIsOpen ? '#EFEEEA' : isOddRow ? '#faf9f6' : '#fff',
      border: 'none',
      '&:hover': {
        background: '#EFEEEA'
      }
    }),
    indicatorSeparator: () => ({
      display: 'default'
    }),
    indicatorsContainer: () => ({
      opacity: '1 !important'
    })
  };

  const { mutateAsync: update, isLoading: isUpdating } = useMutation(
    chatgenieAPI.updateCopilotPriorityRules,
    {
      onSuccess: () => {
        toast.success(`Successfully saved rule!`);
      },
      onError(err) {
        toast.error(`Failed to save rule: ${err?.response?.data?.message || err?.message}`);
      }
    }
  );

  const handleSave = data => {
    const prev = rule;
    setRule(prev => ({ ...prev, ...data }));
    // eslint-disable-next-line no-prototype-builtins
    isIgnoreFieldUpdating.current = data.hasOwnProperty('ignore');
    update(
      {
        conditions: [
          {
            condition_id: rule.id,
            priority_id: data.selected_priority?.id ?? null,
            action: data.action ?? null,
            ignore: data.ignore ?? rule.ignore ?? false,
            service_level_agreement_id: selectedSla?.id
          }
        ]
      },
      {
        onSuccess: () => {
          updateCachedRule({ ...rule, ...data }, { slaId: selectedSla?.id });
        }
      }
    ).catch(() => {
      setRule(prev);
    });
  };

  return (
    <Styled.PrioritizationItem isOddRow={isOddRow} isIgnored={rule.ignore}>
      <span>{rule.text}</span>
      {rule.action === null ? (
        <PrioritySelectField
          value={rule.selected_priority}
          onChange={value =>
            handleSave({
              selected_priority: value
            })
          }
          customStyles={customStyles}
          isLoading={!isIgnoreFieldUpdating.current && isUpdating}
          isDisabled={rule.ignore}
          size="small"
        />
      ) : (
        <Select
          placeholder="Select option..."
          customStyles={getCustomStyles(customStyles)}
          value={booleanOptions.find(el => el.value === rule.action)}
          getOptionLabel={getOptionLabel}
          getOptionValue={option => option?.value}
          options={booleanOptions}
          isLoading={!isIgnoreFieldUpdating.current && isUpdating}
          isDisabled={rule.ignore}
          size="small"
          onChange={option =>
            handleSave({
              action: option.value
            })
          }
        />
      )}
      <span />
      {isIgnoreFieldUpdating.current && isUpdating ? (
        <Spinner width={16} />
      ) : (
        <Checkbox
          checked={rule.ignore}
          onChange={e => {
            const { checked } = e.target;
            handleSave({ ignore: checked, selected_priority: rule.selected_priority });
          }}
          id={rule.id}
        />
      )}
    </Styled.PrioritizationItem>
  );
}

PrioritizationItem.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    text: PropTypes.string,
    selected_priority: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      color: PropTypes.string
    }),
    ignore: PropTypes.bool,
    action: PropTypes.bool
  }),
  isOddRow: PropTypes.bool
};

PrioritizationItem.defaultProps = {
  isOddRow: true,
  data: {}
};

export default PrioritizationItem;
