import React from 'react';
import PropTypes from 'prop-types';
import MessageForm from 'src/components/MessageForm';
import { ReactComponent as MainLogo } from 'src/assets/icons/logo.svg';
import { ReactComponent as HelpIcon } from 'src/assets/icons/help-outline.svg';
import useLogout from 'src/hooks/useLogout';
import * as Styled from './style';

const OnboardingScreen = ({
  title,
  children,
  aside,
  error,
  isLoading,
  step,
  size,
  backLink,
  totalSteps
}) => {
  const { logout } = useLogout();

  const handleLogout = () => {
    logout();
  };

  return (
    <Styled.Wrapper>
      <Styled.Body>
        <Styled.TopRightActions>
          <Styled.TopRightAction type="button" onClick={handleLogout}>
            Log Out
          </Styled.TopRightAction>
        </Styled.TopRightActions>
        <Styled.Help href="https://help.getthread.com" target="_blank">
          <HelpIcon width="14" height="14" fill="#999" />
          Need help?
        </Styled.Help>
        <Styled.Content size={size}>
          <Styled.Logo>
            <MainLogo />
          </Styled.Logo>
          {step && <Styled.Steps step={step} to={totalSteps} />}
          <Styled.Header>{title && <Styled.Title>{title}</Styled.Title>}</Styled.Header>
          {isLoading ? <Styled.Spinner width="40" height="40" /> : children}
          {backLink && (
            <Styled.BackLinkWrapper>
              <Styled.BackLink>Previous</Styled.BackLink>
            </Styled.BackLinkWrapper>
          )}
        </Styled.Content>
        {error && <MessageForm>{error}</MessageForm>}
      </Styled.Body>
      {aside && <Styled.SideBar>{aside}</Styled.SideBar>}
    </Styled.Wrapper>
  );
};

OnboardingScreen.propTypes = {
  title: PropTypes.string,
  backLink: PropTypes.string,
  children: PropTypes.node,
  aside: PropTypes.node,
  error: PropTypes.string,
  step: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  isLoading: PropTypes.bool,
  size: PropTypes.oneOf(['medium', 'large']),
  totalSteps: PropTypes.number
};

OnboardingScreen.defaultProps = {
  backLink: '',
  title: '',
  children: null,
  aside: null,
  error: '',
  step: null,
  isLoading: false,
  size: 'medium',
  totalSteps: 5
};

export default React.memo(OnboardingScreen);
