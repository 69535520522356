import React from 'react';
import PropTypes from 'prop-types';
import ActionModal from 'src/components/ActionModal';
import { Button } from '@Thread-Magic/jasmine';
import { ReactComponent as HelpIcon } from 'src/assets/icons/help-outline.svg';
import * as Styled from './style';

const UpdatedModal = ({ onClose, isOpen }) => (
  <ActionModal isOpen={isOpen} size="medium" toggleHandler={onClose}>
    <Styled.ContentWrapper>
      <Styled.HelpLink
        href="https://docs.getthread.com/article/ry9zssic5c-how-to-install-the-teams-bot-on-your-customers-tenant"
        target="_blank"
      >
        <HelpIcon />
        Need help?
      </Styled.HelpLink>
      <Styled.Figure>
        <Styled.Success />
      </Styled.Figure>
      <Styled.Title>Your app has been updated. Looking good!</Styled.Title>
      <Styled.Subtitle>
        Your changes in Service page will automatically be reflected in the app. In order for Teams
        App changes to go live, you will have to re-upload the app.
      </Styled.Subtitle>
      <Styled.ActionWrapper>
        <Button type="button" onClick={onClose} size="large">
          Ok, got it.
        </Button>
      </Styled.ActionWrapper>
    </Styled.ContentWrapper>
  </ActionModal>
);

UpdatedModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func
};

UpdatedModal.defaultProps = {
  onClose: () => {},
  isOpen: false
};

export default UpdatedModal;
