import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
`;

export const Check = styled.div`
  position: absolute;
  right: 12px;
  top: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 100%;
  background-color: ${({ theme }) => theme.colors.background.secondary};
  border: 1px solid ${({ theme }) => theme.colors.border.variant1};
  transition: all 0.2s;

  svg {
    display: none;
    stroke: #ffffff;
    width: 18px;
    height: 18px;
  }
`;

export const Label = styled.label`
  position: relative;
  max-width: 248px;
  width: 100%;
  margin-right: 20px;
  padding: 15px 22px 22px;
  border: 1px solid ${({ theme }) => theme.colors.border.variant1};
  background: #ffffff;
  cursor: pointer;

  *:last-child {
    margin-right: 0;
  }

  &:hover ${Check}, &:focus ${Check} {
    border-color: ${({ theme }) => theme.colors.brand.light.base};
  }
`;

export const Input = styled.input`
  &:checked ~ ${Check} {
    border-color: ${({ theme }) => theme.colors.brand.light.base};
    background-color: ${({ theme }) => theme.colors.brand.light.base};

    svg {
      display: flex;
    }
  }
`;

export const Circle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  background: #ccf1eb;
  border: 2px solid #ffffff;
  border-radius: 100%;
`;

export const Figure = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 12px;

  ${Circle}:nth-child(2) {
    position: relative;
    left: -10px;
  }

  svg {
    width: 32px;
    height: 32px;
    fill: ${({ theme }) => theme.colors.brand.light.base};
  }
`;

export const Title = styled.h4`
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
`;

export const Description = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
`;
