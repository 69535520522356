import React from 'react';
import PropTypes from 'prop-types';
import Connectwise from 'src/pages/SignupTicketing/Connectwise';
import { FORM_ID } from 'src/pages/SignupTicketing/Connectwise/config';
import { Route, Switch, withRouter } from 'react-router-dom';
import { messageIntegrations } from '../../../../constants/integrations';
import MessageForm from '../../../../components/MessageForm';
import SimpleButton from '../../../../components/SimpleButton';
import PageWrapper from '../../../../components/PageWrapper';
import Switcher from '../../../../components/Switcher';
import { ToolsStyled, FormContainer, FormContent, IntegrationLogo } from '../../style';
import styles from '../../styles.module.scss';
import { useControlForm } from '../../hook';
import IntegrationSyncForm from '../IntegrationSyncForm';
import { overlayChidrens } from '../../overlayTypes';

const CWManageForm = ({
  onSubmit,
  formData,
  isValid,
  status,
  visibleOverlay,
  configuration,
  errorMessage,
  match
}) => {
  const { onFormSubmit, isActive, setIsActive, defaultValues, canSubmit, onFormValid } =
    useControlForm({
      onSubmit,
      formData,
      configuration,
      defaultValueNormalizer(data) {
        return {
          ...data,
          host: data.baseUrl,
          privateKey: ''
        };
      },
      submitValueNormalizer(data) {
        return {
          baseUrl: data.host,
          companyName: data.companyName,
          publicKey: data.publicKey,
          privateKey: data.privateKey
        };
      }
    });

  return (
    <PageWrapper
      title="Manage"
      isBackButton
      backButtonPath="/dashboard/integrations"
      pageLogo={<img src="/images/connectwise-logo.png" width="150" alt="Connectwise logo" />}
      tools={
        <ToolsStyled>
          <Switcher
            id="is-active-flow"
            checked={isActive}
            onChange={() => setIsActive(!isActive)}
            controlText={{ checked: 'on', unchecked: 'off' }}
          />
          <SimpleButton
            isLoader={visibleOverlay}
            isDisabled={!canSubmit}
            type="submit"
            form={FORM_ID}
          >
            Save
          </SimpleButton>
        </ToolsStyled>
      }
      tabs={[
        { id: 1, path: `${match.url}`, name: 'Configuration' },
        { id: 2, path: `${match.url}/sync`, name: 'PSA Sync' }
      ]}
    >
      <FormContainer>
        <IntegrationLogo src="/images/connectwise-logo.png" width="150" alt="Connectwise logo" />
        <Switch>
          <Route path={`${match.path}/sync`} component={IntegrationSyncForm} />
          <Route
            path={`${match.path}`}
            render={() => (
              <FormContent>
                {errorMessage && <MessageForm typeView="block">{errorMessage}</MessageForm>}
                {isValid === false && (
                  <MessageForm typeView="block">{messageIntegrations.NOT_VALID}</MessageForm>
                )}
                {isValid === true && !errorMessage && configuration.is_active && (
                  <MessageForm typeView="block" typeMsg="success">
                    {messageIntegrations.VALID}
                  </MessageForm>
                )}
                <Connectwise
                  onSubmit={onFormSubmit}
                  defaultValues={defaultValues}
                  onFormValid={onFormValid}
                  isLogoVisible={false}
                  withSyncMembersCheckbox={false}
                />
              </FormContent>
            )}
          />
        </Switch>
        {visibleOverlay && overlayChidrens[status] && (
          <div className={styles.overlay}>{overlayChidrens[status].icon}</div>
        )}
      </FormContainer>
    </PageWrapper>
  );
};

CWManageForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  formData: PropTypes.objectOf(PropTypes.any),
  isValid: PropTypes.bool,
  status: PropTypes.string,
  visibleOverlay: PropTypes.bool,
  errorMessage: PropTypes.string,
  configuration: PropTypes.any
};

CWManageForm.defaultProps = {
  formData: {},
  isValid: undefined,
  visibleOverlay: false,
  status: '',
  errorMessage: '',
  configuration: {}
};

export default withRouter(CWManageForm);
