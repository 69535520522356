import axios from 'axios';
import { actionPlatformsTypes as types } from '../../../../constants/action-types';
import { URL_API } from '../../../../constants';
import { getHeaders } from '../../../../utils';

export const fetch = () => (dispatch, getState) =>
  dispatch({
    type: types.GET,
    payload: axios({
      method: 'GET',
      url: `${URL_API}/api/action-platform/platforms`,
      headers: getHeaders(getState)
    })
  });
