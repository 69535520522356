import React from 'react';
import PropTypes from 'prop-types';
import ConfirmModal from 'src/components/ConfirmModal';
import * as Styled from './style';

const WindowsDisconnectModal = ({
  disconnect,
  name,
  isFirstConfirmModalOpen,
  setIsFirstConfirmModalOpen
}) => {
  const toggleFirstConfirmModal = () => {
    setIsFirstConfirmModalOpen(!isFirstConfirmModalOpen);
  };

  const handleUninstallChatConfiguration = () => {
    setIsFirstConfirmModalOpen(false);

    disconnect();
  };

  return (
    <>
      <ConfirmModal
        isOpen={isFirstConfirmModalOpen}
        toggleHandler={toggleFirstConfirmModal}
        title={`Disconnect ${name}`}
        modalType="error"
        onSubmit={handleUninstallChatConfiguration}
      >
        <Styled.ModalWrapper>
          <p>
            Confirming this action will disconnect your custom {name} app from your customer&apos;s
            tenant.
          </p>
        </Styled.ModalWrapper>
      </ConfirmModal>
    </>
  );
};

WindowsDisconnectModal.propTypes = {
  disconnect: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  isFirstConfirmModalOpen: PropTypes.bool.isRequired,
  setIsFirstConfirmModalOpen: PropTypes.func.isRequired
};

export default WindowsDisconnectModal;
