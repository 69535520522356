import styled, { css } from 'styled-components/macro';

export const Messenger = styled.div`
  position: relative;
  margin: auto;
  width: 88%;
  height: 65vh;
  max-width: 370px;
  max-height: 600px;

  iframe {
    position: relative;
    width: 100%;
    height: 100%;
    margin: auto;
    border: none;
    box-shadow: 0px 14px 22px rgb(0 0 0 / 10%);
    border-radius: 10px;
    z-index: 1;
  }
`;

const button = css`
  position: absolute;
  top: calc(100% + 18px);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 70px;
  padding: 0;
  border: none;
  border-radius: 100%;
  background-color: ${({ color }) => color};

  &:focus {
    outline: none;
  }
`;

export const Chat = styled.div`
  ${button}
  right: 93px;

  img {
    max-width: 40px;
  }
`;

export const Close = styled.div`
  ${button}
  right: 0;

  svg {
    width: 22px;
    height: 22px;
    fill: #ffffff;
  }
`;
