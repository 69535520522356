import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { processfile, getFileName } from 'src/utils';
import theme from 'src/config/theme';
import MessageForm from 'src/components/MessageForm';
import { ReactComponent as WelcomeFigure } from 'src/assets/images/welcome-figure.svg';
import OnboardingScreen from 'src/containers/OnboardingScreen';
import SignupUserProfileForm from './components/SignupUserProfileForm';
import * as Styled from './SignupProfile.styles';

const SignupProfile = ({ onSubmit, error, user }) => {
  const [formInitialData, setFormInitialData] = useState({ full_name: user.fullname });

  useEffect(() => {
    const createFiles = async () => {
      const logoUrl = `${window.location.origin}${theme.messenger.default.logo}`;

      setFormInitialData({
        logo: {
          url: logoUrl,
          file: await processfile(logoUrl, getFileName(logoUrl))
        }
      });
    };

    createFiles();
  }, []);

  return (
    <OnboardingScreen
      step={1}
      title="Looks like you’re new! Tell us a bit about yourself"
      aside={
        <Styled.Figure>
          <WelcomeFigure />
        </Styled.Figure>
      }
    >
      {error && <MessageForm>{error}</MessageForm>}
      <SignupUserProfileForm onSubmit={onSubmit} initialData={formInitialData} />
    </OnboardingScreen>
  );
};

SignupProfile.defaultProps = {
  error: '',
  user: {}
};

SignupProfile.propTypes = {
  error: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  user: PropTypes.shape({
    fullname: PropTypes.string
  })
};

export default SignupProfile;
