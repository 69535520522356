import React from 'react';
import { format, zonedTimeToUtc } from 'date-fns-tz';
import { userRoleMap } from 'src/constants/user-roles';

const eventTypeTranslator = {
  ACCESSED_INBOX: 'Login',
  ACCESSED_ADMIN: 'Login',
  ACCESSED_TIMEPAD_POD: 'Login',
  ACCESSED_CHAT_POD: 'Login'
};

export const columns = [
  {
    key: 'time',
    title: 'Time',
    component: row =>
      format(zonedTimeToUtc(row.logged_at.date, row.logged_at.timezone), 'yyyy-MM-dd HH:mm:ss'),
    isSort: false
  },
  {
    key: 'user',
    title: 'User',
    component: row => row.member?.user?.fullname || row?.contact?.user?.fullname || 'Unknown',
    isSort: false
  },
  {
    key: 'email',
    title: 'Email',
    component: row => row.member?.user?.email || row?.contact?.user?.email || 'Unknown',
    isSort: false
  },
  {
    key: 'role',
    title: 'Role',
    component: row => userRoleMap[row.role],
    isSort: false
  },
  {
    key: 'event_type',
    title: 'Event type',
    component: row => eventTypeTranslator[row.event_type] || row.event_type,
    isSort: false
  },
  {
    key: 'description',
    title: 'Description',
    isSort: false
  }
];
