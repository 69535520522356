import { chatgenieAPI } from 'src/config/api';
import * as yup from 'yup';

const fieldNames = {
  support_board: 'support_board',
  default_priority: 'default_priority',
  default_service_source: 'default_service_source',
  email_service_source: 'email_service_source',
  phone_service_source: 'phone_service_source'
};

const fields = {
  [fieldNames.support_board]: {
    name: fieldNames.support_board,
    id: fieldNames.support_board,
    label: 'Service board',
    placeholder: 'Select service board',
    fieldKey: fieldNames.support_board,
    options: query => chatgenieAPI.getBoards({ query })
  },
  [fieldNames.default_priority]: {
    name: fieldNames.default_priority,
    id: fieldNames.default_priority,
    label: 'Default priority',
    placeholder: 'Select default priority',
    fieldKey: fieldNames.default_priority,
    options: query => chatgenieAPI.getPriorities({ query })
  },
  [fieldNames.default_service_source]: {
    name: fieldNames.default_service_source,
    id: fieldNames.default_service_source,
    label: 'Chat source',
    placeholder: 'Select chat source',
    fieldKey: fieldNames.default_service_source,
    options: query => chatgenieAPI.getSources({ query })
  },
  [fieldNames.email_service_source]: {
    name: fieldNames.email_service_source,
    id: fieldNames.email_service_source,
    label: 'Email source',
    placeholder: 'Select email source',
    fieldKey: fieldNames.email_service_source,
    options: query => chatgenieAPI.getSources({ query })
  },
  [fieldNames.phone_service_source]: {
    name: fieldNames.phone_service_source,
    id: fieldNames.phone_service_source,
    label: 'Phone source',
    placeholder: 'Select phone source',
    fieldKey: fieldNames.phone_service_source,
    options: query => chatgenieAPI.getSources({ query })
  }
};

const fieldsArray = Object.values(fields);

const schema = yup
  .object({
    [fieldNames.support_board]: yup
      .object()
      .required()
      .label(fields[fieldNames.support_board].label)
      .nullable(),
    [fieldNames.default_priority]: yup
      .object()
      .required()
      .label(fields[fieldNames.default_priority].label)
      .nullable(),
    [fieldNames.default_service_source]: yup
      .object()
      .required()
      .label(fields[fieldNames.default_service_source].label)
      .nullable(),
    [fieldNames.email_service_source]: yup
      .object()
      .required()
      .label(fields[fieldNames.email_service_source].label)
      .nullable(),
    [fieldNames.phone_service_source]: yup
      .object()
      .required()
      .label(fields[fieldNames.phone_service_source].label)
      .nullable()
  })
  .required();

const submitDataNormalizer = data => {
  const normalizedData = {
    support_board_id: data[fieldNames.support_board].id,
    default_service_source_id: data[fieldNames.default_service_source].id,
    default_priority_id: data[fieldNames.default_priority].id,
    email_service_source_id: data[fieldNames.email_service_source].id,
    phone_service_source_id: data[fieldNames.phone_service_source].id
  };
  return normalizedData;
};

export default {
  fields,
  fieldNames,
  fieldsArray,
  schema,
  submitDataNormalizer
};
