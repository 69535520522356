export const auvikFormFields = {
  BASE_URL: {
    id: 'baseUrl',
    label: 'Base URL',
    placeholder: ''
  },
  USERNAME: {
    id: 'username',
    label: 'Username',
    placeholder: ''
  },
  APIKEY: {
    id: 'apiKey',
    label: 'API Key',
    placeholder: 'e.g. CoPw0lLoCZNtWpiL'
  },
  DOMAIN_PREFIX: {
    id: 'domainPrefix',
    label: 'Domain prefix',
    placeholder: ''
  }
};
