import { isProduction } from 'src/utils';

export const chatgenieBotDownloadLink = isProduction()
  ? 'https://s3.amazonaws.com/app.chat-genie.com/downloads/thread.zip'
  : 'https://s3.amazonaws.com/stage-app.chatgenie.io/downloads/thread-stage.zip';

export const teamsCompanionAppInstallation =
  'https://docs.getthread.com/article/ynz0rlxyls-installing-the-bot-in-microsoft-teams';

export const slackCompanionAppInstallation =
  'https://docs.getthread.com/article/ljgmr5sc5d-installing-slack-as-an-msp';

export const teamsPermissionDocLink =
  'https://docs.getthread.com/article/yzdy7pjm55-troubleshooting-you-don-t-have-permission-error-in-teams';

export const auth0Config = {
  audience: process.env.REACT_APP_AUTH_AUDIENCE,
  domain: process.env.REACT_APP_AUTH_DOMAIN,
  clientId: process.env.REACT_APP_AUTH_CLIENT_ID,
  scope: 'openid email profile offline_access'
};
