import styled from 'styled-components';
import { lighten } from 'polished';

export const Wrapper = styled.div`
  padding: 2rem;
`;

const GroupWrapper = styled.div`
  & + & {
    margin-top: 3rem;
  }
`;

const GroupContent = styled.div``;

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 1rem;
`;

const Icon = styled.div`
  width: 2rem;
  min-width: 2rem;
  height: 2rem;
  flex-grow: 0;
`;

const Title = styled.h2`
  flex-grow: 1;
  font-size: 1rem;
  font-weight: 600;
  margin: 0;
`;

const Note = styled.span`
  font-size: 0.8rem;
  color: ${({ theme }) => theme.colors.text.variant2};
`;

export const DeployButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.3rem;
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  background: none;
  color: ${({ theme }) => theme.colors.brand.light.base};
  transform: scaleX(0.99) translateX(-0.2rem);
  font-size: 0.8rem;
  font-weight: 600;
  opacity: 0;
  transition: 0.2s ease-out;

  span {
    display: inline-block;
    transform: translateX(-0.4rem);
    transition: 0.2s ease-out;
  }

  tr:hover & {
    transform: scaleX(1) translateX(0);
    opacity: 1;

    span {
      transform: translateX(0);
    }
  }

  &:hover {
    color: ${({ theme }) => lighten(0.08, theme.colors.brand.light.base)};
  }
`;

export const Group = {
  Wrapper: GroupWrapper,
  Content: GroupContent,
  Header,
  Icon,
  Title,
  Note
};
