import React from 'react';
import PropTypes from 'prop-types';
import ActionModal from 'src/components/ActionModal';
import { Button } from '@Thread-Magic/jasmine';
import { ReactComponent as CrazyGuy } from 'src/assets/icons/guy-who-went-crazy.svg';
import { ReactComponent as HelpIcon } from 'src/assets/icons/help-outline.svg';
import * as Styled from './style';

const CreatedModal = ({ onClose, isOpen }) => (
  <ActionModal isOpen={isOpen} size="medium" toggleHandler={onClose}>
    <Styled.ContentWrapper>
      <Styled.HelpLink
        href="https://docs.getthread.com/article/ry9zssic5c-how-to-install-the-teams-bot-on-your-customers-tenant"
        target="_blank"
      >
        <HelpIcon />
        Need help?
      </Styled.HelpLink>
      <Styled.Figure>
        <CrazyGuy />
      </Styled.Figure>
      <Styled.Title>Your app has been created. Great job! </Styled.Title>
      <Styled.Subtitle>Next, install the app with customers</Styled.Subtitle>
      <Styled.List>
        <Styled.Item>
          <Styled.Step>1</Styled.Step>
          <Styled.Content>
            <Styled.Caption>Go to the client you’d like to install it</Styled.Caption>
          </Styled.Content>
        </Styled.Item>
        <Styled.Item>
          <Styled.Step>2</Styled.Step>
          <Styled.Content>
            <Styled.Caption>Click on Teams/Slack Connect</Styled.Caption>
            <Styled.Description>
              Upload the zip in the Teams tenant where you want people to start chats.
            </Styled.Description>
            <a
              href="https://chatgenie.helpdocs.io/article/ry9zssic5c-how-to-install-the-teams-bot-on-your-customers-tenant"
              target="_blank"
              rel="noreferrer"
            >
              See instructions →
            </a>
          </Styled.Content>
        </Styled.Item>
        <Styled.Item>
          <Styled.Step>3</Styled.Step>
          <Styled.Content>
            <Styled.Caption>Start receiving and replying to chats.</Styled.Caption>
            <Styled.Description>
              Once the app is installed you’ll receive new chats in Inbox, Slack or Teams.
            </Styled.Description>
          </Styled.Content>
        </Styled.Item>
      </Styled.List>
      <Styled.ActionWrapper>
        <Button type="button" onClick={onClose} size="large">
          Ok, got it.
        </Button>
      </Styled.ActionWrapper>
    </Styled.ContentWrapper>
  </ActionModal>
);

CreatedModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func
};

CreatedModal.defaultProps = {
  onClose: () => {},
  isOpen: false
};

export default CreatedModal;
