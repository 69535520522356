import React from 'react';
import PropTypes from 'prop-types';
import { Wrapper, Label } from './RadioGroup.style';

const RadioGroup = ({ value, options, onChange }) => {
  const handleChange = e => {
    onChange(e.target.value);
  };
  return (
    <Wrapper>
      {options.map(option => (
        <Label key={option.value}>
          <input
            type="radio"
            value={option.value}
            checked={option.value === value}
            onChange={handleChange}
          />
          <span className="dot" />
          {option.label}
        </Label>
      ))}
    </Wrapper>
  );
};

RadioGroup.propTypes = {
  value: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired
};

export default RadioGroup;
