import styled from 'styled-components/macro';

export const ColorsList = styled.div`
  display: flex;
`;

export const AddColor = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ circleSize }) => circleSize}px;
  height: ${({ circleSize }) => circleSize}px;
  border: 1px solid ${({ theme }) => theme.colors.accent.main};
  border-radius: 100%;
  background-color: #ffffff;
  transition: transform 0.2s;

  svg {
    fill: ${({ theme }) => theme.colors.accent.main};
  }

  &:hover,
  &:focus {
    outline: none;
  }
`;

export const Color = styled.div`
  margin: 0;
  position: relative;
  width: ${({ circleSize }) => circleSize}px;
  height: ${({ circleSize }) => circleSize}px;
  border-radius: 100%;
  background-color: ${({ fill }) => fill};
  cursor: pointer;
  margin-right: 10px;

  ::after {
    content: '';
    height: calc(100% + 10px);
    width: calc(100% + 10px);
    position: absolute;
    ${({ isActive, theme }) => isActive && `border: 1px solid ${theme.colors.brand.light.base};`}
    top: -5px;
    left: -5px;
    right: 0;
    bottom: 0;
    border-radius: 100%;
  }
`;
