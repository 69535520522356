import React from 'react';
// import useAuthUser from 'src/hooks/useAuthUser';
import PageWrapper from 'src/components/PageWrapper';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect, useRouteMatch } from 'react-router-dom';
import useAuthUser from 'src/hooks/useAuthUser';
import OnBoarding from './OnBoarding';
import PartnerCenterAuth from './PartnerCenterAuth';
import usePartnerIntegrationState from './hooks/usePartnerIntegrationState';
import Loader from './components/Loader';
import PartnerClients from './PartnerClients';
import PartnerSync from './PartnerSync';
import { partnerIntegrationStates } from './constants';

const AppDeployer = () => {
  const { company } = useAuthUser();
  const { isLoading, syncState } = usePartnerIntegrationState({ companyId: company.id });
  const match = useRouteMatch();
  return (
    <PageWrapper title="Deployment Assist" padding={0}>
      {isLoading ? (
        <Loader message="Loading..." />
      ) : syncState === partnerIntegrationStates.PENDING ? (
        <Switch>
          <Route path={`${match.path}/onboarding`} component={OnBoarding} />
          <Route path={`${match.path}/auth`} component={PartnerCenterAuth} />
          <Route path={`${match.path}/sync`} component={PartnerSync} />
          <Redirect from={match.path} to={`${match.path}/onboarding`} />
        </Switch>
      ) : (
        <Switch>
          <Route path={`${match.path}/clients`} component={PartnerClients} />
          <Redirect from={match.path} to={`${match.path}/clients`} />
        </Switch>
      )}
    </PageWrapper>
  );
};

AppDeployer.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string,
    path: PropTypes.string
  }).isRequired
};

export default AppDeployer;
