import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { PopupWrapper, PopupItems, PopupItem, PopupItemLink } from './PopupStyles';

const Popup = ({ actions, onClose }) => {
  const handleClick = action => e => {
    e.preventDefault();
    e.stopPropagation();

    if (typeof action === 'function') {
      action();
      onClose();
    }
  };

  useEffect(() => {
    window.addEventListener('click', onClose);
    return () => {
      window.removeEventListener('click', onClose);
    };
  }, []);

  return (
    <PopupWrapper>
      <PopupItems>
        {actions.map((action, key) => (
          <PopupItem key={key}>
            <PopupItemLink onClick={handleClick(action.action)}>{action.title}</PopupItemLink>
          </PopupItem>
        ))}
      </PopupItems>
    </PopupWrapper>
  );
};

Popup.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      action: PropTypes.func.isRequired
    })
  )
};

export default Popup;
