import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Wrapper, Options, Option, PopoverTitle } from './ClientSort.style';
import Popover from '../Popover';

const ClientSort = ({ value, onChange, sortOptions }) => {
  const selectedOption = useMemo(
    () => sortOptions.find(option => option.value === value) || sortOptions[0],
    [value]
  );

  const handleClick = useCallback(
    option => () => {
      onChange(option.value);
    },
    [onChange]
  );

  return (
    <Wrapper>
      <Popover
        variant="plain"
        closeOnSelect
        title={
          <PopoverTitle>
            Sort by <strong>{selectedOption.label}</strong>
          </PopoverTitle>
        }
      >
        <Options>
          {sortOptions.map(option => (
            <Option key={option.value} onClick={handleClick(option)}>
              {option.label}
            </Option>
          ))}
        </Options>
      </Popover>
    </Wrapper>
  );
};

ClientSort.defaultProps = {
  value: ''
};

ClientSort.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  sortOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string
    })
  ).isRequired
};

export default ClientSort;
