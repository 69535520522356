import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getContactsByCompany } from 'src/redux/modules/contacts/actions';

import Table from 'src/components/Table';
import { contactsColumnsData } from './config';

const Contacts = ({
  getContactsByCompany,
  contactsByCompany,
  isFetchContactsByCompany,
  errorMessage,
  match
}) => {
  useEffect(() => {
    getContactsByCompany({ companyId: match.params.company });
  }, [match.params.company]);

  return (
    <Fragment>
      <Table
        isLoading={isFetchContactsByCompany}
        error={errorMessage}
        columns={contactsColumnsData}
        rows={contactsByCompany}
        getData={params => getContactsByCompany({ companyId: match.params.company, params })}
      />
    </Fragment>
  );
};

Contacts.propTypes = {
  getContactsByCompany: PropTypes.func.isRequired,
  contactsByCompany: PropTypes.objectOf(PropTypes.any),
  isFetchContactsByCompany: PropTypes.bool,
  errorMessage: PropTypes.string,
  history: PropTypes.objectOf(PropTypes.any),
  match: PropTypes.objectOf(PropTypes.any),
  location: PropTypes.objectOf(PropTypes.any).isRequired
};

Contacts.defaultProps = {
  contactsByCompany: {},
  isFetchContactsByCompany: false,
  errorMessage: '',
  history: null,
  match: null
};

const mapStateToProps = state => ({
  contactsByCompany: state.contactsReducer.contactsByCompany,
  isFetchContactsByCompany: state.contactsReducer.isFetchContactsByCompany,
  errorMessage: state.contactsReducer.errorMessage
});

const mapDispatchToProps = dispatch => ({
  getContactsByCompany: params => dispatch(getContactsByCompany(params))
});

export default connect(mapStateToProps, mapDispatchToProps)(Contacts);
