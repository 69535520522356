import checkIcon from 'src/assets/icons/check.svg';
import checkIndeterminateIcon from 'src/assets/icons/check-indeterminate.svg';
import styled from 'styled-components';

export const Label = styled.label`
  cursor: pointer;

  span {
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    line-height: 16px;
  }

  span::after,
  span::before {
    content: '';
    display: block;
    position: relative;
    top: 1px;
    flex-shrink: 0;
    border-radius: 4px;
    transition: all 0.15s;
    pointer-events: none;
  }

  span::before {
    display: inline-flex;
    width: 16px;
    height: 16px;
    margin-right: 10px;
    box-sizing: border-box;
    background-color: #ffffff;
    border: 1px solid #979797;
    box-shadow: 0 0 0 2px transparent;
    cursor: pointer;
    transition: all 0.15s;
  }

  span::after {
    position: absolute;
    left: 0;
    width: 16px;
    height: 16px;
    opacity: 0;
    background: url(${checkIcon}) no-repeat center 40%;
  }

  input {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    border: 0;
    padding: 0;
    white-space: nowrap;
    clip-path: inset(100%);
    clip: rect(0 0 0 0);
    overflow: hidden;
  }

  input:checked + span::before {
    border-color: ${({ theme }) => theme.colors.brand.light.base};
    background: ${({ theme }) => theme.colors.brand.light.base};
  }

  input:indeterminate + span::before {
    border-color: ${({ theme }) => theme.colors.brand.light.base};
    background: ${({ theme }) => theme.colors.brand.light.base};
  }

  input:indeterminate + span::after {
    background: url(${checkIndeterminateIcon}) no-repeat center 40%;
    background-size: 55% auto;
    opacity: 1;
  }

  input:checked + span::after {
    opacity: 1;
  }
`;
