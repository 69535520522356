import styled from 'styled-components';

export const Wrapper = styled.div``;

export const Options = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  border-radius: 4px;
  padding: 8px 0;
`;

export const Option = styled.li`
  background-color: transparent;
  transition: background-color 0.2s;
  padding: 8px 16px;
  cursor: pointer;
  user-select: none;

  &:hover {
    background-color: #efeeea;
  }
`;

export const PopoverTitle = styled.span`
  display: inline-flex;
  align-items: center;
  gap: 0.3em;
  font-weight: 400;

  strong {
    font-weight: 600;
  }
`;
