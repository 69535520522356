import styled from 'styled-components';

export const WrapperStyled = styled.div`
  position: fixed;
  width: calc(100% - 240px);
  left: 240px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  min-height: 60px;
  box-shadow: 0px 3px 30px rgba(0, 0, 0, 0.06);
  background-color: ${({ theme }) => theme.colors.background.dark};
  z-index: 100;
`;
