import {
  actionStatuses,
  dialogTypes,
  flowsTypes,
  contactsTypes,
  workspacesTypes
} from '../../../constants/action-types';

const defaultState = {
  text: '',
  type: '',
  isVisible: false
};

const map = {
  [`${flowsTypes.CREATE_FLOW}${actionStatuses.FULFILLED}`]: () => ({
    text: 'Flow created',
    type: 'success',
    isVisible: true
  }),
  [`${flowsTypes.UPDATE_FLOW}${actionStatuses.FULFILLED}`]: () => ({
    text: 'Flow updated',
    type: 'success',
    isVisible: true
  }),
  [`${contactsTypes.INVITE_CONTACT}${actionStatuses.FULFILLED}`]: () => ({
    text: 'Invitation sent',
    type: 'success',
    isVisible: true
  }),
  [`${workspacesTypes.CREATE_WORKSPACE_SETTINGS}${actionStatuses.FULFILLED}`]: (state, action) => ({
    text: 'Settings saved',
    type: 'success',
    isVisible: action.meta.isNotify
  }),
  [`${workspacesTypes.UPDATE_WORKSPACE_SETTINGS}${actionStatuses.FULFILLED}`]: (state, action) => ({
    text: 'Settings saved',
    type: 'success',
    isVisible: action.meta.isNotify
  }),
  [dialogTypes.SHOW_DIALOG]: (state, action) => ({
    ...state,
    ...action.payload,
    isVisible: true
  }),
  [dialogTypes.CLEAR_DIALOG]: state => ({
    ...state,
    isVisible: false
  })
};

export default function notificationsReducer(state = defaultState, action) {
  return (map[action.type] && map[action.type](state, action)) || state;
}
