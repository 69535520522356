import React from 'react';
import PropTypes from 'prop-types';

import { TextStyled } from './style';

const MessageForm = ({ className, children, typeView, typeMsg, icon }) => (
  <TextStyled className={className} type={typeView} typeMsg={typeMsg} isIcon={icon}>
    {icon}
    {children}
  </TextStyled>
);

MessageForm.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  typeView: PropTypes.string,
  typeMsg: PropTypes.string,
  icon: PropTypes.node
};

MessageForm.defaultProps = {
  children: '',
  className: '',
  typeView: 'text',
  typeMsg: 'error',
  icon: ''
};

export default MessageForm;
