import styled from 'styled-components';

export const ButtonStyled = styled.button`
  margin-right: 10px;
  padding: 4px;
  border: none;
  line-height: 0;
  background-color: transparent;
  cursor: pointer;

  svg {
    display: block;
    width: 24px;
    height: auto;
    fill: ${({ theme }) => theme.colors.brand.light.base};
  }
`;
