import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { components } from 'react-select';
import keyBy from 'lodash/keyBy';
import { OptionNameStyled, OptionDescriptionStyled, SelectWrapperStyled } from './styles';
import SimpleSelect from '../../components/SimpleSelect';
import { notificationsList } from '../../constants/user-notifications';

const notificationsListByObject = keyBy(notificationsList, 'value');

const EntityNotificationSelect = ({ row, action }) => {
  const [isLoading, setIsLoading] = useState(false);
  const handleChange = useCallback(async option => {
    setIsLoading(true);
    try {
      await action({ id: row.id, entity_notification: option.value });
    } finally {
      setIsLoading(false);
    }
  }, []);

  return (
    <SelectWrapperStyled style={{ minWidth: '120px' }}>
      <SimpleSelect
        components={{
          Option: ({ children, data, ...props }) => (
            <components.Option {...props}>
              <OptionNameStyled>{children}</OptionNameStyled>
              <OptionDescriptionStyled>{data.description}</OptionDescriptionStyled>
            </components.Option>
          )
        }}
        name="user-notifications"
        value={notificationsListByObject[row.entity_notification]}
        onChange={handleChange}
        options={notificationsList}
        isLoading={isLoading}
        isDisabled={isLoading}
        size="small"
        customStyles={{ menuStyles: { minWidth: '220px' } }}
      />
    </SelectWrapperStyled>
  );
};

EntityNotificationSelect.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.number.isRequired,
    entity_notification: PropTypes.number.isRequired
  }).isRequired,
  action: PropTypes.func.isRequired
};

export default EntityNotificationSelect;
