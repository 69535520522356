import axios from 'axios';
import { URL_API } from '../../../constants';
import { companiesTypes, usersTypes } from '../../../constants/action-types';
import { getHeaders } from '../../../utils';
import { normalizeWorspaces } from '../../../utils/normalizer';

export const getCompanies =
  (props = {}) =>
  (dispatch, getState) =>
    dispatch({
      type: companiesTypes.GET_COMPANIES,
      payload: axios({
        method: 'GET',
        url: `${URL_API}/api/workspaces?page=${props.page || 1}`,
        headers: getHeaders(getState)
      }).then(res => {
        const mspWorkspaces = normalizeWorspaces(res.data.data);
        if (mspWorkspaces.length === 1) {
          dispatch({ type: usersTypes.SAVE_APP_ID, payload: { appId: mspWorkspaces[0].appId } });
        }
        return res;
      })
    });

export const getSubCompanies =
  (props = {}) =>
  (dispatch, getState) =>
    dispatch({
      type: companiesTypes.GET_SUB_COMPANIES,
      payload: axios({
        method: 'GET',
        url: `${URL_API}/api/v1/companies${props.queryString || ''}`,
        headers: getHeaders(getState)
      })
    });

export const getCompany = companyId => (dispatch, getState) =>
  dispatch({
    type: companiesTypes.GET_COMPANY,
    payload: axios({
      method: 'GET',
      url: `${URL_API}/api/v1/companies/${companyId}`,
      headers: getHeaders(getState)
    })
  });

export const updateMessengerStatus =
  ({ companyId, status }) =>
  (dispatch, getState) =>
    dispatch({
      type: companiesTypes.UPDATE_MESSENGER_STATUS,
      payload: axios({
        method: 'PUT',
        url: `${URL_API}/api/v1/companies/${companyId}`,
        headers: getHeaders(getState),
        data: {
          messenger_status: status
        }
      })
    });
