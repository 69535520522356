import { actionPlatformsTypes as types, actionStatuses } from '../../../../constants/action-types';

const defaultState = {
  data: [],
  loading: false,
  errorMessage: ''
};

const map = {
  [`${types.GET}${actionStatuses.PENDING}`]: state => ({
    ...state,
    errorMessage: '',
    loading: true
  }),
  [`${types.GET}${actionStatuses.FULFILLED}`]: (state, action) => ({
    ...state,
    data: action.payload.data.data,
    loading: false
  }),
  [`${types.GET}${actionStatuses.REJECTED}`]: (state, action) => ({
    ...state,
    errorMessage: action.payload.response.data.message,
    loading: false
  })
};

export default (state = defaultState, action) => {
  return (map[action.type] && map[action.type](state, action)) || state;
};
