import { chatgenieAPI } from 'src/config/api';
import * as yup from 'yup';

const fieldNames = {
  support_board: 'support_board',
  default_ticket_type: 'default_ticket_type',
  email_service_source: 'email_service_source',
  phone_service_source: 'phone_service_source',
  halo_psa_external_note_action_system_id: 'halo_psa_external_note_action_system_id',
  halo_psa_internal_note_action_system_id: 'halo_psa_internal_note_action_system_id',
  halo_psa_email_action_system_id: 'halo_psa_email_action_system_id'
};

const fields = {
  [fieldNames.support_board]: {
    name: fieldNames.support_board,
    id: fieldNames.support_board,
    label: 'Team',
    placeholder: 'Select team',
    fieldKey: fieldNames.support_board,
    options: query => chatgenieAPI.getBoards({ query })
  },
  [fieldNames.default_ticket_type]: {
    name: fieldNames.default_ticket_type,
    id: fieldNames.default_ticket_type,
    label: 'Default Ticket Type for new threads',
    placeholder: 'Select ticket type',
    fieldKey: fieldNames.default_ticket_type,
    options: query => chatgenieAPI.getTicketTypes({ query })
  },
  [fieldNames.email_service_source]: {
    name: fieldNames.email_service_source,
    id: fieldNames.email_service_source,
    label: 'Email source',
    placeholder: 'Select email source',
    fieldKey: fieldNames.email_service_source,
    options: query => chatgenieAPI.getSources({ query })
  },
  [fieldNames.phone_service_source]: {
    name: fieldNames.phone_service_source,
    id: fieldNames.phone_service_source,
    label: 'Phone source',
    placeholder: 'Select phone source',
    fieldKey: fieldNames.phone_service_source,
    options: query => chatgenieAPI.getSources({ query })
  },
  [fieldNames.halo_psa_external_note_action_system_id]: {
    name: fieldNames.halo_psa_external_note_action_system_id,
    id: fieldNames.halo_psa_external_note_action_system_id,
    label: 'Action for sending customer-facing notes',
    placeholder: 'Select action',
    fieldKey: 'halo_psa_external_note_action_system_id',
    options: query => chatgenieAPI.getOutcomes({ query })
  },
  [fieldNames.halo_psa_internal_note_action_system_id]: {
    name: fieldNames.halo_psa_internal_note_action_system_id,
    id: fieldNames.halo_psa_internal_note_action_system_id,
    label: 'Action for emailing contacts',
    placeholder: 'Select action',
    fieldKey: 'halo_psa_internal_note_action_system_id',
    options: query => chatgenieAPI.getOutcomes({ query })
  },
  [fieldNames.halo_psa_email_action_system_id]: {
    name: fieldNames.halo_psa_email_action_system_id,
    id: fieldNames.halo_psa_email_action_system_id,
    label: 'Action for sending internal "private notes"',
    placeholder: 'Select action',
    fieldKey: 'halo_psa_email_action_system_id',
    options: query => chatgenieAPI.getOutcomes({ query })
  }
};

const fieldsArray = Object.values(fields);

const schema = yup
  .object({
    [fieldNames.support_board]: yup
      .object()
      .required()
      .label(fields[fieldNames.support_board].label)
      .nullable(),
    [fieldNames.default_ticket_type]: yup
      .object()
      .required()
      .label(fields[fieldNames.default_ticket_type].label)
      .nullable(),
    [fieldNames.email_service_source]: yup
      .object()
      .required()
      .label(fields[fieldNames.email_service_source].label)
      .nullable(),
    [fieldNames.phone_service_source]: yup
      .object()
      .required()
      .label(fields[fieldNames.phone_service_source].label)
      .nullable(),
    [fieldNames.halo_psa_external_note_action_system_id]: yup
      .object()
      .required()
      .label(fields[fieldNames.halo_psa_external_note_action_system_id].label)
      .nullable(),
    [fieldNames.halo_psa_internal_note_action_system_id]: yup
      .object()
      .required()
      .label(fields[fieldNames.halo_psa_internal_note_action_system_id].label)
      .nullable(),
    [fieldNames.halo_psa_email_action_system_id]: yup
      .object()
      .required()
      .label(fields[fieldNames.halo_psa_email_action_system_id].label)
      .nullable()
  })
  .required();

const submitDataNormalizer = data => {
  const normalizedData = {
    support_board_id: data[fieldNames.support_board].id,
    default_ticket_type_id: data[fieldNames.default_ticket_type].id,
    email_service_source_id: data[fieldNames.email_service_source].id,
    phone_service_source_id: data[fieldNames.phone_service_source].id,
    halo_psa_externhalo_psa_al_note_action_system_id:
      data[fieldNames.halo_psa_external_note_action_system_id].id,
    halo_psa_internal_note_action_system_id:
      data[fieldNames.halo_psa_internal_note_action_system_id].id,
    halo_psa_email_action_system_id: data[fieldNames.halo_psa_email_action_system_id].id
  };
  return normalizedData;
};

export default {
  fields,
  fieldNames,
  fieldsArray,
  schema,
  submitDataNormalizer
};
