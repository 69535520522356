import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import useAuthUser from 'src/hooks/useAuthUser';

const HasRoles = ({ roles, fallback = null, children }) => {
  const user = useAuthUser();

  const isMatching = useMemo(() => {
    return !user.userId || roles.some(role => user.roles[role]);
  }, [user]);

  return <>{isMatching ? children : fallback}</>;
};

HasRoles.defaultProps = {
  fallback: null,
  children: null
};

HasRoles.propTypes = {
  roles: PropTypes.arrayOf(PropTypes.string).isRequired,
  fallback: PropTypes.node,
  children: PropTypes.node
};

export default HasRoles;
