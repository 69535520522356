export const normalizeWorspaces = data =>
  data
    .map(item => ({
      id: item.id,
      name: item.name,
      appId: item.app_id,
      isClient: item.is_client,
      memberRole: item.member_role
    }))
    .filter(item => !item.isClient);

export const normalizeWorspaceSettings = data => ({
  id: data.id,
  salesBoard: data.sales_board,
  supportBoard: data.support_board,
  reopenStatus: data.reopen_status,
  messengerErrorStatus: data.ms_teams_error_status,
  overrideSettings: data.override_settings,
  proactiveMessagingStatus: data.proactive_messaging_status,
  supportType: data.support_type,
  lang: data.lang,
  overrideBotPromptMessages: data.override_bot_prompt_messages,
  botPromptMessages: data.bot_prompt_message_translations,
  allowNewContacts: data.allow_new_contacts,
  autoAddMembers: data.auto_add_members,
  externalIntegration: data.external_integration
});

export const normalizeUserData = data => ({
  createdAt: data.created_at,
  email: data.email,
  fullname: data.full_name,
  flowItemsReady: data.flow_items_ready,
  userId: data.user_id,
  memberId: data.member_id,
  cwConfiguration: data.is_client || data.cw_configuration,
  chatConfiguration: data.chat_configuration,
  botInstalled: data.bot_installed,
  slackHasNewConfiguration: data.slack_has_new_configuration === 0, // Invert condition NEED FIX after relise
  msTeamsHasNewConfiguration: data.msteams_has_new_configuration === 0, // Invert condition NEED FIX after relise
  roles: data.roles?.reduce((obj, index) => ({ [index]: true, ...obj }), {}) || {},
  subscription: data.subscription,
  isFlows: !!data.flow_count,
  isClient: data.is_client,
  featureFlags: data.feature_toggles,
  psaType: data.psa_type,
  company: {
    ...data.company,
    settings: data.company?.settings ? normalizeWorspaceSettings(data.company.settings) : {},
    appId: data.company?.app_id,
    primary_color: /^#[0-9A-F]{6}$/i.test(data.company?.primary_color || '')
      ? data.company.primary_color
      : '',
    secondary_color: /^#[0-9A-F]{6}$/i.test(data.company?.secondary_color || '')
      ? data.company.secondary_color
      : ''
  }
});
