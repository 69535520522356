import { useState, useEffect } from 'react';

import { useMutation, useQuery } from 'react-query';

import { chatgenieAPI } from 'src/config/api';
import queryKeys from 'src/constants/queryKeys';
import useToast from 'src/hooks/useToast';

const useTabs = () => {
  const { toast } = useToast();

  const [agentEnabled, setAgentEnabled] = useState(false);
  const [settings, setSettings] = useState({});

  const {
    data: res,
    isLoading,
    refetch
  } = useQuery([queryKeys.GET_AGENT_SETTINGS], () => chatgenieAPI.getAgentSettings(), {
    refetchOnMount: true
  });

  const { mutate } = useMutation(
    ['updateAgentSettings'],
    data => chatgenieAPI.updateAgentSettings(data),
    {
      onSuccess: () => refetch(),
      onError: error => {
        toast.error(
          `Failed to update agent's settings: ${
            error?.response?.data?.message || error?.message || 'Unknown'
          }`
        );
      }
    }
  );

  function updateAgentSettings(settings) {
    mutate(settings);
    if (settings.enabled !== undefined) {
      setAgentEnabled(settings.enabled);
    }
  }

  useEffect(() => {
    if (res) {
      setAgentEnabled(res.enabled);
      setSettings(res);
    }
  }, [res]);

  return {
    settings,
    agentEnabled,
    updateAgentSettings,
    isLoading
  };
};

export default useTabs;
