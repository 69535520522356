import styled, { css } from 'styled-components';

const styles = css`
  font-size: 14px;
  line-height: 12px;
  font-weight: 600;
  display: ${({ isHidden }) => (isHidden ? 'none' : 'block')};
  letter-spacing: -0.09px;
  min-width: 136px;
  height: 45px;
  padding: 0 16px;
  border: none;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.brand.light.base};
  color: #ffffff;
  white-space: nowrap;
  transition: background-color 0.2s;

  &:hover,
  &:focus {
    text-decoration: none;
    outline: none;
    color: #ffffff;
    background-color: ${({ theme }) => theme.colors.brand.light.hover};
  }

  &:active {
    background-color: ${({ theme }) => theme.colors.brand.light.active};
  }

  svg {
    width: 30px;
    height: 30px;
  }

  ${({ full }) =>
    full &&
    `
    width: 100%;
  `}

  ${({ size }) =>
    size === 'large' &&
    `
    height: 63px;
    font-size: 18px;
    line-height: 14px;
    font-weight: normal;
  `}

  ${({ size }) =>
    size === 'small' &&
    `
    height: 30px;
    font-size: 12px;
    line-height: 14px;
    font-weight: normal;

    svg {
      width: 20px;
      height: 20px;
      }
  `}

  ${({ styleMode }) =>
    styleMode === 'error' &&
    `
    background-color: #EC0505;

    &:hover,
    &:focus {
      outline: none;
      background-color: #FF0000;
      }

    &:active {
      background-color: #DB0202;
      }
  `}

  a,
  a:hover {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    text-decoration: none;
    color: #fafafa;
  }

  ${({ disabled }) =>
    disabled &&
    `
    background-color: #BDBDBD;
    color: #616161;
    cursor: not-allowed;

    &:hover,
    &:focus {
      outline: none;
      color: #616161;
      background-color: #BDBDBD;
    }
  `}

  ${({ customStyle }) => customStyle}
`;

export const ButtonStyled = styled.button`
  ${styles}
`;

export const LinkStyled = styled.a`
  ${styles}
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;
