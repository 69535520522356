import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as MsTeamsLogo } from 'src/assets/images/msteams-logo-2.svg';
import useAuthUser from 'src/hooks/useAuthUser';
import { FiArrowRight } from 'react-icons/fi';
import { Wrapper, Group, DeployButton } from './PartnerClients.style';
import usePartnerIntegrationState from '../hooks/usePartnerIntegrationState';
import CustomerTenantMapModal from '../CustomerTenantMapModal';
import { customerDeploymentStates } from '../constants';
import ClientTableByDeploymentState from './ClientTableByDeploymentState';

const DeployAction = ({ onClick }) => {
  return (
    <DeployButton onClick={onClick}>
      <span>Deploy App</span>
      <FiArrowRight />
    </DeployButton>
  );
};

DeployAction.propTypes = {
  onClick: PropTypes.func.isRequired
};

const PartnerClients = () => {
  const { company } = useAuthUser();
  const { lastSyncedAt } = usePartnerIntegrationState({ companyId: company.id, refetch: false });

  const [showCustomerTenantMapModal, setShowCustomerTenantMapModal] = useState(false);

  const handleShowCustomerTenantMapModal = useCallback(() => {
    setShowCustomerTenantMapModal(true);
  }, []);

  const handleCloseCustomerTenantMapModal = useCallback(() => {
    setShowCustomerTenantMapModal(false);
  }, []);

  return (
    <Wrapper>
      <Group.Wrapper>
        <Group.Header>
          <Group.Icon>
            <MsTeamsLogo />
          </Group.Icon>
          <Group.Title>Top clients to deploy Teams</Group.Title>
          <Group.Note>Last updated {lastSyncedAt}</Group.Note>
        </Group.Header>
        <Group.Content>
          <ClientTableByDeploymentState
            deploymentState={customerDeploymentStates.NOT_DEPLOYED}
            onDeploy={handleShowCustomerTenantMapModal}
          />
        </Group.Content>
      </Group.Wrapper>
      <Group.Wrapper>
        <Group.Header>
          <Group.Title>Clients you&apos;ve already deployed to</Group.Title>
        </Group.Header>
        <Group.Content>
          <ClientTableByDeploymentState deploymentState={customerDeploymentStates.DEPLOYED} />
        </Group.Content>
      </Group.Wrapper>
      <CustomerTenantMapModal
        isOpen={showCustomerTenantMapModal}
        onClose={handleCloseCustomerTenantMapModal}
      />
    </Wrapper>
  );
};

export default PartnerClients;
