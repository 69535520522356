import { useState, useEffect } from 'react';

export const useRefactrFormPage = ({ onSubmit, formData, configuration }) => {
  const formId = 'integration-refactr-form';
  const [baseUrl, setBaseUrl] = useState(formData.baseUrl || '');
  const [login, setLogin] = useState(formData.login || '');
  const [password, setPassword] = useState(formData.password || '');
  const [submitted, setSubmitted] = useState(false);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    setBaseUrl(formData.baseUrl);
    setLogin(formData.login);
    setPassword(formData.password);
  }, [formData]);

  useEffect(() => {
    setIsActive(configuration.is_active);
  }, [configuration]);

  const onFormSubmit = event => {
    event.preventDefault();
    setSubmitted(true);

    onSubmit(
      {
        baseUrl,
        login,
        password
      },
      isActive
    );
  };

  return {
    formId,
    baseUrl,
    setBaseUrl,
    login,
    setLogin,
    password,
    setPassword,
    onFormSubmit,
    submitted,
    isActive,
    setIsActive
  };
};
