import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  color: #fafafa;
  margin-top: auto;
`;

export const UserLabel = styled.span`
  margin-left: 12px;
  margin: 0 16px 0 12px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const UserWrapper = styled.div`
  display: flex;
  align-items: center;
  max-width: 100%;
`;

export const Avatar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 28px;
  width: 28px;
  height: 28px;
  font-size: 12px;
  text-decoration: uppercase;
  color: #ffffff;
  background-color: ${({ theme }) => theme.colors.brand.light.active};
`;
