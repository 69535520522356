import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as MainLogo } from 'src/assets/icons/logo.svg';
import MessageForm from 'src/components/MessageForm';
import * as Styled from './style';

const OnboardingModal = ({ title, children, error, isLoading }) => (
  <Styled.Wrapper>
    <Styled.Logo>
      <MainLogo />
    </Styled.Logo>
    <Styled.Modal>
      <Styled.Header>{title && <Styled.Title>{title}</Styled.Title>}</Styled.Header>
      {error && <MessageForm>{error}</MessageForm>}
      {isLoading ? <Styled.Spinner width="40" height="40" /> : children}
    </Styled.Modal>
  </Styled.Wrapper>
);

OnboardingModal.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  error: PropTypes.string,
  isLoading: PropTypes.bool
};

OnboardingModal.defaultProps = {
  title: '',
  children: null,
  error: '',
  isLoading: false
};

export default React.memo(OnboardingModal);
