import React from 'react';
import PropTypes from 'prop-types';

import useLogout from 'src/hooks/useLogout';

import OnboardingModal from '../../containers/OnboardingModal';
import MessageForm from '../MessageForm';
import LinkButton from '../LinkButton';

import { WrapperStyled } from './style';

const ErrorPage = ({ message }) => {
  const { logout } = useLogout();

  return (
    <OnboardingModal>
      <WrapperStyled>
        <MessageForm typeView="block">{message}</MessageForm>
        <LinkButton
          onClick={() => {
            logout();
          }}
        >
          Go to login page
        </LinkButton>
      </WrapperStyled>
    </OnboardingModal>
  );
};

ErrorPage.propTypes = {
  message: PropTypes.string
};

ErrorPage.defaultProps = {
  message: ''
};

export default ErrorPage;
