import { queryClient } from 'src/config/queryClient';
import queryKeys from 'src/constants/queryKeys';
import sortBy from 'lodash/sortBy';

export const addRuleIntoCache = (data, additionalQueryKey) => {
  queryClient.setQueryData([queryKeys.GET_COPILOT_PRIORITY_RULES, additionalQueryKey], old => {
    const isCustomSectionAvailable = old.conditions.find(section => section.type === 'CUSTOM');
    return {
      ...old,
      conditions: isCustomSectionAvailable
        ? old.conditions.map(section =>
            section.type === 'CUSTOM'
              ? {
                  ...section,
                  rules: [...section.rules, data]
                }
              : section
          )
        : [...old.conditions, { type: 'CUSTOM', rules: [data] }]
    };
  });
};

export const removeRuleFromCache = (data, additionalQueryKey) => {
  queryClient.setQueryData([queryKeys.GET_COPILOT_PRIORITY_RULES, additionalQueryKey], old => ({
    ...old,
    conditions: old.conditions.map(section =>
      section.type === 'CUSTOM'
        ? {
            ...section,
            rules: section.rules.filter(el => el.id !== data.id)
          }
        : section
    )
  }));
};

export const updateCachedRule = (data, additionalQueryKey) => {
  queryClient.setQueryData([queryKeys.GET_COPILOT_PRIORITY_RULES, additionalQueryKey], old => ({
    ...old,
    conditions: old.conditions.map(section => ({
      ...section,
      rules: section.rules.map(el => (el.id === data.id ? { ...el, ...data } : el))
    }))
  }));
};

export const updateMultipleEntities = ({ rules, firstAccess }, additionalQueryKey) => {
  queryClient.setQueryData([queryKeys.GET_COPILOT_PRIORITY_RULES, additionalQueryKey], old => ({
    ...old,
    first_access: firstAccess,
    conditions: old.conditions.map(section => ({
      ...section,
      rules: section.rules.map(el => (el.id in rules ? { ...el, ...rules[el.id] } : el))
    }))
  }));
};

export const getSortedPriorities = data => ({
  ...data,
  data: sortBy(data.data, 'sort_order')
});
