import styled from 'styled-components';

export const ButtonStyled = styled.button`
  position: relative;
  padding: 0;
  border: none;
  background-color: transparent;
  font-size: 14px;
  line-height: 16px;
  font-weight: 600;
  transition: color 0.2s;
  color: ${({ theme }) => theme.colors.brand.light.base};

  &:active,
  &:focus,
  &:hover {
    outline: none;
    text-decoration: none;
    color: ${({ theme }) => theme.colors.brand.light.hover};
  }

  & > span {
    opacity: ${({ isLoader }) => (isLoader ? 0 : 1)};
  }

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
