import React, { useState, useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Table from 'src/components/Table';
import { getPartnerClients } from 'src/redux/modules/deployment-center/actions';
import { match } from 'ts-pattern';
import { columns } from './config';
import { Wrapper } from './PartnerDeployedClients.style';
import { normalizeClientCompanyFilters } from '../utils';
import ControlBar from '../components/ControlBar';

const mapSortOptionToQueryParam = sortOption =>
  match(sortOption)
    .with('requests', () => '-thread_usage')
    .with('name', () => 'name')
    .with('magic_ai_transactions', () => 'magic_ai_transactions')
    .otherwise(() => 'requests');

const mapSortFieldToSortOption = sortField =>
  match(sortField)
    .with('thread_usage', () => 'requests')
    .with('name', () => 'name')
    .with('magic_ai_transactions', () => 'magic_ai_transactions')
    .otherwise(() => 'requests');

const sortOptions = [
  { label: '# threads', value: 'requests' },
  { label: '# Magic transactions', value: 'magic_ai_transactions' },
  { label: 'Name', value: 'name' }
];

const PartnerDeployedClients = ({
  viewFiltersAndSorting,
  setViewFiltersAndSorting,
  view,
  setView,
  getPartnerClients,
  clients,
  isFetchClients,
  errorMessage,
  history,
  location
}) => {
  const [isViewFiltersChanged, setIsViewFiltersChanged] = useState(false);
  const [checkedCompanies, setCheckedCompanies] = useState([]);

  const queryStringRef = useRef('');

  const handleFilterChange = useCallback(newFilters => {
    setViewFiltersAndSorting(prev => ({ ...prev, filters: newFilters }));
    setIsViewFiltersChanged(true);
  }, []);

  const handleSortOptionChange = useCallback(newSortOption => {
    setViewFiltersAndSorting(prev => ({ ...prev, sortOption: newSortOption }));
    setIsViewFiltersChanged(true);
  }, []);

  const handleGetPartnerClients = useCallback(
    params => {
      const { queryString = '' } = params;
      const existingUrlParams = new URLSearchParams(queryString);
      const page = existingUrlParams.get('page') || 1;
      const normalizedFilters = normalizeClientCompanyFilters(viewFiltersAndSorting.filters);

      const urlParams = new URLSearchParams();
      urlParams.set('page', page);

      Object.keys(normalizedFilters).forEach(key => {
        if (normalizedFilters[key]) {
          urlParams.set(key, normalizedFilters[key]);
        }
      });

      const sortParam = mapSortOptionToQueryParam(viewFiltersAndSorting.sortOption);
      urlParams.set('sort', sortParam);

      const search = existingUrlParams.get('query') || '';
      if (search.length > 0) {
        urlParams.set('query', search);
      }

      getPartnerClients({ ...params, queryString: urlParams.toString() });

      queryStringRef.current = urlParams.toString();
    },
    [viewFiltersAndSorting, getPartnerClients]
  );

  const handleActionSelectorChange = useCallback(() => {
    setCheckedCompanies([]);
  }, []);

  useEffect(() => {
    if (view) {
      setViewFiltersAndSorting({
        filters: view.filter_data,
        sortOption: mapSortFieldToSortOption(view.sort_field)
      });
      setIsViewFiltersChanged(false);
    }
  }, [view]);

  useEffect(() => {
    handleGetPartnerClients({ queryString: queryStringRef.current });
  }, [viewFiltersAndSorting]);

  return (
    <Wrapper>
      <ControlBar
        filters={viewFiltersAndSorting.filters}
        onFilterChange={handleFilterChange}
        viewFiltersChanged={isViewFiltersChanged}
        sortOption={viewFiltersAndSorting.sortOption}
        sortField={mapSortOptionToQueryParam(viewFiltersAndSorting.sortOption)}
        onSortOptionChange={handleSortOptionChange}
        view={view}
        checkedCompanies={checkedCompanies}
        onViewChange={setView}
        onActionsChange={handleActionSelectorChange}
        viewType="deployment_center_deployed_clients"
        sortOptions={sortOptions}
        showCompanyType={false}
        showAgreementType={false}
        showDeployStatus={false}
      />
      <Table
        getData={handleGetPartnerClients}
        isLoading={isFetchClients}
        error={errorMessage}
        columns={columns()}
        onRowClick={row => {
          history.push({
            pathname: `/dashboard/clients/companies/${row.company_id}/connect`,
            state: { companyName: row.name, appId: row.company_id, search: location.search }
          });
        }}
        rows={clients}
      />
    </Wrapper>
  );
};

PartnerDeployedClients.propTypes = {
  viewFiltersAndSorting: PropTypes.shape({}).isRequired,
  setViewFiltersAndSorting: PropTypes.func.isRequired,
  setView: PropTypes.func.isRequired,
  getPartnerClients: PropTypes.func.isRequired,
  clients: PropTypes.objectOf(PropTypes.any),
  isFetchClients: PropTypes.bool,
  errorMessage: PropTypes.string,
  history: PropTypes.objectOf(PropTypes.any),
  location: PropTypes.objectOf(PropTypes.any),
  view: PropTypes.shape({})
};

PartnerDeployedClients.defaultProps = {
  clients: {},
  isFetchClients: false,
  errorMessage: '',
  history: {},
  location: {},
  view: null
};

const mapStateToProps = state => ({
  clients: state.deploymentCenterReducer.clients,
  isFetchClients: state.deploymentCenterReducer.isFetchClients,
  errorMessage: state.deploymentCenterReducer.errorMessage
});

const mapDispatchToProps = dispatch => ({
  getPartnerClients: params => dispatch(getPartnerClients(params))
});

export default connect(mapStateToProps, mapDispatchToProps)(PartnerDeployedClients);
