export default {
  colors: {
    accent: {
      main: '#051275',
      light: '#E1ECFE'
    },
    brand: {
      main: {
        base: '#005C55',
        hover: '#008270',
        active: '#007464'
      },
      light: {
        base: '#00BB99',
        hover: '#66D6C2',
        active: '#00BB99'
      },
      lighter: {
        hover: '#F0FBF9'
      }
    },
    background: {
      default: '#ffffff',
      secondary: '#faf9f6',
      dark: '#eeeeee',
      variant1: '#ffffff',
      variant2: '#efeeea',
      variant3: '#e9e9e9',
      variant4: '#faf9f6',
      variant5: '#E1ECFE',
      variant6: '#eef4fe'
    },
    text: {
      default: '#000000',
      main: '#051275',
      secondary: '#2C2D2F',
      dark: '#212121',
      label: '#616161',
      variant1: '#212121',
      variant2: '#999999',
      variant3: '#666666',
      variant4: '#616999'
    },
    border: {
      default: '#eeeeee',
      light: '#f8f7f7',
      active: '#051275',
      hover: '#dddddd',
      variant1: '#dddddd'
    },
    action: {
      hover: '#efeeea',
      active: '#eef4fe',
      disabled: '#999999',
      variant1: '#ffffff',
      variant2: '#f5f5f5',
      variant3: '#FFF5E4'
    },
    danger: {
      main: '#e7121d',
      default: '#FF0000',
      light: '#fdeeee',
      dark: '#EB5757',
      darkest: '#c13515'
    },
    common: {
      white: '#ffffff',
      black: '#000000',
      contrast: '#000000'
    },
    // absolute colors
    gray1: '#dddddd',
    gray2: '#f5f5f5',
    gray3: '#efefef',
    gray4: '#c4c4c4',
    gray5: '#999999',
    gray6: '#666666',
    gray7: '#bdbdbd',
    lightGray1: '#efeeea',
    lightGray2: '#e9e9e9',
    blue1: '#061274',
    blue2: '#2366B3',
    blue3: '#616999',
    blue4: '#076AF4',
    blue5: '#0E1D96',
    blue6: '#1829B7',
    lightBlue: '#317FFF',
    green1: '#6FCF97',
    green2: '#155724',
    green3: '#D4F2E9',
    orange1: '#F8A325',
    orange2: '#FCECD6',
    sazerac: '#FFF5E4',
    red1: '#EB5757',
    red2: '#e7121d',
    red3: '#fdeeee',
    red4: '#EC0505',
    red5: '#FF0000',
    boyRed: '#756A4E',
    purple1: '#D6DBFC',
    teamsBrandColor: '#45468C',
    priority: {
      Red: '#E06464',
      Orange: '#E4980E',
      Yellow: '#FFC367',
      White: '#4575D3',
      Purple: 'transparent',
      default: '#E7E7E7'
    }
  },
  fonts: {
    main: 'Inter, "Open Sans", Helvetica Neue, sans-serif',
    title: '"SF Pro Display", Helvetica Neue, sans-serif',
    text: '"SF Pro Text","Helvetica Neue, sans-serif'
  },
  messenger: {
    default: {
      primaryColors: ['#00bb99', '#ecb940', '#89c5fc', '#9364b7'],
      logo: '/images/logo-x2.png',
      trayIcon: '/images/tray-icon.png',
      botName: 'Thread',
      botLogos: [
        '/images/bot-icon-1.png',
        '/images/bot-icon-2.png',
        '/images/bot-icon-3.png',
        '/images/bot-icon-4.png'
      ]
    }
  }
};
