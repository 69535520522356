import { useState, useEffect, useRef } from 'react';
import { chatsTypes } from 'src/constants/action-types';
import { removeLocalStorage, setLocalStorage } from '../../utils';
import { GET_TEAMS_INTERVAL } from '../../config/integration';

export const useSignUpBotInstallPage = ({
  configurationId,
  getConfigurationId,
  getTeams,
  teams,
  history,
  token
}) => {
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const interval = useRef(null);

  const finishBotInstall = () => {
    setLocalStorage({ chatConfiguration: chatsTypes.MS_TEAMS, botInstalled: true });
    removeLocalStorage('chatPlatformStep');
    removeLocalStorage('onboardingStep');
    history.replace('/dashboard/integrations');
  };

  useEffect(() => {
    if (!configurationId) {
      getConfigurationId();
    } else {
      interval.current = setInterval(() => {
        if (!token) {
          clearInterval(interval.current);
        } else {
          getTeams(interval.current).then(response => {
            const isTeams = !!response.action.payload.data.data.length;

            if (isTeams) {
              clearInterval(interval.current);
              finishBotInstall();
            }

            return response;
          });
        }
      }, GET_TEAMS_INTERVAL);
    }
  }, [token, configurationId, getConfigurationId, getTeams]);

  useEffect(() => {
    if (teams.length) {
      finishBotInstall();
    }
  }, [teams.length]);

  useEffect(() => {
    return () => {
      clearInterval(interval.current);
    };
  }, []);

  return {
    isVisibleModal,
    setIsVisibleModal
  };
};
