import { createStore, applyMiddleware, combineReducers } from 'redux';
import promiseMiddleware from 'redux-promise-middleware';
import thunk from 'redux-thunk';

// middlewares
import gaEvents from './middlewares/gaEvents';

// modules
import usersReducer from './modules/users/usersReducer';
import dialogReducer from './modules/dialog/dialogReducer';
import chatsReducer from './modules/chats/chatsReducer';
import flowsReducer from './modules/flows/flowsReducer';
import companiesReducer from './modules/companies/reducer';
import contactsReducer from './modules/contacts/reducer';
import channelsReducer from './modules/channels/channelsReducer';
import notificationsReducer from './modules/notifications/notificationsReducer';
import integrationsReducer from './modules/integrations';
import deploymentCenterReducer from './modules/deployment-center/reducer';
import workspaces from './modules/workspaces/reducer';

const middleware =
  process.env.NODE_ENV === 'production'
    ? [promiseMiddleware(), thunk, gaEvents]
    : [promiseMiddleware(), thunk];

export const reducer = combineReducers({
  usersReducer,
  contactsReducer,
  chatsReducer,
  flowsReducer,
  companiesReducer,
  channelsReducer,
  dialogReducer,
  notificationsReducer,
  integrations: integrationsReducer,
  deploymentCenterReducer,
  workspaces
});

const composeStoreWithMiddleware = applyMiddleware(...middleware)(createStore);

const configureStore = (initialState = {}) =>
  composeStoreWithMiddleware(
    reducer,
    initialState,
    // eslint-disable-next-line no-underscore-dangle
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  );

export default configureStore();
