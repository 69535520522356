import React from 'react';

const partnerClientsColumns = ({ DeployActionComponent, onDeployAction }) => [
  {
    key: 'name',
    title: 'Name'
  },
  {
    key: 'totalUsers',
    title: 'Total users',
    component: row => row.usageReport.totalUsers
  },
  {
    key: 'activeUsers',
    title: 'Active users',
    component: row => row.usageReport.activeUsers
  },
  {
    key: 'activeUsersPercentage',
    title: '% of active users',
    component: row => row.usageReport.activeUsersPercentage
  },
  {
    key: 'lastActive',
    title: 'Last active',
    component: row => row.usageReport.lastActive
  },
  {
    key: 'teamsChats',
    title: 'Teams chats',
    component: row => row.usageReport.teamsChats
  },
  {
    key: 'directMessages',
    title: 'DMs',
    component: row => row.usageReport.directMessages
  },
  {
    key: 'messagesSent',
    title: 'Messages sent',
    component: row => row.usageReport.directMessages
  },
  {
    key: 'action',
    title: '',
    component: () => <DeployActionComponent onClick={onDeployAction} />,
    isSort: false,
    compact: true
  }
];

export default partnerClientsColumns;
