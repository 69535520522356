import React from 'react';
import PropTypes from 'prop-types';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import SimpleButton from '../../components/SimpleButton';

import * as Styled from './style';

const Installation = ({ appId }) => {
  const snippet = `<script>
  var chatgenieParams = {
    appId: "${appId}"
  }
  function run(ch){ch.default.messenger().initialize(chatgenieParams);}!function(){var e=window.chatgenie;if(e)run(e);else{function t(){var t=document.createElement("script");t.type="text/javascript",t.async=true,t.readyState?t.onreadystatechange=function(){"loaded"!==t.readyState&&"complete"!==t.readyState||(t.onreadystatechange=null,window.chatgenie&&(e=window.chatgenie,run(e)))}:t.onload=function(){window.chatgenie&&(e=window.chatgenie,run(e))},t.src="${process.env.REACT_APP_MESSENGER_URL}/widget.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n)}window.attachEvent?window.attachEvent("onload",t):window.addEventListener("load",t,!1)}}();
</script>`;

  return (
    <Styled.Wrapper>
      <div>App ID:</div>
      <Styled.Code>{appId}</Styled.Code>
      <div>Snippet:</div>
      <Styled.Code>{snippet}</Styled.Code>
      <CopyToClipboard text={snippet}>
        <SimpleButton size="small" type="button">
          Copy to clipboard
        </SimpleButton>
      </CopyToClipboard>
    </Styled.Wrapper>
  );
};

Installation.propTypes = {
  appId: PropTypes.string.isRequired
};

export default Installation;
