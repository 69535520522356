import React from 'react';
import PropTypes from 'prop-types';
import config from './config';
import * as Styled from '../styles';
import withController from '../withController';

const Halo = ({ onSubmit, children, handleSubmit }) => {
  const onFormSubmit = data => {
    const formatted = {
      [config.fields.BASE_URL.fieldKey]: data[config.fields.BASE_URL.name],
      [config.fields.CLIENT_ID.fieldKey]: data[config.fields.CLIENT_ID.name],
      [config.fields.CLIENT_SECRET.fieldKey]: data[config.fields.CLIENT_SECRET.name]
    };
    onSubmit(data, formatted);
  };

  return (
    <Styled.Form id={config.formId} onSubmit={handleSubmit(onFormSubmit)}>
      {children}
    </Styled.Form>
  );
};

Halo.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  children: PropTypes.any.isRequired
};

export default withController(Halo, config);
