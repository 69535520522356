import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import PropTypes from 'prop-types';
import * as Styled from '../style';
import CopilotTestAction from '../CopilotTestAction';
import { Card } from '../CopilotTestAction/style';
import RecapRulesEditor from './components/RecapRulesEditor';

const newMessageNormalizer = ({ recap }) => {
  return {
    type: 'copilot',
    name: 'Magic AI',
    body: (
      <Card>
        <h5>Generate recap action</h5>
        <div>
          <ReactMarkdown>{recap}</ReactMarkdown>
        </div>
      </Card>
    )
  };
};

function ViewRecapRules({ rules, templates, threads, saveRules, generateRecap, onChange }) {
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    setIsAnimating(true);
    setTimeout(() => setIsAnimating(false), 2000);
  }, [rules]);

  return (
    <Styled.ViewContainer>
      <Styled.ViewInner paddingTop={32}>
        <RecapRulesEditor
          rules={rules}
          templates={templates}
          onSave={saveRules}
          onChange={onChange}
        />
      </Styled.ViewInner>
      <CopilotTestAction
        title="Test recap"
        threads={threads}
        description="Magic will automatically create a recap of the thread."
        firstMessage="Hi, Magic here! Select a thread above and I'll create a recap for you."
        askAnotherMessage="Select another thread above and I’ll give you a recap!"
        onAction={generateRecap}
        isAnimating={isAnimating}
        newMessageNormalizer={newMessageNormalizer}
      />
    </Styled.ViewContainer>
  );
}

ViewRecapRules.propTypes = {
  rules: PropTypes.string.isRequired,
  templates: PropTypes.arrayOf({
    scenario: PropTypes.string,
    template: PropTypes.string
  }).isRequired,
  threads: PropTypes.arrayOf({
    ticket_id: PropTypes.number,
    title: PropTypes.string
  }).isRequired,
  saveRules: PropTypes.func.isRequired,
  generateRecap: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
};

export default ViewRecapRules;
