import React, { Fragment, useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setContext, setTags, setUser } from '@sentry/react';

import { saveToken, getInfo, saveAppId } from 'src/redux/modules/users/usersActions';
import { gaId, cannyId } from 'src/config/integration';
import SelectWorkspace from 'src/pages/SelectWorkspace';
import SignupTicketing from 'src/pages/SignupTicketing';
import SignupSync from 'src/pages/SignupSync';
import SignupChat from 'src/pages/SignupChat';
import SignupBotInstall from 'src/pages/SignupBotInstall';
import SignupProfile from 'src/pages/SignupProfile';
import TeamsAppPreview from 'src/pages/TeamsAppPreview';
import jwtDecode from 'jwt-decode';
import { getLocalStorage } from 'src/utils';

import { userPilotInit } from 'src/utils/userPilot.analytics';
import HasRoles from 'src/components/HasRoles';
import NoAccess from 'src/components/NoAccess';
import SignupMessengerDesign from 'src/pages/SignupMessengerDesign';
import SignupMessengerSettings from 'src/pages/SignupMessengerSettings';
import SignupSuccess from 'src/pages/SignupSuccess';
import { useAuth0, useGetToken, useLoginWithRedirect } from '../../components/Auth0';
import StackMessages from '../StackMessages';
import Dialog from '../Dialog';
import AuthPrivateRouter from '../../components/AuthPrivateRouter';
import Dashboard from '../Dashboard';

import PageLoader from '../../components/PageLoader';
import NoMatch from '../../components/NoMatch';
import Canny from '../../components/Canny';
import gaInit, { gaSet } from '../../components/GoogleAnalytics';
import ErrorPage from '../../components/ErrorPage';

const DashboardRoleProtected = props => {
  return (
    <HasRoles roles={['admin']} fallback={<NoAccess />}>
      <Dashboard {...props} />
    </HasRoles>
  );
};

const Layout = ({ user, saveToken, saveAppId, getInfo, isFetchUser, signInErrorMessage }) => {
  const { loading, getTokenSilently, isAuthenticated, loginWithRedirect } = useAuth0();

  useLoginWithRedirect({ loading, isAuthenticated, loginWithRedirect, path: '/' });

  useGetToken({
    loading,
    getTokenHandler: getTokenSilently,
    getTokenCallback: token => {
      const appData = getLocalStorage('app');
      const tokenData = jwtDecode(token);

      // The appData.sub is set when the user has only one workspace or when user adds a new workspace
      if (appData?.sub === tokenData?.sub && appData?.id) {
        saveAppId({ appId: appData.id, token });
      }

      // Inbox redirect does not set appData.sub, it only sends a valid app id via query param
      if (!appData?.sub && appData?.id) {
        saveAppId({ appId: appData.id, token });
      }

      saveToken(token);
    }
  });

  useEffect(() => {
    gaInit({ trackingId: gaId });
  }, []);

  useEffect(() => {
    if (user.userId) {
      userPilotInit(user);
    }
  }, [user.userId]);

  useEffect(() => {
    if (user.userId) {
      const { memberId, email, company } = user;

      setUser({ email, id: memberId });
      setTags({
        email,
        memberId,
        appId: company?.appId,
        companyId: company?.id
      });
      setContext('company', {
        appId: company?.appId,
        name: company?.name,
        id: company?.id
      });
    }
  }, [user]);

  useEffect(() => {
    if (user.userId) gaSet({ userId: user.userId });
  }, [user.userId]);

  useEffect(() => {
    if (user.token && !user.userId) {
      getInfo();
    }
  }, [user, getInfo]);

  if (loading || !user.token || isFetchUser) {
    return <PageLoader />;
  }

  return (
    <Fragment>
      <Dialog />
      <StackMessages />
      <Switch>
        <AuthPrivateRouter path="/signup/workspaces" component={SelectWorkspace} infoIgnore />
        <AuthPrivateRouter path="/signup/welcome" component={SignupProfile} infoIgnore />
        <AuthPrivateRouter path="/signup/ticketing" component={SignupTicketing} />
        <AuthPrivateRouter path="/signup/messenger/design" component={SignupMessengerDesign} />
        <AuthPrivateRouter path="/signup/messenger/settings" component={SignupMessengerSettings} />
        <AuthPrivateRouter path="/signup/chat" component={SignupChat} />
        <AuthPrivateRouter path="/signup/bot-install" component={SignupBotInstall} />
        <AuthPrivateRouter path="/signup/synchronization" component={SignupSync} />
        <AuthPrivateRouter path="/signup/success" component={SignupSuccess} />
        <AuthPrivateRouter path="/dashboard" component={DashboardRoleProtected} />
        <Route path="/teams-app-preview" component={TeamsAppPreview} />
        <AuthPrivateRouter exact path="/" component={DashboardRoleProtected} />
        <Route exact path="/login" component={NoMatch} />
        <Route component={NoMatch} />
      </Switch>
      <Canny token={user.token} appID={cannyId} />
      {signInErrorMessage && <ErrorPage message={signInErrorMessage} />}
    </Fragment>
  );
};

Layout.propTypes = {
  user: PropTypes.objectOf(PropTypes.any),
  saveToken: PropTypes.func.isRequired,
  signInErrorMessage: PropTypes.string,
  getInfo: PropTypes.func.isRequired,
  isFetchUser: PropTypes.bool,
  saveAppId: PropTypes.func.isRequired
};

Layout.defaultProps = {
  user: {},
  signInErrorMessage: '',
  isFetchUser: false
};

const mapDispatchToProps = dispatch => ({
  saveToken: token => dispatch(saveToken(token)),
  saveAppId: props => dispatch(saveAppId(props)),
  getInfo: () => dispatch(getInfo())
});

const mapStateToProps = state => ({
  user: state.usersReducer.user,
  isFetchUser: state.usersReducer.isFetchUser,
  isFetchCompanies: state.workspaces.isFetchWorkspaces,
  companies: state.companiesReducer.companies,
  signInErrorMessage: state.usersReducer.signInErrorMessage
});

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
