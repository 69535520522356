import styled from 'styled-components/macro';
import SimpleLink from 'src/components/LinkButton';
import SimpleButton from 'src/components/SimpleButton';
import ImageAttachmentBlock from 'src/components/ImageAttachmentBlock';

export const Wrapper = styled.div`
  display: ${({ display }) => display ?? 'block'};
  margin-left: ${({ marginLeft }) => marginLeft ?? 0}px;
  margin-bottom: 20px;
`;

export const Logo = styled(ImageAttachmentBlock)`
  min-width: 500px;
  height: 75px;
`;

export const Icon = styled(ImageAttachmentBlock)`
  width: 90px;
  height: 75px;
`;

export const SubTitle = styled.h2`
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 22px;
  font-weight: bold;
  color: #000000;
`;

export const IconWrapper = styled.div`
  height: 75px;
  max-width: 90px;
  width: auto;
  object-fit: contain;
  overflow: hidden;
  label {
    padding: 4px;
  }
`;

export const LogoWrapper = styled.div`
  height: 75px;

  img {
    max-width: 145px;
  }

  label {
    padding: 12px;
  }
`;

export const ColorsList = styled.div`
  display: flex;
`;

export const AddColor = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 42px;
  border: 1px solid ${({ theme }) => theme.colors.accent.main};
  border-radius: 100%;
  background-color: #ffffff;
  transition: transform 0.2s;

  &:hover,
  &:focus {
    outline: none;
    transform: scale(1.1);
  }
`;

export const Color = styled.div`
  margin-right: 12px;

  label {
    position: relative;
    width: 42px;
    height: 42px;
    margin: 0;
    border-radius: 100%;
    background-color: ${({ fill }) => fill};
    cursor: pointer;
  }

  input {
    position: absolute;
    clip: rect(0 0 0 0);
    width: 1px;
    height: 1px;
    margin: -1px;
  }

  input:checked ~ label::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: calc(100% + 10px);
    height: calc(100% + 10px);
    margin-top: -26px;
    margin-left: -26px;
    border: 1px solid ${({ theme }) => theme.colors.brand.light.base};
    border-radius: 100%;
  }
`;

export const Button = styled(SimpleButton)`
  width: 100%;
  margin: 44px 0 20px;
`;

export const LinkButton = styled(SimpleLink)`
  display: block;
  margin: 0 auto;
`;

export const TrayIconHolder = styled.div`
  display: flex;
  padding: 16px;
  width: 68px;
  height: 68px;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
  border-radius: 100%;
  background: ${({ color }) => color};

  img {
    display: block;
    margin: auto;
    max-width: 40px;
  }
`;

export const DesktopIconHolder = styled.div`
  display: flex;
  padding: 10px;
  width: 68px;
  height: 68px;

  img {
    display: block;
    margin: auto;
    //max-width: 40px;
  }
`;

export const IconTooltip = styled.div`
  color: #999;
  font-size: 12px;
  margin-top: 10px;
`;
