import camelCase from 'lodash.camelcase';
import snakeCase from 'lodash.snakecase';

export const cwErrorsDataTransformer = data => ({
  host: data.host && data.host[0],
  hostV2: data.host_v2 && data.host_v2[0],
  companyName: data.company_name && data.company_name[0],
  publicKey: data.private_key && data.private_key[0],
  privateKey: data.public_key && data.public_key[0]
});

export const flowListTransformData = data =>
  data.map(item => ({
    ...item,
    id: item.id,
    name: item.name,
    type: item.type,
    entityType: item.entity_type,
    isCreateChannel: item.is_create_channel || false,
    isReplyBroadcast: item.is_reply_broadcast || false,
    lastRunTime: item.last_run_time,
    inboxChannelName: item.inbox_channel?.friendly_name || '',
    channelName: item.channel?.friendly_name || '',
    ofMessages: item.of_messages,
    status: item.is_active,
    isActive: item.is_active,
    filterData: item.filter_data,
    channelId: item.channel_id,
    inboxChannel: item.inbox_channel,
    channel: item.channel,
    notifications: item.notifications?.map(item => ({ code: item.code })) || []
  }));

export const flowErrorsTransformData = data => ({
  entityType: data.entity_type,
  filterData: data.filter_data,
  channel: data.channel_id
});

export const flowTransformData = data => ({
  actions: data.actions,
  channelId: data.channel_id,
  inboxChannel: data.inbox_channel,
  channel: data.channel,
  category: data.channel && { name: data.channel.category, id: data.channel.category_id },
  isCreateChannel: data.is_create_channel,
  isReplyBroadcast: data.is_reply_broadcast,
  createdAt: data.created_at,
  entityType: data.entity_type,
  filterData: data.filter_data && JSON.parse(data.filter_data),
  id: data.id,
  isActive: data.is_active,
  name: data.name,
  type: data.type,
  userId: data.user_id,
  notifications: data.notifications?.map(item => ({ code: item.code })) || []
});

export const groupingChannels = data => {
  const groups = data.reduce((reduceObj, item) => {
    const obj = reduceObj;
    const category = item.category.toLowerCase();
    obj[category] = obj[category] ? [...obj[category], item] : [item];
    return obj;
  }, {});

  return Object.keys(groups).map(group => ({ label: group, options: groups[group] }));
};

export const transformToCamelCase = data => {
  const newObject = {};
  Object.keys(data).forEach(key => {
    newObject[camelCase(key)] = data[key];
  });
  return newObject;
};

export const transformToSnakeCase = data => {
  const newObject = {};
  Object.keys(data).forEach(key => {
    newObject[snakeCase(key)] = data[key];
  });
  return newObject;
};
