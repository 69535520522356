import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as Sentry from '@sentry/react';
import startCase from 'lodash.startcase';
import useToast from 'src/hooks/useToast';
import { Checkbox } from '@Thread-Magic/jasmine';
import SimpleButton from 'src/components/SimpleButton';
import SimpleTextarea from 'src/components/SimpleTextarea';
import Alert from 'src/components/Alert';
import { psaTypes, languageOptions } from 'src/constants';
import queryKeys from 'src/constants/queryKeys';
import { chatgenieAPI } from 'src/config/api';
import { useMutation, useQuery } from 'react-query';
import { queryClient } from 'src/config/queryClient';
import { transformToCamelCase } from 'src/utils/data-transformer';
import { psaTranslate } from 'src/utils/psa';
import CustomSelect from './CustomSelect';
import SupportTypeSection from './SupportTypeSection';
import * as Styled from './style';
import MessengerWidget from './MessengerWidget';
import CustomMultiSelect from './CustomMultiSelect';
import Switcher from '../Switcher';

const paginatedSelectStyles = {
  control: state => ({
    background: state.isDisabled ? state.theme.colors.gray3 : state.background
  })
};

const MessengerSettings = ({ companyId, appId, parentCompanyId, psaType }) => {
  const { toast } = useToast();
  const [data, setData] = useState();
  const languageValueOption = data?.lang
    ? languageOptions.find(item => item.value === data?.lang)
    : languageOptions[0];
  const isParentCompany = () => parentCompanyId === companyId;
  const {
    data: messengerSettings,
    isLoading,
    error: parentCompanyError
  } = useQuery([queryKeys.GET_MESSENGER_SETTINGS, parentCompanyId], () =>
    chatgenieAPI.getMessengerSettings()
  );
  const {
    data: companyMessengerSettings,
    isLoading: isLoadingCompanyMessengerSettings,
    error: clientCompanyError
  } = useQuery(
    [queryKeys.GET_MESSENGER_SETTINGS, companyId],
    () => chatgenieAPI.getCompanyMessengerSettings(companyId),
    { enabled: !isParentCompany() }
  );

  const isLoadingAll = isLoading || isLoadingCompanyMessengerSettings || !data;
  const isActionButtonDisabled = isParentCompany()
    ? !data
    : !companyMessengerSettings?.id && !data?.overrideSettings;

  // We do not support Kaseya anymore, should we enabled this for all PSA types or remove?
  const isDefaultStatusVisible = psaType === psaTypes.KASEYA_BMS && isParentCompany();
  const isDefaultPriorityVisible = [psaTypes.AUTOTASK, psaTypes.HALO].includes(psaType);
  const isDefaultTicketTypeVisible = [psaTypes.HALO].includes(psaType);
  const isDefaultServiceSourceVisible = [psaTypes.CONNECTWISE, psaTypes.AUTOTASK].includes(psaType);
  const isContactTypeVisible = psaType === psaTypes.CONNECTWISE;
  const isHaloPsaType = [psaTypes.HALO].includes(psaType);

  const mutateSettings = useMutation(
    data => {
      if (isParentCompany()) {
        return chatgenieAPI.updateMessengerSettings(data);
      }
      if (companyMessengerSettings?.id) {
        return chatgenieAPI.updateCompanyMessengerSettings({ data, companyId });
      }
      return chatgenieAPI.createCompanyMessengerSettings({ data, companyId });
    },
    {
      onSuccess: res => {
        queryClient.setQueryData(
          [queryKeys.GET_MESSENGER_SETTINGS, isParentCompany() ? parentCompanyId : companyId],
          res
        );
        toast.success('Successfully saved');
      },
      onError: error => {
        let errorMessage = error.response?.data?.message || error.message;

        if (error.response?.data?.errors) {
          errorMessage = Object.values(error.response?.data?.errors).reduce(
            (acc, error) => `${acc} ${error.join(' ')}`,
            `${errorMessage} `
          );
        }

        toast.error(errorMessage);
      }
    }
  );

  const validateData = data => {
    if (!data.supportBoard) {
      toast.warn('Please select Support board');
      return false;
    }
    if (data.salesFlowEnabled && !data.salesBoard && isParentCompany()) {
      toast.warn('Please select Sales board');
      return false;
    }
    if (isDefaultServiceSourceVisible && !data.defaultServiceSource) {
      toast.warn('Please select Chat source');
      return false;
    }
    if (isDefaultPriorityVisible && !data.defaultPriority) {
      toast.warn('Please select Default priority');
      return false;
    }
    if (isDefaultStatusVisible && !data.defaultStatus) {
      toast.warn('Please select Default status');
      return false;
    }
    if (isDefaultTicketTypeVisible && !data.defaultTicketType) {
      toast.warn('Please select Ticket type');
      return false;
    }
    return true;
  };

  const getBoards = (query, page) =>
    chatgenieAPI.getBoards({ query, page, normalizer: res => res.data });
  const getContactTypes = (query, page) =>
    chatgenieAPI.getContactTypes({ query, page, normalizer: res => res.data });
  const getServiceSources = (query, page) =>
    chatgenieAPI.getSources({ query, page, normalizer: res => res.data });
  const getTicketTypes = (query, page) =>
    chatgenieAPI.getTicketTypes({ query, page, normalizer: res => res.data });
  const getPriorities = (query, page) =>
    chatgenieAPI.getPriorities({ query, page, normalizer: res => res.data });
  const getStatuses = (query, page) =>
    chatgenieAPI.getStatuses({
      query,
      page,
      boardId: data.supportBoard?.id,
      normalizer: res => res.data
    });

  const isMessengerConfigured = Boolean(
    !data?.supportBoard || (data?.salesFlowEnabled && !data?.salesBoard)
  );

  const onFormSubmit = event => {
    event.preventDefault();
    const isValid = validateData(data);
    if (!isValid) {
      return;
    }
    const normalizedData = {
      override_settings: data.overrideSettings,
      support_type: data.supportType,
      support_board_id: data.supportBoard?.id,
      contact_type_id: data.contactType?.id,
      reopen_status_id: data.reopenStatus?.id || null,
      ms_teams_error_status_id: data.msTeamsErrorStatus?.id || null,
      allow_new_contacts: data.allowNewContacts,
      proactive_messaging_status: data.proactiveMessagingStatus,
      messenger_excluded_boards: data.messengerExcludedBoards.map(board => board.id),
      lang: data.lang,
      override_bot_prompt_messages: data.overrideBotPromptMessages,
      bot_prompt_message_translations: data.botPromptMessageTranslations[data.lang],
      ...(isDefaultServiceSourceVisible && {
        default_service_source_id: data.defaultServiceSource?.id || null
      }),
      ...(isDefaultTicketTypeVisible && {
        default_ticket_type_id: data.defaultTicketType?.id || null
      }),
      ...(isDefaultPriorityVisible && {
        default_priority_id: data.defaultPriority?.id || null
      }),
      ...(isDefaultStatusVisible && {
        default_status_id: data.defaultStatus?.id || null
      }),
      ...(isParentCompany() && {
        sales_flow_enabled: data.salesFlowEnabled,
        sales_board_id: data.salesBoard?.id,
        email_service_source_id: data.emailServiceSource?.id,
        phone_service_source_id: data.phoneServiceSource?.id
      })
    };
    mutateSettings.mutate(normalizedData);
  };

  useEffect(() => {
    if (messengerSettings || companyMessengerSettings) {
      const settingsData = isParentCompany()
        ? messengerSettings
        : companyMessengerSettings?.id
        ? companyMessengerSettings
        : messengerSettings;

      const normalizedSettingsData = settingsData && {
        ...transformToCamelCase(settingsData),
        overrideSettings: companyMessengerSettings?.override_settings
      };
      setData(normalizedSettingsData);
    }
  }, [messengerSettings, companyMessengerSettings]);

  useEffect(() => {
    if (parentCompanyError || clientCompanyError) {
      const parentCompanyErrorMessage =
        parentCompanyError?.response?.data?.message || parentCompanyError?.message;
      const clientCompanyErrorMessage =
        clientCompanyError?.response?.data?.message || clientCompanyError?.message;
      const error = new Error(
        isParentCompany() ? parentCompanyErrorMessage : clientCompanyErrorMessage
      );
      Sentry.captureException(error);
      toast.error(error.message, {
        id: 'messenger-settings-fetch-error'
      });
    }
  }, [parentCompanyError, clientCompanyError]);

  return (
    <Styled.LocalPageHolder isLoading={isLoadingAll}>
      <Styled.Content>
        {!isLoadingAll && (
          <Styled.Body>
            <Styled.Form
              id="save-settings"
              onSubmit={onFormSubmit}
              disabled={!isParentCompany() && !data.overrideSettings}
            >
              {!isParentCompany() && (
                <>
                  <Styled.FormGroup>
                    <Checkbox
                      className="override-design"
                      id="override-design"
                      checked={Boolean(data.overrideSettings)}
                      onChange={() =>
                        setData(old => ({ ...old, overrideSettings: old.overrideSettings ? 0 : 1 }))
                      }
                    >
                      Override parent settings
                    </Checkbox>
                  </Styled.FormGroup>
                  <hr />
                </>
              )}
              {isMessengerConfigured && (
                <Alert type="warning">
                  Select {psaTranslate('board')}s and save your configuration to start using
                  Messenger.
                </Alert>
              )}
              <Styled.Title marginTop={10}>Messenger settings</Styled.Title>
              <Styled.FormGroup maxWidth="800px">
                <Styled.SubTitle>
                  Support Types<Styled.TitleLabel>New</Styled.TitleLabel>
                </Styled.SubTitle>
                <Styled.Description>
                  Select which support options you want to provide customers.
                </Styled.Description>
                <SupportTypeSection
                  value={data.supportType}
                  onChange={event => {
                    const value = event.target.id;
                    setData(old => ({ ...old, supportType: value }));
                  }}
                />
              </Styled.FormGroup>
              <Styled.FormGroup>
                <CustomSelect
                  name="support-board"
                  value={data.supportBoard}
                  options={getBoards}
                  onChange={option => {
                    setData(old => ({
                      ...old,
                      supportBoard: option,
                      reopenStatus: null,
                      defaultStatus: null,
                      msTeamsErrorStatus: null
                    }));
                  }}
                  label={`${isHaloPsaType ? 'Default' : 'Service'} ${psaTranslate('Board')}`}
                  componentType="paginate"
                />
              </Styled.FormGroup>
              {isContactTypeVisible && (
                <Styled.FormGroup>
                  <CustomSelect
                    name="contact-type"
                    value={data.contactType}
                    options={getContactTypes}
                    onChange={option => {
                      setData(old => ({
                        ...old,
                        contactType: option
                      }));
                    }}
                    label="Contact Type"
                    componentType="paginate"
                    description="Select which contact type should be used when new contact is created in PSA."
                  />
                </Styled.FormGroup>
              )}
              {isParentCompany() && (
                <>
                  <Styled.SubTitle>Capture leads from your website</Styled.SubTitle>
                  <Styled.FormGroup>
                    <Checkbox
                      className="sales-flow-enabled"
                      id="sales-flow-enabled"
                      checked={!!data.salesFlowEnabled}
                      onChange={() =>
                        setData(old => ({
                          ...old,
                          salesFlowEnabled: old.salesFlowEnabled ? 0 : 1
                        }))
                      }
                    >
                      Enable Sales flow to capture leads from your website.
                    </Checkbox>
                    {!!data.salesFlowEnabled && (
                      <Styled.InnerField>
                        <CustomSelect
                          name="sales-board"
                          value={data.salesBoard}
                          options={getBoards}
                          placeholder="Select a board for your website leads"
                          onChange={option =>
                            setData(old => ({
                              ...old,
                              salesBoard: option
                            }))
                          }
                          componentType="paginate"
                        />
                      </Styled.InnerField>
                    )}
                  </Styled.FormGroup>
                </>
              )}
              {isDefaultServiceSourceVisible && (
                <Styled.FormGroup>
                  <CustomSelect
                    name="chat-source"
                    value={data.defaultServiceSource}
                    options={getServiceSources}
                    onChange={option =>
                      setData(old => ({
                        ...old,
                        defaultServiceSource: option
                      }))
                    }
                    label="Chat source"
                    componentType="paginate"
                  />
                </Styled.FormGroup>
              )}
              {isDefaultPriorityVisible && (
                <Styled.FormGroup>
                  <CustomSelect
                    name="default-priority"
                    value={data.defaultPriority}
                    options={getPriorities}
                    onChange={option =>
                      setData(old => ({
                        ...old,
                        defaultPriority: option
                      }))
                    }
                    label="Default priority"
                    componentType="paginate"
                  />
                </Styled.FormGroup>
              )}
              {isDefaultStatusVisible && (
                <Styled.FormGroup>
                  <CustomSelect
                    name="default-status"
                    value={data.defaultStatus}
                    options={getStatuses}
                    isDisabled={!data.supportBoard}
                    customStyles={paginatedSelectStyles}
                    onChange={option =>
                      setData(old => ({
                        ...old,
                        defaultStatus: option
                      }))
                    }
                    label="Default status"
                    componentType="paginate"
                  />
                </Styled.FormGroup>
              )}
              {isDefaultTicketTypeVisible && (
                <Styled.FormGroup>
                  <CustomSelect
                    name="ticket-type"
                    value={data.defaultTicketType}
                    options={getTicketTypes}
                    onChange={option =>
                      setData(old => ({
                        ...old,
                        defaultTicketType: option
                      }))
                    }
                    label="Ticket types"
                    componentType="paginate"
                  />
                </Styled.FormGroup>
              )}
              {isParentCompany() && (
                <>
                  <Styled.FormGroup>
                    <CustomSelect
                      name="email-source"
                      title="email-source"
                      value={data.emailServiceSource}
                      options={getServiceSources}
                      onChange={option => {
                        setData(old => ({
                          ...old,
                          emailServiceSource: option
                        }));
                      }}
                      label="Email source"
                      componentType="paginate"
                    />
                  </Styled.FormGroup>
                  <Styled.FormGroup>
                    <CustomSelect
                      name="phone-source"
                      title="phone-source"
                      value={data.phoneServiceSource}
                      options={getServiceSources}
                      onChange={option => {
                        setData(old => ({
                          ...old,
                          phoneServiceSource: option
                        }));
                      }}
                      label="Phone source"
                      componentType="paginate"
                    />
                  </Styled.FormGroup>
                </>
              )}
              <Styled.FormGroup>
                <CustomSelect
                  key={data.supportBoard?.id}
                  name="reopen-status"
                  value={data.reopenStatus}
                  isDisabled={!data.supportBoard}
                  options={getStatuses}
                  customStyles={paginatedSelectStyles}
                  onChange={option =>
                    setData(old => ({
                      ...old,
                      reopenStatus: option
                    }))
                  }
                  label="Reopen Service Status"
                  description={`Select which Status from the ${
                    isHaloPsaType ? 'Default' : 'Service'
                  } 
                  ${psaTranslate('Board')} you’d like to use when a thread is re-opened.`}
                  componentType="paginate"
                />
              </Styled.FormGroup>
              <Styled.FormGroup>
                <CustomSelect
                  key={data.supportBoard?.id}
                  name="ms_teams_error_status"
                  value={data.msTeamsErrorStatus}
                  isDisabled={!data.supportBoard}
                  options={getStatuses}
                  customStyles={paginatedSelectStyles}
                  onChange={option =>
                    setData(old => ({
                      ...old,
                      msTeamsErrorStatus: option
                    }))
                  }
                  label="Messenger Error Status"
                  description="Select which status you’d like to use to open a ticket when there is an error with Messenger."
                  componentType="paginate"
                />
              </Styled.FormGroup>

              {!isParentCompany() && (
                <>
                  <Styled.SubTitle>New contacts</Styled.SubTitle>
                  <Styled.FormGroup>
                    <Checkbox
                      className="allow-contacts"
                      id="allow-contacts"
                      checked={Boolean(data.allowNewContacts)}
                      onChange={() =>
                        setData(old => ({ ...old, allowNewContacts: old.allowNewContacts ? 0 : 1 }))
                      }
                    >
                      Allow new contacts to open chats
                    </Checkbox>
                  </Styled.FormGroup>
                </>
              )}
              <Styled.FormGroup oneLine>
                <div>
                  <Styled.SubTitle>
                    Access all tickets in Messenger (formerly &quot;Proactive Messages&quot;)
                  </Styled.SubTitle>
                  <Styled.Description>
                    Contacts can access all their tickets, including those created by email and
                    phone, from Thread Messenger. Disabling this setting limits Messenger users to
                    access only Chat-created threads. We recommend enabling this setting.
                  </Styled.Description>
                </div>
                <Switcher
                  className="proactive-messages"
                  id="proactive-messages"
                  checked={data.proactiveMessagingStatus === 'enabled'}
                  controlText={{ checked: 'ON', unchecked: 'OFF' }}
                  onChange={() =>
                    setData(old => ({
                      ...old,
                      proactiveMessagingStatus:
                        old.proactiveMessagingStatus === 'enabled' ? 'disabled' : 'enabled'
                    }))
                  }
                />
              </Styled.FormGroup>
              {data.proactiveMessagingStatus === 'enabled' && (
                <Styled.FormGroup oneLine>
                  <span className="label">
                    {psaType === 'datto_autotask'
                      ? 'Exclude these queues'
                      : isHaloPsaType
                      ? 'Exclude these teams'
                      : 'Exclude these boards'}
                  </span>
                  <CustomMultiSelect
                    name="support-board"
                    value={data.messengerExcludedBoards}
                    options={getBoards}
                    onChange={option => {
                      setData(old => ({
                        ...old,
                        messengerExcludedBoards: option
                      }));
                    }}
                    componentType="paginate"
                  />
                </Styled.FormGroup>
              )}
              <Styled.FormGroup>
                <CustomSelect
                  key={data.lang}
                  name="default-language"
                  title="default-language"
                  value={languageValueOption}
                  options={languageOptions}
                  getOptionValue={option => option.value}
                  getOptionLabel={option => option.label}
                  onChange={option => setData(old => ({ ...old, lang: option.value }))}
                  label="Default language"
                />
              </Styled.FormGroup>

              <Styled.Title marginTop={32}>Bot settings</Styled.Title>
              <Styled.SubTitle>Custom Bot messages</Styled.SubTitle>
              <Styled.FormGroup>
                <Checkbox
                  className="override-prompts"
                  id="override-prompts"
                  checked={Boolean(data.overrideBotPromptMessages)}
                  onChange={() =>
                    setData(old => ({
                      ...old,
                      overrideBotPromptMessages: old.overrideBotPromptMessages ? 0 : 1
                    }))
                  }
                >
                  Override initial bot prompts
                </Checkbox>
              </Styled.FormGroup>
              {!!data.overrideBotPromptMessages && (
                <Styled.FormGroup>
                  {Object.keys(data.botPromptMessageTranslations[data.lang]).map(key => (
                    <Styled.FormGroup key={key}>
                      <Styled.SubTitle>{startCase(key)}</Styled.SubTitle>
                      <SimpleTextarea
                        id={key}
                        autoresize
                        type="text"
                        name={key}
                        value={data.botPromptMessageTranslations[data.lang][key]}
                        placeholder={data.botPromptMessageTranslations[data.lang][key]}
                        onChange={event => {
                          const { value } = event.target;
                          setData(old => ({
                            ...old,
                            botPromptMessageTranslations: {
                              ...old.botPromptMessageTranslations,
                              [data.lang]: {
                                ...data.botPromptMessageTranslations[data.lang],
                                [key]: value
                              }
                            }
                          }));
                        }}
                      />
                      {!data.botPromptMessageTranslations[data.lang][key] && (
                        <Styled.InputError>This is a required field</Styled.InputError>
                      )}
                    </Styled.FormGroup>
                  ))}
                </Styled.FormGroup>
              )}
              <SimpleButton
                isLoader={mutateSettings.isLoading}
                type="submit"
                isDisabled={isActionButtonDisabled}
                formId="save-settings"
                size="small"
              >
                Save
              </SimpleButton>
            </Styled.Form>
          </Styled.Body>
        )}
        {!isLoadingAll && (
          <Styled.Aside>
            <MessengerWidget appId={appId} supportType={data.supportType} />
          </Styled.Aside>
        )}
      </Styled.Content>
    </Styled.LocalPageHolder>
  );
};

MessengerSettings.propTypes = {
  companyId: PropTypes.number.isRequired,
  parentCompanyId: PropTypes.number.isRequired,

  appId: PropTypes.string.isRequired,
  psaType: PropTypes.string
};

MessengerSettings.defaultProps = {
  psaType: null
};

const mapStateToProps = state => ({
  psaType: state.usersReducer.user.psaType,
  parentCompanyId: state.usersReducer.user.company.id
});

export default connect(mapStateToProps)(MessengerSettings);
