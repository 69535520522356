import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import Messenger from 'src/pages/Messenger';
import IntegratedNavigation from 'src/components/IntegratedNavigation';
import Copilot from 'src/pages/Copilot';
import AppDeployer from 'src/pages/AppDeployer';
import DeploymentCenter from 'src/pages/DeploymentCenter';
import useLogout from 'src/hooks/useLogout';
import Navigation from '../../components/Navigation';
import PageWrapper from '../../components/PageWrapper';
import UserInfo from '../../components/UserInfo';
import { getCompanies } from '../../redux/modules/companies/actions';
import ChatIntegrationsEditor from '../ChatIntegrationsEditor';
import Clients from '../Clients';
import Company from '../Company';
import ConfigureFlow from '../ConfigureFlow';
import Feedback from '../Feedback';
import Flows from '../Flows';
import Integrations from '../Integrations';
import IntegrationsEditor from '../IntegrationsEditor';
import Members from '../Members';
import Templates from '../Templates';
import * as Styled from './style';
import AuditLogs from '../AuditLogs';

const Dashboard = ({ user, isAdmin, history, location }) => {
  const { logout, switchWorkspace } = useLogout();
  const { featureFlags } = user;

  useEffect(() => {
    if (!user.isFlows && location.pathname === '/dashboard/flows') {
      history.replace('/dashboard/templates');
    }
  }, []);

  useEffect(() => {
    window.chatgenieHooks = window.chatgenieHooks || {};

    const { email, fullname, company } = user;

    if (!user.email) {
      return;
    }

    if (window.chatgenie?.boot) {
      window.chatgenie.boot({
        fullname,
        email,
        companyName: company?.name
      });
    } else {
      window.chatgenieHooks.onLoad = () => {
        if (window.chatgenie?.boot) {
          window.chatgenie.boot({
            fullname,
            email,
            companyName: company?.name
          });
        }
      };
    }
  }, [user]);

  return (
    <Styled.Layout>
      <Styled.Sidebar>
        <Styled.Title to="/">Admin</Styled.Title>
        <IntegratedNavigation appId={user.appId} />
        <Navigation featureFlags={featureFlags} isAdmin={isAdmin} />
        <UserInfo
          data={user}
          items={[
            {
              id: 'switch-workspace',
              title: 'Switch Workspace',
              handler: () => {
                switchWorkspace();
                history.push('/signup/workspaces');
              }
            },
            {
              id: 'sign-out',
              title: 'Sign Out',
              handler: () => {
                logout();
              }
            }
          ]}
        />
      </Styled.Sidebar>
      <Styled.Wrapper>
        <Styled.Content>
          {isAdmin && (
            <Switch>
              <Route path="/dashboard/messenger/settings" component={Messenger} />
              <Route path="/dashboard/flows/configure-flow" component={ConfigureFlow} />
              <Route path="/dashboard/flows" component={Flows} />
              <Route path="/dashboard/audit-logs" component={AuditLogs} />
              <Route path="/dashboard/templates/create-flow" component={ConfigureFlow} />
              <Route path="/dashboard/templates" component={Templates} />
              <Route path="/dashboard/magic" render={({ match }) => <Copilot match={match} />} />
              <Route path="/dashboard/deployment-assist" component={AppDeployer} />

              <Route path="/dashboard/deployment-center" component={DeploymentCenter} />
              <Route
                path="/dashboard/clients/companies/:company"
                render={({ match, location }) => (
                  <Company match={match} location={location} user={user} />
                )}
              />
              <Route
                path="/dashboard/clients"
                render={({ match }) => <Clients match={match} user={user} />}
              />
              <Route
                path="/dashboard/feedback"
                render={({ match }) => (
                  <PageWrapper
                    title="Feedback"
                    tabs={[
                      { id: 1, path: `${match.url}/feature-requests`, name: 'Feature requests' },
                      { id: 2, path: `${match.url}/integrations`, name: 'Integration requests' }
                    ]}
                  >
                    <Switch>
                      <Redirect exact from={match.path} to={`${match.path}/feature-requests`} />
                      <Route
                        path={`${match.path}/feature-requests`}
                        render={() => (
                          <Feedback boardToken={process.env.REACT_APP_CANNY_FEATURE_TOKEN} />
                        )}
                      />
                      <Route
                        path={`${match.path}/integrations`}
                        render={() => (
                          <Feedback boardToken={process.env.REACT_APP_CANNY_INTEGRATIONS_TOKEN} />
                        )}
                      />
                    </Switch>
                  </PageWrapper>
                )}
              />
              <Route path="/dashboard/members" component={Members} />
              <Route
                path="/dashboard/integrations/chat/:platform"
                component={ChatIntegrationsEditor}
              />
              <Route path="/dashboard/integrations/:platform" component={IntegrationsEditor} />
              <Route path="/dashboard/integrations" component={Integrations} />
              <Route render={() => <Redirect to="/dashboard/flows" />} />
            </Switch>
          )}
        </Styled.Content>
      </Styled.Wrapper>
    </Styled.Layout>
  );
};

Dashboard.propTypes = {
  user: PropTypes.objectOf(PropTypes.any).isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  isAdmin: PropTypes.bool
};

Dashboard.defaultProps = {
  isAdmin: false
};

const mapDispatchToProps = dispatch => ({
  getCompanies: () => dispatch(getCompanies())
});

const mapStateToProps = state => ({
  user: state.usersReducer.user,
  isAdmin: state.usersReducer.user.roles.admin,
  signInErrorMessage: state.usersReducer.signInErrorMessage
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
