import axios from 'axios';

import { URL_API } from '../../../constants';
import { getHeaders } from '../../../utils';
import {
  actionStatuses,
  chatsTypes,
  flowsTypes,
  usersTypes
} from '../../../constants/action-types';

export const getInfo = () => (dispatch, getState) =>
  dispatch({
    type: usersTypes.SIGN_IN,
    payload: axios({
      method: 'GET',
      url: `${URL_API}/api/users/info`,
      headers: getHeaders(getState)
    })
  });

export const saveToken = token => ({
  type: usersTypes.SAVE_TOKEN,
  payload: { token }
});

export const saveAppId = ({ appId, token }) => ({
  type: usersTypes.SAVE_APP_ID,
  payload: { appId, token }
});

export const setPartialUserData = payload => ({
  type: usersTypes.SET_PARTIAL_USER_DATA,
  payload
});

export const createProfile = data => (dispatch, getState) => {
  const formData = new FormData();
  Object.keys(data).forEach(key => {
    formData.append(key, data[key]);
  });
  return dispatch({
    type: usersTypes.CREATE_PROFILE,
    payload: async () =>
      axios({
        method: 'POST',
        url: `${URL_API}/api/signup/profile`,
        headers: { ...getHeaders(getState), 'Content-Type': 'multipart/form-data' },
        data: formData
      }).then(response => {
        // This will help skip chat sign up during onboarding but still allow
        // chat integration later from the admin (or in case of reintegration)
        dispatch({
          type: `${chatsTypes.UPDATE_CHAT_CONFIGURATION}${actionStatuses.FULFILLED}`,
          payload: response
        });
        dispatch(
          saveAppId({
            appId: response.data.data.app_id,
            token: getState().usersReducer.user.token
          })
        );
      })
  });
};

export const clearUserData = () => ({
  type: usersTypes.CLEAR_USER_DATA
});

export const setIsFlows = () => ({
  type: usersTypes.SET_IS_FLOWS
});

export const setFlowItemsReady = payload => ({
  type: flowsTypes.FLOW_ITEMS_READY,
  payload
});

export const signOut = () => dispatch => {
  localStorage.clear();
  window.location.reload();
  dispatch({ type: usersTypes.SIGN_OUT });
};

export const executeSlackAction = (companyId, data) => (dispatch, getState) =>
  axios({
    method: 'POST',
    url: `${URL_API}/api/v1/companies/${companyId}/slack/actions`,
    data,
    headers: getHeaders(getState)
  });

export const getSlackUsers =
  ({ query, page, companyId } = {}) =>
  (dispatch, getState) =>
    axios({
      method: 'GET',
      url: `${URL_API}/api/v1/companies/${companyId}/slack/users?page=${page || 1}${
        query ? `&query=${query}` : ''
      }`,
      headers: getHeaders(getState)
    });

export const getCannyToken = () => (dispatch, getState) =>
  dispatch({
    type: usersTypes.GET_CANNY_TOKEN,
    payload: axios({
      method: 'GET',
      url: `${URL_API}/api/canny/token`,
      headers: getHeaders(getState)
    })
  });

export const inviteMember = data => (dispatch, getState) => {
  dispatch({
    type: usersTypes.INVITE_MEMBER,
    payload: axios({
      method: 'POST',
      url: `${URL_API}/api/users`,
      headers: getHeaders(getState),
      data
    })
  });
};

export const clearInviteMemberError = () => ({
  type: usersTypes.CLEAR_INVITE_MEMBER_ERROR
});
