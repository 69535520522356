import React, { useEffect } from 'react';
import 'rc-notification/assets/index.css';
import Notification from 'rc-notification';

import { ReactComponent as Success } from 'src/assets/icons/message-success.svg';
import { ReactComponent as Error } from 'src/assets/icons/message-error.svg';
import { ReactComponent as Warning } from 'src/assets/icons/message-warning.svg';

import { MessageStyled } from './style';

let notification = null;
Notification.newInstance({}, message => (notification = message));

const messages = {
  success: content => (
    <MessageStyled>
      <Success />
      {content}
    </MessageStyled>
  ),
  error: content => (
    <MessageStyled>
      <Error />
      {content}
    </MessageStyled>
  ),
  warning: content => (
    <MessageStyled>
      <Warning />
      {content}
    </MessageStyled>
  )
};

const message = (type, content) => {
  notification.notice({
    content: messages[type](content),
    duration: 2
  });
};

export const useNotifications = (notifications = [], clearNotifications = () => {}) => {
  useEffect(() => {
    notifications.forEach(item => {
      if (item.errors) {
        Object.keys(item.errors).forEach(key => message(item.type, item.errors[key]));
      } else {
        message(item.type, item.label);
      }
    });

    if (notifications.length) {
      setTimeout(() => {
        clearNotifications();
      }, 2000);
    }
  }, [notifications]);
};
