import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Switch, Route, withRouter } from 'react-router-dom';

import MessengerSettings from 'src/components/MessengerSettings';
import MessengerDesign from 'src/components/MessengerDesign';
import Installation from 'src/containers/Installation';
import Hours from 'src/containers/Hours';
import TeamsApps from 'src/containers/TeamsApps';
import TeamsBuilder from 'src/components/TeamsBuilder';
import ServicePageBuilder from 'src/components/ServicePageBuilder';
import PageWrapper from 'src/components/PageWrapper';

const Messenger = ({ match, company }) => (
  <PageWrapper
    title="Messenger"
    tabs={[
      { id: 1, path: match.url, name: 'Settings' },
      { id: 2, path: `${match.url}/design`, name: 'Design' },
      { id: 3, path: `${match.url}/hours`, name: 'Hours' },
      { id: 4, path: `${match.url}/installation`, name: 'Installation' },
      { id: 5, path: `${match.url}/apps`, name: 'Teams Apps' }
    ]}
  >
    <Switch>
      <Route
        path={`${match.path}/installation`}
        render={() => <Installation appId={company.appId} />}
      />
      <Route path={`${match.path}/hours`} render={() => <Hours companyId={company.id} />} />
      <Route
        path={`${match.path}/apps/service-page-builder/:teamsAppId`}
        render={props => <ServicePageBuilder parentCompanyId={company.id} {...props} />}
      />
      <Route
        path={`${match.path}/apps/service-page-builder`}
        render={props => <ServicePageBuilder parentCompanyId={company.id} {...props} />}
      />
      <Route
        path={`${match.path}/apps/teams-app-builder/:teamsAppId`}
        render={props => <TeamsBuilder parentCompanyId={company.id} {...props} />}
      />
      <Route
        path={`${match.path}/apps/teams-app-builder`}
        render={props => <TeamsBuilder parentCompanyId={company.id} {...props} />}
      />
      <Route
        path={`${match.path}/apps`}
        render={props => <TeamsApps companyId={company.id} {...props} />}
      />
      <Route
        path={`${match.path}/design`}
        render={() => <MessengerDesign companyId={company.id} appId={company.appId} />}
      />
      <Route
        path={match.path}
        render={() => <MessengerSettings companyId={company.id} appId={company.appId} />}
      />
    </Switch>
  </PageWrapper>
);

Messenger.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string,
    path: PropTypes.string
  }),
  company: PropTypes.shape({
    id: PropTypes.number,
    appId: PropTypes.string
  })
};

Messenger.defaultProps = {
  match: null,
  company: {}
};

const mapStateToProps = state => ({
  company: state.usersReducer.user.company
});

export default withRouter(connect(mapStateToProps)(Messenger));
