import React from 'react';
import { FiTrash2 } from 'react-icons/fi';
import SimpleInput from 'src/components/SimpleInput';
import SimpleTextarea from 'src/components/SimpleTextarea';
import SimpleSelect from 'src/components/SimpleSelect';
import { IntentArgumentWrapper, IntentField, IntentFieldGroup } from './style';

export const argumentTypes = [{ label: 'Text', value: 'text' }];

export default function IntentArgument({ onRemove, data, onChange }) {
  return (
    <IntentArgumentWrapper>
      <button type="button" onClick={onRemove}>
        <FiTrash2 />
      </button>
      <IntentFieldGroup>
        <IntentField>
          <p>Name</p>
          <SimpleInput
            value={data.name}
            onChange={e => {
              onChange({ ...data, name: e.target.value });
            }}
            placeholder="Name of argument"
          />
        </IntentField>
        <IntentField>
          <p>Question</p>
          <SimpleTextarea
            value={data.description}
            onChange={e => {
              onChange({ ...data, description: e.target.value });
            }}
            placeholder="Question of argument"
          />
        </IntentField>
        <IntentField>
          <p>Type</p>
          <SimpleSelect
            placeholder="Type of argument"
            value={data.type}
            onChange={value => {
              onChange({ ...data, type: value });
            }}
            options={argumentTypes}
          />
        </IntentField>
      </IntentFieldGroup>
    </IntentArgumentWrapper>
  );
}
