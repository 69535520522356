import styled from 'styled-components/macro';
import { ReactComponent as SuccessIcon } from 'src/assets/icons/success-2.svg';

export const HelpLink = styled.a`
  position: absolute;
  left: -12px;
  top: 6px;
  display: flex;
  align-items: center;
  fill: ${({ theme }) => theme.colors.gray5};
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.gray6};
  transition: all 0.2s;

  svg {
    width: 14px;
    height: 14px;
    margin-right: 6px;
  }

  &:focus,
  &:hover {
    fill: ${({ theme }) => theme.colors.gray5};
    color: ${({ theme }) => theme.colors.gray6};
  }
`;

export const Figure = styled.div`
  display: flex;
  align-items: center;
  margin: 40px 0 2px;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: ${({ theme }) => theme.colors.gray6};
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.h5`
  display: flex;
  align-items: center;
  margin: 0;
  margin-top: -6px;
  font-weight: 600;
  font-size: 20px;
  line-height: 56px;
  color: ${({ theme }) => theme.colors.text.default};
`;

export const Subtitle = styled.p`
  display: flex;
  align-items: center;
  text-align: center;
  margin-bottom: 28px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.text.default};
`;

export const List = styled.ul`
  max-width: 600px;
  margin: 0;
  padding: 0;
`;

export const Item = styled.li`
  display: flex;
  margin-bottom: 26px;
  list-style: none;
`;

export const Step = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 38px;
  height: 38px;
  margin-right: 18px;
  border-radius: 38px;
  font-weight: 600;
  font-size: 16px;
  background-color: ${({ theme }) => theme.colors.accent.light};
  color: ${({ theme }) => theme.colors.blue5};
`;

export const Content = styled.p`
  margin: 0;

  a {
    font-weight: 600;
    text-decoration: none;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
`;

export const Caption = styled.span`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: ${({ theme }) => theme.colors.text.default};
`;

export const Description = styled.p`
  margin: 0;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.gray6};
`;

export const ActionWrapper = styled.div`
  margin-top: 26px;
`;

export const Success = styled(SuccessIcon)`
  margin-bottom: 10px;
`;
