import React, { useEffect, useRef, useState } from 'react';
import AutoExpandTextarea from 'src/components/AutoExpandTextarea';
import { FiX } from 'react-icons/fi';
import PropTypes from 'prop-types';
import { ReactComponent as Spinner } from 'src/assets/icons/spinner.svg';
import { useMutation } from 'react-query';
import { chatgenieAPI } from 'src/config/api';
import useToast from 'src/hooks/useToast';
import { Checkbox } from '@Thread-Magic/jasmine';
import * as Styled from '../../style';
import PrioritySelectField from './PrioritySelectField';
import { addRuleIntoCache, removeRuleFromCache, updateCachedRule } from '../utils';
import usePrioritizationConfig from '../hooks/usePrioritizationConfig';

function ExceptionItem({ data, onSave, onRemove, autoFocusInput }) {
  const [rule, setRule] = useState(data);
  const { selectedSla } = usePrioritizationConfig();
  const { toast } = useToast();
  const textInputRef = useRef();
  const isSaveButtonVisible =
    rule.text !== data.text ||
    rule.selected_priority?.id !== data.selected_priority?.id ||
    !data.id ||
    rule.ignore !== data.ignore;
  const isSaveButtonDisabled = !rule.text || !rule.selected_priority?.id;
  const { mutate: update, isLoading: isUpdating } = useMutation(
    chatgenieAPI.updateCopilotPriorityRules,
    {
      onSuccess: () => {
        updateCachedRule(rule, { slaId: selectedSla?.id });
        toast.success(`Successfully saved rule!`);
      },
      onError(err) {
        toast.error(`Failed to save rule: ${err?.response?.data?.message || err?.message}`);
      }
    }
  );
  const { mutate: create, isLoading: isCreating } = useMutation(
    chatgenieAPI.createCopilotPriorityRule,
    {
      onSuccess: res => {
        toast.success(`Successfully saved rule!`);
        addRuleIntoCache({ ...rule, id: res.id }, { slaId: selectedSla?.id });
        onSave(res);
      },
      onError(err) {
        toast.error(`Failed to save rule: ${err?.response?.data?.message || err?.message}`);
      }
    }
  );
  const { mutate: deleteException, isLoading: isDeleting } = useMutation(
    chatgenieAPI.deleteCopilotPriorityRule,
    {
      onSuccess: res => {
        toast.success(`Successfully deleted rule!`);
        removeRuleFromCache(rule, { slaId: selectedSla?.id });
        onRemove(res);
      },
      onError(err) {
        toast.error(`Failed to delete rule: ${err?.response?.data?.message || err?.message}`);
      }
    }
  );

  const handleSave = () => {
    if (data.id) {
      update({
        conditions: [
          {
            condition_id: data.id,
            priority_id: rule.selected_priority.id,
            action: null,
            text: rule.text,
            ignore: rule.ignore ?? false,
            service_level_agreement_id: selectedSla?.id
          }
        ]
      });
    } else {
      create({
        priority_id: rule.selected_priority.id,
        text: rule.text,
        ignore: rule.ignore ?? false,
        service_level_agreement_id: selectedSla?.id
      });
    }
  };

  const handleDelete = () => {
    if (data.id) {
      deleteException(data.id);
    } else {
      onRemove();
    }
  };

  useEffect(() => {
    if (autoFocusInput && textInputRef.current) {
      textInputRef.current.focus();
    }
  }, []);

  return (
    <Styled.ExceptionItem tabIndex={-1}>
      <AutoExpandTextarea
        value={rule.text}
        textareaRef={textInputRef}
        placeholder="Write a prompt here"
        borderOnHover={false}
        onChange={e => {
          const { value } = e.target;
          setRule(old => ({ ...old, text: value }));
        }}
      />
      <PrioritySelectField
        value={rule.selected_priority}
        onChange={value => setRule(old => ({ ...old, selected_priority: value }))}
        noBorder
        isDisabled={rule.ignore}
      />
      {isSaveButtonVisible ? (
        <Styled.ExceptionSaveButton
          disabled={isSaveButtonDisabled || isUpdating || isCreating}
          onClick={handleSave}
        >
          {isCreating || isUpdating ? <Spinner color="#fff" /> : 'Save'}
        </Styled.ExceptionSaveButton>
      ) : (
        <span />
      )}
      <Styled.ExceptionRemoveButton onClick={handleDelete} disabled={isDeleting}>
        {isDeleting ? <Spinner /> : <FiX />}
      </Styled.ExceptionRemoveButton>
      <Checkbox
        checked={rule.ignore}
        onChange={e => {
          const { checked } = e.target;
          setRule(old => ({ ...old, ignore: checked }));
        }}
        id={rule.id}
      />
    </Styled.ExceptionItem>
  );
}

ExceptionItem.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    text: PropTypes.string,
    selected_priority: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      color: PropTypes.string
    }),
    ignore: PropTypes.bool,
    isNew: PropTypes.bool
  }),
  onSave: PropTypes.func,
  autoFocusInput: PropTypes.bool,
  onRemove: PropTypes.func
};

ExceptionItem.defaultProps = {
  onSave: () => {},
  onRemove: () => {},
  data: {},
  autoFocusInput: false
};

export default ExceptionItem;
