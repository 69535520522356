export const URL_API = process.env.REACT_APP_API_URL;
export const BUSINESS_MESSENGER_URL = process.env.REACT_APP_BUSINESS_MESSSENGER_URL;
export const psaTypes = {
  KASEYA_BMS: 'kaseya_bms',
  INTERNAL_PSA: 'internal_psa',
  CONNECTWISE: 'connectwise',
  AUTOTASK: 'datto_autotask',
  HALO: 'halo_psa'
};

// These are used only for Core PSA auth API
// Ideally we should migrate these to `psaTypes` listed above
export const TICKET_SERVICE_TYPES = {
  CONNECTWISE: 'CONNECTWISE',
  KASEYA: 'BMS',
  AUTOTASK: 'AUTOTASK',
  CG: 'CG',
  HALO: 'HALO'
};

export const INVITATION_TYPES = {
  SLACK: 'slack',
  MS_TEAMS: 'ms_teams',
  WINDOWS: 'windows'
};

export const languageOptions = [
  { id: 1, value: 'en', label: 'English' },
  { id: 2, value: 'fr', label: 'French' }
];
