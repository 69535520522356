import { JasmineThemeProvider, theme as jasmineTheme } from '@Thread-Magic/jasmine';
import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { usePostHog } from 'posthog-js/react';
import { ThemeProvider } from 'styled-components';
import { withPostHogProvider } from 'src/components/PostHogProvider';
import { Auth0Provider } from 'src/components/Auth0';
import { getUrlParam, setLocalStorage } from 'src/utils';
import Layout from '../Layout';

const Root = ({ history }) => {
  const posthog = usePostHog();

  useEffect(() => {
    const appId = getUrlParam('app_id');

    // IMPORTANT: Inbox uses `appId` key to track workspace instead of `app`
    if (appId) {
      setLocalStorage({ app: { id: appId } });
    }
  }, []);

  useEffect(() => {
    history.listen(() => {
      posthog?.capture('$pageview');
    });
  }, [history]);

  return (
    <Auth0Provider history={history} screenMode={getUrlParam('screen')}>
      <JasmineThemeProvider themeMode="light">
        <ThemeProvider theme={{ ...jasmineTheme.light }}>
          <Layout />
        </ThemeProvider>
      </JasmineThemeProvider>
    </Auth0Provider>
  );
};

export default withPostHogProvider(withRouter(Root));
