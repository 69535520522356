import * as yup from 'yup';
import React from 'react';

const helpDocsLink = 'https://docs.getthread.com/article/wsyfhsd74u-creating-a-kaseya-bms-api-user';
const hostOptions = [
  {
    label: 'https://api.bms.kaseya.com',
    value: 'https://api.bms.kaseya.com'
  },
  {
    label: 'https://api.bmsemea.kaseya.com',
    value: 'https://api.bmsemea.kaseya.com'
  },
  {
    label: 'https://api.bmsapac.kaseya.com',
    value: 'https://api.bmsapac.kaseya.com'
  },
  {
    label: 'https://api.vorexlogin.kaseya.com',
    value: 'https://api.vorexlogin.kaseya.com'
  }
];
const fields = {
  HOST: {
    id: 'host',
    name: 'host',
    label: 'Host',
    placeholder: 'e.g. http://company.bms.kaseya.com',
    helpTooltip: 'Same host name you use to login into the portal',
    fieldKey: 'host'
  },
  HOSTV2: {
    id: 'hostV2',
    name: 'hostV2',
    label: 'API Host',
    placeholder: 'Select your API server',
    helpTooltip: 'Each server has a dedicated API host based on the region',
    fieldType: 'select',
    fieldOptions: hostOptions,
    fieldKey: 'host_v2'
  },
  COMPANY_NAME: {
    id: 'companyName',
    name: 'companyName',
    label: 'Company Name',
    placeholder: 'Name of your company',
    fieldKey: 'company_name',
    helpTooltip: 'Same as the company name you use to login into the portal'
  },
  USERNAME: {
    id: 'username',
    name: 'username',
    label: 'Username',
    placeholder: 'Your Kaseya username',
    fieldKey: 'username',
    helpTooltip: (
      <>
        Follow{' '}
        <a href={helpDocsLink} rel="noopener noreferrer" target="_blank">
          this documentation
        </a>{' '}
        for more details
      </>
    )
  },
  PASSWORD: {
    id: 'password',
    name: 'password',
    type: 'password',
    label: 'Password',
    placeholder: 'Your Kaseya password',
    fieldKey: 'password',
    helpTooltip: (
      <>
        Follow{' '}
        <a href={helpDocsLink} rel="noopener noreferrer" target="_blank">
          this documentation
        </a>{' '}
        for more details
      </>
    )
  }
};
const schema = yup
  .object({
    [fields.HOST.name]: yup.string().required().label(fields.HOST.label).default(''),
    [fields.HOSTV2.name]: yup.object().required().label(fields.HOSTV2.label),
    [fields.COMPANY_NAME.name]: yup
      .string()
      .required()
      .label(fields.COMPANY_NAME.label)
      .default(''),
    [fields.USERNAME.name]: yup.string().required().label(fields.USERNAME.label).default(''),
    [fields.PASSWORD.name]: yup.string().required().label(fields.PASSWORD.label).default('')
  })
  .required();

export const FORM_ID = 'kaseya-form';

export default {
  key: 'KASEYA',
  fields,
  schema,
  helpDocsLink,
  logo: { src: '/images/kaseya-logo.png', width: 64, alt: 'Kaseya logo' },
  formId: FORM_ID
};
