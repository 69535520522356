import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';

import { ReactComponent as Success } from 'src/assets/icons/message-success.svg';
import { ReactComponent as Error } from 'src/assets/icons/message-error.svg';
import { ReactComponent as Warning } from 'src/assets/icons/message-warning.svg';

import { clearDialog } from '../../redux/modules/dialog/dialogActions';
import { useDialog } from './hook';
import { OverlayStyled, MessageStyled, TextStyled } from './style';

const icons = {
  success: <Success fill="#52C41A" />,
  error: <Error />,
  warning: <Warning />
};

const Dialog = ({ text, type, isVisible, clearDialog }) => {
  useDialog({ text, type, isVisible, clearDialog });

  return (
    <CSSTransition in={isVisible} timeout={300} classNames="dialog" unmountOnExit>
      <OverlayStyled>
        <MessageStyled>
          {icons[type]}
          <TextStyled>{text}</TextStyled>
        </MessageStyled>
      </OverlayStyled>
    </CSSTransition>
  );
};

Dialog.propTypes = {
  text: PropTypes.string,
  type: PropTypes.string,
  isVisible: PropTypes.bool,
  clearDialog: PropTypes.func
};

Dialog.defaultProps = {
  text: '',
  type: '',
  isVisible: false,
  clearDialog: () => {}
};

const mapStateToProps = state => ({
  text: state.dialogReducer.text,
  type: state.dialogReducer.type,
  isVisible: state.dialogReducer.isVisible
});

function mapDispatchToProps(dispatch) {
  return {
    clearDialog: () => dispatch(clearDialog())
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Dialog);
