import React, { Fragment, useMemo } from 'react';
import useAuthUser from 'src/hooks/useAuthUser';
import PageWrapper from 'src/components/PageWrapper';
import { Route, Switch, Redirect, useRouteMatch } from 'react-router-dom/';
import CopilotCategorization from 'src/components/Copilot/CopilotCategorization';
import CopilotRecap from 'src/components/Copilot/CopilotRecap';
import PropTypes from 'prop-types';
import theme from 'src/config/theme';
import CopilotPrioritization from 'src/components/Copilot/CopilotPrioritization';
import CopilotGenerateTitle from 'src/components/Copilot/CopilotGenerateTitle';
import AgentWithChat from 'src/components/Copilot/Agent';
import IntentScreen from 'src/components/Copilot/Agent/IntentScreen';
import SimpleButton from 'src/components/SimpleButton';
import { useIsMutating } from 'react-query';

const Copilot = ({ match }) => {
  const { featureFlags, company } = useAuthUser();
  const isIntentScreen = useRouteMatch('/dashboard/magic/agent/*');
  const isSettingsScreen = useRouteMatch('/dashboard/magic/agent/settings');
  const isAgentEnabled = featureFlags.includes('magic-agent');
  const isCopilotEnabled = featureFlags.includes('copilot');
  const isCategorizationEnabled = featureFlags.includes('copilot_categorization');
  const isRecapEnabled = featureFlags.includes('copilot_summarize_thread');
  const isPrioritizationEnabled = featureFlags.includes('new_copilot_prioritization');
  const isGenerateTitleEnabled = featureFlags.includes('copilot_generate_title');
  const tabs = [
    ...(isPrioritizationEnabled
      ? [{ id: 1, path: `${match.url}/prioritization`, name: 'Prioritization' }]
      : []),
    ...(isCategorizationEnabled
      ? [{ id: 2, path: `${match.url}/categorization`, name: 'Categorization' }]
      : []),
    ...(isRecapEnabled ? [{ id: 3, path: `${match.url}/recap`, name: 'Recap' }] : []),
    ...(isGenerateTitleEnabled ? [{ id: 4, path: `${match.url}/title`, name: 'Title' }] : []),
    ...(isAgentEnabled
      ? [{ id: 5, path: `${match.url}/agent`, name: 'Agent', isNested: true }]
      : [])
  ];

  const isMutating = useIsMutating(['upsertIntent']);

  const pageWrapperProps = useMemo(() => {
    if (isIntentScreen && !isSettingsScreen) {
      return {
        title: 'Agent intent',
        tabs: [],
        padding: 0,
        isBackButton: true,
        tools: (
          <SimpleButton onClick={() => {}} isLoader={!!isMutating} form="intent-form">
            Save
          </SimpleButton>
        )
      };
    }
    return {
      title: 'Magic AI',
      tabs,
      padding: 0,
      backgroundColor: theme.colors.common.white
    };
  }, [isIntentScreen, isMutating]);

  if (!isCopilotEnabled) {
    return '';
  }

  let redirectPath = `${match.path}`;

  if (isPrioritizationEnabled) {
    redirectPath = `${match.path}/prioritization`;
  } else if (isCategorizationEnabled) {
    redirectPath = `${match.path}/categorization`;
  } else if (isRecapEnabled) {
    redirectPath = `${match.path}/recap`;
  }

  return (
    <PageWrapper {...pageWrapperProps}>
      <Switch>
        <Redirect exact from={match.path} to={redirectPath} />
        {isPrioritizationEnabled && (
          <Route
            path={`${match.path}/prioritization`}
            render={() => <CopilotPrioritization companyId={company.id} />}
          />
        )}
        {isCategorizationEnabled && (
          <Route
            path={`${match.path}/categorization`}
            render={() => <CopilotCategorization companyId={company.id} />}
          />
        )}
        {isRecapEnabled && (
          <Route
            path={`${match.path}/recap`}
            render={() => <CopilotRecap companyId={company.id} />}
          />
        )}
        {isGenerateTitleEnabled && (
          <Route path={`${match.path}/title`} render={() => <CopilotGenerateTitle />} />
        )}
        {isAgentEnabled && (
          <Fragment>
            <Route path={[`${match.path}/agent`, `${match.path}/agent/settings`]} exact>
              <AgentWithChat appId={company.appId} base={`${match.path}/agent`} />
            </Route>
            <Route path={`${match.path}/agent/create`} exact component={IntentScreen} />
            <Route path={`${match.path}/agent/update/:intentId`} exact component={IntentScreen} />
          </Fragment>
        )}
      </Switch>
    </PageWrapper>
  );
};

Copilot.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string,
    path: PropTypes.string
  }).isRequired
};

export default Copilot;
