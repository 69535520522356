const roleMap = { BASIC_USER: 'User', ADMIN_USER: 'Site Contact' };

export const contactsColumnsData = [
  { key: 'name', title: 'Name', isSort: false },
  { key: 'email', title: 'Email', isSort: false },
  { key: 'phone', title: 'Phone', isSort: false },
  {
    key: 'role',
    title: 'Role',
    component: row => roleMap[row.role],
    filterType: 'radio',
    isSort: true
  }
];
