import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Button } from '@Thread-Magic/jasmine';
import useAuthUser from 'src/hooks/useAuthUser';
import { chatsTypes } from 'src/constants/action-types';
import { updateChatConfiguration } from 'src/redux/modules/chats/chatsActions';
import { removeLocalStorage, setLocalStorage } from 'src/utils';
import {
  M365_INTEGRATIONS_FULL_PATH,
  isUserIntegratedWithMicrosoft
} from 'src/containers/ChatIntegrationsEditor/MicrosoftIntegrations/microsoftConnectionHelpers';
import { connect } from 'react-redux';

const SignupSkipButton = ({ updateChatConfiguration, chatConfiguration }) => {
  const history = useHistory();
  const authenticatedUser = useAuthUser();

  const skipStepLocalAndRedirect = useCallback(() => {
    setLocalStorage({ chatConfiguration: chatsTypes.SKIPPED, botInstalled: true });
    removeLocalStorage('chatPlatformStep');
    removeLocalStorage('onboardingStep');
    history.replace('/dashboard/flows');
  }, [history, chatsTypes]);

  const handleUpdateChatConfiguration = useCallback(() => {
    if (isUserIntegratedWithMicrosoft(authenticatedUser)) {
      // If there is already a WINDOWS chat configuration, just redirect the user to the integrations page
      removeLocalStorage('chatPlatformStep');
      removeLocalStorage('onboardingStep');
      history.replace(M365_INTEGRATIONS_FULL_PATH);
      return;
    }

    // selected_configuration as null will always push the user to the onboarding (/signup/bot-install)
    if (!chatConfiguration) {
      return updateChatConfiguration(authenticatedUser.company?.id, chatsTypes.SKIPPED)
        .then(() => {
          skipStepLocalAndRedirect();
        })
        .catch(err => {
          // eslint-disable-next-line no-console
          console.error('Error occurred while saving chat configuration', err);
        });
    }

    return skipStepLocalAndRedirect();
  }, [updateChatConfiguration, authenticatedUser, history]);

  return (
    <Button size="large" full onClick={handleUpdateChatConfiguration}>
      Do this later
    </Button>
  );
};

SignupSkipButton.propTypes = {
  updateChatConfiguration: PropTypes.func.isRequired,
  chatConfiguration: PropTypes.string
};

SignupSkipButton.defaultProps = {
  chatConfiguration: null
};

const mapStateToProps = state => ({
  chatConfiguration: state.usersReducer.user.chatConfiguration
});

const mapDispatchToProps = dispatch => ({
  updateChatConfiguration: (companyId, chatConfig) =>
    dispatch(updateChatConfiguration(companyId, chatConfig))
});

export default connect(mapStateToProps, mapDispatchToProps)(SignupSkipButton);
