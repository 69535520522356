import React, { useEffect } from 'react';
import useAuthUser from 'src/hooks/useAuthUser';
import { useHistory } from 'react-router-dom';
import { Wrapper } from './PartnerSync.style';
import Loader from '../components/Loader';
import usePartnerIntegrationState from '../hooks/usePartnerIntegrationState';
import { partnerIntegrationStates } from '../constants';

const PartnerSync = () => {
  const history = useHistory();
  const { company } = useAuthUser();
  const { syncState } = usePartnerIntegrationState({ companyId: company.id });

  const successPage = '/dashboard/deployment-center';

  useEffect(() => {
    if (syncState === partnerIntegrationStates.SYNCED) {
      history.replace(successPage);
    }
  }, [syncState]);

  return (
    <Wrapper>
      <Loader message="Syncing with your Microsoft tenants..." />
    </Wrapper>
  );
};

export default PartnerSync;
