import styled, { css } from 'styled-components';

export const TextareaContainer = styled.div`
  position: relative;
  padding: 0;
  margin: 0;
  display: flex;
  outline: 0;
  resize: none;

  textarea {
    flex: 1;
    outline: 0;
    border: 1px solid transparent;
    transition: 0.1s;
    border-radius: 4px;
    resize: none;
    &:disabled {
      background-color: transparent;
    }
    ${({ borderOnHover }) =>
      borderOnHover &&
      css`
        &:hover:not(:disabled) {
          border-color: ${({ theme }) => theme.colors.brand.light.base};
        }
      `}

    ${({ isLoading }) =>
      isLoading &&
      css`
        border-color: ${({ theme }) => theme.colors.brand.light.base};
        pointer-events: none;
      `}
  }
  & > svg {
    position: absolute;
    right: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
  }
  ${({ isLoading }) =>
    isLoading &&
    css`
      &::before {
        content: '';
        border-radius: 4px;
        position: absolute;
        top: 1px;
        left: 1px;
        bottom: 1px;
        right: 1px;
        z-index: 2;
        background-color: rgba(255, 255, 255, 0.6);
      }
    `}
`;
