import React from 'react';

import CustomMultiSelect from 'src/components/MessengerSettings/CustomMultiSelect';
import { chatgenieAPI } from 'src/config/api';
import { AgentHeader } from './style';
import useTabs from './Tabs/hook';

export const argumentTypes = [
  { label: 'Text', value: 'text' },
  { label: 'Number', value: 'number' },
  { label: 'List', value: 'list' },
  { label: 'Date', value: 'date' },
  { label: 'Time', value: 'time' },
  { label: 'DateTime', value: 'datetime' }
];

const Settings = () => {
  const { isLoading, settings, updateAgentSettings } = useTabs();

  const getBoards = (query, page) =>
    chatgenieAPI.getBoards({ query, page, normalizer: res => res.data });

  const getCompanies = (query, page) =>
    chatgenieAPI.getCompanies({ query, page, normalizer: res => res.data });

  return (
    <>
      <AgentHeader>
        <div>
          <h5>Agent Settings</h5>
        </div>
      </AgentHeader>
      <div>
        <p>Customers with Magic Agent</p>
        <CustomMultiSelect
          name="included-customers"
          value={settings?.customerActivations}
          options={getCompanies}
          onChange={option => updateAgentSettings({ customerActivations: option })}
          componentType="paginate"
          isLoading={isLoading}
        />
      </div>
      <div>
        <p>Board to exclude</p>
        <CustomMultiSelect
          name="excluded-boards"
          value={settings?.boardExclusions}
          options={getBoards}
          onChange={option => updateAgentSettings({ boardExclusions: option })}
          componentType="paginate"
          isLoading={isLoading}
        />
      </div>
    </>
  );
};

export default Settings;
