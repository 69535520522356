import React, { useMemo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { components } from 'react-select';
import SimpleSelect from 'src/components/SimpleSelect';
import { keyBy } from 'lodash';
import theme from 'src/config/theme';
import * as Styled from './BulkAction.styles';

const BulkAction = ({ type, action, label, options = [] }) => {
  const [value, setValue] = useState(null);
  const [isLoading] = useState(false);
  const optionsMappedByValue = useMemo(() => {
    return keyBy(options, 'value');
  }, []);

  const handleChange = useCallback(async v => {
    setValue(v);
    await action(v.value);
  }, []);

  return (
    <Styled.Wrapper>
      <SimpleSelect
        components={{
          Option: ({ children, data, ...props }) => (
            <components.Option {...props}>
              <Styled.Option>{children}</Styled.Option>
            </components.Option>
          )
        }}
        value={optionsMappedByValue[value]}
        onChange={handleChange}
        customStyles={{
          placeholder: {
            color: theme.colors.accent.main,
            fontWeight: '600'
          },
          controlStyles: {
            borderRadius: 5
          }
        }}
        options={options}
        isLoading={isLoading}
        isDisabled={isLoading}
        isSearchable={false}
        placeholder={label}
        size="small"
      />
    </Styled.Wrapper>
  );
};

BulkAction.defaultProps = {
  options: []
};

BulkAction.propTypes = {
  type: PropTypes.oneOf(['button', 'select']).isRequired,
  action: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired
    })
  ),
  label: PropTypes.string.isRequired
};

export default BulkAction;
