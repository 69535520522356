import axios from 'axios';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import store from 'src/redux/store';
import { URL_API } from 'src/constants';
import { getHeaders } from 'src/utils';

const queryKeysFromQueryString = queryString => {
  const urlSearch = new URLSearchParams(queryString);

  return [...urlSearch.entries()].map(([key, value]) => `${key}=${value}`);
};

const useMembers = ({ queryString }) => {
  const queryClient = useQueryClient();

  const { data, isLoading, isFetching, error } = useQuery({
    queryFn: async () => {
      const response = await axios.get(`${URL_API}/api/v1/members${queryString}`, {
        headers: getHeaders(store.getState, {})
      });
      return response.data;
    },
    queryKey: ['members', ...queryKeysFromQueryString(queryString)]
  });

  const { mutateAsync: updateMember, isLoading: isUpdatingMember } = useMutation({
    mutationFn: async ({ id, ...data }) => {
      const response = await axios.put(`${URL_API}/api/v1/members/${id}`, data, {
        headers: getHeaders(store.getState, {})
      });
      if (data.state) {
        await Promise.all([queryClient.invalidateQueries(['members'])]);
      }
      return response.data.data;
    },
    onSuccess: updatedMember => {
      queryClient.setQueryData(
        ['members', ...queryKeysFromQueryString(queryString)],
        ({ data, ...rest }) => ({
          ...rest,
          data: data.map(member => (member.id === updatedMember.id ? updatedMember : member))
        })
      );
    }
  });

  return {
    error,
    members: data,
    isLoading: isLoading || isFetching,
    updateMember,
    isUpdatingMember
  };
};

export default useMembers;
