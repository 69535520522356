import axios from 'axios';
import { actionIcons } from 'src/containers/ConfigureFlow/config';
import { teamsAppsNormalizer, teamsAppNormalizer } from './normalizer';
import store from '../redux/store';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL
});

api.interceptors.request.use(
  async config => {
    if (!config.headers.Authorization) {
      const token = store?.getState()?.usersReducer?.user?.token;
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }
    const appId = store?.getState()?.usersReducer?.user?.appId;
    config.headers['APP-ID'] = appId;
    return config;
  },
  error => Promise.reject(error)
);

export const chatgenieAPI = {
  getOutcomes: ({ query }) =>
    api.get(`api/v1/outcomes?query=${query || ''}`).then(res => res.data?.data),
  getServiceLevelAgreements: query =>
    api.get(`api/v1/service-level-agreements?query=${query || ''}`).then(res => res.data?.data),
  testCopilotPriorities: params =>
    api.post(`/api/v1/copilot/priority/test`, params.data).then(res => res.data),
  getCopilotPriorities: () =>
    api.get(`/api/v1/copilot/priority`).then(res => ({
      override_priorities: 0,
      priorities: res.data.map(item => ({
        ...item,
        text: item.copilot_description?.en || ''
      }))
    })),
  getCopilotPriorityRules: slaId =>
    api
      .get(
        `/api/v1/copilot/priority/wizard?include_custom_conditions=true${
          slaId ? `&service_level_agreement_id=${slaId}` : ''
        }`
      )
      .then(res => res.data),
  updateCopilotPriorityRules: data =>
    api.put(`/api/v1/copilot/priority/wizard`, data).then(res => res.data),
  createCopilotPriorityRule: data =>
    api.post(`/api/v1/copilot/priority/wizard`, data).then(res => res.data),
  deleteCopilotPriorityRule: id =>
    api.delete(`/api/v1/copilot/priority/wizard/${id}`).then(res => res.data),
  getCategorizationTypes: () => api.get(`/api/board-types/categories`).then(res => res.data),
  updateCategorizationTypes: data =>
    api.put(`/api/board-types/categories`, data).then(res => res.data),
  testCopilotCategorization: params =>
    api.post(`/api/v1/copilot/category/test`, params.data).then(res => res.data),
  updateCopilotPriorities: params =>
    api.post(`/api/v1/copilot/priority`, params.data).then(res => res.data),
  getSettings: () => api.get(`/api/v1/settings`).then(res => res.data.data),
  updateSettings: data => api.put(`/api/v1/settings`, data).then(res => res.data.data),
  getMessengerDesign: () => api.get(`/api/v1/messenger/design`).then(res => res.data.data),
  updateMessengerDesign: params =>
    api.put(`/api/v1/messenger/design`, params.data).then(res => res.data.data),
  getCompanyMessengerDesign: params =>
    api.get(`/api/v1/companies/${params.companyId}/messenger/design`).then(res => res.data.data),
  createCompanyMessengerDesign: params =>
    api
      .post(`/api/v1/companies/${params.companyId}/messenger/design`, params.data)
      .then(res => res.data.data),
  updateCompanyMessengerDesign: params =>
    api
      .put(`/api/v1/companies/${params.companyId}/messenger/design`, params.data)
      .then(res => res.data.data),
  getHoursData: params =>
    api.get(`/api/workspaces/${params.companyId}/messenger-hours`).then(res => res.data.data),
  getTimezones: params =>
    api
      .get(
        `/api/workspaces/${params.companyId}/messenger-hours/options?per_page=100&query=${
          params.query || ''
        }`
      )
      .then(res => res.data.timezones),
  getActions: () =>
    api.get('/api/flow/actions').then(res => {
      return res.data.data?.map(item => ({
        ...item,
        icon: actionIcons(item.command.toUpperCase()),
        code: item.command.toUpperCase(),
        noChain: false
      }));
    }),
  getActionById: id => api.get(`/api/flow/actions/${id}`).then(res => res.data.data),
  getFlowTimezones: (query, page) =>
    api
      .get(`/api/flow/timezones?page=${page || 1}${query ? `&query=${query}` : ''}`)
      .then(res => res.data),
  getFlowFilterOperators: () => api.get(`/api/flow/filter-operators`).then(res => res.data.data),
  executeAction: params =>
    api.post('/api/action-platform/actions', params).then(res => {
      params.onSuccess(res.data, { actions: params.actions, form_data: params.form_data });
      return res.data;
    }),
  getOptions: params => api.post('/api/flow/actions/options', params).then(res => res.data.data),
  getCompany: params =>
    api.get(`/api/v1/companies/${params.id}`, params).then(res => res.data.data),
  updateHoursData: params =>
    api.put(`/api/workspaces/${params.companyId}/messenger-hours`, params.data),
  getTeamsApps: params =>
    api
      .get(
        `/api/workspaces/${params.companyId}/ms-teams-apps${
          params.query ? `?query=${params.query}` : ''
        }`
      )
      .then(res => teamsAppsNormalizer(res.data.data)),
  getTeamsApp: params =>
    api
      .get(`/api/workspaces/${params.companyId}/ms-teams-apps/${params.teamsAppId}`)
      .then(res => teamsAppNormalizer(res.data.data)),
  deleteTeamsApp: params =>
    api.delete(`/api/workspaces/${params.companyId}/ms-teams-apps/${params.teamsAppId}`),
  uploadAttachments: params =>
    api.post(`/api/attachments`, params.data).then(({ data: { data } }) => ({
      id: data.id,
      url: data.path
    })),
  createTeamsApp: params =>
    api
      .post(`/api/workspaces/${params.companyId}/ms-teams-apps`, params.data)
      .then(res => teamsAppNormalizer(res.data.data)),
  updateTeamsApp: params =>
    api
      .put(`/api/workspaces/${params.companyId}/ms-teams-apps/${params.teamsAppId}`, params.data)
      .then(res => teamsAppNormalizer(res.data.data)),
  downloadTeamsApp: params =>
    api.get(`/api/workspaces/${params.companyId}/ms-teams-apps/${params.teamsAppId}/manifest`, {
      responseType: 'arraybuffer'
    }),
  updateChatConfiguration: params =>
    api.patch(`/api/v1/companies/${params.companyId}/chat-configuration`, {
      selected_configuration: params.selectedConfiguration,
      ms_teams_app_id: params.teamsAppId
    }),
  uninstallChatConfiguration: params =>
    api.delete(`/api/v1/companies/${params.companyId}/chat-configuration`),
  connectPlatform: data => api.post(`/api/action-platform/connect-wise/auth`, data),
  createAutotaskIntegration: data => api.post(`/api/v1/psa/autotask`, data),
  createHaloIntegration: data => api.post(`/api/v1/psa/halo`, data),
  getMagicLinkToken: companyId => api.get(`/api/v1/magic-link/token?company_id=${companyId}`),
  getActionPlatforms: () => api.get(`/api/action-platform/platforms`).then(res => res.data.data),
  getBoards: ({ query, page, normalizer = res => res?.data?.data } = {}) =>
    api
      .get(`/api/boards?page=${page || 1}&sort=name${query ? `&filter-name=~${query}` : ''}`)
      .then(res => (normalizer ? normalizer(res) : res)),
  getContactTypes: ({ query, page, normalizer = res => res?.data?.data } = {}) =>
    api
      .get(`/api/contact-types?page=${page || 1}&sort=name${query ? `&filter-name=~${query}` : ''}`)
      .then(res => (normalizer ? normalizer(res) : res)),
  getCompanyTypes: ({ query, page, normalizer = res => res?.data?.data } = {}) =>
    api
      .get(`/api/company-types?page=${page || 1}${query ? `&query=${query}` : ''}`)
      .then(res => (normalizer ? normalizer(res) : res)),

  getCompanyType: ({ id, normalizer = res => res?.data?.data } = {}) =>
    api.get(`/api/company-types/${id}`).then(res => (normalizer ? normalizer(res) : res)),
  getAgreementTypes: ({ query, page, normalizer = res => res?.data?.data } = {}) =>
    api
      .get(`/api/agreement-types?page=${page || 1}${query ? `&query=${query}` : ''}`)
      .then(res => (normalizer ? normalizer(res) : res)),
  getAgreementType: ({ id, normalizer = res => res?.data?.data } = {}) =>
    api.get(`/api/agreement-types/${id}`).then(res => (normalizer ? normalizer(res) : res)),
  getStatuses: ({ query, page, boardId, normalizer = res => res?.data?.data } = {}) =>
    api
      .get(`/api/statuses?filter-name=~${query}&filter-board_id=${boardId || ''}&page=${page || 1}`)
      .then(res => (normalizer ? normalizer(res) : res)),
  getSources: ({ query, page, normalizer = res => res?.data?.data } = {}) =>
    api
      .get(`/api/v1/sources?filter-name=~${query}&page=${page || 1}`)
      .then(res => (normalizer ? normalizer(res) : res)),
  getTicketTypes: ({ query, page, normalizer = res => res?.data?.data } = {}) =>
    api
      .get(`/api/v1/types?filter-name=~${query}&page=${page || 1}`)
      .then(res => (normalizer ? normalizer(res) : res)),
  getPriorities: ({ query, page, normalizer = res => res?.data?.data } = {}) =>
    api
      .get(`/api/v1/priorities?filter-name=~${query}&page=${page || 1}`)
      .then(res => (normalizer ? normalizer(res) : res)),
  getPrioritiesBySla: ({ query, slaId, normalizer = res => res?.data?.data } = {}) =>
    api
      .get(`/api/v1/priorities/sla/${slaId}?query=${query || ''}`)
      .then(res => (normalizer ? normalizer(res) : res)),
  getMessengerSettings: () => api.get(`/api/v1/messenger/settings`).then(res => res?.data?.data),
  updateMessengerSettings: data =>
    api.patch(`/api/v1/messenger/settings`, data).then(res => res?.data?.data),
  getCompanyMessengerSettings: companyId =>
    api.get(`/api/v1/companies/${companyId}/messenger/settings`).then(res => res?.data?.data),
  updateCompanyMessengerSettings: ({ data, companyId }) =>
    api
      .patch(`/api/v1/companies/${companyId}/messenger/settings`, data)
      .then(res => res?.data?.data),
  createCompanyMessengerSettings: ({ data, companyId }) =>
    api
      .post(`/api/v1/companies/${companyId}/messenger/settings`, data)
      .then(res => res?.data?.data),
  getAuditLogs: ({ page, normalizer = res => res?.data } = {}) =>
    api
      .get(`/api/v1/audit-logs?page=${page || 1}`)
      .then(res => (normalizer ? normalizer(res) : res)),
  partnerCenter: {
    onBoard: data => api.post(`/api/v1/partner-center/auth`, data),
    deployedClients: (queryString = '') =>
      api.get(`/api/v1/partner-center/clients/deployed${queryString}`),
    notDeployedClients: (queryString = '') =>
      api.get(`/api/v1/partner-center/clients/not-deployed${queryString}`),
    status: () => api.get(`/api/v1/partner-center/status`)
  },
  integration: {
    getSyncTypes: () => api.get(`/api/v1/integration/sync`).then(res => res?.data?.data),
    triggerSync: data => api.post(`/api/v1/integration/sync`, data)
  },
  adminViews: {
    getAll: ({ type } = {}) => {
      return api.get(`/api/v1/admin-views/${type}`);
    },
    create: (type, payload) => {
      return api.post(`/api/v1/admin-views/${type}`, payload);
    },
    update: (type, id, payload) => {
      return api.patch(`/api/v1/admin-views/${type}/${id}`, payload);
    }
  },
  recap: {
    getRules: () => api.get(`/api/v1/copilot/recap/rules`).then(res => res?.data?.rules),
    getTemplates: () => api.get(`/api/v1/copilot/recap/templates`).then(res => res?.data),
    getThreads: () => api.get(`/api/v1/copilot/recap/test/threads`).then(res => res?.data),
    setRules: rules =>
      api.put(`/api/v1/copilot/recap/rules`, { rules }).then(res => res?.data || {}),
    generateRecap: (ticketId, rules) =>
      api
        .post(`/api/v1/copilot/recap/test`, { ticket_id: ticketId, rules })
        .then(res => res?.data || {})
  },
  deploymentCenter: {
    metrics: data => api.get(`/api/v1/deployment-center/metrics`, data),
    magicAiTrialPeriod: data => api.get(`/api/v1/deployment-center/magic-ai-trial`, data)
  },
  generateTitle: {
    getSettings: () => api.get(`/api/v1/copilot/title/settings`).then(res => res.data),
    updateSettings: data => api.put(`/api/v1/copilot/title/settings`, data),
    test: data => api.post(`/api/v1/copilot/title/test`, data)
  },

  updateMessengerStatuses: ({ companyIds, excludedCompanyIds, action, query }) =>
    api.patch(`/api/v1/messenger/statuses?${query}`, {
      company_ids: companyIds,
      excluded_company_ids: excludedCompanyIds,
      action
    }),
  updateMagicAIStatuses: ({ companyIds, excludedCompanyIds, action, query }) =>
    api.patch(`/api/v1/companies/magic-ai/statuses?${query}`, {
      company_ids: companyIds,
      excluded_company_ids: excludedCompanyIds,
      action
    }),
  getAgents: ({ query, page, normalizer = res => res?.data } = {}) =>
    api
      .get(`/api/v1/copilot/agent?query=${query}&page=${page || 1}`)
      .then(res => (normalizer ? normalizer(res) : res)),
  getAgent: id => api.get(`/api/v1/copilot/agent/${id}`).then(res => res.data),
  createAgent: data => api.post(`/api/v1/copilot/agent`, data),
  updateAgent: data => api.put(`/api/v1/copilot/agent/${data.id}`, data),
  getAgentSettings: () => api.get(`/api/v1/copilot/agent/settings`).then(res => res.data),
  updateAgentSettings: data => api.put(`/api/v1/copilot/agent/settings`, data),
  getCompanies: ({ query, page, normalizer = res => res?.data?.data } = {}) =>
    api
      .get(
        `/api/v1/copilot/agent/settings/companies?page=${page || 1}&sort=name${
          query ? `&filter-name=~${query}` : ''
        }`
      )
      .then(res => (normalizer ? normalizer(res) : res)),
  syncMsUsers: () => api.get(`/api/v1/integration/ms-graph/sync-users`),
  syncMsGroups: () => api.get(`/api/v1/integration/ms-graph/sync-groups`)
};
