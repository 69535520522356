export const notificationTypes = {
  SUCCESS: 'success',
  ERROR: 'error'
};

export const notificationConstants = {
  FLOW_CREATED: {
    name: 'flow-created',
    label: 'Flow created',
    type: notificationTypes.SUCCESS
  },
  FLOW_UPDATED: {
    name: 'flow-updated',
    label: 'Flow updated',
    type: notificationTypes.SUCCESS
  },
  FLOW_ERROR: {
    name: 'flow-error',
    type: notificationTypes.ERROR
  },
  CONTACT_INVITE_FULFILLED: {
    name: 'contact-invite',
    label: 'Invite successfully sent',
    type: notificationTypes.SUCCESS
  },
  MEMBER_INVITE_FULFILLED: {
    name: 'member-invite',
    label: 'Invite successfully sent',
    type: notificationTypes.SUCCESS
  }
};
