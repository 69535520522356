import { useState, useEffect } from 'react';

export const useItGlueFormPage = ({ onSubmit, formData, configuration }) => {
  const formId = 'integration-it-glue-form';
  const [baseUrl, setBaseUrl] = useState(formData.baseUrl || '');
  const [apiKey, setApiKey] = useState(formData.apiKey || '');
  const [submitted, setSubmitted] = useState(false);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    setBaseUrl(formData.baseUrl);
    setApiKey(formData.apiKey);
  }, [formData]);

  useEffect(() => {
    setIsActive(configuration.is_active);
  }, [configuration]);

  const onFormSubmit = event => {
    event.preventDefault();
    setSubmitted(true);

    onSubmit(
      {
        baseUrl,
        apiKey: apiKey.trim()
      },
      isActive
    );
  };

  return {
    formId,
    baseUrl,
    setBaseUrl,
    apiKey,
    setApiKey,
    onFormSubmit,
    submitted,
    isActive,
    setIsActive
  };
};
