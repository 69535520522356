import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

import { SelectGroupStyled, SelectBadgeStyled, styles } from './style';

import * as Styled from './style';

const SimpleSelect = props => {
  const {
    className,
    value,
    options,
    title,
    name,
    size,
    customStyles,
    onChange,
    getOptionValue,
    getOptionLabel,
    formatGroupLabel,
    isSearchable,
    isClearable,
    components,
    placeholder,
    label,
    isDisabled,
    isLoading
  } = props;

  const GroupLabel = ({ label, options }) => (
    <SelectGroupStyled>
      <span>{label}</span>
      <SelectBadgeStyled>{options.length}</SelectBadgeStyled>
    </SelectGroupStyled>
  );

  const SelectElement = props => (
    <Select
      className={className}
      title={title}
      name={name}
      id={name}
      size="small"
      styles={styles({ size, customStyles })}
      classNamePrefix="select"
      value={value}
      isDisabled={isDisabled}
      onChange={onChange}
      options={options}
      placeholder={placeholder}
      components={components}
      isSearchable={isSearchable}
      isClearable={isClearable}
      getOptionValue={getOptionValue}
      getOptionLabel={getOptionLabel}
      menuPlacement="auto"
      formatGroupLabel={formatGroupLabel && GroupLabel}
      isLoading={isLoading}
      {...props}
    />
  );

  return (
    <Styled.Wrapper>
      {label && <Styled.Label htmlFor={name}>{label}</Styled.Label>}
      <SelectElement />
    </Styled.Wrapper>
  );
};

SimpleSelect.propTypes = {
  options: PropTypes.arrayOf(PropTypes.any),
  value: PropTypes.any,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  getOptionValue: PropTypes.func,
  getOptionLabel: PropTypes.func,
  formatGroupLabel: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  className: PropTypes.string,
  customStyles: PropTypes.oneOfType([PropTypes.objectOf(PropTypes.any), PropTypes.string])
};

SimpleSelect.defaultProps = {
  formatGroupLabel: false,
  className: 'simple-select',
  customStyles: {}
};

export default SimpleSelect;
