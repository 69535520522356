import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import styled from 'styled-components/macro';

export const ModalStyled = styled(Modal)`
  max-width: ${({ size }) => (size === 'medium' ? '726px' : '490px')};
  margin-top: 88px;

  .modal-content {
    padding: 20px 40px;
  }
`;

export const ModalError = styled.p`
  font-size: 14px;
  font-weight: 600;
  padding: 10px 12px;
  background: rgba(235, 87, 87, 0.2);
  color: ${({ theme }) => theme.colors.red1};
  border: 1px solid ${({ theme }) => theme.colors.red1};
`;

export const ModalHeaderStyled = styled(ModalHeader)`
  display: flex;
  justify-content: center;
  padding: 0;
  border: none;

  ${({ aligned }) => aligned === 'left' && 'justify-content: stretch;'}

  .modal-title {
    margin-bottom: 14px;
    font-size: 18px;
    line-height: 24px;
    font-weight: bold;
  }

  .close {
    position: absolute;
    bottom: 100%;
    left: 100%;
    margin: 0;
    padding: 5px;
    font-size: 26px;
    font-weight: 300;
    border: 1px solid transparent;
    transition: border-color 0.2s;
    opacity: 1;

    &:focus {
      outline: none;
      border-color: ${({ theme }) => theme.colors.brand.light.base};
    }

    span {
      display: block;
      height: 15px;
      width: 15px;
      line-height: 15px;
      text-shadow: none;
      color: #ffffff;
    }
  }
`;

export const ModalBodyStyled = styled(ModalBody)`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.09px;
  color: #2e2e2e;

  ${({ aligned }) => aligned === 'left' && 'display: block;'}
`;

export const DescriptionStyled = styled.p`
  width: 100%;
  text-align: center;
`;
