import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import { Button, Select, EmojiPicker } from '@Thread-Magic/jasmine';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQuery, useMutation } from 'react-query';
import { chatgenieAPI } from 'src/config/api';
import { queryClient } from 'src/config/queryClient';
import { useForm, Controller, useFieldArray, useWatch } from 'react-hook-form';
import useAuthUser from 'src/hooks/useAuthUser';
import useToast from 'src/hooks/useToast';
import { useContentfulData } from 'src/hooks/useContentfulData';
import useWorkspaceSettings from 'src/hooks/useWorkspaceSettings';
import queryKeys from 'src/constants/queryKeys';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import PageBuilder from 'src/components/PageBuilder';
import ConfirmModal from 'src/components/ConfirmModal';
import { TeamsHeader, TeamsSidebar } from 'src/components/msTeamsParts';
import { ReactComponent as TrashIcon } from 'src/assets/icons/trash.svg';
import { ReactComponent as MoveIcon } from 'src/assets/icons/move.svg';
import { ReactComponent as EditIcon } from 'src/assets/icons/edit.svg';
import { ReactComponent as ExitIcon } from 'src/assets/icons/exit.svg';
import { ReactComponent as EyeIcon } from 'src/assets/icons/eye.svg';
import { ReactComponent as LayoutLeft } from 'src/assets/icons/layout-option-left.svg';
import { ReactComponent as LayoutRight } from 'src/assets/icons/layout-option-right.svg';
import { blocksFiledTypes, schema, formDefaultValues, newBlockData, imageSizes } from './config';
import PendingModal from './PendingModal';
import * as Styled from './style';

const ServicePageBuilder = ({ companyId, parentCompanyId, match, location }) => {
  const authUser = useAuthUser();
  const [isConfirmModal, setIsConfirmModal] = useState(false);
  const [isPendingModal, setIsPendingModal] = useState(false);
  const { toast } = useToast();
  const { logo, botIcon, trayIcon, primaryColor, botName } = useWorkspaceSettings();
  const teamsAppId = match.params?.teamsAppId;
  const appData = location.state?.appData;

  const [imageTemplates] = useContentfulData({
    filters: { content_type: 'imageTemplates' },
    defaultData: {},
    dataNormalizer: ({ items }) => {
      const data = items.filter(({ fields }) => {
        return fields.sourceKey === 'featuredImage';
      })[0];

      return data.fields.images.reduce((accumulator, { fields }) => {
        const key = fields.title.split('-')[0];
        if (accumulator[key]) {
          accumulator[key].push(fields);
        } else {
          accumulator[key] = [fields];
        }
        return accumulator;
      }, {});
    }
  });

  const { data: app = {}, isLoading } = useQuery(
    [queryKeys.GET_TEAMS_APP, parentCompanyId, teamsAppId],
    () => chatgenieAPI.getTeamsApp({ companyId: parentCompanyId, teamsAppId }),
    {
      enabled: !appData && !!teamsAppId
    }
  );

  const state = appData || app;

  const updateData = newData => {
    queryClient.setQueryData(
      [queryKeys.GET_TEAMS_APP, parentCompanyId, newData.id.toString()],
      () => newData
    );
    queryClient.refetchQueries([queryKeys.GET_TEAMS_APPS, parentCompanyId]);
    if (companyId) {
      queryClient.refetchQueries([queryKeys.GET_COMPANY, companyId]);
    }
  };

  const ErrorHandler = ({ response }) => {
    if (response?.data && response.data.errors) {
      Object.keys(response.data.errors).map(key => {
        response.data.errors[key].map(msg => toast.error(`Please check '${key}' field: ${msg}`));
      });
    } else {
      toast.error(response?.data?.message || 'Something went wrong');
    }
  };

  const mutationCreate = useMutation(
    data => chatgenieAPI.createTeamsApp({ companyId: parentCompanyId, data }),
    {
      onSuccess: response => {
        const { id, servicePageId } = response;
        updateData(response);
        goToHomePage({
          appEvent: 'created',
          teamsAppId: id,
          servicePageId,
          appData: state,
          action: location.state?.action
        });
      },
      onError: ErrorHandler
    }
  );

  const mutationUpdate = useMutation(
    data => chatgenieAPI.updateTeamsApp({ companyId: parentCompanyId, data, teamsAppId: state.id }),
    {
      onSuccess: response => {
        const { id, servicePageId } = response;
        updateData(response);
        goToHomePage({ appEvent: 'updated', teamsAppId: id, servicePageId });
      },
      onError: ErrorHandler
    }
  );

  const {
    control,
    register,
    reset,
    handleSubmit,
    formState: { errors, isDirty },
    setValue,
    getValues,
    setError,
    clearErrors
  } = useForm({
    defaultValues: { ...formDefaultValues, ...state },
    resolver: yupResolver(schema)
  });

  const blocks = useWatch({ control, name: 'blocks' });
  const featuredImage = useWatch({ control, name: 'featuredImage' });
  const { fields, append, remove, move } = useFieldArray({ control, name: 'blocks' });
  const isChatPlacementSwapped = useWatch({ control, name: 'isChatPlacementSwapped' }) === 1;
  const history = useHistory();

  const handleImageValidation = (file, name) => {
    const normalizedImageName = name.split('.')[0];
    if (imageSizes[normalizedImageName] && file?.size > imageSizes[normalizedImageName].size) {
      if (featuredImage) {
        toast.error(imageSizes[normalizedImageName].errorMessage);
      } else {
        setError(name, {
          type: 'custom',
          message: imageSizes[normalizedImageName].errorMessage
        });
      }
    }

    clearErrors(name);
    return true;
  };

  const onDragEnd = result => {
    if (!result.destination) return;
    move(result.source.index, result.destination.index);
  };

  const addLink = () => {
    append({ ...newBlockData });
  };

  const goBackHandler = () => {
    const data = getValues();
    history.push({
      pathname: data.id ? `../teams-app-builder/${data.id}` : `./teams-app-builder`,
      state: { ...location.state, appData: data }
    });
  };

  const goToHomePage = (state = {}) => {
    const pathname =
      location.state?.backPathname ||
      location.pathname
        .split('/')
        .slice(0, state.appEvent === 'created' ? -1 : -2)
        .join('/');
    history.push({ pathname, state });
  };

  const handlePreviewApp = () => {
    const previewWindow = window.open(
      `${window.location.origin}/teams-app-preview`,
      '_blank',
      'toolbar=0,menubar=0'
    );

    previewWindow.teamsAppState = {
      ...state,
      blocks,
      featuredImage,
      isChatPlacementSwapped
    };
  };

  const publishHandler = () => {
    handleSubmit(data => {
      const prepareData = {
        name: data.title,
        app_name: data.appName,
        external_id: data.externalAppId || undefined,
        description: data.description,
        short_description: data.shortDescription,
        app_icon: data.logo,
        tab_icon: data.icon,
        service_page: {
          id: data.servicePageId,
          featured_image: data.featuredImage,
          blocks: data.blocks.map((block, index) => ({
            block_type: block.blockType || 'link',
            block_order: index,
            properties: block
          })),
          is_chat_placement_swapped: data.isChatPlacementSwapped
        },
        tabs: data.tabs?.map((tab, index) => ({
          name: tab.name,
          entity_id: tab.id,
          content_url: tab.url,
          website_url: tab.url,
          tab_order: index
        }))
      };
      state.id ? mutationUpdate.mutate(prepareData) : mutationCreate.mutate(prepareData);
    })();
  };

  useEffect(() => {
    if (mutationCreate.isLoading) {
      setIsPendingModal(true);
    } else if (!mutationCreate.isLoading && isPendingModal) {
      setIsPendingModal(false);
    }
  }, [mutationCreate.isLoading]);

  useEffect(() => {
    if (state.id) {
      reset(state);
    }
  }, [state.id]);

  return (
    <PageBuilder
      title="Service Page"
      isLoading={mutationUpdate.isLoading || isLoading}
      actions={[
        <>
          <Button
            type="button"
            size="large"
            styleMode="outline"
            onClick={() => (isDirty ? setIsConfirmModal(true) : goToHomePage())}
          >
            <ExitIcon /> Exit
          </Button>
          <Button type="button" size="large" className="secondary" onClick={handlePreviewApp}>
            <EyeIcon /> Preview
          </Button>
        </>,
        <>
          <Button type="button" className="secondary" size="large" onClick={goBackHandler}>
            Previous
          </Button>
          <Button
            onClick={publishHandler}
            type="button"
            size="large"
            isLoader={mutationUpdate.isLoading || mutationCreate.isLoading}
          >
            Publish changes
          </Button>
        </>
      ]}
    >
      <Styled.Wrapper>
        <TeamsSidebar iconUrl={state.icon} appName={state.appName} />
        <Styled.Form>
          <Styled.FormGroup>
            <TeamsHeader>Microsoft Teams</TeamsHeader>
            <Controller
              control={control}
              name="featuredImage"
              render={({ field: { onChange, value, ref, name } }) => (
                <Styled.HeaderImageBlock
                  value={value}
                  onChange={data => onChange(data.url)}
                  controllerRef={ref}
                  accept="image/png, image/jpeg"
                  components={{
                    uploadPlaceholder: () => (
                      <Styled.HeaderImagePlaceholder>
                        <Styled.ImagePlaceholderTitle>
                          + Upload Header Image
                        </Styled.ImagePlaceholderTitle>
                        <Styled.ImagePlaceholderHint>
                          Use JPEG or PNG format, min. width 1024px and size less than 10 MB
                        </Styled.ImagePlaceholderHint>
                        {errors.featuredImage && (
                          <Styled.ImageError>{errors.featuredImage.message}</Styled.ImageError>
                        )}
                      </Styled.HeaderImagePlaceholder>
                    ),
                    imagePreview: children => (
                      <Styled.ImagePreview>
                        <Styled.EditWrapper>
                          <Styled.EditBackground>
                            <EditIcon />
                            Edit
                          </Styled.EditBackground>
                        </Styled.EditWrapper>
                        {children}
                      </Styled.ImagePreview>
                    )
                  }}
                  validator={handleImageValidation}
                  name={name}
                  formErrorObject={errors.featuredImage}
                  uploadText="Images wider than 1500px work best."
                  tabs={[
                    {
                      name: 'gallery',
                      component: callback => (
                        <Styled.GalleryWrapper>
                          {Object.keys(imageTemplates).map(key => (
                            <>
                              <Styled.TitleTemplate>{key}</Styled.TitleTemplate>
                              <Styled.ImageTemplateList>
                                {imageTemplates[key].map(({ file }) => (
                                  <Styled.Template
                                    url={file.url}
                                    onClick={() => {
                                      onChange(`https:${file.url}`);
                                      callback();
                                    }}
                                  />
                                ))}
                              </Styled.ImageTemplateList>
                            </>
                          ))}
                        </Styled.GalleryWrapper>
                      )
                    }
                  ]}
                />
              )}
            />
            <Styled.ChatSwapper>
              <Styled.ChatSwapperText isLightColor={featuredImage?.length}>
                Layout options
              </Styled.ChatSwapperText>
              <Styled.ChatSwapperSwitchesWrap>
                <Styled.ChatSwapperSwitch
                  isActive={!isChatPlacementSwapped}
                  onClick={() => setValue('isChatPlacementSwapped', 0)}
                >
                  <LayoutLeft />
                </Styled.ChatSwapperSwitch>
                <Styled.ChatSwapperSwitch
                  isActive={isChatPlacementSwapped}
                  onClick={() => setValue('isChatPlacementSwapped', 1)}
                >
                  <LayoutRight />
                </Styled.ChatSwapperSwitch>
              </Styled.ChatSwapperSwitchesWrap>
            </Styled.ChatSwapper>
          </Styled.FormGroup>
          <Styled.FormContent>
            <Styled.Row isChatPlacementSwapped={isChatPlacementSwapped}>
              <Styled.Col>
                <Styled.Action type="button" onClick={addLink}>
                  + Add a support link
                </Styled.Action>
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="droppable">
                    {provided => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        {fields.map(({ id }, index) => (
                          <Draggable key={id} draggableId={id} index={index}>
                            {provided => (
                              <Styled.LinkWrapper
                                key={id}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                              >
                                <Controller
                                  control={control}
                                  name={`blocks.${index}.icon`}
                                  render={({ field: { value, onChange, ref, name } }) => (
                                    <Styled.IconBlock
                                      value={value}
                                      onChange={data => onChange(data.url)}
                                      controllerRef={ref}
                                      validator={handleImageValidation}
                                      accept="image/png"
                                      aspect={1}
                                      name={name}
                                      uploadText="Recommended size is 78px x 78px PNG."
                                      formErrorObject={errors.blocks?.[index]?.icon}
                                      tabs={[
                                        {
                                          name: 'emojis',
                                          component: callback => (
                                            <EmojiPicker
                                              onSelect={emoji => {
                                                onChange(emoji.native);
                                                setValue(`blocks.${index}.blockType`, 'link');
                                                callback();
                                              }}
                                              title="Pick your emoji…"
                                            />
                                          )
                                        }
                                      ]}
                                    />
                                  )}
                                />
                                <Styled.Fields>
                                  <Styled.Row>
                                    <Styled.FieldWrapper>
                                      <Styled.FiledControl
                                        {...register(`blocks.${index}.title`)}
                                        type="text"
                                        placeholder="Title"
                                        accent
                                      />
                                      {errors.blocks && errors.blocks[index]?.title && (
                                        <Styled.FieldError>
                                          {errors.blocks[index].title?.message}
                                        </Styled.FieldError>
                                      )}
                                    </Styled.FieldWrapper>
                                  </Styled.Row>
                                  <Styled.Row>
                                    <Styled.FieldWrapper>
                                      <Styled.FiledControl
                                        {...register(`blocks.${index}.description`)}
                                        type="text"
                                        placeholder="A short description"
                                      />
                                      {errors.blocks && errors.blocks[index]?.description && (
                                        <Styled.FieldError>
                                          {errors.blocks[index].description?.message}
                                        </Styled.FieldError>
                                      )}
                                    </Styled.FieldWrapper>
                                  </Styled.Row>
                                  <Styled.Row>
                                    <Styled.FieldWrapper>
                                      <span>Button Type</span>
                                      <Controller
                                        control={control}
                                        name={`blocks.${index}.type`}
                                        render={({ field: { onChange, ref, value } }) => (
                                          <Select
                                            id="type"
                                            inputRef={ref}
                                            value={{
                                              value,
                                              label: value.charAt(0).toUpperCase() + value.slice(1)
                                            }}
                                            onChange={event => onChange(event.value)}
                                            options={[
                                              { value: 'link', label: 'Link' },
                                              { value: 'phone', label: 'Phone' },
                                              { value: 'email', label: 'Email' }
                                            ]}
                                          />
                                        )}
                                      />
                                    </Styled.FieldWrapper>
                                    <Styled.FieldWrapper>
                                      <span>Button Label</span>
                                      <Styled.FiledControl
                                        {...register(`blocks.${index}.label`)}
                                        type="text"
                                        placeholder={
                                          blocksFiledTypes[blocks[index]?.type]?.labelPlaceholder
                                        }
                                      />
                                      {errors.blocks && errors.blocks[index]?.label && (
                                        <Styled.FieldError>
                                          {errors.blocks[index]?.label?.message}
                                        </Styled.FieldError>
                                      )}
                                    </Styled.FieldWrapper>
                                    <Styled.FieldWrapper>
                                      <span>
                                        {blocksFiledTypes[blocks[index]?.type]?.valueTitle}
                                      </span>
                                      <Styled.FiledControl
                                        {...register(`blocks.${index}.value`)}
                                        type="text"
                                        placeholder={
                                          blocksFiledTypes[blocks[index]?.type]?.valuePlaceholder
                                        }
                                      />
                                      {errors.blocks && errors.blocks[index]?.value && (
                                        <Styled.FieldError>
                                          {blocksFiledTypes[blocks[index]?.type]?.valueTitle}{' '}
                                          {errors.blocks[index].value?.message}
                                        </Styled.FieldError>
                                      )}
                                    </Styled.FieldWrapper>
                                  </Styled.Row>
                                </Styled.Fields>
                                <div>
                                  <Styled.LinkAction type="button" onClick={() => remove(index)}>
                                    <TrashIcon />
                                  </Styled.LinkAction>
                                  <Styled.LinkAction type="button" {...provided.dragHandleProps}>
                                    <MoveIcon />
                                  </Styled.LinkAction>
                                </div>
                              </Styled.LinkWrapper>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </Styled.Col>
              <Styled.MessengerWrapper isChatPlacementSwapped={isChatPlacementSwapped}>
                <Styled.Messenger
                  appId={authUser.company.appId}
                  image={logo?.path}
                  botIcon={trayIcon?.path}
                  botImage={botIcon?.path}
                  primaryColor={primaryColor}
                  botName={botName}
                  isCloseButton={false}
                />
                <Styled.MessengerDescription>
                  *Messenger settings{' '}
                  <Link to="/dashboard/messenger/settings">can be found here.</Link>
                </Styled.MessengerDescription>
              </Styled.MessengerWrapper>
            </Styled.Row>
          </Styled.FormContent>
        </Styled.Form>
      </Styled.Wrapper>
      <ConfirmModal
        isOpen={isConfirmModal}
        toggleHandler={() => setIsConfirmModal(false)}
        title="Are you sure you want to exit?"
        description="If you leave the page you might lose unsaved changes."
        submitText="Yes"
        cancelText="No, cancel"
        onSubmit={() => goToHomePage()}
      />
      <PendingModal isOpen={isPendingModal} />
    </PageBuilder>
  );
};

ServicePageBuilder.propTypes = {
  companyId: PropTypes.number.isRequired,
  parentCompanyId: PropTypes.number.isRequired,
  location: PropTypes.objectOf(PropTypes.any),
  match: PropTypes.objectOf(PropTypes.any)
};

ServicePageBuilder.defaultProps = {
  location: {},
  match: {}
};

export default ServicePageBuilder;
