export const refactrFormFields = {
  BASE_URL: {
    id: 'baseUrl',
    label: 'Base URL',
    placeholder: ''
  },
  LOGIN: {
    id: 'login',
    label: 'Login',
    placeholder: ''
  },
  PASSWORD: {
    id: 'password',
    label: 'Password',
    placeholder: ''
  },
  TOKEN: {
    id: 'token',
    label: 'Token',
    placeholder: ''
  }
};
