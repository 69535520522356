import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSubCompanies } from 'src/redux/modules/companies/actions';

const useClientCompanies = () => {
  const dispatch = useDispatch();
  const { isFetchSubCompanies, subCompanies = { data: [] } } = useSelector(
    state => state.companiesReducer
  );

  useEffect(() => {
    dispatch(getSubCompanies());
  }, []);

  return {
    isLoading: isFetchSubCompanies,
    clientCompanies: subCompanies.data
  };
};

export default useClientCompanies;
