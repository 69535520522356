import { usePostHog } from 'posthog-js/react';
import { useDispatch } from 'react-redux';
import { useAuth0 } from 'src/components/Auth0';
import { queryClient } from 'src/config/queryClient';
import { clearUserData } from 'src/redux/modules/users/usersActions';
import { setLocalStorage } from 'src/utils';

export default function useLogout() {
  const dispatch = useDispatch();
  const { logout: logoutAuth0 } = useAuth0();
  const posthog = usePostHog();

  const switchWorkspace = () => {
    setLocalStorage({ app: { id: null } });
    queryClient.clear();
    dispatch(clearUserData());
    posthog.reset();
  };

  const logout = async () => {
    localStorage.clear();
    posthog.reset();
    await logoutAuth0();
  };

  return {
    logout,
    switchWorkspace
  };
}
