export const MESSENGER_STATES = {
  ENABLED: 'ENABLED',
  DISABLED: 'DISABLED'
};

export const MAGIC_AI_STATES = {
  ENABLED: 'ENABLED',
  DISABLED: 'DISABLED',
  TRIAL: 'TRIAL'
};
