import { useState, useEffect, useMemo } from 'react';
import { createClient } from 'contentful';
import * as Sentry from '@sentry/react';

const contentfulCreds = {
  space: process.env.REACT_APP_CONTENTFUL_SPACE,
  accessToken: process.env.REACT_APP_CONTENTFUL_TOKEN,
  environment: process.env.REACT_APP_CONTENTFUL_ENV
};
const useContentfulData = ({ filters, dataNormalizer, defaultData, enabled = true }) => {
  const [data, setData] = useState(defaultData);
  const [loading, setLoading] = useState(true);
  const client = useMemo(() => createClient(contentfulCreds), []);

  useEffect(() => {
    if (enabled) {
      client
        .getEntries(filters)
        .then(entries => {
          setData(dataNormalizer ? dataNormalizer(entries) : entries);
          setLoading(false);
        })
        .catch(err => {
          Sentry.captureException(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, []);
  return [data, loading];
};

export { useContentfulData };
