import React, { useEffect } from 'react';
import OnboardingScreen from 'src/containers/OnboardingScreen';
import MessengerWidget from 'src/components/MessengerSettings/MessengerWidget';
import { Button } from '@Thread-Magic/jasmine';
import LinkButton from 'src/components/LinkButton';
import { useMutation, useQuery } from 'react-query';
import { chatgenieAPI } from 'src/config/api';
import queryKeys from 'src/constants/queryKeys';
import useToast from 'src/hooks/useToast';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { psaTypes } from 'src/constants';
import { yupResolver } from '@hookform/resolvers/yup';
import FormInputWithLabel from 'src/components/FormInputWithLabel';
import useAuthUser from 'src/hooks/useAuthUser';
import { setLocalStorage } from 'src/utils';
import * as Styled from './styles';
import content from './constants';
import connectwiseConfig from './configs/connectwiseConfig';
import kaseyaConfig from './configs/kaseyaConfig';
import autotaskConfig from './configs/autotaskConfig';
import haloConfig from './configs/haloConfig';

const FormRenderers = {
  [psaTypes.CONNECTWISE]: connectwiseConfig,
  [psaTypes.KASEYA_BMS]: kaseyaConfig,
  [psaTypes.AUTOTASK]: autotaskConfig,
  [psaTypes.INTERNAL_PSA]: connectwiseConfig,
  [psaTypes.HALO]: haloConfig
};

const SignupMessengerSettings = () => {
  const { toast } = useToast();
  const history = useHistory();
  const { psaType } = useAuthUser();

  const formConfig = FormRenderers[psaType];
  const { submitDataNormalizer, fieldsArray, fieldNames, schema } = formConfig;

  const {
    control,
    reset,
    setValue,
    formState: { errors, isValid },
    handleSubmit,
    getValues
  } = useForm({
    defaultValues: schema.cast(),
    resolver: yupResolver(schema),
    mode: 'onChange'
  });

  const { appId, company: { id: companyId } = {} } = useAuthUser();
  const { data: messengerSettings, isLoading } = useQuery(
    [queryKeys.GET_MESSENGER_SETTINGS, companyId],
    () => chatgenieAPI.getMessengerSettings()
  );

  const updateMessengerSettings = useMutation(data => chatgenieAPI.updateMessengerSettings(data), {
    onSuccess() {
      setLocalStorage({
        onboardingStep: 'success'
      });
      history.push('/signup/success');
    },
    onError: error => {
      toast.error(error.message);
    }
  });

  const onSubmit = data => {
    updateMessengerSettings.mutate(submitDataNormalizer(data));
  };

  const handleNavigateBack = () => {
    setLocalStorage({
      onboardingStep: 'design'
    });
    history.push('/signup/messenger/design');
  };

  useEffect(() => {
    if (messengerSettings) {
      reset(messengerSettings);
    }
  }, [messengerSettings, reset]);

  return (
    <OnboardingScreen
      title={content.TITLE}
      step={5}
      isLoading={isLoading}
      aside={<MessengerWidget appId={appId} />}
    >
      <Styled.Description>{content.DESCRIPTION}</Styled.Description>
      <Styled.Form onSubmit={handleSubmit(onSubmit)} id="messenger-settings">
        {fieldsArray.map(item => (
          <Styled.FormItem key={item.id}>
            <Controller
              control={control}
              name={item.id}
              render={({ field: { onChange, ref, value } }) => {
                // Status field is dependent on support board field
                const fieldOptions =
                  item.fieldKey === fieldNames.default_status
                    ? query => item.options(query, getValues(fieldNames.support_board)?.id)
                    : item.options;
                const disabled =
                  item.fieldKey === fieldNames.default_status &&
                  !getValues(fieldNames.support_board)?.id;

                const onChangeHandler = (...args) => {
                  onChange(...args);
                  if (item.fieldKey === fieldNames.support_board) {
                    setValue(fieldNames.default_status, null);
                  }
                };

                return (
                  <FormInputWithLabel
                    name={item.name}
                    label={item.label}
                    value={value}
                    ref={ref}
                    disabled={disabled}
                    fieldType="async-select"
                    getOptionValue={option => option.name}
                    getOptionLabel={option => option.name}
                    fieldOptions={fieldOptions}
                    onChange={onChangeHandler}
                    placeholder={item.placeholder}
                    errorMessage={errors[item.name]?.message}
                  />
                );
              }}
            />
          </Styled.FormItem>
        ))}
      </Styled.Form>

      <Styled.Actions>
        <Button
          size="large"
          type="submit"
          form="messenger-settings"
          disabled={!isValid}
          isLoader={updateMessengerSettings.isLoading}
        >
          Continue
        </Button>
        <LinkButton styleMode="outline" size="large" type="button" onClick={handleNavigateBack}>
          Previous
        </LinkButton>
      </Styled.Actions>
    </OnboardingScreen>
  );
};

export default SignupMessengerSettings;
