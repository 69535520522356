import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import MagicAIBanner from 'src/assets/images/banner-magic-min.jpg';
import MagicAILogo from 'src/assets/icons/magic-icon.svg';
import useToast from 'src/hooks/useToast';
import { chatgenieAPI } from 'src/config/api';
import ConfirmModal from 'src/components/ConfirmModal';
import * as Styled from './style';
import { normalizeClientCompanyFilters } from '../utils';
import { MAGIC_AI_STATES } from '../utils/constants';
import { useMagicAiTrial } from '../context/magicAiTrialContext';

const FLOW_TEMPLATE_NAME = 'Magic AI Template';

const MagicAiTrial = ({ viewFiltersAndSorting, view }) => {
  const { setIsTrialPeriodRunning, isTrialPeriodRunning, isLoadingTrialPeriod, refetch, daysLeft } =
    useMagicAiTrial();

  const history = useHistory();
  const [confirmMagicAITrial, setConfirmMagicAITrial] = useState(false);
  const { toast } = useToast();

  const { mutateAsync: startMagicAITrial, isLoading } = useMutation({
    mutationFn: queryParams =>
      chatgenieAPI.updateMagicAIStatuses({
        action: MAGIC_AI_STATES.TRIAL,
        query: queryParams
      })
  });

  const handleMagicAITrialClick = async () => {
    if (!view) {
      toast.warn('Please select a view first!');
      return;
    }

    const normalizedFilters = normalizeClientCompanyFilters(viewFiltersAndSorting.filters);
    const queryParams = new URLSearchParams();

    Object.keys(normalizedFilters).forEach(key => {
      if (normalizedFilters[key]) {
        queryParams.set(key, normalizedFilters[key]);
      }
    });
    setConfirmMagicAITrial(true);

    const result = await startMagicAITrial(queryParams.toString());
    const updatedCompaniesCount = result.data?.updated_count || 0;
    toast.success(
      `Magic AI trial was enabled successfully for ${updatedCompaniesCount} companies!`
    );
    setIsTrialPeriodRunning(true);
    refetch();
  };

  const handleCancelModal = () => {
    setConfirmMagicAITrial(false);
  };

  const handleConfirmModal = () => {
    history.push(`/dashboard/flows/configure-flow?name=${FLOW_TEMPLATE_NAME}`);
  };

  if ((daysLeft && daysLeft < 1) || isLoadingTrialPeriod) {
    return <></>;
  }

  return (
    <Styled.Wrapper>
      <Styled.MagicAIBanner src={MagicAIBanner} alt="Magic AI trial banner" />

      <Styled.MagicAIContent>
        <Styled.MagicAiBannerIconWrapper>
          <img src={MagicAILogo} alt="Magic AI logo" />
        </Styled.MagicAiBannerIconWrapper>
        <Styled.TrialInfo>
          <p>
            <strong>
              {!isTrialPeriodRunning ? 'Start your Magic AI trial' : 'You’re on the Magic AI trial'}
            </strong>
          </p>
          <p>
            {!isTrialPeriodRunning
              ? 'Enable AI for your managed customers and start your 30 day trial.'
              : `Your Magic AI trial will end in ${daysLeft} days.`}
          </p>
        </Styled.TrialInfo>
        {!isTrialPeriodRunning && (
          <Styled.TrialStartBtn onClick={handleMagicAITrialClick} type="submit">
            Enable AI
          </Styled.TrialStartBtn>
        )}
      </Styled.MagicAIContent>

      <ConfirmModal
        className="magic-ai-trial-modal"
        title={
          <Styled.ModalTitle>
            <img src={MagicAILogo} alt="Magic AI logo" />
            <p>Magic is enabled.</p>
          </Styled.ModalTitle>
        }
        description="Amazing! We’ve enabled AI for all your managed customers. Next set up a flow to run
              the action."
        isOpen={confirmMagicAITrial}
        cancelText="Do it later"
        submitText="Create a Magic AI flow"
        toggleHandler={handleCancelModal}
        onSubmit={handleConfirmModal}
        isLoading={isLoading}
        btnFullWidth
      />
    </Styled.Wrapper>
  );
};

export default MagicAiTrial;
