import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import createAuth0Client from '@auth0/auth0-spa-js';
import { getUrlParam, setLocalStorage } from '../../utils';

const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState({}, document.title, window.location.pathname);

export const Auth0Context = React.createContext();

export const useAuth0 = () => useContext(Auth0Context);

export const useGetToken = ({ loading, getTokenHandler, getTokenCallback }) => {
  useEffect(() => {
    if (!loading) {
      const fn = async () => {
        const token = await getTokenHandler();
        return token;
      };
      fn().then(token => getTokenCallback(token));
    }
  }, [loading]);
};

export const useLoginWithRedirect = ({ loading, isAuthenticated, loginWithRedirect, path }) => {
  useEffect(() => {
    if (loading || isAuthenticated) {
      return;
    }
    const fn = async () => {
      await loginWithRedirect({
        appState: { targetUrl: path }
      });
    };
    fn();
  }, [loading, isAuthenticated, loginWithRedirect, path]);
};

export const Auth0Provider = ({
  children,
  history,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  screenMode
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState();
  const [user, setUser] = useState();
  const [auth0Client, setAuth0] = useState();
  const [loading, setLoading] = useState(true);
  const [popupOpen, setPopupOpen] = useState(false);

  // NB: we should enable cacheLocation to use localstorage to deal with browsers strict against 3rd party cookies (e.g. Firefox, Incognito)
  // https://auth0.com/docs/libraries/auth0-single-page-app-sdk#change-storage-options
  const config = {
    domain: process.env.REACT_APP_AUTH_DOMAIN,
    client_id: process.env.REACT_APP_AUTH_CLIENT_ID,
    audience: process.env.REACT_APP_AUTH_AUDIENCE,
    redirect_uri: window.location.origin,
    screen_mode: screenMode,
    useCookiesForTransactions: true,
    cacheLocation: 'localstorage'
  };

  useEffect(() => {
    const initAuth0 = async () => {
      const auth0FromHook = await createAuth0Client(config);
      setAuth0(auth0FromHook);

      // We check for `signup/chat` to avoid conflict because Slack and
      // Teams onboarding also uses authorization code flow similar to Auth0
      // Firefox is not catching code/state so we persist it in localStorage
      if (window.location.pathname.includes('signup/chat')) {
        const code = getUrlParam('code');
        const state = getUrlParam('state');

        setLocalStorage({
          chatIntegrationCode: code,
          chatIntegrationState: state && JSON.parse(decodeURIComponent(state))
        });
        history.replace('/signup/chat');
      }
      // We check for `deployment-assist` to avoid conflict with auth0
      // Partner Center onboarding also uses authorization code flow similar to Auth0
      // Firefox is not catching code so we persist it in localStorage
      if (window.location.pathname.includes('dashboard/deployment-assist/auth')) {
        setLocalStorage({
          msAuthCode: getUrlParam('code')
        });

        history.push('/dashboard/deployment-assist/auth');
      }

      if (window.location.search.includes('code=')) {
        const { appState } = await auth0FromHook.handleRedirectCallback();

        onRedirectCallback(appState);
      }

      const isAuthenticated = await auth0FromHook.isAuthenticated();

      setIsAuthenticated(isAuthenticated);

      if (isAuthenticated) {
        const user = await auth0FromHook.getUser();
        setUser(user);
      }

      setLoading(false);
    };
    initAuth0();
    // eslint-disable-next-line
  }, []);

  const loginWithPopup = async (params = {}) => {
    setPopupOpen(true);
    try {
      await auth0Client.loginWithPopup(params);
    } catch (error) {
      // eslint-disable-next-line no-new
      new Error(error);
    } finally {
      setPopupOpen(false);
    }
    const user = await auth0Client.getUser();
    setUser(user);
    setIsAuthenticated(true);
  };

  const handleRedirectCallback = async () => {
    setLoading(true);
    await auth0Client.handleRedirectCallback();
    const user = await auth0Client.getUser();
    setLoading(false);
    setIsAuthenticated(true);
    setUser(user);
  };

  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        loading,
        popupOpen,
        loginWithPopup,
        handleRedirectCallback,
        getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
        loginWithRedirect: (...p) => auth0Client.loginWithRedirect(...p),
        getTokenSilently: (...p) => auth0Client.getTokenSilently(...p),
        getTokenWithPopup: (...p) => auth0Client.getTokenWithPopup(...p),
        logout: (...p) => auth0Client.logout(...p)
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};

Auth0Provider.propTypes = {
  children: PropTypes.node,
  onRedirectCallback: PropTypes.func,
  redirectException: PropTypes.bool,
  screenMode: PropTypes.string
};

Auth0Provider.defaultProps = {
  children: '',
  screenMode: 'logIn',
  onRedirectCallback: () => {},
  redirectException: false
};
