import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { ReactComponent as DangerIcon } from 'src/assets/icons/danger.svg';
import { Tooltip } from 'src/components/Tooltip/style';
import theme from 'src/config/theme';
import SimpleSelect from '../SimpleSelect';
import {
  ErrorMessageStyled,
  HelpIconStyled,
  InputStyled,
  LabelStyled,
  Message,
  WrapperStyled
} from './style';
import AsyncSelect from '../SimpleSelect/async';

const FormInputWithLabel = React.forwardRef(
  (
    {
      id,
      label,
      type,
      onChange,
      onBlur,
      value,
      state,
      placeholder,
      errorMessage,
      children,
      disabled,
      helpTooltip,
      size,
      fieldType,
      fieldOptions,
      ...props
    },
    ref
  ) => (
    <WrapperStyled>
      <LabelStyled hasError={errorMessage} htmlFor={id}>
        {label}
      </LabelStyled>
      {helpTooltip && (
        <Fragment>
          <HelpIconStyled
            data-tip
            data-for={id}
            width={16}
            height={16}
            fill={theme.colors.brand.light.base}
          />
          <Tooltip id={id} effect="solid" delayHide={1000} clickable>
            {helpTooltip}
          </Tooltip>
        </Fragment>
      )}
      {fieldType === 'select' ? (
        <SimpleSelect
          {...props}
          options={fieldOptions}
          type={type}
          name={id}
          id={id}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          inputRef={ref}
          isDisabled={disabled}
          hasError={errorMessage}
          placeholder={placeholder}
          customStyles={errorMessage && { controlStyles: { borderColor: theme.colors.red1 } }}
        />
      ) : fieldType === 'async-select' ? (
        <AsyncSelect
          {...props}
          options={fieldOptions}
          name={id}
          id={id}
          inputRef={ref}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          isDisabled={disabled}
          hasError={errorMessage}
          placeholder={placeholder}
          customStyles={errorMessage && { controlStyles: { borderColor: theme.colors.red1 } }}
        />
      ) : (
        fieldType === 'input' && (
          <InputStyled
            {...props}
            invalid={state.invalid}
            type={type}
            name={id}
            id={id}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            disabled={disabled}
            hasError={errorMessage}
            placeholder={placeholder}
            size={size}
          />
        )
      )}
      {children}
      {errorMessage && (
        <Message>
          <DangerIcon width={16} height={16} fill={theme.colors.red1} />
          <ErrorMessageStyled>{errorMessage}</ErrorMessageStyled>
        </Message>
      )}
    </WrapperStyled>
  )
);

FormInputWithLabel.propTypes = {
  state: PropTypes.objectOf(PropTypes.any),
  type: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  size: PropTypes.string,
  placeholder: PropTypes.string,
  errorMessage: PropTypes.string,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  helpTooltip: PropTypes.node,
  onBlur: PropTypes.func,
  fieldType: PropTypes.string,
  fieldOptions: PropTypes.oneOfType([PropTypes.array, PropTypes.func])
};

FormInputWithLabel.defaultProps = {
  state: {},
  type: 'text',
  id: '',
  label: '',
  onChange: () => {},
  onBlur: undefined,
  value: '',
  placeholder: '',
  errorMessage: '',
  children: null,
  disabled: false,
  helpTooltip: null,
  size: '',
  fieldType: 'input',
  fieldOptions: []
};

export default FormInputWithLabel;
