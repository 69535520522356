import React from 'react';
import MagicAILogo from 'src/assets/icons/magic-icon.svg';
import MessengerLogo from 'src/assets/icons/messenger.svg';
import { FiLoader } from 'react-icons/fi';
import ReactTooltip from 'react-tooltip';
import RankStars from '../components/RankStars';
import ToggleMessengerMagicAI from '../components/ToggleMessengerMagicAI';
import {
  NoValuesDescription,
  MagicAIStatus,
  MessengerStatus,
  Text,
  CollectingData
} from './PartnerQueueClient.style';
import AppIcon from '../components/AppIcon';

const TextWrapper = ({ children, handleClick, row }) => {
  return <Text onClick={() => handleClick(row)}>{children}</Text>;
};

const CollectingDataWrapper = ({ isCollectingData, children }) =>
  isCollectingData ? (
    <CollectingData data-tip data-for="collecting-data-tip">
      <ReactTooltip className="tooltip" id="collecting-data-tip" effect="solid" delayShow={300}>
        We’re still processing your data. Come back in 7 days to see usage.
      </ReactTooltip>
      <FiLoader /> Getting data
    </CollectingData>
  ) : (
    children
  );

export const columns = ({ handleClick }) => [
  {
    key: 'company_name',
    title: 'Name',
    isSort: false,
    component: row => (
      <TextWrapper handleClick={handleClick} row={row}>
        {row.company_name}
      </TextWrapper>
    )
  },
  {
    key: 'type',
    title: 'Type',
    isSort: false,
    component: row => (
      <TextWrapper handleClick={handleClick} row={row}>
        {row.company_type}
      </TextWrapper>
    )
  },
  {
    key: 'thread_usage',
    title: 'Req. 90d',
    isSort: false,
    component: row => (
      <TextWrapper handleClick={handleClick} row={row}>
        <CollectingDataWrapper isCollectingData={row.is_collecting_data}>
          {row.thread_usage === 0 ? (
            <NoValuesDescription>N/A</NoValuesDescription>
          ) : (
            row.thread_usage
          )}
        </CollectingDataWrapper>
      </TextWrapper>
    )
  },
  {
    key: 'is_messenger_enabled',
    title: 'Messenger',
    component: row => (
      <TextWrapper handleClick={handleClick} row={row}>
        <MessengerStatus>
          {row.is_messenger_enabled && <img src={MessengerLogo} alt="Messenger mini logo" />}
          {row.is_messenger_enabled ? 'Enabled' : 'Disabled'}
        </MessengerStatus>
      </TextWrapper>
    ),
    isSort: false
  },
  {
    key: 'magic_ai_status',
    title: 'Magic AI',
    component: row => (
      <TextWrapper handleClick={handleClick} row={row}>
        <MagicAIStatus>
          {['TRIAL', 'ENABLED'].includes(row.magic_ai_status) && (
            <img src={MagicAILogo} alt="Magic AI logo" />
          )}
          {row.magic_ai_status?.toLowerCase()}
        </MagicAIStatus>
      </TextWrapper>
    ),
    isSort: false
  },
  {
    key: 'app',
    title: 'Apps',
    component: row => (
      <TextWrapper handleClick={handleClick} row={row}>
        {row.app.length === 0 ? (
          <NoValuesDescription>N/A</NoValuesDescription>
        ) : (
          row.app.split(',').map(app => <AppIcon app={app.trim()} />)
        )}
      </TextWrapper>
    ),
    isSort: false
  },
  {
    key: 'rank',
    title: 'Rank',
    component: row => (
      <TextWrapper handleClick={handleClick} row={row}>
        <CollectingDataWrapper isCollectingData={row.is_collecting_data}>
          <RankStars rank={row.rank} />
        </CollectingDataWrapper>
      </TextWrapper>
    ),
    isSort: false
  },
  {
    key: '',
    title: '',
    component: row => (
      <ToggleMessengerMagicAI
        magicAIStatus={row.magic_ai_status}
        isMessengerEnabled={row.is_messenger_enabled}
        companyId={row.company_id}
      />
    ),
    isSort: false
  }
];
