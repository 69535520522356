import styled, { css } from 'styled-components/macro';
import { ReactComponent as SpinnerElement } from 'src/assets/icons/spinner.svg';

export const Paragraph = styled.p`
  text-align: center;
`;

export const ButtonStyle = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 44px;
  margin-bottom: 17px;
  padding: 12px;
  border-radius: 4px;
  border: solid 1px ${({ theme }) => theme.colors.gray1};
  white-space: nowrap;
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
  letter-spacing: -0.15px;
  color: #000000;
  transition: border-color 0.2s;
  background-color: #ffffff;
  text-decoration: none;

  &:last-child {
    margin-bottom: 0;
  }

  &:focus,
  &:hover {
    outline: none;
    text-decoration: none;
    border-color: ${({ theme }) => theme.colors.brand.light.hover};
    color: #000000;
  }

  img {
    max-width: 70px;
    object-fit: cover;
    margin-left: 10px;
  }
`;

export const Button = styled.button`
  ${ButtonStyle}
`;

export const Description = styled.p`
  max-width: 400px;
  margin: 0 auto;
  font-size: 14px;
  text-align: center;
  margin-bottom: 30px;
`;

export const Spinner = styled(SpinnerElement)`
  position: relative;
  display: block;
  margin: 20px auto 10px;
`;

export const Wrapper = styled.div`
  max-width: 500px;
  margin: 0 auto;
`;
