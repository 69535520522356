import React from 'react';
import { Success, Error } from 'src/assets/icons';
import { ReactComponent as Spinner } from 'src/assets/icons/spinner.svg';

export const overlayChidrens = {
  fetch_pending: {
    icon: <Spinner />,
    text: 'Loading configuration'
  },
  fetch_rejected: {
    icon: <Spinner />,
    text: 'Error'
  },
  update_pending: {
    icon: <Spinner />,
    text: 'Updating'
  },
  update_fulfilled: {
    icon: Success,
    text: 'Updated successfully'
  },
  update_rejected: {
    icon: Error,
    text: 'Error'
  },
  create_pending: {
    icon: <Spinner />,
    text: 'Creating configuration'
  },
  create_fulfilled: {
    icon: Success,
    text: 'Configuration created successfully'
  },
  create_rejected: {
    icon: Error,
    text: 'Error'
  }
};
