import { ReactComponent as Loupe } from 'src/assets/icons/loupe.svg';
import styled, { css } from 'styled-components';

export const LoupeIcon = styled(Loupe)`
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
`;

export const RemoveButton = styled.button`
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  display: none;
  margin: 0;
  padding: 0;
  border: none;
  background: none;
`;

export const Wrapper = styled.div`
  label {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    height: 32px;
    min-width: 32px;
    margin: 0;
    font-size: 12px;
    line-height: 14px;
    background-color: transparent;
    cursor: pointer;
  }

  input {
    width: 0;
    padding: 8px 0;
    border: none;
    border-radius: 5px;
    font-size: 12px;
    line-height: 14px;
    background-color: transparent;
    transition: width 0.3s, padding 0.3s;
    opacity: 0;
    color: #333333;

    ${({ isActive }) =>
      isActive &&
      css`
        width: 250px;
        padding: 8px 32px 8px 30px;
        opacity: 1;

        & + ${RemoveButton} {
          display: block;
        }
      `}

    &:focus {
      outline: none;
      width: 250px;
      padding: 8px 32px 8px 30px;
      box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.brand.light.base};
      opacity: 1;
    }

    &:focus + ${RemoveButton} {
      display: block;
    }

    &::placeholder {
      color: ${({ theme }) => theme.colors.text.variant2};
      opacity: 1;
    }
  }
`;
