import styled from 'styled-components';

export const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  user-select: none;
  border-radius: 4px;
  max-height: 300px;
  overflow-y: auto;
`;

export const ListItem = styled.li`
  padding: 10px 16px;
  margin: 0;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.1s;

  &:hover {
    background-color: #f1f2f3;
  }
`;
