import { usePostHog } from 'posthog-js/react';

export const useSsoFlag = () => {
  const posthog = usePostHog();
  if (posthog.isFeatureEnabled('inbox-sso')) {
    return true;
  }

  return false;
};
