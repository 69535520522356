import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import useDebounce from 'src/hooks/useDebounce';
import { ReactComponent as RemoveIcon } from 'src/assets/icons/remove.svg';

import * as Styled from './styles';

export const useSearch = () => {
  const history = useHistory();
  const { search } = useLocation();
  const query = useMemo(() => new URLSearchParams(search), [search]);
  const [searchValue, setSearchValue] = useState(query.get('query') || '');
  const debouncedSearchTerm = useDebounce(searchValue, 500);

  const searchHandler = value => {
    setSearchValue(value);
    const currentParams = new URLSearchParams(history.location.search);
    currentParams.set('query', value);

    if (value) {
      history.replace({ search: currentParams.toString() });
    } else {
      history.replace({ search: '' });
    }
  };

  return {
    debouncedSearchTerm,
    searchHandler,
    searchValue,
    setSearchValue
  };
};

const Search = ({ value, setValue, className, isExpanded, placeholder }) => (
  <Styled.Wrapper isActive={isExpanded || !!value} className={className}>
    <label htmlFor="search">
      <Styled.LoupeIcon className="loupe-icon" />
      <input
        id="search"
        type="text"
        placeholder={placeholder}
        onChange={event => setValue(event.target.value)}
        value={value}
      />
      {value && (
        <Styled.RemoveButton
          type="button"
          className="remove-button"
          aria-label="remove text"
          onMouseDown={() => setValue('')}
        >
          <RemoveIcon />
        </Styled.RemoveButton>
      )}
    </label>
  </Styled.Wrapper>
);

Search.propTypes = {
  value: PropTypes.string,
  setValue: PropTypes.func,
  className: PropTypes.string,
  isExpanded: PropTypes.bool,
  placeholder: PropTypes.string
};

Search.defaultProps = {
  value: '',
  setValue: () => {},
  className: '',
  isExpanded: false,
  placeholder: 'Search'
};

export default React.memo(Search);
