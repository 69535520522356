import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { chatgenieAPI } from 'src/config/api';
import { isHalo } from 'src/utils/psa';
import * as Styled from '../style';
import CopilotTestChat from '../CopilotTestChat';
import { Card } from '../CopilotTestChat/style';
import PrioritizationSection from './components/PrioritizationSection';
import GenerateAnimation from './components/GenerateAnimation';
import ExceptionsSection from './components/ExceptionsSection';
import ServiceLevelAgreementField from './components/ServiceLevelAgreementField';
import usePrioritizationConfig from './hooks/usePrioritizationConfig';

const newMessageNormalizer = ({ priority, explanation }) => {
  return {
    type: 'copilot',
    name: 'Magic AI',
    body: (
      <Card>
        <h5>Auto prioritize action</h5>
        <div>
          <p>
            <b>{priority}</b>
          </p>
          <p>{explanation}</p>
        </div>
      </Card>
    )
  };
};

function ViewPrioritization({ companyId, data, customRules, runGenerateAnimation }) {
  const { selectedSla, setSelectedSla } = usePrioritizationConfig();
  const handleSendMessage = ({ message }) => {
    return chatgenieAPI.testCopilotPriorities({
      companyId,
      data: {
        message,
        service_level_agreement_id: selectedSla?.id
      }
    });
  };

  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    setIsAnimating(true);
    setTimeout(() => setIsAnimating(false), 2000);
  }, [data]);

  return (
    <Styled.ViewContainer>
      <Styled.ViewInner paddingTop={32}>
        {runGenerateAnimation ? <GenerateAnimation /> : <h2>Prioritization</h2>}
        {isHalo() && <ServiceLevelAgreementField onChange={setSelectedSla} value={selectedSla} />}
        {data.map((section, index) => (
          <PrioritizationSection isFirst={index === 0} key={section.type} data={section} />
        ))}
        <ExceptionsSection data={customRules} />
      </Styled.ViewInner>
      <CopilotTestChat
        title="Test auto set priorities"
        description="When defined, Magic AI will automatically set the priority on the thread. You can define
          these on a channel level by adding it to a flow."
        firstMessage="Hi, I’m Magic AI! Ask me something or tell me your issue and I’ll give you the priority!"
        askAnotherMessage="Ask me another one and I’ll give you the priority!"
        onSend={handleSendMessage}
        isAnimating={isAnimating}
        newMessageNormalizer={newMessageNormalizer}
      />
    </Styled.ViewContainer>
  );
}

ViewPrioritization.propTypes = {
  customRules: PropTypes.object,
  data: PropTypes.arrayOf(PropTypes.object),
  companyId: PropTypes.number.isRequired,
  runGenerateAnimation: PropTypes.bool
};

ViewPrioritization.defaultProps = {
  runGenerateAnimation: false,
  data: {},
  customRules: null
};

export default ViewPrioritization;
