import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';

export const Tooltip = styled(ReactTooltip)`
  &&&& {
    padding: 12px 17px;
    border-radius: 2px;
    background: rgba(0, 187, 153, 0.9);
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    font-size: 12px;
    line-height: 16px;
    color: #ffffff;

    a {
      color: #ffffff;
      text-decoration: underline;

      &:hover,
      &:focus {
        color: #ffffff;
      }
    }

    &::after {
      display: block;
      margin-left: -6px;
      transform: rotate(-45deg) skew(8deg, 8deg);
      z-index: 10;
      border-bottom: 6px solid rgba(0, 187, 153, 0.9);
      border-left: 6px solid rgba(0, 187, 153, 0.9);
      border-right: 6px solid transparent;
      border-top: 6px solid transparent;
      bottom: -5px;
      z-index: -10;
      border-radius: 0px 0px 0px 2px;
    }

    &.place-bottom::after {
      border-top: 6px solid rgba(0, 187, 153, 0.9);
      border-right: 6px solid rgba(0, 187, 153, 0.9);
      border-left: 6px solid transparent;
      border-bottom: 6px solid transparent;
    }

    &.show {
      opacity: 1;
    }
  }
`;
