import React from 'react';
import { FiCheck } from 'react-icons/fi';
import PropTypes from 'prop-types';
import * as Styled from './style';

function Stepper({ activeStep, steps }) {
  return (
    <Styled.Stepper count={steps.length} activeStep={activeStep}>
      {steps.map((step, index) => (
        <Styled.StepperItem key={step.type} active={activeStep >= index}>
          <span>
            <FiCheck />
          </span>
          {step.type}
        </Styled.StepperItem>
      ))}
    </Styled.Stepper>
  );
}

Stepper.propTypes = {
  activeStep: PropTypes.number.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default Stepper;
