import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import queryKeys from 'src/constants/queryKeys';
import { RadioButton } from '@Thread-Magic/jasmine';
import { useHistory, useLocation } from 'react-router-dom';
import { useQuery, useMutation } from 'react-query';
import useToast from 'src/hooks/useToast';
import { chatgenieAPI } from 'src/config/api';
import { ReactComponent as Figure } from 'src/assets/images/teams-placeholder.svg';
import { ReactComponent as PlusIcon } from 'src/assets/icons/plus.svg';
import { ReactComponent as Spinner } from 'src/assets/icons/spinner.svg';

import { useSearch } from 'src/components/Search';
import { INVITATION_TYPES } from 'src/constants';
import { Wrapper } from '../Connect/style';
import * as Styled from './style';

const Apps = ({ match, companyId }) => {
  const { toast } = useToast();
  const history = useHistory();
  const location = useLocation();
  const [activeApp, setActiveApp] = useState(null);
  const { debouncedSearchTerm, searchHandler, searchValue } = useSearch();
  const isUpdateAppScreen = location.state?.action === 'updateApp';

  const { data: apps = [], isLoading } = useQuery(
    [queryKeys.GET_TEAMS_APPS, companyId, debouncedSearchTerm],
    () => chatgenieAPI.getTeamsApps({ companyId, query: searchValue }),
    {
      onError: error => {
        toast.error(error.message);
      }
    }
  );

  const mutationUpdateConfiguration = useMutation(chatgenieAPI.updateChatConfiguration, {
    onSuccess: () => {
      history.push({
        pathname: `/dashboard/clients/companies/${match.params.company}/connect`,
        state: { action: 'updateApp' }
      });
      toast.success(
        <>
          App has been updated. Please uninstall the old app and install this one in the Teams
          tenant.
          <Styled.ToastLink
            target="_blank"
            href="https://docs.getthread.com/article/ry9zssic5c-how-to-install-the-teams-bot-on-your-customers-tenant#upload_the_app_to_microsoft_teams"
          >
            See How
          </Styled.ToastLink>
        </>,
        { duration: 7000 }
      );
    },
    onError: error => {
      if (error.response.status === 400) {
        toast.warn(error.response.data?.message);
      } else {
        toast.error(error.message);
      }
    }
  });

  useEffect(() => {
    if (apps.length) setActiveApp(0);
  }, [apps.length]);

  return (
    <Wrapper>
      <Styled.Title>Choose an App to install</Styled.Title>
      <Styled.CustomSearch
        value={searchValue}
        setValue={searchHandler}
        isExpanded
        placeholder="Search apps"
      />
      {apps.length ? (
        <Styled.Container>
          <Styled.Content>
            <Styled.List>
              {apps.map((item, index) => (
                <Styled.Item key={item.id}>
                  <RadioButton
                    name="apps"
                    onChange={() => setActiveApp(index)}
                    checked={index === activeApp}
                  >
                    <Styled.Logo url={item.logo} />
                    <div>
                      <Styled.AppTitle>{item.title}</Styled.AppTitle>
                      <Styled.AppInfo>{item.shortDescription}</Styled.AppInfo>
                    </div>
                  </RadioButton>
                </Styled.Item>
              ))}
            </Styled.List>
            <Styled.ButtonLink
              onClick={() =>
                history.push({
                  pathname: `${match.url}/teams-app-builder`,
                  state: { action: 'updateApp', backPathname: match.url }
                })
              }
            >
              <PlusIcon />
              Create New App
            </Styled.ButtonLink>
          </Styled.Content>
          <Styled.GroubAction>
            <Styled.Button
              size="large"
              isLoader={mutationUpdateConfiguration.isLoading}
              onClick={() => {
                if (isUpdateAppScreen) {
                  mutationUpdateConfiguration.mutate({
                    companyId: match.params.company,
                    teamsAppId: apps[activeApp].id
                  });
                } else {
                  history.push({
                    pathname: `${match.url.split('/').slice(0, -1).join('/')}/invitation`,
                    state: {
                      teamsAppId: apps[activeApp].id,
                      teamsAppName: apps[activeApp].appName,
                      inviteType: INVITATION_TYPES.MS_TEAMS
                    }
                  });
                }
              }}
            >
              Continue
            </Styled.Button>
            <Styled.ButtonLink onClick={() => history.go(-1)}>Previous</Styled.ButtonLink>
          </Styled.GroubAction>
        </Styled.Container>
      ) : (
        <Styled.Container isLoading={isLoading}>
          {isLoading && <Spinner />}
          <Styled.Figure>
            <Figure />
          </Styled.Figure>
          {debouncedSearchTerm && !apps.length ? (
            <Styled.SubTitle>No search results</Styled.SubTitle>
          ) : (
            <Styled.SubTitle>You don’t have any apps yet</Styled.SubTitle>
          )}
          <Styled.Description>
            Service your customers with a custom branded support landing page on your website or in
            Microsoft Teams.
          </Styled.Description>
          <Styled.Button
            size="large"
            onClick={() => history.push(`${match.url}/teams-app-builder`)}
          >
            Create App
          </Styled.Button>
        </Styled.Container>
      )}
    </Wrapper>
  );
};

Apps.propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  companyId: PropTypes.number.isRequired
};

export default Apps;
