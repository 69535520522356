import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as MessengerIcon } from 'src/assets/icons/messenger.svg';
import { ReactComponent as Check } from 'src/assets/icons/check-soft.svg';
import * as Styled from './style';

export default function WindowsStatusActions({ appId, isInstalled }) {
  const description = isInstalled
    ? 'Client has the auto-authentication on and can chat with Messenger.'
    : 'Command prompt to silent install the app';

  return (
    <Styled.Col>
      <Styled.Subtitle>Installed app</Styled.Subtitle>

      <Styled.WrapperMessenger>
        <Styled.Logo>
          <MessengerIcon width={70} height={70} />
        </Styled.Logo>
        <Styled.Description>
          <Styled.Name>Messenger</Styled.Name>
          <Styled.InfoMessenger>{description}</Styled.InfoMessenger>
          {isInstalled && (
            <Styled.Status>
              <Check />
              Installed
            </Styled.Status>
          )}
          <Styled.Code>
            msiexec /i
            &quot;https://assets.getthread.com/messenger/downloads/desktop/messenger.msi&quot;
            APP_ID={appId} FLOW=customer INSTALL_TYPE=user /qn
          </Styled.Code>
        </Styled.Description>
      </Styled.WrapperMessenger>
    </Styled.Col>
  );
}

WindowsStatusActions.propTypes = {
  appId: PropTypes.string.isRequired,
  isInstalled: PropTypes.bool.isRequired
};
