import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { chatgenieAPI } from 'src/config/api';
import { useMutation } from 'react-query';
import useToast from 'src/hooks/useToast';
import teams from 'src/assets/images/msteams-logo-2.svg';
import slack from 'src/assets/images/slack-logo-2.svg';
import { ReactComponent as Check } from 'src/assets/icons/check-soft.svg';
import { ReactComponent as Spinner } from 'src/assets/icons/spinner.svg';
import * as Styled from './style';
import { Wrapper } from '../Connect/style';
import SlackStatusActions from './SlackStatusActions';
import TeamsStatusActions from './TeamsStatusActions';
import WindowsStatusActions from './WindowsStatusActions';
import TeamsAndSlackDisconnectModals from './TeamsAndSlackDisconnectModals';
import WindowsDisconnectModal from './WindowsDisconnectModal';

const platformTypes = {
  SLACK: {
    name: 'Slack',
    logo: <img src={slack} alt="Slack" />,
    center: 'Slack workspace',
    helpDocUrl:
      'https://slack.com/help/articles/360003125231-Remove-apps-and-custom-integrations-from-your-workspace'
  },
  MS_TEAMS: {
    name: 'Microsoft Teams',
    logo: <img src={teams} alt="MsTeams" />,
    center: 'Microsoft Teams admin center',
    helpDocUrl:
      'https://docs.getthread.com/article/yeag82uzj7-how-to-uninstall-the-microsoft-teams-thread-service-app'
  },
  WINDOWS: {
    name: 'Microsoft 365',
    logo: <img width={70} src="/images/m365-logo.png" alt="M365" style={{ marginTop: '-10px' }} />,
    center: 'M365',
    helpDocUrl:
      'https://docs.getthread.com/article/yeag82uzj7-how-to-uninstall-the-microsoft-teams-thread-service-app'
  }
};

const Status = ({ chatPlatform, companyId, match, appId }) => {
  const history = useHistory();
  const { toast } = useToast();
  const [isFirstConfirmModalOpen, setIsFirstConfirmModalOpen] = useState(false);
  const platform = chatPlatform?.platform;
  const app = chatPlatform?.app;

  const { mutate, isLoading } = useMutation(chatgenieAPI.uninstallChatConfiguration, {
    onSuccess: () => {
      history.push({
        pathname: `/dashboard/clients/companies/${companyId}/connect`,
        state: {
          action: 'updateApp'
        }
      });

      toast.success(`Disconnected successfully`);
    },
    onError: error => {
      toast.error('It was not possible to disconnect your app. Please try again later.');

      // eslint-disable-next-line no-console
      console.error('Error occurred while uninstalling chat configuration', error);
    }
  });

  //

  if (!platform) {
    return <Redirect to={{ pathname: `/dashboard/clients/companies/${companyId}/connect` }} />;
  }
  return (
    <Wrapper>
      <Styled.Title>{platformTypes[platform]?.name} Connect</Styled.Title>
      <Styled.Container>
        <Styled.Col>
          <Styled.Subtitle>Platform</Styled.Subtitle>
          <Styled.Wrapper>
            <Styled.Logo>{platformTypes[platform]?.logo}</Styled.Logo>
            <Styled.Description>
              <Styled.Name>{platformTypes[platform]?.name}</Styled.Name>
              <Styled.PlatformID>{chatPlatform?.platform_id}</Styled.PlatformID>
              {chatPlatform?.is_active && (
                <Styled.Status>
                  <Check />
                  Connected
                </Styled.Status>
              )}
            </Styled.Description>
            {chatPlatform?.is_active ? (
              <Styled.DangerOutlineButton
                styleMode="outline"
                size="small"
                onClick={() => setIsFirstConfirmModalOpen(true)}
              >
                {isLoading ? <Spinner width={20} height={20} /> : 'Disconnect'}
              </Styled.DangerOutlineButton>
            ) : (
              <Styled.Status danger>Not connected</Styled.Status>
            )}
          </Styled.Wrapper>
        </Styled.Col>

        {/* App Actions */}
        {platform === 'SLACK' && <SlackStatusActions companyId={companyId} />}

        {platform === 'MS_TEAMS' && (
          <TeamsStatusActions app={app} currentUrl={match.url} companyId={companyId} />
        )}

        {platform === 'WINDOWS' && (
          <WindowsStatusActions appId={appId} isInstalled={chatPlatform.is_installed} />
        )}

        {/* Disconnect Modals */}
        {platform === 'WINDOWS' ? (
          <WindowsDisconnectModal
            disconnect={() => mutate({ companyId })}
            name={platformTypes[platform]?.name}
            isFirstConfirmModalOpen={isFirstConfirmModalOpen}
            setIsFirstConfirmModalOpen={setIsFirstConfirmModalOpen}
          />
        ) : (
          <TeamsAndSlackDisconnectModals
            disconnect={() => mutate({ companyId })}
            name={platformTypes[platform]?.name}
            workspaceName={platformTypes[platform]?.center}
            helpDocUrl={platformTypes[platform]?.helpDocUrl}
            isFirstConfirmModalOpen={isFirstConfirmModalOpen}
            setIsFirstConfirmModalOpen={setIsFirstConfirmModalOpen}
          />
        )}
      </Styled.Container>
    </Wrapper>
  );
};

Status.propTypes = {
  companyId: PropTypes.number.isRequired,
  match: PropTypes.shape({
    url: PropTypes.string
  }).isRequired,
  chatPlatform: PropTypes.shape({
    platform: PropTypes.string,
    platform_id: PropTypes.string,
    is_active: PropTypes.bool,
    app: PropTypes.object
  }),
  appId: PropTypes.string.isRequired
};

Status.defaultProps = {
  chatPlatform: null
};

const mapStateToProps = state => ({
  chatConfiguration: state.usersReducer.user.chatConfiguration
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Status);
