import testimonialAuthor from 'src/assets/images/testimonial-author1.png';

export const introContent = {
  title: 'Run your business more efficiently with well categorizated service requests',
  description:
    'Let Magic AI tell you what to tackle next. Make everyones lives easier by helping your customers who need it most when they need it. Service Magic!',
  buttonLabel: 'Get Started',
  testimonial: {
    title: 'What people love about Magic AI',
    fullname: 'Tyler Mack',
    position: 'Operations Manager - WBM Technologies',
    photo: testimonialAuthor,
    review:
      'Thread’s AI functionality automates time entries for us by summarizing chats in real time. This is huge and saves us 300 hours a month! We are excited about the ability to power more of our workflow with AI through the Thread and Microsoft integration.'
  },
  benifits: {
    title: 'Magic AI helps you set the right category',
    items: [
      // {
      //   icon: <ChatIcon />,
      //   text: 'Priorities are set within seconds'
      // },
      // {
      //   icon: <QuestionCircled />,
      //   text: 'No P1 issues getting lost'
      // },
      // {
      //   icon: <ClapIcon />,
      //   text: 'Customers are served better'
      // }
    ]
  }
};
