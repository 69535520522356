import { normalizeWorspaces } from 'src/utils/normalizer';
import { workspacesTypes, actionStatuses } from 'src/constants/action-types';

const defaultState = {
  workspaces: [],
  isFetchWorkspaces: false,
  isFetchingAddWorkspace: false,
  errorMessage: ''
};

const map = {
  // GET_WORKSPACES
  [`${workspacesTypes.GET_WORKSPACES}${actionStatuses.FULFILLED}`]: (state, action) => ({
    ...state,
    workspaces: normalizeWorspaces(action.payload.data.data),
    isFetchWorkspaces: false
  }),
  [`${workspacesTypes.GET_WORKSPACES}${actionStatuses.PENDING}`]: state => ({
    ...state,
    workspaces: [],
    isFetchWorkspaces: true
  }),
  [`${workspacesTypes.GET_WORKSPACES}${actionStatuses.REJECTED}`]: (state, action) => ({
    ...state,
    isFetchWorkspaces: false,
    errorMessage: action.payload.response.data.message
  }),

  // ADD_WORKSPACE
  [`${workspacesTypes.ADD_WORKSPACE}${actionStatuses.FULFILLED}`]: state => ({
    ...state,
    isFetchingAddWorkspace: false
  }),
  [`${workspacesTypes.ADD_WORKSPACE}${actionStatuses.PENDING}`]: state => ({
    ...state,
    isFetchingAddWorkspace: true
  }),
  [`${workspacesTypes.ADD_WORKSPACE}${actionStatuses.REJECTED}`]: (state, action) => ({
    ...state,
    isFetchingAddWorkspace: false,
    errorMessage: action.payload.response.data.message
  })
};

export default function workspaces(state = defaultState, action) {
  return (map[action.type] && map[action.type](state, action)) || state;
}
