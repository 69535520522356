import snakeCase from 'lodash.snakecase';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import { ReactComponent as ArrowDownIcon } from 'src/assets/icons/arrow-down-fill.svg';
import { ReactComponent as ArrowUpIcon } from 'src/assets/icons/arrow-up-fill.svg';
import { ReactComponent as Spinner } from 'src/assets/icons/spinner.svg';
import { match } from 'ts-pattern';
import SimpleCheckbox from '../SimpleCheckbox';
import Filters from './components/Filters';
import Search, { useSearch } from '../Search';
import * as Styled from './styles';

/*
 * TablewView component is a table with pagination, sorting, filtering and search like <Table>
 * The main diff is this table allows selecting all items from all pages for bulk editions
 * For bulk editions, the API should receive the filters from views + excluded ids
 */
const TablewView = ({
  rows,
  columns,
  onRowClick,
  isLoading,
  className,
  error,
  getData,
  isSearch,
  showTopPanelMeta,
  selectable,
  initialFilters,
  initialSortKey,
  onSelect,
  selectedIds,
  onExclude,
  excludedIds,
  onSelectAll,
  isAllSelected,
  cellPadding = true,
  clearSelected = false
}) => {
  const { meta, data } = rows;
  const history = useHistory();

  const [activeFilters, setActiveFilters] = useState(initialFilters);
  const [sortColumnActive, setSortColumn] = useState(initialSortKey);
  const { debouncedSearchTerm, searchHandler, searchValue, setSearchValue } = useSearch();

  const handleSelectedChange = useCallback(
    row =>
      ({ target }) => {
        if (isAllSelected) {
          onExclude(prev => {
            return target.checked ? prev.filter(id => id !== row.id) : [...prev, row.id];
          });

          return;
        }

        onSelect(prev => {
          return target.checked ? [...prev, row.id] : prev.filter(id => id !== row.id);
        });
      },
    [isAllSelected]
  );

  const handleSelectAllChange = useCallback(
    ({ target }) => {
      onSelectAll(target.checked);
      onExclude([]);
      onSelect([]);
    },
    [data]
  );

  const getDataHandler = (params = {}) => {
    const { page } = params;

    const queryParams = [
      searchValue && `query=${searchValue || ''}`,
      `page=${page || 1}`,
      sortColumnActive.key &&
        `sort=${sortColumnActive.direction === 'desc' ? '-' : ''}${snakeCase(
          sortColumnActive.key
        )}`,
      ...Object.keys(activeFilters).map(key => {
        const filterValue = activeFilters[key];
        const { filterKey } = columns.filter(item => item.key === key)[0];
        const filterString =
          filterValue instanceof Object
            ? Object.keys(filterValue).reduce(
                (arr, value) => (filterValue[value] ? [...arr, value] : arr),
                []
              )
            : filterValue;

        return filterString?.length ? `filter-${filterKey || snakeCase(key)}=${filterString}` : '';
      })
    ];

    const queryString = queryParams
      .filter(item => !!item)
      .map((query, index) => `${index < 1 ? '?' : '&'}${query}`)
      .join('');

    getData({ queryString, page, searchValue });
  };

  const sortColumnHandler = key => {
    const direction = sortColumnActive.direction === 'asc' ? 'desc' : 'asc';

    if (sortColumnActive.key === key) {
      setSortColumn({ ...sortColumnActive, direction });
    } else {
      setSortColumn({ key, direction: 'asc' });
    }
  };

  const resetHandler = () => {
    setSearchValue('');
    setSortColumn({ key: '', direction: '' });
    setActiveFilters({});
    history.replace({ search: '' });
  };

  const clickRowHandler = row => {
    if (typeof onRowClick === 'function') {
      onRowClick(row);
    }
  };

  const filterHandler = (event, entity, type) => {
    const { value, checked } = event.target;
    const entityValue = match(type)
      .with('checkbox', () => ({ ...activeFilters[entity], [value]: checked }))
      .with('radio', () => ({ [value]: checked }))
      .otherwise(() => '');

    setActiveFilters({
      ...activeFilters,
      [entity]: entityValue
    });
  };

  const resetSelections = () => {
    onSelect([]);
    onExclude([]);
  };

  const isRowSelected = useCallback(
    row => {
      if (isAllSelected) {
        return !excludedIds.find(item => item === row.id);
      }

      return selectedIds.find(item => item === row.id) ?? false;
    },
    [isAllSelected, selectedIds, excludedIds]
  );

  const TableCell = ({ children, ...props }) => {
    if (cellPadding) {
      return <Styled.TableCell {...props}>{children}</Styled.TableCell>;
    }

    return <Styled.TableCellWithoutPadding {...props}>{children}</Styled.TableCellWithoutPadding>;
  };

  useEffect(() => {
    getDataHandler();
  }, [debouncedSearchTerm, sortColumnActive, activeFilters]);

  useEffect(() => {
    if (onSelect) {
      onSelect(selectedIds);
    }
  }, [selectedIds]);

  useEffect(() => {
    if (clearSelected) {
      resetSelections();
    }
  }, [clearSelected]);

  return (
    <Styled.TableWrapper className={className}>
      <Styled.TopPanel>
        <Styled.Wrapper>
          {isSearch && <Search value={searchValue} setValue={searchHandler} isExpanded />}
          {(searchValue ||
            sortColumnActive.key ||
            !!Object.keys(activeFilters).filter(key => activeFilters[key]).length) && (
            <Styled.Reset type="button" onClick={resetHandler}>
              Reset all
            </Styled.Reset>
          )}

          {isAllSelected && (
            <Styled.SelectedInfo>{`${
              meta.total - excludedIds.length
            } selected`}</Styled.SelectedInfo>
          )}

          {!isAllSelected && selectedIds.length > 0 && (
            <Styled.SelectedInfo>{`${selectedIds.length} selected`}</Styled.SelectedInfo>
          )}
        </Styled.Wrapper>

        {!!meta?.total && showTopPanelMeta && (
          <Styled.Info>
            {meta.from}-{meta.to} of {meta.total}
          </Styled.Info>
        )}
      </Styled.TopPanel>
      {data.length ? (
        <Styled.Table>
          <Styled.TableHeader hasTopBorder={isSearch}>
            <tr>
              {selectable && (
                <th className="checkbox">
                  <SimpleCheckbox
                    checked={isAllSelected}
                    indeterminate={excludedIds.length > 0}
                    onChange={handleSelectAllChange}
                  />
                </th>
              )}
              {columns.map(({ key, title, compact, isSort = true, filters, filterType }) => (
                <th key={key} className={[compact ? 'compact' : ''].join(' ')}>
                  {title || ''}
                  {isSort && (
                    <Styled.SortButton
                      type="button"
                      onClick={() => sortColumnHandler(key)}
                      isActive={key === sortColumnActive.key}
                    >
                      {sortColumnActive.direction === 'desc' && key === sortColumnActive.key ? (
                        <ArrowUpIcon />
                      ) : (
                        <ArrowDownIcon />
                      )}
                    </Styled.SortButton>
                  )}
                  {(filters || meta?.filter_values?.[key]) && (
                    <Filters
                      data={filters || meta.filter_values[key]}
                      name={key}
                      onChangeFilter={filterHandler}
                      onClear={() => setActiveFilters({ ...activeFilters, [key]: '' })}
                      value={activeFilters[key]}
                      type={filterType}
                    />
                  )}
                </th>
              ))}
            </tr>
          </Styled.TableHeader>
          <tbody>
            {data.map(row => (
              <Styled.TableRow
                key={row.id}
                onClick={() => onRowClick(row) && clickRowHandler(row)}
                disabled={row.disabled}
              >
                {selectable && (
                  <Styled.TableCell className="checkbox">
                    <SimpleCheckbox
                      checked={isRowSelected(row)}
                      onChange={handleSelectedChange(row)}
                    />
                  </Styled.TableCell>
                )}
                {columns.map(column => (
                  <TableCell
                    key={column.key}
                    className={[column.compact ? 'compact' : ''].join(' ')}
                  >
                    {typeof column.component === 'function'
                      ? column.component(row)
                      : row[column.key]}
                  </TableCell>
                ))}
              </Styled.TableRow>
            ))}
          </tbody>
        </Styled.Table>
      ) : null}
      {error && <Styled.Message typeView="block">{error}</Styled.Message>}
      {isLoading && (
        <Styled.Loader>
          <Spinner />
        </Styled.Loader>
      )}
      {!data.length && !error && !isLoading && (
        <Styled.Placeholder>There is no data to display</Styled.Placeholder>
      )}
      <Styled.BottomPanel>
        {meta?.last_page > 1 && (
          <ReactPaginate
            previousLabel="Previous"
            nextLabel="Next"
            forcePage={meta.current_page - 1}
            breakLabel="..."
            breakClassName="break-me"
            pageCount={meta.last_page}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={value => getDataHandler({ page: value.selected + 1 })}
            containerClassName="pagination"
            subContainerClassName="pages pagination"
            activeClassName="active"
          />
        )}
        {!!meta?.total && (
          <Styled.Info>
            {meta.from}-{meta.to} of {meta.total}
          </Styled.Info>
        )}
      </Styled.BottomPanel>
    </Styled.TableWrapper>
  );
};

TablewView.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      component: PropTypes.any,
      compact: PropTypes.bool,
      filters: PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.string.isRequired,
          value: PropTypes.any
        })
      )
    })
  ),
  isLoading: PropTypes.bool,
  selectable: PropTypes.bool,
  error: PropTypes.string,
  onRowClick: PropTypes.func,
  getData: PropTypes.func,
  rows: PropTypes.objectOf(PropTypes.any),
  className: PropTypes.string,
  isSearch: PropTypes.bool,
  showTopPanelMeta: PropTypes.bool,
  initialFilters: PropTypes.objectOf(PropTypes.any),
  initialSortKey: PropTypes.objectOf(PropTypes.any),
  cellPadding: PropTypes.bool,
  onSelect: PropTypes.func,
  selectedIds: PropTypes.arrayOf(PropTypes.number),
  clearSelected: PropTypes.bool,
  onSelectAll: PropTypes.func,
  isAllSelected: PropTypes.bool,
  onExclude: PropTypes.func,
  excludedIds: PropTypes.arrayOf(PropTypes.number)
};

TablewView.defaultProps = {
  className: '',
  isLoading: false,
  selectable: false,
  error: '',
  onRowClick: () => {},
  getData: () => {},
  columns: [],
  rows: { data: [], meta: {} },
  isSearch: true,
  showTopPanelMeta: true,
  initialFilters: {},
  initialSortKey: {
    key: '',
    direction: ''
  },
  cellPadding: true,
  onSelect: () => {},
  selectedIds: [],
  clearSelected: false,
  onSelectAll: () => {},
  isAllSelected: false,
  onExclude: () => {},
  excludedIds: []
};

export default React.memo(TablewView);
