export const companyTypes = {
  GET_PARTNER_CLIENTS: 'GET_PARTNER_CLIENTS',
  UPDATE_PARTNER_CLIENTS_DATA: 'UPDATE_PARTNER_CLIENTS_DATA'
};

export const actionStatuses = {
  FULFILLED: '_FULFILLED',
  PENDING: '_PENDING',
  REJECTED: '_REJECTED'
};
