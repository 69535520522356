import React from 'react';
import PropTypes from 'prop-types';
import config from './config';
import * as Styled from '../styles';
import withController from '../withController';

const Connectwise = ({ onSubmit, handleSubmit, children }) => {
  const onFormSubmit = data => {
    const formatted = {
      [config.fields.HOST.fieldKey]: data[config.fields.HOST.name],
      [config.fields.COMPANY_NAME.fieldKey]: data[config.fields.COMPANY_NAME.name],
      [config.fields.PUBLIC_KEY.fieldKey]: data[config.fields.PUBLIC_KEY.name],
      [config.fields.PRIVATE_KEY.fieldKey]: data[config.fields.PRIVATE_KEY.name]
    };
    onSubmit(data, formatted);
  };

  return (
    <Styled.Form id={config.formId} onSubmit={handleSubmit(onFormSubmit)}>
      {children}
    </Styled.Form>
  );
};

Connectwise.propTypes = {
  onSubmit: PropTypes.func,
  handleSubmit: PropTypes.func.isRequired,
  children: PropTypes.any.isRequired
};

Connectwise.defaultProps = {
  onSubmit: () => {}
};

export default withController(Connectwise, config);
