import ReactGA from 'react-ga';
import { isProduction } from 'src/utils';

const gaInit = ({ trackingId, options }) => {
  if (!isProduction()) return null;

  const initialize = ReactGA.initialize(trackingId, {
    ...options
  });
  ReactGA.pageview(window.location.pathname + window.location.search);

  return initialize;
};

export const gaEvent = data => {
  ReactGA.event(data);
};

export const gaSet = data => {
  if (!isProduction()) return null;
  ReactGA.set(data);
};

export default gaInit;
