import styled, { css } from 'styled-components/macro';
import { ReactComponent as Loupe } from 'src/assets/icons/loupe.svg';

const wrapperStyle = css`
  position: relative;
  display: flex;
  height: calc(100% + 80px);
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding: 6vw 6vw 8vw;
  background: ${({ theme }) => theme.colors.background.default};
  min-height: ${({ minHeight }) => (minHeight ? `${minHeight}px` : '90px')};
`;

export const LayoutCompact = styled.div`
  ${wrapperStyle}
`;

export const LayoutFullPage = styled.div`
  ${wrapperStyle}
  width: 100%;
  height: 100%;
  align-items: flex-start;
  padding: 40px 44px;
`;

export const Title = styled.h3`
  margin: 16px 0 8px;
  font-weight: 700;
  font-size: 20px;
  line-height: 33px;
`;

export const Description = styled.p`
  max-width: 500px;
  margin-bottom: 20px;
  text-align: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
`;

export const ModalOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding-bottom: 20px;
  background-color: ${({ theme }) => theme.colors.background.default}99;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 45px;

  button {
    margin-top: 10px;
  }
`;

export const Content = styled.div`
  max-width: 500px;

  h3 {
    margin-bottom: 8px;
    font-weight: 700;
    font-size: 20px;
    line-height: 33px;
    color: ${({ theme }) => theme.colors.text.default};
  }

  p {
    margin: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: ${({ theme }) => theme.colors.text.default};
  }
`;

export const List = styled.table`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0;
  padding: 0;
`;

export const AppsHeader = styled.th`
  margin-bottom: 16px;
  padding: 6px 16px;
  background: ${({ theme }) => theme.colors.background.secondary};

  td {
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
  }
`;

export const App = styled.tr`
  position: relative;
  display: flex;
  margin-bottom: 16px;
  padding-bottom: 28px;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.variant1};
`;

export const Preview = styled.td`
  margin-right: 34px;
`;

export const Logo = styled.div`
  width: 86px;
  height: 86px;
  border-radius: 4px;
  background-image: url(${({ src }) => src});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`;

export const AppContent = styled.td`
  h4 {
    margin: 10px 0 0;
    font-weight: 600;
    font-size: 16px;
    line-height: 33px;
    color: ${({ theme }) => theme.colors.text.default};
  }

  p {
    margin: 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: ${({ theme }) => theme.colors.text.default};
  }
`;

export const Actions = styled.td`
  display: flex;
  align-items: flex-start;
  margin: 12px 16px 0 auto;
`;

export const Action = styled.button`
  display: block;
  align-items: center;
  padding: 12px;
  border: none;
  background-color: transparent;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: ${({ theme }) => theme.colors.brand.light.base};

  svg {
    width: 20px;
    height: 20px;
    margin-right: 5px;
    margin-bottom: 4px;
    ${({ isStrokeStyle, theme }) =>
      isStrokeStyle
        ? `stroke: ${theme.colors.brand.light.base};`
        : `fill: ${theme.colors.brand.light.base};`}
  }

  ${({ danger }) =>
    danger &&
    css`
      color: ${({ theme }) => theme.colors.red1};

      svg {
        stroke: ${({ theme }) => theme.colors.red1};
      }
    `}
`;

export const LoupeIcon = styled(Loupe)`
  width: 18px;
  height: 18px;
  margin-right: 8px;
  padding: 2px;
`;

export const SearchWrapper = styled.div`
  margin-bottom: 16px;
`;

export const EmptyPlaceholder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: 15px;
  color: ${({ theme }) => theme.colors.text.secondary};
`;
