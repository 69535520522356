import { useCallback, useState } from 'react';
import { chatgenieAPI } from 'src/config/api';

const usePartnerOnboard = () => {
  const [isOnBoarded, setIsOnBoarded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleOnBoardPartner = useCallback(code => {
    setIsLoading(true);
    chatgenieAPI.partnerCenter
      .onBoard({ code })
      .then(() => {
        setIsOnBoarded(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return {
    isLoading,
    isOnBoarded,
    onBoardPartner: handleOnBoardPartner
  };
};

export default usePartnerOnboard;
