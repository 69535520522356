import React, { useState } from 'react';
import FormInputWithLabel from 'src/components/FormInputWithLabel';
import useAuthUser from 'src/hooks/useAuthUser';
import CopyToClipboard from 'react-copy-to-clipboard';
import SimpleButton from 'src/components/SimpleButton';
import { Code } from 'src/containers/Installation/style';
import { FiArrowRight } from 'react-icons/fi';
import { EmbeddedPodSettingsWrapper, FormContent, SettingTitle } from '../../style';
import * as Styled from '../../../../pages/SignupTicketing/styles';

const customTabTypes = [
  {
    id: 1,
    label: 'Thread: Real-time Notes',
    path: 'chat',
    description:
      'This app allows you to engage with customers in real-time, instantly update ticket details, and access a wide range of additional features.'
  },
  {
    id: 2,
    label: 'Thread: Auto Time Entries',
    path: 'timepad',
    description:
      'Easily submit and track time entries with automatic generation and comprehensive visibility of submitted entries.'
  }
];

export default function EmbeddedPodSettings() {
  const { appId } = useAuthUser();
  const [customTab, setCustomTab] = useState(customTabTypes[0]);
  const customTabUrl = `${process.env.REACT_APP_INBOX_URL}/halo/${customTab.path}/$FAULTID?agentId=$LOGGEDINAGENTID&appId=${appId}`;

  return (
    <FormContent>
      <SettingTitle marginBottom={12}>Thread Custom Tabs</SettingTitle>
      <p>Embedded Thread apps to enhance your productivity and collaboration within the PSA</p>
      <FormInputWithLabel
        fieldType="select"
        label="Custom Tab to configure"
        value={customTab}
        onChange={setCustomTab}
        fieldOptions={customTabTypes}
        placeholder="Select Custom Tab"
      />
      <p>{customTab.description}</p>
      <EmbeddedPodSettingsWrapper>
        <h6>Installation steps</h6>
        <ol>
          <li>
            Navigate to your PSA:
            <br /> <b>Configuration</b> <FiArrowRight /> <b>Custom Objects</b> <FiArrowRight />{' '}
            <b> Custom Tabs</b>
          </li>
          <li>
            Select <b>Ticket</b> as <b>Entity</b> at the top-left and click <b>New</b> button at the
            top-right
          </li>
          <li>
            Fill in the following details and click <b>Save</b>:
            <ul>
              <li>
                Tab Name:<b> {customTab.label}</b> (or any name you want)
              </li>
              <li>
                Sequence: <b>1</b> (any order number you want)
              </li>
              <li>
                Type: <b>Iframe</b>
              </li>
              <li>
                URL:
                <Code>{customTabUrl}</Code>
                <CopyToClipboard text={customTabUrl}>
                  <SimpleButton size="small" type="button">
                    Copy
                  </SimpleButton>
                </CopyToClipboard>
              </li>
            </ul>
          </li>
          <li>
            Ensure the newly added tab is included in the <b>Allowed Custom Tabs</b> list under{' '}
            <b>Ticket Type</b> <FiArrowRight /> <b>Allowed Values</b>, or verify that{' '}
            <b>Allow all Custom Tabs</b> is checked.
          </li>
          <li>Well done! The custom tabs should now appear on the ticket screen.</li>
        </ol>
      </EmbeddedPodSettingsWrapper>
      <Styled.FormContainer />
    </FormContent>
  );
}
