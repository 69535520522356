import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import styled from 'styled-components';

export const ModalStyled = styled(Modal)`
  max-width: 770px;
  margin-top: 88px;

  .modal-content {
    padding: 20px 60px 30px;
  }
`;

export const ModalHeaderStyled = styled(ModalHeader)`
  display: flex;
  padding: 0;
  border: none;

  .modal-title {
    margin-bottom: 8px;
    font-size: 17px;
    line-height: 26px;
    font-weight: bold;
  }

  .close {
    position: absolute;
    top: 15px;
    right: 15px;
    margin: 0;
    padding: 8px;
    font-size: 24px;
    font-weight: 300;
    border: 1px solid transparent;
    transition: border-color 0.2s;

    &:focus {
      outline: none;
      border-color: ${({ theme }) => theme.colors.accent.main};
    }

    span {
      display: block;
      height: 15px;
      width: 15px;
      line-height: 15px;
    }
  }
`;

export const ModalBodyStyled = styled(ModalBody)`
  padding: 0;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0, 09px;
  color: #2e2e2e;

  img {
    max-width: 100%;
    margin: 10px 0;
  }

  ol + img {
    margin-top: 0;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  ol {
    padding-left: 16px;
  }
`;
