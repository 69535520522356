import React, { useState } from 'react';
import { SelectAsyncPaginate } from '@Thread-Magic/jasmine';
import { ReactComponent as DefaultViewIcon } from 'src/assets/icons/dc-onboarding-default-views.svg';
import * as Styled from 'src/pages/DeploymentCenter/PartnerOnboarding/style';
import SimpleButton from 'src/components/SimpleButton';
import { chatgenieAPI } from 'src/config/api';
import useAdminViews from 'src/hooks/useAdminViews';
import { useHistory } from 'react-router-dom';

const getCompanyTypes = (query, page) =>
  chatgenieAPI.getCompanyTypes({ query, page, normalizer: res => res.data });

const getAgreementTypes = (query, page) =>
  chatgenieAPI.getAgreementTypes({ query, page, normalizer: res => res.data });

const DefaultViewStep = () => {
  const history = useHistory();
  const [companyTypes, setCompanyTypes] = useState([]);
  const [agreementTypes, setAgreementTypes] = useState([]);
  const { createAdminView, isLoading } = useAdminViews({
    type: 'deployment_center'
  });

  const createDefaultView = async () => {
    const companyTypeIds = companyTypes.map(ct => ct.id);
    const agreementTypeIds = agreementTypes.map(at => at.id);

    const defaultAdminViewData = {
      name: 'Default',
      filter_data: {
        state: 'any'
      },
      sort_field: 'rank',
      sort_order: 'asc'
    };

    if (companyTypeIds.length) {
      defaultAdminViewData.filter_data.company_type = companyTypeIds;
    } else if (agreementTypeIds.length) {
      defaultAdminViewData.filter_data.agreement_type = agreementTypeIds;
    }

    await createAdminView(defaultAdminViewData);

    history.push('/dashboard/deployment-center/companies');
  };

  return (
    <Styled.Wrapper>
      <Styled.Body>
        <Styled.Logo>
          <DefaultViewIcon />
        </Styled.Logo>
        <Styled.TextInfo>
          <h2>Let’s create a list of customers.</h2>
          <p>
            Next, we need a list of your managed customers. Select below how you would like to
            filter them.
          </p>
        </Styled.TextInfo>
        <Styled.SelectField>
          <p>Choose company type(s)</p>
          <SelectAsyncPaginate
            value={companyTypes}
            getOptionValue={option => option?.id}
            components={{ ClearIndicator: null }}
            menuPortalTarget={document.body}
            onChange={data => {
              setCompanyTypes(data);
            }}
            placeholder="Select company type"
            name="company-type"
            options={getCompanyTypes}
            className="basic-multi-select"
            classNamePrefix="select"
            isMulti
            isDisabled={agreementTypes?.length}
          />
        </Styled.SelectField>
        <Styled.OR>or</Styled.OR>
        <Styled.SelectField>
          <p>Choose agreement type(s)</p>
          <SelectAsyncPaginate
            value={agreementTypes}
            getOptionValue={option => option?.id}
            components={{ ClearIndicator: null }}
            menuPortalTarget={document.body}
            onChange={data => {
              setAgreementTypes(data);
            }}
            placeholder="Select agreement type"
            name="agreement-type"
            options={getAgreementTypes}
            className="basic-multi-select"
            classNamePrefix="select"
            isMulti
            isDisabled={companyTypes?.length}
          />
        </Styled.SelectField>
        <SimpleButton
          isLoader={isLoading}
          isDisabled={!companyTypes?.length && !agreementTypes.length}
          target="button"
          className="dc-btn-continue"
          onClick={createDefaultView}
        >
          Continue
        </SimpleButton>
      </Styled.Body>
    </Styled.Wrapper>
  );
};

export default DefaultViewStep;
