import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ReactComponent as SlackIcon } from 'src/assets/icons/slack.svg';
import { ReactComponent as MSIcon } from 'src/assets/icons/microsoft.svg';
import { ReactComponent as CWAutomateIcon } from 'src/assets/icons/cw-automate-logo.svg';
import { ReactComponent as PerchSecurityIcon } from 'src/assets/icons/perch-security.svg';
import { ReactComponent as AuvikIcon } from 'src/assets/icons/auvik.svg';
import { ReactComponent as ItGlueIcon } from 'src/assets/icons/it-glue.svg';
import { ReactComponent as RefactrIcon } from 'src/assets/icons/refactr-logo.svg';
import { ReactComponent as CloudRadialLogo } from 'src/assets/images/cloudradial-logo-2.svg';
import { ReactComponent as ConnectWiseIcon } from 'src/assets/icons/cw-manage-logo.svg';
import { ReactComponent as TimeZestLogo } from 'src/assets/icons/timezest_logo.svg';
import { psaTypes } from 'src/constants';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  .icon {
    display: inline-block;
    margin-right: 10px;
    min-width: 2em;
    height: 2em;
    object-fit: contain;
  }
`;

const iconMap = {
  slack: <SlackIcon width="40" height="27" />,
  automate: <CWAutomateIcon width="40" height="27" />,
  msteams: <MSIcon width="40" height="27" />,
  perch: <PerchSecurityIcon />,
  auvik: <AuvikIcon width="40" height="27" />,
  itglue: <ItGlueIcon width="40" height="20" />,
  refactr: <RefactrIcon width="40" height="20" />,
  cloudradial: <CloudRadialLogo width="40" height="25" />,
  timezest: <TimeZestLogo width="40" height="25" />,
  [psaTypes.CONNECTWISE]: <ConnectWiseIcon width="40" height="27" />,
  [psaTypes.KASEYA_BMS]: <img src="/images/bms-logo.png" width="40" alt="BMS logo" />,
  [psaTypes.AUTOTASK]: <img src="/images/autotask-logo-mini.png" width="40" alt="Autotask logo" />,
  [psaTypes.HALO]: (
    <div style={{ width: 40, display: 'flex', justifyContent: 'center' }}>
      <img src="/images/halo-logo-mini.png" width="30" alt="HaloPSA logo" />
    </div>
  )
};

export const IntegrationPlatformTitle = ({ row }) => {
  return (
    <Wrapper>
      <span className="icon">{iconMap[row.command]}</span>
      <strong>{row.name}</strong>
    </Wrapper>
  );
};

IntegrationPlatformTitle.propTypes = {
  row: PropTypes.objectOf(PropTypes.any).isRequired
};
