import styled from 'styled-components';

export const FromStyled = styled.form`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
`;

export const SelectWrapperStyled = styled.div`
  min-width: 120px;
`;

export const OptionNameStyled = styled.span`
  font-size: 13px;
  font-weight: bold;
`;

export const OptionDescriptionStyled = styled.p`
  font-size: 11px;
  margin: 2px 0 0 0;
`;

export const OptionRoleStyled = styled.span`
  font-size: 13px;
  line-height: 2em;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:after {
    content: url("data:image/svg+xml,%3Csvg width='12' height='9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.333 1 4 8.333.667 5' stroke='%230E1D96' stroke-width='1.333' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
    display: ${({ isActive }) => (isActive ? 'inline' : 'none')};
  }
`;

export const WrapContent = styled.span`
  white-space: normal;
  text-transform: ${({ lowercase }) => (lowercase ? 'lowercase' : 'none')};

  svg {
    width: 30.61px;
    height: 32.23px;
  }
`;

export const PageTools = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
`;

export const AutoAddMembers = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`;

export const HelpIconWrapper = styled.button`
  padding: 0;
  margin: 0;
  height: 18px;
  width: 18px;
  color: ${({ theme }) => theme.colors.text.variant2};
  background-color: transparent;
  outline: none;
  border: none;
  line-height: 0;
  transition: color 0.2s;

  &:hover {
    color: ${({ theme }) => theme.colors.accent.main};
  }
`;
