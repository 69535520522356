import * as yup from 'yup';

const initialTabs = [
  { id: 'support', key: 'support', name: 'Support', url: 'https://teams.microsoft.com' },
  { id: 'chat', key: 'chat', name: 'Chat', url: 'https://teams.microsoft.com' },
  {
    id: 'portal',
    key: 'portal',
    name: 'Portal',
    url: 'https://chatgeniesandbox.us.cloudradial.com'
  },
  { id: 'about', key: 'about', name: 'About', url: 'https://teams.microsoft.com' }
];

export const schema = yup
  .object({
    title: yup.string().required().max(32).label('Internal app name'),
    appName: yup.string().required().max(32).label('Teams app name'),
    externalAppId: yup.string().nullable(true).optional().label('External app ID'),
    description: yup.string().required().max(4000).label('Description'),
    shortDescription: yup.string().required().max(80).label('Short description'),
    logo: yup.string().required().label('Logo'),
    icon: yup.string().required().label('Icon'),
    tabs: yup
      .array()
      .of(
        yup.object().shape({
          name: yup.string().required().label('Name').max(128),
          url: yup.string().required().url().label('Url')
        })
      )
      .required('Required')
  })
  .required();

export const formDefaultValues = {
  title: '',
  appName: '',
  description: `This is your IT support bot and app for resolving IT requests. You can use this Teams App to start a chat directly with your IT service desk. You can also view and chat on existing requests that you have opened up by email or phone. You'll even notice that I send you messages when you email or phone in a request so that you can continue to work through Teams.\n\nWith Chat, you go from waiting to working. Start a chat and let us handle the rest!`,
  shortDescription: 'Your IT support bot. Message me to get connected to a person.',
  icon: '',
  logo: '',
  tabs: initialTabs
};

export const logoImageSize = {
  size: 1024 * 1024 * 5, // 5 MB
  errorMessage: 'Allowed maximum size is 5 MB'
};
