import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { chatgenieAPI } from 'src/config/api';
import { useHistory, Redirect } from 'react-router-dom';
import queryKeys from 'src/constants/queryKeys';
import { Button } from '@Thread-Magic/jasmine';
import useToast from 'src/hooks/useToast';
import slack from 'src/assets/images/slack.png';
import msteams from 'src/assets/images/msteams.png';
import apple from 'src/assets/images/apple-logo.png';
import windows from 'src/assets/icons/windows.svg';
import { ReactComponent as Spinner } from 'src/assets/icons/spinner.svg';
import { INVITATION_TYPES } from 'src/constants';
import { chatsTypes } from 'src/constants/action-types';
import * as Styled from './style';

const Connect = ({ match, location, companyId, user }) => {
  const { toast } = useToast();
  const queryClient = useQueryClient();
  const [isUpdateLoading, setIsUpdateLoading] = useState(false);
  const { data, isLoading, refetch } = useQuery([queryKeys.GET_COMPANY, companyId], () =>
    chatgenieAPI.getCompany({ id: companyId })
  );
  const selectedPlatform = data?.chat_platform?.platform;
  const [activePlatform, setActivePlatform] = useState(null);
  const history = useHistory();
  const { featureFlags } = user;

  // NB: Mac has no integration step, so we update the selected_configuration right after the click
  const mutationUpdateConfiguration = useMutation(chatgenieAPI.updateChatConfiguration, {
    onSuccess: async () => {
      await queryClient.refetchQueries([queryKeys.GET_COMPANY, companyId]);
      history.push(`${match.url}/download/mac`);
    },
    onMutate: () => {
      setIsUpdateLoading(true);
    },
    onError: error => {
      if (error.response.status === 400) {
        toast.warn(error.response.data?.message);
      } else {
        toast.error(error.message);
      }
    }
  });

  const selectPlatform = () => {
    if (activePlatform === 'MS_TEAMS') {
      history.push(`${match.url}/apps`);
      return;
    }

    if (activePlatform === 'MAC') {
      mutationUpdateConfiguration.mutate({
        companyId,
        selectedConfiguration: chatsTypes.MAC
      });

      return;
    }

    if (activePlatform === 'WINDOWS') {
      history.push({
        pathname: `${match.url}/invitation/windows`,
        state: {
          inviteType: INVITATION_TYPES.WINDOWS
        }
      });
      return;
    }

    history.push({
      pathname: `${match.url}/invitation`,
      state: {
        inviteType: INVITATION_TYPES.SLACK
      }
    });
  };

  useEffect(() => {
    if (location.state?.action === 'updateApp') {
      refetch();
    }
  }, [location.state]);

  if (isLoading) {
    return (
      <Styled.Wrapper>
        <Spinner />;
      </Styled.Wrapper>
    );
  }

  if (selectedPlatform && selectedPlatform === chatsTypes.MAC) {
    return <Redirect to={{ pathname: `${match.url}/download/mac` }} />;
  }

  if (selectedPlatform) {
    return <Redirect to={{ pathname: `${match.url}/status` }} />;
  }

  return (
    <Styled.Wrapper>
      <>
        <Styled.Title>Select a platform</Styled.Title>
        <Styled.Content>
          <Styled.PlatformButton
            type="button"
            onClick={() => setActivePlatform('SLACK')}
            isActive={activePlatform === 'SLACK'}
          >
            <img src={slack} alt="slack" />
          </Styled.PlatformButton>
          <Styled.PlatformButton
            type="button"
            onClick={() => setActivePlatform('MS_TEAMS')}
            isActive={activePlatform === 'MS_TEAMS'}
          >
            <img src={msteams} alt="msteams" />
          </Styled.PlatformButton>
          <Styled.PlatformButton
            type="button"
            onClick={() => setActivePlatform('MAC')}
            isActive={activePlatform === 'MAC'}
          >
            <Styled.ButtonWrapper>
              <Styled.MacLogo src={apple} alt="mac" /> <Styled.AppName>Mac</Styled.AppName>
            </Styled.ButtonWrapper>
          </Styled.PlatformButton>
          <Styled.PlatformButton
            type="button"
            onClick={() => setActivePlatform('WINDOWS')}
            isActive={activePlatform === 'WINDOWS'}
          >
            <Styled.ButtonWrapper>
              <Styled.MacLogo src={windows} alt="windows" />{' '}
              <Styled.AppName>Windows</Styled.AppName>
            </Styled.ButtonWrapper>
          </Styled.PlatformButton>
        </Styled.Content>
        <Button
          size="large"
          isDisabled={!activePlatform}
          onClick={selectPlatform}
          isLoader={isUpdateLoading}
        >
          Continue
        </Button>
      </>
    </Styled.Wrapper>
  );
};

Connect.propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  companyId: PropTypes.number.isRequired,
  user: PropTypes.objectOf(PropTypes.any).isRequired
};

export default Connect;
