import React, { useState } from 'react';
import FormInputWithLabel from 'src/components/FormInputWithLabel';
import PropTypes from 'prop-types';
import * as Styled from './styles';

// Hardcore image templates, because the settings only accept id for images
// TODO: Perhaps we will refactor and add the ability to use the image url for uploading in the future.
const imageTemplatesProd = [
  {
    id: 5144,
    url: 'https://chatgenie-file-storage.s3.amazonaws.com/uploads/2023/03/292e6b55-d695-41c0-af25-ce7481fa6ac8.png'
  },
  {
    id: 5145,
    url: 'https://chatgenie-file-storage.s3.amazonaws.com/uploads/2023/03/525359ac-9f45-464b-a946-3f7ccad39e00.png'
  },
  {
    id: 5146,
    url: 'https://chatgenie-file-storage.s3.amazonaws.com/uploads/2023/03/d25ae9cc-8b15-4e01-a343-8017ae3fdd2c.png'
  },
  {
    id: 5147,
    url: 'https://chatgenie-file-storage.s3.amazonaws.com/uploads/2023/03/2b902b08-beea-4468-b354-c02e56981f01.png'
  }
];

const imageTemplatesStage = [
  {
    id: 1252,
    url: 'https://chatgenie-file-storage.s3.amazonaws.com/uploads/2023/03/38f8f12f-3f50-4c89-a5a3-69d6d3de737a.png'
  },
  {
    id: 1253,
    url: 'https://chatgenie-file-storage.s3.amazonaws.com/uploads/2023/03/b391e2dd-a609-4667-9b64-85368d2ee52f.png'
  },
  {
    id: 1254,
    url: 'https://chatgenie-file-storage.s3.amazonaws.com/uploads/2023/03/62bb0842-c6c6-4f0a-815c-f7baffbaeea3.png'
  },
  {
    id: 1255,
    url: 'https://chatgenie-file-storage.s3.amazonaws.com/uploads/2023/03/4285b1eb-b1e0-4fc0-a577-f7c75cfb309c.png'
  }
];

const imageTemplates =
  process.env.REACT_APP_ENV === 'production' ? imageTemplatesProd : imageTemplatesStage;

const BotStyleBlock = ({ state, onChangeState }) => {
  const [isActiveLogo, setIsActiveLogo] = useState(false);
  const { botName, botIcon } = state;

  const updateBotStyle = newState => onChangeState(prev => ({ ...prev, ...newState }));

  return (
    <>
      <Styled.Wrapper>
        <Styled.SubTitle>Give your bot an avatar</Styled.SubTitle>
        <Styled.ColorsList>
          <Styled.Icon
            isActive={isActiveLogo}
            onChange={botIcon => {
              setIsActiveLogo(true);
              updateBotStyle({ botIcon });
            }}
            name="bot-icon"
            accept="image/png, image/jpeg"
            value={botIcon.url}
            components={{
              imagePreview: children => (
                <Styled.ImagePreviewWrapper>
                  <Styled.ImagePreview>{children}</Styled.ImagePreview>
                  <Styled.IconAction>Change</Styled.IconAction>
                </Styled.ImagePreviewWrapper>
              )
            }}
            tabs={[
              {
                name: 'gallery',
                component: callback => (
                  <Styled.ImageTemplateList>
                    {imageTemplates.map(file => (
                      <Styled.Template
                        key={file.id}
                        url={file.url}
                        onClick={() => {
                          updateBotStyle({ botIcon: file });
                          callback();
                        }}
                      />
                    ))}
                  </Styled.ImageTemplateList>
                )
              }
            ]}
          />
        </Styled.ColorsList>
      </Styled.Wrapper>
      <Styled.Wrapper>
        <FormInputWithLabel
          id="bot-name"
          onChange={event => updateBotStyle({ botName: event.target.value })}
          value={botName}
          label="Give your bot a name"
          placeholder="Enter bot name"
          size="large"
        />
      </Styled.Wrapper>
    </>
  );
};

BotStyleBlock.defaultProps = {
  onChangeState: () => {},
  state: {}
};

BotStyleBlock.propTypes = {
  onChangeState: PropTypes.func,
  state: PropTypes.objectOf(PropTypes.any)
};

export default BotStyleBlock;
