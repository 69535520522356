import { lighten } from 'polished';
import styled from 'styled-components';

export const Wrapper = styled.div`
  max-width: 400px;
  margin: 0 auto;
  width: 100%;
`;

export const Form = styled.form``;

export const FormGroup = styled.div`
  margin-bottom: 20px;
`;

export const IconWrapper = styled.div`
  height: 60px;
  width: auto;
  object-fit: contain;
  overflow: hidden;
  label {
    padding: 8px;
  }
`;

export const FieldLabel = styled.label`
  display: block;
  margin-bottom: 6px;
  font-size: 16px;
  line-height: 22px;
  font-weight: bold;
  color: #000000;

  ${({ hasError, theme }) => (hasError ? `color: ${theme.colors.red1};` : '')};
`;

export const TrayIconHolder = styled.div`
  display: flex;
  width: 68px;
  height: 68px;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
  border-radius: 100%;
  background: ${({ color }) => color};

  img {
    display: block;
    margin: auto;
    max-width: 40px;
  }
`;

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background-color: ${({ theme }) => theme.colors.accent.main};
  color: #fff;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: 700;
  padding: 15px 40px;
  letter-spacing: 0.33px;
  width: ${({ isFullWidth }) => (isFullWidth ? '100%' : 'auto')};
  transition: background-color 0.2s;

  svg {
    height: 18px;
    width: 18px;

    circle {
      stroke: #ffffff !important;
    }
  }

  &:disabled {
    cursor: not-allowed;
    background-color: ${({ theme }) => theme.colors.gray5} !important;
  }

  &:hover {
    background-color: ${({ theme }) => lighten(0.1, theme.colors.accent.main)};
  }
`;

export const ErrorMessage = styled.div`
  display: flex;
  flex-wrap: nowrap;
  gap: 6px;
  align-items: center;
  margin-top: 6px;
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.red1};

  svg {
    display: block;
    width: 16px;
    height: 16px;
  }
`;
