import React from 'react';
import PropTypes from 'prop-types';
import { psaTranslate } from 'src/utils/psa';
import * as Styled from './style';
import BoardAccordion from './BoardAccordion';
import useBoardConfig from '../hooks/useBoardConfig';

function BoardTypeTable({ data, onChange, isEditable }) {
  const { showTableHeader } = useBoardConfig();
  return (
    <Styled.BoardTypeTableContainer>
      {showTableHeader && (
        <Styled.BoardTypeTableHeader>{psaTranslate('Board')}</Styled.BoardTypeTableHeader>
      )}
      <Styled.BoardAccordions>
        {data.map((board, index) => (
          <BoardAccordion
            onChange={onChange}
            key={board.id}
            isEditable={isEditable}
            board={board}
            defaultIsCollapsed={index !== 0}
          />
        ))}
        {data.length === 0 && (
          <Styled.BoardTableEmpty>No {psaTranslate('board')} types to show</Styled.BoardTableEmpty>
        )}
      </Styled.BoardAccordions>
    </Styled.BoardTypeTableContainer>
  );
}

BoardTypeTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func,
  isEditable: PropTypes.bool
};

BoardTypeTable.defaultProps = {
  onChange: () => {},
  isEditable: true
};

export default BoardTypeTable;
