import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';
import Companies from './Companies';
import Contacts from './Contacts';
import PageWrapper from '../../components/PageWrapper';

const Clients = ({ match }) => {
  return (
    <PageWrapper
      title="Clients"
      tabs={[
        { id: 1, path: `${match.url}/companies`, name: 'Companies' },
        { id: 2, path: `${match.url}/contacts`, name: 'Contacts' }
      ]}
    >
      <Switch>
        <Redirect exact from={match.path} to={`${match.url}/companies`} />
        <Route path={`${match.path}/companies`} component={Companies} />
        <Route path={`${match.path}/contacts`} component={Contacts} />
      </Switch>
    </PageWrapper>
  );
};

Clients.propTypes = {
  match: PropTypes.objectOf(PropTypes.any),
  user: PropTypes.objectOf(PropTypes.any).isRequired
};

Clients.defaultProps = {
  match: null
};

export default Clients;
