import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useQueries } from 'react-query';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import queryKeys from 'src/constants/queryKeys';
import { useHistory, withRouter, Link } from 'react-router-dom';
import { chatgenieAPI } from 'src/config/api';
import useToast from 'src/hooks/useToast';
import useAuthUser from 'src/hooks/useAuthUser';
import { Button } from '@Thread-Magic/jasmine';
import uuid from 'uuid';
import { yupResolver } from '@hookform/resolvers/yup';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import ConfirmModal from 'src/components/ConfirmModal';
import { ReactComponent as MenuIcon } from 'src/assets/icons/menu.svg';
import { ReactComponent as PlusIcon } from 'src/assets/icons/plus-2.svg';
import { ReactComponent as TrashIcon } from 'src/assets/icons/trash.svg';
import { ReactComponent as ExitIcon } from 'src/assets/icons/exit.svg';
import { ReactComponent as InfoIcon } from 'src/assets/icons/info.svg';
import PageBuilder from 'src/components/PageBuilder';
import servicePagePreview from 'src/assets/images/service-page-preview.png';
import * as Styled from './style';
import { schema, formDefaultValues, logoImageSize } from './config';

const TeamsBuilder = ({ parentCompanyId, match = {}, location = {} }) => {
  const authUser = useAuthUser();
  const teamsAppId = match.params?.teamsAppId;
  const appData = location.state?.appData;
  const { toast } = useToast();
  const [isConfirmModal, setIsConfirmModal] = useState(false);
  const history = useHistory();

  const data = useQueries([
    {
      queryKey: [queryKeys.GET_TEAMS_APP, parentCompanyId, teamsAppId],
      queryFn: () => chatgenieAPI.getTeamsApp({ companyId: parentCompanyId, teamsAppId }),
      enabled: !appData && !!teamsAppId,
      onError: error => {
        toast.error(error.message);
      }
    },
    {
      queryKey: [queryKeys.GET_ACTION_PLATFORMS],
      queryFn: () => chatgenieAPI.getActionPlatforms(),
      onError: error => {
        toast.error(error.message);
      }
    }
  ]);

  const [
    { data: app = {}, isLoading: isLoadingApp },
    { data: platforms = [], isLoading: isLoadingPlatforms }
  ] = data;

  const isLoading = isLoadingApp || isLoadingPlatforms;
  const state = appData || app;
  const isCloudradialIntegration = platforms.filter(item => item.command === 'cloudradial')[0]
    ?.is_active;

  const {
    control,
    register,
    reset,
    handleSubmit,
    formState: { errors, isDirty },
    setError,
    clearErrors
  } = useForm({
    defaultValues: { ...formDefaultValues, ...state },
    resolver: yupResolver(schema)
  });

  const { fields, append, remove, move } = useFieldArray({ control, name: 'tabs' });

  const onDragEnd = result => {
    if (!result.destination) return;
    move(result.source.index, result.destination.index);
  };

  const handleImageValidation = (file, name) => {
    if (file && file.size > logoImageSize.size) {
      setError(name, {
        type: 'custom',
        message: logoImageSize.errorMessage
      });
      return false;
    }
    clearErrors(name);
    return true;
  };

  const nextHandler = () => {
    handleSubmit(data => {
      history.push({
        pathname: data.id ? `../service-page-builder/${data.id}` : './service-page-builder',
        state: { ...location.state, appData: data }
      });
    })();
  };

  const goToHomePage = () => {
    const pathname =
      location.state?.backPathname ||
      location.pathname
        .split('/')
        .slice(0, state.id ? -2 : -1)
        .join('/');
    history.push(pathname);
  };

  useEffect(() => {
    if (state.id && platforms) {
      const hasIntegration = !!state.tabs.filter(({ key }) => key === 'portal')[0];
      const portalTab = {
        id: 'portal',
        key: 'portal',
        name: 'Portal',
        url: 'https://chatgeniesandbox.us.cloudradial.com'
      };

      reset({
        ...state,
        tabs: [...state.tabs, ...(!hasIntegration ? [portalTab] : [])]
      });
    }
  }, [state.id, platforms]);

  return (
    <PageBuilder
      isLoading={isLoading}
      title="Teams Custom App"
      actions={[
        <Button
          type="button"
          size="large"
          styleMode="outline"
          onClick={() => (isDirty ? setIsConfirmModal(true) : goToHomePage())}
        >
          <ExitIcon /> Exit
        </Button>,
        <Button type="button" size="large" onClick={nextHandler}>
          Next
        </Button>
      ]}
    >
      <Styled.Form>
        <Styled.FormGroup>
          <Styled.GroupTitle marginBottom={8}>Internal app name</Styled.GroupTitle>
          <Styled.GroupDescription>App name displayed in Thread</Styled.GroupDescription>
          <Styled.Input {...register('title')} placeholder="Internal app name" />
          <Styled.FieldError>{errors.title?.message}</Styled.FieldError>
        </Styled.FormGroup>
        <Styled.FormGroup>
          <Styled.GroupTitle>Teams app name</Styled.GroupTitle>
          <Styled.GroupDescription>App name displayed in Teams</Styled.GroupDescription>
          <Styled.Input {...register('appName')} placeholder="Teams app name" />
          <Styled.FieldError>{errors.appName?.message}</Styled.FieldError>
        </Styled.FormGroup>
        {/* WBM is looking to update hundreds of apps but they cant because we used to generate
        new uuid for external id every time app was downloaded. Now we will ask for the external
        id from the original app installation this way teams will allow updates. */}
        {state.id && authUser.company.id === 291014 && (
          <Styled.FormGroup>
            <Styled.GroupTitle>External app ID (optional)</Styled.GroupTitle>
            <Styled.GroupDescription>
              Can be found in Teams Admin if the app was previously installed
            </Styled.GroupDescription>
            <Styled.Input
              {...register('externalAppId')}
              placeholder="e08e574f-1ba2-424c-9cb2-215ee642d278"
            />
            <Styled.FieldError>{errors.externalAppId?.message}</Styled.FieldError>
          </Styled.FormGroup>
        )}
        <Styled.ImageFieldsWrapper>
          <Styled.FormGroup>
            <Styled.GroupTitle>Logo for app store</Styled.GroupTitle>
            <Controller
              control={control}
              name="logo"
              render={({ field: { value, onChange, ref, name } }) => (
                <Styled.ImageBlock
                  value={value}
                  onChange={data => onChange(data.url)}
                  controllerRef={ref}
                  validator={handleImageValidation}
                  accept="image/png"
                  aspect={1}
                  name={name}
                />
              )}
            />
            <Styled.ImageDescription>192px square PNG</Styled.ImageDescription>
            <Styled.FieldError>{errors.logo?.message}</Styled.FieldError>
          </Styled.FormGroup>
          <Styled.FormGroup>
            <Styled.GroupTitle>Icon for tab</Styled.GroupTitle>
            <Controller
              control={control}
              name="icon"
              render={({ field: { value, onChange, ref, name } }) => (
                <Styled.ImageBlock
                  value={value}
                  onChange={data => onChange(data.url)}
                  controllerRef={ref}
                  validator={handleImageValidation}
                  accept="image/png"
                  aspect={1}
                  name={name}
                />
              )}
            />
            <Styled.ImageDescription>32px square PNG</Styled.ImageDescription>
            <Styled.FieldError>{errors.icon?.message}</Styled.FieldError>
          </Styled.FormGroup>
        </Styled.ImageFieldsWrapper>
        <Styled.FormGroup>
          <Styled.GroupTitle>Short description</Styled.GroupTitle>
          <Styled.GroupDescription>
            A short description of your app, used when space is limited
          </Styled.GroupDescription>
          <Styled.Textarea
            {...register('shortDescription')}
            placeholder="Short description of your support app."
          />
          <Styled.FieldError>{errors.shortDescription?.message}</Styled.FieldError>
        </Styled.FormGroup>
        <Styled.FormGroup>
          <Styled.GroupTitle>Full description</Styled.GroupTitle>
          <Styled.GroupDescription>
            The full description of your app, displayed when installing in the preview
          </Styled.GroupDescription>
          <Styled.Textarea
            {...register('description')}
            minHeight={130}
            placeholder="Full description of your support app."
          />
          <Styled.FieldError>{errors.description?.message}</Styled.FieldError>
        </Styled.FormGroup>
        <Styled.FormGroup>
          <Styled.GroupTitle>Tabs</Styled.GroupTitle>
          <Styled.GroupDescription>
            Re-order or add new tabs in your Teams app
          </Styled.GroupDescription>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {provided => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {fields.map(({ name, key, url, id }, index) => (
                    <Draggable key={key} draggableId={id} index={index}>
                      {provided =>
                        url !== 'https://teams.microsoft.com' ? (
                          <Styled.Tab
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <MenuIcon />
                            {key === 'portal' ? (
                              <>
                                <Styled.IntegrationName isActive={isCloudradialIntegration}>
                                  {isCloudradialIntegration ? 'Cloudradial' : 'Portal'}
                                </Styled.IntegrationName>
                                {!isCloudradialIntegration && (
                                  <Styled.IntegrationInfo>
                                    <InfoIcon />
                                    To enable this tab, go to the{' '}
                                    <Link to="/dashboard/integrations/cloudradial">
                                      integrations
                                    </Link>{' '}
                                    page
                                  </Styled.IntegrationInfo>
                                )}
                              </>
                            ) : (
                              <>
                                <Styled.FieldWrapper
                                  isError={errors.tabs && errors.tabs[index]?.name}
                                >
                                  <Styled.TabField
                                    {...register(`tabs.${index}.name`)}
                                    type="text"
                                    placeholder="Name your tab"
                                  />
                                  {errors.tabs && errors.tabs[index]?.name && (
                                    <Styled.ErrorTooltip>
                                      {errors.tabs[index]?.name?.message}
                                    </Styled.ErrorTooltip>
                                  )}
                                </Styled.FieldWrapper>
                                <Styled.FieldWrapper
                                  isError={errors.tabs && errors.tabs[index]?.url}
                                >
                                  <Styled.TabField
                                    {...register(`tabs.${index}.url`)}
                                    type="url"
                                    placeholder="URL (e.g. htttp://acme.com)"
                                  />
                                  {errors.tabs && errors.tabs[index]?.url && (
                                    <Styled.ErrorTooltip>
                                      {errors.tabs[index]?.url?.message}
                                    </Styled.ErrorTooltip>
                                  )}
                                </Styled.FieldWrapper>
                              </>
                            )}
                            {key !== 'portal' && (
                              <Styled.RemoveButton type="button" onClick={() => remove(index)}>
                                <TrashIcon />
                              </Styled.RemoveButton>
                            )}
                          </Styled.Tab>
                        ) : (
                          <Styled.Tab
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <MenuIcon />
                            {name}
                          </Styled.Tab>
                        )
                      }
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <Styled.AddTabButton
            type="button"
            onClick={() => append({ id: uuid.v4(), name: '', url: 'https://' })}
          >
            <PlusIcon />
            Add tab
          </Styled.AddTabButton>
        </Styled.FormGroup>
      </Styled.Form>
      <ConfirmModal
        isOpen={isConfirmModal}
        toggleHandler={() => setIsConfirmModal(false)}
        title="Are you sure you want to exit?"
        description="If you leave the page you might lose unsaved changes."
        submitText="Yes"
        cancelText="No, cancel"
        onSubmit={goToHomePage}
      />
      <Styled.Figure>
        <img src={servicePagePreview} alt="service page preview" />
      </Styled.Figure>
    </PageBuilder>
  );
};

TeamsBuilder.propTypes = {
  parentCompanyId: PropTypes.number.isRequired,
  location: PropTypes.objectOf(PropTypes.any),
  match: PropTypes.objectOf(PropTypes.any)
};

TeamsBuilder.defaultProps = {
  location: {},
  match: {}
};

export default withRouter(TeamsBuilder);
