import React, { createContext } from 'react';
import {
  FiAlertOctagon,
  FiCheck,
  // FiChevronsUp,
  FiCoffee,
  FiEdit,
  FiShieldOff,
  // FiStar,
  FiUser,
  FiUserCheck,
  FiUserX,
  FiUsers,
  FiX
} from 'react-icons/fi';
import { HiOutlineOfficeBuilding } from 'react-icons/hi';
import testimonialAuthor from 'src/assets/images/testimonial-author1.png';
import { ReactComponent as ChatIcon } from 'src/assets/icons/chat-outlined.svg';
import { ReactComponent as QuestionCircled } from 'src/assets/icons/question-circled.svg';
import { ReactComponent as ClapIcon } from 'src/assets/icons/clap-outlined.svg';

export const introContent = {
  title: (
    <>
      Never miss an important service request from a customer with{' '}
      <span>automated prioritizations</span>
    </>
  ),
  description:
    'Let Magic AI tell you what to tackle next. Make everyones lives easier by helping your customers who need it most when they need it. Service Magic!',
  buttonLabel: 'Get Started',
  testimonial: {
    title: 'What people love about Magic AI',
    fullname: 'Tyler Mack',
    position: 'Operations Manager - WBM Technologies',
    photo: testimonialAuthor,
    review:
      'Thread’s AI functionality automates time entries for us by summarizing chats in real time. This is huge and saves us 300 hours a month! We are excited about the ability to power more of our workflow with AI through the Thread and Microsoft integration.'
  },
  benifits: {
    title: 'Magic AI helps you set the right priority',
    items: [
      {
        icon: <ChatIcon />,
        text: 'Priorities are set within seconds'
      },
      {
        icon: <QuestionCircled />,
        text: 'No P1 issues getting lost'
      },
      {
        icon: <ClapIcon />,
        text: 'Customers are served better'
      }
    ]
  }
};

export const ruleSections = {
  IMPACT: {
    title: 'Impact',
    icons: [<HiOutlineOfficeBuilding />, <FiUsers />, <FiUser />, <FiShieldOff />]
  },
  URGENCY: {
    title: 'Urgency',
    icons: [<FiAlertOctagon />, <FiCoffee />, <FiEdit />]
  },
  ONBOARDING: {
    title: 'Employee On & Offboarding',
    additional:
      'Magic AI has an understanding of the date of the request and is able to see if the request has a future date or if it’s for now.',
    icons: [<FiUserCheck />, <FiUserCheck />, <FiUserX />, <FiUserX />]
  },
  CUSTOM: {
    title: 'Custom Rules',
    icons: []
  }
};

export const booleanOptions = [
  {
    label: 'Yes',
    description: 'Escalate 1 priority higher',
    icon: <FiCheck size={16} />,
    value: true
  },
  {
    label: 'No',
    description: 'No, keep it the same',
    icon: <FiX size={!6} />,
    value: false
  }
];

export const defaultRuleValues = (ruleId, priorities) => {
  // Priorities are sorted from high to low - 0 indexed is highest priority.
  // Rule ids are hardcoded - every company will have the rules with same ids
  const mappedValues = {
    3: priorities[0],
    4: priorities[1],
    5: priorities[2],
    6: priorities[0],
    8: priorities[1],
    9: priorities[3],
    10: priorities[2],
    11: priorities[1],
    12: priorities[1],
    13: priorities[0]
  };
  return mappedValues[ruleId];
};

export const PrioritizationConfigContext = createContext({
  selectedSla: null
});
