import React, { useMemo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import useAuthUser from 'src/hooks/useAuthUser';
import { psaTypes } from 'src/constants';
import { setLocalStorage } from 'src/utils';
import { getMsConfiguration } from 'src/redux/modules/chats/chatsActions';
import { useMsConfiguration, useIsLoadingMsConfiguration } from 'src/hooks/useMsConfiguration';
import { fetch as fetchPlatforms } from '../../redux/modules/integrations/platforms/actions';
import { useIntegrations } from './hook';
import PageWrapper from '../../components/PageWrapper';
import Table from '../../components/Table';
import tableColumns from './integrationsTableColumns';
import { getChatIntegrationsRows } from './chatIntegrationsHelpers';
import { useSsoFlag } from '../ChatIntegrationsEditor/MicrosoftIntegrations/featureFlag';

const Integrations = props => {
  const {
    history,
    platforms,
    fetchPlatforms,
    isLoading,
    chatConfiguration,
    errorMessage,
    psaType,
    getMsConfiguration
  } = props;
  const user = useAuthUser();
  const isTimeZestEnabled = user?.featureFlags?.includes('timezest_integration');

  useIntegrations({ fetchPlatforms });

  useEffect(() => {
    getMsConfiguration(user.company.id);
  }, []);
  const msConfiguration = useMsConfiguration();
  const isLoadingMsConfiguration = useIsLoadingMsConfiguration();

  const handleHintClick = e => {
    e.preventDefault();
    setLocalStorage({ onboardingStep: 'bot_install' });
    history.push('/signup/bot-install');
  };

  const getSlackConfig = (isActive, isValid) => ({
    id: 444,
    name: 'Slack',
    command: 'slack',
    is_valid: isValid,
    is_active: isActive,
    created_at: null,
    updated_at: null
  });

  const getTeamsConfig = (isActive, isValid, isIntegratedWithSlackApp = false) => ({
    id: 555,
    name: `Microsoft 365${isIntegratedWithSlackApp ? '' : ' & Teams app'}`,
    command: 'msteams',
    is_valid: isValid,
    is_active: isActive,
    hint: isActive && !isValid && (
      <Link to="/signup/bot-install" onClick={handleHintClick}>
        You need to install the bot into at least one team
      </Link>
    ),
    created_at: null,
    updated_at: null
  });

  const handleIntegrationEdit = row => {
    if (['slack', 'msteams'].includes(row.command)) {
      history.push(`/dashboard/integrations/chat/${row.command}`);
    } else {
      history.push(`/dashboard/integrations/${row.command}`);
    }
  };

  const ssoFeatureEnabled = useSsoFlag();

  const data = useMemo(() => {
    let expandedPlatforms = [...platforms];

    if (!isTimeZestEnabled) {
      expandedPlatforms = expandedPlatforms.filter(el => el.command !== 'timezest');
    }

    // Handle chat platforms
    expandedPlatforms.push(
      ...getChatIntegrationsRows(
        chatConfiguration,
        msConfiguration,
        getTeamsConfig,
        getSlackConfig,
        ssoFeatureEnabled
      )
    );

    // Handle psa platforms
    if (psaType === psaTypes.KASEYA_BMS) {
      expandedPlatforms = expandedPlatforms.map(el =>
        el.command === psaTypes.CONNECTWISE
          ? { ...el, command: psaTypes.KASEYA_BMS, name: 'BMS' }
          : el
      );
    } else if (psaType === psaTypes.AUTOTASK) {
      expandedPlatforms = expandedPlatforms.map(el =>
        el.command === psaTypes.CONNECTWISE
          ? { ...el, command: psaTypes.AUTOTASK, name: 'Autotask' }
          : el
      );
    } else if (psaType === psaTypes.HALO) {
      expandedPlatforms = expandedPlatforms.map(el =>
        el.command === psaTypes.CONNECTWISE
          ? { ...el, command: psaTypes.HALO, name: 'HaloPSA' }
          : el
      );
    } else if (psaType === psaTypes.INTERNAL_PSA) {
      expandedPlatforms = expandedPlatforms.filter(
        el => ![psaTypes.CONNECTWISE, psaTypes.KASEYA_BMS].includes(el.command)
      );
    }
    return expandedPlatforms;
  }, [platforms, chatConfiguration, psaType, isTimeZestEnabled, msConfiguration]);

  return (
    <PageWrapper title="Integrations">
      <Table
        isLoading={isLoading || isLoadingMsConfiguration}
        error={errorMessage}
        columns={tableColumns}
        rows={{ data }}
        onRowClick={handleIntegrationEdit}
        isSearch={false}
      />
    </PageWrapper>
  );
};

Integrations.propTypes = {
  history: PropTypes.object.isRequired,
  platforms: PropTypes.array.isRequired,
  fetchPlatforms: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  chatConfiguration: PropTypes.string,
  errorMessage: PropTypes.string,
  psaType: PropTypes.string.isRequired,
  getMsConfiguration: PropTypes.func
};

Integrations.defaultProps = {
  chatConfiguration: null,
  errorMessage: null,
  getMsConfiguration: () => {}
};

const mapDispatchToProps = dispatch => ({
  getMsConfiguration: companyId => dispatch(getMsConfiguration(companyId)),
  fetchPlatforms: () => dispatch(fetchPlatforms())
});

const mapStateToProps = state => ({
  chatConfiguration: state.usersReducer.user.chatConfiguration,
  platforms: state.integrations.platforms.data,
  isLoading: state.integrations.platforms.loading,
  errorMessage: state.integrations.platforms.errorMessage,
  psaType: state.usersReducer.user.psaType
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Integrations));
