import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import { LabelStyled, InputStyled, ControlStyled, ControlTextStyled } from './style';

const Switcher = forwardRef((props, ref) => {
  const {
    id,
    label,
    checked,
    controlText,
    width,
    labelStyle,
    backgroundType,
    className = '',
    isLoading = false
  } = props;

  return (
    <LabelStyled
      htmlFor={id}
      style={labelStyle}
      className={isLoading ? [className, 'switcher-loading'].join('') : className}
    >
      <InputStyled ref={ref} type="checkbox" {...props} disabled={isLoading} />
      {label && <span>{label}</span>}
      <ControlStyled controlText={controlText} width={width} backgroundType={backgroundType}>
        {controlText && (
          <ControlTextStyled checked={checked}>
            {checked ? controlText.checked : controlText.unchecked}
          </ControlTextStyled>
        )}
      </ControlStyled>
    </LabelStyled>
  );
});

Switcher.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  controlText: PropTypes.objectOf(PropTypes.any),
  width: PropTypes.number,
  className: PropTypes.string,
  theme: PropTypes.objectOf(PropTypes.any).isRequired,
  labelStyle: PropTypes.objectOf(PropTypes.any),
  isLoading: PropTypes.bool
};

Switcher.defaultProps = {
  label: '',
  width: 78,
  controlText: null,
  labelStyle: null,
  isLoading: false,
  className: ''
};

export default Switcher;
