export const timeZestFormFields = {
  BASE_URL: {
    id: 'domainBaseUrl',
    label: 'Your domain',
    placeholder: 'https://yourdomain.timezest.com'
  },
  APIKEY: {
    id: 'apiKey',
    label: 'API Key',
    placeholder: 'e.g. CoPw0lLoCZNtWpiL',
    type: 'password'
  }
};
