import { chatsTypes } from 'src/constants/action-types';

export function getChatIntegrationsRows(
  chatConfiguration,
  msConfiguration,
  getTeamsConfig,
  getSlackConfig,
  ssoFeatureEnabled
) {
  const rows = [];

  if (!chatConfiguration || chatConfiguration === chatsTypes.SKIPPED) {
    rows.push(getSlackConfig(false, false), getTeamsConfig(false, false));
    return rows;
  }

  if (chatConfiguration === chatsTypes.SLACK) {
    rows.push(getSlackConfig(true, true));
    const isActive = msConfiguration.is_connected_to_m365;
    const isValid = isActive; // For M365, the configuration is always valid if it's active
    ssoFeatureEnabled && rows.push(getTeamsConfig(isActive, isValid, true));
    return rows;
  }

  if (chatConfiguration === chatsTypes.WINDOWS) {
    // A WINDOWS configuration refers to a Microsoft 365 integration, without the Teams bot
    rows.push(getSlackConfig(false, false)); // We still show the Slack row to give users the option to integrate with the Slack companion app
    rows.push(getTeamsConfig(true, true));
    return rows;
  }

  if (chatConfiguration === chatsTypes.MS_TEAMS) {
    rows.push(getTeamsConfig(true, msConfiguration.has_teams_companion_app));
    return rows;
  }

  return rows;
}
