import { chatsTypes, configurationsTypes, actionStatuses } from '../../../constants/action-types';

const defaultState = {
  teams: [],
  configurationId: null,
  chatErrorMessage: '',
  chatInfoLoader: 'idle',
  msConfiguration: {}
};

const map = {
  [`${chatsTypes.SIGN_IN_SLACK}${actionStatuses.FULFILLED}`]: state => ({
    ...state,
    chatErrorMessage: '',
    chatInfoLoader: 'fulfilled'
  }),
  [`${chatsTypes.SIGN_IN_SLACK}${actionStatuses.PENDING}`]: state => ({
    ...state,
    chatErrorMessage: '',
    chatInfoLoader: 'pending'
  }),
  [`${chatsTypes.SIGN_IN_SLACK}${actionStatuses.REJECTED}`]: (state, action) => ({
    ...state,
    chatErrorMessage: action.payload.response.data.message,
    chatInfoLoader: 'rejected'
  }),
  [`${chatsTypes.UPDATE_CHAT_CONFIGURATION}${actionStatuses.PENDING}`]: state => ({
    ...state,
    chatErrorMessage: '',
    chatInfoLoader: 'pending'
  }),
  [`${chatsTypes.UPDATE_CHAT_CONFIGURATION}${actionStatuses.FULFILLED}`]: state => ({
    ...state,
    chatErrorMessage: '',
    chatInfoLoader: 'fulfilled'
  }),
  [`${chatsTypes.UPDATE_CHAT_CONFIGURATION}${actionStatuses.REJECTED}`]: (state, action) => ({
    ...state,
    chatErrorMessage: action.payload.response.data.message,
    chatInfoLoader: 'rejected'
  }),
  [`${chatsTypes.SIGN_IN_MS}${actionStatuses.FULFILLED}`]: state => ({
    ...state,
    chatErrorMessage: '',
    chatInfoLoader: 'fulfilled_redirect'
  }),
  [`${chatsTypes.SIGN_IN_MS}${actionStatuses.PENDING}`]: state => ({
    ...state,
    chatErrorMessage: '',
    chatInfoLoader: 'pending'
  }),
  [`${chatsTypes.SIGN_IN_MS}${actionStatuses.REJECTED}`]: (state, action) => ({
    ...state,
    chatErrorMessage: action.payload.response.data.message,
    chatInfoLoader: 'rejected'
  }),
  [`${chatsTypes.INTEGRATE_MS}${actionStatuses.FULFILLED}`]: (state, action) => ({
    ...state,
    configurationId: action.payload.data.data.id,
    chatErrorMessage: '',
    chatInfoLoader: 'fulfilled'
  }),
  [`${chatsTypes.INTEGRATE_MS}${actionStatuses.PENDING}`]: state => ({
    ...state,
    chatErrorMessage: '',
    chatInfoLoader: 'pending'
  }),
  [`${chatsTypes.INTEGRATE_MS}${actionStatuses.REJECTED}`]: (state, action) => ({
    ...state,
    chatErrorMessage: action.payload.response.data.message,
    chatInfoLoader: 'rejected'
  }),
  [`${configurationsTypes.GET_CONFIGURATION}${actionStatuses.FULFILLED}`]: (state, action) => ({
    ...state,
    configurationId: action.payload.data.data.id,
    chatInfoLoader: 'fulfilled'
  }),
  [`${configurationsTypes.GET_CONFIGURATION}${actionStatuses.PENDING}`]: state => ({
    ...state,
    chatInfoLoader: 'pending'
  }),
  [`${configurationsTypes.GET_CONFIGURATION}${actionStatuses.REJECTED}`]: (state, action) => ({
    ...state,
    chatErrorMessage: action.payload.response.data.message
  }),
  [`${configurationsTypes.GET_TEAMS}${actionStatuses.FULFILLED}`]: (state, action) => ({
    ...state,
    teams: action.payload.data.data
  }),
  [`${configurationsTypes.GET_MS_CONFIGURATION}${actionStatuses.PENDING}`]: state => ({
    ...state,
    loading: true
  }),
  [`${configurationsTypes.GET_MS_CONFIGURATION}${actionStatuses.REJECTED}`]: state => ({
    ...state,
    loading: false
  }),
  [`${configurationsTypes.GET_MS_CONFIGURATION}${actionStatuses.FULFILLED}`]: (state, action) => ({
    ...state,
    msConfiguration: action.payload.data.data,
    loading: false
  }),
  [`${configurationsTypes.UPDATE_SSO_ENABLED}${actionStatuses.FULFILLED}`]: (state, action) => ({
    ...state,
    msConfiguration: {
      ...state.msConfiguration,
      is_sso_enabled: action.payload.data.data.sso_enabled
    }
  }),
  [`${chatsTypes.UNINSTALL_CHAT_CONFIGURATION}${actionStatuses.FULFILLED}`]: state => ({
    ...state,
    msConfiguration: {
      is_connected_to_m365: false,
      is_sso_enabled: 0,
      is_connected_to_ms_teams: false,
      has_teams_companion_app: false
    }
  })
};

export default function chatsReducer(state = defaultState, action) {
  return (map[action.type] && map[action.type](state, action)) || state;
}
