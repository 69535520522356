import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, withRouter, Redirect } from 'react-router-dom';
import { getLocalStorage } from 'src/utils';
import { isMicrosoft365Integration } from 'src/containers/ChatIntegrationsEditor/MicrosoftIntegrations/microsoftConnectionHelpers';
import { useLoginWithRedirect, useAuth0 } from '../Auth0';

const AuthPrivateRouter = ({ component: Component, user, path, infoIgnore, params, ...rest }) => {
  const { loading, isAuthenticated, loginWithRedirect } = useAuth0();

  useLoginWithRedirect({ loading, isAuthenticated, loginWithRedirect, path });

  // Logic below is used in order to keep onboarding
  // steps stateful based on data collected throughout
  // onboarding process
  return (
    <Route
      path={path}
      render={props => {
        const { location } = props;
        const state = { from: location };
        const {
          appId,
          userId,
          flowItemsReady,
          chatConfiguration,
          cwConfiguration,
          slackHasNewConfiguration,
          msTeamsHasNewConfiguration
        } = user;

        const onboardingStep = getLocalStorage('onboardingStep');
        const chatPlatformStep = getLocalStorage('chatPlatformStep');

        // This logic is used when you try to access
        // onboarding but you were already onboarded
        // and have all onboarding data
        if (
          flowItemsReady &&
          chatConfiguration &&
          !(slackHasNewConfiguration || msTeamsHasNewConfiguration) &&
          cwConfiguration &&
          appId &&
          userId &&
          !onboardingStep &&
          !chatPlatformStep &&
          !location.pathname.match(/dashboard/g) &&
          !isMicrosoft365Integration()
        ) {
          return <Redirect to={{ pathname: '/dashboard/flows', state }} />;
        }

        // When workspace is not yet selected
        if (
          !appId &&
          userId &&
          location.pathname !== '/signup/workspaces' &&
          location.pathname !== '/signup/welcome'
        ) {
          return <Redirect to={{ pathname: '/signup/workspaces', state }} />;
        }

        // When PSA configuration is not yet setup
        if (!cwConfiguration && appId && userId && location.pathname !== '/signup/ticketing') {
          return <Redirect to={{ pathname: '/signup/ticketing', state }} />;
        }

        // When configuring messenger design in onboarding
        if (
          cwConfiguration &&
          onboardingStep === 'design' &&
          appId &&
          location.pathname !== '/signup/messenger/design'
        ) {
          return <Redirect to={{ pathname: '/signup/messenger/design', state }} />;
        }

        // When initial data is not yet collected from provided configurations
        if (
          !flowItemsReady &&
          onboardingStep === 'sync' &&
          chatConfiguration &&
          !(slackHasNewConfiguration || msTeamsHasNewConfiguration) &&
          cwConfiguration &&
          appId &&
          userId &&
          location.pathname !== '/signup/synchronization'
        ) {
          return <Redirect to={{ pathname: '/signup/synchronization', state }} />;
        }

        // When configuring messenger settings in onboarding
        if (
          cwConfiguration &&
          onboardingStep === 'settings' &&
          flowItemsReady &&
          appId &&
          userId &&
          location.pathname !== '/signup/messenger/settings'
        ) {
          return <Redirect to={{ pathname: '/signup/messenger/settings', state }} />;
        }

        // When onboarding is finished
        if (
          cwConfiguration &&
          onboardingStep === 'success' &&
          flowItemsReady &&
          appId &&
          userId &&
          location.pathname !== '/signup/success'
        ) {
          return <Redirect to={{ pathname: '/signup/success', state }} />;
        }

        // When chat configuration is not yet setup
        // Special case: when we need to request additional permissions
        // slackHasNewConfiguration or msTeamsHasNewConfiguration will be
        // set to true which will throw the user to chat configuration step
        if (
          (!chatConfiguration || slackHasNewConfiguration || msTeamsHasNewConfiguration) &&
          cwConfiguration &&
          appId &&
          userId &&
          location.pathname !== '/signup/chat'
        ) {
          return <Redirect to={{ pathname: '/signup/chat', state }} />;
        }

        // When bot is not yet setup in Teams tenant
        // Special case: for MS Teams user needs to manually add CG app to
        // proper tenant and team whereas Slack automatically sets up the bot
        if (
          chatConfiguration &&
          onboardingStep === 'bot_install' &&
          !(slackHasNewConfiguration || msTeamsHasNewConfiguration) &&
          cwConfiguration &&
          appId &&
          userId &&
          location.pathname !== '/signup/bot-install'
        ) {
          return <Redirect to={{ pathname: '/signup/bot-install', state }} />;
        }

        return isAuthenticated && (infoIgnore || user.userId) ? (
          <Component {...props} {...params} />
        ) : null;
      }}
      {...rest}
    />
  );
};

AuthPrivateRouter.propTypes = {
  user: PropTypes.objectOf(PropTypes.any),
  settings: PropTypes.objectOf(PropTypes.any),
  params: PropTypes.objectOf(PropTypes.any),
  location: PropTypes.objectOf(PropTypes.any),
  path: PropTypes.string,
  infoIgnore: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.objectOf(PropTypes.any)]).isRequired
};

AuthPrivateRouter.defaultProps = {
  user: {},
  settings: {},
  params: {},
  location: {},
  infoIgnore: false,
  path: ''
};

const mapStateToProps = state => ({
  user: state.usersReducer.user
});

export default withRouter(connect(mapStateToProps)(AuthPrivateRouter));
