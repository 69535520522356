import styled, { css, keyframes } from 'styled-components/macro';

const fadeInOut = keyframes`
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  
  100% {
    opacity: 0;
  }
`;
const destroyGenerateAnimation = keyframes`
  0% {
    opacity: 1;
    height: 116px;
    padding-bottom: 24px;
  }
  100% {
    height: 0;
    opacity: 0;
    padding-bottom: 0;
  }
`;

const starsAnimation = css`
  path:nth-child(1) {
    animation: ${fadeInOut} 2s linear infinite;
  }
  path:nth-child(2) {
    animation: ${fadeInOut} 2s linear infinite;
    animation-delay: 0.5s;
  }
  path:nth-child(3) {
    animation: ${fadeInOut} 2s linear infinite;
    animation-delay: 1s;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  & > svg,
  & > img {
    margin: 48px auto 0;
  }
  transition: 300ms background;
  background: ${({ pageMode }) => (pageMode === 'edit' ? '#eeeeee' : '#fff')};
`;

export const EditWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #eeeeee;
  height: 100%;
  position: relative;
  padding: 32px;
  padding-bottom: 0;
  & > svg {
    width: 93px;
    height: 93px;
  }
  & > h5 {
    margin: 10px 0;
    font-size: 20px;
  }
`;

export const Block = styled.div`
  display: flex;
  align-self: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 32px;
`;

export const IntroWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const IntroHeader = styled(Block)`
  display: flex;
  align-self: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 32px;
  padding-top: 0;
  max-width: 588px;
  h5 {
    font-size: 20px;
    margin: 11px 0 24px;
    span {
      color: #03bb99;
    }
  }
  p {
    font-size: 14px;
    margin-bottom: 20px;
  }
`;

export const Benifits = styled(Block)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px;
  background: #faf9f6;
  width: 100%;
  h5 {
    margin-bottom: ${({ hasBenifits }) => (hasBenifits ? '21px' : '0')};
  }
`;

export const BenifitsItems = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  border-radius: 4px;
  border: 1px solid #ddd;
  background: #fff;
  max-width: 683px;
  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 21px;
    text-align: center;
    p {
      margin: 10px 0 0;
      font-size: 14px;
    }
    &:not(:last-child) {
      border-right: 1px solid #ddd;
    }
  }
`;

export const Testimonial = styled(Block)`
  display: flex;
  margin-bottom: 78px;
`;

export const TestimonialItem = styled.div`
  display: flex;
  max-width: 683px;
  & > svg {
    min-width: 38px;
  }
`;

export const TestimonialContent = styled.div`
  display: flex;
  margin-top: 20px;
  margin-left: 6px;
  text-align: left;
`;

export const TestimonialAvatar = styled.div`
  width: 124px;
  position: relative;
  background-color: #f2eaa1;
  border-radius: 16px;
  border: 1px solid #000;
  border-bottom-left-radius: 0px;
  overflow: visible;
  margin-left: 11px;
  margin-top: 20px;
  display: flex;
  align-items: flex-end;
  img {
    width: 124px;
  }
`;

export const TestimonialAvatarInfo = styled.div`
  position: absolute;
  background-color: #c2f5c2;
  border-radius: 16px;
  border: 1px solid #000;
  border-top-right-radius: 0px;
  top: 100%;
  right: 100%;
  font-size: 12px;
  line-height: 15px;
  width: 160px;
  padding: 12px 20px;
  p {
    margin: 0;
    text-align: left;
  }
`;

export const Stepper = styled.div`
  display: flex;
  justify-content: space-between;
  width: ${({ count }) => `${100 * count}px`};
  margin-top: 16px;
  position: relative;
  & > div {
    width: 20px;
    overflow: visible;
  }

  &::before {
    content: '';
    position: absolute;
    top: 8px;
    left: 0;
    right: 16px;
    height: 2px;
    z-index: 1;
    background: repeating-linear-gradient(90deg, transparent, transparent 6px, #999 6px, #999 14px);
  }
  &::after {
    content: '';
    position: absolute;
    transition: 300ms;
    top: 8px;
    left: 0;
    width: ${({ activeStep, count }) => `${(100 / (count - 1)) * activeStep}%`};
    height: 3px;
    z-index: 1;
    background: #27ae60;
  }
`;

export const StepperItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 11px;
  z-index: 2;
  line-height: 18px;
  text-transform: uppercase;
  span {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    border: 1px solid #999;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #eeeeee;
    font-size: 12px;
    margin-bottom: 3px;
    transition: 300ms;
    ${({ active }) =>
      active &&
      css`
        background-color: #27ae60;
        outline: 1px solid white;
        border: none;
        svg {
          color: white;
        }
      `}
  }
`;

export const EditContent = styled.div`
  margin-top: 24px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px;
  width: 100%;
  margin-bottom: 64px;
  max-width: ${({ small }) => (small ? '492px' : '952px')};
  h5 {
    font-weight: 600;
    svg {
      color: #03bb99;
    }
  }
  & > p {
    font-size: 16px;
    text-align: center;
    max-width: 520px;
  }
`;

export const TypesListContainer = styled.div`
  width: 100%;
`;

export const TypesListHeader = styled.div`
  display: grid;
  grid-template-columns: ${({ isEditable }) => (isEditable ? '3fr 8fr 1fr' : '3fr 9fr')};
  padding: 12px 24px 12px 42px;
  font-size: 14px;
  font-weight: 600;
  color: #666;
  border-bottom: 1px solid #ddd;
  & > span:nth-child(2) {
    padding-left: 8px;
  }
`;

export const TypesListItem = styled.div`
  display: grid;
  grid-template-columns: ${({ isEditable }) => (isEditable ? '3fr 8fr 1fr' : '3fr 9fr')};
  align-items: center;
  justify-content: center;
  padding: 12px 24px 12px 42px;
  font-size: 14px;
  border-bottom: 1px solid #ddd;
  ${({ disabled }) =>
    disabled &&
    css`
      background: #fafafa;
      & > span,
      & .expandable-textarea-container {
        opacity: 0.4;
      }
    `}
  & > span {
    font-weight: 600;
    margin-right: 4px;
  }
  & > label,
  & > svg {
    margin-left: 10px;
  }
  textarea {
    height: 41px;
    max-height: 200px;
    padding: 8px;
  }
`;

export const ActionArea = styled.div`
  width: calc(100% + 64px);
  padding: 10px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  box-shadow: 0px -2px 0px rgba(0, 0, 0, 0.1);
  position: sticky;
  margin-top: auto;
  left: -32px;
  bottom: 0;
  outline: none;
  & > button {
    margin: 0 10px;
    svg {
      margin-right: 4px;
      ${({ animate }) => animate && starsAnimation}
    }
    &:hover svg {
      ${starsAnimation}
    }
  }
`;

export const ViewContainer = styled.div`
  display: flex;
  height: 100%;
`;
export const ViewInner = styled.div`
  flex: 1;
  max-width: calc(100% - 420px);
  padding: 0 21px;
  padding-top: ${({ paddingTop }) => paddingTop ?? 0}px;
  margin-bottom: 32px;
  h2 {
    font-size: 20px;
    margin-bottom: 25px;
  }
`;
export const ViewHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 32px 0 16px;
  h2 {
    font-size: 20px;
  }
  button {
    border: 0;
    outline: 0;
    background-color: transparent;
    color: #03bb99;
    svg {
      font-size: 22px;
    }
  }
`;

export const PriorityField = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${({ marginTop }) => marginTop ?? 0}px;
  & > div {
    width: ${({ width }) => (width ? `${width}px` : '100%')};
    max-width: 100%;
  }
  & > p {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 8px;
  }
  & > svg {
    color: #00bb99;
    min-width: 30px;
    font-size: 30px;
  }
`;

export const RadioOptionWrapper = styled.div`
  display: flex;
`;

export const RadioOptionButton = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  padding: 12px;
  background: ${({ active }) => (active ? '#EBFFFC' : '#fff')};
  border-radius: 4px;
  border: 1px solid ${({ active }) => (active ? '#00BB99' : '#ddd')};
  width: 50%;
  margin: 3px;

  svg {
    font-size: 24px;
    color: #999;
    margin-bottom: 4px;
    opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  }
`;
export const Info = styled.div`
  display: flex;
  font-size: 12px;
  font-style: normal;
  border-radius: 4px;
  background: #dbeefc;
  width: 100%;
  padding: 12px;
  svg {
    margin-right: 10px;
    color: #4babee;
    min-width: 24px;
    font-size: 24px;
  }
`;

export const PrioritizationSection = styled.div`
  margin-bottom: 20px;
  ${({ noBottomBorder }) => !noBottomBorder && 'border-bottom: 1px solid #ddd;'}

  & > div:first-child {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 6px;
    h5 {
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
    }
    span {
      font-size: 14px;
      color: #666;
      font-weight: 600;
    }
    button {
      background-color: transparent;
      border: none;
      svg {
        color: #00bb99;
        font-size: 22px;
      }
    }
  }
  & > div:nth-child(2) {
    display: flex;
    flex-direction: column;
    ${({ noBottomBorder }) => !noBottomBorder && 'margin-bottom: 24px;'}
  }
`;
export const PrioritizationItem = styled.div`
  display: grid;
  grid-template-columns: minmax(100px, 3fr) minmax(250px, 2fr) 104px 24px;
  grid-gap: 10px;
  font-size: 14px;
  align-items: center;
  font-style: normal;
  border-radius: 4px;
  padding: 8px 12px;
  background-color: ${({ isOddRow }) => (isOddRow ? '#faf9f6' : '#fff')};
  & .simple-select.select--is-disabled {
    opacity: 0.5;
  }
`;

export const PrioritySelectValue = styled.div`
  line-height: 16px;
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  & > svg {
    margin-right: 10px;
    min-width: 16px;
  }
`;

export const GenerateAnimation = styled.div`
  display: flex;
  padding: 24px 0;
  padding-top: 0;
  align-items: center;
  position: relative;
  ${({ shouldDestroy }) =>
    shouldDestroy &&
    css`
      animation: ${destroyGenerateAnimation} 0.6s ease-in-out forwards;
    `}
  & > svg {
    margin-right: 14px;
  }
  & > div {
    position: absolute;
    left: 106px;
    bottom: 24px;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    h6 {
      font-size: 20px;
      margin-bottom: 6px;
    }
    p {
      margin: 0;
      font-size: 16px;
    }
  }
`;

export const ExceptionAddButton = styled.button`
  border: none;
  background-color: transparent;
  color: #03bb99;
  font-weight: 600;
  font-size: 14px;
  margin-top: 10px;
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
`;

export const ExceptionRemoveButton = styled.button`
  border: none;
  background-color: transparent;
  color: #999;
  font-weight: 600;
  font-size: 16px;
  padding: 0 10px;
  svg {
    height: 16px;
  }
`;
export const ExceptionSaveButton = styled(ExceptionRemoveButton)`
  background-color: #00bb99;
  display: flex;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  color: #fff;
  height: 28px;
  font-size: 14px;
  padding: 0 10px;
  & > svg circle {
    stroke: #fff !important;
  }
  &:disabled {
    background-color: #999;
  }
`;
export const ExceptionItem = styled(PrioritizationItem)`
  display: grid;
  grid-template-columns: minmax(100px, 3fr) minmax(250px, 2fr) 56px 36px 36px;
  grid-gap: 10px;
  align-items: center;
  border: 1px solid transparent;
  transition: 0.1s;
  padding: 0;
  background-color: #fff;
  border-radius: 4px;
  padding: 2px;
  &:focus-within {
    border-color: #00bb99;
  }
  textarea {
    border: none;
    resize: none;
    outline: none;
    height: 41px;
    max-height: 200px;
    padding: 10px;
  }
  & > label {
    padding-right: 12px;
  }
`;

export const IgnoreFieldRow = styled.div`
  display: flex;
  font-size: 14px;
  justify-content: center;
  margin-bottom: 10px;
`;
