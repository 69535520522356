import styled from 'styled-components';

export const ToggleAIAndMessenger = styled.div`
  display: flex;
  position: absolute;
  right: 0;
  background-color: #fff;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.08), 0 8px 24px 0 rgba(0, 0, 0, 0.2);
  flex-direction: column;
  z-index: 1;
  min-width: 180px;
  border-radius: 4px;
`;

export const Toggle = styled.div`
  padding: 10px 15px;

  &:hover {
    background-color: #efeeea;
  }
`;

export const Wrapper = styled.div`
  width: fit-content;
  position: relative;
`;

export const DotsWrap = styled.div`
  border-radius: 5px;
  img {
    width: 24px;
    height: 24px;
  }

  &:hover {
    background-color: #efeeea;
  }
`;
