import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100vh;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.background.variant4};
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 0;
`;

export const Logo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 32rem;
  margin-top: auto;
  margin-bottom: auto;
  padding-bottom: 40px;
`;

export const Message = styled.section`
  text-align: center;
  margin-top: 44px;
`;

export const MessageTitle = styled.h1`
  font-size: 20px;
  font-family: inherit;
  font-weight: 600;
  line-height: 1.5;
  margin-top: 0;
  margin-bottom: 26px;
`;

export const MessageBody = styled.p`
  font-size: 16px;
  line-height: 1.375;
  text-align: center;

  a {
    display: inline-block;
    border-bottom: 1px solid ${({ theme }) => theme.colors.accent.main};
    text-decoration: none;
    transition: opacity 0.2s;

    &:hover {
      opacity: 0.7;
    }
  }
`;

export const Footer = styled.footer`
  margin-top: auto;
  padding: 0 0 40px;
`;

export const FooterNote = styled.p`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.text.variant2};
  text-align: center;
  margin: 0;
`;
