import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as CWAutomateFullLogo } from 'src/assets/icons/cw-automate-full-logo.svg';
import { useCWAutomateFormPage } from './hook';
import { messageIntegrations } from '../../../../constants/integrations';
import { cwAutomateFormFields } from '../../../../constants/cw-automate-form';
import { overlayChidrens } from '../../overlayTypes';

import FormInputWithLabel from '../../../../components/FormInputWithLabel';
import MessageForm from '../../../../components/MessageForm';
import SimpleButton from '../../../../components/SimpleButton';
import PageWrapper from '../../../../components/PageWrapper';
import Switcher from '../../../../components/Switcher';

import { ToolsStyled, ContentStyled } from '../../style';
import styles from '../../styles.module.scss';

const CWAutomateForm = ({
  onSubmit,
  formData,
  isValid,
  status,
  visibleOverlay,
  configuration,
  errorMessage
}) => {
  const {
    baseUrl,
    setBaseUrl,
    username,
    setUsername,
    password,
    setPassword,
    formId,
    onFormSubmit,
    isActive,
    setIsActive
  } = useCWAutomateFormPage({ onSubmit, formData, configuration });

  return (
    <PageWrapper
      title="Automate"
      isBackButton
      isModalContent
      pageLogo={<CWAutomateFullLogo height="40" />}
      tools={
        <ToolsStyled>
          <Switcher
            id="is-active-flow"
            checked={isActive}
            onChange={() => setIsActive(!isActive)}
            controlText={{ checked: 'on', unchecked: 'off' }}
          />
          <SimpleButton
            onClick={onFormSubmit}
            isLoader={visibleOverlay}
            isDisabled={!baseUrl || !username || !password}
          >
            Save
          </SimpleButton>
        </ToolsStyled>
      }
    >
      <ContentStyled>
        <p className={styles.description}>
          {'Let’s setup your Automate, this is a one time setup. '}
          <a
            href="https://docs.getthread.com/article/1tw39qcd82-how-do-i-set-up-connectwise-automate"
            target="_blank"
            rel="noopener noreferrer"
          >
            Need help?
          </a>
        </p>
        {errorMessage && <MessageForm typeView="block">{errorMessage}</MessageForm>}
        {isValid === false && (
          <MessageForm typeView="block">{messageIntegrations.NOT_VALID}</MessageForm>
        )}
        {isValid === true && !errorMessage && configuration.is_active && (
          <MessageForm typeView="block" typeMsg="success">
            {messageIntegrations.VALID}
          </MessageForm>
        )}
        <form className={styles.form} id={formId} onSubmit={onFormSubmit}>
          <FormInputWithLabel
            onChange={event => setBaseUrl(event.target.value)}
            value={baseUrl}
            {...cwAutomateFormFields.BASE_URL}
          />
          <FormInputWithLabel
            onChange={event => setUsername(event.target.value)}
            value={username}
            {...cwAutomateFormFields.USERNAME}
          />
          <FormInputWithLabel
            onChange={event => setPassword(event.target.value)}
            value={password}
            type="password"
            {...cwAutomateFormFields.PASSWORD}
          />
          <SimpleButton
            className={styles.button}
            type="submit"
            formId={formId}
            isDisabled={!baseUrl || !username || !password}
          >
            Connect
          </SimpleButton>
        </form>
      </ContentStyled>
      {visibleOverlay && overlayChidrens[status] && (
        <div className={styles.overlay}>
          {overlayChidrens[status].icon}
          <p>{overlayChidrens[status].text}</p>
        </div>
      )}
    </PageWrapper>
  );
};

CWAutomateForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  formData: PropTypes.objectOf(PropTypes.any),
  isValid: PropTypes.bool
};

CWAutomateForm.defaultProps = {
  formData: {},
  isValid: undefined
};

export default CWAutomateForm;
