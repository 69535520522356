import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './style';

export const TeamsSidebar = ({ iconUrl, appName }) => (
  <Styled.AsidePreview>
    <Styled.MenuPlaceholder />
    <Styled.MenuPlaceholder />
    <Styled.MenuPlaceholder />
    <Styled.MenuPlaceholder />
    <Styled.MenuLogo url={iconUrl} content={appName} />
    <Styled.MenuPlaceholder />
    <Styled.MenuPlaceholder />
  </Styled.AsidePreview>
);

export const TeamsHeader = ({ children }) => (
  <Styled.HeaderPreview>
    <Styled.TeamsTitle>{children}</Styled.TeamsTitle>
  </Styled.HeaderPreview>
);
