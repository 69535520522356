import React from 'react';
import PropTypes from 'prop-types';
import Autotask from 'src/pages/SignupTicketing/Autotask';
import { FORM_ID } from 'src/pages/SignupTicketing/Autotask/config';
import { Route, Switch, withRouter } from 'react-router-dom';
import { messageIntegrations } from '../../../../constants/integrations';
import { overlayChidrens } from '../../overlayTypes';
import MessageForm from '../../../../components/MessageForm';
import SimpleButton from '../../../../components/SimpleButton';
import PageWrapper from '../../../../components/PageWrapper';
import Switcher from '../../../../components/Switcher';
import {
  ToolsStyled,
  ContentStyled,
  FormContainer,
  IntegrationLogo,
  FormContent
} from '../../style';
import styles from '../../styles.module.scss';
import { useControlForm } from '../../hook';
import IntegrationSyncForm from '../IntegrationSyncForm';

const AutotaskForm = ({
  onSubmit,
  formData,
  isValid,
  status,
  visibleOverlay,
  configuration,
  errorMessage,
  match
}) => {
  const { onFormSubmit, isActive, setIsActive, canSubmit, defaultValues, onFormValid } =
    useControlForm({
      onSubmit,
      formData,
      configuration,
      submitValueNormalizer(data) {
        return {
          code: data.code,
          companyName: data.companyName,
          username: data.username,
          password: data.password
        };
      }
    });

  return (
    <PageWrapper
      title="Autotask"
      isBackButton
      backButtonPath="/dashboard/integrations"
      pageLogo={<img src="/images/autotask-logo.png" width="150" alt="Autotask logo" />}
      tools={
        <ToolsStyled>
          <Switcher
            id="is-active-flow"
            checked={isActive}
            onChange={() => setIsActive(!isActive)}
            controlText={{ checked: 'on', unchecked: 'off' }}
          />
          <SimpleButton
            isLoader={visibleOverlay}
            isDisabled={!canSubmit}
            type="submit"
            form={FORM_ID}
          >
            Save
          </SimpleButton>
        </ToolsStyled>
      }
      tabs={[
        { id: 1, path: `${match.url}`, name: 'Configuration' },
        { id: 2, path: `${match.url}/sync`, name: 'PSA Sync' }
      ]}
    >
      <ContentStyled>
        <FormContainer>
          <IntegrationLogo src="/images/autotask-logo.png" width="150" alt="Autotask logo" />
          <Switch>
            <Route path={`${match.path}/sync`} component={IntegrationSyncForm} />
            <Route
              path={`${match.path}`}
              render={() => (
                <FormContent>
                  {errorMessage && <MessageForm typeView="block">{errorMessage}</MessageForm>}
                  {isValid === false && (
                    <MessageForm typeView="block">{messageIntegrations.NOT_VALID}</MessageForm>
                  )}
                  {isValid === true && !errorMessage && configuration.is_active && (
                    <MessageForm typeView="block" typeMsg="success">
                      {messageIntegrations.VALID}
                    </MessageForm>
                  )}
                  <Autotask
                    onSubmit={onFormSubmit}
                    defaultValues={defaultValues}
                    onFormValid={onFormValid}
                    isLogoVisible={false}
                    withSyncMembersCheckbox={false}
                  />
                </FormContent>
              )}
            />
          </Switch>
          {visibleOverlay && overlayChidrens[status] && (
            <div className={styles.overlay}>{overlayChidrens[status].icon}</div>
          )}
        </FormContainer>
      </ContentStyled>
    </PageWrapper>
  );
};

AutotaskForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  formData: PropTypes.objectOf(PropTypes.any),
  isValid: PropTypes.bool,
  status: PropTypes.string,
  visibleOverlay: PropTypes.bool,
  errorMessage: PropTypes.string,
  configuration: PropTypes.any
};

AutotaskForm.defaultProps = {
  formData: {},
  isValid: undefined,
  visibleOverlay: false,
  status: '',
  errorMessage: '',
  configuration: {}
};

export default withRouter(AutotaskForm);
