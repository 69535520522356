import Table from 'src/components/Table';
import React, { useState } from 'react';
import { useIsFetching } from 'react-query';
import queryKeys from 'src/constants/queryKeys';
import { chatgenieAPI } from 'src/config/api';
import { queryClient } from 'src/config/queryClient';
import { useHistory } from 'react-router-dom';
import SimpleButton from 'src/components/SimpleButton';
import { columns } from './config';
import { AgentHeader } from './style';

const Agent = ({ appId }) => {
  const [data, setData] = useState(
    queryClient.getQueryData([queryKeys.GET_AGENTS, { page: 1 }]) || []
  );
  const isFetching = useIsFetching({ queryKey: [queryKeys.GET_AGENTS] });
  const [errorMessage, setErrorMessage] = useState('');
  const getData = params => {
    const page = params?.page || 1;
    setData(queryClient.getQueryData([queryKeys.GET_AGENTS, { page }]) || []);
    queryClient
      .fetchQuery({
        queryKey: [queryKeys.GET_AGENTS, { page }],
        queryFn: () => chatgenieAPI.getAgents({ page, query: '' })
      })
      .then(res => {
        setErrorMessage('');
        setData(res);
      })
      .catch(err => {
        setErrorMessage(err?.response?.data?.message || err?.message);
      });
  };
  const history = useHistory();

  return (
    <>
      <AgentHeader>
        <div>
          <h5>Service Catalog</h5>
        </div>
        <div>
          <SimpleButton
            onClick={() => {
              history.push('/dashboard/magic/agent/create');
            }}
          >
            Create Intent
          </SimpleButton>
        </div>
      </AgentHeader>
      <Table
        isSearch={false}
        showTopPanelMeta={false}
        getData={getData}
        isLoading={!!isFetching}
        error={errorMessage}
        columns={columns}
        onRowClick={row => {
          history.push(`/dashboard/magic/agent/update/${row.id}`);
        }}
        rows={{
          data,
          meta: {
            current_page: 1,
            from: data.length,
            last_page: 1,
            to: data.length,
            total: data.length
          }
        }}
      />
    </>
  );
};

export default Agent;
