import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import ActionModal from 'src/components/ActionModal';
import { ReactComponent as Spinner } from 'src/assets/icons/spinner.svg';
import styled from 'styled-components/macro';

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 320px;
`;

const Title = styled.h5`
  margin-bottom: 16px;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
`;

const Description = styled.p`
  margin-bottom: 30px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
`;

const pendingTextsConfig = [
  'Building your Teams app…',
  'Powering up our AI overlord…',
  'Initiating coffee process...',
  'Putting the last hamster on the hamster wheel…',
  'Contemplating life choices…'
];

const TEXT_INTERVAL = 18000;

const PendingModal = ({ isOpen }) => {
  const [descriptionIndex, setDescriptionIndex] = useState(0);

  useEffect(() => {
    const id = setInterval(() => {
      setDescriptionIndex(index => index + 1);
    }, TEXT_INTERVAL / pendingTextsConfig.length);

    if (descriptionIndex === pendingTextsConfig.length - 1) {
      clearInterval(id);
    }

    return () => clearInterval(id);
  });

  return (
    <ActionModal isOpen={isOpen} size="medium">
      <ContentWrapper>
        <Title>Creating your new custom Teams app!</Title>
        <Description>{pendingTextsConfig[descriptionIndex]}</Description>
        <Spinner width={30} height={30} />
      </ContentWrapper>
    </ActionModal>
  );
};

PendingModal.propTypes = {
  isOpen: PropTypes.bool
};

PendingModal.defaultProps = {
  isOpen: false
};

export default PendingModal;
