import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as Spinner } from 'src/assets/icons/spinner.svg';
import { Wrapper, Message } from './Loader.style';

const Loader = ({ message, width = 40, height = 40 }) => {
  return (
    <Wrapper>
      <Spinner width={width} height={height} />
      {message && <Message>{message}</Message>}
    </Wrapper>
  );
};

Loader.defaultProps = {
  message: '',
  width: 40,
  height: 40
};

Loader.propTypes = {
  message: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number
};

export default Loader;
