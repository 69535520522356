import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as Styled from './styles';

const ImageUpload = ({ onChange, background, file, name, ...rest }) => {
  const reader = new FileReader();
  const [imagePreviewUrl, setImagePreviewUrl] = useState(file);
  useEffect(() => {
    setImagePreviewUrl(file);
  }, [file]);

  useEffect(() => () => {
    reader.abort();
  });

  const handleImageChange = event => {
    event.preventDefault();
    const newFile = event.target.files[0];

    if (newFile) {
      reader.onloadend = () => {
        onChange({
          file: newFile,
          url: reader.result
        });
      };
      reader.readAsDataURL(newFile);
    }
  };

  return (
    <Styled.Wrapper>
      <input
        className="fileInput"
        name={name}
        data-testid={name}
        type="file"
        onChange={handleImageChange}
        {...rest}
      />
      {background ? (
        background(<img src={imagePreviewUrl} alt="" />)
      ) : (
        <img src={imagePreviewUrl} alt="" width="116" />
      )}
    </Styled.Wrapper>
  );
};

ImageUpload.defaultProps = {
  background: null,
  file: '',
  name: ''
};

ImageUpload.propTypes = {
  onChange: PropTypes.func.isRequired,
  background: PropTypes.func,
  file: PropTypes.string,
  name: PropTypes.string
};

export default ImageUpload;
