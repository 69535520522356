import styled from 'styled-components';
import { darken } from 'polished';

export const LabelStyled = styled.label`
  padding: 0;
  margin: 0;
  cursor: pointer;

  display: flex;
  flex-direction: row;

  span {
    padding-right: 10px;
  }

  &.switcher-loading {
    cursor: not-allowed;
  }
`;

export const ControlStyled = styled.span`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: ${({ width }) => `${width}px` || '78px'};
  height: 30px;
  padding: 3px;
  box-sizing: border-box;
  border-radius: 15px;
  background: ${({ backgroundType, theme }) =>
    backgroundType === 'theme' ? theme.colors.gray1 : '#bdbdbd'};
  transition: background 0.2s;

  &::before {
    content: '';
    position: absolute;
    left: 3px;
    display: block;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: ${({ backgroundType, theme }) =>
      backgroundType === 'theme' ? theme.colors.background.default : '#616161'};
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 0 2px 0 rgba(0, 0, 0, 0.08);
    transition: left 0.2s, background 0.2s;
  }

  .switcher-loading &&&&::before {
    background-color: #fff;
  }

  .switcher-loading &&&&::before {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' fill='%23051275'%3E%3Cpath opacity='.25' d='M16 0a16 16 0 0 0 0 32 16 16 0 0 0 0-32m0 4a12 12 0 0 1 0 24 12 12 0 0 1 0-24'/%3E%3Cpath d='M16 0a16 16 0 0 1 16 16h-4A12 12 0 0 0 16 4z'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 16 16' to='360 16 16' dur='0.8s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
    background-size: 80% 80%;
    background-repeat: no-repeat;
    background-position: center;
  }
`;

export const ControlTextStyled = styled.span`
  padding: ${({ checked }) => (checked ? '0 32px 0 16px' : '0 16px 0 32px')};
  font-size: 12px;
  line-height: 17px;
  font-weight: bold;
  user-select: none;
`;

export const InputStyled = styled.input`
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;

  .switcher-loading &&&&:checked ~ ${ControlStyled} {
    background: ${darken(0.2, '#bdbdbd')};
  }

  &:checked ~ ${ControlStyled} {
    justify-content: flex-start;
    background: ${({ theme }) => theme.colors.brand.light.base};

    &::before {
      background: #ffffff;
      left: calc(100% - 24px - 3px);
    }
  }

  &:checked ~ ${ControlStyled} ${ControlTextStyled} {
    left: 0;
    color: #ffffff;
  }
`;
