import styled, { css } from 'styled-components/macro';
import ImageAttachmentBlock from 'src/components/ImageAttachmentBlock';
import dangerIcon from 'src/assets/icons/danger.svg';

const formActionStyle = css`
  width: 100%;
  padding: 11px 13px;
  border: 1px solid ${({ theme }) => theme.colors.border.variant1};
  border-radius: 2px;
  background: ${({ theme }) => theme.colors.background.default};
  font-size: 14px;
  line-height: 17px;
  transition: border 0.2s;

  &:hover,
  &:focus {
    outline: none;
    border: 1px solid ${({ theme }) => theme.colors.brand.light.hover};
  }
`;

export const ImageBlock = styled(ImageAttachmentBlock)`
  width: 120px;
  height: 120px;
  margin: 8px 0;
`;

export const Form = styled.form`
  flex-grow: 1;
  margin-bottom: 72px;
  padding: min(3vw, 35px) min(6vw, 84px);
`;
export const ImageFieldsWrapper = styled.div`
  display: flex;
  & > fieldset:last-child {
    margin-left: 40px;
    padding-bottom: 0;
  }
  & > fieldset {
    min-width: 160px;
  }
`;
export const FormGroup = styled.fieldset`
  max-width: 700px;
  margin-bottom: 16px;

  &:last-child {
    padding-bottom: 32px;
  }
`;

export const FieldError = styled.p`
  height: 13px;
  margin-top: 2px;
  margin-bottom: 0;
  font-size: 11px;
  line-height: 13px;
  color: ${({ theme }) => theme.colors.danger.main};
`;

export const GroupTitle = styled.legend`
  display: flex;
  float: none;
  margin: 0;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  ${({ marginBottom }) => marginBottom && `margin-bottom: ${marginBottom}px`}
`;

export const GroupDescription = styled.p`
  margin: 2px 0 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.text.variant2};
`;

export const Figure = styled.div`
  position: relative;
  max-width: 30%;
  width: 100%;

  img {
    position: fixed;
    top: calc(10% + 72px);
    left: 73%;
    width: min(60vw, 655px);
    height: auto;
    z-index: -1;
  }
`;

export const AddTabButton = styled.button`
  display: flex;
  align-items: center;
  padding: 0;
  margin-left: 3px;
  margin-top: 8px;
  border: none;
  background: none;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: ${({ theme }) => theme.colors.brand.light.base};

  svg {
    fill: ${({ theme }) => theme.colors.brand.light.base};
    width: 12px;
    height: 12px;
    margin-right: 10px;
  }

  &:hover,
  &:focus {
    color: ${({ theme }) => theme.colors.brand.light.hover};

    svg {
      fill: ${({ theme }) => theme.colors.brand.light.hover};
    }
  }
`;

export const Tab = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: -1px;
  padding: 8px 32px;
  padding-right: 0;
  border-radius: 2px;
  border: 1px solid ${({ theme }) => theme.colors.border.variant1};
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  background-color: ${({ theme }) => theme.colors.background.default};

  svg {
    position: absolute;
    left: 8px;
    display: block;
    width: 16px;
    height: 16px;
  }
`;

export const RemoveButton = styled.button`
  position: absolute;
  left: 100%;
  padding: 8px;
  border: none;
  background: none;

  svg {
    position: static;
    right: 0;
  }
`;

export const TabField = styled.input`
  width: 100%;
  height: 100%;
  margin: -8px 0;
  padding: 9px 0;
  border: none;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;

  &:focus {
    outline: none;
  }
`;

export const ImageWrapper = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 120px;
  margin: 0;
  margin-top: 8px;
  margin-bottom: 8px;
  border: 1px solid ${({ theme }) => theme.colors.border.variant1};
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.background.default};
  cursor: pointer;

  img {
    max-width: 100%;
    height: auto;
  }

  input {
    position: absolute;
    top: -1000px;
    left: -1000px;
    opacity: 0;
    width: 1px;
    height: 1px;
  }

  svg {
    width: 24px;
    height: 24px;
  }
`;

export const ImagePlaceholder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.background.default};
`;

export const ImageDescription = styled.p`
  margin: 0;
  font-size: 12px;
  line-height: 15px;
  color: ${({ theme }) => theme.colors.text.variant3};
`;

export const Input = styled.input`
  ${formActionStyle}
`;

export const Textarea = styled.textarea`
  ${formActionStyle}
  display: block;
  min-height: ${({ minHeight }) => minHeight || 50}px;
  max-height: 180px;
`;

export const FieldWrapper = styled.div`
  position: relative;
  width: 100%;

  &:last-of-type input {
    padding-left: 12px;
    border-left: 1px solid ${({ theme }) => theme.colors.border.variant1};
  }

  ${({ isError }) =>
    isError &&
    css`
      &::after {
        content: '';
        position: absolute;
        right: 8px;
        top: 50%;
        width: 20px;
        height: 20px;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url(${dangerIcon});
        transform: translateY(-50%);
      }
    `}
`;

export const ErrorTooltip = styled.div`
  position: absolute;
  top: calc(100% + 12px);
  right: -8px;
  padding: 12px;
  border-radius: 2px;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  background: ${({ theme }) => theme.colors.danger.main};
  z-index: 100;

  &::after {
    content: '';
    position: absolute;
    top: -5px;
    right: 20px;
    width: 12px;
    height: 12px;
    bottom: 100%;
    border-radius: 2px;
    background: ${({ theme }) => theme.colors.danger.main};
    transform: rotate(45deg) skew(10deg, 10deg);
  }
`;

export const IntegrationInfo = styled.span`
  margin: 0 16px 0 auto;
  font-size: 12px;
  line-height: 15px;

  svg {
    position: static;
    display: inline-flex;
    margin-right: 5px;
    stroke: ${({ theme }) => theme.colors.brand.light.base};
  }
`;

export const IntegrationName = styled.span`
  color: ${({ isActive }) => (isActive ? '#000000' : '#999999')};
`;
