import React from 'react';
import PropTypes from 'prop-types';
import { Wrapper, Image, Title, Description, Actions, Button } from './Card.style';
import Loader from '../Loader';

const Card = ({ image, title, description, action, isLoading }) => {
  return (
    <Wrapper>
      <Image>{image}</Image>
      {title && <Title>{title}</Title>}
      {description && <Description>{description}</Description>}
      {action && (
        <Actions>
          {isLoading ? (
            <Loader width={24} height={24} />
          ) : (
            <Button onClick={action.action} variant={action.variant}>
              {action.label}
            </Button>
          )}
        </Actions>
      )}
    </Wrapper>
  );
};

Card.defaultProps = {
  image: null,
  title: null,
  description: null,
  action: null,
  isLoading: false
};

Card.propTypes = {
  image: PropTypes.node,
  title: PropTypes.node,
  description: PropTypes.node,
  action: PropTypes.shape({
    action: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    variant: PropTypes.oneOf(['primary', 'secondary'])
  }),
  isLoading: PropTypes.bool
};

export default Card;
