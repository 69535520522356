import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from 'react-query';
import useToast from 'src/hooks/useToast';
import useAuthUser from 'src/hooks/useAuthUser';
import { getInitials } from 'src/utils';
import { ReactComponent as MagicIcon } from 'src/assets/icons/magic-icon-with-bg.svg';
import FadeInOutContainer from 'src/components/FadeInOutContainer';
import magicAnimated from 'src/assets/icons/magic-animated.gif';
import * as Styled from './style';

const animationContainerStyles = {
  display: 'flex',
  justifyContent: 'center',
  paddingTop: '32px',
  position: 'absolute',
  width: '100%',
  left: 0,
  top: 0
};

const CopilotTestAction = ({
  title,
  description,
  threads,
  onAction,
  firstMessage,
  askAnotherMessage,
  newMessageNormalizer,
  isAnimating
}) => {
  const authUser = useAuthUser();
  const { toast } = useToast();
  const [message, setMessage] = useState('');
  const [option, setOption] = useState(0);
  const scrollElementRef = useRef(null);

  const initialMessage = {
    type: 'copilot',
    name: 'Magic AI',
    body: firstMessage
  };

  const [messages, setMessages] = useState([initialMessage]);

  const { mutate, isLoading } = useMutation(
    () => {
      return onAction(option);
    },
    {
      onSuccess: res => {
        const copilotMessage = newMessageNormalizer(res);

        setMessages(old => [...old, copilotMessage]);

        // Prompt user to ask another question after 2 seconds
        setTimeout(() => {
          setMessages(old => [
            ...old,
            {
              type: 'copilot',
              name: 'Magic AI',
              body: askAnotherMessage
            }
          ]);
        }, 2000);
      },
      onError: error => {
        toast.error(`Failed to send message: ${error?.response?.data?.message || error?.message}`);
      }
    }
  );

  const sendMessage = event => {
    if (!message || isLoading || isAnimating) return;

    if (event.key === 'Enter' || !event.key) {
      setMessages([
        ...messages,
        {
          type: 'user',
          name: authUser.fullname,
          body: message
        }
      ]);
    }
  };

  const scrollToBottom = () =>
    scrollElementRef.current.scrollTo(0, scrollElementRef.current.scrollHeight);

  useEffect(() => {
    if (scrollElementRef.current) {
      scrollToBottom();
    }
  }, [messages]);

  useEffect(() => {
    if (scrollElementRef.current && isLoading) {
      scrollToBottom();
    }
  }, [isLoading]);

  useEffect(() => {
    if (!isAnimating) {
      setMessages([initialMessage]);
    }
  }, [isAnimating]);

  const options = threads?.map(thread => ({
    value: thread.ticket_id,
    label: `Thread ${thread.ticket_id}: ${thread.title}`
  }));

  const updateThread = option => {
    const matchingThread = threads.find(thread => thread.ticket_id === option.value);
    setOption((matchingThread && matchingThread.ticket_id) || 0);
  };

  return (
    <Styled.Aside>
      <Styled.AsideTitle>{title}</Styled.AsideTitle>
      <Styled.AsideDescription>{description}</Styled.AsideDescription>
      <Styled.CustomSelect options={options} option={option} onChange={updateThread} />
      <Styled.ActionButton
        styleMode="outline"
        size="small"
        onClick={mutate}
        isDisabled={option === 0}
      >
        Generate Recap
      </Styled.ActionButton>
      <Styled.Messenger>
        <FadeInOutContainer fullHeight visible={!isAnimating}>
          <Styled.MessengerBody ref={scrollElementRef}>
            {messages.map((message, index) => (
              <Styled.Message
                // eslint-disable-next-line react/no-array-index-key
                key={`${message.body}-${index}`}
                className={`message-${message.type}`}
              >
                <Styled.Avatar type={message.type}>
                  {message.type === 'copilot' ? (
                    <MagicIcon width="32" height="32" />
                  ) : (
                    getInitials(message.name)
                  )}
                </Styled.Avatar>
                <Styled.MessageContent>
                  <Styled.MessageName>{message.name}</Styled.MessageName>
                  {message.body}
                </Styled.MessageContent>
              </Styled.Message>
            ))}
            {isLoading && (
              <Styled.TypingIndicator>
                <Styled.CopilotAvatar>
                  <MagicIcon width="18" height="18" />
                </Styled.CopilotAvatar>
                <Styled.TypingText>Magic is&nbsp;typing...</Styled.TypingText>
              </Styled.TypingIndicator>
            )}
          </Styled.MessengerBody>
        </FadeInOutContainer>
        <FadeInOutContainer visible={isAnimating} style={animationContainerStyles}>
          <img src={magicAnimated} alt="Magic" width="64" height="64" />
        </FadeInOutContainer>
      </Styled.Messenger>
    </Styled.Aside>
  );
};

CopilotTestAction.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  threads: PropTypes.arrayOf({
    ticket_id: PropTypes.number,
    title: PropTypes.string
  }).isRequired,
  firstMessage: PropTypes.string.isRequired,
  askAnotherMessage: PropTypes.string.isRequired,
  newMessageNormalizer: PropTypes.func.isRequired,
  onAction: PropTypes.func.isRequired,
  isAnimating: PropTypes.bool
};

CopilotTestAction.defaultProps = {
  isAnimating: false
};

export default CopilotTestAction;
