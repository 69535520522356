import React, { Fragment, useState } from 'react';
import theme from 'src/config/theme';
import { useIsFetching } from 'react-query';
import queryKeys from 'src/constants/queryKeys';
import { chatgenieAPI } from 'src/config/api';
import { queryClient } from 'src/config/queryClient';
import { columns } from './config';

import PageWrapper from '../../components/PageWrapper';
import Table from '../../components/Table';

import { TableTitle } from './styles';

const AuditLogs = () => {
  const [data, setData] = useState(
    queryClient.getQueryData([queryKeys.GET_AUDIT_LOGS, { page: 1 }])
  );

  const isFetching = useIsFetching({ queryKey: [queryKeys.GET_AUDIT_LOGS] });
  const [errorMessage, setErrorMessage] = useState('');
  const getData = params => {
    const page = params?.page || 1;
    setData(queryClient.getQueryData([queryKeys.GET_AUDIT_LOGS, { page }]));
    queryClient
      .fetchQuery({
        queryKey: [queryKeys.GET_AUDIT_LOGS, { page }],
        queryFn: () => chatgenieAPI.getAuditLogs({ page })
      })
      .then(res => {
        setErrorMessage('');
        setData(res);
      })
      .catch(err => {
        setErrorMessage(err?.response?.data?.message || err?.message);
      });
  };

  return (
    <Fragment>
      <PageWrapper backgroundColor={theme.colors.common.white} title="Security Center">
        <TableTitle>Audit log</TableTitle>
        <Table
          isSearch={false}
          showTopPanelMeta={false}
          getData={getData}
          isLoading={!!isFetching}
          error={errorMessage}
          columns={columns}
          rows={data}
        />
      </PageWrapper>
    </Fragment>
  );
};

export default AuditLogs;
