import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Table from 'src/components/Table';
import useAuthUser from 'src/hooks/useAuthUser';
import { FiArrowRight } from 'react-icons/fi';
import { DeployButton } from './PartnerClients.style';
import usePartnerClients from '../hooks/usePartnerClients';
import partnerClientsColumns from './partnerClientsColumns';
import { customerDeploymentStates } from '../constants';

const DeployAction = ({ onClick }) => {
  return (
    <DeployButton onClick={onClick}>
      <span>Deploy App</span>
      <FiArrowRight />
    </DeployButton>
  );
};

DeployAction.propTypes = {
  onClick: PropTypes.func.isRequired
};

const ClientTableByDeploymentState = ({ deploymentState, onDeploy }) => {
  const { company } = useAuthUser();

  const [queryString, setQueryString] = useState(`?page=1`);

  const handleQueryStringChanged = useCallback(({ queryString }) => {
    setQueryString(queryString);
  }, []);

  const { isLoading, clients } = usePartnerClients({
    companyId: company.id,
    deploymentState,
    queryString
  });

  const tableColumns = useMemo(() => {
    return partnerClientsColumns({
      DeployActionComponent:
        deploymentState === customerDeploymentStates.NOT_DEPLOYED ? DeployAction : null,
      onDeployAction: onDeploy
    });
  }, [onDeploy]);

  return (
    <Table
      getData={handleQueryStringChanged}
      isLoading={isLoading}
      rows={clients}
      columns={tableColumns}
    />
  );
};

ClientTableByDeploymentState.defaultProps = {
  onDeploy: () => {}
};

ClientTableByDeploymentState.propTypes = {
  deploymentState: PropTypes.oneOf(Object.values(customerDeploymentStates)).isRequired,
  onDeploy: PropTypes.func
};

export default ClientTableByDeploymentState;
