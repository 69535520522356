import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as TimeZestFullLogo } from 'src/assets/icons/timezest_full_logo.svg';
import { timeZestFormFields } from 'src/constants/timezest-integration-form';
import { messageIntegrations } from '../../../../constants/integrations';
import { overlayChidrens } from '../../overlayTypes';

import PageWrapper from '../../../../components/PageWrapper';
import FormInputWithLabel from '../../../../components/FormInputWithLabel';
import MessageForm from '../../../../components/MessageForm';
import SimpleButton from '../../../../components/SimpleButton';
import Switcher from '../../../../components/Switcher';

import { ToolsStyled, ContentStyled } from '../../style';
import styles from '../../styles.module.scss';
import { useControlForm } from '../../hook';

const formId = 'integration-timezest-form';
const TimeZestForm = ({
  onSubmit,
  formData,
  isValid,
  status,
  visibleOverlay,
  configuration,
  errorMessage
}) => {
  const {
    defaultValues: values = {},
    setDefaultValues: setValues,
    isActive,
    setIsActive
  } = useControlForm({ onSubmit, formData, configuration });
  const { domainBaseUrl, apiKey } = values;
  const isDisabled = !domainBaseUrl || !apiKey;
  const handleSubmit = e => {
    e.preventDefault();
    onSubmit(values, isActive);
  };

  return (
    <PageWrapper
      title="TimeZest"
      isBackButton
      isModalContent
      pageLogo={<TimeZestFullLogo height="40" />}
      tools={
        <ToolsStyled>
          <Switcher
            id="is-active-flow"
            checked={isActive}
            onChange={() => setIsActive(!isActive)}
            controlText={{ checked: 'on', unchecked: 'off' }}
          />
          <SimpleButton onClick={handleSubmit} isLoader={visibleOverlay} isDisabled={isDisabled}>
            Save
          </SimpleButton>
        </ToolsStyled>
      }
    >
      <ContentStyled>
        <p className={styles.description}>Let’s setup your TimeZest, this is a one time setup.</p>
        {errorMessage && <MessageForm typeView="block">{errorMessage}</MessageForm>}
        {isValid === false && (
          <MessageForm typeView="block">{messageIntegrations.NOT_VALID}</MessageForm>
        )}
        {isValid === true && !errorMessage && configuration.is_active && (
          <MessageForm typeView="block" typeMsg="success">
            {messageIntegrations.VALID}
          </MessageForm>
        )}
        <form className={styles.form} id={formId} onSubmit={handleSubmit}>
          <FormInputWithLabel
            onChange={event => setValues(old => ({ ...old, domainBaseUrl: event.target.value }))}
            value={domainBaseUrl}
            {...timeZestFormFields.BASE_URL}
          />
          <FormInputWithLabel
            onChange={event => setValues(old => ({ ...old, apiKey: event.target.value }))}
            value={apiKey}
            {...timeZestFormFields.APIKEY}
          />
          <SimpleButton
            className={styles.button}
            type="submit"
            formId={formId}
            isDisabled={isDisabled}
          >
            Connect
          </SimpleButton>
        </form>
      </ContentStyled>
      {visibleOverlay && overlayChidrens[status] && (
        <div className={styles.overlay}>
          {overlayChidrens[status].icon}
          <p>{overlayChidrens[status].text}</p>
        </div>
      )}
    </PageWrapper>
  );
};

TimeZestForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  formData: PropTypes.objectOf(PropTypes.any),
  isValid: PropTypes.bool
};

TimeZestForm.defaultProps = {
  formData: {},
  isValid: undefined
};

export default TimeZestForm;
