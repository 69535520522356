export default {
  SLACK: {
    key: 'SLACK',
    buttonText: 'Connect with slack',
    integrationLink: process.env.REACT_APP_SLACK_LINK
  },
  MSTEAMS: {
    key: 'MSTEAMS',
    buttonText: 'Connect with msteams'
  }
};
