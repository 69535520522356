import React from 'react';
import PropTypes from 'prop-types';

import {
  ModalError,
  ModalStyled,
  ModalHeaderStyled,
  ModalBodyStyled,
  DescriptionStyled
} from './style';

const ActionModal = ({
  isOpen,
  toggleHandler,
  title,
  description,
  errorMessage,
  children,
  size,
  aligned = 'centered',
  headerStyle = {}
}) => (
  <ModalStyled isOpen={isOpen} toggle={toggleHandler} size={size}>
    {title && (
      <ModalHeaderStyled toggle={toggleHandler} aligned={aligned} style={headerStyle}>
        {title}
      </ModalHeaderStyled>
    )}
    {errorMessage && <ModalError>{errorMessage}</ModalError>}
    <ModalBodyStyled aligned={aligned}>
      {description && <DescriptionStyled>{description}</DescriptionStyled>}
      {children}
    </ModalBodyStyled>
  </ModalStyled>
);

ActionModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleHandler: PropTypes.func.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  children: PropTypes.node,
  size: PropTypes.oneOf(['small', 'medium']),
  errorMessage: PropTypes.string,
  aligned: PropTypes.oneOf(['centered', 'left']),
  headerStyle: PropTypes.objectOf(PropTypes.any)
};

ActionModal.defaultProps = {
  title: '',
  description: '',
  children: '',
  size: 'small',
  errorMessage: null,
  aligned: 'centered',
  headerStyle: {}
};

export default ActionModal;
