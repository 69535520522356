import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { clearNotifications } from '../../redux/modules/notifications/notificationsActions';
import { useNotifications } from './hook';

const StackMessages = ({ notificationsList, clearNotifications, children }) => {
  useNotifications(notificationsList, clearNotifications);
  return <React.Fragment>{children}</React.Fragment>;
};

StackMessages.propTypes = {
  notificationsList: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  clearNotifications: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
};

StackMessages.defaultProps = {
  notificationsList: [],
  clearNotifications: () => {},
  children: ''
};

const mapStateToProps = state => ({
  notificationsList: state.notificationsReducer.notifications
});

function mapDispatchToProps(dispatch) {
  return {
    clearNotifications: () => dispatch(clearNotifications())
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(StackMessages);
