import theme from 'src/config/theme';
import styled from 'styled-components';

const placeholderMessage = size => ({
  fontSize: '14px',
  lineHeight: '17px',
  textAlign: 'center',
  padding: size === 'small' ? '6px 14px' : '11px 20px',
  boxShadow: '0 4px 11px #0000001A',
  color: '#999999'
});

export const styles = ({ size, customStyles = {} }) => ({
  control: (provided, state) => ({
    ...provided,
    minHeight: size === 'small' ? '30px' : '38px',
    borderColor: state.isFocused ? theme.colors.brand.light.hover : theme.colors.gray1,
    boxShadow: 'none',
    borderRadius: 2,
    fontSize: '14px',
    lineHeight: '17px',
    color: '#212121',
    backgroundColor: state.isDisabled ? '#E5E5E5' : '#FFFFFF',
    outline: state.isFocused && 'none',
    cursor: 'pointer',
    '&:hover': {
      borderColor: theme.colors.brand.light.hover
    },
    ...customStyles?.controlStyles
  }),
  valueContainer: provided => ({
    ...provided,
    padding: size === 'small' ? '6px 8px' : '12px 10px',
    '& input': {
      maxHeight: '16px'
    }
  }),
  input: provided => ({
    ...provided,
    maxHeight: '16px',
    margin: 0,
    padding: 0,
    '& input': {
      maxHeight: '16px'
    }
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  indicatorsContainer: provided => ({
    ...provided,
    '& svg': {
      fill: '#000000'
    }
  }),
  dropdownIndicator: provided => ({
    ...provided,
    padding: size === 'small' ? '4px' : '8px'
  }),
  menu: provided => ({
    ...provided,
    margin: 0,
    border: 'none',
    borderRadius: 0,
    boxShadow: '0 4px 11px #0000001A',
    ...customStyles?.menuStyles
  }),
  menuList: provided => ({
    ...provided,
    whiteSpace: 'normal',
    padding: 0
  }),
  noOptionsMessage: () => placeholderMessage(size),
  loadingMessage: () => placeholderMessage(size),
  option: (provided, state) => ({
    ...provided,
    fontSize: '12px',
    lineHeight: '16px',
    padding: size === 'small' ? '6px 14px' : '11px 20px',
    color: '#616161',
    backgroundColor: state.isSelected
      ? `${({ theme }) => theme.colors.brand.light.base}42`
      : state.isFocused
      ? '#F5F5F5'
      : null,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#F5F5F5'
    },
    ...customStyles?.optionStyles
  }),
  placeholder: provided => ({
    ...provided,
    ...customStyles?.placeholder
  })
});

export const Wrapper = styled.div`
  position: relative;
`;

export const SelectGroupStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    font-size: 11px;
    margin-right: 2px;
  }
`;

export const SelectBadgeStyled = styled.span`
  display: inline-block;
  min-width: 1;
  padding: 0.16666666666667em 0.5em;
  border-radius: 2em;
  font-size: 11px;
  font-weight: normal;
  line-height: 1;
  text-align: center;
  color: #172b4d;
  background-color: #ebecf0;
`;

export const Label = styled.label`
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 16px;
  color: #212121;
`;
