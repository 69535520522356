import styled from 'styled-components/macro';
import { ReactComponent as SpinnerElement } from 'src/assets/icons/spinner.svg';

export const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 24px 16px 16px;
  background-color: #ffffff;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    height: 50%;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.second};
  }
`;

export const Modal = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 530px;
  max-height: 600px;
  min-height: 430px;
  width: 100%;
  margin-bottom: 80px;
  box-shadow: 0px 14px 50px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 24px 62px;
  background-color: #ffffff;
  z-index: 1;
`;

export const Logo = styled.div`
  position: relative;
  margin-bottom: 34px;
  z-index: 1;
`;

export const Title = styled.h1`
  width: 100%;
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 20px;
  line-height: 56px;
  text-align: center;
  color: #000000;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Spinner = styled(SpinnerElement)`
  position: relative;
  bottom: 40px;
  margin: auto;
`;
