import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { chatgenieAPI } from 'src/config/api';
import SimpleSelect from 'src/components/SimpleSelect';
import { SelectAsyncPaginate } from '@Thread-Magic/jasmine';
import { Wrapper, Section, SectionTitle } from './ClientFilter.style';
import RadioGroup from '../RadioGroup';

const appOptions = [
  { label: 'Messenger', value: 'messenger' },
  { label: 'Slack', value: 'slack' },
  { label: 'Ms Teams', value: 'ms_teams' },
  { label: 'Windows', value: 'windows' },
  { label: 'Mac', value: 'mac' }
];

const getCompanyTypes = (query, page) =>
  chatgenieAPI.getCompanyTypes({ query, page, normalizer: res => res.data });

const getAgreementTypes = (query, page) =>
  chatgenieAPI.getAgreementTypes({ query, page, normalizer: res => res.data });

const ClientFilter = ({
  view,
  filters,
  onChange,
  showCompanyType,
  showAgreementType,
  showApp,
  showDeployStatus,
  multiSelectValues,
  setMultiSelectValues
}) => {
  const viewRef = useRef(null);

  const handleChange = useCallback(
    (key, value) => {
      onChange({ ...filters, [key]: value });
    },
    [filters, onChange]
  );

  const handleMultiSelectChange = (key, types) => {
    const typeIds = types.map(t => t.id);
    setMultiSelectValues(prev => ({
      ...prev,
      [key]: types
    }));
    onChange({ ...filters, [key]: typeIds.length ? typeIds : null });
  };

  const formatFilter = async (filter, getResourceFromApi) => {
    if (Object.is(filter) && 'id' in filter && 'name' in filter) {
      return filter;
    }

    const data = await getResourceFromApi({ id: filter });
    return data;
  };

  const formatFilterFromView = useCallback(
    async key => {
      if (!filters[key]) {
        return;
      }

      let getResourceFromApi = chatgenieAPI.getCompanyType;

      if (key === 'agreement_type') {
        getResourceFromApi = chatgenieAPI.getAgreementType;
      }

      const formatted = await Promise.all(
        filters[key].map(async filter => {
          return formatFilter(filter, getResourceFromApi);
        })
      );

      setMultiSelectValues(prev => ({
        ...prev,
        [key]: formatted
      }));
    },
    [filters]
  );

  useEffect(() => {
    if (!view) {
      return;
    }

    if (view.id !== viewRef.current) {
      setMultiSelectValues({
        company_type: null,
        agreement_type: null
      });

      formatFilterFromView('company_type');
      formatFilterFromView('agreement_type');

      viewRef.current = view.id;
    }
  }, [filters, viewRef, view]);

  return (
    <Wrapper>
      {showCompanyType && (
        <Section>
          <SectionTitle>Company type</SectionTitle>
          <SelectAsyncPaginate
            name="company_type"
            title="company_type"
            value={multiSelectValues.company_type}
            options={getCompanyTypes}
            getOptionValue={option => option.name}
            getOptionLabel={option => option.name}
            placeholder="Select"
            onChange={option => handleMultiSelectChange('company_type', option)}
            components={{ ClearIndicator: null }}
            isMulti
          />
        </Section>
      )}
      {showAgreementType && (
        <Section>
          <SectionTitle>Agreement type</SectionTitle>
          <SelectAsyncPaginate
            name="agreement_type"
            title="agreement_type"
            value={multiSelectValues.agreement_type}
            options={getAgreementTypes}
            getOptionValue={option => option.id}
            getOptionLabel={option => option.name}
            placeholder="Select"
            onChange={option => handleMultiSelectChange('agreement_type', option)}
            components={{ ClearIndicator: null }}
            isMulti
          />
        </Section>
      )}
      {showApp && (
        <Section>
          <SectionTitle>App</SectionTitle>
          <SimpleSelect
            name="app"
            title="app"
            value={appOptions.find(option => option.value === filters.app)}
            options={appOptions}
            getOptionValue={option => option.value}
            getOptionLabel={option => option.label}
            onChange={option => handleChange('app', option.value)}
          />
        </Section>
      )}
      {showDeployStatus && (
        <Section>
          <SectionTitle>Deploy status</SectionTitle>
          <RadioGroup
            value={filters.state}
            options={[
              { label: 'Any', value: 'any' },
              { label: 'Only deployed apps', value: 'deployed' },
              { label: 'Only undeployed', value: 'not_deployed' }
            ]}
            onChange={value => handleChange('state', value)}
          />
        </Section>
      )}
    </Wrapper>
  );
};

ClientFilter.defaultProps = {
  view: PropTypes.shape({
    id: PropTypes.number,
    type: PropTypes.string
  }),
  filters: {
    state: 'any',
    app: null,
    companyType: ''
  },
  showCompanyType: true,
  showApp: true,
  showAgreementType: true,
  showDeployStatus: true
};

ClientFilter.propTypes = {
  view: PropTypes.any,
  filters: PropTypes.shape({
    state: PropTypes.string,
    app: PropTypes.string,
    company_type: PropTypes.arrayOf(PropTypes.number),
    agreement_type: PropTypes.arrayOf(PropTypes.number)
  }),
  onChange: PropTypes.func.isRequired,
  multiSelectValues: PropTypes.shape({
    company_type: PropTypes.arrayOf(PropTypes.shape({})),
    agreement_type: PropTypes.arrayOf(PropTypes.shape({}))
  }).isRequired,
  setMultiSelectValues: PropTypes.func.isRequired,
  showCompanyType: PropTypes.bool,
  showApp: PropTypes.bool,
  showAgreementType: PropTypes.bool,
  showDeployStatus: PropTypes.bool
};

export default ClientFilter;
