import axios from 'axios';
import { URL_API } from '../../../constants';
import { channelsTypes } from '../../../constants/action-types';
import { getHeaders } from '../../../utils';

export const getChannels = (name, platform, type, categoryId) => (dispatch, getState) =>
  dispatch({
    type: channelsTypes.GET_CHANNELS,
    payload: axios({
      method: 'GET',
      url: `${URL_API}/api/channel/channels?filter-friendly_name=~${name || ''}&filter-platform=${
        platform || ''
      }&filter-type=i=${type}&per_page=50&filter-category_id=${categoryId || ''}`,
      headers: getHeaders(getState)
    })
  }).then(res => res?.value?.data?.data);

export const saveChannel = data => (dispatch, getState) =>
  dispatch({
    type: channelsTypes.SAVE_CHANNEL,
    payload: axios({
      method: 'POST',
      url: `${URL_API}/api/channel/channels`,
      headers: getHeaders(getState),
      data
    })
  }).then(res => res?.value?.data?.data);

export const getCategories = like => (dispatch, getState) =>
  dispatch({
    type: channelsTypes.GET_CATEGORIES,
    payload: axios({
      method: 'GET',
      url: `${URL_API}/api/channel/channel-categories?filter-category=~${like || ''}&limit=50`,
      headers: getHeaders(getState)
    })
  }).then(res => res?.value?.data?.data);
