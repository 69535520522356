import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { getSlackUsers, executeSlackAction } from 'src/redux/modules/users/usersActions';
import { connect } from 'react-redux';
import {
  getRandomAvatarColor,
  RadioButton,
  Avatar,
  SelectAsyncPaginate
} from '@Thread-Magic/jasmine';
import { ReactComponent as TickIcon } from 'src/assets/icons/check-soft.svg';
import Alert from 'src/components/Alert';
import * as Styled from './style';

const action = {
  all: 'send_welcome_message_to_all_users',
  selected: 'send_welcome_message_to_users'
};
export const AvatarOption = props => {
  const { selectProps, children, isFocused, data, selectOption } = props;
  const isSelected = selectProps.data?.find(el => el.id === data.id) || data.is_welcome;
  return (
    <Styled.ListItem
      isSelected={isSelected}
      isFocused={isFocused}
      onClick={() => selectOption(data)}
    >
      <Avatar size={28} name={children} color={getRandomAvatarColor(children)} isManual />
      <div className="label">
        <span>{children}</span>
        {isSelected ? <TickIcon width="19px" /> : ''}
      </div>
    </Styled.ListItem>
  );
};
AvatarOption.propTypes = {
  selectProps: PropTypes.shape({
    data: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number
      })
    )
  }).isRequired,
  children: PropTypes.any.isRequired,
  selectOption: PropTypes.func.isRequired,
  data: PropTypes.any.isRequired,
  isFocused: PropTypes.bool.isRequired
};
function SlackStatusActions({ getSlackUsers, executeSlackAction, companyId }) {
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [accessGroup, setAccessGroup] = useState(action.all);
  const [state, setState] = useState({
    loading: false,
    error: '',
    settledTimes: 0
  });
  const handleAccessGroupChange = e => {
    setAccessGroup(e.target.value);
    setSelectedUsers([]);
    setState(old => ({
      ...old,
      loading: false,
      error: ''
    }));
  };
  const handleUserSelect = user => {
    const isAlreadySelected = selectedUsers.find(el => el.id === user.id);
    if (user.is_welcome || isAlreadySelected) {
      return;
    }
    setSelectedUsers(old => [...old, user]);
  };
  const handleSubmit = () => {
    const payload = {
      action: accessGroup
    };
    if (accessGroup === action.selected) {
      if (!selectedUsers.length) {
        return;
      }
      payload.slack_users = selectedUsers.map(el => ({ id: el.id }));
    }
    setState(old => ({
      ...old,
      loading: true,
      error: ''
    }));

    executeSlackAction(companyId, payload)
      .then(() => {
        setState(old => ({
          loading: false,
          error: '',
          settledTimes: old.settledTimes + 1
        }));
        setSelectedUsers([]);
      })
      .catch(err => {
        setSelectedUsers([]);
        setState(old => ({
          loading: false,
          error: err?.response?.data?.message,
          settledTimes: old.settledTimes + 1
        }));
      });
  };
  const getSlackUsersOptions = (query, page) =>
    getSlackUsers({ query, page, companyId }).then(res => Promise.resolve(res?.data || []));

  const getPlaceholderText = () => {
    const { length } = selectedUsers;
    return length ? `Selected ${length} user${length > 1 ? 's' : ''}` : 'Search users';
  };
  return (
    <Styled.Col>
      <Styled.Subtitle>Send welcome message to:</Styled.Subtitle>
      <Styled.Row>
        <RadioButton
          name="access-group"
          onChange={handleAccessGroupChange}
          value={action.all}
          checked={accessGroup === action.all}
          label={
            <Styled.RadioLabel>
              <b>All Users&nbsp;</b> - Recommended for Production Rollout
            </Styled.RadioLabel>
          }
        />
      </Styled.Row>
      <Styled.Row marginBottom={24}>
        <RadioButton
          name="access-group"
          value={action.selected}
          checked={accessGroup === action.selected}
          onChange={handleAccessGroupChange}
          label={
            <Styled.RadioLabel>
              <b>Selected Users&nbsp;</b> - Recommended for Pilot Rollouts
            </Styled.RadioLabel>
          }
        />
      </Styled.Row>
      {state.error && <Alert type="warning">{state.error}</Alert>}
      {accessGroup === action.selected && (
        <SelectAsyncPaginate
          placeholder={getPlaceholderText()}
          id="slack-users"
          isMulti
          key={state.settledTimes}
          onChange={([value]) => {
            handleUserSelect(value);
          }}
          closeMenuOnSelect={false}
          blurInputOnSelect={false}
          components={{
            Option: AvatarOption
          }}
          customStyles={{
            menu: () => ({
              position: 'relative'
            })
          }}
          value={null}
          data={selectedUsers}
          options={getSlackUsersOptions}
        />
      )}
      <Styled.Row marginTop={16}>
        <Styled.Button isLoader={state.loading} onClick={handleSubmit} size="large">
          Save
        </Styled.Button>
      </Styled.Row>
    </Styled.Col>
  );
}
SlackStatusActions.propTypes = {
  getSlackUsers: PropTypes.func.isRequired,
  executeSlackAction: PropTypes.func.isRequired,
  companyId: PropTypes.number.isRequired
};

SlackStatusActions.defaultProps = {};
const mapDispatchToProps = dispatch => ({
  getSlackUsers: params => dispatch(getSlackUsers(params)),
  executeSlackAction: (companyId, data) => dispatch(executeSlackAction(companyId, data))
});
export default connect(null, mapDispatchToProps)(SlackStatusActions);
