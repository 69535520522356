import React from 'react';
import PropTypes from 'prop-types';
import { useMutation, useQueryClient } from 'react-query';
import { Button } from '@Thread-Magic/jasmine';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { chatsTypes } from 'src/constants/action-types';
import queryKeys from 'src/constants/queryKeys';
import { chatgenieAPI } from 'src/config/api';
import useToast from 'src/hooks/useToast';
import { ReactComponent as DownloadIcon } from 'src/assets/icons/download.svg';
import { ReactComponent as ClipboardIcon } from 'src/assets/icons/clipboard.svg';
import { ReactComponent as Spinner } from 'src/assets/icons/spinner.svg';
import * as Styled from './style';

const MacDownload = ({ history, companyId }) => {
  const queryClient = useQueryClient();
  const { toast } = useToast();

  const downloadMac = () => {
    window.open(
      'https://docs.getthread.com/article/5h71x2m1jq-deploying-messenger-to-mac',
      '_blank'
    );
  };

  const mutationUpdateConfiguration = useMutation(chatgenieAPI.updateChatConfiguration, {
    onSuccess: async () => {
      await queryClient.refetchQueries([queryKeys.GET_COMPANY, companyId]);
      history.push({
        pathname: `/dashboard/clients/companies/${companyId}/connect`
      });
    },
    onError: error => {
      if (error.response.status === 400) {
        toast.warn(error.response.data?.message);
      } else {
        toast.error(error.message);
      }
    }
  });

  const snippet = `#!/bin/bash

# Exit the script on any command failure
set -e
set -o pipefail

# Log file
LOG_PATH="/tmp/messenger_install.log"

# macOS username
if [ -n "$SUDO_USER" ]; then
    USERNAME="$SUDO_USER"
else
    USERNAME=$(whoami)
fi

MESSENGER_CONFIG="/Users/$USERNAME/Library/Application Support/Messenger"

# Function to log messages
log() {
    echo "$(date '+%Y-%m-%d %H:%M:%S') - $1" | tee -a "$LOG_PATH"
}

log "username: $USERNAME"

# Variables
DOWNLOAD_URL="https://assets.getthread.com/messenger/downloads/desktop/messenger.dmg"
DMG_PATH="/tmp/messenger.dmg"
MOUNT_PATH="/tmp/messenger_mount"
APP_ID="ENTER YOUR APP ID HERE"

log "Starting installation of Messenger app..."

# Uninstall existing Messenger apps
rm -rf /Applications/Messenger.app && rm -rf /Applications/Chatgenie\ Messenger.app

# Download the DMG file
log "Downloading DMG from $DOWNLOAD_URL..."
curl -o "$DMG_PATH" "$DOWNLOAD_URL"
log "DMG downloaded to $DMG_PATH."

# Function to unmount the DMG
function unmount_dmg {
    log "Unmounting DMG from $MOUNT_PATH..."
    hdiutil detach "$MOUNT_PATH" || true
    log "DMG unmounted."
}

# Mount the DMG
log "Mounting DMG..."
hdiutil attach "$DMG_PATH" -mountpoint "$MOUNT_PATH"
log "DMG mounted at $MOUNT_PATH."

# Trap any error and ensure that the DMG gets unmounted even if there's an error
trap unmount_dmg EXIT

# Install the app
log "Installing Messenger app to /Applications..."
cp -R "$MOUNT_PATH"/*.app /Applications/
log "Installation complete."

# Unmount the DMG
unmount_dmg

# Remove the downloaded DMG to clean up
log "Removing downloaded DMG..."
rm "$DMG_PATH"
log "DMG removed."

# Close any running instances of the app
log "Closing any running instances of the Messenger app..."
pkill -f "Messenger.app/Contents/MacOS/Messenger" || true
log "Instances closed."

# Configure app id
if [ -d "$MESSENGER_CONFIG" ]; then
    log "Messenger config folder exists for user $USERNAME."
    echo '{ "APP_ID": "'"$APP_ID"'" }' > "$MESSENGER_CONFIG/config.json"
    log "Configured Messenger app ID in $MESSENGER_CONFIG/config.json."
else
    log "Messenger config folder not found for user $USERNAME."
fi

# Run the nohup command to configure the app
log "Configuring the Messenger app..."
nohup /Applications/Messenger.app/Contents/MacOS/Messenger APP_ID="$APP_ID" FLOW=customer &
log "Configuration initiated."

# Allow a brief moment for configuration to complete
sleep 2

# Launch the app
log "Launching the Messenger app..."
open -a Messenger
log "Messenger app launched."

# Script finished
log "Messenger app installation and configuration complete!"
  `;

  const skipMacDeployment = () => {
    mutationUpdateConfiguration.mutate({ companyId, selectedConfiguration: chatsTypes.SKIPPED });
  };

  return (
    <Styled.Wrapper>
      <>
        <Styled.Title>Deploy Mac app</Styled.Title>
        <Styled.ButtonLink onClick={downloadMac}>
          Learn more about Mac app deployment
        </Styled.ButtonLink>
        <Styled.Content>
          <Styled.SubTitle>Download the Mac app</Styled.SubTitle>
          <p>
            Messenger is available as a dmg for Mac for easy deployment via shell script or for mass
            deployment via your favorite RMM, MDM, or other deployment solution.
          </p>
          <Button size="large" styleMode="outline" onClick={downloadMac}>
            <DownloadIcon width={20} height={20} /> Download the Mac app
          </Button>

          <Styled.SubTitle>Recommended Shell Script</Styled.SubTitle>
          <Styled.Code>
            <CopyToClipboard text={snippet} message="Copied">
              <Styled.ClipboardContainer>
                <button
                  type="button"
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content="Hello world!"
                >
                  <ClipboardIcon />
                </button>
              </Styled.ClipboardContainer>
            </CopyToClipboard>
            {snippet}
          </Styled.Code>
        </Styled.Content>

        <Styled.Footer>
          <Styled.ButtonLink size="large" onClick={skipMacDeployment}>
            {mutationUpdateConfiguration.isLoading ? (
              <Spinner width={30} height={30} />
            ) : (
              <strong>Select a different app</strong>
            )}
          </Styled.ButtonLink>
        </Styled.Footer>
      </>
    </Styled.Wrapper>
  );
};

MacDownload.propTypes = {
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  companyId: PropTypes.number.isRequired
};

export default MacDownload;
