import { useEffect } from 'react';
import 'rc-notification/assets/index.css';

export const useDialog = ({ isVisible, clearDialog }) => {
  useEffect(() => {
    if (isVisible) {
      setTimeout(() => {
        clearDialog();
      }, 700);
    }
  }, [isVisible]);
};
