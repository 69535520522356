export const ADMIN_ROLE = 'ADMIN_USER';
export const BASIC_ROLE = 'BASIC_USER';

export const userRoles = [
  {
    value: ADMIN_ROLE,
    label: 'Admin'
  },
  {
    value: BASIC_ROLE,
    label: 'Member'
  }
];

export const userRoleMap = {
  [ADMIN_ROLE]: 'Admin',
  [BASIC_ROLE]: 'Member'
};
