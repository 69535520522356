import React from 'react';
import PropTypes from 'prop-types';
import { getInitials } from 'src/utils';
import NavDropdown from '../NavDropdown';

import * as Styled from './style';

const UserInfo = ({ data, items }) => {
  function handleOnSelect(option) {
    option.handler();
  }

  return (
    <Styled.Wrapper title={data.email}>
      <NavDropdown
        options={items}
        position="top"
        onSelect={handleOnSelect}
        AlwaysSelectedOption={
          <Styled.UserWrapper>
            <Styled.Avatar>{getInitials(data.fullname)}</Styled.Avatar>
            <Styled.UserLabel>{data.company.name}</Styled.UserLabel>
          </Styled.UserWrapper>
        }
      />
    </Styled.Wrapper>
  );
};

UserInfo.propTypes = {
  data: PropTypes.objectOf(PropTypes.any),
  items: PropTypes.arrayOf(PropTypes.any)
};

UserInfo.defaultProps = {
  data: {},
  items: []
};

export default UserInfo;
