import styled from 'styled-components';

export const WrapperStyled = styled.div`
  display: flex;
  align-items: center;
  font-size: 22px;
  line-height: 30px;
  letter-spacing: -0.59px;
  color: #333333;
`;
