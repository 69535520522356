import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import 'pure-react-carousel/dist/react-carousel.es.css';
import FigureSwitcher from 'src/components/FigureSwitcher';
import { ReactComponent as SlackLogo } from 'src/assets/images/slack-logo-2.svg';
import { ReactComponent as TeamsLogo } from 'src/assets/images/msteams-logo-2.svg';
import OnboardingScreen from 'src/containers/OnboardingScreen';
import {
  getConfigurationId,
  getTeams,
  updateChatConfiguration
} from 'src/redux/modules/chats/chatsActions';
import SignupSkipButton from 'src/components/SignupSkipButton';
import { useSignUpBotInstallPage } from './hook';
import * as Styled from './styles';

export const SignupBotInstall = ({
  configurationId,
  getConfigurationId,
  chatConfiguration,
  updateChatConfiguration,
  getTeams,
  teams,
  history,
  token
}) => {
  useSignUpBotInstallPage({
    configurationId,
    getConfigurationId,
    getTeams,
    teams,
    history,
    token
  });

  const handleClickSeeFullInstructions = () => {
    window.open(
      'https://docs.getthread.com/article/ynz0rlxyls-installing-the-bot-in-microsoft-teams'
    );
  };

  return (
    <OnboardingScreen
      title="Teams App Installation"
      size="large"
      step={4}
      aside={
        <FigureSwitcher
          logos={{
            slack: <SlackLogo />,
            msteams: <TeamsLogo />
          }}
        />
      }
    >
      <Styled.Paragraph>Please install the bot in Teams and add it to a team.</Styled.Paragraph>

      <Styled.Wrapper>
        <Styled.Spinner width="40" height="40" />
        <Styled.Description>
          We will move to the next step as soon as you install the bot in Teams
        </Styled.Description>

        <Styled.Button type="button" onClick={handleClickSeeFullInstructions}>
          See full instructions
        </Styled.Button>

        <SignupSkipButton
          chatConfiguration={chatConfiguration}
          updateChatConfiguration={updateChatConfiguration}
        />
      </Styled.Wrapper>
    </OnboardingScreen>
  );
};

SignupBotInstall.propTypes = {
  configurationId: PropTypes.number,
  getConfigurationId: PropTypes.func.isRequired,
  chatConfiguration: PropTypes.string,
  updateChatConfiguration: PropTypes.func.isRequired,
  getTeams: PropTypes.func.isRequired,
  teams: PropTypes.arrayOf(PropTypes.any),
  history: PropTypes.objectOf(PropTypes.any),
  token: PropTypes.string
};

SignupBotInstall.defaultProps = {
  configurationId: null,
  teams: [],
  history: {},
  token: '',
  chatConfiguration: null
};

const mapStateToProps = state => ({
  token: state.usersReducer.user.token,
  configurationId: state.chatsReducer.configurationId,
  teams: state.chatsReducer.teams
});

const mapDispatchToProps = dispatch => ({
  getTeams: () => dispatch(getTeams()),
  getConfigurationId: () => dispatch(getConfigurationId()),
  updateChatConfiguration: (companyId, chatConfig) =>
    dispatch(updateChatConfiguration(companyId, chatConfig))
});

export default connect(mapStateToProps, mapDispatchToProps)(SignupBotInstall);
