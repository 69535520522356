import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { isMicrosoft365Integration } from 'src/containers/ChatIntegrationsEditor/MicrosoftIntegrations/microsoftConnectionHelpers';
import { getLocalStorage, setLocalStorage } from '../../utils';
import { chatsTypes, flowsTypes } from '../../constants/action-types';

export const useSignUpChatPage = ({ signInSlack, integrateMs, history, isReintegration }) => {
  const dispatch = useDispatch();
  const code = getLocalStorage('chatIntegrationCode');
  const state = getLocalStorage('chatIntegrationState');

  ['chatIntegrationCode', 'chatIntegrationState'].forEach(item => localStorage.removeItem(item));
  const path = isMicrosoft365Integration() ? 'ms-team/windows' : 'ms-team';
  const chatConfiguration = isMicrosoft365Integration() ? chatsTypes.WINDOWS : chatsTypes.MS_TEAMS;

  const chatTypes = {
    slack: () =>
      signInSlack(code).then(() => {
        setLocalStorage({ chatConfiguration: chatsTypes.SLACK });
        // Invert condition NEED FIX after relise
        setLocalStorage({ slackHasNewConfiguration: false });
        setLocalStorage({ botInstalled: true });

        if (isReintegration) {
          history.replace('/dashboard/flows');
        } else {
          dispatch({ type: flowsTypes.FLOW_ITEMS_READY, payload: false });
          history.replace('/signup/synchronization');
        }
      }),
    msTeams: () =>
      integrateMs(code, state, path, chatConfiguration).then(() => {
        if (isMicrosoft365Integration()) {
          setLocalStorage({ chatConfiguration: chatsTypes.WINDOWS });
        } else {
          setLocalStorage({ chatConfiguration: chatsTypes.MS_TEAMS });
        }

        // Invert condition NEED FIX after relise
        setLocalStorage({ msTeamsHasNewConfiguration: false });

        if (isMicrosoft365Integration()) {
          history.replace('/dashboard/integrations/chat/msteams/integrations');
          return;
        }

        if (isReintegration) {
          history.replace('/dashboard/flows');
        } else {
          setLocalStorage({ onboardingStep: 'bot_install' });
          dispatch({ type: flowsTypes.FLOW_ITEMS_READY, payload: false });
          history.replace('/signup/bot-install');
        }
      })
  };

  useEffect(() => {
    if (code && state) {
      chatTypes[state.chatType]();
    }
  }, []);
};
