import { channelsTypes, actionStatuses } from '../../../constants/action-types';

const defaultState = {
  channelList: [],
  newChannelData: null,
  savingLoader: false,
  savingError: ''
};

const map = {
  [`${channelsTypes.GET_CHANNELS}${actionStatuses.FULFILLED}`]: (state, action) => ({
    ...state,
    channelList: action.payload.data.data
  }),
  [`${channelsTypes.GET_CHANNELS}${actionStatuses.PENDING}`]: state => ({
    ...state
  }),
  [`${channelsTypes.GET_CHANNELS}${actionStatuses.REJECTED}`]: state => ({
    ...state
  }),
  [`${channelsTypes.SAVE_CHANNEL}${actionStatuses.FULFILLED}`]: (state, action) => ({
    ...state,
    newChannelData: action.payload.data.data,
    savingError: '',
    savingLoader: false
  }),
  [`${channelsTypes.SAVE_CHANNEL}${actionStatuses.PENDING}`]: state => ({
    ...state,
    savingError: '',
    savingLoader: true
  }),
  [`${channelsTypes.SAVE_CHANNEL}${actionStatuses.REJECTED}`]: (state, action) => {
    return {
      ...state,
      savingError: action.payload.response.data.message,
      savingLoader: false
    };
  }
};

export default function channelsReducer(state = defaultState, action) {
  return (map[action.type] && map[action.type](state, action)) || state;
}
