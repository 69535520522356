import React, { useEffect } from 'react';
import threadThrophyGif from 'src/assets/images/thread-throphy.gif';
import useAuthUser from 'src/hooks/useAuthUser';
import { Button } from '@Thread-Magic/jasmine';
import * as Styled from './styles';

const SignupSuccess = () => {
  const authUser = useAuthUser();
  const redirectToInbox = () => {
    localStorage.removeItem('onboardingStep');
    window.location = `${process.env.REACT_APP_INBOX_URL}/app-launch?app_id=${authUser.appId}`;
  };

  useEffect(() => {
    const timeout = setTimeout(redirectToInbox, 5000);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <Styled.Wrapper>
      <Styled.GlobalStyle />
      <img src={threadThrophyGif} alt="Success" />
      <Styled.SubTitle>Success!</Styled.SubTitle>
      <Styled.Description>
        Your account has been set up, we’ll now take you to your Inbox.
      </Styled.Description>
      <Styled.Actions>
        <Button size="large" type="button" onClick={redirectToInbox}>
          Go to Inbox
        </Button>
      </Styled.Actions>
    </Styled.Wrapper>
  );
};

export default SignupSuccess;
