import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ReactComponent as AppDeploymentAuthImage } from 'src/assets/images/app-deployment-auth.svg';
import { getLocalStorage } from 'src/utils';
import { usePartnerOnboard } from '../hooks/usePartnerOnboard';
import Card from '../components/Card';

const PartnerCenterAuth = () => {
  const { isOnBoarded, isLoading, onBoardPartner } = usePartnerOnboard();
  const history = useHistory();

  const handleStartAuthFlow = useCallback(async () => {
    const clientId = process.env.REACT_APP_PARTNER_APP_CLIENT_ID;
    const redirectUrl = encodeURIComponent(
      `${process.env.REACT_APP_URL}/dashboard/deployment-assist/auth`
    );
    window.location.replace(
      `https://login.microsoftonline.com/common/oauth2/authorize?&client_id=${clientId}&response_type=code&redirect_url=${redirectUrl}`
    );
  }, []);
  const authCode = getLocalStorage('msAuthCode');

  useEffect(() => {
    if (authCode) {
      window.localStorage.removeItem('msAuthCode');
      onBoardPartner(authCode);
    }
  }, [authCode]);

  useEffect(() => {
    if (isOnBoarded) {
      history.replace('/dashboard/deployment-assist/sync');
    }
  }, [isOnBoarded]);

  return (
    <Card
      image={<AppDeploymentAuthImage />}
      title="Please grant permissions to continue"
      description={
        <>
          We&apos;ll need your consent in a new window.
          <br />
          Please accept to continue.
        </>
      }
      action={{
        action: handleStartAuthFlow,
        label: 'Open Permission modal',
        variant: 'secondary'
      }}
      isLoading={isLoading}
    />
  );
};

PartnerCenterAuth.propTypes = {};

export default PartnerCenterAuth;
