import axios from 'axios';
import { actionPlatformConfigurationsTypes as types } from '../../../../constants/action-types';
import { URL_API } from '../../../../constants';
import { getHeaders } from '../../../../utils';
import { transformToSnakeCase } from '../../../../utils/data-transformer';

export const clearState = () => ({
  type: types.CLEAR_STATE
});

export const fetch = configurationId => (dispatch, getState) => {
  if (!configurationId) {
    return Promise.reject(new Error('Configuration id not specified'));
  }
  return dispatch({
    type: types.GET_SINGLE,
    payload: axios({
      method: 'GET',
      url: `${URL_API}/api/action-platform/configurations/${configurationId}`,
      headers: getHeaders(getState)
    })
  });
};

export const create = (platformId, data, isActive) => (dispatch, getState) => {
  if (!platformId) {
    return Promise.reject(new Error('Platform id not specified'));
  }
  return dispatch({
    type: types.CREATE,
    payload: axios({
      method: 'POST',
      url: `${URL_API}/api/action-platform/configurations`,
      headers: getHeaders(getState),
      data: {
        platform_id: platformId,
        configuration: JSON.stringify(transformToSnakeCase(data)),
        is_active: isActive
      }
    })
  });
};

export const update = (configurationId, data, isActive) => (dispatch, getState) => {
  if (!configurationId) {
    return Promise.reject(new Error('Configuration id not specified'));
  }
  return dispatch({
    type: types.UPDATE,
    payload: axios({
      method: 'PUT',
      url: `${URL_API}/api/action-platform/configurations/${configurationId}`,
      headers: getHeaders(getState),
      data: {
        id: configurationId,
        configuration: JSON.stringify(transformToSnakeCase(data)),
        is_active: isActive
      }
    })
  });
};
