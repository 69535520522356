import styled, { css } from 'styled-components';
import SimpleButton from 'src/components/SimpleButton';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 36.75rem;
  margin-top: 7.5rem;
  margin-left: auto;
  margin-right: auto;
  padding: 2rem 2rem 3.5rem;
  background-color: #fff;
  text-align: center;
  border-radius: 0.5rem;
`;

export const Image = styled.div`
  margin-bottom: 2.5rem;
`;

export const Title = styled.h2`
  font-size: 1.25rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.text.default};
  margin: 0 0 1.25rem;
`;

export const Description = styled.p`
  margin: 0 0 2.5rem;
  font-size: 14px;
  max-width: 30em;
  color: ${({ theme }) => theme.colors.text.default};
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
`;

export const outlinedButtonStyle = css`
  background-color: transparent !important;
  border: 1px solid ${({ theme }) => theme.colors.brand.light.base};
  color: ${({ theme }) => theme.colors.brand.light.base};
  transition: background-color 0.25s ease-in-out, color 0.1s ease-out;
  &:hover,
  &:active,
  &:focus {
    background-color: ${({ theme }) => theme.colors.brand.light.base} !important;
    color: #fff;
  }
`;

export const Button = styled(SimpleButton)`
  ${({ variant }) => variant === 'secondary' && outlinedButtonStyle}
`;
