import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Spinner } from '../../assets/icons';

import { ButtonStyled, LinkStyled } from './style';

const SimpleButton = forwardRef((props, ref) => {
  const {
    children,
    isDisabled,
    isLoader,
    styleMode,
    size,
    full,
    className,
    href,
    isLink,
    target,
    ...rest
  } = props;

  return !isLink ? (
    <ButtonStyled
      ref={ref}
      {...props}
      disabled={isDisabled || isLoader}
      styleMode={styleMode}
      size={size}
      full={full}
      className={className}
      {...rest}
    >
      {isLoader ? Spinner : children}
    </ButtonStyled>
  ) : (
    <LinkStyled
      ref={ref}
      href={href}
      styleMode={styleMode}
      className={className}
      target={target}
      {...rest}
    >
      {isLoader ? Spinner : children}
    </LinkStyled>
  );
});

SimpleButton.propTypes = {
  children: PropTypes.node,
  isDisabled: PropTypes.bool,
  isLink: PropTypes.bool,
  isLoader: PropTypes.bool,
  className: PropTypes.string,
  styleMode: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  full: PropTypes.bool,
  href: PropTypes.string,
  target: PropTypes.string
};

SimpleButton.defaultProps = {
  children: '',
  isDisabled: false,
  isLink: false,
  isLoader: false,
  className: 'simple-button',
  styleMode: '',
  size: 'medium',
  full: false,
  href: '',
  target: ''
};

export default SimpleButton;
