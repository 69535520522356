import styled, { css } from 'styled-components';
import { Select, Button } from '@Thread-Magic/jasmine';

export const Form = styled.form`
  ${({ disabled }) =>
    disabled &&
    `
      &::after {
        content: '';
        position: absolute;
        top: 44px;
        bottom: 80px;
        left: 0;
        width: 100%;
        background-color: #ffffff94;
      }
    `}
`;

export const PageTitle = styled.h1`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
`;

export const PageDescription = styled.p`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #000000;
`;

export const FormSectionLabel = styled.span`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
`;

export const FormGroup = styled.fieldset`
  margin-bottom: 24px;
`;

export const FormInputLabel = styled.legend`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  margin-bottom: 0px;
`;

export const CustomSelect = styled(Select)`
  width: 100%;
  text-align: left;
`;

export const FormRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
`;

export const FormCell = styled.div`
  flex: 1 1 auto;
  padding: 0 5px;
  &:first-child {
    padding-left: 0px;
  }
  &:last-child {
    padding-right: 0px;
  }
  ${props =>
    props.fixed &&
    css`
      max-width: ${props.fixed};
      min-width: ${props.fixed};
    `}
  ${props =>
    props.align &&
    css`
      text-align: ${props.align};
    `}
`;

export const Textarea = styled.textarea`
  width: 100%;
  margin: 10px 0;
  padding: 5px 14px;
  border: 1px solid #dddddd;
  border-radius: 2px;
  font-size: 14px;
  line-height: 24px;
  resize: none;
  transition: border-color 0.3s;
  border-box: box-sizing;
  height: 360px;
  resize: vertical;

  &:hover,
  &:focus {
    outline: none;
    border: 1px solid ${({ theme }) => theme.colors.brand.light.base};
  }

  &[disabled] {
    cursor: not-allowed;

    &:hover,
    &:focus {
      outline: none;
      border: 1px solid #dddddd;
    }
  }
`;

export const CustomButton = styled(Button)``;
