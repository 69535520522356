export const MEMBER_STATE_ACTIVE = 'ACTIVE';
export const MEMBER_STATE_PENDING = 'PENDING';
export const MEMBER_STATE_DELETED = 'DELETED';

export const memberStateOptions = [
  {
    value: MEMBER_STATE_ACTIVE,
    label: 'Active'
  },
  {
    value: MEMBER_STATE_PENDING,
    label: 'Pending'
  },
  {
    value: MEMBER_STATE_DELETED,
    label: 'Deleted'
  }
];
