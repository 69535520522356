export const notificationsList = [
  {
    value: 2,
    label: 'All',
    description:
      'All assigned tickets whether through a flow or not, will send in a one on one message with the bot'
  },
  {
    value: 1,
    label: 'Flow Only',
    description:
      'Only tickets that are sent to Teams or Slack by a flow will be sent the engineer in a one on one message'
  },
  {
    value: 0,
    label: 'None',
    description: 'Disable all assigned to notifications in the one on one with the Thread bot'
  }
];
