import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as RefactrFullLogo } from 'src/assets/icons/refactr-full-logo.svg';
import { useRefactrFormPage } from './hook';
import { messageIntegrations } from '../../../../constants/integrations';
import { refactrFormFields } from '../../../../constants/refactr-integration-form';
import { overlayChidrens } from '../../overlayTypes';

import PageWrapper from '../../../../components/PageWrapper';
import FormInputWithLabel from '../../../../components/FormInputWithLabel';
import MessageForm from '../../../../components/MessageForm';
import SimpleButton from '../../../../components/SimpleButton';
import Switcher from '../../../../components/Switcher';

import { ToolsStyled, ContentStyled } from '../../style';
import styles from '../../styles.module.scss';

const RefactrForm = ({
  onSubmit,
  formData,
  isValid,
  status,
  visibleOverlay,
  configuration,
  errorMessage
}) => {
  const {
    baseUrl,
    setBaseUrl,
    login,
    setLogin,
    password,
    setPassword,
    formId,
    onFormSubmit,
    isActive,
    setIsActive
  } = useRefactrFormPage({ onSubmit, formData, configuration });

  return (
    <PageWrapper
      title="Refactr"
      isBackButton
      isModalContent
      pageLogo={<RefactrFullLogo height="40" />}
      tools={
        <ToolsStyled>
          <Switcher
            id="is-active-flow"
            checked={isActive}
            onChange={() => setIsActive(!isActive)}
            controlText={{ checked: 'on', unchecked: 'off' }}
          />
          <SimpleButton
            onClick={onFormSubmit}
            isLoader={visibleOverlay}
            isDisabled={!baseUrl || !login || !password}
          >
            Save
          </SimpleButton>
        </ToolsStyled>
      }
    >
      <ContentStyled>
        <p className={styles.description}>Let’s setup your Refactr, this is a one time setup.</p>
        {errorMessage && <MessageForm typeView="block">{errorMessage}</MessageForm>}
        {isValid === false && (
          <MessageForm typeView="block">{messageIntegrations.NOT_VALID}</MessageForm>
        )}
        {isValid === true && !errorMessage && configuration.is_active && (
          <MessageForm typeView="block" typeMsg="success">
            {messageIntegrations.VALID}
          </MessageForm>
        )}
        <form className={styles.form} id={formId} onSubmit={onFormSubmit}>
          <FormInputWithLabel
            onChange={event => setBaseUrl(event.target.value)}
            value={baseUrl}
            {...refactrFormFields.BASE_URL}
          />
          <FormInputWithLabel
            onChange={event => setLogin(event.target.value)}
            value={login}
            {...refactrFormFields.LOGIN}
          />
          <FormInputWithLabel
            onChange={event => setPassword(event.target.value)}
            type="password"
            value={password}
            {...refactrFormFields.PASSWORD}
          />
          <SimpleButton
            className={styles.button}
            type="submit"
            formId={formId}
            isDisabled={!baseUrl || !login || !password}
          >
            Connect
          </SimpleButton>
        </form>
      </ContentStyled>
      {visibleOverlay && overlayChidrens[status] && (
        <div className={styles.overlay}>
          {overlayChidrens[status].icon}
          <p>{overlayChidrens[status].text}</p>
        </div>
      )}
    </PageWrapper>
  );
};

RefactrForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  formData: PropTypes.objectOf(PropTypes.any),
  isValid: PropTypes.bool
};

RefactrForm.defaultProps = {
  formData: {},
  isValid: undefined
};

export default RefactrForm;
