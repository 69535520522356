import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ReactComponent as SuccessIcon } from 'src/assets/icons/success.svg';
import { ReactComponent as ErrorIcon } from 'src/assets/icons/error.svg';
import { ReactComponent as UnavailableIcon } from 'src/assets/icons/unavailable.svg';
import { ReactComponent as ConfigureIcon } from 'src/assets/icons/configure.svg';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  .icon {
    display: inline-block;
    margin-right: 10px;
    object-fit: contain;

    svg {
      min-width: 1.5em;
      height: 1.5em;
    }
  }
`;

const Hint = styled.span`
  margin-left: 10px;
`;

const getStatus = ({ valid, active }) => {
  if (valid && active) {
    return 'running';
  }

  if (valid && !active) {
    return 'notRunning';
  }

  if (!valid && active) {
    return 'running';
  }

  return 'configure';
};

const statusMap = {
  running: {
    title: 'Running',
    icon: <SuccessIcon />
  },
  notRunning: {
    title: 'Not Running',
    icon: <ErrorIcon />
  },
  configure: {
    title: 'Configure',
    icon: <ConfigureIcon />
  },
  unavailable: {
    title: 'Unavailable',
    icon: <UnavailableIcon />
  }
};

/**
 * active - means configuration has been created
 * valid - means wasn't configured correctly or has issues
 * hint - provide hint if configuration is not valid
 */
export const IntegrationStatus = ({ valid, active, hint }) => {
  const statusMapped = statusMap[getStatus({ valid, active })];

  if (!statusMapped) {
    return null;
  }

  return (
    <Wrapper>
      <span className="icon">{statusMapped.icon}</span>
      {statusMapped.title}
      {hint && <Hint>{hint}</Hint>}
    </Wrapper>
  );
};

IntegrationStatus.defaultProps = {
  hint: null
};

IntegrationStatus.propTypes = {
  valid: PropTypes.bool.isRequired,
  active: PropTypes.bool.isRequired,
  hint: PropTypes.objectOf(PropTypes.any)
};
