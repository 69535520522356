import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { FiCheckCircle } from 'react-icons/fi';

import { ReactComponent as FlowIcon } from 'src/assets/icons/flow.svg';
import { ReactComponent as CopyIcon } from 'src/assets/icons/template.svg';
import { ReactComponent as MessageIcon } from 'src/assets/icons/message.svg';
import { ReactComponent as MagicIcon } from 'src/assets/icons/magic-icon-solid.svg';
import { ReactComponent as ClientsIcon } from 'src/assets/icons/client.svg';
import { ReactComponent as ShieldIcon } from 'src/assets/icons/shield-icon.svg';
import { ReactComponent as UsersIcon } from 'src/assets/icons/users.svg';
import { ReactComponent as IntegrationIcon } from 'src/assets/icons/integration.svg';
import { ReactComponent as StarIcon } from 'src/assets/icons/star.svg';
import { ReactComponent as HelpIcon } from 'src/assets/icons/help.svg';

import { WrapperStyled } from './style';

const Navigation = ({ featureFlags, isAdmin }) => (
  <WrapperStyled data-qa="navigation">
    {isAdmin && (
      <>
        <NavLink to="/dashboard/flows">
          <FlowIcon />
          Flows
        </NavLink>
        <NavLink to="/dashboard/templates">
          <CopyIcon />
          Templates
        </NavLink>
        <NavLink to="/dashboard/messenger/settings">
          <MessageIcon />
          Messenger
        </NavLink>
        {featureFlags.includes('copilot') && (
          <NavLink to="/dashboard/magic">
            <MagicIcon />
            Magic AI
          </NavLink>
        )}
        <NavLink to="/dashboard/clients">
          <ClientsIcon />
          Clients
        </NavLink>
        <NavLink to="/dashboard/deployment-center">
          <FiCheckCircle />
          Deployment Center
        </NavLink>
        <NavLink to="/dashboard/members">
          <UsersIcon />
          Members
        </NavLink>
        <NavLink to="/dashboard/integrations">
          <IntegrationIcon />
          Integrations
        </NavLink>
        <NavLink to="/dashboard/audit-logs">
          <ShieldIcon />
          Security Center
        </NavLink>
      </>
    )}
    <NavLink to="/dashboard/feedback">
      <StarIcon />
      Feedback
    </NavLink>
    <a href="https://help.getthread.com" target="_blank" rel="noopener noreferrer">
      <HelpIcon />
      Help Center
    </a>
  </WrapperStyled>
);

Navigation.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  featureFlags: PropTypes.array.isRequired
};

export default Navigation;
