import React from 'react';

import { ReactComponent as M365Logo } from 'src/assets/images/m365-source-logo.svg';
import { psaTypeToLogo } from 'src/utils/psa';
import StateSelect from './StateSelect';
import { WrapContent } from './styles';

export const usersColumnsData = ({
  EntityNotificationSelect,
  RoleSelect,
  updateMember,
  userPsaType
}) => [
  {
    key: 'name',
    title: 'Name',
    component: row => (
      <WrapContent>
        <b>{row.name}</b>
      </WrapContent>
    ),
    isSort: false
  },
  {
    key: 'email',
    title: 'Email',
    component: row => <WrapContent lowercase>{row.email}</WrapContent>,
    isSort: false
  },
  {
    key: 'state',
    title: 'State',
    component: row => <StateSelect row={row} action={updateMember} />,
    isSort: false
  },
  {
    key: 'entity_notification',
    title: 'Notification',
    component: row => <EntityNotificationSelect row={row} action={updateMember} />,
    filterType: 'radio',
    isSort: false
  },
  {
    key: 'role',
    title: 'Role',
    component: row => <RoleSelect row={row} action={updateMember} />,
    filterType: 'radio',
    isSort: false
  },
  {
    key: 'conversation_source',
    title: 'Source',
    component: row => (
      <WrapContent>{memberSourceToIcon(row.conversation_source_id, userPsaType)}</WrapContent>
    ),
    isSort: false
  }
];

const memberSourceToIcon = (sourceId, userPsaType) => {
  if (sourceId === 5) {
    return <M365Logo />;
  }

  // If sourceId is null, assume the member came from the PSA
  // Check the user's PSA type to determine which icon to show
  if (!sourceId) {
    return psaTypeToLogo(userPsaType);
  }
  return <div />;
};
