import React from 'react';
import { integrationCommands } from 'src/constants/integrations';
import MagicIcon from 'src/assets/icons/magic-icon.svg';
import { Logo as StyledLogo } from './style';

export const ticketingLogos = {
  BMS: <StyledLogo src="/images/bms-logo.png" alt="bms" width="26" />,
  CONNECTWISE: <StyledLogo src="/images/connectwise-logo.svg" alt="connectwise" width="26" />
};

export const actionIcons = command => {
  if (command === integrationCommands.IT_GLUE) {
    return <StyledLogo src="/images/itglue-logo.png" alt="itglue" width="26" />;
  }

  if (command === integrationCommands.AUTOMATE) {
    return <StyledLogo src="/images/automate-icon.png" alt="automate" width="26" />;
  }

  if (command === integrationCommands.COPILOT) {
    return <StyledLogo src={MagicIcon} alt="copilot" width="22" />;
  }

  return <StyledLogo src="/images/generic-psa.png" alt="PSA" width="26" />;
};
