import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 18px;
  min-width: 280px;

  .select__value-container {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .select__indicator svg {
    fill: #999;
  }
`;

export const RadioOptionButton = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  padding: 12px;
  background: ${({ active }) => (active ? '#EBFFFC' : '#fff')};
  border-radius: 4px;
  border: 1px solid ${({ active }) => (active ? '#00BB99' : '#ddd')};
  width: 50%;
  margin: 3px;

  svg {
    font-size: 24px;
    color: #999;
    margin-bottom: 4px;
    opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  }
`;

export const Section = styled.div`
  & + & {
    margin-top: 10px;
  }
`;

export const SectionTitle = styled.h4`
  font-size: 14px;
  font-weight: 600;
  margin: 0 0 10px;
`;
