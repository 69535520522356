import styled from 'styled-components/macro';
import { NavLink } from 'react-router-dom';

export const Layout = styled.div`
  display: flex;
  width: 100%;
`;

export const Sidebar = styled.aside`
  position: fixed;
  width: 240px;
  height: 100%;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.brand.main.base};
  z-index: 100;
  overflow-y: auto;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-grow: 1;
`;

export const Content = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  flex-grow: 1;
  background-color: #eeeeee;
`;

export const Title = styled(NavLink)`
  color: #ffffff;
  padding: 26px 20px 19px;
  text-decoration: none;

  &:hover {
    color: #ffffff;
    text-decoration: none;
  }
`;
