import React from 'react';
import { ReactComponent as QuoteIcon } from 'src/assets/icons/quote.svg';
import { Button } from '@Thread-Magic/jasmine';
import PropTypes from 'prop-types';
import * as Styled from './style';

function Introduction({ onStartClick, content }) {
  return (
    <Styled.IntroWrapper>
      <Styled.IntroHeader>
        <h5>{content.title}</h5>
        <p>{content.description}</p>
        <Button size="large" onClick={onStartClick}>
          {content.buttonLabel}
        </Button>
      </Styled.IntroHeader>
      <Styled.Benifits hasBenifits={!!content.benifits.items.length}>
        <h5>{content.benifits.title}</h5>
        {!!content.benifits.items.length && (
          <Styled.BenifitsItems>
            {content.benifits.items.map(item => (
              <div key={item.text}>
                {item.icon}
                <p>{item.text}</p>
              </div>
            ))}
          </Styled.BenifitsItems>
        )}
      </Styled.Benifits>
      <Styled.Testimonial>
        <h5>{content.testimonial.title}</h5>
        <Styled.TestimonialItem>
          <QuoteIcon />
          <Styled.TestimonialContent>“{content.testimonial.review}”</Styled.TestimonialContent>
          <Styled.TestimonialAvatar>
            <img src={content.testimonial.photo} alt={content.testimonial.fullname} />
            <Styled.TestimonialAvatarInfo>
              <p>
                <b>{content.testimonial.fullname}</b>, {content.testimonial.position}
              </p>
            </Styled.TestimonialAvatarInfo>
          </Styled.TestimonialAvatar>
        </Styled.TestimonialItem>
      </Styled.Testimonial>
    </Styled.IntroWrapper>
  );
}

Introduction.propTypes = {
  onStartClick: PropTypes.func.isRequired,
  content: PropTypes.shape({
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    description: PropTypes.string,
    buttonLabel: PropTypes.string,
    testimonial: PropTypes.shape({
      title: PropTypes.string,
      fullname: PropTypes.string,
      position: PropTypes.string,
      photo: PropTypes.string,
      review: PropTypes.string
    }),
    benifits: PropTypes.shape({
      title: PropTypes.string,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          icon: PropTypes.node,
          text: PropTypes.string
        })
      )
    })
  }).isRequired
};

export default Introduction;
