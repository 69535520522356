import styled, { css } from 'styled-components/macro';
import MessengerPreview from 'src/components/MessengerPreview';
import ImageAttachmentBlock from 'src/components/ImageAttachmentBlock';

export const Wrapper = styled.div`
  display: flex;
`;

export const IconPopup = styled.div`
  position: absolute;
  top: 40px;
  left: 40px;
  min-width: 340px;
  border: 1px solid ${({ theme }) => theme.colors.border.variant1};
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.background.default};
  z-index: 1000;

  .emoji-mart {
    border: none;
  }
`;

export const PopupHeader = styled.nav`
  padding: 0 4px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.variant1};
`;

export const PickerTab = styled.button`
  position: relative;
  width: 72px;
  margin-right: 6px;
  padding: 12px 0 10px;
  border: none;
  color: ${({ theme }) => theme.colors.brand.light.base};
  text-align: center;
  font-size: 16px;
  line-height: 19px;
  background: none;

  &:hover {
    font-weight: 600;
  }

  ${({ isActive }) =>
    isActive &&
    css`
      font-weight: 600;

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        display: block;
        width: 100%;
        height: 2px;
        background-color: ${({ theme }) => theme.colors.brand.light.base};
      }
    `}
`;

export const FieldError = styled.p`
  height: 13px;
  margin-top: 4px;
  margin-bottom: 0;
  font-size: 11px;
  line-height: 13px;
  color: ${({ theme }) => theme.colors.danger.main};
`;

export const Form = styled.form`
  width: 100%;
  height: max-content;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.background.default};
`;

export const FormGroup = styled.fieldset`
  position: relative;
  display: flex;
`;

export const FormContent = styled.fieldset`
  margin-bottom: 200px;
  padding: 28px 54px 28px 34px;
`;

export const EditBackground = styled.span`
  position: absolute;
  left: 10px;
  top: 16px;
  display: flex;
  align-items: center;
  padding: 16px;
  border: none;
  background-color: transparent;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: ${({ theme }) => theme.colors.action.variant1};
  user-select: none;
  z-index: 100;

  svg {
    margin-right: 9px;
    width: 16px;
    height: 16px;
    stroke: #ffffff;
  }
`;

export const EditWrapper = styled.div`
  display: block;
  left: 0;
  position: absolute;
  top: 0;
  width: 50%;
  height: 100%;

  &::after {
    content: '';
    position: absolute;
    background: ${({ theme }) =>
      `linear-gradient(to right, ${theme.colors.text.default} 0,transparent 50%) !important`};
    width: 100%;
    height: 100%;
    top: 0;
  }
`;

export const IconBlock = styled(ImageAttachmentBlock)`
  width: 78px;
  height: 78px;
  margin: 0;
  margin-right: 15px;
  background: ${({ theme }) => theme.colors.background.secondary};
  font-size: 46px;
`;

export const HeaderImageBlock = styled(ImageAttachmentBlock)`
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  height: 184px;
  margin: 0;
  margin-top: 64px;
  padding: 0;
  background: ${({ theme }) => theme.colors.background.secondary};
  cursor: pointer;

  ${({ background }) =>
    background &&
    css`
      background-image: url(${background});
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    `}

  & > label {
    width: 100%;
    padding: 0;
    border: none;
    background: #faf9f6;
  }
`;

export const HeaderImagePlaceholder = styled.div`
  pointer-events: none;
`;

export const ImagePreview = styled.div`
  width: 100%;
  height: 100%;
  pointer-events: none;

  img,
  canvas {
    object-fit: cover;
  }
`;

export const ImageError = styled(FieldError)`
  position: absolute;
  left: 50%;
  margin-top: 4px;
  transform: translateX(-50%);
`;

export const FieldWrapper = styled.label`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;

  &:not(:last-child) {
    margin-right: 9px;
  }

  input {
    margin-bottom: 0;
  }
`;

export const ImagePlaceholderTitle = styled.p`
  margin: 0 0 8px;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: ${({ theme }) => theme.colors.brand.light.base};
`;

export const ImagePlaceholderHint = styled.p`
  margin: 0;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: ${({ theme }) => theme.colors.text.variant2};
`;

export const Action = styled.button`
  padding: 0;
  border: none;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: ${({ theme }) => theme.colors.brand.light.base};
  background: none;

  & + div {
    width: 100%;
  }
`;

export const LinkWrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 1000px;
  padding: 26px 0 17px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.variant1};
  background-color: ${({ theme }) => theme.colors.background.default};
`;

export const Fields = styled.div`
  flex-grow: 1;
  min-width: 400px;
  margin-right: 12px;

  span {
    display: inline-block;
    margin-bottom: 11px;
    font-size: 14px;
    line-height: 17px;
    font-weight: 700;
    white-space: nowrap;
    color: ${({ theme }) => theme.colors.text.default};
  }
`;

export const FiledControl = styled.input`
  width: 100%;
  height: 40px;
  margin-bottom: 9px;
  margin-right: 9px;
  padding: 12px;
  background: ${({ theme }) => theme.colors.background.default};
  border: 1px solid ${({ theme }) => theme.colors.border.variant1};
  border-radius: 2px;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.text.default};
  transition: border 0.2s;

  &:hover,
  &:focus {
    outline: none;
    border: 1px solid ${({ theme }) => theme.colors.brand.light.hover};
  }

  ${({ accent }) =>
    accent &&
    css`
      font-weight: 600;
    `}
`;

export const Row = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  flex-direction: ${({ isChatPlacementSwapped }) =>
    isChatPlacementSwapped ? 'row-reverse' : 'row'};
`;

export const Col = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
`;

export const MessengerWrapper = styled.div`
  margin: ${({ isChatPlacementSwapped }) => (isChatPlacementSwapped ? '0 38px 0 0' : '0 0 0 38px')};
  height: 600px;
`;

export const Messenger = styled(MessengerPreview)`
  min-width: 405px;
  height: 654px;
  margin-top: -80px;
`;

export const MessengerDescription = styled.p`
  margin: 16px 0;
  font-size: 12px;
  line-height: 15px;
  color: ${({ theme }) => theme.colors.text.variant2};

  a {
    color: ${({ theme }) => theme.colors.text.variant2};
  }
`;

export const LinkAction = styled.button`
  margin-bottom: 6px;
  padding: 4px;
  border: none;
  background: none;
`;

export const GalleryWrapper = styled.div`
  padding: 10px 15px;
`;

export const ImageTemplateList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0 0 4px;
  padding: 0;
`;

export const Template = styled.li`
  display: flex;
  width: calc(100% / 3 - 16px / 3);
  height: 60px;
  margin: 0 0 8px;
  margin-right: 8px;
  background: url(${({ url }) => url});
  background-size: cover;
  background-position: center;

  &:nth-child(3n + 3) {
    margin-right: 0;
  }
`;

export const TitleTemplate = styled.p`
  margin: 0 0 4px;
  font-size: 14px;
  line-height: 17px;
  text-transform: capitalize;
`;

export const ChatSwapper = styled.div`
  position: absolute;
  z-index: 2;
  right: 60px;
  top: 80px;
  display: flex;
  align-items: center;
`;

export const ChatSwapperText = styled.span`
  color: ${({ theme }) => theme.colors.brand.light.base};
  margin-right: 10px;
  font-size: 16px;
  font-weight: 600;
`;

export const ChatSwapperSwitchesWrap = styled.div`
  display: flex;
  //box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
  border: 1px solid ${({ theme }) => theme.colors.gray1};
  border-radius: 4px;
`;

export const ChatSwapperSwitch = styled.div`
  padding: 5px 8px;
  background-color: #fff;
  border-radius: 0 4px 4px 0;

  &:first-child {
    border-right: 1px solid ${({ theme }) => theme.colors.border.default};
    border-radius: 4px 0 0 4px;
  }

  svg {
    * {
      fill: ${({ isActive, theme }) =>
        isActive ? theme.colors.brand.light.base : theme.colors.gray1};
    }
  }
`;
