import styled from 'styled-components/macro';

export const Form = styled.form`
  width: 100%;
`;

export const FormGrid = styled.div`
  display: grid;
  grid-template-columns: 25% 75%;
  row-gap: 20px;
  font-size: 12px;
  line-height: 15px;
  align-items: center;
  margin-bottom: 50px;
`;

export const Label = styled.label`
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  margin: 0;
`;

export const InlineTip = styled.div`
  display: flex;
  align-items: center;

  p {
    margin: 0 0 0 15px;
    max-width: 225px;
  }
`;

export const CenterDiv = styled.div`
  display: flex;
  justify-content: center;
`;
