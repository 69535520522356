import styled from 'styled-components';

export const Wrapper = styled.div`
  padding-top: 35px;
`;

export const Action = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 35px;
  padding-bottom: 10px;
`;
