import React from 'react';
import { useMutation, useQuery } from 'react-query';
import Switcher from 'src/components/Switcher';
import { chatgenieAPI } from 'src/config/api';
import { queryClient } from 'src/config/queryClient';
import queryKeys from 'src/constants/queryKeys';

export default function LogMagicAIActivityCheckbox() {
  const { data: settings, isLoading: isSettingsLoading } = useQuery(
    [queryKeys.GET_SETTINGS],
    chatgenieAPI.getSettings
  );

  const mutateSettings = useMutation(
    () =>
      chatgenieAPI.updateSettings({
        send_magic_ai_note_to_psa: !settings?.send_magic_ai_note_to_psa
      }),
    {
      onSuccess: res => {
        queryClient.setQueryData([queryKeys.GET_SETTINGS], res);
      }
    }
  );

  return (
    <Switcher
      id="send-magic-ai-note-to-psa"
      width={72}
      checked={!!settings?.send_magic_ai_note_to_psa}
      onChange={mutateSettings.mutate}
      isLoading={isSettingsLoading || mutateSettings.isLoading}
      controlText={{ checked: 'ON', unchecked: 'OFF' }}
    />
  );
}
