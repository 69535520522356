import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { flowListTransformData } from '../../utils/data-transformer';
import { flowFields } from '../../constants/flows';
import { getFlows, deleteFlow, renameFlow } from '../../redux/modules/flows/flowsActions';
import { useFlows } from './hook';
import { flowTableColumns } from './config';

import PageWrapper from '../../components/PageWrapper';
import SimpleButton from '../../components/SimpleButton';
import ActionModal from '../../components/ActionModal';
import FormInputWithLabel from '../../components/FormInputWithLabel';
import Table from '../../components/Table';
import LinkButton from '../../components/LinkButton';

import { FromStyled, HolderStyled } from './styles';

const Flows = ({
  flows,
  getFlows,
  history,
  deleteFlow,
  renameFlow,
  flowListLoading,
  flowListError,
  changeFlowLoading,
  errorMessage,
  user
}) => {
  const {
    isCreateModal,
    setIsCreateModal,
    renameFlowModal,
    setRenameFlowModal,
    deleteFlowModal,
    setDeleteFlowModal,
    flowName,
    setFlowName,
    openRenameFlowModal,
    openDeleteFlowModal,
    renameFlowHandler,
    deleteFlowHandler,
    createFlowHandler,
    duplicateFlowHandler
  } = useFlows({
    deleteFlow,
    renameFlow,
    history,
    flows
  });

  const actions = row => [
    { title: 'Rename', action: () => openRenameFlowModal(row.id, row.name) },
    { title: 'Duplicate', action: () => duplicateFlowHandler(row) },
    { title: 'Delete', action: () => openDeleteFlowModal(row.id) }
  ];

  return (
    <Fragment>
      <PageWrapper
        title="Flows"
        tools={
          <SimpleButton
            onClick={() => {
              setIsCreateModal(!isCreateModal);
            }}
          >
            Create Flow
          </SimpleButton>
        }
      >
        {user.isFlows ? (
          <Fragment>
            <Table
              getData={getFlows}
              isLoading={flowListLoading}
              error={flowListError}
              columns={flowTableColumns(actions, user.chatConfiguration)}
              rows={flows}
              onRowClick={row =>
                history.push(`/dashboard/flows/configure-flow?id=${row.id}&name=${row.name}`)
              }
            />
          </Fragment>
        ) : (
          <HolderStyled>
            <h3>Welcome to Thread!</h3>
            <p>Let’s get you started with your first flow.</p>
            <LinkButton
              onClick={() => {
                setIsCreateModal(!isCreateModal);
              }}
            >
              Create Flow
            </LinkButton>
            {' or '}
            <Link to="/dashboard/templates">Use Template</Link>
          </HolderStyled>
        )}
      </PageWrapper>
      <ActionModal
        isOpen={isCreateModal}
        toggleHandler={() => setIsCreateModal(!isCreateModal)}
        title="Create Flow"
      >
        <FromStyled id="create-flow" onSubmit={createFlowHandler}>
          <FormInputWithLabel
            onChange={event => setFlowName(event.target.value)}
            value={flowName}
            {...flowFields.FLOW_NAME}
          />
          <SimpleButton type="submit" form="create-flow" isDisabled={!flowName}>
            Create Flow
          </SimpleButton>
        </FromStyled>
      </ActionModal>
      <ActionModal
        isOpen={deleteFlowModal.isVisible}
        toggleHandler={() => setDeleteFlowModal({ isVisible: !deleteFlowModal.isVisible })}
        title="Delete Flow"
        description="Are you sure you want to delete the Flow?"
      >
        <FromStyled id="delete-flow" onSubmit={deleteFlowHandler}>
          <SimpleButton
            type="submit"
            form="delete-flow"
            styleMode="error"
            isLoader={changeFlowLoading}
          >
            Delete Flow
          </SimpleButton>
        </FromStyled>
      </ActionModal>
      <ActionModal
        isOpen={renameFlowModal.isVisible}
        toggleHandler={() => setRenameFlowModal({ isVisible: !renameFlowModal.isVisible })}
        title="Rename Flow"
        error={errorMessage}
      >
        <FromStyled id="rename-flow" onSubmit={renameFlowHandler}>
          <FormInputWithLabel
            onChange={event => setFlowName(event.target.value)}
            value={flowName}
            {...flowFields.FLOW_NAME}
          />
          <SimpleButton
            type="submit"
            form="rename-flow"
            isDisabled={!flowName}
            isLoader={changeFlowLoading}
          >
            Rename Flow
          </SimpleButton>
        </FromStyled>
      </ActionModal>
    </Fragment>
  );
};

Flows.propTypes = {
  history: PropTypes.objectOf(PropTypes.any),
  user: PropTypes.objectOf(PropTypes.any),
  flows: PropTypes.objectOf(PropTypes.any),
  getFlows: PropTypes.func.isRequired,
  deleteFlow: PropTypes.func.isRequired,
  renameFlow: PropTypes.func.isRequired,
  flowListError: PropTypes.string,
  errorMessage: PropTypes.string,
  flowListLoading: PropTypes.bool,
  changeFlowLoading: PropTypes.bool
};

Flows.defaultProps = {
  history: {},
  user: {},
  flows: {},
  flowListLoading: false,
  flowListError: '',
  errorMessage: '',
  changeFlowLoading: false
};

const mapStateToProps = state => ({
  flows: {
    ...state.flowsReducer.flows,
    data: flowListTransformData(state.flowsReducer.flows.data)
  },
  flowListLoading: state.flowsReducer.flowListLoading,
  flowListError: state.flowsReducer.flowListError,
  changeFlowLoading: state.flowsReducer.changeFlowLoading,
  errorMessage: state.flowsReducer.errorMessage,
  user: state.usersReducer.user
});

const mapDispatchToProps = dispatch => ({
  getFlows: params => dispatch(getFlows(params)),
  deleteFlow: id => dispatch(deleteFlow(id)),
  renameFlow: (id, name) => dispatch(renameFlow(id, name))
});

export default connect(mapStateToProps, mapDispatchToProps)(Flows);
