import styled, { css } from 'styled-components/macro';

export const Wrapper = styled.div`
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 240px;
  width: calc(100% - 240px);
  height: 100vh;
  background-color: ${({ theme }) => theme.colors.background.secondary};
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 72px);
  overflow: auto;

  ${({ isLoading }) =>
    isLoading &&
    css`
      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.5);
      }
    `}

  .loader {
    width: 40px;
    height: 40px;
    z-index: 1000;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 24px;
  background-color: ${({ theme }) => theme.colors.background.default};
`;

export const Title = styled.h2`
  font-weight: 600;
  font-size: min(2vw, 20px);
  line-height: 33px;
  text-align: center;

  @media screen and (max-width: 800px) {
    font-size: 16px;
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  width: 25%;

  && > button {
    width: auto;
    min-width: 120px;
    flex-shrink: 0;
  }

  &:last-child {
    justify-content: flex-end;
  }

  button {
    width: 120px;
    min-width: 0;

    &:first-child:last-child {
      margin: 0;
    }

    svg {
      margin-right: 6px;
      stroke: ${({ theme }) => theme.colors.brand.light.base};
    }

    &:hover svg,
    &:focus svg {
      stroke: ${({ theme }) => theme.colors.common.white};
    }
  }

  button.secondary {
    display: flex;
    align-items: center;
    margin: 0 16px;
    font-weight: 700;
    font-size: 14px;
    line-height: 33px;
    color: ${({ theme }) => theme.colors.brand.light.base};
    background-color: ${({ theme }) => theme.colors.background.default};
    text-decoration: none;

    &:hover,
    &:focus {
      color: ${({ theme }) => theme.colors.brand.light.hover};
    }

    svg {
      margin-right: 6px;
      stroke: ${({ theme }) => theme.colors.brand.light.base};
    }
  }
`;
