import React from 'react';
import PropTypes from 'prop-types';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { ReactComponent as Arrows } from 'src/assets/icons/arrow-line.svg';
import { ReactComponent as WebLogo } from 'src/assets/images/web.svg';
import * as Styled from './styles';

const FigureSwitcher = ({ entity, logos }) => {
  const transitionHandler = (node, done) => node.addEventListener('transitionend', done, false);

  return (
    <Styled.Rendering>
      <SwitchTransition>
        <CSSTransition
          key={entity}
          addEndListener={transitionHandler}
          timeout={200}
          classNames="chat"
        >
          <Styled.ImageLeftWrapper>
            <img key={entity} src={`/images/${entity}.png`} alt="chat" />
          </Styled.ImageLeftWrapper>
        </CSSTransition>
      </SwitchTransition>
      <Styled.ImageRightWrapper>
        <img src="/images/web.png" alt="web" />
      </Styled.ImageRightWrapper>
      <Styled.LogoSwitcher>
        <Styled.Logo>{logos[entity]}</Styled.Logo>
        <Styled.Separator>
          <Arrows />
        </Styled.Separator>
        <Styled.Logo>
          <WebLogo />
        </Styled.Logo>
      </Styled.LogoSwitcher>
    </Styled.Rendering>
  );
};

FigureSwitcher.propTypes = {
  entity: PropTypes.string,
  logos: PropTypes.objectOf(PropTypes.node).isRequired
};

FigureSwitcher.defaultProps = {
  entity: 'msteams'
};

export default FigureSwitcher;
