import {
  actionStatuses,
  notificationTypes,
  flowsTypes,
  usersTypes
} from '../../../constants/action-types';
import { notificationConstants } from '../../../constants/notification';

const defaultState = {
  notifications: []
};

const map = {
  [`${flowsTypes.CREATE_FLOW}${actionStatuses.REJECTED}`]: (state, action) => ({
    notifications: [
      ...state.notifications,
      {
        ...notificationConstants.FLOW_ERROR,
        label: action.payload.response.data.message,
        errors: action.payload.response.data.errors,
        id: state.notifications.length
      }
    ]
  }),
  [`${flowsTypes.UPDATE_FLOW}${actionStatuses.REJECTED}`]: (state, action) => ({
    notifications: [
      ...state.notifications,
      {
        ...notificationConstants.FLOW_ERROR,
        label: action.payload.response.data.message,
        errors: action.payload.response.data.errors,
        id: state.notifications.length
      }
    ]
  }),
  [`${usersTypes.INVITE_MEMBER}${actionStatuses.FULFILLED}`]: state => ({
    notifications: [
      ...state.notifications,
      {
        ...notificationConstants.MEMBER_INVITE_FULFILLED,
        id: state.notifications.length
      }
    ]
  }),
  [notificationTypes.CLEAR_NOTIFICATIONS]: () => ({
    notifications: defaultState.notifications
  })
};

export default function notificationsReducer(state = defaultState, action) {
  return (map[action.type] && map[action.type](state, action)) || state;
}
