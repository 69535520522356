import React, { useState } from 'react';
import SignupTicketing from './SignupTicketing';

const SignupTicketingContainer = () => {
  const [activeTicketing, setActiveTicketing] = useState(null);

  return (
    <SignupTicketing setActiveTicketing={setActiveTicketing} activeTicketing={activeTicketing} />
  );
};

export default SignupTicketingContainer;
