import styled, { css } from 'styled-components/macro';
import MessageForm from '../../components/MessageForm';

export const AreaStyled = styled.div`
  position: relative;
  display: flex;
  flex-grow: 1;
  width: calc(100% + 40px);
  height: calc(100% + 40px);
  margin: -20px;
  padding: 20px 20px 240px;
  background: repeating-linear-gradient(
      to right,
      transparent,
      transparent 20px,
      #e9e9e9 20px,
      #e9e9e9 21px
    ),
    repeating-linear-gradient(to bottom, transparent, transparent 20px, #e9e9e9 20px, #e9e9e9 21px);
`;

export const OverlayLoaderStyled = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 100;
`;
export const ActionContainerStyled = styled.div`
  position: relative;
`;

export const ActionDeleteStyled = styled.button`
  position: absolute;
  top: 38px;
  right: 20px;
  cursor: pointer;
  border: none;
  background-color: transparent;
  outline: none;
`;

export const WrapperStyled = styled.div`
  display: inline-flex;
  max-width: 1000px;
  min-width: 620px;
  width: auto;
  margin: 36px auto;
  flex-direction: column;

  svg[data-tip='true'] {
    cursor: pointer;
    margin-left: 8px;
  }
`;

export const MessageStyled = styled(MessageForm)`
  max-width: 540px;
  margin: 0 0 10px;
  text-align: left;
  font-weight: 400;
`;

export const TitleWrapperStyled = styled.div`
  position: relative;
`;

const inputStyles = css`
  min-width: 100px;
  min-height: 38px;
  padding: 4px 12px;
  font-size: 22px;
  line-height: 30px;
  font-weight: 500;
  letter-spacing: -0.59px;
  white-space: nowrap;
`;

export const TitleStyled = styled.h2`
  ${inputStyles}
  margin: 0;
  cursor: pointer;
  transition: box-shadow 0.2s;

  ${({ isShow }) =>
    !isShow &&
    `
    position: absolute;
    top: 40px;
    left: 0;
    background: red;
    visibility: hidden;
    z-index: -100;
  `};

  &:hover {
    box-shadow: 0 0 0 1px #cccccc;
  }
`;

export const OptionWrapperStyled = styled.div`
  position: relative;

  svg {
    position: absolute;
    top: 50%;
    left: 6px;
    display: block;
    fill: #616161;
    transform: translateY(-50%);
    z-index: 50;
    user-select: none;
  }

  ${({ isValue }) =>
    isValue &&
    `
    padding-left: 14px;

    svg { left: 0; }
  `};
`;

export const SubTitleStyled = styled.h4`
  margin-top: 10px;
  margin-bottom: 6px;
  font-size: 12px;
  line-height: 17px;
  font-weight: bold;
  letter-spacing: -0.43;
  color: #616161;
`;

export const TitleInputStyled = styled.input`
  ${inputStyles}
  display: ${({ isShow }) => (isShow ? 'block' : 'none')};
  width: ${({ titleWidth }) => titleWidth}px;
  border: none;
  outline: none;
  box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.accent.main};
`;

export const SeparatorStyled = styled.div`
  display: flex;
  justify-content: center;
  ${({ absolute }) =>
    absolute &&
    css`
      position: absolute;
      top: -38px;
      left: calc(calc(100% - 20px) / 2);
    `}
  ${({ withTopOffset }) => withTopOffset && 'margin-bottom: -26px;'}
  ${({ animated }) =>
    animated &&
    css`
      display: flex;
      align-items: center;
      position: relative;
      flex-wrap: nowrap;
      button {
        position: absolute;
        top: calc(100% - 20px);
        left: 50%;
        transform: translateX(-50%);
        background-color: white;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
        color: #061274;
        min-width: 42px;
        height: 42px;
        transition: all 0.4s ease-in-out;
        border-radius: 21px;
        overflow: hidden;
        svg {
          width: 13px;
          height: 13px;
          path {
            fill: #061274;
          }
        }
        .hidden-text {
          position: absolute;
          font-weight: 600;
          font-size: 12px;
          line-height: 15px;
          left: 110%;
          opacity: 0;
          width: 0;
          transition: all 0.3s ease;
          color: ${({ theme }) => theme.colors.brand.light.base};
        }

        &:hover,
        &:focus {
          background-color: white;
          color: #061274;
          padding-right: 20px;

          .hidden-text {
            position: relative;
            opacity: 1;
            left: 5px;
            width: 100%;
          }
        }
      }
    `}
`;

export const ToolsStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 220px;
`;

export const FormWrapperStyled = styled.fieldset`
  margin-top: 8px;
`;

export const FromStyled = styled.form`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
`;

export const FiltersStyled = styled.div`
  .rule,
  .ruleGroup {
    position: relative;
  }

  .ruleGroup {
    display: flex;
    flex-direction: column;
    align-items: flex-stretch;
    padding: 8px;
    background: rgba(0, 0, 0, 0.02);
    border-radius: 3px;
    box-shadow: rgba(55, 53, 47, 0.1) 0px 0px 0px 1px;
  }

  .ruleGroup-header:not(:only-child) {
    order: 1;
  }

  & > .ruleGroup {
    position: relative;
    padding-left: 0;
  }

  .ruleGroup .rule,
  .ruleGroup .ruleGroup {
    margin-bottom: 10px;
    margin-left: 80px;
  }

  .select__control,
  .rule > input,
  .rule-value > div,
  .rule-value > input {
    box-shadow: rgba(55, 53, 47, 0.1) 0px 0px 0px 1px;
    background: #ffffff;
  }

  /* dotted rule */
  .rule {
    display: flex;
    align-items: center;
  }

  .ruleGroup-header + .rule::after,
  .ruleGroup-header + .ruleGroup::after {
    content: 'Where';
    position: absolute;
    left: -54px;
    font-size: 12px;
    font-weight: bold;
    color: #616161;
  }

  .rule > *:not(:last-child) {
    margin: 0;
    margin-right: 10px;
  }

  .rule-value {
    min-width: 180px;
  }

  .rule-control {
    width: 180px;
  }

  .ruleGroup-combinators,
  .rule-operators {
    display: inline-flex;
    margin-right: 10px;

    & > div {
      width: 100%;
    }
  }

  .ruleGroup-combinators {
    position: absolute;
    top: 30px;
    align-self: flex-start;
    min-width: 70px !important;
    margin-top: -30px;
  }

  .rule-operators {
    min-width: 86px !important;
  }

  .rule-fields {
    min-width: 160px !important;
  }

  .ruleGroup > .rule ~ .rule {
    top: auto;
  }

  .rule-remove,
  .ruleGroup-remove {
    font-size: 20px;
    font-weight: 300;
    padding: 16px 10px;
    line-height: 0;
  }

  .rule-remove {
    margin-right: 0;
  }

  input.text-control,
  input.number-control {
    border: 1px solid #cccccc;
    border-radius: 4px;
    min-height: 38px;
    padding: 2px 8px;
    max-width: 180px;
    font-family: 'Inter', 'Helvetica Neue', sans-serif;
    font-size: 16px;
    line-height: 24px;
    transition: all 100ms;

    &:focus {
      border-color: #1b80fe;
      box-shadow: 0 0 0 1px #2684ff;
      outline: none;
    }
  }

  button,
  select {
    min-width: 30px;
    margin: 0;
    margin-right: 5px;
    padding: 8px 12px;
    font-size: 12px;
    line-height: 14px;
    border: none;
    border-radius: 4px;
    background-color: ${({ theme }) => theme.colors.accent.main};
    color: #ffffff;
  }

  button:hover,
  button:focus {
    outline: none;
    background-color: #07189a;
  }

  button:active {
    background-color: #1767c1;
  }

  select {
    height: 28px;
    background-color: #ffffff;
    color: #333333;
    border: 1px solid #e0e0e0;
  }
`;

export const CheckListStyled = styled.div`
  display: inline-flex;
  flex-direction: ${({ column }) => (column ? 'column' : 'row')};
  flex-wrap: wrap;

  label {
    margin: ${({ column }) => column && '0 0 10px 0'};
  }
`;

export const OffsetButtomStyled = styled.div`
  margin-bottom: 8px;
`;

export const NoOptionsWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const Logo = styled.img`
  margin-right: 10px;
`;

export const DraggableBox = styled.div``;

export const DragIcon = styled.span``;
