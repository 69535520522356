import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from 'react-query';
import queryKeys from 'src/constants/queryKeys';
import FadeInOutContainer from 'src/components/FadeInOutContainer';
import { chatgenieAPI } from 'src/config/api';
import { uniqBy } from 'lodash';
import { captureMessage } from '@sentry/react';
import magicAnimated from 'src/assets/icons/magic-animated.gif';
import { isHalo } from 'src/utils/psa';
import PageHolder from '../../PageHolder';
import Introduction from '../Introduction';
import EditPrioritization from './EditPrioritization';
import { Wrapper } from '../style';
import { introContent as content, PrioritizationConfigContext } from './constants';
import ViewPrioritization from './ViewPrioritization';

const pageModes = {
  start: 'start',
  edit: 'edit',
  view: 'view',
  loading: 'loading'
};

const CopilotPrioritization = ({ companyId }) => {
  const [selectedSla, setSelectedSla] = useState(null);
  const isDependentFieldAvailable = isHalo() ? !!selectedSla : true;

  const { isLoading, data } = useQuery(
    [queryKeys.GET_COPILOT_PRIORITY_RULES, { slaId: selectedSla?.id }],
    () => chatgenieAPI.getCopilotPriorityRules(selectedSla?.id),
    {
      enabled: isDependentFieldAvailable
    }
  );
  const { isLoading: isLoadingPriorities, data: priorities } = useQuery(
    [queryKeys.GET_PRIORITIES],
    () =>
      isHalo()
        ? chatgenieAPI.getPrioritiesBySla({ slaId: selectedSla?.id })
        : chatgenieAPI.getPriorities({ query: '', page: 1, normalizer: res => res.data }),
    { enabled: isDependentFieldAvailable }
  );
  const [pageMode, setPageMode] = useState({});

  const [customRules, conventionalRules] = useMemo(() => {
    if (!data?.conditions) return [[], []];
    const customExcluded = [];
    let custom = {
      type: 'CUSTOM',
      rules: []
    };
    data.conditions.forEach(section => {
      if (section.type === 'CUSTOM') {
        custom = section;
      } else {
        customExcluded.push(section);
      }
    });
    return [custom, customExcluded.map(el => ({ ...el, rules: uniqBy(el.rules, 'id') }))];
  }, [data]);

  const handleStart = async () => {
    if (!conventionalRules.length) {
      captureMessage('No rules found for copilot prioritization');
      return;
    }

    setPageMode({ id: pageModes.edit });
  };

  useEffect(() => {
    if (data) {
      setPageMode({ id: !isHalo() && data.first_access ? pageModes.start : pageModes.view });
    }
  }, [data]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pageMode.id]);

  useEffect(() => {
    if (isHalo()) {
      chatgenieAPI.getServiceLevelAgreements(companyId).then(res => {
        setSelectedSla(res[0]);
      });
    }
  }, []);

  if (isLoading || isLoadingPriorities || !isDependentFieldAvailable) {
    return <PageHolder fullHeight isLoading />;
  }

  return (
    <Wrapper pageMode={pageMode.id}>
      {pageMode.id && pageMode.id !== pageModes.view && (
        <img src={magicAnimated} alt="Magic" width="92" height="92" />
      )}

      <PrioritizationConfigContext.Provider value={{ selectedSla, setSelectedSla }}>
        <FadeInOutContainer visible={pageMode.id === pageModes.start}>
          <Introduction content={content} onStartClick={handleStart} />
        </FadeInOutContainer>

        <FadeInOutContainer visible={pageMode.id === pageModes.edit}>
          <EditPrioritization
            defaultStep={pageMode.step}
            companyId={companyId}
            onSave={() => setPageMode({ id: pageModes.view, firstTime: true })}
            data={conventionalRules}
            priorities={priorities}
          />
        </FadeInOutContainer>
        <FadeInOutContainer visible={pageMode.id === pageModes.view}>
          <ViewPrioritization
            companyId={companyId}
            onClickEdit={step => setPageMode({ id: pageModes.edit, step })}
            data={conventionalRules}
            customRules={customRules}
            runGenerateAnimation={pageMode.firstTime}
          />
        </FadeInOutContainer>
      </PrioritizationConfigContext.Provider>
    </Wrapper>
  );
};

CopilotPrioritization.propTypes = {
  companyId: PropTypes.number.isRequired
};

export default CopilotPrioritization;
