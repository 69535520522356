import styled from 'styled-components';
import { lighten } from 'polished';

export const PopupWrapper = styled.nav`
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 100;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);
`;

export const PopupItems = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

export const PopupItem = styled.li`
  cursor: pointer;
`;

export const PopupItemLink = styled.a`
  display: block;
  font-size: 12px;
  color: #000000;
  padding: 10px 20px;
  text-decoration: none;
  background: #ffffff;
  transition: background-color 0.2s;

  &:hover {
    text-decoration: none;
    color: #000000;
    background-color: ${() => lighten(0.05, '#E6E6E6')};
  }
`;
