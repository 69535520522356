import React, { useEffect, useMemo, useState } from 'react';
import theme from 'src/config/theme';
import { ReactComponent as PlusIcon } from 'src/assets/icons/plus-2.svg';
import PropTypes from 'prop-types';
import uniqBy from 'lodash/uniqBy';
import ColorField from '../ColorField';
import * as Styled from './styles';

const ColorPicker = ({ additionalColors, maxColorCount, circleSize, color, onChange }) => {
  const colors = useMemo(() => {
    return uniqBy([...additionalColors, ...theme.messenger.default.primaryColors]).splice(
      0,
      maxColorCount
    );
  }, [additionalColors, maxColorCount]);
  const activeColor = color || colors[0];
  const [isColorPicker, setIsColorPicker] = useState(false);
  const [customColor, setCustomColor] = useState(null);

  useEffect(() => {
    setCustomColor(colors.includes(color) ? null : color);
  }, [color, colors]);

  return (
    <Styled.ColorsList>
      {colors.map(color => (
        <Styled.Color
          onClick={() => onChange(color)}
          isActive={activeColor === color}
          circleSize={circleSize}
          fill={color}
          key={color}
        />
      ))}
      {customColor && !isColorPicker && (
        <Styled.Color
          onClick={() => onChange(customColor)}
          isActive={activeColor === customColor}
          circleSize={circleSize}
          fill={customColor}
          key={customColor}
        />
      )}
      {isColorPicker ? (
        <ColorField circleSize={circleSize} setColorHandler={onChange} activeColor={activeColor} />
      ) : (
        <Styled.AddColor
          type="button"
          aria-label="add-color"
          circleSize={circleSize}
          onClick={() => setIsColorPicker(true)}
        >
          <PlusIcon />
        </Styled.AddColor>
      )}
    </Styled.ColorsList>
  );
};

ColorPicker.defaultProps = {
  circleSize: 42,
  color: null,
  onChange: () => {},
  additionalColors: [],
  maxColorCount: 7
};

ColorPicker.propTypes = {
  color: PropTypes.string,
  circleSize: PropTypes.number,
  onChange: PropTypes.func,
  additionalColors: PropTypes.arrayOf(PropTypes.string),
  maxColorCount: PropTypes.number
};

export default ColorPicker;
