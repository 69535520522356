/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React from 'react';
import { components } from 'react-select';
import SimpleCheckbox from 'src/components/SimpleCheckbox';
import { FiX } from 'react-icons/fi';
import { Option, MultiValue } from './styles';

export const CustomOption = props => {
  return (
    <Option {...props}>
      <SimpleCheckbox checked={props.isSelected} onChange={() => null} /> <span>{props.label}</span>
    </Option>
  );
};

export const CustomMultiValue = props => {
  return (
    <MultiValue {...props} className="custom-multi-value">
      <span>{props.data.name}</span>
    </MultiValue>
  );
};

export const CustomMenuList = props => {
  const { children, ...rest } = props;
  return (
    <components.MenuList {...rest}>
      {React.Children.map(children, child =>
        React.cloneElement(child, { selectProps: props.selectProps })
      )}
    </components.MenuList>
  );
};

export const CustomClearIndicator = props => {
  return (
    <components.ClearIndicator {...props}>
      <FiX color="#333" />
    </components.ClearIndicator>
  );
};
