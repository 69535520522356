import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import styled from 'styled-components';

export const ModalStyled = styled(Modal)`
  max-width: 460px;
  margin-top: 88px;

  .modal-content {
    padding: 20px 40px;
  }
`;

export const ModalHeaderStyled = styled(ModalHeader)`
  display: flex;
  justify-content: center;
  padding: 0;
  border: none;

  .modal-title {
    margin-bottom: 14px;
    font-size: 18px;
    line-height: 24px;
    font-weight: bold;
  }

  .close {
    position: absolute;
    bottom: 100%;
    left: 100%;
    margin: 0;
    padding: 5px;
    font-size: 26px;
    font-weight: 300;
    border: 1px solid transparent;
    transition: border-color 0.2s;
    opacity: 1;

    &:focus {
      outline: none;
      border-color: ${({ theme }) => theme.colors.brand.light.hover};
    }

    span {
      display: block;
      height: 15px;
      width: 15px;
      line-height: 15px;
      text-shadow: none;
      color: #ffffff;
    }
  }
`;

export const ModalBodyStyled = styled(ModalBody)`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.09px;
  color: #2e2e2e;

  div {
    width: 100%;
  }
`;

export const DescriptionStyled = styled.p`
  width: 100%;
  font-size: 12px;
  text-align: center;
  line-height: 1.5;
  letter-spacing: -0.08px;
  color: #616161;
`;

export const ActionsWrapperStyled = styled.div`
  display: flex;
  flex-direction: ${({ btnFullWidth }) => (btnFullWidth ? 'column' : 'row')};
  justify-content: ${({ btnCenter }) => (btnCenter ? 'center' : 'flex-end')};

  button:not(:last-child) {
    margin-bottom: ${({ btnFullWidth }) => (btnFullWidth ? 16 : 0)}px;
    margin-right: ${({ btnFullWidth }) => (btnFullWidth ? 0 : 16)}px;
  }
`;
