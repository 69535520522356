import { ReactComponent as DownArrow } from 'src/assets/icons/down-arrow.svg';

import React, { useEffect, useState } from 'react';

import SimpleTextarea from 'src/components/SimpleTextarea';
import SimpleInput from 'src/components/SimpleInput';
import { Button } from '@Thread-Magic/jasmine';
import { FiPlus } from 'react-icons/fi';
import uuid from 'uuid';
import { useParams, useHistory } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { chatgenieAPI } from 'src/config/api';
import queryKeys from 'src/constants/queryKeys';
import PageHolder from 'src/components/PageHolder';
import { sortBy } from 'lodash';
import useToast from 'src/hooks/useToast';
import { SeparatorStyled } from '../../../containers/ConfigureFlow/style';
import { IntentBlock, IntentField, IntentFieldGroup, IntentWrapper } from './style';
import IntentArgument, { argumentTypes } from './IntentArgument';

const getDefaultArgumentData = () => ({
  name: '',
  description: '',
  type: argumentTypes[0] || null,
  id: uuid.v4()
});

const IntentScreen = () => {
  const { intentId } = useParams();
  const [data, setData] = useState({
    name: '',
    description: '',
    arguments: [getDefaultArgumentData()],
    url: ''
  });
  const history = useHistory();
  const { toast } = useToast();

  const { data: res, isLoading } = useQuery(
    [queryKeys.GET_AGENTS, intentId],
    () => chatgenieAPI.getAgent(intentId),
    {
      enabled: !!intentId,
      refetchOnMount: true
    }
  );
  const { mutate } = useMutation(
    ['upsertIntent'],
    data => {
      if (intentId) {
        return chatgenieAPI.updateAgent({ ...data, id: intentId });
      }
      return chatgenieAPI.createAgent(data);
    },
    {
      onSuccess: () => {
        history.push('/dashboard/magic/agent');
      },
      onError: error => {
        toast.error(
          `Failed to ${intentId ? 'update' : 'create'} intent: ${
            error?.response?.data?.message || error?.message || 'Unknown'
          }`
        );
      }
    }
  );

  const handleSubmit = e => {
    e.preventDefault();
    const normalizedData = {
      ...data,
      arguments: data.arguments.map(arg => ({
        ...arg,
        id: typeof arg.id === 'string' ? undefined : arg.id,
        type: arg.type?.value || null
      }))
    };
    mutate(normalizedData);
  };

  const handleRemoveArgument = id => {
    if (data.arguments.length === 1) {
      toast.warn('There must be at least one argument.');
      return;
    }
    setData(prev => ({
      ...prev,
      arguments: prev.arguments.filter(arg => arg.id !== id)
    }));
  };

  useEffect(() => {
    if (res) {
      setData({
        ...res,
        arguments: sortBy(res.arguments, 'id').map(arg => ({
          ...arg,
          type: argumentTypes.find(type => type.value === arg.type) || null
        }))
      });
    }
  }, [res]);

  if (isLoading) {
    return <PageHolder fullHeight isLoading={isLoading} />;
  }

  return (
    <IntentWrapper id="intent-form" onSubmit={handleSubmit}>
      <IntentBlock>
        <h5>Intent</h5>
        <div>
          <IntentFieldGroup>
            <IntentField>
              <p>Name</p>
              <SimpleInput
                value={data.name}
                onChange={e => {
                  setData(prev => ({
                    ...prev,
                    name: e.target.value
                  }));
                }}
                placeholder="Name of agent"
              />
            </IntentField>
            <IntentField>
              <p>Description</p>
              <SimpleTextarea
                value={data.description}
                onChange={e => {
                  setData(prev => ({
                    ...prev,
                    description: e.target.value
                  }));
                }}
                placeholder="Description of agent"
              />
            </IntentField>
          </IntentFieldGroup>
        </div>
      </IntentBlock>
      <SeparatorStyled withTopOffset>
        <DownArrow />
      </SeparatorStyled>
      <IntentBlock>
        <h5>Required Information</h5>
        <div>
          <p>Arguments</p>
          {data.arguments.map(argument => {
            return (
              <IntentArgument
                onRemove={() => handleRemoveArgument(argument.id)}
                key={argument.id}
                data={argument}
                onChange={newdata =>
                  setData(prev => ({
                    ...prev,
                    arguments: prev.arguments.map(arg => (arg.id === argument.id ? newdata : arg))
                  }))
                }
              />
            );
          })}
          <Button
            onClick={() => {
              setData(prev => ({
                ...prev,
                arguments: [...prev.arguments, getDefaultArgumentData()]
              }));
            }}
            type="button"
          >
            <FiPlus />
            Add argument
          </Button>
        </div>
      </IntentBlock>
      <SeparatorStyled withTopOffset>
        <DownArrow />
      </SeparatorStyled>
      <IntentBlock>
        <h5>Automation (Optional)</h5>
        <div>
          <IntentFieldGroup>
            <IntentField>
              <p>URL</p>
              <SimpleInput
                value={data.url}
                onChange={e => {
                  setData(prev => ({
                    ...prev,
                    url: e.target.value
                  }));
                }}
                placeholder="URL for the API"
              />
            </IntentField>
          </IntentFieldGroup>
        </div>
      </IntentBlock>
    </IntentWrapper>
  );
};

export default IntentScreen;
