import uuid from 'uuid';

const getDayRule = day => ({
  id: uuid.v4(),
  field: '37',
  value: { type: 'object', label: day, value: day, displayValue: day },
  operator: '='
});

const getTimeRule = (operator, value) => ({
  id: uuid.v4(),
  field: '38',
  value: { value, type: 'time_of_day' },
  operator
});

export const generateFlow = data => {
  const filterData = {
    id: uuid.v4(),
    not: false,
    rules: data
      .filter(item => item.is_available)
      .map(item => ({
        id: uuid.v4(),
        not: false,
        rules: [
          getDayRule(item.weekday),
          getTimeRule('<', item.time_from),
          getTimeRule('>', item.time_to)
        ],
        combinator: 'and'
      })),
    combinator: 'or'
  };

  const generatedFlow = {
    channel: null,
    channelId: null,
    entityType: 'ticket',
    filterData,
    id: null,
    isActive: true,
    isCreateChannel: false,
    name: 'Hours',
    notifications: ['note', 'status']
  };

  return JSON.stringify(generatedFlow);
};

export const getRelativeGreeting = () => {
  const currentTime = new Date();
  let greetingText = 'Good evening';
  if (currentTime.getHours() < 12) {
    greetingText = 'Good morning';
  }
  if (currentTime.getHours() >= 12 && currentTime.getHours() <= 18) {
    greetingText = 'Good afternoon';
  }
  return greetingText;
};
