import styled from 'styled-components/macro';

export const HeaderPreview = styled.div`
  position: absolute;
  top: 0;
  z-index: 100;
  width: 100%;
  height: 64px;
  display: flex;
  align-items: center;
  padding: 11px 21px 13px;
  background-color: #45468c;

  &::after,
  &::before {
    content: '';
  }

  &::after {
    display: flex;
    flex-grow: 1;
    max-width: 700px;
    margin-right: 70px;
    height: 40px;
    margin-left: 85px;
    background: #5d5ea3;
    border-radius: 4px;
  }

  &::before {
    position: absolute;
    right: 13px;
    width: 40px;
    height: 40px;
    border-radius: 64px;
    background: #5d5ea3;
  }
`;

export const AsidePreview = styled.div`
  display: flex;
  flex-direction: column;
  padding: 18px 3px;
  width: 60px;
  background-color: #ebebeb;
`;

export const MenuLogo = styled.div`
  margin-bottom: auto;
  border-left: 2px solid #5c5ec1;
  text-overflow: ellipsis;
  text-align: center;
  color: #5c5ec1;

  &::before {
    content: '';
    display: block;
    width: 30px;
    height: 30px;
    margin: 0 auto 6px;
    background: url(${({ url }) => url});
    background-size: contain;
    background-repeat: no-repeat;
  }

  &::after {
    content: '${({ content }) => content}';
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0 2px;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: #5c5ec1;
  }
`;

export const MenuPlaceholder = styled.div`
  margin-bottom: 16px;
  padding: 0 5px;

  &::after,
  &::before {
    content: '';
    display: block;
  }

  &::before {
    width: 21px;
    height: 21px;
    margin: 0 auto 4px;
    border-radius: 100%;
    background: #d9d9d9;
  }

  &::after {
    width: 43px;
    height: 9px;
    background: #d9d9d9;
  }
`;

export const TeamsTitle = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
`;
